import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators, MemberAccount, MemberAccountService, MemberService, NameChangeType } from 'projects/services/src/public-api';
import { ErrorType } from 'projects/components/src/public-api';

@Component({
    selector: 'pc-name-change-modal',
    templateUrl: './name-change-modal.component.html'
})
export class NameChangeModalComponent extends BaseModalComponent<NameChangeModalComponent> implements OnInit {

    title: string;
    currentName: string;
    id: string;
    type: NameChangeType;
    label: string;
    warning: { message: string, type: ErrorType };
    formGroup: UntypedFormGroup;
    updating = false;

    constructor(dialogRef: MatDialogRef<NameChangeModalComponent>,
                private memberService: MemberService,
                private memberAccountService: MemberAccountService,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.title = data.title;
        this.currentName = data.currentName;
        this.id = data.id;
        this.type = data.type;
        switch (this.type) {
            case NameChangeType.MEMBER_NAME:
                this.label = 'Enter New Member Name';
                break;
            case NameChangeType.ACCOUNT_NAME:
                this.label = 'Enter New Account Name';
                this.warning = {
                    type: ErrorType.WARN,
                    message: 'The account name must be unique across all accounts for this member.<br>This is only for use by the member, and is not visible to other members.'
                };
                break;
            case NameChangeType.ACCOUNT_FRIENDLY_NAME:
                this.label = 'Enter New Friendly Account Name';
                this.warning = {
                    type: ErrorType.WARN,
                    message: 'The friendly name must be unique across all accounts for this member.<br>This is the value that other members will see when transacting with this member.'
                };
                break;
            case NameChangeType.ACCOUNT_SIGNATORY_CHANGE:
                this.label = 'Enter New Account Signatory';
                this.warning = {
                    type: ErrorType.WARN,
                    message: 'The updated signatory will be used as signature for issuing checks.'
                };
                break;
        }
    }

    ngOnInit() {
        const maxLength = this.type === NameChangeType.ACCOUNT_SIGNATORY_CHANGE ? 19 : 64;
        this.formGroup = new UntypedFormGroup({
            nameCtrl: new UntypedFormControl(this.currentName, [Validators.required, CustomValidators.memberName, Validators.minLength(3), Validators.maxLength(maxLength)])
        });
        this.confirm = this.confirm.bind(this);
        this.close = this.close.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.notUpdating = this.notUpdating.bind(this);
    }

    confirm(reset: any) {
        this.updating = true;
        const newName = this.formGroup.get('nameCtrl').value.trim();
        const memberNameChangeRequest = {
            memberId: this.id,
            newName: newName
        };
        let body: any;
        let observable: any;
        switch (this.type) {
            case NameChangeType.MEMBER_NAME:
                observable = this.memberService.updateMemberName(memberNameChangeRequest);
                break;
            case NameChangeType.ACCOUNT_NAME:
                body = { accountName: this.formGroup.controls['nameCtrl'].value };
                observable = this.memberAccountService.updateMemberAccount(this.id, body);
                break;
            case NameChangeType.ACCOUNT_FRIENDLY_NAME:
                body = { accountFriendlyName: this.formGroup.controls['nameCtrl'].value };
                observable = this.memberAccountService.updateMemberAccount(this.id, body);
                break;
            case NameChangeType.ACCOUNT_SIGNATORY_CHANGE:
                body = { signatory: this.formGroup.controls['nameCtrl'].value };
                observable = this.memberAccountService.updateMemberAccount(this.id, body);
                break;
            default:
                return;
        }
        observable.subscribe(
            (result: any) => {
                this.updating = false;
                const closeValue = this.type === NameChangeType.MEMBER_NAME ? { value: newName } : result;
                super.close(closeValue);
            },
            (error: any) => {
                this.updating = false;
                reset();
                throw error;
            });
    }

    close() {
        if (this.notUpdating()) {
            super.close();
        }
    }

    isFormValid() {
        return this.formGroup.valid && this.currentName !== this.formGroup.get('nameCtrl').value.trim();
    }

    notUpdating() {
        return !this.updating;
    }
}
