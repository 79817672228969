<div class="dashboard-list">
    <div class="row">
        <div class="col-12">
            <div class="dashboard-info-icon">
                <img class="dashboard-icon" src="assets/images/receipt-icon-2.svg" alt="">
                <a href="#">{{'Transaction Types'}}</a>
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-12">
            <div *ngIf="!tableView">
                <pc-charts-gadgets *ngIf="chartData.length > 0"
                    [chartData]="chartData"
                    [chartType]="ChartType.GROUPED_VERTICAL_BAR_CHART"
                    [yAxisTickFormatting]="'whole'">
                </pc-charts-gadgets>
            </div>
        </div>
        <div class="col-12" *ngIf="tableView">
            <div class="col-sm-12 mt-3 mb-3 horizontal-scroll mat-table-container"> 
                <table mat-table [dataSource]="transactionTypeTableData">
                    <ng-container matColumnDef="date">
                        <th class="centered" mat-header-cell *matHeaderCellDef> Date </th>
                        <td class="centered text-nowrap" mat-cell *matCellDef="let element">
                            {{ element.date }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="ach">
                        <th class="centered" mat-header-cell *matHeaderCellDef> ACH </th>
                        <td class="centered" mat-cell *matCellDef="let element">
                            {{ element.ach }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="cash">
                        <th class="centered" mat-header-cell *matHeaderCellDef> Cash </th>
                        <td class="centered" mat-cell *matCellDef="let element">
                            {{ element.cash }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="wire">
                        <th class="centered" mat-header-cell *matHeaderCellDef> Wire </th>
                        <td class="centered" mat-cell *matCellDef="let element">
                            {{ element.wire }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="check">
                        <th class="centered" mat-header-cell *matHeaderCellDef> Check </th>
                        <td class="centered" mat-cell *matCellDef="let element">
                            {{ element.check }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="network">
                        <th class="centered" mat-header-cell *matHeaderCellDef> Network </th>
                        <td class="centered" mat-cell *matCellDef="let element">
                            {{ element.network }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="fee">
                        <th class="centered" mat-header-cell *matHeaderCellDef> Fee </th>
                        <td class="centered" mat-cell *matCellDef="let element">
                            {{ element.fee }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="adjustment">
                        <th class="centered" mat-header-cell *matHeaderCellDef> Adjustment </th>
                        <td class="centered" mat-cell *matCellDef="let element">
                            {{ element.adjustment }}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </div>
    <div>
        <pc-view-decision
            [tableView]="tableView"
            (viewChanged)="viewChanged($event)">
        </pc-view-decision>
    </div>
</div>
