<div>
    <p><span class="form-title">API Keys</span></p>
    <p>Your accessId: {{memberId}}<pc-clipboard-gadget value="{{memberId}}"></pc-clipboard-gadget></p>
    <div class="col-sm-12 mt-3 mb-3 horizontal-scroll mat-table-container">
        <table mat-table [dataSource]="keys">
            <ng-container matColumnDef="api_key">
                <th class="centered" mat-header-cell *matHeaderCellDef>  API Key (the 'accessKey' in the request header) </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    {{ element.key }}<pc-clipboard-gadget *ngIf="element.copyable" value="{{element.key}}"></pc-clipboard-gadget>
                </td>
            </ng-container>
            <ng-container matColumnDef="secret">
                <th class="centered" mat-header-cell *matHeaderCellDef> Secret (the 'accessSecret' used to generate the 'shaHash' for payments) </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    {{ element.secret || 'Not available'}}<pc-clipboard-gadget *ngIf="element.copyable" value="{{element.secret}}"></pc-clipboard-gadget>
                </td>
            </ng-container>
            <ng-container matColumnDef="issued_date">
                <th class="centered" mat-header-cell *matHeaderCellDef>  Issued Date </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    {{ element.created | date:'mediumDate' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="disable">
                <th class="centered" mat-header-cell *matHeaderCellDef> Disable </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <i *ngIf="element.status === ApiAccessStatus.ENABLED" class="fa fa-times" id="disable_key_btn" (click)="onDisableKey(element)"></i>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedApiKeysColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedApiKeysColumns;"></tr>
        </table>
    </div>
    <div class="col-sm-12 mt-3" *ngIf="this.authService.isAdmin()">
        <div class="row mt-3">
            <div class="col-sm-8">
                <b>Please note that keys and secrets are only visible when created.<br> Please store your keys securely.</b>
            </div>
            <div class="col-sm-4 text-end" *ngIf="canCreateNewKey()">
                <pc-submit-button pc-no-dbl-click [submit]="onCreateKey" [text]="'Create'"></pc-submit-button>
            </div>
        </div>
    </div>
</div>
<div class="pt-3">
    <pt-payment-processing-notifications  [memberId]="memberId" [readOnly]="readOnly"></pt-payment-processing-notifications>
</div>