import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import {
    DataroomService, Upload, LicenseService, License, Utils, LicenseFormService, DocumentType, MemberType, MemberService, Member, CustomValidators
} from 'projects/services/src/public-api';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from 'projects/components/src/lib/modal';
import { v4 as uuidv4 } from 'uuid';
import { DatePipe } from '@angular/common';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pt-license-modal',
    templateUrl: './license-modal.component.html',
    styleUrls: ['./license-modal.component.scss']
})
export class LicenseModalComponent extends BaseModalComponent<LicenseModalComponent> implements OnInit {

    memberId: string;
    existingLicense: License;
    licenseExpDate: any;
    licenseFile: File;
    licenseForm: UntypedFormGroup;
    licenseFileRequired = false;
    memberType: MemberType;
    jurisdictionOptions: string[] = ['State Operator', 'Local Operator', 'State Tax', 'Local Tax' ];
    changeTrigger: number = 1;
    licensePlaceholder = '';

    constructor(private licenseService: LicenseService,
                private dataroomService: DataroomService,
                private memberService: MemberService,
                private datePipe: DatePipe,
                private licenseFormService: LicenseFormService,
                private notificationService: NotificationService,
                dialogRef: MatDialogRef<LicenseModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.memberId = data.memberId;
        if (data.license) {
            this.existingLicense = data.license;
            this.licenseExpDate = this.existingLicense.expirationDate;
            if (this.existingLicense.jurisdiction === 'State Operator') {
                this.licensePlaceholder = 'No spaces in license number';
            }
        }
    }

    ngOnInit() {
        this.addLicense = this.addLicense.bind(this);
        this.licenseValid = this.licenseValid.bind(this);
        this.selectionsMade = this.selectionsMade.bind(this);
        this.memberService.loadMember(this.memberId).subscribe((member: Member) => {
            this.memberType = member.memberType;
            this.licenseForm = this.licenseFormService.initializeForm(this.existingLicense, this.memberId, this.memberType);
        });
        if (this.existingLicense?.documentId) {
            this.dataroomService.getDocumentById(this.existingLicense.documentId).subscribe((upload: Upload) => {
                this.licenseFile = new File([], upload.fileName);
            });
        }
    }

    addLicense(reset: any) {
        const license = this.licenseFormService.getLicense(this.licenseForm);
        license.memberId = this.memberId;
        license.expirationDate = this.licenseExpDate;

        if (license.documentId) {
            this.licenseService.saveLicense(license).subscribe(() => {
                this.close(true);
            });
        } else {
            if (this.licenseFile) {
                const path = this.memberId + '/licenses/' + license.jurisdiction.replace(' ', '_') + '/' + uuidv4();
                this.dataroomService.uploadFile(this.memberId, path, this.licenseFile, this.licenseFile.name, '', DocumentType.LICENSE, this.memberId).subscribe((uploadResp: Upload) => {
                    license.documentId = uploadResp.id;
                    this.licenseService.saveLicense(license).subscribe(() => {
                        this.close(true);
                    });
                }, () => {
                    reset();
                    this.notificationService.showError('Document upload was unsuccessful. Please check your connection and try again.');
                });
            } else {
                this.licenseService.saveLicense(license).subscribe(() => {
                    this.close(true);
                });
            }
        }
    }

    close(refresh?: boolean) {
        this.licenseFile = null;
        super.close(refresh);
    }

    selectLicenseFile(event: any) {
        if (event.target.files && event.target.files.length) {
            const file = event.target.files[0];
            Utils.validateFile(file, event);
            this.licenseFile = file;
            event.target.value = '';
            this.licenseForm.get('documentIdCtrl').setValue(null);
        }
    }

    onDateSelected(date: any) {
        this.licenseExpDate = null;
        if (date?.singleDate) {
            this.licenseExpDate = this.datePipe.transform(date?.singleDate?.jsDate, 'yyyy-MM-dd');
        }
    }

    licenseValid() {
        return this.licenseForm && this.licenseForm.valid && this.licenseForm.dirty && (!this.licenseFileRequired || this.licenseFile);
    }

    selectionsMade() {
        return this.licenseForm.controls['jurisdictionCtrl'].value;
    }

    selectedJurisdiction(stateJurisdiction: string) {
        this.licenseFileRequired = false;
        this.licenseForm.get('licenseExpDateCtrl').setValidators(null);
        this.licenseForm.get('licenseNumberCtrl').setValidators([Validators.required, , CustomValidators.noWhitespace]);
        this.licensePlaceholder = '';
        if (stateJurisdiction === 'State Operator') {
            if (this.memberType === MemberType.BUSINESS) {
                this.licenseFileRequired = true;
                this.licenseForm.get('licenseExpDateCtrl').setValidators([Validators.required]);
            }
            this.licensePlaceholder = 'No spaces in license number';
            this.licenseForm.get('licenseNumberCtrl').setValidators([Validators.required, CustomValidators.noWhitespace]);
        }
        this.licenseForm.get('licenseExpDateCtrl').updateValueAndValidity();
        this.licenseForm.get('licenseNumberCtrl').updateValueAndValidity();
        this.changeTrigger = Math.random();
    }

    onDeleteFile() {
        this.licenseFile = null;
        this.licenseForm.get('documentIdCtrl').setValue(null);
        if (this.licenseForm.controls['jurisdictionCtrl'].value === 'State Operator' && this.memberType === MemberType.BUSINESS) {
            this.licenseFileRequired = true;
        }
    }

    getButtonText() {
        if (this.existingLicense) {
            return 'Update License';
        }
        return 'Add License';
    }

}
