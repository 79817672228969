import { Upload } from './upload';

export class License {

    id: string;
    created: Date;

    memberId: string;

    jurisdiction: string;
    regulatoryBody: string;
    type: string;
    segment: string;
    classification: string;

    licenseNo: string;
    license: string;
    holderName: string;
    note: string;

    documentId: string;
    document: Upload;
    expirationDate: Date;
    status: LicenseStatus;

    msaEnabled: boolean;
    msaStartDate: string;
    msaExpirationDate: string;
    msaDocumentId: string;
    msaDocument: Upload;
    msaKeyPartyId: string;
    msaScope: string;

    // ad hoc fields
    msaExpired: boolean;
    msaKeyPartyName: string;
}


export enum LicenseStatus {
    ACTIVE = 'ACTIVE',
    DISABLED = 'DISABLED',
    EXPIRED = 'EXPIRED'
}
