import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import {
    Address, AddressService, MemberAccount, MemberAccountStatus, MemberAccountType, MemberStatus, MemberType, PagedResponse, RiskTier, SearchService, Utils, WorkflowService
} from 'projects/services/src/public-api';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pt-member-account-search-results',
    templateUrl: './member-account-search-results.component.html',
    styleUrls: ['./member-account-search-results.component.scss']
})
export class MemberAccountSearchResultsComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

    MemberAccountStatus = MemberAccountStatus;
    MemberStatus = MemberStatus;
    MemberType = MemberType;
    RiskTier = RiskTier;
    Utils = Utils;

    @Input() searchTerm: string;

    filterEvent: EventEmitter<null> = new EventEmitter<null>();
    isLoadingResults = true;
    resultsLength = 0;
    subscription: any;
    pageTracking: PageTracking;
    defaultSize = 10;
    displayedColumns = ['account_owner', 'account_info', 'contact_info', 'status', 'created'];
    memberAccounts: MemberAccount[] = [];

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private addressService: AddressService,
                private workflowService: WorkflowService,
                private searchService: SearchService,
                private cdr: ChangeDetectorRef) { }

    ngOnInit() {
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'member.sortName', 'asc', this.defaultSize);
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.searchTerm && this.pageTracking) {
            this.filterEvent.emit();
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.paginator.page, this.filterEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                return this.searchService.searchMemberAccountsBySearchTerm(this.searchTerm, [MemberAccountType.BUSINESS],
                    this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<MemberAccount>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((response: MemberAccount[]) => {
            this.memberAccounts = response;
            for (const member of this.memberAccounts) {
                this.loadMemberAccount(member);
            }
        });
    }

    loadMemberAccount(memberAccount: MemberAccount) {
        this.addressService.getPrimaryAddress(memberAccount.member.id).subscribe((location: Address) => {
            memberAccount.member.address = location;
        });
        if (memberAccount.status !== MemberAccountStatus.ACTIVE) {
            memberAccount.member.status = MemberStatus[memberAccount.status];
        }
    }

    memberDetails(event: any, memberAccount: MemberAccount) {
        if (event.target && event.target.tagName !== 'I') {
            this.router.navigate(['/administration/member/', memberAccount.memberId]);
        }
    }

    memberAccountDetails(event: any, memberAccount: MemberAccount) {
        if (event.target && event.target.tagName !== 'I') {
            this.router.navigate(['/member-accounts/details/', memberAccount.id]);
        }
    }
}
