import { GQLQueryType } from 'projects/services/src/lib/graphql/queries/gql-query-type';

export const gqlTransactionFields = `id, transactionType, state, status, checkId, transactionDate, totalAmount, payorMemberId, 
    payorMemberName, recipientMemberName, recipientMemberId,
    check{
        checkNumber
    }
`;

export abstract class GqlTransactionQueries {

    static findById(): GQLQueryType {
        return {
            params() {
                return '$id: ID!';
            },
            query(selectedFields: String = gqlTransactionFields): String {
                return `transactionById(id: $id){
                        ${selectedFields}
                }`;
            }
        };
    }
}