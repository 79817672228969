<div class="row col-12" *ngIf="!isUpdatedTerms">
    <span class="form-title">Application Submission</span>
</div>
<div class="row">
    <div class="col-12" *ngIf="isUpdatedTerms">
        <strong>Confia Financial is moving to a new banking infrastructure.  As such, some of our terms of service have changed.  Please review and accept the new terms of service.</strong>
    </div>
    <div class="col-12" *ngIf="!isUpdatedTerms">
        <div class="mt-3 warning" *ngIf="isMemberCompletionRequired()">
            Additional information is required.  Please review anything marked with a<i class="fa fa-onboard fa-question-circle"></i>.
        </div>
        <div class="mt-3">
            As part of Confia Financial’s onboarding and Account opening process, you hereby authorize and agree to submit for review by Confia Financial, any and all necessary documentation required to comply with Confia Financial’s Bank Secrecy Act/Anti-Money Laundering Program and the USA Patriot Act (among other regulations).
        </div>
        <div class="mt-3">
            <div>
               <pc-simple-checkbox [class]="termsForm.get('memberTruthfulCtrl').valid ? '' : 'required'" [label]="'I warrant that I have answered truthfully and not withheld any requested information about myself and the Applicant'" [formCtrl]="'memberTruthfulCtrl'" [formGroup]="termsForm" [invert]="true">
                </pc-simple-checkbox>

                <pc-simple-checkbox *ngIf="isATMBusiness" [class]="termsForm.get('atmPaymentProcessorAccessGrantedCtrl').valid ? '' : 'required'" [label]="'We will give access to our ATM payment processor system showing daily balances'" [formCtrl]="'atmPaymentProcessorAccessGrantedCtrl'" [formGroup]="termsForm" [invert]="true">
                </pc-simple-checkbox>

                <pc-simple-checkbox *ngIf="isATMBusiness" [class]="termsForm.get('atmSiteOwnerContractAccessGrantedCtrl').valid ? '' : 'required'" [label]="'We warrant that the form of contract provided to Confia Financial applies to our location agreements. Any material deviation from those contract terms will be disclosed to Confia Financial'" [formCtrl]="'atmSiteOwnerContractAccessGrantedCtrl'" [formGroup]="termsForm" [invert]="true">
                </pc-simple-checkbox>

                <pc-simple-checkbox *ngIf="isATMBusiness" [class]="termsForm.get('atmWithdrawalOnlyAcknowledgedCtrl').valid ? '' : 'required'" [label]="'We warrant that our ATMs are withdrawal only (i.e. we do not accept cash)'" [formCtrl]="'atmWithdrawalOnlyAcknowledgedCtrl'" [formGroup]="termsForm" [invert]="true">
                </pc-simple-checkbox>

                <pc-simple-checkbox [class]="termsForm.get('ownersCompleteCtrl').valid ? '' : 'required'" [label]="'I have included information about all Control Persons, Directors, Officers and all owners who have at least 15% ownership of the Applicant'" [formCtrl]="'ownersCompleteCtrl'" [formGroup]="termsForm" [invert]="true">
                </pc-simple-checkbox>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="mt-3">
            <strong>By {{isUpdatedTerms ? 'updating' : 'submitting'}} this application for an Account: (i) you provide Confia Financial, its affiliates and agents with explicit consent to process your application, and (ii) you hereby agree that your application, and if approved, your Account, will be governed by the Member Account Terms and Conditions and the Platform Use Agreement.</strong>
        </div>
        <div class="mt-3">
            <div>
                <a href="/static/Confia_Financial_Client_Agreement_04-2024.pdf" target="_blank" rel="noopener noreferrer" class="small"><i class="fa fa-paperclip"></i> Confia Financial Member Account Terms and Conditions</a>
                <pc-simple-checkbox [class]="termsForm.get('memberTermsCtrl').valid ? '' : 'required'" [label]="'I have read and accept the Member Account Terms and Conditions'" [formCtrl]="'memberTermsCtrl'" [formGroup]="termsForm" [invert]="true">
                </pc-simple-checkbox>
                <a href="https://www.confia.fi/platform-use/" target="_blank" rel="noopener noreferrer" class="small"><i class="fa fa-external-link"></i> Confia Financial Platform Use Agreement</a>
                <pc-simple-checkbox [class]="termsForm.get('platformTermsCtrl').valid ? '' : 'required'" [label]="'I have read and accept the Platform Use Agreement'" [formCtrl]="'platformTermsCtrl'" [formGroup]="termsForm" [invert]="true">
                </pc-simple-checkbox>
                <a href="https://www.confia.fi/online-privacy-policy/" target="_blank" rel="noopener noreferrer" class="small"><i class="fa fa-external-link"></i> Confia Financial Privacy Policy</a> | <a href="https://www.confia.fi/privacy-notice/" target="_blank" rel="noopener noreferrer" class="small"><i class="fa fa-external-link"></i> Confia Financial Privacy Notice</a>
                <pc-simple-checkbox [class]="termsForm.get('privacyTermsCtrl').valid ? '' : 'required'" [label]="'I have read and acknowledge the Confia Financial Privacy Notice and Privacy Policy.'" [formCtrl]="'privacyTermsCtrl'" [formGroup]="termsForm" [invert]="true">
                </pc-simple-checkbox>
                <a href="https://confia.fi/mobile-end-user-license-agreement/" target="_blank" rel="noopener noreferrer" class="small"><i class="fa fa-external-link"></i> Mobile End User License Agreement</a>
                <pc-simple-checkbox [class]="termsForm.get('mobileAgreementCtrl').valid ? '' : 'required'" [label]="'I have read and accept the Mobile End User License Agreement'" [formCtrl]="'mobileAgreementCtrl'" [formGroup]="termsForm" [invert]="true">
                </pc-simple-checkbox>
                <a href="/static/Remote_Deposit_Terms_04-2024.pdf" target="_blank" rel="noopener noreferrer" class="small"><i class="fa fa-paperclip"></i> Remote Deposit Capture</a>
                <pc-simple-checkbox [class]="termsForm.get('remoteDepositCtrl').valid ? '' : 'required'" [label]="'I have read and accept the Remote Deposit Capture'" [formCtrl]="'remoteDepositCtrl'" [formGroup]="termsForm" [invert]="true">
                </pc-simple-checkbox>
                <a href="https://confia.fi/esign-consent/)" target="_blank" rel="noopener noreferrer" class="small"><i class="fa fa-external-link"></i> E-sign Disclosure and Consent</a>
                <pc-simple-checkbox [class]="termsForm.get('eSignCtrl').valid ? '' : 'required'" [label]="'I have read and accept the E-sign Disclosure and Consent'" [formCtrl]="'eSignCtrl'" [formGroup]="termsForm" [invert]="true">
                </pc-simple-checkbox>
                <a href="/static/E-Statement_Disclosure_04-2024.pdf" target="_blank" rel="noopener noreferrer" class="small"><i class="fa fa-paperclip"></i> E-statement</a>
                <pc-simple-checkbox [class]="termsForm.get('eStatementCtrl').valid ? '' : 'required'" [label]="'I have read and accept the E-statement'" [formCtrl]="'eStatementCtrl'" [formGroup]="termsForm" [invert]="true">
                </pc-simple-checkbox>
            </div>
        </div>
        <div *ngIf="isReferredByPartner()">
            <div class="mt-5">
                <strong>To help expedite your onboarding: If your Account Application is successful, Confia Financial will help attach your account to "{{referringMember.name}}", the referring partner that invited you to the Confia Financial Platform.</strong>
            </div>
            <div class="mt-5">
                <div>
                    <pc-simple-checkbox [class]="termsForm.get('partnerAttachTermsCtrl').valid ? '' : 'required'" [label]="'Upon a successful Account Application,  I allow “' + referringMember.name + '“ to facilitate transactions on behalf of ”' + memberName + '”, according to our agreement with “' + referringMember.name  + '“.'" [formCtrl]="'partnerAttachTermsCtrl'" [formGroup]="termsForm" [invert]="true">
                    </pc-simple-checkbox>
                </div>
            </div>
        </div>
        <div class="mt-5">
            <strong>The Applicant represents and warrants that the undersigned representative of the Applicant has the authority to bind the Applicant to the terms of the aforementioned agreements and to {{isUpdatedTerms ? 'update' : 'submit'}} this application:</strong>
        </div>
        <div class="mt-5 mb-3">
            <div>
                <pc-form-text-input [required]="true" [label]="'Signature (type name):'" [placeholder]="'full name'"
                    [formCtrl]="'nameCtrl'" [formGroup]="termsForm" [colSize]="6"></pc-form-text-input>
            </div>
            <div>
                <pc-form-text-input [required]="true" [label]="'Title:'" [placeholder]="'title'"
                    [formCtrl]="'titleCtrl'" [formGroup]="termsForm" [colSize]="6"></pc-form-text-input>
            </div>
        </div>
        <div class="button-panel pull-right" *ngIf="!isUpdatedTerms">
            <pc-submit-button class="pull-right" [enabled]="termsAccepted" [submit]="onComplete" [text]="'Submit'"></pc-submit-button>
        </div>

        <div class="mt-5 mb-3 col-12" *ngIf="isUpdatedTerms">
            <strong>If you do not agree to the new terms of service or wish to close your account, please contact us at clientservices@confia.io</strong>
        </div>
        <div class="button-panel pull-right" *ngIf="isUpdatedTerms">
            <pc-submit-button [enabled]="termsAccepted" [submit]="acceptUpdatedTerms" [text]="'Accept Terms'"></pc-submit-button>
            <pc-submit-button [submit]="cancel" [text]="'Cancel'"></pc-submit-button>
        </div>
    </div>
</div>
