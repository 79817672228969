import { AccountingSupplier } from './accounting-supplier';

export class AccountingSupplierSummary {

    pageNumber: number;
    pageSize: number;
    totalResults: number;
    results: AccountingSupplier[];
    totalOwed: number;
    noBillsOutstanding: number;
    daysPayableOutstanding: number;

}
