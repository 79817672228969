import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import {
    Configuration, KeyParty, KeyPartyService, KeyPartyStatus, KeyPartyType, MfaType, PagedResponse, Role, User, UserAccount, UserAccountService, UserFormService,
    UserService, WorkflowService
} from 'projects/services/src/public-api';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { ErrorType } from 'projects/components/src/public-api';

@Component({
    selector: 'pc-new-user-modal',
    templateUrl: './new-user-modal.component.html'
})
export class NewUserModalComponent extends BaseModalComponent<NewUserModalComponent> implements OnInit {

    Role = Role;

    memberId: string;
    memberAccountId: string;
    role: Role;
    newUserForm: UntypedFormGroup;
    errorMessage: { message: string, type: ErrorType };
    unAssignedKeyParties: KeyParty[];

    mfaTypes = [{
        id: MfaType.SMS,
        name: 'SMS Text Message'
    }, {
        id: MfaType.TOTP,
        name: 'Time-based Token (TOTP)'
    }];

    constructor(private userAccountService: UserAccountService,
                private userFormService: UserFormService,
                private workflowService: WorkflowService,
                private userService: UserService,
                private notifier: NotificationService,
                private keyPartyService: KeyPartyService,
                dialog: MatDialogRef<NewUserModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialog);
        this.memberId = data.memberId;
        this.role = data.role;
        this.memberAccountId = data.memberAccountId || null;
    }

    ngOnInit() {
        this.isNewUserFormValid = this.isNewUserFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        if (this.role === Role.CORPORATE_ADMIN) {
            this.keyPartyService.getAllKeyPartiesByType(this.memberId, KeyPartyType.INDIVIDUAL).subscribe((response: PagedResponse<KeyParty>) => {
                this.unAssignedKeyParties = response.content.filter((keyParty) => {
                    return keyParty.status === KeyPartyStatus.ACTIVE && keyParty.userId === null;
                });
                this.createNewUserForm(true);
                if (this.unAssignedKeyParties.length === 0) {
                    this.errorMessage = {
                        message: 'There are no key parties that are not already assigned to a user.  Please add a key party for corporate admin access.',
                        type: ErrorType.ERROR
                    };
                    this.newUserForm.disable();
                }
            });
        } else {
            this.createNewUserForm(false);
        }
    }

    createNewUserForm(keyPartyLinkRequired: boolean) {
        this.newUserForm = this.userFormService.newUserForm(this.memberId, this.memberAccountId, keyPartyLinkRequired);
        if (this.isMfaEnabled()) {
            this.newUserForm.patchValue({
                mfaCtrl: MfaType.SMS
            });
            this.newUserForm.get('mfaCtrl').setValidators([Validators.required]);
        } else {
            this.newUserForm.patchValue({
                mfaCtrl: MfaType.NONE
            });
        }
    }

    close(refresh?: boolean) {
        super.close(refresh);
    }

    onSubmit(reset: any) {
        this.errorMessage = null;
        const newUserRequest = this.userFormService.getNewUserRequest(this.newUserForm, this.role);
        this.workflowService.registerUser(newUserRequest).subscribe(() => {
            this.close(true);
            this.notifier.showSuccess(`An invitation has been sent to <b>${newUserRequest.firstName + ' ' + newUserRequest.lastName}</b> at <b>${newUserRequest.email}</b>.`);
        }, (errorResult: any) => {
            reset();
            if (errorResult.status === 409 || errorResult.status === 400) {
                this.errorMessage = {
                    message: errorResult.error,
                    type: ErrorType.ERROR
                };
            } else if (errorResult.status === 429) {
                this.close();
                throw errorResult;
            } else {
                throw errorResult;
            }
        });
    }

    isMfaEnabled() {
        return Configuration.getConfig().mfaEnabled;
    }

    isNewUserFormValid() {
        return this.newUserForm.valid && this.errorMessage?.type !== ErrorType.ERROR;
    }

    onEmailChange(email: string) {
        this.errorMessage = null;
        if (this.newUserForm.get('emailCtrl').valid) {
            this.newUserForm.disable();
            this.userService.searchUsers(email).subscribe((response: PagedResponse<User>) => {
                if (response.totalElements > 0) {
                    const existingUser = response.content[0];
                    this.newUserForm.patchValue({
                        userIdCtrl: existingUser.id,
                        firstNameCtrl: existingUser.firstName,
                        middleNameCtrl: existingUser.middleName,
                        lastNameCtrl: existingUser.lastName,
                        mobilePhoneCtrl: existingUser.mobilePhone
                    }, {emitEvent: true});
                    this.userAccountService.getAllUserAccountsByMemberId(this.memberId).subscribe((accountResponse: PagedResponse<UserAccount>) => {
                        let existingUserAccount: UserAccount;
                        for (const account of accountResponse.content) {
                            if (account.userId === existingUser.id) {
                                existingUserAccount = account;
                            }
                        }
                        if (existingUserAccount) {
                            this.newUserForm.patchValue({
                                titleCtrl: existingUserAccount.position
                            }, {emitEvent: true});
                            this.errorMessage = {
                                message: 'This email is already in use as a user for this member.',
                                type: ErrorType.ERROR
                            };
                            this.newUserForm.controls['emailCtrl'].enable({onlySelf: true});
                            this.newUserForm.markAsPristine();
                        } else {
                            this.handleExistingUserNotLinkedToMember();
                        }
                    });
                } else {
                    this.handleNewUserFromEmail();
                }
            });
        }
    }

    clearForm() {
        this.newUserForm.patchValue({
            userIdCtrl: null,
            firstNameCtrl: null,
            middleNameCtrl: null,
            lastNameCtrl: null,
            titleCtrl: null,
            mobilePhoneCtrl: null,
            officePhoneCtrl: null
        }, {emitEvent: true});
    }

    handleNewUserFromEmail() {
        this.clearForm();
        this.newUserForm.enable({emitEvent: true});
    }

    handleExistingUserNotLinkedToMember() {
        this.newUserForm.patchValue({
            titleCtrl: null
        }, {emitEvent: true});
        this.newUserForm.controls['titleCtrl'].enable({onlySelf: true});
        this.newUserForm.controls['emailCtrl'].enable({onlySelf: true});
        this.errorMessage = {
            message: 'This user already exists with another member(s). Are you sure?',
            type: ErrorType.WARN
        };
    }

    getTitle() {
        if (this.role === Role.AUTHORITY_READONLY) {
            return 'Add New Authority';
        } else if (this.role === Role.CORPORATE_ADMIN || this.role === Role.NEW_ADMIN_REGISTRANT) {
            return 'Add New Corporate Admin';
        }
        return 'Add New User';
    }
}
