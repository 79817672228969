import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import { AuthService } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-sidebar',
    templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {

    @Input() expandedMenu = true;

    @Output() toggleExpandSideMenu: EventEmitter<any> = new EventEmitter<any>();

    constructor(public authService: AuthService) {}

    @HostListener('window:resize', ['$event'])
    getScreenSize(_event? : any) {
        if (window.innerWidth < 1025) {
            if (this.expandedMenu) {
                this.expandedMenu = false;
                this.onToggleExpandSideMenu();
            }
        } else {
            if (!this.expandedMenu){
                this.expandedMenu = true;
                this.onToggleExpandSideMenu();
            }
        }
    }

    ngOnInit() {
        this.getScreenSize();
    }

    onToggleExpandSideMenu() {
        this.toggleExpandSideMenu.emit('toggle');
    }
}
