import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import {
    AccountingPartyType,
    Bill, BusinessClient, ExternalAccountingId, Invoice, MemberAccount, MemberType, MerchantAccount, MerchantAccountService, PagedResponse, RecordsService, SupportingDocument, TransactionEntrySelection, TransactionSubType, Utils
} from 'projects/services/src/public-api';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTable } from '@angular/material/table';
import { ErrorType } from 'projects/components/src/public-api';
import { forkJoin, of } from 'rxjs';

@Component({
    selector: 'pt-transaction-entry-selection-step',
    templateUrl: './transaction-entry-selection-step.component.html',
    styleUrls: ['./transaction-entry-selection-step.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class TransactionEntrySelectionStepComponent implements OnInit, OnChanges {

    TransactionSubType = TransactionSubType;

    @Input() memberAccount: MemberAccount;
    @Input() currentBusinessClient: BusinessClient<Invoice | Bill>;
    @Input() isAccountingLinked = false;
    @Input() isCashDeposit = false;
    @Input() subType: TransactionSubType;
    @Input() availableBalance: number;
    @Input() achDirectPaymentEnabled = false;
    @Input() isUniversalWhiteListedMerchant = false;

    @Output() transactionEntriesSelected: EventEmitter<TransactionEntrySelection<Invoice | Bill>[]> = new EventEmitter();
    @Output() nextStep: EventEmitter<null> = new EventEmitter<null>();

    transactionEntriesLoading = false;
    accountingExternalIds: ExternalAccountingId[] = [];
    customerTransactionEntries: TransactionEntrySelection<Invoice | Bill>[] = [];
    documentUploadContext: Invoice | Bill;
    errorMessage: { message: string, type: ErrorType };

    entryHeaders: string[] = ['entry_number', 'entry_date', 'entry_due_date', 'entry_unpaid_amount', 'entry_paid_amount', 'entry_included', 'entry_document'];
    entryColumns: string[] = ['entry_number', 'entry_date', 'entry_due_date', 'entry_unpaid_amount', 'entry_paid_amount', 'entry_included', 'empty'];
    otherEntryColumns: string[] = ['other_entry_description_label', 'other_entry_description', 'other_entry_amount_label', 'other_entry_amount', 'other_entry_include', 'other_entry_document'];
    summaryColumns: string[] = ['empty', 'empty', 'empty', 'entry_unpaid_amount', 'entry_paid_amount', 'empty', 'summary_add_row'];

    expandedElement: TransactionEntrySelection<Invoice | Bill>;

    @ViewChild('transactionEntriesTable') table: MatTable<any>;
    @ViewChild('upload') uploadLink: ElementRef;

    constructor(private recordsService: RecordsService,
                private merchantAccountService: MerchantAccountService) {}

    ngOnInit() {
        this.next = this.next.bind(this);
        this.transactionEntriesValid = this.transactionEntriesValid.bind(this);
        this.merchantAccountService.getAllMerchantAccountsByMerchantMemberIdAndMemberId(this.currentBusinessClient.memberId, this.memberAccount.memberId).subscribe((merchantAccounts : PagedResponse<MerchantAccount>) => {
            if (merchantAccounts.content?.length > 0 && merchantAccounts.content[0]?.accountingExternalIds?.length > 0) {
                this.accountingExternalIds = merchantAccounts.content[0]?.accountingExternalIds;
            }
            this.loadMatchingTransactionEntries();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.currentBusinessClient && !changes.currentBusinessClient.firstChange) {
            this.merchantAccountService.getAllMerchantAccountsByMerchantMemberIdAndMemberId(this.currentBusinessClient.memberId, this.memberAccount.memberId).subscribe((merchantAccounts : PagedResponse<MerchantAccount>) => {
                if (merchantAccounts.content?.length > 0 && merchantAccounts.content[0]?.accountingExternalIds?.length > 0) {
                    this.accountingExternalIds = merchantAccounts.content[0]?.accountingExternalIds;
                }
                this.loadMatchingTransactionEntries();
            });
        }
    }

    loadMatchingTransactionEntries() {
        this.errorMessage = null;
        this.transactionEntriesLoading = true;
        this.customerTransactionEntries = [];

        if (this.isAccountingLinked && this.currentBusinessClient) {
            let observable;
            let observables: any[] = [];
            if (this.subType === TransactionSubType.DEPOSIT) {
                if (this.isAccountingMerchant()) {
                    for (const entry of this.accountingExternalIds) {
                        if (entry.accountingPartyType === AccountingPartyType.CUSTOMER) {
                            observables.push(this.recordsService.findInvoicesByOwnerAndCustomer(this.memberAccount.memberId, entry.accountingExternalId));
                        }
                    }
                } else if (this.currentBusinessClient.type !== MemberType.INDIVIDUAL_MERCHANT && this.currentBusinessClient.type !== MemberType.BUSINESS_MERCHANT) {
                    observables.push(this.recordsService.findInvoicesByOwnerAndBusinessClient(this.memberAccount.memberId, this.currentBusinessClient.memberId));
                }
            } else if (this.subType === TransactionSubType.PAYMENT) {
                if (this.isAccountingMerchant()) {
                    for (const entry of this.accountingExternalIds) {
                        if (entry.accountingPartyType === AccountingPartyType.SUPPLIER) {
                            observables.push(this.recordsService.findExpensesByOwnerAndVendor(this.memberAccount.memberId, entry.accountingExternalId));
                        }
                    }
                } else if (this.currentBusinessClient.type !== MemberType.INDIVIDUAL_MERCHANT && this.currentBusinessClient.type !== MemberType.BUSINESS_MERCHANT) {
                    observables.push(this.recordsService.findExpensesByOwnerAndBusinessClient(this.memberAccount.memberId, this.currentBusinessClient.memberId));
                }
            }

            if (observables.length === 0) {
                observables.push(of(new PagedResponse()));
            }
            observable = forkJoin(observables);
            observable.subscribe((eligibleEntries: PagedResponse<Invoice | Bill>[]) => {
                const entriesByClient = this.currentBusinessClient.transactionEntries;
                for (const eligibleEntry of eligibleEntries) {
                    for (const entry of eligibleEntry.content) {
                        const entryToAdd = new TransactionEntrySelection<Invoice | Bill>();
                        entryToAdd.data = entry;
                        entryToAdd.data.memberAccountId = this.currentBusinessClient.memberAccountId;
                        entryToAdd.data.memberName = this.currentBusinessClient.name;
                        const selectedEntry = entriesByClient.find((selection: TransactionEntrySelection<Invoice | Bill>) => {
                            return selection.data.externalId === entry.externalId;
                        });
                        if ((this.subType === TransactionSubType.DEPOSIT && entry.type !== 'PAYMENT') || this.subType === TransactionSubType.PAYMENT) {
                            if (selectedEntry) {
                                entry.paidAmount = Number(selectedEntry.data.paidAmount).toFixed(2);
                                entry.supportingDocuments = selectedEntry.data.supportingDocuments;
                                if (entry.unpaidAmount === null) {
                                    entry.unpaidAmount = Number(entry.totalAmount);
                                }
                                entryToAdd.included = true;
                                this.customerTransactionEntries.push(entryToAdd);
                            } else {
                                entry.supportingDocuments = [];
                                if (entry.unpaidAmount === null) {
                                    entry.unpaidAmount = Number(entry.totalAmount);
                                }
                                if (entry.unpaidAmount !== null && Number(entry.unpaidAmount) > 0) {
                                    entry.paidAmount = Number(entry.unpaidAmount).toFixed(2);
                                    this.customerTransactionEntries.push(entryToAdd);
                                }
                            }
                        }
                    }
                }

                let otherEntriesPresent = false;
                entriesByClient.forEach((otherEntry: TransactionEntrySelection<Invoice | Bill>) =>  {
                    if (otherEntry.data.type === 'OTHER') {
                        this.customerTransactionEntries.push(otherEntry);
                        otherEntriesPresent = true;
                    }
                });
                if (!otherEntriesPresent) {
                    this.addOtherEntry();
                }
                this.calculateTotal();
                this.transactionEntriesLoading = false;
            },
            (_error: any) => {
                this.addOtherEntry();
                this.transactionEntriesLoading = false;
                this.errorMessage = { type: ErrorType.ERROR, message: `Unable to load ${this.subType === TransactionSubType.DEPOSIT ? 'invoices' : 'bills'} at this time. Please try again later.`};
            });
        } else {
            this.currentBusinessClient.transactionEntries.forEach((otherEntry) =>  {
                if (otherEntry.data.type === 'OTHER') {
                    this.customerTransactionEntries.push(otherEntry);
                }
            });
            this.addOtherEntry();
            this.calculateTotal();

        }
    }

    isAccountingMerchant() {
        return (this.currentBusinessClient.type === MemberType.INDIVIDUAL_MERCHANT || this.currentBusinessClient.type === MemberType.BUSINESS_MERCHANT) && this.accountingExternalIds?.length > 0;
    }

    calculateTotal() {
        let runningCustomerTotal = '0.00';
        for (const entry of this.customerTransactionEntries) {
            if (entry.included && entry.data.paidAmount) {
                runningCustomerTotal = (Number(runningCustomerTotal) + Number(entry.data.paidAmount)).toFixed(2);
            }
        }
        this.currentBusinessClient.selectedTotal = runningCustomerTotal;
    }

    addOtherEntry() {
        const otherAmount = new TransactionEntrySelection<Invoice | Bill>();
        otherAmount.data = this.subType === TransactionSubType.DEPOSIT ? this.newInvoice() : this.newBill();
        this.customerTransactionEntries.push(otherAmount);
        this.transactionEntriesLoading = false;
    }

    newInvoice(): Invoice {
        const invoice = new Invoice();
        invoice.type = 'OTHER';
        invoice.txnDate = new Date();
        invoice.supportingDocuments = [];
        invoice.customerExternalId = this.currentBusinessClient.accountingId || this.currentBusinessClient.memberAccountId;
        invoice.memberAccountId = this.currentBusinessClient.memberAccountId;
        invoice.customerName =  this.currentBusinessClient.name;
        return invoice;
    }

    newBill(): Bill {
        const bill = new Bill();
        bill.type = 'OTHER';
        bill.txnDate = new Date();
        bill.supportingDocuments = [];
        bill.vendorExternalId = this.currentBusinessClient.accountingId || this.currentBusinessClient.memberAccountId;
        bill.memberAccountId = this.currentBusinessClient.memberAccountId;
        bill.vendorName =  this.currentBusinessClient.name;
        return bill;
    }

    notifyTransactionEntryChange() {
        const transactionEntries: TransactionEntrySelection<Invoice | Bill>[] = [];
        for (const entry of this.customerTransactionEntries) {
            if (entry.included && entry.data.paidAmount) {
                transactionEntries.push(entry);
            }
        }
        this.transactionEntriesSelected.emit(transactionEntries);

    }

    addNewTransactionEntryRow() {
        this.addOtherEntry();
        this.table.renderRows();
    }

    onSelected(entry: TransactionEntrySelection<Invoice | Bill>) {
        entry.included = !entry.included;
        this.calculateTotal();
        this.notifyTransactionEntryChange();
    }

    onEntryChange(transactionEntry: TransactionEntrySelection<Invoice | Bill>) {
        transactionEntry.validate();
        if (transactionEntry.data.type === 'OTHER') {
            transactionEntry.data.paidAmount = Utils.formatNonNegativeCurrency(transactionEntry.data.paidAmount, true);
            transactionEntry.included = (Number(transactionEntry.data.paidAmount) > 0);
        }
        this.calculateTotal();
        this.notifyTransactionEntryChange();
    }

    next() {
        this.nextStep.emit();
    }

    removeOtherAmount(index: number) {
        this.customerTransactionEntries.splice(index, 1);
        this.calculateTotal();
        this.table.renderRows();
        this.notifyTransactionEntryChange();
    }

    addSupportingDocument(entry: Invoice | Bill) {
        this.documentUploadContext = entry;
        const link: HTMLElement = this.uploadLink.nativeElement;
        link.click();
    }

    removeSupportingDocument(entry: Invoice | Bill, index: number) {
        entry.supportingDocuments.splice(index, 1);
        this.notifyTransactionEntryChange();
    }

    selectFile(event: any) {
        if (event.target.files && event.target.files.length) {
            const file = event.target.files[0];
            Utils.validateFile(file, event);
            const supportingDocument = new SupportingDocument();
            supportingDocument.file = file;
            this.documentUploadContext.supportingDocuments.push(supportingDocument);
        }
        event.target.value = '';
        this.documentUploadContext = null;
    }

    transactionEntriesValid() {
        let entriesIncluded = false;
        for (const entry of this.customerTransactionEntries) {
            if (entry.data.type === 'OTHER' && Number(entry.data.paidAmount) > 0 && entry.included) {
                if (!this.isUniversalWhiteListedMerchant && (!entry.data.supportingDocuments.length || !this.isDescriptionValid(entry))) {
                    return false;
                }
                entriesIncluded = true;
            } else if (entry.data.type !== 'OTHER' && entry.included && entry.error) {
                return false;
            } else if (entry.included) {
                entriesIncluded = true;
            }
        }
        if (this.subType === TransactionSubType.PAYMENT) {
            if (this.achDirectPaymentEnabled) {
                return entriesIncluded;
            } else {
                return entriesIncluded && Number(this.currentBusinessClient.selectedTotal) <= this.availableBalance;
            }
        }
        return entriesIncluded;
    }

    isEntryFromAccounting(_index: number, entry: TransactionEntrySelection<Invoice | Bill>) {
        return entry.data.type !== 'OTHER';
    }

    isOtherEntry(_index: number, entry: TransactionEntrySelection<Invoice | Bill>) {
        return entry.data.type === 'OTHER';
    }

    isDescriptionValid(element: any) {
        return element.data.description && element.data.description.trim().length > 1;
    }

}
