import { AccountingCustomer } from './accounting-customer';

export class AccountingCustomerSummary {

    pageNumber: number;
    pageSize: number;
    totalResults: number;
    results: AccountingCustomer[];
    totalOwed: number;
    noInvoicesOutstanding: number;
    daysSalesOutstanding: number;

}
