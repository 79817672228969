import { Builder } from 'projects/services/src/lib/utils/builder';
import { GQLQueryType } from 'projects/services/src/lib/graphql/queries/gql-query-type';
import { gql } from 'apollo-angular';

const defaultPageVariables = '$start: Int, $size: Int, $sort: String, $sortDir: String';
const defaultPageParams = 'start: $start, size: $size, sort: $sort, sortDir: $sortDir';
const pageFields = 'totalElements, totalPages, number, empty, first, last';

/**
 * Builder specialized in build [@GQLQueryType](projects/services/src/lib/graphql/queries/gql-query-type) query implementations.
 * The result of the builder is used by the [GqlService](projects/services/src/lib/graphql/gql.service.ts to request the
 * graphql server.
 */
export class GqlQueryBuilder implements Builder<any> {

    private queryType: GQLQueryType;
    private fields: String;
    private isPaginated: boolean = false;
    private alias: String = 'result: ';
    private pageFragment: String = `fragment pageFields on Page {${pageFields}}`;

    private constructor(queryType: GQLQueryType) {
        this.queryType = queryType;
    }

    /**
     * create an instance of this builder.
     * @param queryType - the query we implemented and want to be built.
     */
    static builder(queryType: GQLQueryType) : GqlQueryBuilder {
        if (queryType === null) {
            throw new Error('invalid query type provided');
        }

        return new GqlQueryBuilder(queryType);
    }

    /**
     * If we don't want the default fields defined in the [@GQLQueryType].
     * @param {String} fields - the list of the field in graphql format. Example: 'id, status, transaction{id, created}'
     */
    withFields(fields: String) {
        this.fields = fields;
        return this;
    }

    /**
     * If the query [@GQLQueryType] you implemented is paginated, we flag it with this methods so this builder will
     * be aple to config the params, arguments and fragments required by the graphql.
     */
    paginated() {
        this.isPaginated = true;
        return this;
    }

    /**
     * If the default Page type is not used for the query in use, we override the typeName so the query can find
     * the correct type to be used in the pageFields fragment
     * @param typeName
     */
    withPageFragmentType(typeName: String) {
        this.pageFragment = `fragment pageFields on ${typeName} {${pageFields}}`;
        return this;
    }

    /**
     * This join all the query chunks in a format graphql expects.
     *
     * @return {String} a full graphql query ready to be sent to a graphql server.
     */
    build() {
        const header = this.buildHeader();
        const typedQuery = this.buildQueryType();

        if (this.isPaginated) {
            return gql`
                ${this.pageFragment}
                
                ${header}{
                    ${typedQuery}
                }
            `;
        }

        return gql`
            ${header}{
                ${typedQuery}
            }
        `;
    }

    /**
     * Configure the query body of [@GQLQueryType].
     * - Add the alias to the query, so all the results will be "normalized"
     * - Dynamically add the paginated fields and arguments (if needed).
     * - The fragment ...pageFields with all the fields used by a paginated query.
     *
     * @private
     */
    private buildQueryType() {
        let result = `${this.alias}${this.queryType.query(this.fields, defaultPageParams)}`;

        if (this.isPaginated) {
            result = result.substring(0, result.length - 1);
            return `${result} 
                ...pageFields
            }`;
        }

        return result;
    }

    /**
     * Configure the query definition with all the params and paged params required by the  @GQLQueryType].
     * @return {String} - the graphql query definition
     */
    private buildHeader() {
        const params = this.queryType.params();

        if (!this.isPaginated && !params) {
            return 'query';
        }

        let result = 'query(';

        if (params) {
            result = `${result}${params}`;
        }

        if (this.isPaginated) {
            result = `${result}${params ? ', ' : ''}${defaultPageVariables}`;
        }

        return `${result})`;
    }
}
