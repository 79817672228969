<div>
    <form [formGroup]="keyPartyForm">
        <div>
            <div class="modal-header">
            <label class="form-title">{{ isExistingKeyParty ? 'Update Corporate Key Party' : 'New Corporate Key Party'}}</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content">
                <pc-error-message [error]="errorMessage" [width]="'full'"></pc-error-message>
                <div class="row">
                    <div class="col-12" *ngIf="authService.isAuthority() && !isExistingKeyParty" >
                        <pc-corporate-key-party-searcher [formGroup]="corporateKeyPartyForm" [label]="'Search Corporate Key Parties'" [formCtrl]="'idCtrl'"
                            [placeholder]="'Search corporate key parties by name or tax id'" [existingKeyPartyIds]="existingKeyPartyIds" (corporateKeyPartySelected)="setCorporateKeyParty($event)">
                        </pc-corporate-key-party-searcher >
                    </div>
                    <div class="col-6">
                        <span class="subtitle">Corporate Details</span>
                        <pc-text-input [required]="true" [label]="'Legal Business Name'" [placeholder]="'Legal Business Name'"
                            [formCtrl]="'nameCtrl'" [formGroup]="corporateKeyPartyForm">
                        </pc-text-input>
                        <pc-text-input [label]="'DBA Name'" [placeholder]="'DBA Name'"
                            [formCtrl]="'dbaNameCtrl'" [formGroup]="corporateKeyPartyForm">
                        </pc-text-input>
                        <pc-form-text-input [required]="true" [label]="'EIN/Tax Number'" [placeholder]="'numbers only (no hyphens)'" [formCtrl]="'taxIdCtrl'" [formGroup]="corporateKeyPartyForm"
                            [colSize]="6" [restrictToDigits]="true">
                        </pc-form-text-input>
                        <pc-text-option-selector [required]="true" [label]="'Business Type'"
                            [options]="businessTypeOpts" [formCtrl]="'businessTypeCtrl'" [formGroup]="corporateKeyPartyForm" [defaultValue]="''">
                        </pc-text-option-selector>
                        <pc-phone-input [required]="true" [label]="'Phone Number'"
                            [formCtrl]="'officePhoneCtrl'" [formGroup]="corporateKeyPartyForm">
                        </pc-phone-input>
                        <pc-email-input [label]="'Email Address'" [placeholder]="'Email Address'"
                            [formCtrl]="'emailCtrl'" [formGroup]="corporateKeyPartyForm">
                        </pc-email-input>
                        <pc-percentage-input [label]="'Ownership %'"
                            [formCtrl]="'percentOwnershipCtrl'" [formGroup]="keyPartyForm">
                        </pc-percentage-input>
                        <pc-simple-checkbox [label]="'License Holder'" [formCtrl]="'isLicenseHolderCtrl'"
                            [formGroup]="keyPartyForm" [invert]="true" (changed)="updateFormValidators()">
                        </pc-simple-checkbox>
                    </div>
                    <div class="col-6">
                        <span class="subtitle">Corporate Primary Address</span>
                        <pc-address-input [addressTypeOpts]="[AddressType.PRIMARY]" [addressForm]="addressForm"></pc-address-input>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <pc-submit-button [enabled]="isKeyPartyFormValid" [submit]="onSubmit" [text]="isExistingKeyParty ? 'Save Changes' : 'Add Key Party'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
