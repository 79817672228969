import { GQLQueryType } from 'projects/services/src/lib/graphql/queries/gql-query-type';

export const gqlUserFields = `
    id 
    firstName
    lastName
    email
`;

export const gqlMemberFields = `
    id
    memberType
    email
    status
    name
`;

export const gqlDocumentFields = `
    id
    created
    updated

    memberId
    authorId
    fileName
    referencedEntityId
    path
    uri
    explanation
    documentType
    documentHash
    version
    status
`;

export const gqlMessageFields = `
    id, created, updated, 
    memberId, senderId, recipientId, recipientMemberId, senderMemberId,
    documentId, subject, fileName, body, messageId, parentMessageId,
    readStatus,
    sender {
        ${gqlUserFields}
    }
    senderMember {
        ${gqlMemberFields}
    }
    recipient {
        ${gqlUserFields}
    }
    recipientMember {
        ${gqlMemberFields}
    }
    document {
        ${gqlDocumentFields}
    }
`;

export abstract class GqlMessageQueries {

    static gqlPageFragment = 'MessagePage';

    static searchMessages(): GQLQueryType {
        return {
            params() {
                return `$senderId: String, $type: String, $senderMemberId: String, $counterParty: String, 
                    $readStatus: Boolean, $startDate: Date, $endDate: Date, $timezone: String`;
            },
            query(selectedFields: String = gqlMessageFields, pageParams?: String): String {
                return `searchMessages(senderId: $senderId, type: $type, senderMemberId: $senderMemberId, 
                            counterParty: $counterParty, readStatus: $readStatus, startDate: $startDate, 
                            endDate: $endDate, timezone: $timezone, ${pageParams}){

                        content{
                            ... on Message {
                                ${selectedFields}
                            }
                        }
                }`;
            }
        };
    }

}