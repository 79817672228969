import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BaseModalComponent } from 'projects/components/src/public-api';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MemberAccount, PaymentProcessorNotification, PaymentProcessingService,
    PaymentProcessingNotificationsFormService } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-add-payment-processing-setting-modal',
    templateUrl: './add-payment-processing-setting-modal.component.html',
    styleUrls: ['./add-payment-processing-setting-modal.component.scss']
})
export class AddPaymentProcessingSettingModalComponent extends BaseModalComponent<AddPaymentProcessingSettingModalComponent> implements OnInit {

    @ViewChild('modalContent') modalContent: ElementRef;

    paymentProcessingSettingsForm: FormGroup;
    paymentProcessorSetting: PaymentProcessorNotification;
    isEdit: boolean;
    activeBusinessAccounts: MemberAccount[] = [];
    memberId = '';

    constructor(
        private dialogRef: MatDialogRef<AddPaymentProcessingSettingModalComponent>,
        private paymentProcessingSettingsFormService: PaymentProcessingNotificationsFormService,
        private paymentProcessorService: PaymentProcessingService,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        super(dialogRef);
        this.paymentProcessorSetting = data.paymentProcessorSetting || null;
        this.memberId = data.memberId;
        this.activeBusinessAccounts = data.accounts;
        this.isEdit = data.isEdit;
    }

    ngOnInit(): void {
        this.isSettingFormValid = this.isSettingFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.initializeForm();
    }

    private initializeForm(): void {
        this.paymentProcessingSettingsForm = this.paymentProcessingSettingsFormService.initializeForm(this.memberId, this.paymentProcessorSetting);
    }

    onSubmit(): void {
        if (this.paymentProcessingSettingsForm.valid) {
            if (this.isEdit) {
                const updatedSetting = this.paymentProcessingSettingsFormService.getUpdatedPaymentProcessorSetting(this.paymentProcessingSettingsForm);
                this.paymentProcessorService.updatePaymentProcessorSetting(this.paymentProcessorSetting.id, updatedSetting).subscribe((response: any) => {
                    this.close(response);
                });
            } else {
                const paymentProcessorsetting = this.paymentProcessingSettingsFormService.getPaymentProcessorSetting(this.paymentProcessingSettingsForm);
                this.paymentProcessorService.savePaymentProcessorSetting(paymentProcessorsetting).subscribe((response) => {
                    this.close(response);
                });
            }
        }
    }

    close(refresh?: any): void {
        super.close(refresh);
    }

    isSettingFormValid(): boolean {
        if (!this.paymentProcessingSettingsForm) {
            return false;
        }
        return this.paymentProcessingSettingsForm.valid;
    }

    onSelectSourceAccount(event: any): void {
        this.paymentProcessingSettingsForm.get('accountIdCtrl').setValue(event.id);
        this.paymentProcessingSettingsForm.updateValueAndValidity();
    }
}