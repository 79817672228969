import { Component } from '@angular/core';
import { BsaReport, Reconciliation, ReconciliationStatus, ReportStatus } from 'projects/services/src/public-api';
import { BaseBadgeComponent } from '../badge.component';

@Component({
    selector: 'pc-report-badge',
    templateUrl: '../badge.component.html',
    styleUrls: ['../badge.component.scss']
})
export class ReportBadgeComponent extends BaseBadgeComponent<Reconciliation | BsaReport> {

    setBadgeData() {
        this.badgeClass = 'large ';
        this.badgeToolTip = this.parent.status;
        switch (this.parent.status) {
            case ReconciliationStatus.GOOD:
                this.badgeText = 'GOOD';
                this.badgeClass += 'green-badge';
                break;
            case ReconciliationStatus.INVESTIGATE:
                this.badgeText = 'INVESTIGATE';
                this.badgeClass += 'red-badge';
                break;
            case ReportStatus.AUTHORITY_REVIEW:
                this.badgeText = 'Under Review';
                this.badgeClass += 'dark-grey-badge';
                break;
            case ReportStatus.READY_FOR_SUBMISSION:
                this.badgeText = 'Ready To Submit';
                this.badgeClass += 'dark-grey-badge';
                break;
            case ReportStatus.SUBMISSION_IN_PROGRESS:
                this.badgeText = 'In Progress';
                this.badgeClass += 'grey-badge';
                break;
            case ReportStatus.SUBMISSION_SUCCESSFUL:
                this.badgeText = 'Submitted';
                this.badgeClass += 'grey-badge';
                break;
            case ReportStatus.SUBMISSION_FAILED:
                this.badgeText = 'Failed';
                this.badgeClass += 'red-badge';
                break;
            case ReportStatus.ACCEPTED:
                this.badgeText = 'Accepted';
                this.badgeClass += 'grey-badge';
                break;
            case ReportStatus.ACCEPTED_WITH_WARNINGS:
                this.badgeText = 'Warnings';
                this.badgeClass += 'orange-badge';
                break;
            case ReportStatus.REJECTED:
                this.badgeText = 'Rejected';
                this.badgeClass += 'red-badge';
                break;
            case ReportStatus.ACKNOWLEDGED:
                this.badgeText = 'Completed';
                this.badgeClass += 'green-badge';
                break;
            case ReportStatus.ACKNOWLEDGEMENT_FAILED:
                this.badgeText = 'Failed';
                this.badgeClass += 'red-badge';
                break;
            case ReportStatus.FILED_MANUALLY:
                this.badgeText = 'Filed Manually';
                this.badgeClass += 'green-badge';
                break;
            case ReportStatus. WILL_NOT_BE_SUBMITTED:
                this.badgeText = 'Not Submitted';
                this.badgeClass += 'green-badge';
        }
    }
}