import { DatePipe } from '@angular/common';
import { IMyDateModel } from 'angular-mydatepicker';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
    Address, AddressFormService, AddressOccupant, AddressService, AddressType, IndividualKeyParty, KeyParty, KeyPartyFormService, KeyPartyService, KeyPartyStatus,
    KeyPartyType, OnboardingTrackingService, CustomValidators, Configuration, Country
} from 'projects/services/src/public-api';
import { ConfirmModalComponent } from 'projects/components/src/lib/modal';

@Component({
    selector: 'pt-administrator-details',
    templateUrl: './administrator-details.component.html',
    styleUrls: ['./administrator-details.component.scss']
})
export class AdministratorDetailsComponent implements OnInit {

    AddressType = AddressType;

    @Input() userId: string;
    @Input() memberId: string;
    @Input() task: any;
    @Input() changeTrigger: number;
    @Input() readOnly = false;

    @Output() next: EventEmitter<string> = new EventEmitter<string>();

    addressForm: UntypedFormGroup;
    keyPartyForm: UntypedFormGroup;
    individualKeyPartyForm: UntypedFormGroup;
    isOwner = false;
    displayDefaultEmptyDate = true;
    isUsCitizen = true;
    passportExpirationDate: any;
    countries: Country[];

    constructor(private addressService: AddressService,
                private addressFormService: AddressFormService,
                private keyPartyService: KeyPartyService,
                private datePipe: DatePipe,
                private keyPartyFormService: KeyPartyFormService,
                private cd: ChangeDetectorRef,
                private dialog: MatDialog) {}

    ngOnInit() {
        this.isAdminDetailsValid = this.isAdminDetailsValid.bind(this);
        this.initLocation = this.initLocation.bind(this);
        this.onNavigate = this.onNavigate.bind(this);
        this.onSave = this.onSave.bind(this);
        this.countries = Configuration.getConfig().countries;
        this.initForms();
    }

    initForms(callback?: any) {
        this.keyPartyService.getKeyPartyByMemberIdAndUserId(this.memberId, this.userId).subscribe((keyParty: KeyParty) => {
            this.initKeyParty(keyParty);
            this.addressService.getPrimaryAddress(this.userId).subscribe((address: Address) => {
                this.initLocation(address, callback);
            });
        });
    }

    initKeyParty(keyParty: KeyParty) {
        if (keyParty) {
            this.isOwner = keyParty.percentOwnership > 0;
            this.keyPartyForm = this.keyPartyFormService.initializeKeyPartyForm(keyParty.type, keyParty.status, keyParty);
            this.individualKeyPartyForm = this.keyPartyFormService.initializeIndividualKeyPartyForm(keyParty.individualKeyParty);
            if (keyParty.individualKeyParty.passportExpirationDate) {
                this.displayDefaultEmptyDate = false;
                this.passportExpirationDate = keyParty.individualKeyParty.passportExpirationDate;
            }
        } else {
            this.keyPartyForm = this.keyPartyFormService.initializeKeyPartyForm(KeyPartyType.INDIVIDUAL, KeyPartyStatus.ACTIVE, null);
            this.individualKeyPartyForm = this.keyPartyFormService.initializeIndividualKeyPartyForm();
        }
        if (this.readOnly) {
            this.keyPartyForm.disable();
            this.individualKeyPartyForm.disable();
        } else {
            this.individualKeyPartyForm.get('firstNameCtrl').disable();
            this.individualKeyPartyForm.get('middleNameCtrl').disable();
            this.individualKeyPartyForm.get('lastNameCtrl').disable();
            this.individualKeyPartyForm.get('phoneCtrl').disable();
            this.individualKeyPartyForm.get('emailCtrl').disable();
        }

        this.keyPartyForm.markAsPristine();
        this.individualKeyPartyForm.markAsPristine();
        this.keyPartyForm.valueChanges.subscribe(() => {
            this.updateValidity();
        });
        this.individualKeyPartyForm.valueChanges.subscribe(() => {
            this.updateValidity();
        });
    }

    initLocation(address: Address, callback?: any) {
        this.addressForm = this.addressFormService.initializeForm(address);
        if (this.readOnly) {
            this.addressForm.disable();
        }
        this.addressForm.markAsPristine();
        this.onOwnershipChange();
        this.onCitizenshipChange();
        if (callback) {
            callback();
        }
        this.addressForm.valueChanges.subscribe(() => {
            this.updateValidity();
        });
    }

    onNavigate(reset: any) {
        if (this.isAdminDetailsValid()) {
            const dialogConfig: MatDialogConfig = {};
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'normal-modal';
            dialogConfig.disableClose = true;

            dialogConfig.data = {
                title: 'You have unsaved changes.',
                description: 'Click Save to save your changes and continue, or click Ignore to continue without saving.',
                confirmText: 'Save',
                confirmAction: 'save',
                rejectText: 'Ignore',
                rejectAction: 'ignore'
            };

            const dialog = this.dialog.open(ConfirmModalComponent, dialogConfig);

            dialog?.afterClosed().subscribe(
                (confirmAction: any) => {
                    if (confirmAction === 'save') {
                        this.updateInformation(() => {
                            reset();
                            this.next.emit();
                        });
                    } else if (confirmAction === 'ignore') {
                        this.initForms(() => {
                            reset();
                            this.next.emit();
                        });
                    } else {
                        reset();
                    }
                }
            );
        } else {
            reset();
            this.next.emit();
        }
    }

    onSave(reset?: any) {
        this.updateInformation(reset);
    }

    updateInformation(callback?: any) {
        const address = this.addressFormService.getAddress(this.addressForm);
        this.addressService.linkAddressToOccupant(address, this.userId, this.memberId).subscribe((addressOccupant: AddressOccupant) => {
            this.isOwner = this.keyPartyForm.get('ownerCtrl').value;
            if (this.isOwner || this.keyPartyForm.get('isDirectorCtrl').value || this.keyPartyForm.get('isOfficerCtrl').value) {
                const individualKeyParty = this.keyPartyFormService.getIndividualKeyParty(this.individualKeyPartyForm);
                individualKeyParty.passportExpirationDate = this.passportExpirationDate;
                individualKeyParty.address = addressOccupant.address;

                this.keyPartyService.saveIndividualKeyParty(individualKeyParty).subscribe((individualKeyParty: IndividualKeyParty) => {
                    const keyParty = this.keyPartyFormService.getKeyParty(this.keyPartyForm);
                    keyParty.memberId = this.memberId;
                    keyParty.individualKeyPartyId = individualKeyParty.id;
                    keyParty.userId = this.userId;
                    this.keyPartyService.saveKeyParty(keyParty).subscribe(() => {
                        this.initForms(callback);
                    });
                });
            } else {
                this.initForms(callback);
            }
        });
    }

    onDateChanged(event: IMyDateModel) {
        if (event && event.singleDate) {
            this.passportExpirationDate = this.datePipe.transform(event?.singleDate?.jsDate, 'yyyy-MM-dd');
        } else {
            this.passportExpirationDate = '';
        }
    }

    onCitizenshipChange() {
        this.isUsCitizen = this.individualKeyPartyForm.get('usCitizenCtrl').value;
        if (this.isUsCitizen) {
            this.individualKeyPartyForm.get('taxIdCtrl').setValidators([Validators.required, Validators.pattern('^([0-9]{9})$')]);
            this.individualKeyPartyForm.get('passportCountryOfIssueCtrl').setValidators(null);
            this.individualKeyPartyForm.get('passportNumberCtrl').setValidators(null);
            this.individualKeyPartyForm.get('passportExpDateCtrl').setValidators(null);
        } else {
            this.individualKeyPartyForm.get('taxIdCtrl').setValidators(null);
            this.individualKeyPartyForm.get('passportCountryOfIssueCtrl').setValidators([Validators.required]);
            this.individualKeyPartyForm.get('passportNumberCtrl').setValidators([Validators.required, Validators.maxLength(25), CustomValidators.noLeadingOrTrailingWhitespace]);
            this.individualKeyPartyForm.get('passportExpDateCtrl').setValidators([Validators.required]);
        }
        this.individualKeyPartyForm.get('taxIdCtrl').updateValueAndValidity();
        this.individualKeyPartyForm.get('passportCountryOfIssueCtrl').updateValueAndValidity();
        this.individualKeyPartyForm.get('passportNumberCtrl').updateValueAndValidity();
        this.individualKeyPartyForm.get('passportExpDateCtrl').updateValueAndValidity();
    }

    onOwnershipChange() {
        this.isOwner = this.keyPartyForm.get('ownerCtrl').value;
        if (this.isOwner) {
            this.keyPartyForm.get('percentOwnershipCtrl').setValidators([Validators.required, Validators.min(1), Validators.max(100), CustomValidators.onlyNumeric]);

        } else {
            this.keyPartyForm.get('percentOwnershipCtrl').setValidators(null);
            this.keyPartyForm.patchValue({percentOwnershipCtrl: ''});
        }
        this.keyPartyForm.get('percentOwnershipCtrl').updateValueAndValidity();
    }

    updateValidity() {
        OnboardingTrackingService.setAdminInfo(this.keyPartyFormService.getIndividualKeyParty(this.individualKeyPartyForm),
            this.addressFormService.getAddress(this.addressForm),
            this.keyPartyFormService.getKeyParty(this.keyPartyForm));
        OnboardingTrackingService.setAdminInfoDirty(this.individualKeyPartyForm.dirty || this.addressForm.dirty || this.keyPartyForm.dirty);
        this.cd.detectChanges();
    }

    isAdminDetailsValid() {
        const formsValid = this.individualKeyPartyForm && this.individualKeyPartyForm.valid
            && this.addressForm && this.addressForm.valid
            && (!this.isOwner || (this.isOwner && this.keyPartyForm && this.keyPartyForm.valid));
        return formsValid && OnboardingTrackingService.isAdminInfoValid() && OnboardingTrackingService.isAdminInfoDirty();
    }

    isBackgroundInfoChecked() {
        return this.keyPartyForm.get('ownerCtrl').value || this.keyPartyForm.get('isDirectorCtrl').value || this.keyPartyForm.get('isOfficerCtrl').value;
    }
}
