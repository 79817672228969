<div>
    <div>
        <div class="modal-header">
            <label class="form-title">Merge Key Party</label>
            <i class="modal-close" (click)="close()">&times;</i>
        </div>
        <div class="modal-content">
            <div class="row">
                <div class="info-message mb-2 col-12">
                    <p>
                        Please select the key party to merge:
                    </p>
                </div>
                <div class="col-6">
                    <div class="mb-3">
                        <span class="subtitle">Current Key Party:</span>
                        <mat-card class="shadow mb-3">
                            <div class="row">
                                <div class="col-12">
                                    <div *ngIf="keyParty.individualKeyParty">
                                        <b>{{keyParty.individualKeyParty.name}}</b>
                                    </div>
                                    <div *ngIf="keyParty.corporateKeyParty">
                                        <b>{{keyParty.corporateKeyParty.name}}</b>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="col-12 target-id" *ngIf="keyParty.individualKeyParty">
                                        {{ keyParty.individualKeyParty.id }}<pc-clipboard-gadget value="{{keyParty.individualKeyParty.id}}"></pc-clipboard-gadget>
                                    </div>
                                    <div class="col-12 target-id" *ngIf="keyParty.corporateKeyParty">
                                        {{ keyParty.corporateKeyParty.id }}<pc-clipboard-gadget value="{{keyParty.corporateKeyParty.id}}"></pc-clipboard-gadget>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div *ngIf="keyParty.corporateKeyParty?.dbaName">
                                        <b>DBA: {{keyParty.corporateKeyParty.dbaName}}</b>
                                    </div>
                                    <div *ngIf="keyParty.individualKeyParty">
                                        <div *ngIf="keyParty.individualKeyParty.phone">
                                            {{keyParty.individualKeyParty.phone | phone}}
                                        </div>
                                        <div *ngIf="keyParty.individualKeyParty.email">
                                            {{keyParty.individualKeyParty.email}}
                                        </div>
                                        <div *ngIf="keyParty.individualKeyParty.birthDate">
                                            DOB: {{keyParty.individualKeyParty.birthDate | date}}
                                        </div>
                                        <div *ngIf="keyParty.individualKeyParty.taxId">
                                            SSN: {{keyParty.individualKeyParty.taxId}}
                                        </div>
                                        <div *ngIf="keyParty.individualKeyParty.passportNumber">
                                            Passport: {{keyParty.individualKeyParty.passportNumber}}
                                        </div>
                                        <div *ngIf="keyParty.individualKeyParty.passportExpirationDate">
                                            Expiry: {{keyParty.individualKeyParty.passportExpirationDate | date}}
                                        </div>
                                    </div>
                                    <div *ngIf="keyParty.corporateKeyParty">
                                        <div *ngIf="keyParty.corporateKeyParty.taxId">
                                            EIN: {{keyParty.corporateKeyParty.taxId}}
                                        </div>
                                        <div *ngIf="keyParty.corporateKeyParty.officePhone">
                                            {{keyParty.corporateKeyParty.officePhone | phone}}
                                        </div>
                                        <div *ngIf="keyParty.corporateKeyParty.email">
                                            {{keyParty.corporateKeyParty.email}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <strong>Address:</strong>
                                    <pc-business-address *ngIf="keyParty.individualKeyParty?.address" [address]="keyParty.individualKeyParty?.address"></pc-business-address>
                                    <pc-business-address *ngIf="keyParty.corporateKeyParty?.address" [address]="keyParty.corporateKeyParty?.address"></pc-business-address>
                                </div>
                                <div class="col-12 mt-2">
                                    <div class="row">
                                        <div class="col-9">
                                            <pc-load-member-details [linkDescription]="keyParty.member.name" [memberId]="keyParty.member.id" [activeTab]="'key_parties'" [newTab]="true"></pc-load-member-details>
                                        </div>
                                        <div class="col-3">
                                            <pc-member-badge [parent]="keyParty.member"></pc-member-badge>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                    <div *ngIf="selectedIndividualKeyParty && keyParty.individualKeyParty">
                        <span class="subtitle">Result:</span>
                        <b [ngClass]="selectedIndividualKeyParty.name !== keyParty.individualKeyParty.name ? 'error' : ''">{{selectedIndividualKeyParty.name}}</b>
                        <div *ngIf="selectedIndividualKeyParty.phone">
                            <span [ngClass]="selectedIndividualKeyParty.phone !== keyParty.individualKeyParty.phone ? 'error' : ''">{{selectedIndividualKeyParty.phone | phone}}</span>
                        </div>
                        <div *ngIf="selectedIndividualKeyParty.email">
                            <span [ngClass]="selectedIndividualKeyParty.email !== keyParty.individualKeyParty.email ? 'error' : ''">{{selectedIndividualKeyParty.email}}</span>
                        </div>
                        <div *ngIf="selectedIndividualKeyParty.birthDate">
                            <span [ngClass]="selectedIndividualKeyParty.birthDate !== keyParty.individualKeyParty.birthDate ? 'error' : ''">DOB: {{selectedIndividualKeyParty.birthDate | date}}</span>
                        </div>
                        <div *ngIf="selectedIndividualKeyParty.taxId">
                            <span [ngClass]="selectedIndividualKeyParty.taxId !== keyParty.individualKeyParty.taxId ? 'error' : ''">SSN: {{selectedIndividualKeyParty.taxId}}</span>
                        </div>
                        <div *ngIf="selectedIndividualKeyParty.passportNumber">
                            <span [ngClass]="selectedIndividualKeyParty.passportNumber !== keyParty.individualKeyParty.passportNumber ? 'error' : ''">Passport: {{selectedIndividualKeyParty.passportNumber}}</span>
                        </div>
                        <div *ngIf="selectedIndividualKeyParty.passportExpirationDate">
                            <span [ngClass]="selectedIndividualKeyParty.passportExpirationDate !== keyParty.individualKeyParty.passportExpirationDate ? 'error' : ''">Expiry: {{selectedIndividualKeyParty.passportExpirationDate | date}}</span>
                        </div>
                        <div class="mt-3">
                            <b>Address:</b>
                            <div *ngIf="selectedIndividualKeyParty.address.streetAddressOne">
                                <span [ngClass]="selectedIndividualKeyParty.address.streetAddressOne !== keyParty.individualKeyParty.address.streetAddressOne ? 'error' : ''">{{selectedIndividualKeyParty.address.streetAddressOne}}</span>
                            </div>
                            <div *ngIf="selectedIndividualKeyParty.address.streetAddressTwo">
                                <span [ngClass]="selectedIndividualKeyParty.address.streetAddressTwo !== keyParty.individualKeyParty.address.streetAddressTwo ? 'error' : ''">{{selectedIndividualKeyParty.address.streetAddressTwo}}</span>
                            </div>
                            <div *ngIf="selectedIndividualKeyParty.address.city">
                                <span [ngClass]="selectedIndividualKeyParty.address.city !== keyParty.individualKeyParty.address.city ? 'error' : ''">{{selectedIndividualKeyParty.address.city}}
                                    <span *ngIf="selectedIndividualKeyParty.address.stateProvince" [ngClass]="selectedIndividualKeyParty.address.stateProvince !== keyParty.individualKeyParty.address.stateProvince ? 'error' : ''">, {{ selectedIndividualKeyParty.address.stateProvince }}</span>
                                </span>
                            </div>
                            <div *ngIf="selectedIndividualKeyParty.address.country && selectedIndividualKeyParty.address.country !== 'United States'">
                                <span [ngClass]="selectedIndividualKeyParty.address.country !== keyParty.individualKeyParty.address.country ? 'error' : ''">{{selectedIndividualKeyParty.address.country}}</span>
                            </div>
                            <div *ngIf="selectedIndividualKeyParty.address.zipPostalCode">
                                <span [ngClass]="selectedIndividualKeyParty.address.zipPostalCode !== keyParty.individualKeyParty.address.zipPostalCode ? 'error' : ''">{{selectedIndividualKeyParty.address.zipPostalCode}}</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="selectedCorporateKeyParty && keyParty.corporateKeyParty">
                        <span class="subtitle">Result:</span>
                        <b [ngClass]="selectedCorporateKeyParty.name !== keyParty.corporateKeyParty.name ? 'error' : ''">{{selectedCorporateKeyParty.name}}</b>

                        <div *ngIf="selectedCorporateKeyParty?.dbaName">
                            <b [ngClass]="selectedCorporateKeyParty.dbaName !== keyParty.corporateKeyParty.dbaName ? 'error' : ''">DBA: {{selectedCorporateKeyParty.dbaName}}</b>
                        </div>
                        <div *ngIf="selectedCorporateKeyParty.taxId">
                            <span [ngClass]="selectedCorporateKeyParty.taxId !== keyParty.corporateKeyParty.taxId ? 'error' : ''">EIN: {{selectedCorporateKeyParty.taxId}}</span>
                        </div>
                        <div *ngIf="selectedCorporateKeyParty.officePhone">
                            <span [ngClass]="selectedCorporateKeyParty.officePhone !== keyParty.corporateKeyParty.officePhone ? 'error' : ''">{{selectedCorporateKeyParty.officePhone | phone}}</span>
                        </div>
                        <div *ngIf="selectedCorporateKeyParty.email">
                            <span [ngClass]="selectedCorporateKeyParty.email !== keyParty.corporateKeyParty.email ? 'error' : ''">{{selectedCorporateKeyParty.email | phone}}</span>
                        </div>

                        <div class="mt-3">
                            <b>Address:</b>
                            <div *ngIf="selectedCorporateKeyParty.address.streetAddressOne">
                                <span [ngClass]="selectedCorporateKeyParty.address.streetAddressOne !== keyParty.corporateKeyParty.address.streetAddressOne ? 'error' : ''">{{selectedCorporateKeyParty.address.streetAddressOne}}</span>
                            </div>
                            <div *ngIf="selectedCorporateKeyParty.address.streetAddressTwo">
                                <span [ngClass]="selectedCorporateKeyParty.address.streetAddressTwo !== keyParty.corporateKeyParty.address.streetAddressTwo ? 'error' : ''">{{selectedCorporateKeyParty.address.streetAddressTwo}}</span>
                            </div>
                            <div *ngIf="selectedCorporateKeyParty.address.city">
                                <span [ngClass]="selectedCorporateKeyParty.address.city !== keyParty.corporateKeyParty.address.city ? 'error' : ''">{{selectedCorporateKeyParty.address.city}}
                                    <span *ngIf="selectedCorporateKeyParty.address.stateProvince" [ngClass]="selectedCorporateKeyParty.address.stateProvince !== keyParty.corporateKeyParty.address.stateProvince ? 'error' : ''">, {{ selectedCorporateKeyParty.address.stateProvince }}</span>
                                </span>
                            </div>
                            <div *ngIf="selectedCorporateKeyParty.address.country && selectedCorporateKeyParty.address.country !== 'United States'">
                                <span [ngClass]="selectedCorporateKeyParty.address.country !== keyParty.corporateKeyParty.address.country ? 'error' : ''">{{selectedCorporateKeyParty.address.country}}</span>
                            </div>
                            <div *ngIf="selectedCorporateKeyParty.address.zipPostalCode">
                                <span [ngClass]="selectedCorporateKeyParty.address.zipPostalCode !== keyParty.corporateKeyParty.address.zipPostalCode ? 'error' : ''">{{selectedCorporateKeyParty.address.zipPostalCode}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <span class="subtitle">Existing Key Parties:</span>
                    <div class="existing-list">
                        <div *ngFor="let individualKeyParty of similarIndividualKeyParties">
                            <mat-radio-group>
                                <mat-card class="shadow mb-3" [ngClass]="isSelected(individualKeyParty.id) ? 'selected' : ''" (click)="onIndividualKeyPartyChange(individualKeyParty)">
                                    <div class="row">
                                        <div class="col-12">
                                            <mat-radio-button [value]="individualKeyParty.id" [checked]="isSelected(individualKeyParty.id)" (change)="onIndividualKeyPartyChange(individualKeyParty)"><b>{{individualKeyParty.name}}</b></mat-radio-button>
                                        </div>
                                        <div class="col-12 target-id">
                                            {{ individualKeyParty.id }}<pc-clipboard-gadget value="{{individualKeyParty.id}}"></pc-clipboard-gadget>
                                        </div>
                                        <div class="col-6">
                                            <div *ngIf="individualKeyParty.phone">
                                                {{individualKeyParty.phone | phone}}
                                            </div>
                                            <div *ngIf="individualKeyParty.email">
                                                {{individualKeyParty.email}}
                                            </div>
                                            <div *ngIf="individualKeyParty.birthDate">
                                                DOB: {{individualKeyParty.birthDate}}
                                            </div>
                                            <div *ngIf="individualKeyParty.taxId">
                                                SSN: {{individualKeyParty.taxId}}
                                            </div>
                                            <div *ngIf="individualKeyParty.passportNumber">
                                                Passport: {{individualKeyParty.passportNumber}}
                                            </div>
                                            <div *ngIf="individualKeyParty.passportExpirationDate">
                                                {{individualKeyParty.passportExpirationDate | date}}
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <strong>Address:</strong>
                                            <pc-business-address *ngIf="individualKeyParty?.address" [address]="individualKeyParty?.address"></pc-business-address>
                                        </div>
                                        <div class="col-12 mt-2">
                                            <strong>Related Members:</strong>
                                            <div class="row" *ngFor="let relatedMember of relatedMembers[individualKeyParty.id]">
                                                <div class="col-9">
                                                    <pc-load-member-details [linkDescription]="relatedMember.name" [memberId]="relatedMember.id" [activeTab]="'key_parties'" [newTab]="true"></pc-load-member-details>
                                                </div>
                                                <div class="col-3">
                                                    <pc-member-badge [parent]="relatedMember"></pc-member-badge>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-card>
                            </mat-radio-group>
                        </div>
                        <div *ngFor="let corporateKeyParty of similarCorporateKeyParties">
                            <mat-radio-group>
                                <mat-card class="shadow mb-3" [ngClass]="isSelected(corporateKeyParty.id) ? 'selected' : ''" (click)="onCorporateKeyPartyChange(corporateKeyParty)">
                                    <div class="row">
                                        <div class="col-12">
                                            <mat-radio-button [value]="corporateKeyParty.id" [checked]="isSelected(corporateKeyParty.id)" (change)="onCorporateKeyPartyChange(corporateKeyParty)"><b>{{corporateKeyParty.name}}</b></mat-radio-button>
                                        </div>
                                        <div class="col-12 target-id">
                                            {{ corporateKeyParty.id }}<pc-clipboard-gadget value="{{corporateKeyParty.id}}"></pc-clipboard-gadget>
                                        </div>
                                        <div class="col-6">
                                            <div *ngIf="corporateKeyParty.dbaName">
                                                <b>DBA: {{corporateKeyParty.dbaName}}</b>
                                            </div>

                                            <div *ngIf="corporateKeyParty.taxId">
                                                EIN: {{corporateKeyParty.taxId}}
                                            </div>
                                            <div *ngIf="corporateKeyParty.officePhone">
                                                {{corporateKeyParty.officePhone | phone}}
                                            </div>
                                            <div *ngIf="corporateKeyParty.email">
                                                {{corporateKeyParty.email}}
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            Address:
                                            <pc-business-address *ngIf="corporateKeyParty?.address" [address]="corporateKeyParty?.address"></pc-business-address>
                                        </div>
                                        <div class="col-12 mt-2">
                                            <strong>Related Members:</strong>
                                            <div class="row" *ngFor="let relatedMember of relatedMembers[corporateKeyParty.id]">
                                                <div class="col-9">
                                                    <pc-load-member-details [linkDescription]="relatedMember.name" [memberId]="relatedMember.id" [activeTab]="'key_parties'" [newTab]="true"></pc-load-member-details>
                                                </div>
                                                <div class="col-3">
                                                    <pc-member-badge [parent]="relatedMember"></pc-member-badge>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-card>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <pc-submit-button [enabled]="isMergeKeyPartyFormValid" [submit]="onSubmit" [text]="'Merge Key Party'"></pc-submit-button>
        </div>
    </div>
</div>
