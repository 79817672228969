import { Address } from './address';
import { TaxInfo } from './tax-info';
import { FinancialDetails } from './financial-details';

export class Member {
    id: string;
    created: Date;
    updated: Date;
    name: string;
    taxId: string;
    memberType: MemberType;
    email: string;
    phone: string;
    memberRegistrationType: MemberRegistrationType;
    approvedDate: Date;
    status: MemberStatus;
    jurisdiction: string;
    tier: RiskTier;
    rating: RiskRating;
    source: MemberSource;
    referredCode: string;
    referralCode: string;
    briefDescription: string;
    businessCategory: BusinessCategory;
    memberFunctions: MemberFunction [];
    metaInfo: MemberMetaInfo;
    taxInfo: TaxInfo;
    financialDetails: FinancialDetails;
    disabledDate: Date;


    address: Address;

    // ad hoc fields
    notificationCount: number;
    submittedDate: Date;
    selected = false;
    error: string;
    platform: any;
}

export class CorporateEntity extends Member {
    businessName: string;
    dbaName: string;
    previousName: string;
    businessType: string;
    isPublic: boolean;
    companyUrl: string;
    naicsCode: number;
}

export class Consumer extends Member {
    firstName: string;
    middleName: string;
    lastName: string;
    dateOfBirth: string;
}

export enum MemberFunction {
    PARTNER = 'PARTNER'
}

export enum MemberType {
    ALL = '',
    FEE_ACCOUNT = 'FEE_ACCOUNT',
    CONSUMER = 'CONSUMER',
    BUSINESS = 'BUSINESS',
    AUTHORITY = 'AUTHORITY',
    BUSINESS_MERCHANT = 'BUSINESS_MERCHANT',
    INDIVIDUAL_MERCHANT = 'INDIVIDUAL_MERCHANT'
}

export enum MemberStatus {
    ALL = '',
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    APPLIED = 'APPLIED',
    UNDER_REVIEW = 'UNDER_REVIEW',
    DISABLED = 'DISABLED',
    PENDING = 'PENDING',
    BLOCKED = 'BLOCKED',
    UNAVAILABLE = 'UNAVAILABLE',
    // FIXME: CN-3880 - I think only accounts should be frozen, not members
    FROZEN = 'FROZEN',
    REJECTED = 'REJECTED'
}

export enum MemberSource {
    ALL = '',
    CONFIA = 'CONFIA',
    BOOTSTRAP = 'BOOTSTRAP',
    PARTNER = 'PARTNER',
    PAYMENT_PROCESSOR = 'PAYMENT_PROCESSOR',
    ACCOUNTING = 'ACCOUNTING'
}

export enum RiskTier {
    NOT_TIERED = 'NOT_TIERED',
    TIER_1 = 'TIER_1',
    TIER_2 = 'TIER_2',
    TIER_3 = 'TIER_3',
    NA = 'NA'
}

export enum RiskRating {
    NOT_RATED = 'NOT_RATED',
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
    SPECIAL = 'SPECIAL',
    BLOCKED = 'BLOCKED'
}

export enum BusinessCategory {
    MRB_LICENSED_BUSINESS = 'MRB_LICENSED_BUSINESS',
    ANCILLARY_MRB_BUSINESS = 'ANCILLARY_MRB_BUSINESS',
    ATM_BUSINESS = 'ATM_BUSINESS',
    ATM_PROCESSOR = 'ATM_PROCESSOR',
    PAYMENT_PROCESSOR = 'PAYMENT_PROCESSOR',
    LABORATORY = 'LABORATORY',
    POOLED_MERCHANT = 'POOLED_MERCHANT',
    OTHER = 'OTHER'
}

export class MemberMetaInfo {
    accountingSystem: string;
    linkBankAccount: boolean;
    paymentProcessor: string;
}

export enum MemberRegistrationType {
    ALL = '',
    PREMIUM_MEMBER = 'PREMIUM_MEMBER',
    REGULAR_MEMBER = 'REGULAR_MEMBER',
    CONSUMER = 'CONSUMER',
}
