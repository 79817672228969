import {Address} from './address';

export class User {
    id: string;
    created: Date;
    updated: Date;

    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    mobilePhone: string;
    status: UserStatus;

    // adhoc fields
    name: string;
    accountRole: string;
    address: Address;
}

export enum UserStatus {
    ACTIVE = 'ACTIVE',
    PENDING_REGISTRATION = 'PENDING_REGISTRATION',
    LOCKED = 'LOCKED'
}


export enum UserUpdatableField {
    EMAIL = 'EMAIL',
    PHONE = 'PHONE'
}
