<div class="row">
    <div class="col-auto dashboard-header">
        <span>Users</span>
    </div>
    <div class="col-sm">
        <button *ngIf="isAuthority()" class="blue-button pull-right" (click)="onAddNewAuthority()" type="button">
            <i class="fa fa-plus pull-left dashboard-icon"></i><div class="pull-right-button">New Authority</div>
        </button>
    </div>
</div>
<div class="row">
    <div class="col-sm-12 horizontal-scroll mat-table-container mt-2">
        <div class="mat-table-spinner" *ngIf="isLoadingResults">
            <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
        </div>
        <table mat-table [dataSource]="accounts" matSort [matSortActive]="pageTracking.sort" matSortDisableClear [matSortDirection]="pageTracking.sortDir">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.user">
                        <strong *ngIf="element.user.firstName">{{element.user.firstName}}</strong>
                        <strong *ngIf="element.user.lastName"> {{element.user.lastName}}</strong>
                        <span *ngIf="element.user.mobilePhone">
                            <br>
                            {{element.user.mobilePhone | phone }}
                        </span>
                        <span *ngIf="element.user.email">
                            <br>
                            {{element.user.email}}
                        </span>
                    </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th class="centered" mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <div class="status"><pc-account-badge [parent]="element"></pc-account-badge></div>
                </td>
            </ng-container>
            <ng-container matColumnDef="role">
                <th class="centered" mat-header-cell *matHeaderCellDef> Role </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <div *ngIf="!currentUser(element) && isAuthority() && element.status === UserAccountStatus.ACTIVE
                        && (element.role !== Role.AUTHORITY_SUPERUSER || isAuthoritySuperuser())">
                        <select type="text" [(ngModel)]="element.role" (change)="onRoleChanged(element)">
                            <option *ngIf="isAuthoritySuperuser()" value="AUTHORITY_SUPERUSER">Authority Superuser</option>
                            <option value="AUTHORITY">Authority</option>
                            <option value="AUTHORITY_REPORTER">Authority Reporter</option>
                            <option value="AUTHORITY_READONLY">Authority Reviewer</option>
                        </select>
                    </div>
                    <div *ngIf="(element.user && currentUser(element)) || element.status !== UserAccountStatus.ACTIVE || (element.role === Role.AUTHORITY_SUPERUSER && !isAuthoritySuperuser())">
                        {{ element.role | translate }}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell class="centered" *matHeaderCellDef> Action </th>
                <td mat-cell  class="action centered" *matCellDef="let element">
                    <i *ngIf="!currentUser(element) && isAuthority() && element.status !== UserAccountStatus.INVITED" class="fa fa-2x" [class.fa-user]="isAccountEnabled(element)" [class.fa-user-o]="!isAccountEnabled(element)" (click)="toggleEnabled($event, element)" [title]="isAccountEnabled(element) ? 'Disable User' : 'Enable User'"></i>
                        <i *ngIf="!currentUser(element) && isAuthority() && element.status === UserAccountStatus.INVITED" class="fa fa-2x fa-user-times" (click)="revokeInvitation(element)" title="Revoke Invitation"></i>
                        <i *ngIf="!currentUser(element) && isAuthority() && element.status !== UserAccountStatus.INVITED && canUserBeReset(element)" class="fa fa-2x" [class.fa-key]="element.user?.status === UserStatus.LOCKED" [class.fa-lock]="element.user?.status === UserStatus.ACTIVE" (click)="resetPassword($event, element)" [title]="element.user?.status === UserStatus.ACTIVE ? 'Reset Password' : 'Resend Password Reset'"></i>
                        <i *ngIf="!currentUser(element) && isAuthority() && element.status !== UserAccountStatus.INVITED && canUserBeReset(element)" class="fa fa-2x fa-phone" (click)="updatePhoneNumber($event, element.user)" [title]="'Change Phone Number'"></i>
                        <i *ngIf="!currentUser(element) && isAuthority() && element.status !== UserAccountStatus.INVITED && canUserBeReset(element)" class="fa fa-2x fa-at" (click)="updateEmail($event, element.user)" [title]="'Change Email Address'"></i>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"  [ngClass]="!isAccountEnabled(row) ? 'disabled' : ''"></tr>
        </table>
        <mat-paginator class="mat-paginator-sticky" [pageIndex]="paginator.pageIndex" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions"
            [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
    </div>
</div>
