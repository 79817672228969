import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    AddressFormService, AddressType, AuthService, CorporateKeyParty, KeyParty, KeyPartyFormService, KeyPartyService, KeyPartyStatus, KeyPartyType, CustomValidators,
    LicenseStatus, PagedResponse, License, LicenseService
} from 'projects/services/src/public-api';
import { BaseModalComponent, ErrorType } from 'projects/components/src/public-api';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pt-corporate-key-party-modal',
    templateUrl: './corporate-key-party-modal.component.html',
    styleUrls: ['./corporate-key-party-modal.component.scss']
})
export class CorporateKeyPartyModalComponent extends BaseModalComponent<CorporateKeyPartyModalComponent> implements OnInit {

    AddressType = AddressType;

    memberId: string;
    keyPartyForm: UntypedFormGroup;
    addressForm: UntypedFormGroup;
    corporateKeyPartyForm: UntypedFormGroup;

    keyParty: KeyParty;
    isExistingKeyParty = false;
    errorMessage: { message: string, type: ErrorType };
    existingKeyPartyIds: string[];

    businessTypeOpts: string[] = ['Corporation',
        'Limited Liability Company',
        'Limited Partnership',
        'General Partnership',
        'Limited Liability Partnership',
        'Sole Proprietorship'];

    constructor(public authService: AuthService,
                private addressFormService: AddressFormService,
                private keyPartyService: KeyPartyService,
                private keyPartyFormService: KeyPartyFormService,
                private licenseService: LicenseService,
                private notifier: NotificationService,
                dialogRef: MatDialogRef<CorporateKeyPartyModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.memberId = data.memberId;
        this.keyParty = data.keyParty;
        this.existingKeyPartyIds = data.existingKeyPartyIds;
        if (this.keyParty) {
            this.isExistingKeyParty = true;
        }
    }

    ngOnInit() {
        this.onSubmit = this.onSubmit.bind(this);
        this.isKeyPartyFormValid = this.isKeyPartyFormValid.bind(this);
        if (this.isExistingKeyParty) {
            this.keyPartyForm = this.keyPartyFormService.initializeKeyPartyForm(this.keyParty.type, this.keyParty.status, this.keyParty);
            this.addressForm = this.addressFormService.initializeForm(this.keyParty.corporateKeyParty.address);
            this.corporateKeyPartyForm = this.keyPartyFormService.initializeCorporateKeyPartyForm(this.keyParty.corporateKeyParty);
            this.showWarning(true);
        } else {
            this.keyPartyForm = this.keyPartyFormService.initializeKeyPartyForm(KeyPartyType.CORPORATE, KeyPartyStatus.ACTIVE);
            this.addressForm = this.addressFormService.initializeForm(null);
            this.corporateKeyPartyForm = this.keyPartyFormService.initializeCorporateKeyPartyForm();
        }
        this.updateFormValidators();
    }

    close(refresh?: boolean) {
        super.close(refresh);
    }

    updateFormValidators() {
        if (this.keyPartyForm?.controls['isLicenseHolderCtrl'].value) {
            this.keyPartyForm.get('percentOwnershipCtrl').setValidators([Validators.min(1), Validators.max(100), CustomValidators.onlyNumeric]);
        } else {
            this.keyPartyForm.get('percentOwnershipCtrl').setValidators([Validators.required, Validators.min(1), Validators.max(100), CustomValidators.onlyNumeric]);
        }
        this.keyPartyForm.get('percentOwnershipCtrl').updateValueAndValidity();
    }

    onSubmit(reset: any) {
        const address = this.addressFormService.getAddress(this.addressForm);
        const corporateKeyParty = this.keyPartyFormService.getCorporateKeyParty(this.corporateKeyPartyForm);
        corporateKeyParty.address = address;
        const keyParty = this.keyPartyFormService.getKeyParty(this.keyPartyForm);

        if (!keyParty.licenseHolder) {
            // we may have deselected the license holder, so we need to check if there are any licenses linked to the KP
            this.licenseService.loadMemberLicenses(this.memberId, [LicenseStatus.ACTIVE], 0, -1, 'id', 'DESC').subscribe((licensesResponse: PagedResponse<License>) => {
                const linkedLicenses = licensesResponse.content.filter((license) => {
                    return license.msaKeyPartyId === keyParty.id && license.msaEnabled === true && license.jurisdiction === 'State Operator';
                });
                if (linkedLicenses.length === 0) {
                    this.saveKeyPartyInformation(corporateKeyParty, keyParty);
                } else {
                    reset();
                    this.notifier.showError(`${corporateKeyParty.name} is in use by license ${linkedLicenses[0].licenseNo} and cannot have license holder removed.<br/><br/>Remove the MSA first.`);
                }
            });
        } else {
            this.saveKeyPartyInformation(corporateKeyParty, keyParty);
        }
    }

    saveKeyPartyInformation(corporateKeyParty: CorporateKeyParty, keyParty: KeyParty) {
        this.keyPartyService.saveCorporateKeyParty(corporateKeyParty).subscribe((result: CorporateKeyParty) => {
            keyParty.corporateKeyPartyId = result.id;
            keyParty.memberId = this.memberId;
            this.keyPartyService.saveKeyParty(keyParty).subscribe(() => {
                this.close(true);
            });
        });
    }

    isKeyPartyFormValid() {
        return this.keyPartyForm.valid && this.addressForm.valid && this.corporateKeyPartyForm.valid
            && (this.keyPartyForm.dirty || this.addressForm.dirty || this.corporateKeyPartyForm.dirty);
    }

    setCorporateKeyParty(corporateKeyParty: CorporateKeyParty) {
        this.corporateKeyPartyForm.patchValue({
            idCtrl: corporateKeyParty.id,
            createdCtrl: corporateKeyParty.created,
            updatedCtrl: corporateKeyParty.updated,

            nameCtrl: corporateKeyParty.name,
            dbaNameCtrl: corporateKeyParty.dbaName,
            taxIdCtrl: corporateKeyParty.taxId,
            businessTypeCtrl: corporateKeyParty.businessType,
            emailCtrl: corporateKeyParty.email,
            officePhoneCtrl: corporateKeyParty.officePhone
        }, {emitEvent: true});
        if (corporateKeyParty.address) {
            this.addressForm.patchValue({
                idCtrl: corporateKeyParty.address?.id,
                createdCtrl: corporateKeyParty.address?.created,
                updatedCtrl: corporateKeyParty.address?.updated,
                streetAddressOneCtrl: corporateKeyParty.address?.streetAddressOne,
                cityCtrl: corporateKeyParty.address?.city,
                stateProvinceCtrl: corporateKeyParty.address?.stateProvince,
                countryCtrl: corporateKeyParty.address?.country || 'United States',
                zipPostalCodeCtrl: corporateKeyParty.address?.zipPostalCode
            });
        } else {
            this.addressForm.patchValue({
                idCtrl: '',
                createdCtrl: null,
                updatedCtrl: null,
                streetAddressOneCtrl: '',
                cityCtrl: '',
                stateProvinceCtrl: '',
                countryCtrl: 'United States',
                zipPostalCodeCtrl: ''
            });
        }
        this.keyPartyForm.patchValue({
            corporateKeyPartyIdCtrl: corporateKeyParty.id
        });
        this.updateFormValidators();
        this.showWarning(!!corporateKeyParty.id);
    }

    showWarning(existingParty: boolean) {
        if (!existingParty) {
            this.errorMessage = null;
        } else {
            this.errorMessage = {
                message: 'This key party exists within Confia.  Changes to the corporate and address information will update anywhere this key party is in use.',
                type: ErrorType.WARN
            };
        }
    }
}
