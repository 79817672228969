import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import {
    AuthService, BusinessClientStatus, KeyParty, KeyPartyService, KeyPartyStatus, KeyPartyType, License, LicenseService, LicenseStatus, MemberRegistrationType, MemberStatus,
    MemberType, PagedResponse, UserAccountService
} from 'projects/services/src/public-api';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CorporateKeyPartyModalComponent, IndividualKeyPartyModalComponent } from 'projects/pt/src/app/components';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { merge, of as observableOf, Subscription } from 'rxjs';
import { catchError, startWith, switchMap } from 'rxjs/operators';
import { PageTracking } from 'projects/components/src/lib/table-utils.service';
import { AbstractKeyPartyList } from 'projects/pt/src/app/administration/settings/components/key-parties/abstract-key-party-list';
import { DatatableComponent } from 'projects/components/src/lib/tables/datatable/datatable.component';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { MergeKeyPartyModalComponent } from 'projects/components/src/lib/modal';

@Component({
    selector: 'pt-active-key-party-list',
    templateUrl: './active-key-party-list.component.html',
    styleUrls: ['./active-key-party-list.component.scss']
})
export class ActiveKeyPartyListComponent extends AbstractKeyPartyList implements OnInit, AfterViewInit, OnDestroy, OnChanges {

    @Input() changeTrigger: number;
    @Input() memberId: string;
    @Input() memberType: MemberType;
    @Input() memberRegistrationType: MemberRegistrationType;
    @Input() memberStatus: MemberStatus | BusinessClientStatus;
    @Input() readOnly = false;
    @Input() allowAdd = true;

    @Output() keyPartiesUpdated: EventEmitter<null> = new EventEmitter<null>();

    @Output() keyPartyDeleted: EventEmitter<null> = new EventEmitter<null>();

    @ViewChild(DatatableComponent)
        dataTable: DatatableComponent<KeyParty>;

    displayedColumns: string[];

    subscription: Subscription;

    private refreshListEvent: EventEmitter<null> = new EventEmitter<null>();

    constructor(private dialog: MatDialog,
        authService: AuthService,
        keyPartyService: KeyPartyService,
        userAccountService: UserAccountService,
        private notifier: NotificationService,
        private licenseService: LicenseService) {
        super(authService, keyPartyService, userAccountService);
    }

    ngOnInit() {
        this.displayedColumns = [...this.DEFINED_COLUMNS];
        this.displayedColumns.splice(this.displayedColumns.indexOf('date_changed'), 1);
        if (!this.authService.isAuthorityOrAdmin() || this.readOnly) {
            this.displayedColumns.splice(this.displayedColumns.indexOf('action'), 1);
        }
        if (!this.authService.isAuthority()) {
            this.displayedColumns.splice(this.displayedColumns.indexOf('details'), 1);
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit() {
        this.loadData();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.changeTrigger && !changes.changeTrigger.firstChange) {
            this.refreshList();
        }
    }

    refreshList() {
        this.refreshListEvent.emit();
    }

    loadData() {
        this.subscription = merge(this.refreshListEvent)
            .pipe(
                startWith({}),
                switchMap(() => {
                    const pageTracking: PageTracking = this.dataTable.pageTracking;
                    this.isLoadingResults = true;
                    return this.getKeyParties(this.memberId, pageTracking.page - 1, pageTracking.pageSize, pageTracking.sort, pageTracking.sortDir, KeyPartyStatus.ACTIVE);
                }),
                catchError(() => {
                    this.isLoadingResults = false;
                    return observableOf([]);
                })
            )
            .subscribe((keyParties: KeyParty[]) => {
                this.keyParties = keyParties;
                this.keyPartiesUpdated.emit();
                UIUtils.scrollDashboardToTop();
                this.isLoadingResults = false;
            });
    }

    editKeyParty(keyParty: KeyParty) {
        if (keyParty.corporateKeyParty) {
            this.addOrUpdateCorporateKeyParty(keyParty);
        } else {
            this.addOrUpdateIndividualKeyParty(keyParty);
        }
    }

    addOrUpdateIndividualKeyParty(keyParty?: KeyParty) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'full-modal';

        this.keyPartyService.getAllKeyPartiesByType(this.memberId, KeyPartyType.INDIVIDUAL).subscribe((keyParties: PagedResponse<KeyParty>) => {
            const existingKeyPartyIds = keyParties.content.map((keyParty) => {
                return keyParty.individualKeyParty.id;
            });

            dialogConfig.data = {
                memberId: this.memberId,
                keyParty: keyParty,
                isMerchant: this.isMerchant(),
                memberType: this.memberType,
                memberAccountStatus: this.memberStatus,
                existingKeyPartyIds
            };

            const dialog = this.dialog.open(IndividualKeyPartyModalComponent, dialogConfig);

            dialog?.afterClosed().subscribe((refresh: boolean) => {
                if (refresh) {
                    this.refreshList();
                }
            });

        });
    }

    addOrUpdateCorporateKeyParty(keyParty?: KeyParty) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'full-modal';

        this.keyPartyService.getAllKeyPartiesByType(this.memberId, KeyPartyType.CORPORATE).subscribe((keyParties: PagedResponse<KeyParty>) => {
            const existingKeyPartyIds = keyParties.content.map((keyParty) => {
                return keyParty.corporateKeyParty.id;
            });
            existingKeyPartyIds.push(this.memberId);

            dialogConfig.data = {
                memberId: this.memberId,
                keyParty: keyParty,
                existingKeyPartyIds
            };

            const dialog = this.dialog.open(CorporateKeyPartyModalComponent, dialogConfig);

            dialog?.afterClosed().subscribe((refresh: boolean) => {
                if (refresh) {
                    this.refreshList();
                }
            });
        });
    }

    onDeleteKeyParty(keyParty: KeyParty) {
        if (keyParty.licenseHolder) {
            this.licenseService.loadMemberLicenses(this.memberId, [LicenseStatus.ACTIVE], 0, -1, 'id', 'DESC').subscribe((licensesResponse: PagedResponse<License>) => {
                const linkedLicenses = licensesResponse.content.filter((license) => {
                    return license.msaKeyPartyId === keyParty.id && license.msaEnabled === true && license.jurisdiction === 'State Operator';
                });
                if (linkedLicenses.length === 0) {
                    this.deleteKeyParty(keyParty.id);
                } else {
                    const keyPartyName = keyParty.corporateKeyPartyId ? keyParty.corporateKeyParty.name : keyParty.individualKeyParty.name;
                    this.notifier.showError(`${keyPartyName} is in use by license ${linkedLicenses[0].licenseNo} and cannot be removed.`);
                }
            });
        } else {
            this.deleteKeyParty(keyParty.id);
        }
    }

    deleteKeyParty(keyPartyId: string) {
        const body = {
            status: KeyPartyStatus.DISABLED
        };
        this.keyPartyService.updateKeyParty(keyPartyId, body).subscribe(() => {
            this.keyPartyDeleted.emit();
            this.refreshList();
        });
    }

    getUBOPercentage() {
        return (this.memberRegistrationType === MemberRegistrationType.REGULAR_MEMBER) ? '25' : '15';
    }

    isAddButtonsVisible() {
        return this.authService.isAuthorityOrAdmin() && this.allowAdd;
    }

    isDescriptionVisible() {
        return !this.isMerchant();
    }

    isMerchant() {
        return this.memberType === MemberType.BUSINESS_MERCHANT || this.memberType === MemberType.INDIVIDUAL_MERCHANT;
    }

    isDeleteKeyPartyVisible(keyParty: KeyParty) {
        return this.authService.isAuthority() && !this.readOnly && keyParty.isDeleteable;
    }

    isEditKeyPartyVisible() {
        return this.authService.isAuthority();
    }

    openKeyPartyMerge(keyParty: KeyParty) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = 'full-modal';

        dialogConfig.data = {
            keyParty: keyParty
        };

        const dialog = this.dialog.open(MergeKeyPartyModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((refresh: boolean) => {
            if (refresh) {
                this.refreshList();
            }
        });
    }
}
