import { Injectable, Inject } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.EntityAPIEndpoint + '/';
    }

    loadUser(id: string) {
        return this.http.get(`${this.baseUrl}user/${id}`);
    }

    updateUser(userId: string, body: any) {
        return this.http.put(`${this.baseUrl}user/${userId}`, body);
    }

    searchUsers(email: string) {
        return this.http.get(`${this.baseUrl}user?email=${encodeURIComponent(email)}`);
    }
}
