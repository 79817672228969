import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { License } from '../models/license';
import { CustomValidators, MemberType } from 'projects/services/src/public-api';
import { DatePipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class LicenseFormService {

    constructor(private formbuilder: UntypedFormBuilder,
                private datePipe: DatePipe) {
    }

    initializeForm(license: License, memberId: string, memberType: MemberType): UntypedFormGroup {
        const form = this.formbuilder.group({
            idCtrl: new UntypedFormControl(),
            createdCtrl: new UntypedFormControl(),
            memberIdCtrl: new UntypedFormControl(memberId),
            documentIdCtrl: new UntypedFormControl(),

            jurisdictionCtrl: new UntypedFormControl('', [Validators.required]),
            regulatoryBodyCtrl: new UntypedFormControl(''),
            typeCtrl: new UntypedFormControl(''),
            segmentCtrl: new UntypedFormControl(''),

            licenseCtrl: new UntypedFormControl(''),
            licenseNumberCtrl: new UntypedFormControl('', [Validators.required]),
            licenseHolderCtrl: new UntypedFormControl('', [Validators.maxLength(250)]),
            licenseNoteCtrl: new UntypedFormControl('', [Validators.maxLength(256)]),
            licenseExpDateCtrl: new UntypedFormControl(''),

            msaEnabledCtrl: new UntypedFormControl(''),
            msaStartDateCtrl: new UntypedFormControl(''),
            msaExpirationDateCtrl: new UntypedFormControl(''),
            msaDocumentIdCtrl: new UntypedFormControl(''),
            msaKeyPartyIdCtrl: new UntypedFormControl(''),
            msaScopeCtrl: new UntypedFormControl('')
        });
        if (license) {
            form.patchValue({
                idCtrl: license.id,
                createdCtrl: license.created,
                memberIdCtrl: license.memberId,
                documentIdCtrl: license.documentId,

                jurisdictionCtrl: license.jurisdiction,
                regulatoryBodyCtrl: license.regulatoryBody,
                typeCtrl: license.type,
                segmentCtrl: license.segment,

                licenseCtrl: license.license,
                licenseNumberCtrl: license.licenseNo,
                licenseHolderCtrl: license.holderName,
                licenseNoteCtrl: license.note,
                licenseExpDateCtrl: license.expirationDate,

                msaEnabledCtrl: license.msaEnabled,
                msaStartDateCtrl: license.msaStartDate,
                msaExpirationDateCtrl: license.msaExpirationDate,
                msaDocumentIdCtrl: license.msaDocumentId,
                msaKeyPartyIdCtrl: license.msaKeyPartyId,
                msaScopeCtrl: license.msaScope
            });
            if (license.jurisdiction === 'State Operator') {
                const validations = memberType === MemberType.BUSINESS ? [Validators.required] : null;
                form.get('licenseNumberCtrl').setValidators([Validators.required, CustomValidators.noWhitespace]);
                form.controls['licenseExpDateCtrl'].setValidators(validations);
                form.controls['licenseNumberCtrl'].updateValueAndValidity();
            }
        }
        return form;
    }

    getLicense(form: UntypedFormGroup): License {
        const license = new License();
        license.id = form.controls['idCtrl'].value;
        license.created = form.controls['createdCtrl'].value;
        license.memberId = form.controls['memberIdCtrl'].value;

        license.jurisdiction = form.controls['jurisdictionCtrl'].value;
        license.regulatoryBody = form.controls['regulatoryBodyCtrl'].value;
        license.type = form.get('typeCtrl').value ? form.get('typeCtrl').value.charAt(0) : '';
        license.segment = form.controls['segmentCtrl'].value;

        license.license = form.controls['licenseCtrl'].value;
        license.licenseNo = form.controls['licenseNumberCtrl'].value;
        license.holderName = form.controls['licenseHolderCtrl'].value;
        license.documentId = form.controls['documentIdCtrl'].value;
        license.note = form.controls['licenseNoteCtrl'].value;

        license.msaEnabled = form.controls['msaEnabledCtrl'].value;
        license.msaKeyPartyId = form.controls['msaKeyPartyIdCtrl'].value;
        license.msaScope = form.controls['msaScopeCtrl'].value;
        license.msaDocumentId = form.controls['msaDocumentIdCtrl'].value;
        license.msaStartDate = form.controls['msaStartDateCtrl'].value;
        license.msaExpirationDate = form.controls['msaExpirationDateCtrl'].value;

        switch (license.regulatoryBody) {
            case ('Cannabis Bureau'): {
                license.classification = 'Retail';
                break;
            }
            case ('Health & Safety'): {
                license.classification = 'Manufacturing';
                break;
            }
            case ('Food & Agriculture'): {
                license.classification = 'Cultivation';
                break;
            }
            case ('Tax Bureau'): {
                license.classification = 'N/A';
                break;
            }
        }

        return license;
    }

    initializeMsaForm(license: License): UntypedFormGroup {
        const form = this.formbuilder.group({
            idCtrl: new UntypedFormControl('', [Validators.required]),
            msaStartDateCtrl: new UntypedFormControl('', [Validators.required]),
            msaExpirationDateCtrl: new UntypedFormControl('', [Validators.required]),
            msaDocumentIdCtrl: new UntypedFormControl(''),
            msaKeyPartyIdCtrl: new UntypedFormControl('', [Validators.required]),
            msaScopeCtrl: new UntypedFormControl('', [Validators.maxLength(1000), Validators.required, CustomValidators.noLeadingOrTrailingWhitespace])
        });
        if (license) {
            form.patchValue({
                idCtrl: license.id,
                msaStartDateCtrl: license.msaStartDate,
                msaExpirationDateCtrl: license.msaExpirationDate,
                msaDocumentIdCtrl: license.msaDocumentId,
                msaKeyPartyIdCtrl: license.msaKeyPartyId,
                msaScopeCtrl: license.msaScope
            });
            form.controls['msaStartDateCtrl'].updateValueAndValidity();
            form.controls['msaExpirationDateCtrl'].updateValueAndValidity();
        }
        return form;
    }
    getUpdatedMsaValues(form: UntypedFormGroup): License {
        const license = new License();

        license.msaKeyPartyId = form.controls['msaKeyPartyIdCtrl'].value;
        license.msaScope = form.controls['msaScopeCtrl'].value;
        license.msaDocumentId = form.controls['msaDocumentIdCtrl'].value;
        license.msaStartDate = form.get('msaStartDateCtrl')?.value?.singleDate?.jsDate ? this.datePipe.transform(form.get('msaStartDateCtrl').value.singleDate.jsDate, 'yyyy-MM-dd') : null;
        license.msaExpirationDate = form.get('msaExpirationDateCtrl')?.value?.singleDate?.jsDate ? this.datePipe.transform(form.get('msaExpirationDateCtrl').value.singleDate.jsDate, 'yyyy-MM-dd') : null;
        return license;
    }

}
