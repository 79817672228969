import { GQLQueryType } from 'projects/services/src/lib/graphql/queries/gql-query-type';
import { gqlAddressFields } from 'projects/services/src/lib/graphql/queries/entities/gql-address.queries';

export const gqlSignatureFields = `
    signature {
        id
        created
        updated
        
        warrantTruthful
        ownersComplete
        memberTermsReviewed
        platformTermsReviewed
        privacyTermsReviewed
        partnerAttachTermsReviewed
    }
`;

export const gqlNotificationCountField = 'notificationCount';
export const gqlMemberAddressField = `address {${gqlAddressFields}}`;

export const gqlMemberFields = `
    id
    created
    email
    phone
    approvedDate
    status
    name
    memberFunctions
    jurisdiction
    memberType
`;

export const gqlConsumerFields = `
    firstName
    middleName
    lastName
    dateOfBirth
`;

export abstract class GqlMemberQueries {

    static gqlMemberPageFragment = 'MemberPage';

    static searchMembers(): GQLQueryType {
        return {
            params(): String {
                return `$name: String, $lastName: String, $fuzzy: Boolean, $memberId: String, $email: String, 
                    $phone: String, $types: [String], $statuses: [String], $memberFunctions: [String], 
                    $jurisdiction: String, $excludeSelf: Boolean`;
            },

            query(selectedFields: String = gqlMemberFields, pageParams: String): String {
                return `searchMembers(name: $name, lastName: $lastName, fuzzy: $fuzzy, memberId: $memberId, 
                            email: $email, phone: $phone, types: $types, statuses: $statuses, memberFunctions: $memberFunctions, 
                            jurisdiction: $jurisdiction, excludeSelf: $excludeSelf, ${pageParams}){

                        content{
                            ${selectedFields}
                        }
                }`;
            }
        };
    }
}