import { Component, Inject, OnInit } from '@angular/core';
import {
    CorporateKeyParty, IndividualKeyParty, KeyParty, KeyPartyService
} from 'projects/services/src/public-api';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { ErrorType } from 'projects/components/src/public-api';

@Component({
    selector: 'pc-link-key-party-modal',
    templateUrl: './merge-key-party-modal.component.html',
    styleUrls: ['./merge-key-party-modal.component.scss']
})
export class MergeKeyPartyModalComponent extends BaseModalComponent<MergeKeyPartyModalComponent> implements OnInit {

    keyParty: KeyParty;
    errorMessage: { message: string, type: ErrorType };
    selectedIndividualKeyParty: IndividualKeyParty;
    selectedCorporateKeyParty: CorporateKeyParty;
    similarIndividualKeyParties: IndividualKeyParty[] = [];
    similarCorporateKeyParties: CorporateKeyParty[] = [];
    relatedMembers: any = {};

    constructor(private notifier: NotificationService,
                private keyPartyService: KeyPartyService,
                dialog: MatDialogRef<MergeKeyPartyModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialog);
        this.keyParty = data.keyParty;
    }

    ngOnInit() {
        this.isMergeKeyPartyFormValid = this.isMergeKeyPartyFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.keyParty.similarKeyParties.forEach((keyParty: KeyParty) => {
            if (keyParty.corporateKeyPartyId) {
                if (this.relatedMembers[keyParty.corporateKeyPartyId]) {
                    this.relatedMembers[keyParty.corporateKeyPartyId].push(keyParty.member);
                } else {
                    this.similarCorporateKeyParties.push(keyParty.corporateKeyParty);
                    this.relatedMembers[keyParty.corporateKeyPartyId] = [keyParty.member];
                }
            } else {
                if (this.relatedMembers[keyParty.individualKeyPartyId]) {
                    this.relatedMembers[keyParty.individualKeyPartyId].push(keyParty.member);
                } else {
                    this.similarIndividualKeyParties.push(keyParty.individualKeyParty);
                    this.relatedMembers[keyParty.individualKeyPartyId] = [keyParty.member];
                }
            }
        });
    }

    close(refresh?: boolean) {
        super.close(refresh);
    }

    onSubmit(reset: any) {
        const targetId = this.selectedCorporateKeyParty?.id || this.selectedIndividualKeyParty?.id;
        this.keyPartyService.mergeKeyParty(this.keyParty.id, targetId).subscribe(() => {
            this.notifier.showSuccess('Key party has been successfully merged.');
            this.close(true);
        }, (error: any) => {
            reset();
            throw error;
        });
    }

    onIndividualKeyPartyChange(keyParty: IndividualKeyParty) {
        this.selectedIndividualKeyParty = keyParty;
    }

    onCorporateKeyPartyChange(keyParty: CorporateKeyParty) {
        this.selectedCorporateKeyParty = keyParty;
    }

    isMergeKeyPartyFormValid() {
        return this.selectedIndividualKeyParty?.id || this.selectedCorporateKeyParty?.id;
    }

    isSelected(id: string) {
        return this.selectedIndividualKeyParty?.id === id || this.selectedCorporateKeyParty?.id === id;
    }
}
