import { ProcessDefinition } from 'projects/services/src/lib/models/processDefinition';

export class Task {
    id: string;
    name: string;
    assignee: string;
    created: Date;
    due: Date;
    description: string;
    owner: string;
    delegationState: string;
    priority: number;
    processInstanceId: string;
    processDefinitionId: string;
    formKey: string;
    variables: any;
    ProcessDefinition: ProcessDefinition;
}
