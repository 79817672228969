<div>
    <div>
        <div class="modal-header">
            <label class="form-title">Select Merchant Account</label>
            <i class="modal-close" (click)="close()">&times;</i>
        </div>
        <div class="modal-content">
            <div class="row mb-3">
                <div class="col-12">
                    <pc-member-account-selector [class]="'full'" [formGroup]="ultimateCounterPartyForm" [formCtrl]="'counterPartyCtrl'" [placeholder]="'Search Counterparty'"
                        [includeAddress]="true" [accountTypes]="[MemberAccountType.MERCHANT]" [walletFunctions]="[]" [excludedMemberIds]="[originatorMemberId, counterpartyMemberId]"
                        [class]="'full'" (memberAccountSelected)="counterPartyMemberAccountSelected($event)"></pc-member-account-selector>
                </div>
                <div class="col-12 mb-4" *ngIf="isAccountSelected()">
                    <span class="subtitle">Merchant Account Information</span>
                    <div class="row">
                        <div class="col-6">
                            <h6>Address:</h6>
                            <pc-business-address [address]="selectedUltimateCounterpartyMemberAccount.address"></pc-business-address>
                        </div>
                        <div class="col-6 row">
                            <div class="col-6 expandedLabel">
                                Type:
                            </div>
                            <div class="col-6 expandedValue">
                                {{selectedUltimateCounterpartyMemberAccount.member.memberType | translate}}
                            </div>
                            <div class="col-6 expandedLabel" *ngIf="selectedUltimateCounterpartyMemberAccount.member.memberType === MemberType.BUSINESS_MERCHANT">
                                Linked to member:
                            </div>
                            <div class="col-6 expandedValue" *ngIf="selectedUltimateCounterpartyMemberAccount.member.memberType === MemberType.BUSINESS_MERCHANT" [ngClass]="merchantLinkedToMember ? '' : 'error'">
                                {{ this.merchantLinkedToMember ? 'Yes' : 'No' }}
                            </div>
                            <div class="col-6 expandedLabel">
                                Tier:
                            </div>
                            <div class="col-6 expandedValue">
                                {{selectedUltimateCounterpartyMemberAccount.member.tier | translate}}
                            </div>
                            <div class="col-6 expandedLabel">
                                Jurisdiction:
                            </div>
                            <div class="col-6 expandedValue">
                                {{selectedUltimateCounterpartyMemberAccount.member.jurisdiction}}
                            </div>
                            <div class="col-6 expandedLabel" *ngIf="selectedUltimateCounterpartyMemberAccount.member.taxInfo">
                                EIN:
                            </div>
                            <div class="col-6 expandedValue" *ngIf="selectedUltimateCounterpartyMemberAccount.member.taxInfo">
                                {{selectedUltimateCounterpartyMemberAccount.member.taxInfo.ein || 'N/A'}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="warning">
                <pc-error-message [error]="warning" [width]="'full'"></pc-error-message>
            </div>
        </div>
        <div class="modal-footer">
            <button class="blue-button" (click)="close()">Cancel</button>
            <pc-submit-button [enabled]="counterPartyChanged" [submit]="onSubmit" [text]="'Select'"></pc-submit-button>
        </div>
    </div>
</div>
