import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MonthlyReport, AuthService, ReportsService, PagedResponse } from 'projects/services/src/public-api';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pt-member-monthly-statement-report',
    templateUrl: './member-monthly-statement-report.component.html',
    styleUrls: ['./member-monthly-statement-report.component.scss']
})
export class MemberMonthlyStatementReportComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

    @Input() memberId: string;
    @Input() memberAccountId: string;

    isLoadingResults = true;

    displayedColumns: string[] = ['month', 'statement'];
    monthlyReports: MonthlyReport[] = [];

    pageTracking: PageTracking;
    resultsLength = 0;
    subscription: any;
    refreshEvent: EventEmitter<null> = new EventEmitter<null>();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(public authService: AuthService,
                private route: ActivatedRoute,
                private router: Router,
                private reportService: ReportsService,
                private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        if (!this.memberId) {
            this.memberId = this.authService.getProfile().memberId;
        }
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'reportEndDate', 'desc', 100);
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.memberAccountId && !changes.memberAccountId.firstChange)) {
            this.refreshEvent.emit();
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.pageIndex = 0;
        });
        this.subscription = merge(this.paginator.page, this.refreshEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                return this.reportService.getMonthlyReportsByMemberIdAndAccountId(this.memberId, this.memberAccountId, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<MonthlyReport>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((monthlyReports: MonthlyReport[]) => {
            this.monthlyReports = monthlyReports;
            UIUtils.scrollDashboardToTop();
        });
    }

    viewPdf(monthlyReport: MonthlyReport) {
        this.reportService.viewPdf(monthlyReport.id);
    }

    viewCsv(monthlyReport: MonthlyReport) {
        this.reportService.viewCsv(monthlyReport.id);
    }

    handlePageBottom(event: PageEvent) {
        this.paginator.pageSize = event.pageSize;
        this.paginator.pageIndex = event.pageIndex;
        this.paginator.page.emit(event);
    }
}
