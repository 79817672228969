<div class="col-sm-12 horizontal-scroll mat-table-container mt-3">
     <div class="mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div> 
    <table mat-table [dataSource]="nachaReportData">
        <ng-container matColumnDef="processorAccount">
            <th mat-header-cell class="left" *matHeaderCellDef> Processor Account </th>
            <td mat-cell class="left" *matCellDef="let element">
                <a [routerLink]="['/member-accounts/details/' + element.memberAccountId]"
                [queryParams]="{_transactionTab: 'TRANSACTION_LIST', txnType: 'ACH'}"
                [routerLinkActive]="['router-link-active']">
                 {{ element.memberName + ' (' + element.accountName + ')' }}
             </a>
            </td>
        </ng-container>
        <ng-container matColumnDef="administrative">
            <th mat-header-cell class="centered" *matHeaderCellDef> Administrative Returns % </th>
            <td mat-cell class="centered" *matCellDef="let element"><span> {{ element?.administrativeReturnsPercentage }}</span></td>
        </ng-container>
        <ng-container matColumnDef="unauthorized">
            <th mat-header-cell class="centered" *matHeaderCellDef> Unauthorized Returns % </th>
            <td mat-cell class="centered" *matCellDef="let element"> <span> {{ element?.unauthorizedReturnsPercentage }}</span></td>
        </ng-container>
        <ng-container matColumnDef="overall">
            <th mat-header-cell class="centered" *matHeaderCellDef> Overall Returns % </th>
            <td mat-cell class="centered" *matCellDef="let element">
                <span> {{ element?.overallReturnsPercentage }} </span>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
