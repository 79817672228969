import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { RecordsService, PagedResponse, Shipment, MetrcSummary } from 'projects/services/src/public-api';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ViewChild, SimpleChanges, OnDestroy, EventEmitter, Component, Input, OnInit, OnChanges, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';


@Component({
    selector: 'pc-member-metrc-transfers-list',
    templateUrl: './member-metrc-transfers-list.component.html',
    styleUrls: ['./member-metrc-transfers-list.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class MemberMetrcTransfersListComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

    @Input() memberId: string;
    @Input() startDate = '';
    @Input() endDate = '';
    @Input() source = '';
    @Input() counterParty: string;
    @Input() recipientId: string;
    @Input() shipperId: string;
    @Input() displaySummary = true;
    @Input() transactionId = '';

    shipments: Shipment[] = [];
    expandedElement: Shipment;
    displayedColumns = ['id', 'supplier', 'recipient', 'created', 'received_date_time', 'transfer_type'];
    refreshEvent: EventEmitter<null> = new EventEmitter<null>();
    subscription: any;
    pageTracking: PageTracking;
    isLoadingResults = true;
    resultsLength = 0;
    transfersSummaryList: MetrcSummary[];
    loadSummary = true;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private recordsService: RecordsService,
                private route: ActivatedRoute,
                private router: Router,
                private cdr: ChangeDetectorRef) {}

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnChanges(change: SimpleChanges) {
        if ((change.startDate && !change.startDate.firstChange) || (change.counterParty && !change.counterParty.firstChange) || (change.endDate && !change.endDate.firstChange)
            || (change.source && !change.source.firstChange) || (change.transactionId && !change.transactionId.firstChange)) {
            this.loadSummary = true;
            this.refreshEvent.emit();
        }
    }

    ngOnInit() {
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'receivedDateTime', 'desc');
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.paginator.page, this.refreshEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                if (this.transactionId) {
                    return this.recordsService.findPossibleShipments(this.transactionId, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
                } else if (this.shipperId || this.recipientId) {
                    return this.recordsService.findAllRelatedShipments(this.memberId, this.shipperId, this.recipientId, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
                } else {
                    return this.recordsService.findShipmentsByOwner(this.memberId, this.source, this.counterParty, this.startDate, this.endDate, false, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
                }
            }),
            map((response: PagedResponse<Shipment>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((shipments: Shipment[]) => {
            this.shipments = shipments;
            if (this.resultsLength >= 0 && this.loadSummary) {
                this.loadPackagesSummary(this.resultsLength);
            }
            UIUtils.scrollDashboardToTop();
        });
    }

    loadPackagesSummary(resultsLength: number) {
        this.transfersSummaryList = [];
        this.recordsService.findShipmentsByOwnerSummary(this.memberId, this.source, this.counterParty, resultsLength, this.startDate, this.endDate).subscribe((response: any) => {
            const categories = Object.keys(response);
            this.transfersSummaryList = this.recordsService.getMetrcSummary(response, categories);
            this.loadSummary = false;
        });
    }

    getSource(manifestSource: string) {
        if (manifestSource === 'Metrc Incoming') {
            return 'Incoming';
        } else if (manifestSource === 'Metrc Outgoing') {
            return 'Outgoing';
        }
        return '';
    }

}
