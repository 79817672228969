import { GQLQueryType } from 'projects/services/src/lib/graphql/queries/gql-query-type';

export const gqlRfiFields = 'id, status, created, updated, transactionId';

/**
 * Each method of this class returns a [@GQLQueryType](projects/services/src/lib/graphql/queries/gql-query-type) object.
 * We could create one class that implements the @GQLQueryType as well, but instead, we're using the anonymous implementation
 * of the `GQLQueryType` interface.
 */
export abstract class GqlRfiQueries {

    static gqlRFIPageFragment = 'RFIPage';

    static searchRFIs(): GQLQueryType {
        return {
            params() {
                return '$memberId: String, $transactionId: String, $status: String, $transactionTypes: [String]';
            },
            query(selectedFields: String = gqlRfiFields, pageParams?: String): String {
                return `searchRfis(memberId: $memberId, transactionId: $transactionId, status: $status, transactionTypes: $transactionTypes, ${pageParams}){
                        content{
                            ${selectedFields}
                        }
                }`;
            }
        };
    }

    static findById(): GQLQueryType {
        return {
            params() {
                return '$id: ID!';
            },
            query(selectedFields: String = gqlRfiFields): String {
                return `rfiById(id: $id){
                        ${selectedFields}
                }`;
            }
        };
    }
}