import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ComponentPortal } from '@angular/cdk/portal';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Overlay } from '@angular/cdk/overlay';
import { BaseModalComponent } from '../base-modal.component';
import { CustomValidators, User, UserService, UserUpdatableField } from 'projects/services/src/public-api';
import { ErrorType, LoaderComponent } from 'projects/components/src/public-api';

@Component({
    selector: 'pc-user-field-update-modal',
    templateUrl: './user-field-update-modal.component.html'
})
export class UserFieldUpdateModalComponent extends BaseModalComponent<UserFieldUpdateModalComponent> implements OnInit {

    UserUpdatableField = UserUpdatableField;

    user: User;
    fieldType: UserUpdatableField;

    fieldUpdateForm: UntypedFormGroup;
    title: string;
    label: string;
    currentValue: string;
    warning: { message: string, type: ErrorType };

    constructor(dialogRef: MatDialogRef<UserFieldUpdateModalComponent>,
                private overlay: Overlay,
                private userService: UserService,
                private formBuilder: UntypedFormBuilder,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.user = { ...data.user};
        this.fieldType = data.fieldType;
    }

    ngOnInit() {
        this.confirm = this.confirm.bind(this);
        this.close = this.close.bind(this);
        this.cancel = this.cancel.bind(this);
        this.formValid = this.formValid.bind(this);
        const validators = [Validators.required];
        if (this.fieldType === UserUpdatableField.PHONE) {
            this.title = 'Change Mobile Phone Number';
            this.label = 'Mobile Number';
            this.currentValue = this.user.mobilePhone;
            this.warning = {
                type: ErrorType.WARN,
                message: 'If this user has MFA enabled and set to SMS, all further notifications will to the above number after changing.'
            };
            validators.push(CustomValidators.phone);
        } else if (this.fieldType === UserUpdatableField.EMAIL) {
            this.title = 'Change Email Address';
            this.label = 'Email Address';
            this.currentValue = this.user.email;
            this.warning = {
                type: ErrorType.WARN,
                message: 'This user will have to reset their password after this email address change.<br>  That may also include updating their TOTP authenticator if they have MFA set up and set to TOTP.'
            };
            validators.push(CustomValidators.email);
        }
        this.fieldUpdateForm = this.formBuilder.group({
            oldValueCtrl: new UntypedFormControl(this.currentValue),
            newValueCtrl: new UntypedFormControl('', validators)
        });
        this.fieldUpdateForm.controls['oldValueCtrl'].disable();
    }

    formValid() {
        return this.fieldUpdateForm.valid && this.currentValue !== this.fieldUpdateForm.controls['newValueCtrl'].value;
    }

    confirm(reset: any) {
        if (this.fieldUpdateForm.valid) {
            const overlayRef = this.overlay.create({
                positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                hasBackdrop: true
            });
            const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
            componentRef.instance.title = 'Updating user.  Please wait...';

            const newValue = this.fieldUpdateForm.controls['newValueCtrl'].value;
            let updates = {};
            if (this.fieldType === UserUpdatableField.PHONE) {
                updates['mobilePhone'] = newValue;
            } else if (this.fieldType === UserUpdatableField.EMAIL) {
                updates['email'] = newValue;
            }
            this.userService.updateUser(this.user.id, updates).subscribe(() => {
                overlayRef.detach();
                this.close(true);
            }, (error: any) => {
                overlayRef.detach();
                reset();
                throw error;
            });
        }
    }

    close(refresh?: boolean) {
        super.close(refresh);
    }

    cancel() {
        super.close(false);
    }
}
