import { Inject, Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { CorporateKeyParty, IndividualKeyParty, KeyParty, KeyPartyStatus, KeyPartyType } from './models/key-party';

@Injectable({
    providedIn: 'root'
})
export class KeyPartyService {

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.EntityAPIEndpoint + '/';
    }

    saveKeyParty(keyParty: KeyParty) {
        return this.http.post(`${this.baseUrl}key_party`, keyParty);
    }

    getAllActiveKeyParties(memberId: string) {
        const params = `?memberId=${memberId}&types=INDIVIDUAL,CORPORATE,CORPORATE_ENTITY&sort=percentOwnership&sortDir=DESC`;
        return this.http.get(`${this.baseUrl}key_party${params}`);
    }

    getAllKeyPartiesByType(memberId: string, type: KeyPartyType) {
        const params = `?memberId=${memberId}&types=${type}&statuses=ACTIVE,DISABLED&sort=percentOwnership&sortDir=DESC`;
        return this.http.get(`${this.baseUrl}key_party${params}`);
    }

    getKeyPartiesByStatus(memberId: string, start: number, size: number, sort: string, sortDir: string, status: KeyPartyStatus) {
        const params = `?memberId=${memberId}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}&statuses=${status}`;
        return this.http.get(`${this.baseUrl}key_party${params}`);
    }

    getCorporateEntityKeyParty(memberId: string) {
        const params = `?memberId=${memberId}&types=CORPORATE_ENTITY&sort=percentOwnership&sortDir=DESC`;
        return this.http.get(`${this.baseUrl}key_party${params}`);
    }

    updateKeyParty(keyPartyId: string, body: any) {
        return this.http.put(`${this.baseUrl}key_party/${keyPartyId}`, body);
    }

    saveIndividualKeyParty(individualKeyParty: IndividualKeyParty) {
        return this.http.post(`${this.baseUrl}key_party/individual`, individualKeyParty);
    }

    saveCorporateKeyParty(corporateKeyParty: CorporateKeyParty) {
        return this.http.post(`${this.baseUrl}key_party/corporation`, corporateKeyParty);
    }

    getKeyPartyByMemberIdAndUserId(memberId: string, userId: string) {
        return this.http.get(`${this.baseUrl}key_party/${memberId}/user/${userId}`);
    }

    findCorporateKeyParties(value: string, searchLimit?: number) {
        return this.http.get(`${this.baseUrl}key_party/corporation?searchTerm=${encodeURIComponent(value)}&size=${searchLimit || ''}`);
    }

    findIndividualKeyParties(value: string, searchLimit?: number) {
        return this.http.get(`${this.baseUrl}key_party/individual?searchTerm=${encodeURIComponent(value)}&size=${searchLimit || ''}`);
    }

    getSimilarIndividualKeyParties(individualKeyPartyId: string) {
        return this.http.get(`${this.baseUrl}key_party/individual/${individualKeyPartyId}/similar`);
    }

    getSimilarCorporateKeyParties(corporateKeyPartyId: string) {
        return this.http.get(`${this.baseUrl}key_party/corporation/${corporateKeyPartyId}/similar`);
    }

    mergeKeyParty(keyPartyId: string, sourceId: string) {
        return this.http.put(`${this.baseUrl}key_party/${keyPartyId}/merge/${sourceId}`, {});
    }
}
