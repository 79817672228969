import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { Observable, merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import {
    Address, AddressService, AuthService, CommentService, CommentType, Member, MemberAccount, MemberAccountService, MemberAccountStatus, MemberFunction, MemberService, MemberStatus, MemberType,
    MetrcService, PagedResponse, ReportsService, RiskTier, Signature, Utils, WorkflowService
} from 'projects/services/src/public-api';
import { ConfirmModalComponent, LoaderComponent } from 'projects/components/src/public-api';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pt-members-list',
    templateUrl: './members-list.component.html',
    styleUrls: ['./members-list.component.scss']
})
export class MembersListComponent implements OnInit, OnChanges, AfterViewInit {

    MemberStatus = MemberStatus;
    MemberFunction = MemberFunction;
    MemberType = MemberType;
    RiskTier = RiskTier;
    Utils = Utils;

    @Input() filter: string;
    @Input() activeTab: string;
    @Input() memberId: string;

    filterEvent: EventEmitter<null> = new EventEmitter<null>();
    isLoadingResults = true;
    resultsLength = 0;
    pageTracking: PageTracking;
    displayedColumns = [];
    consumerView = false;

    membersObservable: Observable<Array<Member>>;

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private route: ActivatedRoute,
                private addressService: AddressService,
                private commentService: CommentService,
                private memberService: MemberService,
                private memberAccountService: MemberAccountService,
                private metrcService: MetrcService,
                private router: Router,
                private workflowService: WorkflowService,
                public authService: AuthService,
                private reportsService: ReportsService,
                private dialog: MatDialog,
                private overlay: Overlay,
                private notificationService: NotificationService,
                private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'sortName', 'asc');
        this.initializeDisplayColumns();
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.memberId && !changes.memberId.firstChange) ||
            (changes.activeTab && !changes.activeTab.firstChange)) {
            if (changes.activeTab) {
                this.paginator.firstPage();
                this.initializeDisplayColumns();
            }
            this.filterEvent.emit();
        }
    }

    initializeDisplayColumns() {
        this.consumerView = false;
        if (this.activeTab === 'members') {
            this.displayedColumns = ['business_name', 'contact_info', 'status', 'approved_date', 'enabled'];
        } else if (this.activeTab === 'fee_accounts') {
            this.displayedColumns = ['business_name'];
        } else if (this.activeTab === 'consumers') {
            this.consumerView = true;
            this.displayedColumns = ['business_name', 'contact_info', 'status', 'created', 'enabled'];
        } else if (this.activeTab === 'under_review') {
            this.displayedColumns = ['business_name', 'contact_info', 'notifications', 'status', 'submitted_date', 'action'];
        } else if (this.activeTab === 'applied') {
            this.displayedColumns = ['business_name', 'contact_info', 'notifications', 'status', 'created', 'action'];
        }
    }

    get memberTypesAndStatusesByActiveTab() {
        switch (this.activeTab) {
            case 'members':
                return {
                    types: [MemberType.BUSINESS],
                    statuses: [MemberStatus.ACTIVE, MemberStatus.DISABLED]
                };
            case 'fee_accounts':
                return {
                    types: [MemberType.FEE_ACCOUNT],
                    statuses: [MemberStatus.ACTIVE]
                };
            case 'consumers':
                return {
                    types: [MemberType.CONSUMER],
                    statuses: [MemberStatus.ACTIVE, MemberStatus.DISABLED, MemberStatus.BLOCKED, MemberStatus.FROZEN]
                };
            case 'under_review':
                return {
                    types: [MemberType.BUSINESS, MemberType.CONSUMER],
                    statuses: [MemberStatus.UNDER_REVIEW]
                };
            case 'applied':
                return {
                    types: [MemberType.BUSINESS, MemberType.CONSUMER],
                    statuses: [MemberStatus.APPLIED]
                };
        }
    }

    get isLoadComments() {
        if (this.activeTab === 'under_review' || this.activeTab === 'applied') {
            return true;
        }

        return false;
    }

    get isLoadSignature() {
        if (this.activeTab === 'under_review') {
            return true;
        }

        return false;
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.membersObservable = merge(this.sort.sortChange, this.paginator.page, this.filterEvent).pipe(
            startWith({}),
            switchMap(() => {
                UIUtils.scrollToTop(document.querySelector('.mat-table-container'));
                this.isLoadingResults = true;

                const typesAndStatuses = this.memberTypesAndStatusesByActiveTab;

                return this.memberService.getMembersByTypesAndStatus(
                    this.memberId,
                    typesAndStatuses.types,
                    typesAndStatuses.statuses,
                    [],
                    this.paginator.pageIndex,
                    this.paginator.pageSize,
                    this.sort.active,
                    this.sort.direction,
                    this.isLoadComments,
                    this.isLoadSignature,
                    true);
            }),
            map((response: PagedResponse<Member>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        );
    }

    memberDetails(event: any, member: Member) {
        if (event.target && event.target.tagName !== 'I') {
            if (member.status === MemberStatus.UNDER_REVIEW) {
                this.memberAccountService.getAllMemberBusinessAccounts(member.id).subscribe((result: PagedResponse<MemberAccount>) => {
                    // FIXME: clean this up once all old onboarding flows are gone
                    const isLegacyOnboarding = result.content.find((memberAccount) => {
                        // legacy flows have an account that is UNDER_REVIEW with an account number already assigned
                        return memberAccount.status === MemberAccountStatus.UNDER_REVIEW && memberAccount.accountNumber;
                    });
                    const isAccountPending = result.content.find((memberAccount) => {
                        // members that have been approved, but the account has not have
                        return memberAccount.status === MemberAccountStatus.PENDING || memberAccount.status === MemberAccountStatus.UNDER_REVIEW;
                    });
                    if (isLegacyOnboarding) {
                        // open the old workflow application
                        this.workflowService.loadTaskByProcessDefinitionWithVariables(['onboard_pt_member'], `memberId_eq_${member.id}`);
                    } else if (isAccountPending) {
                        // the application has been approved, but the account not approved yet
                        this.workflowService.loadTaskByProcessDefinitionWithVariables(['register_member_account_v2'], `memberId_eq_${member.id}`);
                    } else {
                        // we have a new application that has not yet been approved
                        this.workflowService.loadTaskByProcessDefinitionWithVariables(['register_member_v2'], `memberId_eq_${member.id}`);
                    }
                });
            } else {
                // go see the application or user view
                this.router.navigate(['/administration/member/', member.id]);
            }
        }
    }

    isEnabled(member: Member) {
        return member && member.status !== MemberStatus.DISABLED;
    }

    toggleEnabled(event: any, member: Member) {
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
        if (this.isEnabled(member)) {
            if (member.memberType === MemberType.BUSINESS && member.tier !== RiskTier.NA && member.tier !== RiskTier.NOT_TIERED) {
                componentRef.instance.title = 'Validating last SAR submission and no outstanding transactions…';
                this.reportsService.validateDisabledMember(member.id).subscribe((response: boolean) => {
                    if (response) {
                        componentRef.instance.title = 'Disabling member and accounts. Please wait...';
                        this.memberService.updateMember(member.id, { status: MemberStatus.DISABLED }).subscribe(() => {
                            if (member.memberType === MemberType.BUSINESS) {
                                this.metrcService.disableAllActiveLicenses(member.id).subscribe(
                                    () => {
                                        this.reportsService.generateFinalContinuingReport(member.id).subscribe(
                                            () => {
                                                overlayRef.dispose();
                                                this.filterEvent.emit();
                                                this.notificationService.showInfo('Successfully generated final continuing report.');
                                            },
                                            (error: any) => {
                                                overlayRef.dispose();
                                                throw error;
                                            }
                                        );
                                    },
                                    (error: any) => {
                                        overlayRef.dispose();
                                        throw error;
                                    }
                                );
                            } else {
                                overlayRef.dispose();
                                this.filterEvent.emit();
                            }
                        }, (error: any) => {
                            overlayRef.dispose();
                            throw error;
                        });
                    }
                }, (error: any) => {
                    overlayRef.dispose();
                    throw error;
                });
            } else {
                componentRef.instance.title = 'Disabling member and accounts. Please wait...';
                this.memberService.updateMember(member.id, { status: MemberStatus.DISABLED }).subscribe(() => {
                    overlayRef.dispose();
                    this.filterEvent.emit();
                }, (error: any) => {
                    overlayRef.dispose();
                    throw error;
                });
            }
        } else {
            componentRef.instance.title = 'Enabling member and accounts.  Please wait...';
            this.memberService.updateMember(member.id, { status: MemberStatus.ACTIVE }).subscribe((member: Member) => {
                if (member.memberType === MemberType.BUSINESS && member.status === MemberStatus.ACTIVE) {
                    this.reportsService.generateInitialReport(member).subscribe(() => {
                        // Generating NBCU SAR initial report for the enabled member if there isn't one when the authority enables a disabled member.
                    });
                }
                overlayRef.dispose();
                this.filterEvent.emit();
            });
        }
        event.cancelBubble = true;
        if (event.stopImmediatePropagation) {
            event.stopImmediatePropagation();
        }
    }

    deleteApplicant(member: Member) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.data = {
            title: this.activeTab === 'applied' ? 'Delete Applying Member' : 'Delete Member Under Review',
            description: `You are about to delete <strong>${member.name}</strong> from Confia. This member will have to start the application over from scratch to reapply in the future.`,
            confirmMessage: 'Are you sure?',
            confirmText: 'DELETE MEMBER'
        };
        const dialog = this.dialog.open(ConfirmModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((confirmAction: any) =>  {
            if (confirmAction === 'confirmed') {
                const overlayRef = this.overlay.create({
                    positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                    hasBackdrop: true
                });
                const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
                componentRef.instance.title = 'Deleting application...';
                this.memberService.deleteApplicant(member.id).subscribe(() => {
                    overlayRef.dispose();
                    if (this.memberId) {
                        this.memberId = null;
                    }
                    this.filterEvent.emit();
                }, (error: Error) => {
                    overlayRef.dispose();
                    throw error;
                });
            }
        });
    }
}
