export const gqlAddressFields = `
    id
    created
    updated
    
    streetAddressOne
    streetAddressTwo
    city
    stateProvince
    country
    zipPostalCode
    phoneNumber
`;