import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy,  OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { MergeCheckDetailsModelComponent } from 'projects/components/src/public-api';
import { CheckDetails, DataroomService, DocumentType, Member, MemberAccount, PagedResponse, PaymentService, SupportingDocument, Transaction, TransactionState, TransactionStatus, TransactionTableFilter, TransactionType, Upload } from 'projects/services/src/public-api';
import { catchError, forkJoin, map, merge, mergeMap, of, startWith } from 'rxjs';

@Component({
    selector: 'pt-outstanding-checks',
    templateUrl: './outstanding-check-transaction-list.component.html'
})
export class OutStandingCheckDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() payeeMember: Member;
    @Input() payorMember: Member;
    @Input() payorAccount: MemberAccount;
    @Input() externalCheckAmount: number;
    @Input() checkNumber: string;
    @Input() checkCreationDate: Date;

    isLoadingResults = true;
    pageTracking: PageTracking;
    transactions: Transaction [] = [];
    readOnly: Boolean = true;
    displayedColumns = ['Payor', 'Recipient', 'Transaction Date', 'Check Number', 'Amount', 'Transaction Type', 'Document', 'Action'];
    filter: TransactionTableFilter;
    resultsLength = 0;
    resetEvent: any;
    @Output() downloadEvent: EventEmitter<Upload> = new EventEmitter<Upload>();
    @Output() mergeTransactionEvent: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    subscription: any;

    constructor(
        private router: Router,
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        private paymentService: PaymentService,
        private dataroomService: DataroomService,
        private dialog: MatDialog) {}

    ngOnInit() {
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'transactionDate', 'desc', 10);
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.sort.sortChange, this.paginator.page).pipe(
            startWith({}),
            mergeMap(() => {
                this.isLoadingResults = true;
                return this.paymentService.getTransactionsByStatusAndType(
                    '', this.payorAccount.id, TransactionState.PENDING,
                    [TransactionType.OUTGOING_CHECK_TO_MERCHANT, TransactionType.OUTGOING_DIGITAL_CHECK_TO_MERCHANT], [], '',
                    [], '', '', '', '', '', this.paginator.pageIndex, this.paginator.pageSize,
                    this.sort.active, this.sort.direction
                );
            }),
            map((response: PagedResponse<Transaction>) => {
                return response.content || [];
            }),
            mergeMap((transactions: Transaction[]) => {
                const pendingTransactions = transactions.filter((transaction) => {
                    return transaction.status === TransactionStatus.PENDING_PAYMENT_VERIFICATION;
                });
                this.resultsLength = pendingTransactions.length;
                if (pendingTransactions.length === 0) {
                    this.isLoadingResults = false;
                    return of([]);
                }
                const transactionObservables = pendingTransactions.map((transaction) => {
                    return this.paymentService.getTransactionSupportingDocuments(transaction.id).pipe(
                        map((supportingDocuments: PagedResponse<SupportingDocument>) => {
                            this.filterSupportingDocuments(transaction, supportingDocuments.content);
                            return transaction;
                        })
                    );
                });
                return forkJoin(transactionObservables);
            }),
            mergeMap((transactions: Transaction[]) => {
                if (transactions.length === 0) {
                    this.isLoadingResults = false;
                    return of([]);
                }
                const documentObservables = transactions.map((transaction) => {
                    const documentRequests = transaction.supportingDocuments.map((supportingDoc) => {
                        return this.dataroomService.getDocumentById(supportingDoc.documentId)
                            .pipe(map((upload: Upload) => {
                                supportingDoc.document = upload;
                            }));
                    });
                    const checkDetailRequest = transaction.checkId
                        ? this.paymentService.getCheckDetailsById(transaction.checkId).pipe(
                            map((data: CheckDetails) => {
                                transaction.checkNumber = data.checkNumber;
                            })
                        )
                        : of(null);
                    return forkJoin([...documentRequests, checkDetailRequest]).pipe(
                        map(() => {
                            return transaction;
                        })
                    );
                });
                return forkJoin(documentObservables);
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return of([]);
            })
        ).subscribe((loadedTransactions: Transaction[]) => {
            this.transactions = loadedTransactions;
            UIUtils.scrollDashboardToTop();
            this.isLoadingResults = false;
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    downloadCheck(document: Upload) {
        this.downloadEvent.emit(document);
    }

    loadCheckNumbers(transaction: Transaction) {
        if (transaction.checkId) {
            this.paymentService.getCheckDetailsById(transaction.checkId).subscribe((data: CheckDetails) => {
                transaction.checkNumber = data.checkNumber;
            });
        }
    }

    filterSupportingDocuments(transaction: Transaction, supportingDocuments: SupportingDocument []) {
        transaction.supportingDocuments = supportingDocuments.filter((supportingDocument) => {
            return supportingDocument.documentType === DocumentType.DIGITAL_CHECK_TO_MERCHANT;
        });
    }


    handleMerge(transaction?: Transaction) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.width = '67%';
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            existingTransaction: transaction,
            payeeMember: this.payeeMember,
            payorMember: this.payorMember,
            externalCheckAmount: this.externalCheckAmount,
            externalCheckNumber: this.checkNumber,
            checkCreationDate: this.checkCreationDate
        };
        const dialog = this.dialog.open(MergeCheckDetailsModelComponent, dialogConfig);

        dialog?.afterClosed().subscribe((data: any) => {
            if (data?.mergeTransaction && data?.transactionId) {
                this.mergeTransactionEvent.emit(data.transactionId);
            }
        });
    }

    submitEvent(reset?: () => void) {
        this.resetEvent = reset;
        setTimeout(() => {
            reset();
        }, 500);
    }

    handlePageBottom(event: PageEvent) {
        this.paginator.pageSize = event.pageSize;
        this.paginator.pageIndex = event.pageIndex;
    }

    loadTransactionDetails(transaction: Transaction, event: any) {
        if (event.target.tagName === 'TD') {
            this.router.navigate(['/transaction/details/', transaction.id]);
        }
    }
}