import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { HomeComponent } from './home.component';
import { HomeRoutingModule } from './home-routing.module';
import { SharedComponentsModule } from 'projects/components/src/lib/shared-components.module';

import {
    AddMerchantModalComponent, AddWhitelistRelationshipPartyModalComponent, AuthorityPaymentModalComponent, BankingIdentificationModalComponent,
    BusinessCashDepositComponent, DepositFundsModalComponent, DepositModalComponent, MemberLinkedSystemsComponent, MetrcAccountDetailsComponent, MetrcAccountModalComponent,
    BusinessNonCashDepositComponent, RetailCashDepositComponent, RetailPaymentModalComponent, BusinessClientStepComponent, TransactionEntrySelectionStepComponent,
    TrackingSelectionStepComponent, ReviewTransactionStepComponent, RetailInvoiceSelectionStepComponent, PaymentModalComponent, BusinessPaymentComponent, DepositMethodComponent,
    PaymentMethodComponent, ConfiaPaymentComponent, ConfiaClientStepComponent, HeaderComponent, NewMemberHeaderComponent, AuthoritySidebarComponent, ConsumerSidebarComponent,
    MemberSidebarComponent, RetailerSidebarComponent, SidebarComponent, LinkMetrcSystemComponent, LinkAccountingSystemComponent, EditMerchantAccountingIdModalComponent,
    AddAccountingMerchantModalComponent, CurrencyWithdrawalModalComponent, AddConnectedMemberModalComponent, LinkAccountingMerchantModalComponent, UpdateTransactionLineItemsComponent
} from '../components';
import { MemberSummaryComponent } from '../members';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HomeRoutingModule,
        TranslateModule,
        SharedComponentsModule,
        NgbModule,
        MatButtonToggleModule,
        MatTableModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatSortModule,
        MatStepperModule,
        MatIconModule,
        MatTooltipModule,
        MatCardModule
    ],

    declarations: [
        HomeComponent,
        HeaderComponent,
        NewMemberHeaderComponent,
        SidebarComponent,
        MemberSidebarComponent,
        AuthoritySidebarComponent,
        ConsumerSidebarComponent,
        RetailerSidebarComponent,
        DepositFundsModalComponent,
        MemberSummaryComponent,
        MemberLinkedSystemsComponent,
        MetrcAccountModalComponent,
        AuthorityPaymentModalComponent,
        AddMerchantModalComponent,
        MetrcAccountDetailsComponent,
        AddWhitelistRelationshipPartyModalComponent,
        RetailPaymentModalComponent,
        BankingIdentificationModalComponent,
        LinkMetrcSystemComponent,
        LinkAccountingSystemComponent,
        DepositModalComponent,
        BusinessClientStepComponent,
        BusinessCashDepositComponent,
        CurrencyWithdrawalModalComponent,
        BusinessNonCashDepositComponent,
        RetailCashDepositComponent,
        TransactionEntrySelectionStepComponent,
        TrackingSelectionStepComponent,
        ReviewTransactionStepComponent,
        RetailInvoiceSelectionStepComponent,
        PaymentModalComponent,
        BusinessPaymentComponent,
        DepositMethodComponent,
        PaymentMethodComponent,
        ConfiaPaymentComponent,
        ConfiaClientStepComponent,
        EditMerchantAccountingIdModalComponent,
        AddAccountingMerchantModalComponent,
        AddConnectedMemberModalComponent,
        LinkAccountingMerchantModalComponent,
        UpdateTransactionLineItemsComponent
    ],

    exports: [
        DepositFundsModalComponent,
        MemberLinkedSystemsComponent,
        AuthorityPaymentModalComponent,
        AddWhitelistRelationshipPartyModalComponent,
        MemberSummaryComponent,
        RetailPaymentModalComponent,
        DepositModalComponent,
        BusinessClientStepComponent,
        BusinessCashDepositComponent,
        CurrencyWithdrawalModalComponent,
        BusinessNonCashDepositComponent,
        RetailCashDepositComponent,
        TransactionEntrySelectionStepComponent,
        TrackingSelectionStepComponent,
        ReviewTransactionStepComponent,
        RetailInvoiceSelectionStepComponent,
        PaymentModalComponent,
        BusinessPaymentComponent,
        DepositMethodComponent,
        PaymentMethodComponent,
        ConfiaPaymentComponent,
        ConfiaClientStepComponent,
        EditMerchantAccountingIdModalComponent,
        AddAccountingMerchantModalComponent
    ],

    providers: [
    ]
})
export class HomeModule {}
