import { Component, OnInit } from '@angular/core';
import { NachaComplianceReport } from 'projects/services/src/lib/models/nacha-compliance-report';
import { PaymentService } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-authority-nacha-compliance-report',
    templateUrl: './authority-nacha-compliance-report.html',
    styleUrls: []
})
export class NachaComplianceReportComponent implements OnInit {

    displayedColumns: string[] = ['processorAccount', 'administrative', 'unauthorized', 'overall'];
    nachaReportData: NachaComplianceReport[] = [];
    isLoadingResults: Boolean = true;
    resultsLength: Number = 0;

    constructor(private paymentsService: PaymentService) { }

    ngOnInit() {
        this.paymentsService.getNachaComplianceReport().subscribe((data: any) => {
            this.isLoadingResults = false;
            this.nachaReportData = data;
            this.resultsLength = data.length;
        }, (error: any) => {
            throw new Error('Error occurred while fetching the report details');
        });
    }
}