import { Component, ElementRef, EventEmitter, Inject, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AuthService, Bill, ExternalAccountingId, Invoice, Member, MemberAccount, PagedResponse, RecordsService, Transaction, TransactionEntrySelection,
    TransactionSubType, Utils, WorkflowService, RfiService, RfiHistory, Rfi
} from 'projects/services/src/public-api';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTable } from '@angular/material/table';
import { BaseModalComponent, ErrorType, LoaderComponent } from 'projects/components/src/public-api';
import { forkJoin, of } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay } from '@angular/cdk/overlay';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pt-update-transaction-line-items',
    templateUrl: './update-transaction-line-items.component.html',
    styleUrls: ['./update-transaction-line-items.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class UpdateTransactionLineItemsComponent extends BaseModalComponent<UpdateTransactionLineItemsComponent> implements OnInit, OnChanges {

    memberAccount: MemberAccount;
    businessClientAccount: MemberAccount;
    currentBusinessClient: Member;
    memberId: string;
    businessClientAccountId: string;
    merchant: Member;
    transaction: Transaction;
    TransactionSubType = TransactionSubType;
    accountingExternalIds: ExternalAccountingId[] = [];
    rfi: Rfi;

    isAccountingLinked = false;
    amountMismatchError: { message: string, type: ErrorType };
    amountMatched = false;
    subType: TransactionSubType;
    isUniversalWhiteListedMerchant = false;
    addButtonEnabled = false;
    value: string;
    entriesTotal: string;
    existingEntryIds = [];
    transactionEntriesSelected: EventEmitter<TransactionEntrySelection<Invoice | Bill>[]> = new EventEmitter();

    transactionEntriesLoading = false;
    customerTransactionEntries: TransactionEntrySelection<Invoice | Bill>[] = [];
    errorMessage: { message: string, type: ErrorType };

    entryHeaders: string[] = ['entry_number', 'entry_date', 'entry_due_date', 'entry_unpaid_amount', 'entry_paid_amount', 'entry_included'];
    entryColumns: string[] = ['entry_number', 'entry_date', 'entry_due_date', 'entry_unpaid_amount', 'entry_paid_amount', 'entry_included'];
    otherEntryColumns: string[] = ['other_entry_description_label', 'other_entry_description', 'other_entry_amount_label', 'other_entry_amount', 'other_entry_include'];
    summaryColumns: string[] = ['empty', 'empty', 'empty', 'entry_unpaid_amount', 'entry_paid_amount', 'summary_add_row'];
    totalTransactionAmountColumns: string[] = ['empty', 'empty', 'empty', 'total_transaction_amount_label', 'total_transaction_amount_value', 'empty'];


    expandedElement: TransactionEntrySelection<Invoice | Bill>;

    @ViewChild('transactionEntriesTable') table: MatTable<any>;
    @ViewChild('upload') uploadLink: ElementRef;

    constructor(private recordsService: RecordsService,
                private overlay: Overlay,
                private workflowService: WorkflowService,
                private notifier: NotificationService,
                private authService: AuthService,
                private rfiService: RfiService,
                dialogRef: MatDialogRef<UpdateTransactionLineItemsComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.transaction = data.rfi.transaction;
        this.memberId = data.rfi.transaction.originatorId;
        this.memberAccount = data.memberAccount;
        this.currentBusinessClient = data.businessClient;
        this.accountingExternalIds = data.accountingExternalIds;
        this.businessClientAccountId = data.businessClientAccountId;
        this.rfi = data.rfi;
        this.businessClientAccount = data.businessClientAccount;
    }

    ngOnInit() {
        this.subType = this.transaction.subType;
        this.transactionEntriesValid = this.transactionEntriesValid.bind(this);
        this.loadMatchingTransactionEntries();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.currentBusinessClient && !changes.currentBusinessClient.firstChange) {
            this.loadMatchingTransactionEntries();
        }
    }

    loadMatchingTransactionEntries() {
        this.errorMessage = null;
        this.transactionEntriesLoading = true;
        this.customerTransactionEntries = [];

        if (this.currentBusinessClient) {
            let observable;
            let observables : any[] = [];
            if (this.subType === TransactionSubType.DEPOSIT) {
                for (const entry of this.accountingExternalIds) {
                    observables.push(this.recordsService.findInvoicesByOwnerAndCustomer(this.memberAccount.memberId, entry.accountingExternalId));
                }
            } else if (this.subType === TransactionSubType.PAYMENT) {
                for (const entry of this.accountingExternalIds) {
                    observables.push(this.recordsService.findExpensesByOwnerAndVendor(this.memberAccount.memberId, entry.accountingExternalId));
                }
            }

            if (observables.length > 0) {
                observable = forkJoin(observables);
            } else {
                observable = of(new PagedResponse());
            }
            observable.subscribe((eligibleEntries: PagedResponse<Invoice | Bill>[]) => {
                const entriesByClient = this.subType === TransactionSubType.DEPOSIT ? this.transaction.incomes : this.transaction.expenses;
                // Mapping Invoices/Bills to Transaction Entry Selection
                const transactionEntrySelections = entriesByClient.map((entry: Invoice | Bill) => {
                    const selection = new TransactionEntrySelection<Invoice | Bill>();
                    selection.data = entry;
                    return selection;
                });
                for (const eligibleEntry of eligibleEntries) {
                    for (const entry of eligibleEntry.content) {
                        const entryToAdd = new TransactionEntrySelection<Invoice | Bill>();
                        entryToAdd.data = entry;
                        entryToAdd.data.memberAccountId = this.businessClientAccountId;
                        entryToAdd.data.memberName = this.currentBusinessClient.name;
                        const selectedEntry = transactionEntrySelections.find((selection) => {
                            return selection.data.externalId === entry.externalId;
                        });
                        if ((this.subType === TransactionSubType.DEPOSIT && entry.type !== 'PAYMENT') || this.subType === TransactionSubType.PAYMENT) {
                            if (selectedEntry) {
                                entry.paidAmount = Number(selectedEntry.data.paidAmount).toFixed(2);
                                entry.supportingDocuments = selectedEntry.data.supportingDocuments;
                                if (entry.unpaidAmount === null) {
                                    entry.unpaidAmount = Number(entry.totalAmount);
                                }
                                entryToAdd.included = true;
                                this.customerTransactionEntries.push(entryToAdd);
                            } else {
                                entry.supportingDocuments = [];
                                if (entry.unpaidAmount === null) {
                                    entry.unpaidAmount = Number(entry.totalAmount);
                                }
                                if (entry.unpaidAmount !== null && Number(entry.unpaidAmount) > 0) {
                                    entry.paidAmount = Number(entry.unpaidAmount).toFixed(2);
                                    this.customerTransactionEntries.push(entryToAdd);
                                }
                            }
                        }
                    }
                }

                let otherEntriesPresent = true;
                transactionEntrySelections.forEach((otherEntry: TransactionEntrySelection<Invoice | Bill>) =>  {
                    // Avoid duplicate entries of included invoices/bills
                    if (!this.customerTransactionEntries.some((selection) => {
                        return selection.data.externalId && selection.data.externalId === otherEntry.data.externalId;
                    })) {
                        otherEntry.included = true;
                        this.customerTransactionEntries.push(otherEntry);
                    }
                });
                this.customerTransactionEntries.sort((a, b) => {
                    // First criteria: included true with externalId
                    if ( a.included && a.data.externalId && !(b.included && b.data.externalId)) {
                        return -1;
                    }
                    if ( !(a.included && a.data.externalId) && b.included && b.data.externalId ) {
                        return 1;
                    }
                    // Second criteria: included false with externalId
                    if ( !a.included && a.data.externalId && !(b.included && b.data.externalId) ) {
                        return -1;
                    }
                    if ( !(a.included && a.data.externalId) && b.included && b.data.externalId ) {
                        return 1;
                    }
                    // Keep the rest as they are
                    return 0;
                });
                this.existingEntryIds = this.customerTransactionEntries.filter((item) => {
                    return item.included;
                }).map((item) => {
                    return item.id;
                });
                if (!otherEntriesPresent) {
                    this.addOtherEntry();
                }
                this.calculateTotal();
                this.transactionEntriesLoading = false;
            },
            (_error: any) => {
                this.addOtherEntry();
                this.transactionEntriesLoading = false;
                this.errorMessage = { type: ErrorType.ERROR, message: `Unable to load ${this.subType === TransactionSubType.DEPOSIT ? 'invoices' : 'bills'} at this time. Please try again later.`};
            });
        }
    }

    calculateTotal() {
        let runningCustomerTotal = '0.00';
        for (const entry of this.customerTransactionEntries) {
            if (entry.included && entry.data.paidAmount) {
                runningCustomerTotal = (Number(runningCustomerTotal) + Number(entry.data.paidAmount)).toFixed(2);
            }
        }
        this.entriesTotal = runningCustomerTotal;
    }

    addOtherEntry() {
        const otherAmount = new TransactionEntrySelection<Invoice | Bill>();
        otherAmount.data = this.subType === TransactionSubType.DEPOSIT ? this.newInvoice() : this.newBill();
        otherAmount.included = true;
        this.customerTransactionEntries.push(otherAmount);
        this.transactionEntriesLoading = false;
    }

    newInvoice(): Invoice {
        const invoice = new Invoice();
        invoice.type = 'OTHER';
        invoice.txnDate = new Date();
        invoice.supportingDocuments = [];
        invoice.customerExternalId = this.businessClientAccountId;
        invoice.memberAccountId = this.businessClientAccountId;
        invoice.customerName =  this.currentBusinessClient.name;
        return invoice;
    }

    newBill(): Bill {
        const bill = new Bill();
        bill.type = 'OTHER';
        bill.txnDate = new Date();
        bill.supportingDocuments = [];
        bill.vendorExternalId = this.businessClientAccountId;
        bill.memberAccountId = this.businessClientAccountId;
        bill.vendorName =  this.currentBusinessClient.name;
        return bill;
    }

    notifyTransactionEntryChange() {
        const transactionEntries: TransactionEntrySelection<Invoice | Bill>[] = [];
        for (const entry of this.customerTransactionEntries) {
            if (entry.included && entry.data.paidAmount) {
                transactionEntries.push(entry);
            }
        }
        this.transactionEntriesSelected.emit(transactionEntries);
    }

    addNewTransactionEntryRow() {
        this.addButtonEnabled = false;
        this.addOtherEntry();
        this.table.renderRows();
    }

    onSelected(entry: TransactionEntrySelection<Invoice | Bill>) {
        entry.included = !entry.included;
        this.calculateTotal();
        this.notifyTransactionEntryChange();
        this.isAmountMatched();
    }

    onEntryChange(transactionEntry: TransactionEntrySelection<Invoice | Bill>) {
        this.validate(transactionEntry);
        if (transactionEntry.data.type === 'OTHER') {
            transactionEntry.data.paidAmount = Utils.formatNonNegativeCurrency(transactionEntry.data.paidAmount, true);
            transactionEntry.included = (Number(transactionEntry.data.paidAmount) > 0);
        }
        this.calculateTotal();
        this.notifyTransactionEntryChange();
        this.isAmountMatched();
    }

    updateTransactionEntries() {
        if (this.subType === TransactionSubType.DEPOSIT) {
            // Clear existing incomes
            this.transaction.incomes = [];
            // Update incomes based on customerTransactionEntries
            this.customerTransactionEntries.forEach((selection: TransactionEntrySelection<Invoice>) => {
                if (selection.included) {
                    this.transaction.incomes.push(selection.data);
                }
            });
        } else {
            // Clear existing expenses
            this.transaction.expenses = [];
            // Update expenses based on customerTransactionEntries
            this.customerTransactionEntries.forEach((selection: TransactionEntrySelection<Bill>) => {
                if (selection.included) {
                    this.transaction.expenses.push(selection.data);
                }
            });
        }
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });

        const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
        let entries = this.subType === TransactionSubType.DEPOSIT ? 'Invoices' : 'Bills';
        componentRef.instance.title = `Updating ${entries}...`;
        let notifierMessage = `${entries} have been updated successfully`;
        this.workflowService.updateTransactionEntries(this.transaction).subscribe(() => {
            const rfiHistory = new RfiHistory();
            rfiHistory.notes = `Updated ${entries} for transaction`;
            rfiHistory.transactionId = this.transaction.id;
            rfiHistory.status = this.rfi.status;
            rfiHistory.rfiId = this.rfi.id;
            rfiHistory.performedBy = this.authService.getProfile().userId;
            this.rfiService.saveRFIHistory(rfiHistory).subscribe(() => {
                overlayRef.dispose();
                if (notifierMessage) {
                    this.notifier.showSuccessCloseRequired(notifierMessage);
                    this.close(true);
                }
            }, (error: any) => {
                throw error;
            });
        });
    }

    removeOtherAmount(index: number) {
        this.customerTransactionEntries.splice(index, 1);
        this.calculateTotal();
        this.table.renderRows();
        this.notifyTransactionEntryChange();
        this.isAmountMatched();
    }

    transactionEntriesValid() {
        let entriesIncluded = false;
        for (const entry of this.customerTransactionEntries) {
            if (entry.data.type === 'OTHER' && Number(entry.data.paidAmount) > 0 && entry.included) {
                if (!this.isUniversalWhiteListedMerchant && !this.isDescriptionValid(entry)) {
                    return false;
                }
                entriesIncluded = true;
            } else if (entry.data.type !== 'OTHER' && entry.included && entry.error) {
                return false;
            } else if (entry.included) {
                entriesIncluded = true;
            }
        }
        return entriesIncluded;
    }

    isEntryFromAccounting(_index: number, entry: TransactionEntrySelection<Invoice | Bill>) {
        return entry.data.type !== 'OTHER';
    }

    isOtherEntry(_index: number, entry: TransactionEntrySelection<Invoice | Bill>) {
        return entry.data.type === 'OTHER';
    }

    isDescriptionValid(element: any) {
        return element.data.description && element.data.description.trim().length > 1;
    }

    isAmountMatched() {
        const transactionTotalAmount = this.transaction.totalAmount.toFixed(2).toString();
        if (this.entriesTotal !== transactionTotalAmount) {
            this.amountMismatchError = {
                message: 'The entries total should match the transaction amount.',
                type: ErrorType.ERROR
            };
            this.amountMatched = false;
        } else {
            this.amountMismatchError = null;
            this.amountMatched = true;
            let updatedEntryIds = this.customerTransactionEntries.filter((item) => {
                return item.included;
            }).map((item) => {
                return item.id;
            });
            this.addButtonEnabled = updatedEntryIds.length !== this.existingEntryIds.length || !this.existingEntryIds.every((id) => {
                return updatedEntryIds.includes(id);
            });
        }
    }

    isAdmin() {
        return this.authService.isAdmin();
    }

    close(refresh?: boolean) {
        super.close(refresh);
    }

    validate(transactionEntry: TransactionEntrySelection<Invoice | Bill>) {
        if (transactionEntry.data.externalId) {
            if (transactionEntry.noPaidAmount()) {
                transactionEntry.error = null;
                transactionEntry.data.paidAmount = null;
            } else if (Utils.isNaN(transactionEntry.data.paidAmount)) {
                transactionEntry.error = 'Enter Valid Amount';
            } else if (Number(transactionEntry.data.paidAmount) < 0) {
                transactionEntry.error = 'Enter Valid Amount';
            } else if (Number(transactionEntry.data.paidAmount) > Number(transactionEntry.data.unpaidAmount)) {
                transactionEntry.error = 'Exceeds Amount Owed';
            } else {
                transactionEntry.error = null;
                transactionEntry.data.paidAmount = Utils.formatNonNegativeCurrency(transactionEntry.data.paidAmount, true);
            }
        }
    }

}
