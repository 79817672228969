<div class="dashboard-info" *ngIf="isAuthorityOrReviewer()">
    <div class="row mb-3">
        <div class="col-4 form-title">Alert Overview</div>
        <div class="col-8">
            <div class="pull-right" *ngIf="alert?.status === AlertStatus.ACTIVE && isAuthority()">
                <button pc-no-dbl-click class="blue-button" type="button" (click)="reviewAlert(false, $event)"
                    submit-button>
                    Close
                </button>
                <button pc-no-dbl-click class="blue-button" type="button" (click)="reviewAlert(true, $event)"
                    submit-button>
                    Action Required
                </button>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="row">
            <div class="col-6 inner-column">
                <div class="row" *ngIf="alert">
                    <div class="col-3 expandedLabel">
                        Title
                    </div>
                    <div class="col-9 expandedValue text-break">
                        <div>{{alert.description}}</div>
                    </div>
                </div>
                <div class="row" *ngIf="involvedAccount">
                    <div class="col-3 expandedLabel">
                        Involved Account
                    </div>
                    <div class="col-9 expandedValue">
                        <div>
                            <a [routerLink]="['/member-accounts/details/' + involvedAccount.id]"
                                [queryParams]="{_transactionTab: 'ACCOUNT_LIMITS'}"
                                [routerLinkActive]="['router-link-active']">
                                {{ involvedAccount.accountName }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="alert">
                    <div class="col-3 expandedLabel">
                        Trigger
                    </div>
                    <div class="col-9 expandedValue">
                        <div>{{alert.source}}</div>
                    </div>
                </div>
                <div class="row" *ngIf="transaction">
                    <div class="col-3 expandedLabel">
                        Payor
                    </div>
                    <div class="col-9 expandedValue">
                        <div *ngIf="transaction?.payorMemberName && transaction?.payorMemberId">
                            <div *ngIf="authService.isAuthorityOrReviewer()">
                                <pc-load-member-details [linkDescription]="transaction.payorMemberName"
                                    [memberId]="transaction?.payorMemberId"
                                    [activeTab]="'internal'"></pc-load-member-details>
                            </div>
                            <div *ngIf="!authService.isAuthorityOrReviewer()">
                                <a [routerLink]="['/member/details/' + transaction?.payorMemberId]"
                                    [routerLinkActive]="['router-link-active']">
                                    {{ transaction.payorMemberName }}
                                </a>
                            </div>
                        </div>
                        <div *ngIf="transaction?.payorMemberName && !transaction?.payorMemberId">
                            {{transaction.payorMemberName}}</div>
                    </div>
                </div>
                <div *ngIf="transaction" class="row">
                    <div class="col-3 expandedLabel" *ngIf="transaction.subType === TransactionSubType.DEPOSIT">
                        Deposit Amount
                    </div>
                    <div class="col-3 expandedLabel" *ngIf="transaction.subType !== TransactionSubType.DEPOSIT">
                        Payment Amount
                    </div>
                    <div class="col-9 expandedValue">
                        {{ transaction.totalAmount | currency }}
                    </div>
                </div>
                <div *ngIf="transaction" class="row">
                    <div class="col-3 expandedLabel">
                        Transaction Date
                    </div>
                    <div class="col-9 expandedValue">
                        {{ transaction.transactionDate | date:'mediumDate' }}
                    </div>
                </div>
                <div *ngIf="transaction" class="row">
                    <div class="col-3 expandedLabel">
                        Type of Transaction
                    </div>
                    <div class="col-9 expandedValue">
                        {{ transaction.transactionType === TransactionType.ACH_DEPOSIT && transaction.source === TransactionSource.PAYMENT_PROCESSOR ? 'ACH Deposit' : transaction.transactionType | translate }}
                    </div>
                </div>
                <div class="row" *ngIf="transaction">
                    <div class="col-3 expandedLabel">
                        Transaction ID
                    </div>
                    <div class="col-9 expandedValue">
                        <a [routerLink]="['/transaction/details/' + transaction.id]"
                            routerLinkActive="router-link-active">{{transaction.id}}</a>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-3 expandedLabel">
                        Alert Created
                    </div>
                    <div class="col-9 expandedValue">
                        {{ alert?.created | date:'medium' }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-3 expandedLabel">
                        Status
                    </div>
                    <div class="col-9 expandedValue">
                        <pc-alerts-badge *ngIf="alert" [parent]="alert"></pc-alerts-badge>
                    </div>
                </div>
                <div *ngIf="alert?.note" class="row">
                    <div class="col-3 expandedLabel">
                        Note
                    </div>
                    <div class="col-9 expandedValue">
                        {{alert?.note}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="alert?.jsonData && (alert?.type === AlertType.TRANSACTION_FILE_PROCESSING || (this.alert?.type === AlertType.TRANSACTION && !this.alert?.referencedEntityId))">
            <div class="col-3 expandedLabel">
                JSON
            </div>
            <div class="col-9 expandedValue json-preview">
                <pre class="break-json-whitespace">
                  {{ alert?.jsonData | json }}
                </pre>
            </div>
        </div>
        <div class="clear-both" *ngIf="alert?.type !== AlertType.TRANSACTION_FILE_PROCESSING && this.alert?.type === AlertType.TRANSACTION && this.alert?.referencedEntityId">
            <div class="row">
                <div class="tab-row">
                    <div [class]="getTabClass('TRANSACTION_DETAILS')" (click)="onSwitchTabs('TRANSACTION_DETAILS')">
                        Transaction Alert Details
                    </div>
                    <div [class]="getTabClass('TRANSACTIONS')" (click)="onSwitchTabs('TRANSACTIONS')">
                        Related Transactions
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 tab-body" *ngIf="activeTab === 'TRANSACTION_DETAILS'">
                    <h6>Account Limits</h6>
                    <pt-account-summary-list
                        [memberAccountId]="transaction?.originatorAccountId"
                        [changeTrigger]="changeTrigger"
                        ></pt-account-summary-list><br>
                    <h6>Threshold At Time of Transaction</h6>
                    <pt-account-thresholds [jsonData]="alert?.jsonData" [changeTrigger]="changeTrigger"></pt-account-thresholds>
                </div>
                <div class="col-12 tab-body" *ngIf="activeTab === 'TRANSACTIONS'">
                    <pc-transaction-list *ngIf="filter" [filter]="filter"></pc-transaction-list>
                </div>
            </div>
        </div>
    </div>
</div>
