import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
    BankRoutingNumbers, Bill, BusinessClient, BankAccountService, ManifestSelection, MemberType, RemittanceInfo, SupportedTransactionType, TransactionEntrySelection,
    TransactionFormService, TransactionSource, TransactionType, WhitelistRelationshipParty, WhitelistRelationshipPartyService,
    Configuration
} from 'projects/services/src/public-api';
import { v4 as uuidv4 } from 'uuid';
import { BaseTransactionComponent } from '../../common/base-transaction-component/base-transaction.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { PaymentDateType } from '../../common/review-transaction-step/review-transaction-step.component';

@Component({
    selector: 'pt-business-payment',
    templateUrl: './business-payment.component.html',
    styleUrls: ['./business-payment.component.scss'],
    providers: [{
        provide: STEPPER_GLOBAL_OPTIONS,
        useValue: { displayDefaultIndicatorType: false }
    }]
})
export class BusinessPaymentComponent extends BaseTransactionComponent<Bill> implements OnInit {

    readonly NON_BANKING_INTEGRATION_PAYMENT_METHODS = [{
        id: TransactionType.OUTGOING_DIGITAL_CHECK_TO_MERCHANT,
        name: 'Printable PDF Check'
    }, {
        id: TransactionType.OUTGOING_CHECK_TO_MERCHANT,
        name: 'Check Mailed to Merchant'
    }];

    PAYMENT_METHODS_WITH_BANKING_INTEGRATION: Array<{ id: TransactionType, name: string }>;

    @Input() availableBalance: number;
    @Input() achExpressFee: number;
    @Input() wirePaymentFee: number;

    @Output() closeTransactionModal: EventEmitter<boolean> = new EventEmitter<boolean>();

    paymentMethods: { id: TransactionType, name: string }[] = [];
    remittanceInfo = new RemittanceInfo();
    isUniversalWhiteListedMerchant =  false;

    constructor(private transactionFormService: TransactionFormService,
                private bankAccountService: BankAccountService,
                protected breakpointObserver: BreakpointObserver,
                protected cdr: ChangeDetectorRef,
                private whitelistedMerchantService: WhitelistRelationshipPartyService) {
        super(breakpointObserver, cdr);
    }

    ngOnInit() {
        super.ngOnInit();

        this.formGroup = this.transactionFormService.initializePaymentForm();
        this.formGroup.get('memberAccountNameCtrl').setValue(this.memberAccount.accountName);
        this.formGroup.get('sourceCtrl').setValue(TransactionSource.EXTERNAL_TRANSACTION);
    }

    updatePaymentMethods(checksAvailable: boolean) {
        const basePaymentMethods = [{
            id: TransactionType.OUTGOING_ACH_TO_MERCHANT,
            name: 'Standard ACH'
        }, {
            id: TransactionType.WALLET_TO_EXPRESS_ACH_PAYMENT,
            name: 'Express ACH'
        }, {
            id: TransactionType.OUTGOING_WIRE_TO_MERCHANT,
            name: 'Wire'
        }];
        if (checksAvailable) {
            this.PAYMENT_METHODS_WITH_BANKING_INTEGRATION = [
                ...basePaymentMethods,
                ...this.NON_BANKING_INTEGRATION_PAYMENT_METHODS
            ];
        } else {
            this.PAYMENT_METHODS_WITH_BANKING_INTEGRATION = basePaymentMethods;
        }
    }

    onSelectBusinessClient(selectedBusinessClient: BusinessClient<Bill>) {
        if (!this.currentBusinessClient || !selectedBusinessClient || this.currentBusinessClient.memberAccountId !== selectedBusinessClient.memberAccountId) {
            this.currentBusinessClient = selectedBusinessClient;
            this.manifests = [];
            this.manifestSupportingDocs = [];
            this.calculateTotal([]);
        }
        const checksAvailable = Configuration.isChecksAvailable(this.currentBusinessClient?.address?.country);
        this.updatePaymentMethods(checksAvailable);
        this.paymentMethods = [];
        if (this.currentBusinessClient?.memberId && this.currentBusinessClient?.memberAccountId) {
            this.bankAccountService.getBankRoutingNumbers(this.currentBusinessClient.memberId, this.currentBusinessClient.memberAccountId).subscribe((bankRoutingNumbers: BankRoutingNumbers) => {
                if (!bankRoutingNumbers) {
                    if (checksAvailable) {
                        this.paymentMethods = this.NON_BANKING_INTEGRATION_PAYMENT_METHODS;
                    }
                } else {
                    this.paymentMethods = this.PAYMENT_METHODS_WITH_BANKING_INTEGRATION;
                    if (!bankRoutingNumbers.achRoutingNumber) {
                        this.paymentMethods = this.paymentMethods.filter((type) => {
                            return (type.id !== TransactionType.OUTGOING_ACH_TO_MERCHANT && type.id !== TransactionType.WALLET_TO_EXPRESS_ACH_PAYMENT);
                        });
                    }
                    if (!bankRoutingNumbers.wireRoutingNumber) {
                        this.paymentMethods = this.paymentMethods.filter((type) => {
                            return (type.id !== TransactionType.OUTGOING_WIRE_TO_MERCHANT);
                        });
                    }
                }
            });
        } else {
            if (checksAvailable) {
                this.paymentMethods = this.NON_BANKING_INTEGRATION_PAYMENT_METHODS;
            }
        }
        this.isUniversalWhiteListedMerchant = false;
        if ((selectedBusinessClient?.type === MemberType.BUSINESS_MERCHANT || selectedBusinessClient?.type === MemberType.INDIVIDUAL_MERCHANT) && selectedBusinessClient.memberId) {
            this.checkMerchantWhiteListedConfig(selectedBusinessClient.memberId);
        }
        this.stepper.reset();
    }

    checkMerchantWhiteListedConfig(memberId: string) {
        this.whitelistedMerchantService.getMerchantWhitelistingDetails(memberId).subscribe((WhitelistedRelationshipParty: WhitelistRelationshipParty) => {
            if (WhitelistedRelationshipParty && (WhitelistedRelationshipParty.whitelistedTransactionType === SupportedTransactionType.OUTGOING || WhitelistedRelationshipParty.whitelistedTransactionType === SupportedTransactionType.BOTH)) {
                this.isUniversalWhiteListedMerchant = true;
            }
        }, (error: any) => {
            if (error.status === 404) {
                this.closeModal(true);
                throw new Error('The counter party you have selected has been modified or renamed. Please try re-selecting the correct counter party and try your payment again.');
            }
            throw error;
        });
    }

    closeModal(event: any) {
        this.closeTransactionModal.emit(event);
    }

    calculateTotal(selectedBills: TransactionEntrySelection<Bill>[]) {
        if (!this.currentBusinessClient) {
            this.transactionTotal = '0.00';
        } else if (selectedBills && selectedBills.length) {
            this.currentBusinessClient.transactionEntries = selectedBills;
            this.transactionTotal = this.currentBusinessClient.selectedTotal;
        } else {
            this.currentBusinessClient.transactionEntries = [];
            this.transactionTotal = '0.00';
        }
        this.totalChanged.emit(this.transactionTotal);
    }

    onTransactionReviewed(reset: any) {
        const transaction = this.transactionFormService.getTransaction(this.formGroup);
        transaction.submissionReference = uuidv4();
        transaction.recipientMemberId = this.currentBusinessClient.memberId;
        transaction.recipientAccountId = this.currentBusinessClient.memberAccountId;
        transaction.totalAmount = Number(this.transactionTotal);
        transaction.source = TransactionSource.EXTERNAL_TRANSACTION;
        transaction.remittanceMemo = this.remittanceInfo?.remittanceMemo || null;
        transaction.remittanceFile = this.remittanceInfo?.remittanceFile || null;
        transaction.manifestSupportingDocuments = this.manifestSupportingDocs || [];

        if (transaction.transactionDate &&  this.formGroup.controls['paymentDateTypeCtrl'].value !== PaymentDateType.SCHEDULE_PAYMENT) {
            transaction.transactionDate = null;
        }

        transaction.expenses = this.currentBusinessClient.transactionEntries.map((transactionEntry: TransactionEntrySelection<Bill>) => {
            return transactionEntry.data;
        });
        transaction.shipmentIds = this.manifests.map((manifest: ManifestSelection) => {
            return manifest.data.id;
        });
        reset();
        this.transactionReviewed.emit(transaction);
    }

    // eslint-disable-next-line no-empty-function
    onSubmit(_reset: any) {}
}
