import { User } from './user';
import { MemberAccount } from './member-account';
import { Address } from './address';
import { Role } from './profile';
import { Member } from './member';

export class UserAccount {
    id: string;

    memberId: string;
    member: Member;

    memberAccountId: string;
    memberAccount: MemberAccount;

    userId: string;
    user: User;

    role: Role;
    status: UserAccountStatus;
    expirationDate: Date;
    address: Address;
    position: string;

    // ad hoc fields
    isKeyParty: boolean;
}

export enum UserAccountStatus {
    ACTIVE = 'ACTIVE',
    INVITED = 'INVITED',
    PENDING_REGISTRATION = 'PENDING_REGISTRATION',
    DISABLED = 'DISABLED'
}
