export class TransactionTypeTableData {

    date: string;
    ach: number;
    wire: number;
    cash: number;
    check: number;
    network: number;
    fee: number;
    adjustment: number;

}