<div>
    <div class="modal-header">
        <label class="form-title">Merge External Check</label>
    </div>
    <div class="dashboard-info">
        <div class="row">
            <div class="col-6 inner-column">
                <h6 class="flex">External Check Details</h6>
                <div class="mb-4"></div>
                <div class="row mb-2">
                    <div class="col-4 expandedLabel">
                        Recipient
                    </div>
                    <div class="col-8 expandedValue">
                        <div *ngIf="payeeMember">
                            <pc-load-member-details [linkDescription]="payeeMember.name" [memberId]="payeeMember.id" [activeTab]="'internal'" [newTab]="true"></pc-load-member-details>
                        </div>
                        <div *ngIf="!payeeMember">
                            N/A
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-4 expandedLabel">
                        Payor
                    </div>
                    <div class="col-8 expandedValue">
                        <div *ngIf="payorMember">
                            <pc-load-member-details [linkDescription]="payorMember.name" [memberId]="payorMember.id" [activeTab]="'internal'" [newTab]="true"></pc-load-member-details>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 expandedLabel">
                        Payment Amount
                    </div>
                    <div [ngClass]="amountMismatchIndicator ? 'col-8 expandedValue amountMismatch' : 'col-8 expandedValue'">
                        {{ externalCheckAmount | currency }}
                    </div>
                </div>
                <div class="mb-2"></div>
                <div class="row">
                    <div class="col-4 expandedLabel">
                        Check Received Date
                    </div>
                    <div *ngIf="checkCreationDate" class="col-8 expandedValue">
                        {{ checkCreationDate | date:'mediumDate' }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 expandedLabel">
                        Type of Transaction
                    </div>
                    <div class="col-8 expandedValue">
                        External Check Payment
                    </div>
                </div>
                <div class="mb-2"></div>
                <div  class="row">
                    <div class="col-4 expandedLabel">
                        Check Number
                    </div>
                    <div [ngClass]="checkNumberMismatchIndicator ? 'col-8 expandedValue amountMismatch' : 'col-8 expandedValue'">
                        {{ externalCheckNumber || 'Not Available' }}
                    </div>
                </div>
            </div>
            <div class="col-6">
                <h6>Confia Check Details</h6>
                <div class="mb-4"></div>
                <div class="row mb-2">
                    <div class="col-4 expandedLabel">
                        Recipient
                    </div>
                    <div class="col-8">
                        <div *ngIf="existingTransaction?.recipientMemberName">
                            <pc-load-member-details [linkDescription]="existingTransaction?.recipientMemberName" [memberId]="existingTransaction?.recipientMemberId" [activeTab]="'internal'" [newTab]="true"></pc-load-member-details>
                        </div>
                    </div>
                </div>
                <div class="row mb-2" *ngIf="existingTransaction?.payorMemberId">
                    <div class="col-4 expandedLabel">
                        Payor
                    </div>
                    <div class="col-8 expandedValue">
                        <div *ngIf="existingTransaction?.payorMemberName">
                            <pc-load-member-details [linkDescription]="existingTransaction?.payorMemberName" [memberId]="existingTransaction?.payorMemberId" [activeTab]="'internal'" [newTab]="true"></pc-load-member-details>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 expandedLabel" *ngIf="existingTransaction?.subType === TransactionSubType.PAYMENT">
                        Payment Amount
                    </div>
                    <div [ngClass]="amountMismatchIndicator ? 'col-8 expandedValue amountMismatch' : 'col-8 expandedValue'">
                        {{ existingTransaction?.totalAmount | currency }}
                    </div>
                </div>
                <div class="mb-2"></div>
                <div class="row">
                    <div class="col-4 expandedLabel">
                        Check issued Date
                    </div>
                    <div class="col-8 expandedValue">
                        {{ existingTransaction?.transactionDate | date:'mediumDate' }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 expandedLabel">
                        Type of Transaction
                    </div>
                    <div class="col-8 expandedValue">
                        {{ existingTransaction?.transactionType | translate }}
                    </div>
                </div>
                <div class="mb-2"></div>
                <div  class="row">
                    <div class="col-4 expandedLabel">
                        Check Number
                    </div>
                    <div [ngClass]="checkNumberMismatchIndicator ? 'col-8 expandedValue amountMismatch' : 'col-8 expandedValue'">
                        {{ existingTransaction?.checkNumber || 'Check Number' }}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 d-flex justify-content-center align-items-center" *ngIf="amountMismatchIndicator">
            <pc-error-message [error]="AMOUNT_MISMATCH" [width]="'full'"></pc-error-message>
        </div>
        <div class="col-12 d-flex justify-content-center align-items-center" *ngIf="checkNumberMismatchIndicator">
            <pc-error-message [error]="CHECK_NUMBER_MISMATCH" [width]="'full'"></pc-error-message>
        </div>
    </div>
    <div class="modal-footer">
        <pc-submit-button [text]="'Merge'" [submit]="onSubmit" (clickEvent)="onMerge(true)"></pc-submit-button>
        <pc-submit-button [text]="'Cancel'" [submit]="onSubmit" (clickEvent)="onCancel(false)"></pc-submit-button>
    </div>
</div>
