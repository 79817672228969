import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AuthService, UserService, UserFormService, User } from 'projects/services/src/public-api';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { TranslatePipe } from '@ngx-translate/core';
import { ChangePasswordModalComponent } from 'projects/components/src/lib/modal';

@Component({
    selector: 'pc-my-profile',
    templateUrl: './my-profile.component.html'
})
export class MyProfileComponent implements OnInit {

    userForm: UntypedFormGroup;

    constructor(private authService: AuthService,
                private userService: UserService,
                private userFormService: UserFormService,
                private dialog: MatDialog,
                private translatePipe: TranslatePipe) {
    }

    ngOnInit() {
        this.formEnabled = this.formEnabled.bind(this);
        this.onSaveProfile = this.onSaveProfile.bind(this);

        this.userService.loadUser(this.authService.getProfile().userId)
            .subscribe(
                (user: User) => {
                    this.initializeUser(user);
                }
            );
    }

    onChangePassword() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        this.dialog.open(ChangePasswordModalComponent, dialogConfig);
    }

    onSaveProfile(reset: any) {
        const updatedUser = this.userFormService.getUser(this.userForm);
        const updates = {
            firstName: updatedUser.firstName,
            middleName: updatedUser.middleName,
            lastName: updatedUser.lastName,
            mobilePhone: updatedUser.mobilePhone
        };
        this.userService.updateUser(updatedUser.id, updates).subscribe((user: User) => {
            this.initializeUser(user);
            reset();
        });
    }

    formEnabled() {
        return this.userForm && this.userForm.valid && this.userForm.dirty;
    }

    initializeUser(user: User) {
        user.accountRole = this.translatePipe.transform(this.authService.getProfile().role);
        this.userForm = this.userFormService.initializeForm(user);
        this.userForm.get('emailCtrl').disable();
        this.userForm.get('accountRoleCtrl').disable();

    }
}
