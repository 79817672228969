<div class="dashboard-info">
    <div class="row" *ngIf="transaction">
        <div class="col-4 form-title">Transaction Overview</div>
        <div class="col-8">
            <div class="pull-right">
                <button *ngIf="isCreditablePayment() && activeTab === 'TRANSACTION_DETAILS'" class="blue-button pull-right"  (click)="onChargeOrCreditPayment(TransactionType.TRANSACTION_CREDIT, 'Credit')" type="button"><i class="fa fa-plus pull-left dashboard-icon" aria-hidden="true"></i><div class="pull-right-button">Credit Member</div></button>
                <button *ngIf="isChargeablePayment() && activeTab === 'TRANSACTION_DETAILS'" class="blue-button pull-right" (click)="onChargeOrCreditPayment(TransactionType.TRANSACTION_FEE, 'Charge')" type="button"><i class="fa fa-minus pull-left dashboard-icon" aria-hidden="true"></i><div class="pull-right-button">Charge Member</div></button>
                <button *ngIf="isAuthorityCancellablePayment() && !isStoppable && isCancellable && activeTab === 'TRANSACTION_DETAILS'" class="blue-button pull-right" type="button" (click)="onCancelTransaction(TransactionStatus.CANCELLED_BY_AUTHORITY)">Cancel</button>
                <button *ngIf="isClientCancellablePayment() && !isStoppable && isCancellable && activeTab === 'TRANSACTION_DETAILS'" class="blue-button pull-right" type="button" (click)="onCancelTransaction(TransactionStatus.CANCELLED_BY_CLIENT)">Cancel</button>
                <button *ngIf="isRefundablePayment() && activeTab === 'TRANSACTION_DETAILS'" class="blue-button pull-right" (click)="openRefundTransactionModal()">Refund</button>
                <button *ngIf="isReturnableTransaction() && activeTab === 'TRANSACTION_DETAILS'" class="blue-button pull-right" (click)="openReturnTransactionModal()">Complete Return</button>
                <button *ngIf="isStoppable && activeTab === 'TRANSACTION_DETAILS'" class="blue-button pull-right" (click)="openStopPaymentModal()">Stop Payment</button>
                <button *ngIf="isScreenableTransaction && isTransactionScreeningCompleted && activeTab === 'WATCHLIST_SCREENING'" class="blue-button pull-right" (click)="openWatchlistConfirmationModal()">Complete Screening</button>
                <div [matTooltip]="overrideNSFDisabledMessage" *ngIf="isProcessableExternalAuthorizedACHTxn && activeTab === 'TRANSACTION_DETAILS'"><button [disabled]="!isParticipantsActive" class="blue-button pull-right" (click)="processExternalAuthorizedAchToMerchant()">Override NSF and Process</button></div>
                <span class="me-3" *ngIf="isScreenableTransaction && transaction.status === TransactionStatus.PENDING_WATCHLIST_REVIEW && (activeTab === 'WATCHLIST_SCREENING' || activeTab === 'INTERNAL_COMMUNICATION')">
                    Assigned to: <pc-authority-workflow-assign [processInstanceId]="processInstanceId" [taskId]="taskId" [assignee]="assignee" [authorityUsers]="authorityUsers"
                    (noteAdded)="onNoteAdded($event)">
                    </pc-authority-workflow-assign>
                </span>
            </div>
        </div>
        <div class="col-12 display-inline-flex" *ngIf="transaction && (transactionTrackingSupportEvidence || showPossibleManifestsInfo || isScreenableTransaction || showTraceDetails)">
            <div class="tab-row">
                <div [ngClass]="getTabClass('TRANSACTION_DETAILS')" (click)="setActiveTab('TRANSACTION_DETAILS', false)">
                    Transaction Details
                </div>
                <div *ngIf="transactionTrackingSupportEvidence" [ngClass]="getTabClass('HISTORICAL_VOLUMES')" (click)="setActiveTab('HISTORICAL_VOLUMES', false)">
                    Historical Volumes
                </div>
                <div *ngIf="showPossibleManifestsInfo" [ngClass]="getTabClass('POSSIBLE_MANIFESTS')" (click)="setActiveTab('POSSIBLE_MANIFESTS', false)">
                    Possible Manifests
                </div>
                <div *ngIf="isScreenableTransaction" [ngClass]="getTabClass('WATCHLIST_SCREENING')" (click)="setActiveTab('WATCHLIST_SCREENING', false)">
                    <div matBadgeColor="warn" matBadge="{{unclearedHitCount}}" [matBadgeHidden]="unclearedHitCount === 0" matTooltip="Open hits" class="watchlist-screen">
                        Watchlist Screening
                        <i class="fa fa-check-circle" *ngIf="unclearedHitCount === 0"></i>
                    </div>
                </div>
                <div *ngIf="isScreenableTransaction && taskId" [class]="getTabClass('INTERNAL_COMMUNICATION')" (click)="setActiveTab('INTERNAL_COMMUNICATION', false)">
                    Assignment Notes
                </div>
                <div *ngIf="showTraceDetails" [ngClass]="getTabClass('TRACE_DETAILS')" (click)="setActiveTab('TRACE_DETAILS', false)">
                    Trace Details
                </div>
            </div>
        </div>
        <div class="row" *ngIf="transaction && activeTab === 'TRANSACTION_DETAILS'">
            <div class="col-6 inner-column">
                <div class="row">
                    <a *ngIf="rfi" class="link link_position" (click)="viewRfi()">View Attached RFI</a>
                    <div class="col-12 expandedValue my-2">
                        <div class="row">
                            <div class="col-6">
                                <div class="expandedLabel" *ngIf="transaction.recipientMemberName || transaction.recipientMemberId">Recipient:</div>
                                <div *ngIf="transaction.recipientMemberName && transaction.recipientMemberId">
                                    <div *ngIf="authService.isAuthorityOrReviewer()">
                                        <pc-load-member-details [linkDescription]="transaction.recipientMemberName" [memberId]="transaction.recipientMemberId"
                                            [pooled]="isPooledMerchant && originatorMemberId === transaction.payorMemberId" [activeTab]="'internal'"></pc-load-member-details>
                                    </div>
                                    <div *ngIf="!authService.isAuthorityOrReviewer() && transaction.recipientMemberId">
                                        <a class="link" (click)="navigateToMember(transaction.recipientMemberId)"> {{ transaction.recipientMemberName }} </a>
                                    </div>
                                </div>
                                <div *ngIf="transaction.recipientMemberName && !transaction.recipientMemberId">{{transaction.recipientMemberName}}</div>
                                <div class="expandedLabel" *ngIf="showAccountInfo(transaction.recipientAccount)">Recipient Account:</div>
                                <div *ngIf="showAccountInfo(transaction.recipientAccount)">
                                    <a [routerLink]="['/member-accounts/details/' + transaction.recipientAccount.id]"
                                       [queryParams]="{_transactionTab: getAccountTab()}"
                                       [routerLinkActive]="['router-link-active']">
                                        {{ transaction.recipientAccount.accountName }}
                                    </a>
                                </div>
                                <div class="expandedLabel" *ngIf="authService.isAuthorityOrReviewer() && isPooledMerchant && originatorMemberId === transaction.payorMemberId">Counterparty:</div>
                                <div *ngIf="authService.isAuthorityOrReviewer() && isPooledMerchant && originatorMemberId === transaction.payorMemberId">
                                    <span *ngIf="transaction.relatedPartyAccountId">
                                        <a [routerLink]="['/member-accounts/details/' + transaction.relatedPartyAccountId]"
                                           [queryParams]="{_transactionTab: 'TRANSACTION_LIST'}"
                                           [routerLinkActive]="['router-link-active']">
                                            {{ transaction.relatedPartyMemberName }}
                                        </a> <i *ngIf="isAuthority" class="fa fa-pencil" (click)="setCounterparty()" title="Set Counterparty"></i>
                                    </span>
                                    <span *ngIf="!transaction.relatedPartyAccountId" class="warning">
                                        Not set <i *ngIf="isAuthority" class="fa fa-pencil" (click)="setCounterparty()" title="Set Counterparty"></i>
                                    </span>
                                </div>
                            </div>
                        <div class="col-6">
                                <div class="expandedLabel transDate">Date Initiated</div>
                                <div *ngIf="transaction.transactionDate" class="pull-right">{{transaction.transactionDate | date:'medium' }}</div>
                                <div *ngIf="!transaction.transactionDate" class="pull-right">Pending</div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="separator-thick">
                <div class="row">
                    <div class="col-12 expandedValue my-2">
                        <div class="row">
                            <div class="col-6">
                                <div class="expandedLabel">Payor:</div>
                                <div *ngIf="transaction.payorMemberName && transaction.payorMemberId">
                                    <div *ngIf="authService.isAuthorityOrReviewer()">
                                        <pc-load-member-details [linkDescription]="transaction.payorMemberName" [memberId]="transaction.payorMemberId" [activeTab]="'internal'"
                                            [pooled]="isPooledMerchant && originatorMemberId === transaction.recipientMemberId"></pc-load-member-details>
                                    </div>
                                    <div *ngIf="!authService.isAuthorityOrReviewer() && transaction.payorMemberId">
                                        <a class="link" (click)="navigateToMember(transaction.payorMemberId)"> {{ transaction.payorMemberName }} </a>
                                    </div>
                                </div>
                                <div *ngIf="transaction.payorMemberName && !transaction.payorMemberId">{{transaction.payorMemberName}}</div>
                                <div class="expandedLabel" *ngIf="showAccountInfo(transaction.payorAccount)">Payor Account:</div>
                                <div *ngIf="showAccountInfo(transaction.payorAccount)">
                                    <a [routerLink]="['/member-accounts/details/' + transaction.payorAccount.id]"
                                       [queryParams]="{_transactionTab: getAccountTab()}"
                                       [routerLinkActive]="['router-link-active']">
                                        {{ transaction.payorAccount.accountName }}
                                    </a>
                                </div>
                                <div class="expandedLabel" *ngIf="authService.isAuthorityOrReviewer() && isPooledMerchant && originatorMemberId === transaction.recipientMemberId">Counterparty:</div>
                                <div *ngIf="authService.isAuthorityOrReviewer() && isPooledMerchant && originatorMemberId === transaction.recipientMemberId">
                                    <span *ngIf="transaction.relatedPartyAccountId">
                                        <a [routerLink]="['/member-accounts/details/' + transaction.relatedPartyAccountId]"
                                           [queryParams]="{_transactionTab: 'TRANSACTION_LIST'}"
                                           [routerLinkActive]="['router-link-active']">
                                            {{ transaction.relatedPartyMemberName }}
                                        </a> <i *ngIf="isAuthority" class="fa fa-pencil" (click)="setCounterparty()" title="Set Counterparty"></i>
                                    </span>
                                    <span *ngIf="!transaction.relatedPartyAccountId" class="warning">
                                        Not set <i *ngIf="isAuthority" class="fa fa-pencil" (click)="setCounterparty()" title="Set Counterparty"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="expandedLabel transDate">Date Completed</div>
                                <div *ngIf="transaction.completionDate" class="pull-right">{{transaction.completionDate | date:'medium' }}</div>
                                <div *ngIf="!transaction.completionDate" class="pull-right">Pending</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 price">
                        <pc-transaction-amount [transaction]="transaction"></pc-transaction-amount>
                    </div>
                    <div class="col-12 expandedValue transtype">
                        {{ getTransactionType(transaction) === TransactionType.ACH_DEPOSIT && transaction.source === TransactionSource.PAYMENT_PROCESSOR ? 'ACH Deposit' : getTransactionType(transaction) | translate }}
                        <div *ngIf="transaction.reasonCode"> ({{transaction.reasonCode | translate }}) </div>
                        <div *ngIf="transaction.apiOwnerId"> (Partner) </div>
                        <div *ngIf="checkDetails">Check Number: {{ checkDetails?.checkNumber }}</div>
                        <div *ngIf="externalCheckNumber">Check Number: {{ externalCheckNumber || "N/A" }}</div>
                        <div *ngIf="authService.isAuthorityOrReviewer() && checkDetails">Check Id: {{ checkDetails?.checkId }}</div>
                        <div *ngIf="(authService.isAuthorityOrReviewer() && processedCheckDetails?.externalTransferId)">
                            External Transfer Id: {{ processedCheckDetails.externalTransferId }}
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="transaction.apiOwnerId">
                    <div class="col-12 expandedValue">
                        <div class="expandedLabel">Partner</div>
                        <div *ngIf="transaction.apiOwnerId">
                            <div *ngIf="authService.isAuthorityOrReviewer()">
                                <pc-load-member-details [linkDescription]="transaction.apiOwnerName" [memberId]="transaction.apiOwnerId" [activeTab]="'internal'"></pc-load-member-details>
                            </div>
                            <div *ngIf="!authService.isAuthorityOrReviewer()">
                                <a class="member-details-font" (click)="navigateToMember(transaction.apiOwnerId)"> {{ transaction.apiOwnerName }} </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 expandedValue">
                        <div class="expandedLabel">Transaction ID</div>
                        {{transaction.id}}
                    </div>
                </div>
                <div *ngIf="dropOffDate" class="row mt-3">
                    <div class="col-12 expandedValue">
                        <div class="expandedLabel">Drop-off Date</div>
                        {{dropOffDate | date:'mediumDate'}}
                    </div>
                </div>
                <div *ngIf="dropOffLocation" class="row mt-3">
                    <div class="col-12 expandedValue">
                        <div class="expandedLabel">Drop-off Location</div>
                        <pc-business-address [address]="dropOffLocation"></pc-business-address>
                    </div>
                </div>
                <div class="row" *ngIf="transaction.relatedTransactionId">
                    <div class="col-12 expandedValue mt-3">
                        <div class="expandedLabel">Parent Transaction</div>
                        <a [routerLink]="['/transaction/details/' + transaction.relatedTransactionId]"
                           [routerLinkActive]="['router-link-active']">
                            {{transaction.relatedTransactionId}}
                        </a>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 expandedValue">
                        <div class="expandedLabel mb-1">Status</div>
                        <pc-transaction-badge [parent]="transaction"></pc-transaction-badge>
                    </div>
                </div>
                <div *ngIf="externalTransactionReference?.paymentNote1" class="row mt-3">
                    <div class="col-12 expandedValue">
                        <div class="expandedLabel mb-1">Payment Notes</div>
                        {{externalTransactionReference.paymentNote1}}
                    </div>
                </div>
                <div *ngIf="externalTransactionReference?.paymentNote2" class="row mt-3">
                    <div class="col-12 expandedValue">
                        <div class="expandedLabel mb-1">Additional Payment Notes</div>
                        {{externalTransactionReference.paymentNote2}}
                    </div>
                </div>
                <div *ngIf="externalTransactionReference?.paymentRemittanceInfo" class="row mt-3">
                    <div class="col-12 expandedValue">
                        <div class="expandedLabel mb-1">Remittance Info</div>
                        {{externalTransactionReference.paymentRemittanceInfo}}
                    </div>
                </div>
                <div class="row" *ngIf="authService.isAuthorityOrReviewer() && !isPayorFeeAccount && !isPayeeFeeAccount && !isPassThroughScorer">
                    <div class="col-12 expandedValue mt-3">
                        <div class="expandedLabel">Score</div>
                        <div>{{ transaction.pointTrustScore }}</div>
                    </div>
                </div>
                <div class="row mt-3" *ngIf="transaction?.scores && authService.isAuthorityOrReviewer() && !isPayorFeeAccount && !isPayeeFeeAccount && !isPassThroughScorer">
                    <div class="col-12 expandedValue">
                        <div class="expandedLabel mb-1">Scoring Reasons</div>
                        <div class="expandedValue reason-code-padding" *ngIf="transaction?.scores.length <= 0">
                            -
                        </div>
                        <div class="expandedValue" *ngFor="let score of transaction.scores">
                            <div class="expandedValue reason-code-padding" *ngIf="score.pointChainReasonCodes.length <= 0">
                                -
                            </div>
                            <div class="expandedValue reason-code-padding" *ngFor="let reasonCode of score.pointChainReasonCodes">
                                {{ reasonCode | translate }}
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="transaction.cancellationMemo" class="row mt-3">
                    <div class="col-12 expandedValue">
                        <div class="expandedLabel mb-1">Cancellation Reason</div>
                        {{transaction.cancellationMemo}}
                    </div>
                </div>
                <div class="row mt-3" *ngIf="transaction.status === TransactionStatus.PENDING_DELIVERY">
                    <div class="col-12 qr-code-link">
                        <button type="button" class="blue-button pull-right" (click)="openQrTrackingInfo()">Print QR Code</button>
                    </div>
                </div>
                <div class="row mt-3" *ngIf="transaction?.relatedTransactions">
                    <div class="col-sm-12 mt-3 mb-3 horizontal-scroll mat-table-container">
                        <div class="form-title">Related Transactions</div>
                        <table mat-table [dataSource]="transaction.relatedTransactions">
                            <ng-container matColumnDef="type">
                                <th mat-header-cell *matHeaderCellDef> Type </th>
                                <td mat-cell *matCellDef="let element">
                                    <a [routerLink]="['/transaction/details/' + element.id]" [routerLinkActive]="['router-link-active']">
                                        {{ element.transactionType | translate}}
                                    </a>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="amount">
                                <th class="centered" mat-header-cell *matHeaderCellDef> Amount </th>
                                <td class="centered" mat-cell *matCellDef="let element">
                                    <div class="table-amount">
                                        <pc-transaction-amount [transaction]="element"></pc-transaction-amount>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="transaction_date">
                                <th class="centered" mat-header-cell *matHeaderCellDef> Date Initiated </th>
                                <td class="centered" mat-cell *matCellDef="let element">
                                    <div *ngIf="element.transactionDate"> {{ element.transactionDate | date:'medium' }} </div>
                                    <div *ngIf="!element.transactionDate"> Pending </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="completed_date">
                                <th class="centered" mat-header-cell *matHeaderCellDef> Date Completed </th>
                                <td class="centered" mat-cell *matCellDef="let element">
                                    <div *ngIf="element.completionDate">{{ element.completionDate | date:'medium' }}</div>
                                    <div *ngIf="!element.completionDate"> Pending </div>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="relatedTransactionDisplayedColumn"></tr>
                            <tr mat-row *matRowDef="let row; columns: relatedTransactionDisplayedColumn;"></tr>
                        </table>
                    </div>
                </div>
            </div>

            <div class="col-6 details">
                <div class="row" *ngIf="supportingDocuments.length || transaction.notes">
                    <div class="col-sm-12 mb-3 horizontal-scroll mat-table-container">
                        <div class="form-title">Documentation</div>
                        <div class="row" *ngIf="transaction.notes">
                            <div class="col-12">
                                <dl class="row">
                                    <dt class="col-sm-2 expandedLabel">Note:</dt>
                                    <dd class="col-sm-10 expandedValue">{{transaction.notes || 'None'}}</dd>
                                </dl>
                            </div>
                        </div>
                        <table mat-table #documentsTable [dataSource]="supportingDocuments" *ngIf="supportingDocuments.length">
                            <ng-container matColumnDef="document">
                                <th mat-header-cell *matHeaderCellDef> Document Name </th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="supporting-document" *ngIf="element.document && element.document.fileName">
                                        <a class="dashboard-list-a" (click)="downloadFile(element.document)">
                                            <i class="fa fa-paperclip"></i>{{element.document.fileName}}
                                        </a>
                                    </div>
                                    <div class="supporting-document" *ngIf="element.document && !element.document.fileName && showRearCheck">
                                        <a class="dashboard-list-a" href="/static/Rear_Check.pdf" download="Rear_Check.pdf">
                                            <i class="fa fa-paperclip"></i>Check_Rear.pdf
                                        </a>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="description">
                                <th class="centered" mat-header-cell *matHeaderCellDef> Description </th>
                                <td class="centered" mat-cell *matCellDef="let element">
                                    {{ element.document?.explanation }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="date">
                                <th class="centered" mat-header-cell *matHeaderCellDef> Date </th>
                                <td class="centered" mat-cell *matCellDef="let element">
                                    <div *ngIf="element.created">
                                        <pc-date-time [date]="element.created"></pc-date-time>
                                    </div>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="documentDisplayedColumn"></tr>
                            <tr mat-row *matRowDef="let row; columns: documentDisplayedColumn;"></tr>
                        </table>
                    </div>
                </div>
                <div >
                    <dl class="row"  *ngIf="transaction.remittanceMemo">
                        <dt class="col-sm-4 expandedLabel">{{ transaction.transactionType === TransactionType.OUTGOING_WIRE_TO_MERCHANT ? "Notes to Payee:" : "Remittance Memo:" }}</dt>
                        <dd class="col-sm-8 expandedValue">{{  transaction.remittanceMemo }}</dd>
                    </dl>
                </div>
                <div class="row" *ngIf="transaction.expenses && transaction.expenses.length">
                    <div class="col-12 form-subtitle">Expenses</div>
                </div>
                <div *ngFor="let expense of transaction.expenses">
                    <dl class="row" *ngIf="expense">
                        <dt class="col-sm-4 expandedLabel">Verified Expense:</dt>
                        <dd class="col-sm-8 expandedValue">{{expense.id || 'No'}}</dd>

                        <dt class="col-sm-4 expandedLabel" *ngIf="expense.type !== 'OTHER'">Source:</dt>
                        <dd class="col-sm-8 expandedValue" *ngIf="expense.type !== 'OTHER'">{{expense.source || 'N/A' | translate}}</dd>

                        <dt class="col-sm-4 expandedLabel" *ngIf="expense.type !== 'OTHER'">Synced:</dt>
                        <dd class="col-sm-8 expandedValue" *ngIf="expense.type !== 'OTHER'">{{expense.synced ? 'Yes' : 'No'}}</dd>

                        <dt class="col-sm-4 expandedLabel" *ngIf="expense.vendorName">Vendor:</dt>
                        <dd class="col-sm-8 expandedValue" *ngIf="expense.vendorName">{{expense.vendorName || 'N/A'}}</dd>

                        <dt class="col-sm-4 expandedLabel" *ngIf="expense.type !== 'OTHER'">Bill #:</dt>
                        <dd class="col-sm-8 expandedValue" *ngIf="expense.type !== 'OTHER'">{{expense.externalReference || 'N/A'}}</dd>

                        <dt class="col-sm-4 expandedLabel" *ngIf="expense.description || expense.type === 'OTHER'">Description:</dt>
                        <dd class="col-sm-8 expandedValue" *ngIf="expense.description || expense.type === 'OTHER'">{{expense.description || 'N/A'}}</dd>

                        <dt class="col-sm-4 expandedLabel">Submitted Amount:</dt>
                        <dd class="col-sm-8 expandedValue">{{expense.submittedAmount | currency}}</dd>

                        <dt class="col-sm-4 expandedLabel" *ngIf="transaction.state !== TransactionState.PENDING">Paid Amount:</dt>
                        <dd class="col-sm-8 expandedValue" *ngIf="transaction.state !== TransactionState.PENDING">{{expense.paidAmount | currency}}</dd>

                        <dt class="col-sm-4 expandedLabel">Expense Date:</dt>
                        <dd class="col-sm-8 expandedValue">{{(expense.txnDate | date:'mediumDate') || 'N/A'}}</dd>

                        <dt class="col-sm-4 expandedLabel" *ngIf="expense.lineItems.length">Expense Detail:</dt>
                        <dd class="col-sm-8 expandedValue" *ngIf="expense.lineItems.length">
                            <div *ngFor="let item of expense.lineItems">
                                {{item.amount | currency}} <div *ngIf="item.description">- {{item.description}}</div>
                            </div>
                        </dd>
                    </dl>
                </div>
                <div class="row" *ngIf="transaction.incomes && transaction.incomes.length">
                    <div class="col-12 form-subtitle">Invoices</div>
                </div>
                <div *ngFor="let invoice of transaction.incomes">
                    <dl class="row">
                        <dt class="col-sm-4 expandedLabel">Verified Invoice:</dt>
                        <dd class="col-sm-8 expandedValue">{{invoice.id || 'No'}}</dd>

                        <dt class="col-sm-4 expandedLabel" *ngIf="invoice.type !== 'OTHER'">Source:</dt>
                        <dd class="col-sm-8 expandedValue" *ngIf="invoice.type !== 'OTHER'">{{invoice.source || 'N/A' | translate}}</dd>

                        <dt class="col-sm-4 expandedLabel" *ngIf="invoice.type !== 'OTHER'">Synced:</dt>
                        <dd class="col-sm-8 expandedValue" *ngIf="invoice.type !== 'OTHER'">{{invoice.synced ? 'Yes' : 'No'}}</dd>

                        <dt class="col-sm-4 expandedLabel" *ngIf="invoice.customerName">Customer:</dt>
                        <dd class="col-sm-8 expandedValue" *ngIf="invoice.customerName">{{invoice.customerName || 'N/A'}}</dd>

                        <dt class="col-sm-4 expandedLabel" *ngIf="invoice.type !== 'OTHER'">Invoice #:</dt>
                        <dd class="col-sm-8 expandedValue" *ngIf="invoice.type !== 'OTHER'">{{invoice.externalReference || 'N/A'}}</dd>

                        <dt class="col-sm-4 expandedLabel" *ngIf="invoice.description || invoice.type === 'OTHER'">Description:</dt>
                        <dd class="col-sm-8 expandedValue" *ngIf="invoice.description || invoice.type === 'OTHER'">{{invoice.description || 'N/A'}}</dd>

                        <dt class="col-sm-4 expandedLabel">Submitted Amount:</dt>
                        <dd class="col-sm-8 expandedValue">{{invoice.submittedAmount | currency}}</dd>

                        <dt class="col-sm-4 expandedLabel" *ngIf="transaction.state !== TransactionState.PENDING">Paid Amount:</dt>
                        <dd class="col-sm-8 expandedValue" *ngIf="transaction.state !== TransactionState.PENDING">{{invoice.paidAmount | currency}}</dd>

                        <dt class="col-sm-4 expandedLabel">Invoice Date:</dt>
                        <dd class="col-sm-8 expandedValue">{{(invoice.txnDate | date:'mediumDate') || 'N/A'}}</dd>

                        <dt class="col-sm-4 expandedLabel" *ngIf="invoice.lineItems.length">Invoice Detail:</dt>
                        <dd class="col-sm-8 expandedValue" *ngIf="invoice.lineItems.length">
                            <div *ngFor="let item of invoice.lineItems">
                                {{item.amount | currency}} <div *ngIf="item.description">- {{item.description}}</div>
                            </div>
                        </dd>
                    </dl>
                </div>
                <div class="row" *ngIf="shipments.length">
                    <div class="col-12 form-subtitle">License Tracking</div>
                </div>
                <div *ngFor="let shipment of shipments">
                    <dl class="row">
                        <dt class="col-sm-4 expandedLabel">Verified Tracking ID:</dt>
                        <dd class="col-sm-8 expandedValue">{{ shipment.id }}</dd>

                        <dt class="col-sm-4 expandedLabel">Shipper:</dt>
                        <dd class="col-sm-8 expandedValue">{{ shipment.shipperFacilityName }}</dd>

                        <dt class="col-sm-4 expandedLabel">Recipient:</dt>
                        <dd class="col-sm-8 expandedValue">{{ shipment.recipientFacilityName }}</dd>

                        <dt class="col-sm-4 expandedLabel">Received:</dt>
                        <dd class="col-sm-8 expandedValue">{{(shipment.receivedDateTime | date:'mediumDate') || 'N/A'}}</dd>

                        <dt class="col-sm-4 expandedLabel" *ngIf="shipment.lineItems">Manifest Contents:</dt>
                        <dd class="col-sm-8 expandedValue" *ngIf="shipment.lineItems">
                            <div *ngFor="let item of shipment.lineItems">
                                {{item.productName}}: <div *ngIf="item.shippedQuantity">{{item.shippedQuantity}} {{item.quantityUnits}}</div>
                            </div>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
        <div class="col-12" *ngIf="transaction.status === TransactionStatus.PENDING_RECEIPT_VERIFICATION && authService.isAdmin()">
            <div class="pull-right">
                <a [routerLink]="['/administration/settings']" [queryParams]="{_activeTab: 'member-overview'}"><i class="fa fa-compass"></i>Confia Deposit Instructions</a>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="contract">
        <div class="col-12">
            <h6>Smart Contract Order Details</h6>
            <pc-sc-sales-order class="sales-order" [order]="contract.contractTemplate.purchaseOrder"></pc-sc-sales-order>
        </div>
    </div>

    <div *ngIf="transaction && transactionTrackingSupportEvidence && activeTab === 'HISTORICAL_VOLUMES'">
        <pt-transaction-tracking-system-evidence [transactionTrackingSupportEvidence]="transactionTrackingSupportEvidence"></pt-transaction-tracking-system-evidence>
    </div>
    <div *ngIf="transaction && showPossibleManifestsInfo && activeTab === 'POSSIBLE_MANIFESTS'">
        <pc-member-metrc-transfers-list [transactionId]="transaction.id" [displaySummary]="false"></pc-member-metrc-transfers-list>
    </div>
    <div *ngIf="transaction && activeTab === 'WATCHLIST_SCREENING'">
        <pt-watchlist-search-details [transaction]="transaction" (watchlistUpdated)="loadWatchlistClearedStatus()"></pt-watchlist-search-details>
    </div>
    <div class="col-12 tab-body" *ngIf="processInstanceId && activeTab === 'INTERNAL_COMMUNICATION' && isAuthority">
        <pc-authority-workflow-notes [processInstanceId]="processInstanceId" [changeTrigger]="notesTrigger">
        </pc-authority-workflow-notes>
    </div>
    <div *ngIf="transaction && activeTab === 'TRACE_DETAILS'">
        <div>
            <dl class="row" *ngIf="externalTransactionReference?.externalTxnId">
                <dt class="col-md-3">Payment Number:</dt>
                <dd class="col-md-9"> {{ externalTransactionReference.externalTxnId }} </dd>
            </dl>
            <dl class="row" *ngIf="externalTransactionReference?.traceId">
                <dt class="col-md-3">Trace ID:</dt>
                <dd class="col-md-9"> {{ externalTransactionReference.traceId }} </dd>
            </dl>
            <dl class="row" *ngIf="externalTransactionReference?.fileName">
                <dt class="col-md-3">File Name:</dt>
                <dd class="col-md-9"> {{ externalTransactionReference.fileName }} </dd>
            </dl>
            <dl class="row" *ngIf="externalTransactionReference?.fileCreationDate">
                <dt class="col-md-3">File Creation Date:</dt>
                <dd class="col-md-9"> {{ externalTransactionReference.fileCreationDate }} </dd>
            </dl>
            <dl class="row" *ngIf="externalTransactionReference?.fileCreationTime">
                <dt class="col-md-3">File Creation Time:</dt>
                <dd class="col-md-9"> {{ externalTransactionReference.fileCreationTime }} </dd>
            </dl>
            <dl class="row" *ngIf="externalTransactionReference?.fileIdModifier">
                <dt class="col-md-3">File ID Modifier:</dt>
                <dd class="col-md-9"> {{ externalTransactionReference.fileIdModifier }} </dd>
            </dl>
            <dl class="row" *ngIf="externalTransactionReference?.cashLetterId">
                <dt class="col-md-3">Cash Letter ID:</dt>
                <dd class="col-md-9"> {{ externalTransactionReference.cashLetterId }} </dd>
            </dl>
            <dl class="row" *ngIf="externalTransactionReference?.bundleId">
                <dt class="col-md-3">Bundle ID:</dt>
                <dd class="col-md-9"> {{ externalTransactionReference?.bundleId }} </dd>
            </dl>
            <dl class="row" *ngIf="externalTransactionReference?.batchNumber">
                <dt class="col-md-3">Batch Number:</dt>
                <dd class="col-md-9"> {{ externalTransactionReference.batchNumber }} </dd>
            </dl>
            <dl class="row" *ngIf="externalTransactionReference?.standardEntryClassCode">
                <dt class="col-md-3">Standard Entry Class Code:</dt>
                <dd class="col-md-9"> {{ externalTransactionReference.standardEntryClassCode }} </dd>
            </dl>
            <dl class="row" *ngIf="externalTransactionReference?.transactionCode">
                <dt class="col-md-3">Transaction Code:</dt>
                <dd class="col-md-9"> {{ externalTransactionReference.transactionCode }} </dd>
            </dl>
        </div>
    </div>
    <a class="document-download-link" #downloadDocumentLink></a>
</div>
