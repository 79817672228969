import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
    Address, AddressOccupant, AddressService, AddressStatus, AddressType, AuthService, MemberAccount, MemberAccountService, MemberAccountType, MemberFunction,
    MemberType, PagedResponse
} from 'projects/services/src/public-api';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LocationModalComponent } from 'projects/pt/src/app/components';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pt-location-list',
    templateUrl: './location-list.component.html',
    styleUrls: ['./location-list.component.scss']
})
export class LocationListComponent implements OnInit, AfterViewInit, OnDestroy {

    AddressStatus = AddressStatus;
    AddressType = AddressType;

    @Input() memberId: string;
    @Input() readOnly = false;
    @Input() allowAdd = true;
    @Input() title: string = 'Locations';
    @Input() subtitle: string;

    @Output() locationsUpdated: EventEmitter<Address[]> = new EventEmitter<Address[]>();

    isLoadingResults = true;

    displayedColumns: string[] = ['type', 'address', 'nickname', 'date_added', 'action'];
    addressOccupants: AddressOccupant[] = [];
    accountNameMap = {};

    pageTracking: PageTracking;
    resultsLength = 0;
    subscription: any;
    refreshEvent: EventEmitter<null> = new EventEmitter<null>();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(public authService: AuthService,
                private route: ActivatedRoute,
                private router: Router,
                private addressService: AddressService,
                private memberAccountService: MemberAccountService,
                private dialog: MatDialog,
                private notifier: NotificationService,
                private cdr: ChangeDetectorRef) {}

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnInit() {
        if (!this.authService.isAuthority()) {
            this.displayedColumns.splice(this.displayedColumns.indexOf('action'), 1);
        }
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'type', 'asc', 25);
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.memberAccountService.getMemberAccountsByTypesAndStatus(this.memberId, [MemberAccountType.BUSINESS], [], 0, 100, '', '').subscribe((memberAccounts: PagedResponse<MemberAccount>) => {
            memberAccounts.content.forEach((memberAccount: MemberAccount) => {
                this.accountNameMap[memberAccount.id] = memberAccount.accountName;
            });
            this.addTableLoadListener();
        });
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.paginator.page, this.refreshEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                return this.addressService.getAllAddressOccupantLinks(this.memberId, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<AddressOccupant>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((occupants: AddressOccupant[]) => {
            const results: AddressOccupant[] = [];
            if (occupants?.length > 0) {
                for (const addressOccupant of occupants) {
                    this.loadLocation(addressOccupant);
                    results.push(addressOccupant);
                }
                const primaryIndex = results.findIndex((element) => {
                    return element.type === AddressType.PRIMARY;
                });
                results.unshift(results.splice(primaryIndex, 1)[0]);
            }
            this.addressOccupants = results;
            this.locationDetailsUpdated();
            UIUtils.scrollDashboardToTop();
        });
    }

    loadLocation(addressOccupant: AddressOccupant) {
        addressOccupant.address.type = addressOccupant.type;
        addressOccupant.accountName = this.accountNameMap[addressOccupant.memberAccountId];
    }

    refreshList() {
        this.refreshEvent.emit();
    }

    onAddNew() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            memberId: this.memberId
        };
        const dialog = this.dialog.open(LocationModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((refresh: boolean) => {
            if (refresh) {
                this.refreshList();
            }
        });
    }

    locationDetailsUpdated() {
        const locations = this.addressOccupants?.map((occupant) => {
            return occupant.address;
        });
        this.locationsUpdated.emit(locations);
    }

    isEnabled(addressOccupant: AddressOccupant) {
        return addressOccupant && addressOccupant.status !== AddressStatus.DISABLED;
    }

    isDetailView() {
        return this.authService.hasMemberFunction(MemberFunction.PARTNER) || this.authService.isAuthorityOrReviewer();
    }

    toggleEnabled(event: any, addressOccupant: AddressOccupant) {
        this.memberAccountService.getMemberAccountsByTypesAndStatus(this.memberId, [MemberAccountType.BUSINESS, MemberAccountType.MERCHANT], [], 0, 100, '', '').subscribe((memberAccounts: PagedResponse<MemberAccount>) => {
            let accountUsingAddress = memberAccounts.content.find((memberAccount) => {
                return addressOccupant.addressId === memberAccount.addressId;
            });
            if (accountUsingAddress) {
                this.notifier.showError(`${addressOccupant.address.streetAddressOne} is in use by account ${accountUsingAddress.accountName} and cannot be disabled.`);
            } else {
                if (this.isEnabled(addressOccupant)) {
                    this.addressService.updateAddressOccupant(addressOccupant.id, { status : AddressStatus.DISABLED }).subscribe(() => {
                        this.refreshList();
                    });
                } else {
                    this.addressService.updateAddressOccupant(addressOccupant.id, { status : AddressStatus.ACTIVE }).subscribe(() => {
                        this.refreshList();
                    });
                }
            }
            event.cancelBubble = true;
            if (event.stopImmediatePropagation) {
                event.stopImmediatePropagation();
            }
        });
    }

    canToggle(occupant: AddressOccupant) {
        if (this.readOnly || occupant.type === AddressType.PRIMARY || occupant.type === AddressType.BILLING) {
            return false;
        } else if (this.authService.isAdmin() && occupant.type !== AddressType.OPERATIONS) {
            return true;
        }
        return this.authService.isAuthority();
    }
}
