import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AccountingPartyType, Address, BusinessClient, BusinessClientStatus, CorporateEntity, ExternalAccountingId, Member, MerchantAccount, MerchantAccountService, MerchantFormService,
    PagedResponse, SupportedTransactionType, Transaction, TransactionSubType, TransactionType, AuthService, RfiService, RfiHistory, Rfi } from 'projects/services/src/public-api';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent, ErrorType } from 'projects/components/src/public-api';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pt-link-accounting-merchant-modal',
    templateUrl: './link-accounting-merchant-modal.component.html',
    styleUrls: ['./link-accounting-merchant-modal.component.scss']
})
export class LinkAccountingMerchantModalComponent extends BaseModalComponent<LinkAccountingMerchantModalComponent> implements OnInit {

    TransactionType = TransactionType;
    SupportedTransactionType = SupportedTransactionType;
    AccountingPartyType = AccountingPartyType;

    businessClient: BusinessClient<any>;
    merchantForm: UntypedFormGroup;
    merchantMemberAccountForm: UntypedFormGroup;
    existingAccount: MerchantAccount;
    involvedMerchantAccount: MerchantAccount;
    transaction: Transaction;
    merchantAccountExistsError: { message: string, type: ErrorType };
    file: File;
    rfi: Rfi;
    memberId: string;
    isAccountingLinked = false;
    verifyBusinessClient = false;
    newMerchant = false;
    showAchInfo = false;

    constructor(private merchantFormService: MerchantFormService,
                private merchantAccountService: MerchantAccountService,
                private notifier: NotificationService,
                private rfiService: RfiService,
                private authService: AuthService,
                dialogRef: MatDialogRef<LinkAccountingMerchantModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.memberId = data.memberId;
        this.isAccountingLinked = data.accountingLinked;
        this.transaction = data.rfi.transaction;
        this.rfi = data.rfi;
    }

    ngOnInit() {
        this.isMerchantFormValid = this.isMerchantFormValid.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.verifyMerchantLinkage = this.verifyMerchantLinkage.bind(this);
        this.merchantForm = this.merchantFormService.initializeNewMerchantForm();
        this.merchantMemberAccountForm = this.merchantFormService.initializeNewMerchantMemberAccountForm(false);
    }

    isMerchantFormValid() {
        return this.merchantForm && this.merchantForm.valid &&
            (!this.newMerchant || (this.newMerchant && this.merchantMemberAccountForm.valid))
            && !this.merchantAccountExistsError;
    }

    close(refresh?: boolean) {
        super.close(refresh);
    }

    selectAccountingBusinessClient(businessClient: BusinessClient<any>) {
        this.businessClient = businessClient;
        this.verifyBusinessClient = true;
        if (!this.businessClient) {
            this.existingAccount = null;
            this.merchantAccountExistsError = null;
            this.showAchInfo = false;
            this.newMerchant = false;
            this.verifyBusinessClient = false;
            this.merchantForm.patchValue({
                merchantIdCtrl: '',
                allowedTransactionsCtrl: SupportedTransactionType.BOTH,
                achAccountHolderCtrl: '',
                achAccountNumberCtrl: '',
                achRoutingNumberCtrl: '',
                wireRoutingNumberCtrl: ''
            }, {emitEvent: false});
            this.merchantMemberAccountForm.reset();
            this.merchantMemberAccountForm.enable();
            this.merchantMemberAccountForm.controls['countryCtrl'].setValue('United States');
        } else {
            this.newMerchant = this.businessClient.memberAccountId === null;
            this.merchantAccountExistsError = null;
            this.merchantForm.controls['merchantCtrl'].setValue(this.businessClient);
            this.merchantForm.controls['merchantNameCtrl'].setValue(this.businessClient.name);

            if (this.newMerchant) {
                this.existingAccount = null;
                this.showAchInfo = true;
                const accountingData = this.businessClient.accountingData;

                this.merchantMemberAccountForm = this.merchantFormService.initializeNewMerchantMemberAccountForm(false, accountingData);
                this.merchantForm.controls['merchantNameCtrl'].disable();
                this.merchantForm.controls['allowedTransactionsCtrl'].setValue(accountingData.type === AccountingPartyType.SUPPLIER ? SupportedTransactionType.OUTGOING : SupportedTransactionType.INCOMING);
                this.merchantMemberAccountForm.enable();
            } else {
                if (this.businessClient.status === BusinessClientStatus.VERIFICATION_REQUIRED || this.businessClient.status === BusinessClientStatus.PENDING_VERIFICATION_REQUIRED) {
                    this.verifyBusinessClient = true;
                    this.merchantForm.controls['merchantNameCtrl'].disable();
                } else {
                    this.merchantAccountService.getAllMerchantAccountsByMerchantMemberAccountIdAndMemberId(this.businessClient.memberAccountId, this.memberId).subscribe((merchantAccounts: PagedResponse<MerchantAccount>) => {
                        this.merchantForm.controls['merchantNameCtrl'].enable();

                        this.existingAccount = merchantAccounts.content[0];
                        this.merchantForm.controls['merchantIdCtrl'].setValue(this.businessClient.memberAccountId);
                        this.showAchInfo = true;
                        this.merchantForm.controls['allowedTransactionsCtrl'].setValue(this.existingAccount.merchantAccountConfig.merchantAccountType);
                        this.merchantAccountService.getMerchantAccountConfigAddress(this.existingAccount.merchantAccountConfig.id).subscribe((address: Address) => {
                            this.merchantMemberAccountForm.controls['streetAddressOneCtrl'].setValue(address.streetAddressOne);
                            this.merchantMemberAccountForm.controls['cityCtrl'].setValue(address.city);
                            this.merchantMemberAccountForm.controls['stateProvinceCtrl'].setValue(address.stateProvince);
                            this.merchantMemberAccountForm.controls['countryCtrl'].setValue(address.country);
                            this.merchantMemberAccountForm.controls['zipPostalCodeCtrl'].setValue(address.zipPostalCode);
                            this.merchantMemberAccountForm.disable();
                        });
                        this.merchantAccountExistsError = {
                            message: 'This accounting party has already been registered.',
                            type: ErrorType.ERROR
                        };
                    });
                    this.merchantMemberAccountForm.disable();
                    this.verifyBusinessClient = false;
                }
            }
        }
    }

    getAccountingIds(accountingPartyType: AccountingPartyType): ExternalAccountingId[] {
        if (!this.existingAccount.accountingExternalIds.length) {
            return [];
        } else {
            return this.existingAccount.accountingExternalIds.filter((accountExternalId: ExternalAccountingId) => {
                return accountExternalId.accountingPartyType === accountingPartyType;
            });
        }
    }

    verifyMerchantLinkage(reset: any) {
        const merchantAccountId = this.transaction.subType === TransactionSubType.DEPOSIT ? this.transaction.payorAccountId : this.transaction.recipientAccountId;
        this.merchantAccountService.getAllMerchantAccountsByMerchantMemberAccountIdAndMemberId(merchantAccountId, this.transaction.originatorId).subscribe((merchantAccounts : PagedResponse<MerchantAccount>) => {
            this.involvedMerchantAccount = merchantAccounts.content[0];
            const accountingIds = this.involvedMerchantAccount.accountingExternalIds;
            accountingIds.push(new ExternalAccountingId(this.businessClient.accountingData.externalId, this.businessClient.accountingData.type));
            this.merchantAccountService.updateMerchantAccount(this.involvedMerchantAccount.id, { accountingExternalIds: accountingIds }).subscribe(() => {
                this.verifyBusinessClient = false;
                const rfiHistory = new RfiHistory();
                rfiHistory.notes = 'Connected accounting merchant to involved merchant';
                rfiHistory.transactionId = this.transaction.id;
                rfiHistory.status = this.rfi.status;
                rfiHistory.rfiId = this.rfi.id;
                rfiHistory.performedBy = this.authService.getProfile().userId;
                this.rfiService.saveRFIHistory(rfiHistory).subscribe(() => {
                    this.notifier.showSuccessCloseRequired(`Accounting party ${this.businessClient.name} has been linked to ${this.involvedMerchantAccount.merchantMemberAccount.member.name}.`);
                    reset();
                    this.close(true);
                });
            }, (error: any) => {
                reset();
                throw error;
            });
        });
    }

    onCancel() {
        this.merchantForm.controls['merchantCtrl'].setValue(null);
        this.merchantForm.controls['merchantNameCtrl'].setValue(null);
        this.existingAccount = null;
        this.businessClient = null;
        this.merchantAccountExistsError = null;
        this.showAchInfo = false;
        this.newMerchant = false;
        this.verifyBusinessClient = false;
        this.merchantForm.reset();
        this.merchantMemberAccountForm.reset();
        this.merchantMemberAccountForm.enable();
        this.merchantForm.controls['merchantNameCtrl'].enable();
    }

    getDbaName(member: Member) {
        return (member as CorporateEntity).dbaName;
    }

    getSubType() {
        return this.transaction.subType;
    }

    getTransaction() {
        return this.transaction;
    }
}
