<div class="terms default-background-color" *ngIf="task">
    <header class="card-header default-color" [ngStyle]="{'background': landingSettings?.primaryColor}">
        <div class="row h-100">
            <div class="col-5 text-left h-100">
                <pc-secure-image class="h-100" [imgSrc]="imageSrc" *ngIf="imageSrc" alt="Partner Logo" [class]="'img-icon-thumbnail'"></pc-secure-image>
            </div>
            <div class="col-7 text-right h-100">
                <img class="logo-header">
            </div>
        </div>
    </header>
    <div id="scrollable" pt-custom-scroll #appScrollElement="custom-scroll"  #scroll [ngStyle]="{'font-family': landingSettings?.primaryFont}" class="default-background-color" [ngbCollapse]="termsAccepted">
        <div *ngIf="!termsAccepted">
            <pt-consumer-terms></pt-consumer-terms>
        </div>
    </div>
    <div class="buttons default-background-color" *ngIf="!termsAccepted">
        <div>
            <button pc-no-dbl-click class="blue-button" (click)="onCancel()" [ngStyle]="buttonStyle()" submit-button>Cancel</button>
            <button pc-no-dbl-click class="blue-button" [disabled]="appScrollElement.disableBtn" (click)="onAcceptTerms(false)" type="button" [ngStyle]="buttonStyle()" submit-button>Accept and Continue</button>
            <button *ngIf="isQaEnabled" pc-no-dbl-click class="blue-button" [disabled]="appScrollElement.disableBtn" (click)="onAcceptTerms(true)" type="button" [ngStyle]="buttonStyle()" submit-button>Accept Using Default Test Account</button>
        </div>
    </div>

    <mat-stepper [@.disabled]="true" *ngIf="termsAccepted" [linear]="true" #stepper [ngStyle]="{'font-family': landingSettings?.primaryFont}" class="default-background-color" [orientation]="stepperOrientation">
        <ng-template matStepperIcon="contact_info">
            <mat-icon>account_circle</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="address">
            <mat-icon>home</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="banking">
            <mat-icon>add_card</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="complete">
            <mat-icon>done</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="verify">
            <mat-icon>attach_money</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="verification_code">
            <mat-icon>password</mat-icon>
        </ng-template>
        <mat-step *ngIf="flow === RetailFlow.PAY_WITH_CONFIA_ONBOARD" state="contact_info" [completed]="contactInfoForm.valid" [editable]="!consumerValid" label="Contact Information">
            <div class="d-flex justify-content-center">
                <form [formGroup]="contactInfoForm">
                    <pc-text-input [required]="true" [label]="'First Name'" [placeholder]="'First Name'" [formCtrl]="'firstNameCtrl'" [formGroup]="contactInfoForm">
                    </pc-text-input>
                    <pc-text-input [required]="false" [label]="'Middle Name'" [placeholder]="'Middle Name'" [formCtrl]="'middleNameCtrl'" [formGroup]="contactInfoForm">
                    </pc-text-input>
                    <pc-text-input [required]="true" [label]="'Last Name'" [placeholder]="'Last Name'" [formCtrl]="'lastNameCtrl'" [formGroup]="contactInfoForm">
                    </pc-text-input>
                    <pc-phone-input [required]="true" [label]="'Mobile Number'" [formCtrl]="'mobilePhoneCtrl'" [formGroup]="contactInfoForm">
                    </pc-phone-input>
                    <pc-email-input [required]="true" [label]="'Email Address'" [formCtrl]="'emailCtrl'" [formGroup]="contactInfoForm">
                    </pc-email-input>
                    <pc-dob-input [formGroup]="contactInfoForm" [formCtrl]="'birthDateCtrl'" [required]="true">
                    </pc-dob-input>
                    <div class="pull-right mt-2">
                        <button pc-no-dbl-click class="blue-button" mat-button matStepperNext [disabled]="!contactInfoForm.valid" [ngStyle]="buttonStyle()" type="button" submit-button>Next</button>
                    </div>
                </form>
            </div>
        </mat-step>
        <mat-step *ngIf="flow === RetailFlow.PAY_WITH_CONFIA_ONBOARD" state="address" [completed]="consumerValid && addressForm.valid" [editable]="!consumerValid" label="Address Information">
            <div class="d-flex justify-content-center">
                <form [formGroup]="addressForm">
                    <pc-address-input [addressTypeOpts]="[AddressType.PRIMARY]" [addressForm]="addressForm"></pc-address-input>
                    <div class="pull-right mt-2">
                        <button pc-no-dbl-click class="blue-button" mat-button matStepperPrevious [ngStyle]="buttonStyle()" submit-button>Previous</button>
                        <button pc-no-dbl-click *ngIf="!consumerValid" class="blue-button" mat-button matStepperNext [disabled]="!addressForm.valid" (click)="onSave()" [ngStyle]="buttonStyle()" type="button" submit-button>Save</button>
                    </div>
                </form>
            </div>
        </mat-step>
        <mat-step *ngIf="(flow === RetailFlow.PAY_WITH_CONFIA_ONBOARD || flow === RetailFlow.PAY_WITH_CONFIA_BANKING_REQUIRED) && !accountLinked" state="banking" [completed]="accountLinked" [editable]="consumerValid && !accountLinked" label="Banking Information">
            <div class="d-flex justify-content-center">
                <pt-yodlee-onboarding *ngIf="consumerValid" [landingSettings]="landingSettings" [accountToken]="accountToken" [taskId]="task.id" [testAccount]="useTestBankAccount" (resultSelected)="onSelection($event)"></pt-yodlee-onboarding>
            </div>
        </mat-step>
        <mat-step state="verify" [completed]="false" [editable]="false" label="Review Transaction">
            <div class="d-flex justify-content-center flex-column">
                <div class="row">
                    <div class="col-12 centered">
                        <p>Payment to <strong>{{task.variables.recipientMemberName}}</strong></p>
                    </div>
                    <div class="col-12 centered">
                        <p class="retail-amount">{{transactionTotal | currency}}</p>
                    </div>
                    <div class="col-12 centered" *ngIf="task.variables.allowEditableTips">
                        <div class="text-start"><strong> Add Tip Amount </strong></div>
                        <div class="centered">
                            <div class="d-flex flex-column">
                                <div>
                                    <mat-button-toggle-group class="row" #group="matButtonToggleGroup" [(ngModel)]="selected">
                                        <mat-button-toggle (change)="onTipSelect($event.value)" value="15"><strong> 15% <br> {{ getPercentage('15') | currency }} </strong></mat-button-toggle>
                                        <mat-button-toggle (change)="onTipSelect($event.value)" value="20"><strong> 20% <br> {{ getPercentage('20') | currency }} </strong></mat-button-toggle>
                                        <mat-button-toggle (change)="onTipSelect($event.value)" value="25"><strong> 25% <br> {{ getPercentage('25') | currency }} </strong></mat-button-toggle>
                                        <button pc-no-dbl-click [class]="getClass()" (click)="reset()" submit-button><strong>Other</strong></button>
                                    </mat-button-toggle-group>
                                </div>
                            </div>
                            <div [formGroup]="tipForm" *ngIf="isOtherTipPayment">
                                <div class="d-flex justify-content-center other-tip-input">
                                    <div class="dollar">
                                        <input type="text" oninput="value = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" placeholder="0.00" [formControlName]="'tipCtrl'" (change)="onTipChange()">
                                    </div>
                                </div>
                                <div class="tip-error">{{tipError}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-3 payment-container">
                        <div class="centered"><strong> Select Payment Type </strong></div>
                        <div class="mt-2 radio-margin" [ngClass]="tipError !== '' ? 'disabledFont' : ''">
                            <input type="radio" value="BANK_ACCOUNT" [checked] ="selectedPaymentType === 'BANK_ACCOUNT'" [disabled]="tipError !== ''" (change)="onTypeChanged($event.target.value)">
                            <label>&nbsp;Banking - pay securely through your bank account</label>
                        </div>
                    </div>
                    <div class="col-12 centered" *ngIf="expense" [formGroup]="tipForm">
                        <p class="text-start">Order Summary</p>
                        <table class="summary-table">
                            <thead>
                                <tr>
                                    <th class="text-end">Description</th>
                                    <th class="text-center">Amount</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="task.variables?.expenseId">
                                <tr *ngFor="let lineItem of expense.lineItems">
                                    <td class="text-end">{{lineItem.quantity}} {{lineItem.unit}} {{lineItem.description}}</td>
                                    <td class="mat-cell">
                                        <span class="table-amount">{{lineItem.amount | currency}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-end">Sub-Total:</td>
                                    <td class="mat-cell">
                                        <span class="table-amount">{{task.variables.transactionTotal | currency}}</span>
                                   </td>
                                </tr>
                                <tr>
                                    <td class="text-end" [class]="tipError ? 'tip-error' : ''">Tip:</td>
                                    <td class="mat-cell">
                                        <span class="table-amount">{{ (getTipAmount() || 0) | currency }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-end">Total:</td>
                                    <td class="mat-cell">
                                        <span class="table-amount">{{transactionTotal | currency}}</span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="!task.variables?.expenseId">
                                <tr>
                                    <td class="text-end">Sub-Total:</td>
                                    <td class="mat-cell">
                                        <span class="table-amount">{{task.variables.transactionTotal | currency}}</span>
                                   </td>
                                </tr>
                                <tr>
                                    <td class="text-end" [class]="tipError ? 'tip-error' : ''">Tip:</td>
                                    <td class="mat-cell">
                                        <span class="table-amount">{{ (getTipAmount() || 0) | currency }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-end">Total:</td>
                                    <td class="mat-cell">
                                        <span class="table-amount">{{transactionTotal | currency}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12 mt-3">
                        <button pc-no-dbl-click class="pull-right blue-button" mat-button matStepperNext [disabled]="tipError" (click)="onReviewed()" [ngStyle]="buttonStyle()" type="button">Next</button>
                    </div>
                </div>
            </div>
        </mat-step>
        <mat-step state="verification_code" [completed]="false" [editable]="false" label="Verification Code">
            <div class="d-flex justify-content-center">
                <form [formGroup]="verificationForm">
                    <div class="row">
                        <div class="col-12 centered">
                            <p>Payment to <strong>{{task.variables.recipientMemberName}}</strong></p>
                        </div>
                        <div class="col-12 centered">
                            <p class="retail-amount">{{transactionTotal | currency}}</p>
                        </div>
                        <div class="col-12 d-flex justify-content-center verification-code">
                            <div>
                                <pc-numeric-input #verificationCodeCtrl [label]="'Transaction Verification Code'" [placeholder]="'Enter 6 digit code'" [formCtrl]="'codeCtrl'" [formGroup]="verificationForm">
                                </pc-numeric-input>
                            </div>
                        </div>
                        <div class="col-12 mt-3">
                            <div class="pull-right">
                                <button pc-no-dbl-click class="blue-button" mat-button matStepperNext (click)="onResendCode()" [ngStyle]="buttonStyle()" type="button" submit-button>Resend Code</button>
                                <button pc-no-dbl-click class="blue-button" mat-button matStepperNext [disabled]="!verificationForm.valid" (click)="onVerificationCode()" [ngStyle]="buttonStyle()" type="button" submit-button>Verify</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </mat-step>
        <mat-step state="complete">
            <ng-template matStepLabel>Complete</ng-template>
            <div class="d-flex justify-content-center">
                <div *ngIf="accountLinkResult">
                    <p>Your payment to <strong>{{task.variables.recipientMemberName}}</strong> for {{transactionTotal | currency}} has been submitted.</p>
                    <div class="pull-right mt-2">
                        <button pc-no-dbl-click class="blue-button" (click)="checkTransactionStatusAndRedirect()" [ngStyle]="buttonStyle()" submit-button>OK</button>
                    </div>
                </div>
            </div>
        </mat-step>
    </mat-stepper>
    <footer class="card-footer text-center"
            [ngStyle]="{'background': landingSettings?.primaryColor,
            'color':landingSettings?.secondaryColor}" *ngIf="!termsAccepted">
        <p>Copyright &#169; 2024 Confia Financial LLC. All rights reserved.<br>For more
        information about Confia, please see <a [ngStyle]="{'color':landingSettings?.secondaryColor}" href="https://www.confia.fi" target="_blank">www.confia.fi</a>. <br>Please see our
        privacy policy at <a [ngStyle]="{'color':landingSettings?.secondaryColor}" href="https://www.confia.fi/online-privacy-policy/" target="_blank">www.confia.fi/online-privacy-policy/</a></p>
    </footer>
</div>
<div class="splash-bg" *ngIf="!termsAccepted">
    <div class="splash-body" *ngIf="errorMessage">
        <h5>{{errorMessage}}</h5>
    </div>
</div>
