<div>
    <form [formGroup]="merchantForm">
        <div class="modal-header">
            <div class="pull-left"><img class="dashboard-icon " src="assets/images/account-2.svg" alt=""></div>
            <label class="form-title">Connect Accounting Merchant Account</label>
            <i class="modal-close" (click)="close()">&times;</i>
        </div>
        <div class="modal-content">
            <div class="row">
                <div class="col-12">
                    <pc-business-client-selector [formGroup]="merchantForm" [formCtrl]="'merchantCtrl'" [merchantNameFormCtrl]="'merchantNameCtrl'" [placeholder]="'Search Accounting Parties'" [subType] = "getSubType()" [linkAccountingMerchantFromRfi] = "true"
                        [accountingLinked]="isAccountingLinked" [includeConfiaMerchants]="false" (businessClientSelected)="selectAccountingBusinessClient($event)" (closeTransactionModal)="close($event)"></pc-business-client-selector>
                </div>
            </div>
            <div *ngIf="verifyBusinessClient" class="row">
                <div class="col-12 d-flex justify-content-center">
                    <div *ngIf="businessClient.accountingData">
                        <label class="form-label mb-2">Accounting Info</label>
                        <div>Business Name: {{businessClient.accountingData.name}}</div>
                        <div *ngIf="businessClient.accountingData.dbaName">{{businessClient.accountingData.dbaName}}</div>
                        <div *ngIf="businessClient.accountingData.email">{{businessClient.accountingData.email}}</div>
                        <div *ngIf="businessClient.accountingData.officePhone">{{businessClient.accountingData.officePhone}}</div>
                        <div *ngIf="businessClient.accountingData.billAddress" class="mt-2">{{businessClient.accountingData.billAddress.streetAddressOne}}<br/>
                            {{businessClient.accountingData.billAddress.city}}, {{businessClient.accountingData.billAddress.stateProvince}}<br/>
                            {{businessClient.accountingData.billAddress.country}}<br/>
                            {{businessClient.accountingData.billAddress.zipPostalCode}}<br/>
                        </div>
                        <div *ngIf="businessClient.accountingData.contactName" class="mt-2">Contact Name: {{businessClient.accountingData.contactName}}</div>
                        <div *ngIf="businessClient.accountingData.externalId" matTooltipClass="tooltip" matTooltip="{{businessClient.accountingData.externalId}}" class="mt-2 text-truncate">{{businessClient.accountingData.type | translate}} ID: {{businessClient.accountingData.externalId}}</div>
                    </div>
                </div>
                <div class="col-12 mt-3">
                    <p><b>Please confirm the information, and link your accounting system merchant to the involved merchant.</b></p>
                </div>
            </div>
            <div *ngIf="!verifyBusinessClient" class="row">
                <dl class="row" *ngIf="existingAccount">
                    <div class="col-3"></div>
                    <div class="col-6">
                        <dl class="row">
                            <dt class="col-sm-4">Merchant Name:</dt>
                            <dd class="col-sm-8">{{existingAccount.merchantMemberAccount.member.name}}</dd>
                            <dt class="col-sm-4" *ngIf="getDbaName(existingAccount.merchantMemberAccount.member)">Doing Business As:</dt>
                            <dd class="col-sm-8" *ngIf="getDbaName(existingAccount.merchantMemberAccount.member)">{{getDbaName(existingAccount.merchantMemberAccount.member)}}</dd>
                        </dl>
                    </div>
                    <div class="col-3"></div>
                </dl>
                <div class="row">
                    <div class="col-2" *ngIf="(!showAchInfo && newMerchant) || (existingAccount && showAchInfo)">
                    </div>
                    <div class="col-4" *ngIf="!newMerchant && !showAchInfo">
                    </div>
                    <div class="col-4" *ngIf="newMerchant || existingAccount">
                        <span class="subtitle">Merchant Address Info</span>
                        <pc-address-input [addressForm]="merchantMemberAccountForm" [lockCountry]="false"></pc-address-input>
                    </div>
                    <div class="col-4" *ngIf="showAchInfo">
                        <div *ngIf="!newMerchant">
                            <span class="subtitle">Merchant Payment Options</span>
                            <div *ngIf="existingAccount && (existingAccount.merchantAccountConfig.merchantAccountType === SupportedTransactionType.OUTGOING || existingAccount.merchantAccountConfig.merchantAccountType === SupportedTransactionType.BOTH)">
                                Accepts Payments via:
                                <ul>
                                    <li *ngIf="existingAccount.merchantAccountConfig.defaultOutgoingTransactionType === TransactionType.OUTGOING_ACH_TO_MERCHANT">ACH</li>
                                    <li *ngIf="existingAccount.merchantAccountConfig.defaultOutgoingTransactionType === TransactionType.OUTGOING_WIRE_TO_MERCHANT">Wire</li>
                                    <li *ngIf="existingAccount.merchantAccountConfig.defaultOutgoingTransactionType === TransactionType.OUTGOING_CHECK_TO_MERCHANT">Check</li>
                                    <li *ngIf="existingAccount.merchantAccountConfig.defaultOutgoingTransactionType === TransactionType.OUTGOING_DIGITAL_CHECK_TO_MERCHANT">Digital Check</li>
                                </ul>
                            </div>
                            <div *ngIf="existingAccount && (existingAccount.merchantAccountConfig.merchantAccountType === SupportedTransactionType.INCOMING || existingAccount.merchantAccountConfig.merchantAccountType === SupportedTransactionType.BOTH)">
                                Pays via:
                                <ul>
                                    <li *ngIf="existingAccount.merchantAccountConfig.defaultIncomingTransactionType === TransactionType.INCOMING_ACH_FROM_MERCHANT">ACH</li>
                                    <li *ngIf="existingAccount.merchantAccountConfig.defaultIncomingTransactionType === TransactionType.INCOMING_WIRE_FROM_MERCHANT">Wire</li>
                                    <li *ngIf="existingAccount.merchantAccountConfig.defaultIncomingTransactionType === TransactionType.INCOMING_CHECK_FROM_MERCHANT">Check</li>
                                </ul>
                            </div>
                            <div *ngIf="existingAccount">
                                <span *ngIf="getAccountingIds(AccountingPartyType.SUPPLIER).length">Supplier IDs:</span>
                                <ul *ngIf="getAccountingIds(AccountingPartyType.SUPPLIER).length">
                                    <li *ngFor="let supplierId of getAccountingIds(AccountingPartyType.SUPPLIER)" matTooltipClass="tooltip" matTooltip="{{supplierId.accountingExternalId}}" class="text-truncate">{{supplierId.accountingExternalId}}</li>
                                </ul>
                                <span *ngIf="getAccountingIds(AccountingPartyType.CUSTOMER).length">Customer IDs:</span>
                                <ul *ngIf="getAccountingIds(AccountingPartyType.CUSTOMER).length">
                                    <li *ngFor="let supplierId of getAccountingIds(AccountingPartyType.CUSTOMER)" matTooltipClass="tooltip" matTooltip="{{supplierId.accountingExternalId}}" class="text-truncate">{{supplierId.accountingExternalId}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-4" *ngIf="!newMerchant && !showAchInfo">
                    </div>
                    <div class="col-2" *ngIf="(!showAchInfo && newMerchant) || (existingAccount && showAchInfo)">
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div *ngIf="merchantAccountExistsError" class="duplicate-account">
                <pc-error-message [error]="merchantAccountExistsError" [width]="'full'"></pc-error-message>
            </div>
            <pc-submit-button *ngIf="!existingAccount && newMerchant" [submit]="onCancel" [text]="'Cancel'"></pc-submit-button>
            <div *ngIf="verifyBusinessClient" class="d-flex justify-content-end">
                <pc-submit-button [submit]="verifyMerchantLinkage" [text]="'Link To Involved Merchant'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
