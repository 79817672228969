import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import { Configuration, CorporateKeyParty, KeyPartyService, PagedResponse } from 'projects/services/src/public-api';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
    selector: 'pc-corporate-key-party-searcher',
    templateUrl: './corporate-key-party-searcher.component.html',
    styleUrls: ['./corporate-key-party-searcher.component.scss']
})

export class CorporateKeyPartySearcherComponent implements OnInit, AfterViewInit {

    @Input() label: string;
    @Input() placeholder = '';
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;
    @Input() existingKeyPartyIds: string[];

    @Output() corporateKeyPartySelected = new EventEmitter<CorporateKeyParty>();

    corporateKeyPartyAutoComplete: Observable<CorporateKeyParty[]> = null;
    autoCompleteControl = new UntypedFormControl('');
    corporateKeyPartyOptions: CorporateKeyParty[] = [];
    searching = false;
    keyPartySelect: Subscription;
    searchLimit: number;
    totalResults: number;

    @ViewChild('corporateKeyPartySelect', { read: MatAutocompleteTrigger }) trigger: MatAutocompleteTrigger;

    constructor(private keyPartyService: KeyPartyService) {}

    ngOnInit() {
        this.searchLimit = Configuration.getConfig().autoCompleteSearchLimit;
        this.formGroup.addControl('autoCompleteCtrl', this.autoCompleteControl);
        this.corporateKeyPartyAutoComplete = this.autoCompleteControl.valueChanges.pipe(
            startWith(''),
            distinctUntilChanged(),
            debounceTime(1000),
            switchMap((value) => {
                this.corporateKeyPartyOptions = [];
                return this.lookup(value || '');
            })
        );
    }

    ngAfterViewInit() {
        this.subscribeToClosingActions();
    }

    private subscribeToClosingActions() {
        if (this.keyPartySelect && !this.keyPartySelect.closed) {
            this.keyPartySelect.unsubscribe();
        }

        this.keyPartySelect = this.trigger.panelClosingActions.subscribe((e) => {
            if (!e || !e.source) {
                if (this.corporateKeyPartyOptions.length === 1) {
                    this.corporateKeyPartyChanged(this.corporateKeyPartyOptions[0]);
                } else {
                    const autoCompleteValue = this.autoCompleteControl.value;
                    if (autoCompleteValue) {
                        const selected = this.corporateKeyPartyOptions
                            .map((option) => {
                                return option.name;
                            })
                            .find((option) => {
                                return option === autoCompleteValue;
                            });

                        if (!selected && this.selected()) {
                            this.formGroup.controls[this.formCtrl].markAsPristine();
                            this.formGroup.controls[this.formCtrl].setValue('');
                            this.autoCompleteControl.setValue('');
                            this.corporateKeyPartyChanged(new CorporateKeyParty());
                        } else {
                            const keyParty = new CorporateKeyParty();
                            keyParty.name = autoCompleteValue;
                            this.corporateKeyPartyChanged(keyParty);
                        }
                    }
                }
            }
        }, () => {
            return this.subscribeToClosingActions();
        }, () => {
            return this.subscribeToClosingActions();
        });
    }

    lookup(value: string) : Observable<CorporateKeyParty[]> {
        this.totalResults = 0;
        if (value?.length <= 1) {
            return of([]);
        }
        this.searching = true;
        this.formGroup.controls['autoCompleteCtrl'].disable({emitEvent: false});
        return this.keyPartyService.findCorporateKeyParties(value, this.searchLimit).pipe(
            switchMap(
                (response: PagedResponse<CorporateKeyParty>) => {
                    this.totalResults = response.totalElements;
                    this.formGroup.controls['autoCompleteCtrl'].enable({emitEvent: false});
                    this.corporateKeyPartyOptions = response.content;
                    if (value && value.length >= 2) {
                        const newKeyParty = new CorporateKeyParty();
                        newKeyParty.name = value;
                        this.corporateKeyPartyOptions.unshift(newKeyParty);
                    }
                    this.searching = false;
                    return of(this.corporateKeyPartyOptions);
                }
            ), catchError((_error) => {
                this.formGroup.controls['autoCompleteCtrl'].enable({emitEvent: false});
                this.searching = false;
                return [];
            })
        );
    }

    corporateKeyPartyChanged(corporateKeyParty: CorporateKeyParty) {
        this.formGroup.controls[this.formCtrl].markAsDirty();
        this.formGroup.controls[this.formCtrl].setValue(corporateKeyParty.id);
        this.formGroup.controls['autoCompleteCtrl'].disable({emitEvent: false});
        this.formGroup.controls['autoCompleteCtrl'].patchValue(corporateKeyParty.id ? corporateKeyParty.name : 'New Corporate Key Party', {emitEvent: false});
        this.corporateKeyPartySelected.emit(corporateKeyParty);
    }

    onInputChanged(event: any) {
        if (event.keyCode === 220) {
            event.preventDefault();
            return;
        }
    }

    selected() {
        return this.formGroup.controls['autoCompleteCtrl'].disabled;
    }

    onIconClick(event: any) {
        if (this.selected()) {
            this.corporateKeyPartyOptions = [];
            this.formGroup.controls[this.formCtrl].setValue(null);
            this.formGroup.controls['autoCompleteCtrl'].enable({emitEvent: false});
            this.autoCompleteControl.setValue('');
            this.corporateKeyPartySelected.emit(new CorporateKeyParty());
        }
        event.stopPropagation();
    }

    getCorporateKeyPartyName(corporateKeyParty: CorporateKeyParty) {
        if (!corporateKeyParty.id) {
            return corporateKeyParty.name + ' (create new key party)';
        }
        if (corporateKeyParty.dbaName && corporateKeyParty.dbaName.toLowerCase() !== corporateKeyParty.name.toLowerCase()) {
            return corporateKeyParty.name + (' (DBA: ' + corporateKeyParty.dbaName + ')');
        }
        return corporateKeyParty.name;
    }

    keyPartyExists(keyPartyId: string) {
        return keyPartyId && this.existingKeyPartyIds.indexOf(keyPartyId) >= 0;
    }
}
