import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
    KeyParty, KeyPartyService, KeyPartyStatus, KeyPartyType, MemberAccountAccess, PagedResponse, Role, User, UserMemberAccess, IndividualKeyParty
} from 'projects/services/src/public-api';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { ErrorType } from 'projects/components/src/public-api';

@Component({
    selector: 'pc-link-key-party-modal',
    templateUrl: './link-key-party-modal.component.html'
})
export class LinkKeyPartyModalComponent extends BaseModalComponent<LinkKeyPartyModalComponent> implements OnInit {

    memberId: string;
    access: UserMemberAccess;
    user: User;
    linkKeyPartyForm: UntypedFormGroup;
    errorMessage: { message: string, type: ErrorType };
    availableKeyParties: KeyParty[];
    selectedKeyParty: KeyParty;
    unlinkAvailable = true;
    previousKeyParty = null;


    constructor(private notifier: NotificationService,
                private keyPartyService: KeyPartyService,
                private formBuilder: UntypedFormBuilder,
                dialog: MatDialogRef<LinkKeyPartyModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialog);
        this.memberId = data.memberId;
        this.access = data.access;
        this.user = data.access.user;
    }

    ngOnInit() {
        this.isLinkKeyPartyFormValid = this.isLinkKeyPartyFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.previousKeyParty = this.access.keyParty;
        this.unlinkAvailable = !!this.access.keyParty;
        this.access.accountAccess.forEach((accountAccess: MemberAccountAccess) => {
            if (accountAccess.role === Role.CORPORATE_ADMIN || accountAccess.role === Role.ADMIN || accountAccess.role === Role.NEW_ADMIN_REGISTRANT) {
                this.unlinkAvailable = false;
            }
        });
        this.keyPartyService.getAllKeyPartiesByType(this.memberId, KeyPartyType.INDIVIDUAL).subscribe((response: PagedResponse<KeyParty>) => {
            this.availableKeyParties = response.content.filter((keyParty) => {
                return keyParty.status === KeyPartyStatus.ACTIVE && (keyParty.userId === null || keyParty.userId === this.user.id);
            });
            if (this.unlinkAvailable) {
                const unlinkOption = new KeyParty();
                unlinkOption.id = 'unlink';
                unlinkOption.individualKeyParty = new IndividualKeyParty();
                unlinkOption.individualKeyParty.name = '-- Unlink Key Party --';
                this.availableKeyParties.unshift(unlinkOption);
            }
            this.linkKeyPartyForm = this.formBuilder.group({
                keyPartyIdCtrl: new UntypedFormControl('', [Validators.required])
            });
            if (this.availableKeyParties.length === 0) {
                this.errorMessage = {
                    message: 'There are no key parties that are not already assigned to a user.  Please add a key party for administrative access.',
                    type: ErrorType.ERROR
                };
                this.linkKeyPartyForm.disable();
            }
        });
    }

    close(refresh?: boolean) {
        super.close(refresh);
    }

    onSubmit(reset: any) {
        const keyPartyId = this.linkKeyPartyForm.controls['keyPartyIdCtrl'].value;
        if (keyPartyId === 'unlink') {
            this.keyPartyService.updateKeyParty(this.previousKeyParty.id, {userId: null}).subscribe(() => {
                this.close(true);
                this.notifier.showSuccess(`<b>${this.user.name}</b> has been unlinked from <b>${this.previousKeyParty.individualKeyParty.name}</b>.`);
            },
            (errorResult: any) => {
                reset();
                throw errorResult;
            });
        } else {
            const updates = {
                userId: this.user.id
            };
            if (this.previousKeyParty?.id) {
                this.keyPartyService.updateKeyParty(this.previousKeyParty.id, {userId: null}).subscribe(() => {
                    this.updateKeyPartyLink(keyPartyId, updates, reset);
                },
                (errorResult: any) => {
                    reset();
                    throw errorResult;
                });
            } else {
                this.updateKeyPartyLink(keyPartyId, updates, reset);
            }
        }
    }

    updateKeyPartyLink(keyPartyId: string, updates: any, reset: any) {
        this.keyPartyService.updateKeyParty(keyPartyId, updates).subscribe(() => {
            this.close(true);
            this.notifier.showSuccess(`<b>${this.user.name}</b> has been linked to <b>${this.selectedKeyParty.individualKeyParty.name}</b>.`);
        },
        (errorResult: any) => {
            reset();
            throw errorResult;
        });
    }

    isLinkKeyPartyFormValid() {
        return this.linkKeyPartyForm.valid;
    }

    onSelectKeyParty(keyPartyId: string) {
        this.errorMessage = null;
        if (keyPartyId === 'unlink') {
            this.selectedKeyParty = null;
            this.errorMessage = {
                message: 'This will unlink the user from this key party.  You will need to relink to give administrative access.',
                type: ErrorType.WARN
            };
        } else {
            this.selectedKeyParty = this.availableKeyParties.find((unassignedKeyParty: KeyParty) => {
                return unassignedKeyParty.id === keyPartyId;
            });
            if (this.selectedKeyParty.individualKeyParty.lastName !== this.user.lastName) {
                this.errorMessage = {
                    message: 'The key party name does not match the user name.',
                    type: ErrorType.ERROR
                };
                this.linkKeyPartyForm.controls['keyPartyIdCtrl'].setErrors({'incorrect': true});
            } else {
                this.linkKeyPartyForm.controls['keyPartyIdCtrl'].setErrors(null);
            }
        }
    }
}
