import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild} from '@angular/core';
import {
    Transaction, Bill, Invoice, RecordsService, Contract, SmartContractService, LineItem, DataroomService, Upload, SupportingDocument, RfiHistory, AuthService, RfiService, PaymentService,
    Rfi, Shipment, PagedResponse, LabelledPackage, Utils, RfiNotification, WorkflowService, TransactionType, TransactionSubType, RfiStatus, TransactionState, DocumentType, RfiNotificationType,
    AccountingLinkedMember, AccountingPartyType, AccountingService, ExternalAccountingId, Member, MemberAccount, MemberAccountService, MemberAccountStatus, MemberType, MerchantAccount,
    MerchantAccountService, WalletService, CheckProcessorService
} from 'projects/services/src/public-api';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { LoaderComponent } from 'projects/components/src/public-api';
import { v4 as uuidv4 } from 'uuid';
import { MatTable } from '@angular/material/table';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
    LinkAccountingMerchantModalComponent,
    UpdateTransactionLineItemsComponent
} from 'projects/pt/src/app/components/modal';

@Component({
    selector: 'pt-transaction-line-items-list',
    templateUrl: './transaction-line-items-list.component.html',
    styleUrls: ['./transaction-line-items-list.component.scss']
})
export class TransactionLineItemsListComponent implements OnChanges {

    RfiStatus = RfiStatus;
    DocumentType = DocumentType;
    MemberType = MemberType;
    TransactionSubType = TransactionSubType;

    @Input() transaction: Transaction;
    @Input() rfi: Rfi;
    @Input() changeTrigger: number;

    @Output() transactionUpdated: EventEmitter<void> = new EventEmitter<void>();

    expenses: Bill[] = [];
    invoices: Invoice[] = [];
    shipments: Shipment[] = [];
    contract: Contract;
    otherDocuments: SupportingDocument[] = [];
    accountingExternalIds: ExternalAccountingId[] = [];
    memberAccount: MemberAccount;
    merchantAccount: MerchantAccount;
    businessClientAccount: MemberAccount;
    businessClient: Member;
    documentUploadContext: string;
    businessClientAccountId: string;
    availableBalance: any;
    value: string;
    transactionSubType = '';
    isLoadOtherDocuments = true;
    otherDocumentDisplayedColumn = ['document_name', 'description', 'date'];
    isTrackingSystemDetails = true;
    accountingLinked = false;
    accountingMerchant = false;
    entryPresent = false;

    trackingSystemDisplayedColumn = ['item', 'manifest_number', 'supplier', 'recipient', 'created', 'received_date', 'manifest_contents'];
    expenseDisplayedColumn = ['item', 'verified_expense', 'source', 'bill', 'vendor', 'description', 'expense_date', 'amount', 'expense_details', 'documents', 'synced'];
    incomeSystemDisplayedColumn = ['item', 'verified_invoice', 'source', 'invoice', 'customer', 'description', 'invoice_date', 'amount', 'invoice_details', 'documents', 'synced'];
    isIncomesDetails = true;

    @ViewChild('downloadDocumentLink') downloadDocumentLink: ElementRef;
    @ViewChild('upload') uploadLink: ElementRef;
    @ViewChild('billsTable') billsTable: MatTable<Bill>;
    @ViewChild('invoicesTable') invoicesTable: MatTable<Invoice>;

    constructor(private checkProcessorService: CheckProcessorService,
                private recordsService: RecordsService,
                public authService: AuthService,
                private paymentService: PaymentService,
                private rfiService: RfiService,
                private dataroomService: DataroomService,
                private workflowService: WorkflowService,
                private notificationService: NotificationService,
                private overlay: Overlay,
                private smartContractService: SmartContractService,
                private memberAccountService: MemberAccountService,
                private accountingService: AccountingService,
                private merchantAccountService: MerchantAccountService,
                private dialog: MatDialog) {}

    ngOnChanges() {
        if (this.transaction && this.changeTrigger) {
            this.transactionSubType = this.transaction.subType;
            this.expenses = [];
            this.invoices = [];
            this.loadDetails();
            this.isAccountingEntity();
            this.isRfiValid();
        }
    }

    loadDetails() {
        if (this.transaction.transactionType === TransactionType.CURRENCY_WITHDRAWAL) {
            this.loadCurrencyWithdrawalDocuments();
        } else if (this.transaction.transactionType === TransactionType.SMART_CONTRACT_PAYMENT) {
            this.loadContract();
        } else if (this.transaction.transactionType === TransactionType.CASH_DEPOSIT ||
            this.transaction.transactionType === TransactionType.CHECK_DEPOSIT ||
            this.transaction.transactionType === TransactionType.INCOMING_WIRE_FROM_MERCHANT ||
            this.transaction.transactionType === TransactionType.INCOMING_ACH_FROM_MERCHANT ||
            this.transaction.transactionType === TransactionType.INCOMING_CHECK_FROM_MERCHANT ||
            this.transaction.transactionType === TransactionType.ACH_DEPOSIT) {

            this.loadInvoices();
        } else {
            this.loadExpenses();
        }
        this.loadShipments();
        this.extractOtherDocuments();
    }

    loadCurrencyWithdrawalDocuments() {
        for (const supportingDocument of this.transaction.supportingDocuments) {
            if (!supportingDocument.document) {
                this.dataroomService.getDocumentById(supportingDocument.documentId).subscribe((upload: Upload) => {
                    supportingDocument.document = upload;
                });
            }
        }
    }

    loadShipments() {
        this.recordsService.getShipmentsForTransaction(this.transaction.id, 0, 100, 'createdDateTime', 'DESC').subscribe((response: PagedResponse<Shipment>) => {
            this.shipments = response.content;
            this.shipments.forEach((shipment) => {
                this.recordsService.getPackagesForShipment(shipment.id, 0, 100, 'created', 'DESC').subscribe((packages: PagedResponse<LabelledPackage>) => {
                    shipment.lineItems = packages.content;
                });
            });
            this.isTrackingSystemDetails = false;
        });
    }

    loadSupportingDocuments() {
        for (const supportingDocument of this.transaction.supportingDocuments) {
            // restrict rear image loading for members for external check transactions
            const isValidDocument = !supportingDocument.document && (this.authService.isAuthorityOrReviewer() || this.transaction.transactionType !== TransactionType.OUTGOING_EXTERNAL_CHECK_TO_MERCHANT ||
                supportingDocument.documentType !== DocumentType.EXTERNAL_CHECK_REAR_IMAGE);
            if (isValidDocument) {
                this.dataroomService.getDocumentById(supportingDocument.documentId).subscribe((upload: Upload) => {
                    supportingDocument.document = upload;
                    this.extractOtherDocuments();
                });
            }
        }
    }

    loadExpenses() {
        if (this.transaction.lineItems.length > 0) {
            this.entryPresent = true;
            for (const lineItem of this.transaction.lineItems) {
                lineItem.supportingDocuments = [];
                if (lineItem.type !== 'OTHER') {
                    this.recordsService.getExpense(lineItem.transactionEntryId).subscribe(
                        (bill: Bill) => {
                            const isDuplicate = this.expenses.filter((storedExpense: Bill) => {
                                return storedExpense.id === bill.id;
                            }).length > 0;
                            if (!isDuplicate) {
                                bill.supportingDocuments = [];
                                bill.lineItemId = lineItem.id;
                                this.extractLineItemDocuments(lineItem, bill);
                                this.expenses.push(bill);
                                this.billsTable.renderRows();
                            }
                        }
                    );
                } else {
                    const bill = new Bill();
                    if (lineItem.allocatedAmount) {
                        bill.paidAmount = lineItem.allocatedAmount.toFixed(2);
                    }
                    bill.type = lineItem.type;
                    bill.description = lineItem.description;
                    bill.txnDate = lineItem.created;
                    bill.lineItems = [];
                    bill.supportingDocuments = [];
                    bill.lineItemId = lineItem.id;
                    bill.vendorName = lineItem.memberName;
                    bill.memberAccountId = lineItem.memberAccountId;
                    this.extractLineItemDocuments(lineItem, bill);
                    this.expenses.push(bill);
                }
            }
        } else {
            this.loadSupportingDocuments();
        }
        this.transaction.expenses = this.expenses;
    }

    loadInvoices() {
        if (this.transaction.lineItems.length > 0) {
            this.entryPresent = true;
            for (const lineItem of this.transaction.lineItems) {
                lineItem.supportingDocuments = [];
                if (lineItem.type !== 'OTHER') {
                    this.recordsService.getInvoice(lineItem.transactionEntryId).subscribe(
                        (invoice: Invoice) => {
                            const isDuplicate = this.invoices.filter((storedInvoice: Invoice) => {
                                return storedInvoice.id === invoice.id;
                            }).length > 0;
                            if (!isDuplicate) {
                                invoice.supportingDocuments = [];
                                invoice.lineItemId = lineItem.id;
                                this.extractLineItemDocuments(lineItem, invoice);
                                this.invoices.push(invoice);
                                this.invoicesTable.renderRows();
                            }
                        }
                    );
                } else {
                    const invoice = new Invoice();
                    if (lineItem.allocatedAmount) {
                        invoice.paidAmount = lineItem.allocatedAmount.toFixed(2);
                    }
                    invoice.type = lineItem.type;
                    invoice.description = lineItem.description;
                    invoice.txnDate = lineItem.created;
                    invoice.lineItems = [];
                    invoice.supportingDocuments = [];
                    invoice.lineItemId = lineItem.id;
                    invoice.customerName = lineItem.memberName;
                    invoice.memberAccountId = lineItem.memberAccountId;
                    this.extractLineItemDocuments(lineItem, invoice);
                    this.invoices.push(invoice);
                }
            }
        } else {
            this.loadSupportingDocuments();
        }
        this.transaction.incomes = this.invoices;
        this.isIncomesDetails = false;
    }

    extractLineItemDocuments(lineItem: LineItem, entry: any) {
        for (const supportingDocument of this.transaction.supportingDocuments) {
            // restrict rear image loading for members for external check transactions
            const isValidDocument = !supportingDocument.document && (this.authService.isAuthorityOrReviewer() || this.transaction.transactionType !== TransactionType.OUTGOING_EXTERNAL_CHECK_TO_MERCHANT ||
                supportingDocument.documentType !== DocumentType.EXTERNAL_CHECK_REAR_IMAGE);
            if (isValidDocument) {
                this.dataroomService.getDocumentById(supportingDocument.documentId).subscribe((upload: Upload) => {
                    supportingDocument.document = upload;
                    this.extractOtherDocuments();
                });
            }

            if (supportingDocument.lineItemId && supportingDocument.lineItemId === lineItem.id) {
                entry.supportingDocuments.push(supportingDocument);
            }
        }
    }

    loadContract() {
        this.smartContractService.getContractByTransactionId(this.transaction.id).subscribe((contract: Contract) => {
            this.contract = contract;
        });
    }

    extractOtherDocuments() {
        const profile = this.authService.getProfile();
        this.isLoadOtherDocuments = false;
        this.otherDocuments = this.transaction.supportingDocuments.sort((x, y) => {
            return x.created < y.created ? -1 : (x.created > y.created ? 1 : 0);
        }).filter((supportingDocument) => {
            return supportingDocument.document && (!supportingDocument.lineItemId &&
                ((this.authService.isAuthorityOrReviewer()) ||
                (supportingDocument.memberId === profile.memberId && !supportingDocument.authorityOnly))) && (this.transaction.state !== TransactionState.CANCELLED || supportingDocument.documentType !== DocumentType.DIGITAL_CHECK_TO_MERCHANT);
        });
    }

    downloadFile(document: any) {
        this.dataroomService.downloadResource(document, this.downloadDocumentLink);
    }

    addSupportingDocument(lineItemId: string) {
        this.documentUploadContext = lineItemId;
        const link: HTMLElement = this.uploadLink.nativeElement;
        link.click();
    }

    isRemovableDocument(document: SupportingDocument) {
        if (document.documentType === DocumentType.RAW_REAR_CHECK_IMAGE || document.documentType === DocumentType.RAW_FRONT_CHECK_IMAGE
            || document.documentType === DocumentType.PROCESSED_REAR_CHECK_IMAGE || document.documentType === DocumentType.PROCESSED_FRONT_CHECK_IMAGE
            || document.documentType === DocumentType.DIGITAL_CHECK_TO_MERCHANT || document.documentType === DocumentType.EXTERNAL_CHECK_FRONT_IMAGE
            || document.documentType === DocumentType.EXTERNAL_CHECK_REAR_IMAGE) {
            return false;
        }
        return document.documentType !== DocumentType.DEPOSIT_CONFIRMATION && this.rfi.status !== RfiStatus.PENDING_DEPOSIT_VERIFICATION && this.rfi.status !== RfiStatus.CLOSED && this.rfi.status !== RfiStatus.MEMBER_RECONCILIATION && this.isAuthorityOrAdmin();
    }

    removeSupportingDocument(supportingDocument: SupportingDocument) {
        const rfiHistory = new RfiHistory();
        rfiHistory.notes = `Deleted supporting document ${supportingDocument.document.fileName}`;
        rfiHistory.transactionId = this.transaction.id;
        rfiHistory.status = this.rfi.status;
        rfiHistory.rfiId = this.rfi.id;
        rfiHistory.performedBy = this.authService.getProfile().userId;
        rfiHistory.authorityOnly = supportingDocument.authorityOnly;
        this.rfiService.saveRFIHistory(rfiHistory).subscribe(() => {
            this.paymentService.deleteTransactionSupportingDocument(this.transaction.id, supportingDocument.id).subscribe(() => {
                this.transactionUpdated.emit();
            });
        });
    }

    selectFile(event: any) {
        // check file size and error??
        if (event.target.files && event.target.files.length) {
            const file: File = event.target.files[0];
            Utils.validateFile(file, event);

            const overlayRef = this.overlay.create({
                positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                hasBackdrop: true
            });
            const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
            componentRef.instance.title = 'Uploading files.  Please wait...';

            const path = `${this.rfi.memberId}/${this.authService.getProfile().userId}/transaction/${this.transaction.submissionReference}/${uuidv4()}`;
            let documentType = DocumentType.ALL;
            if (this.transactionSubType === TransactionSubType.DEPOSIT) {
                documentType = DocumentType.DEPOSIT_SUPPORTING_DOCUMENT;
            } else if (this.transactionSubType === TransactionSubType.PAYMENT) {
                documentType = DocumentType.PAYMENT_SUPPORTING_DOCUMENT;
            }
            this.dataroomService.uploadFile(this.rfi.memberId, path, file, file.name, null, documentType, this.rfi.memberId).subscribe((uploadResp: any) => {
                const rfiHistory = new RfiHistory();
                rfiHistory.notes = 'Uploaded supporting document ' + `${file.name}`;
                rfiHistory.transactionId = this.transaction.id;
                rfiHistory.status = this.rfi.status;
                rfiHistory.rfiId = this.rfi.id;
                rfiHistory.performedBy = this.authService.getProfile().userId;
                this.rfiService.saveRFIHistory(rfiHistory).subscribe((explanation: RfiHistory) => {
                    const supportingDocument = new SupportingDocument();
                    supportingDocument.documentId = uploadResp.id;
                    supportingDocument.memberId = this.rfi.memberId;
                    supportingDocument.lineItemId = this.documentUploadContext;
                    supportingDocument.rfiHistoryId = explanation.id;
                    this.paymentService.saveTransactionSupportingDocument(this.rfi.transactionId, supportingDocument).subscribe(() => {
                        if (!this.authService.isAuthority()) {
                            const rfiNotification: RfiNotification = {
                                rfiId: rfiHistory.rfiId,
                                type: RfiNotificationType.DOCUMENT,
                                authority: false
                            };
                            this.workflowService.notifyRfiUpdate(rfiNotification).subscribe(() => {
                                this.documentUploadContext = null;
                                overlayRef.dispose();
                                this.transactionUpdated.emit();
                            });
                        } else {
                            this.documentUploadContext = null;
                            overlayRef.dispose();
                            this.transactionUpdated.emit();
                        }
                    });
                });
            }, () => {
                overlayRef.dispose();
                this.notificationService.showError('Document upload was unsuccessful. Please check your connection and try again.');
            });
            event.target.value = '';
        }
    }

    isAuthorityOrAdmin() {
        return this.authService.isAuthorityOrAdmin();
    }

    isAuthority() {
        return this.authService.isAuthority() || this.authService.isAuthoritySuperUser();
    }

    isAdmin() {
        return this.authService.isAdmin();
    }

    isAccountingEntity() {
        this.memberAccountService.loadMemberAccount(this.transaction.originatorAccountId).subscribe((memberAccount: MemberAccount) => {
            this.memberAccount = memberAccount;
            if (memberAccount.status !== MemberAccountStatus.ACTIVE) {
                return;
            }
            this.accountingService.getAccountingSystemInfo(memberAccount.memberId).subscribe((accountingSystem: AccountingLinkedMember) => {
                this.accountingLinked = accountingSystem?.linked;
                if (this.accountingLinked) {
                    this.businessClientAccountId = this.transactionSubType === TransactionSubType.DEPOSIT ? this.transaction.payorAccountId : this.transaction.recipientAccountId;
                    this.merchantAccountService.getAllMerchantAccountsByMerchantMemberAccountIdAndMemberId(this.businessClientAccountId, this.transaction.originatorId).subscribe((merchantAccounts : PagedResponse<MerchantAccount>) => {
                        this.accountingExternalIds = merchantAccounts.content[0]?.accountingExternalIds;
                        this.merchantAccount = merchantAccounts.content[0];
                        if (!this.accountingExternalIds?.length) {
                            return;
                        }
                        switch (this.transactionSubType) {
                            case TransactionSubType.PAYMENT:
                                // filter SUPPLIERS
                                this.accountingExternalIds = this.accountingExternalIds.filter((externalId) => {
                                    const isSupplier = externalId.accountingPartyType === AccountingPartyType.SUPPLIER;
                                    if (isSupplier) {
                                        this.accountingMerchant = true;
                                    }
                                    return isSupplier;
                                });
                                break;
                            case TransactionSubType.DEPOSIT:
                                // filter CUSTOMERS
                                this.accountingExternalIds = this.accountingExternalIds.filter((externalId) => {
                                    const isCustomer = externalId.accountingPartyType === AccountingPartyType.CUSTOMER;
                                    if (isCustomer) {
                                        this.accountingMerchant = true;
                                    }
                                    return isCustomer;
                                });
                                break;
                            default:
                                this.accountingMerchant = false;
                        }
                    });
                }
            });
        });
    }

    isRfiValid() {
        return this.rfi.status === RfiStatus.AUTHORITY_REVIEW;
    }

    linkAccountingMerchant() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'full-modal';
        dialogConfig.data  = {
            memberId: this.transaction.originatorId,
            accountingLinked: true,
            rfi: this.rfi
        };
        const dialog = this.dialog.open(LinkAccountingMerchantModalComponent, dialogConfig);
        dialog.afterClosed().subscribe((refresh: boolean) => {
            if (refresh) {
                this.transactionUpdated.emit();
            }
        });
    }

    editLineItems() {
        this.memberAccountService.loadMemberAccount(this.businessClientAccountId).subscribe((businessClientAccount: MemberAccount) => {
            this.businessClientAccount = businessClientAccount;
            this.businessClient = this.businessClientAccount.member;
            const dialogConfig: MatDialogConfig = {};
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'full-modal';
            dialogConfig.data  = {
                accountingExternalIds: this.accountingExternalIds,
                businessClient: this.businessClient,
                businessClientAccount: this.businessClientAccount,
                memberAccount: this.memberAccount,
                accountingLinked: true,
                businessClientAccountId: this.businessClientAccountId,
                rfi: this.rfi
            };
            const dialog = this.dialog.open(UpdateTransactionLineItemsComponent, dialogConfig);
            dialog.afterClosed().subscribe(() => {
                this.transactionUpdated.emit();
            });
        });
    }
}
