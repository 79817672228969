import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
    AccountingPartyType, Address, BusinessClient, BusinessClientStatus, CorporateEntity, ExternalAccountingId, Member, MemberSource, MerchantAccount, MerchantAccountConfig, MerchantAccountService,
    MerchantAccountSource, MerchantAchInfo, MerchantFormService, PagedResponse, SupportedTransactionType, TransactionType, Utils, WorkflowService
} from 'projects/services/src/public-api';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent, ErrorType } from 'projects/components/src/public-api';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
    selector: 'pt-add-accounting-merchant-modal',
    templateUrl: './add-accounting-merchant-modal.component.html',
    styleUrls: ['./add-accounting-merchant-modal.component.scss']
})
export class AddAccountingMerchantModalComponent extends BaseModalComponent<AddAccountingMerchantModalComponent> implements OnInit {

    TransactionType = TransactionType;
    SupportedTransactionType = SupportedTransactionType;
    AccountingPartyType = AccountingPartyType;

    memberId: string;
    businessClient: BusinessClient<any>;
    merchantForm: UntypedFormGroup;
    merchantMemberAccountForm: UntypedFormGroup;
    newMerchant = false;
    showAchInfo = false;
    existingAccount: MerchantAccount;
    merchantAccountExistsError: { message: string, type: ErrorType };
    file: File;
    isAccountingLinked = false;
    verifyBusinessClient = false;

    constructor(private merchantFormService: MerchantFormService,
                private merchantAccountService: MerchantAccountService,
                private workflowService: WorkflowService,
                private notifier: NotificationService,
                dialogRef: MatDialogRef<AddAccountingMerchantModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.memberId = data.memberId;
        this.isAccountingLinked = data.accountingLinked;
    }

    ngOnInit() {
        this.isMerchantFormValid = this.isMerchantFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.verifyMerchantLinkage = this.verifyMerchantLinkage.bind(this);
        this.merchantForm = this.merchantFormService.initializeNewMerchantForm();
        this.merchantMemberAccountForm = this.merchantFormService.initializeNewMerchantMemberAccountForm(false);
    }

    onSubmit(reset: any) {
        const merchantMember = new Member();
        if (this.newMerchant) {
            merchantMember.name = this.merchantForm.controls['merchantCtrl'].value.name;
            merchantMember.briefDescription = this.merchantMemberAccountForm.controls['briefDescriptionCtrl'].value;
            merchantMember.source = MemberSource.ACCOUNTING;
            const orgAddress = this.merchantFormService.getAddress(this.merchantMemberAccountForm);
            const merchantAccountConfig = new MerchantAccountConfig();
            // When authority added new merchant in members account section which is public
            merchantAccountConfig.shared = false;
            merchantAccountConfig.merchantAccountType = this.merchantForm.controls['allowedTransactionsCtrl'].value;
            merchantAccountConfig.source = MerchantAccountSource.ACCOUNTING;
            const merchantAccount = new MerchantAccount();
            merchantAccount.accountingExternalIds = [{
                accountingExternalId: this.businessClient.accountingData.externalId,
                accountingPartyType: this.businessClient.accountingData.type
            }];
            const merchantAchInfo = new MerchantAchInfo();
            merchantAccount.merchantAccountConfig = merchantAccountConfig;
            merchantAchInfo.accountHolder = this.merchantForm.controls['achAccountHolderCtrl'].value;
            merchantAchInfo.accountNumber = this.merchantForm.controls['achAccountNumberCtrl'].value;
            merchantAchInfo.routingNumber = this.merchantForm.controls['achRoutingNumberCtrl'].value;
            merchantAchInfo.wireRoutingNumber = this.merchantForm.controls['wireRoutingNumberCtrl'].value;
            const licenseHolder = this.merchantMemberAccountForm.controls['licenseHolderCtrl'].value || '';
            const contactInfo = this.merchantFormService.getContactInfo(this.merchantMemberAccountForm);
            const registerBusinessMerchant = {
                contactInfo,
                merchantAddress: orgAddress,
                merchantMember,
                merchantAccount,
                merchantAchInfo,
                licenseHolder
            };

            this.workflowService.registerBusinessMerchant(registerBusinessMerchant, this.file).subscribe((_savedMerchantAccountConfig: MerchantAccountConfig) => {
                this.close(true);
                this.notifier.showSuccess(`Your request to add ${registerBusinessMerchant.merchantMember.name} as an accounting merchant has been submitted.`);
            }, (error: any) => {
                reset();
                if (error.error instanceof ProgressEvent && error.error.loaded === 0 && error.error.type === 'error') {
                    // Client-side error
                    this.notifier.showError('Document upload was unsuccessful. Please check your connection and try again.');
                } else {
                    // Server-side error
                    throw error;
                }
            });
        }
    }

    isMerchantFormValid() {
        return this.merchantForm && this.merchantForm.valid &&
            (!this.newMerchant || (this.newMerchant && this.merchantMemberAccountForm.valid))
            && !this.merchantAccountExistsError;
    }

    close(refresh?: boolean) {
        super.close(refresh);
    }

    updateBankAccountValidity(_event: any) {
        this.merchantFormService.validateAndUpdateMerchantForm(this.merchantForm);
    }

    setAccountingBusinessClient(businessClient: BusinessClient<any>) {
        this.businessClient = businessClient;
        this.verifyBusinessClient = false;
        if (!this.businessClient) {
            this.existingAccount = null;
            this.merchantAccountExistsError = null;
            this.showAchInfo = false;
            this.newMerchant = false;
            this.verifyBusinessClient = false;
            this.merchantForm.patchValue({
                merchantIdCtrl: '',
                allowedTransactionsCtrl: SupportedTransactionType.BOTH,
                achAccountHolderCtrl: '',
                achAccountNumberCtrl: '',
                achRoutingNumberCtrl: '',
                wireRoutingNumberCtrl: ''
            }, {emitEvent: false});
            this.merchantMemberAccountForm.reset();
            this.merchantMemberAccountForm.enable();
            this.merchantMemberAccountForm.controls['countryCtrl'].setValue('United States');
        } else {
            this.newMerchant = this.businessClient.memberAccountId === null;
            this.merchantAccountExistsError = null;
            this.merchantForm.controls['merchantCtrl'].setValue(this.businessClient);
            this.merchantForm.controls['merchantNameCtrl'].setValue(this.businessClient.name);

            if (this.newMerchant) {
                this.existingAccount = null;
                this.showAchInfo = true;
                const accountingData = this.businessClient.accountingData;

                this.merchantMemberAccountForm = this.merchantFormService.initializeNewMerchantMemberAccountForm(false, accountingData);
                this.merchantForm.controls['merchantNameCtrl'].disable();
                this.merchantForm.controls['allowedTransactionsCtrl'].setValue(accountingData.type === AccountingPartyType.SUPPLIER ? SupportedTransactionType.OUTGOING : SupportedTransactionType.INCOMING);
                this.merchantMemberAccountForm.enable();
            } else {
                if (this.businessClient.status === BusinessClientStatus.VERIFICATION_REQUIRED || this.businessClient.status === BusinessClientStatus.PENDING_VERIFICATION_REQUIRED) {
                    this.verifyBusinessClient = true;
                    this.merchantForm.controls['merchantNameCtrl'].disable();
                } else {
                    this.merchantAccountService.getAllMerchantAccountsByMerchantMemberAccountIdAndMemberId(this.businessClient.memberAccountId, this.memberId).subscribe((merchantAccounts: PagedResponse<MerchantAccount>) => {
                        this.merchantForm.controls['merchantNameCtrl'].enable();

                        this.existingAccount = merchantAccounts.content[0];
                        this.merchantForm.controls['merchantIdCtrl'].setValue(this.businessClient.memberAccountId);
                        this.showAchInfo = true;
                        this.merchantForm.controls['allowedTransactionsCtrl'].setValue(this.existingAccount.merchantAccountConfig.merchantAccountType);
                        this.merchantAccountService.getMerchantAccountConfigAddress(this.existingAccount.merchantAccountConfig.id).pipe(
                            catchError((error) => {
                                // Handle the error as there will no address for merchants connected from RFI page.
                                return of({
                                    streetAddressOne: '',
                                    city: '',
                                    stateProvince: '',
                                    country: '',
                                    zipPostalCode: ''
                                });
                            })
                        ).subscribe((address: Address) => {
                            this.merchantMemberAccountForm.controls['streetAddressOneCtrl'].setValue(address.streetAddressOne);
                            this.merchantMemberAccountForm.controls['cityCtrl'].setValue(address.city);
                            this.merchantMemberAccountForm.controls['stateProvinceCtrl'].setValue(address.stateProvince);
                            this.merchantMemberAccountForm.controls['countryCtrl'].setValue(address.country);
                            this.merchantMemberAccountForm.controls['zipPostalCodeCtrl'].setValue(address.zipPostalCode);
                            this.merchantMemberAccountForm.disable();
                        });
                        this.merchantAccountExistsError = {
                            message: 'This accounting party has already been registered.',
                            type: ErrorType.ERROR
                        };
                    });
                    this.merchantMemberAccountForm.disable();
                }
            }
        }
    }

    selectFile(event: any) {
        if (event.target.files && event.target.files.length) {
            for (const file of event.target.files) {
                Utils.validateFile(file, event);
            }
            this.file = event.target.files[0];
        }
        event.target.value = '';
    }

    deleteDocument() {
        this.file = null;
    }

    getAccountingIds(accountingPartyType: AccountingPartyType): ExternalAccountingId[] {
        if (!this.existingAccount.accountingExternalIds.length) {
            return [];
        } else {
            return this.existingAccount.accountingExternalIds.filter((accountExternalId: ExternalAccountingId) => {
                return accountExternalId.accountingPartyType === accountingPartyType;
            });
        }
    }

    verifyMerchantLinkage(reset: any) {
        const merchantAccountId = this.businessClient.merchantData.externalId;
        this.merchantAccountService.getMerchantAccountById(merchantAccountId).subscribe((merchantAccount: MerchantAccount) => {
            const accountingIds = merchantAccount.accountingExternalIds;
            accountingIds.push(new ExternalAccountingId(this.businessClient.accountingData.externalId, this.businessClient.accountingData.type));
            this.merchantAccountService.updateMerchantAccount(merchantAccountId, {accountingExternalIds: accountingIds}).subscribe(() => {
                this.verifyBusinessClient = false;
                this.notifier.showSuccessCloseRequired(`Accounting party ${this.businessClient.name} has been linked to ${merchantAccount.merchantMemberAccount.member.name}.`);
                reset();
                this.close(true);
            }, (error: any) => {
                reset();
                throw error;
            });
        });
    }

    createNewMerchant() {
        this.merchantForm.controls['merchantCtrl'].setValue(this.businessClient);
        this.merchantForm.controls['merchantNameCtrl'].setValue(this.businessClient.name);
        this.merchantForm.controls['merchantNameCtrl'].disable();
        this.merchantMemberAccountForm = this.merchantFormService.initializeNewMerchantMemberAccountForm(false, this.businessClient.accountingData);
        this.newMerchant = true;
        this.showAchInfo = true;
        this.verifyBusinessClient = false;
    }

    onCancel() {
        this.merchantForm.controls['merchantCtrl'].setValue(null);
        this.merchantForm.controls['merchantNameCtrl'].setValue(null);
        this.existingAccount = null;
        this.businessClient = null;
        this.merchantAccountExistsError = null;
        this.showAchInfo = false;
        this.newMerchant = false;
        this.verifyBusinessClient = false;
        this.merchantForm.reset();
        this.merchantMemberAccountForm.reset();
        this.merchantMemberAccountForm.enable();
        this.merchantForm.controls['merchantNameCtrl'].enable();
    }

    getDbaName(member: Member) {
        return (member as CorporateEntity).dbaName;
    }
}
