import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BankAccount, AuthService, BankAccountStatus, BankAccountService, PaymentService, PagedResponse, AccountToken } from 'projects/services/src/public-api';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { YodleeFastlinkModalComponent } from 'projects/pt/src/app/components';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pt-bank-accounts',
    templateUrl: './bank-accounts.component.html'
})
export class BankAccountsComponent implements OnInit, AfterViewInit, OnDestroy {

    BankAccountStatus = BankAccountStatus;

    filter: string;
    pageTracking: PageTracking;
    subscription: any;
    resultsLength = 0;
    isLoadingResults = true;
    accounts: BankAccount[] = [];

    displayedColumns: string[] = ['type', 'account_number', 'ach_routing_number', 'wire_routing_number', 'status', 'name', 'holder', 'date_added', 'financial_institution'];
    statuses: BankAccountStatus[] = [BankAccountStatus.ACTIVE, BankAccountStatus.VALIDATION_REQUIRED, BankAccountStatus.DISABLED, BankAccountStatus.BANK_VALIDATION_REQUIRED, BankAccountStatus.USER_VALIDATION_REQUIRED];

    refreshEvent: EventEmitter<null> = new EventEmitter<null>();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(public authService: AuthService,
                private bankAccountService: BankAccountService,
                private paymentService: PaymentService,
                private route: ActivatedRoute,
                private router: Router,
                private cdr: ChangeDetectorRef,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        this.refreshList = this.refreshList.bind(this);
        this.loadDisplayedColumns();
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'created', 'desc');
    }

    loadDisplayedColumns() {
        if (this.authService.isAuthority()) {
            this.displayedColumns = ['type', 'account_number', 'ach_routing_number', 'wire_routing_number', 'status', 'name', 'holder', 'source', 'date_added', 'financial_institution', 'action'];
        } else if (this.isAdmin()) {
            this.displayedColumns = ['type', 'account_number', 'ach_routing_number', 'wire_routing_number', 'status', 'name', 'holder', 'date_added', 'financial_institution', 'action'];
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.pageIndex = 0;
        });
        this.subscription = merge(this.paginator.page, this.refreshEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                return this.bankAccountService.getBankAccounts(this.authService.getProfile().memberId, this.statuses, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<BankAccount>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((bankAccounts: BankAccount[]) => {
            const results: BankAccount[] = [];
            for (const bankAccount of bankAccounts) {
                this.loadAccount(bankAccount);
                results.push(bankAccount);
            }
            this.accounts = results;
            UIUtils.scrollDashboardToTop();
        });
    }

    loadAccount(bankAccount: BankAccount) {
        if (bankAccount.status === BankAccountStatus.BANK_VALIDATION_REQUIRED || bankAccount.status === BankAccountStatus.USER_VALIDATION_REQUIRED || bankAccount.status === BankAccountStatus.VALIDATION_REQUIRED) {
            bankAccount.status = BankAccountStatus.PENDING;
        }
        if (bankAccount.externalAccountId !== null) {
            // load payee address
            this.paymentService.getExternalAccountDetails(bankAccount.externalAccountId).subscribe((response: any) => {
                bankAccount.externalAccountDetails = response;
            });
        }
    }

    getAccountNumber(accountNumber: string) {
        return '*****' + accountNumber.substring(accountNumber.length - 4);
    }

    getRoutingNumber(routingNumber: string) {
        return '*****' + routingNumber.substring(routingNumber.length - 4);
    }

    refreshList() {
        this.refreshEvent.emit();
    }

    getAccountsYodlee() {
        this.paymentService.getAccessToken(this.authService.getProfile().memberId).subscribe((response: AccountToken) => {
            this.onYodleeFastLink(response);
        });
    }

    onYodleeFastLink(accountToken: AccountToken) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            accountToken,
            callback: this.refreshList
        };
        this.dialog.open(YodleeFastlinkModalComponent, dialogConfig);
    }

    isEnabled(account: BankAccount) {
        return account && (account.status === BankAccountStatus.ACTIVE || account.status === BankAccountStatus.PENDING);
    }

    isAdmin() {
        return this.authService.isAdmin();
    }

    toggleEnabled(event: any, account: BankAccount) {
        if (this.isEnabled(account)) {
            this.bankAccountService.updateBankAccount(account.id, {status : BankAccountStatus.DISABLED}).subscribe(() => {
                this.refreshList();
            });
        } else {
            this.bankAccountService.updateBankAccount(account.id, {status : BankAccountStatus.ACTIVE}).subscribe(() => {
                this.refreshList();
            });
        }
        event.cancelBubble = true;
        if (event.stopImmediatePropagation) {
            event.stopImmediatePropagation();
        }
    }
}
