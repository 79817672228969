import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService, Configuration, MemberFunction, NavigationLinks } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-member-sidebar',
    templateUrl: './member-sidebar.component.html'
})
export class MemberSidebarComponent implements OnInit {

    @Input() expandedMenu: boolean;
    @Input() readOnly = false;

    @Output() toggleExpandSideMenu: EventEmitter<any> = new EventEmitter<any>();

    type: string;
    menuActions: any[] = [];

    constructor(private authService: AuthService) {}

    ngOnInit() {
        this.menuActions.push(NavigationLinks.dashboardLink);
        this.menuActions.push(NavigationLinks.memberAccountsLink);
        // check if PARTNER
        if (this.authService.hasMemberFunction(MemberFunction.PARTNER)) {
            this.menuActions.push(NavigationLinks.connectedMemberAdministrationLink);
        }
        if (Configuration.getConfig().smartContractsEnabled) {
            this.menuActions.push(NavigationLinks.smartContractsLink);
        }
        this.menuActions.push(NavigationLinks.transactionLink);
        this.menuActions.push(NavigationLinks.rfiLink);
        this.menuActions.push(NavigationLinks.reportsLink);
        if (!this.readOnly) {
            this.menuActions.push(NavigationLinks.settingsLink);
        }
        this.menuActions.push(NavigationLinks.supportLink);
    }

    getMenuClass() {
        if (this.expandedMenu) {
            return 'sidenav-wide';
        } else {
            return 'sidenav-narrow';
        }
    }

    onToggleExpandSideMenu() {
        this.toggleExpandSideMenu.emit('toggle');
    }
}
