<div>
    <form *ngIf="linkKeyPartyForm" [formGroup]="linkKeyPartyForm">
        <div>
            <div class="modal-header">
                <label class="form-title">Link Key Party to {{user.name}}</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content-medium">
                <div class="info-message mb-5">
                    <p>
                        Please select the key party to link to <strong>{{user.name}}</strong> <span *ngIf="unlinkAvailable">, or Unlink to remove the link to the key party</span>
                    </p>
                    <p *ngIf="!unlinkAvailable">
                        NOTE: you may only unlink a key party from a user if the user does not have administrative access to any account(s).
                    </p>
                </div>
                <pc-form-selector [label]="'Select Key Party'" [formCtrl]="'keyPartyIdCtrl'" [formGroup]="linkKeyPartyForm" [options]="availableKeyParties"
                    [idValue]="'id'" [displayValue]="'individualKeyParty.name'" (selected)="onSelectKeyParty($event)">
                </pc-form-selector>
                <pc-error-message [width]="'full'" [error]="errorMessage"></pc-error-message>
            </div>
            <div class="modal-footer">
                <pc-submit-button [enabled]="isLinkKeyPartyFormValid" [submit]="onSubmit" [text]="(!selectedKeyParty && errorMessage) ? 'Unlink User' : 'Link User'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
