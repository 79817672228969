import { Component, Inject, OnInit } from '@angular/core';
import { BaseModalComponent } from 'projects/components/src/lib/modal';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Member, MemberAccount, MemberAccountStatus, MemberAccountType, MemberStatus, MemberType, MerchantAccountSource, SupportedTransactionType } from 'projects/services/src/public-api';
import { ErrorType } from 'projects/components/src/lib/error-message';

@Component({
    selector: 'pc-merchant-selector-modal',
    templateUrl: './external-check-involved-party-selector.component.html'
})
export class ExternalCheckInvolvedPartySelectorComponent extends BaseModalComponent<ExternalCheckInvolvedPartySelectorComponent> implements OnInit {

    userForm : UntypedFormGroup;
    isMember : Boolean;
    memberTypes : MemberType [] = [MemberType.BUSINESS];
    merchantAccountSources : MerchantAccountSource [] = [MerchantAccountSource.CONFIA, MerchantAccountSource.ACCOUNTING, MerchantAccountSource.EXTERNAL_TRANSACTION];
    selectedMemberAccount : MemberAccount;
    MerchantAccountSource = MerchantAccountSource;
    SupportedTransactionType = SupportedTransactionType;

    // enums
    MemberAccountType = MemberAccountType;
    MemberType = MemberType;
    MemberStatus = MemberStatus;
    MemberAccountStatus = MemberAccountStatus;
    selectedParty : any;
    readonly MEMBER_WARN = {
        message: 'The selected member cannot be edited later.',
        type: ErrorType.WARN
    };

    constructor(dialogRef: MatDialogRef<ExternalCheckInvolvedPartySelectorComponent>,
                private formBuilder: UntypedFormBuilder,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.isMember = data.isMember;
    }

    ngOnInit(): void {
        this.userForm = this.formBuilder.group({
            selectedUserCtrl: new UntypedFormControl('')
        });
    }

    merchantSelected(memberAccount?: MemberAccount) {
        this.selectedParty = {
            selectedMerchantMemberAccount : memberAccount
        };
        this.close(this.selectedParty);
    }

    memberAccountSelected(memberAccount? : MemberAccount) {
        this.selectedMemberAccount = memberAccount;
    }

    close(data?: any) {
        super.close(data);
    }

    closeMemberDialog() {
        this.selectedParty = {
            selectedMemberAccount : this.selectedMemberAccount
        };
        this.close(this.selectedParty);
    }

    handleSubmit(reset: any) {
        setTimeout(() => {
            reset();
        }, 500);
    }
}