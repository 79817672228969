import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import {
    AuthService, Bill, BusinessClient, BusinessClientStatus, Configuration, Invoice, MerchantAccountConfig, MerchantAccountService, MemberSource,
    MemberType, SupportedTransactionType, TransactionSubType, TransactionType, CorporateEntity, MemberAccountService, MemberAccount, Transaction
} from 'projects/services/src/public-api';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pc-business-client-selector',
    templateUrl: './business-client-selector.component.html',
    styleUrls: ['./business-client-selector.component.scss']
})

export class BusinessClientSelectorComponent implements OnInit, AfterViewInit, OnChanges {

    BusinessClientStatus = BusinessClientStatus;
    MemberType = MemberType;

    @Input() placeholder = '';
    @Input() label: string;
    @Input() formCtrl: string;
    @Input() merchantNameFormCtrl: string;
    @Input() formGroup: UntypedFormGroup;
    @Input() accountingLinked = false;
    @Input() subType: TransactionSubType;
    @Input() includeConfiaMerchants = true;
    @Input() clientContextTrigger = 0;
    @Input() linkAccountingMerchantFromRfi = false;

    @Output() businessClientSelected = new EventEmitter<BusinessClient<Invoice | Bill>>();
    @Output() closeTransactionModal: EventEmitter<boolean> = new EventEmitter<boolean>();

    memberId: string;
    supportedTransactionTypes: SupportedTransactionType[] = [];
    businessClientAutoComplete: Observable<BusinessClient<Invoice | Bill>[]> = null;
    autoCompleteControl = new UntypedFormControl('');
    businessClientOptions: BusinessClient<Invoice | Bill>[] = [];
    searching = false;
    emptyResults = false;
    businessClientSelect: Subscription;
    searchLimit: number;
    totalResults: number;


    @ViewChild('businessClientSelect', { read: MatAutocompleteTrigger }) trigger: MatAutocompleteTrigger;

    constructor(private authService: AuthService,
                private merchantAccountService: MerchantAccountService,
                private memberAccountService: MemberAccountService,
                private notifier: NotificationService) {}

    ngOnInit() {
        this.searchLimit = Configuration.getConfig().autoCompleteSearchLimit;
        if (this.includeConfiaMerchants || this.linkAccountingMerchantFromRfi) {
            if (this.subType === TransactionSubType.PAYMENT) {
                this.supportedTransactionTypes = [SupportedTransactionType.OUTGOING, SupportedTransactionType.BOTH];
            } else {
                this.supportedTransactionTypes = [SupportedTransactionType.INCOMING, SupportedTransactionType.BOTH];
            }
        } else {
            this.supportedTransactionTypes = [SupportedTransactionType.INCOMING, SupportedTransactionType.OUTGOING, SupportedTransactionType.BOTH];
        }
        this.memberId = this.authService.getProfile().memberId;
        this.formGroup.addControl('autoCompleteCtrl', this.autoCompleteControl);
        this.businessClientAutoComplete = this.autoCompleteControl.valueChanges.pipe(
            startWith(''),
            distinctUntilChanged(),
            debounceTime(1000),
            switchMap((value) => {
                this.searching = true;
                this.businessClientOptions = [];
                return this.lookup(value || '');
            })
        );
        this.loadMemberAccount();
        this.formGroup.controls[this.formCtrl].valueChanges.subscribe((_value) => {
            this.loadMemberAccount();
        });
    }

    ngAfterViewInit() {
        this.subscribeToClosingActions();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.clientContextTrigger && !changes.clientContextTrigger.firstChange) {
            this.autoCompleteControl.enable();
            this.autoCompleteControl.markAsUntouched();
        }
    }

    private subscribeToClosingActions() {
        if (this.businessClientSelect && !this.businessClientSelect.closed) {
            this.businessClientSelect.unsubscribe();
        }

        this.businessClientSelect = this.trigger.panelClosingActions.subscribe((e) => {
            if (!e || !e.source) {
                if (this.businessClientOptions.length === 1) {
                    const option = this.businessClientOptions[0];
                    if (option.status === BusinessClientStatus.DISABLED || option.status === BusinessClientStatus.NEW) {
                        this.formGroup.controls[this.formCtrl].markAsPristine();
                        this.formGroup.controls[this.formCtrl].setValue(null);
                        this.formGroup.controls[this.merchantNameFormCtrl].setValue('');
                        this.autoCompleteControl.setValue('');
                        this.businessClientChanged(null);
                    } else {
                        this.businessClientChanged(this.businessClientOptions[0]);
                    }
                } else if (this.businessClientOptions.length > 1) {
                    const selected = this.businessClientOptions
                        .map((option) => {
                            return option.name;
                        })
                        .find((option) => {
                            return option === this.autoCompleteControl.value;
                        });

                    if (!selected && this.selected()) {
                        this.formGroup.controls[this.formCtrl].markAsPristine();
                        this.formGroup.controls[this.formCtrl].setValue(null);
                        this.formGroup.controls[this.merchantNameFormCtrl].setValue('');
                        this.autoCompleteControl.setValue('');
                        this.businessClientChanged(null);
                    }
                }
            }
        }, () => {
            return this.subscribeToClosingActions();
        }, () => {
            return this.subscribeToClosingActions();
        });
    }

    lookup(value: string) : Observable<BusinessClient<Invoice | Bill>[]> {
        this.totalResults = 0;
        if (value?.length <= 1 || this.autoCompleteControl.disabled) {
            this.searching = false;
            return of([]);
        }
        this.formGroup.controls['autoCompleteCtrl'].disable();
        this.emptyResults = false;
        return this.merchantAccountService.findBusinessClients(value, this.memberId, this.supportedTransactionTypes, this.includeConfiaMerchants, this.searchLimit).pipe(
            switchMap(
                (response: {error: string, totalElements: number, businessClients: BusinessClient<any>[]}) => {
                    this.totalResults = response.totalElements;
                    this.businessClientOptions = response.businessClients;
                    if (this.businessClientOptions.length === 0) {
                        this.emptyResults = true;
                        this.businessClientSelected.emit(null);
                    }
                    if (this.includeConfiaMerchants) {
                        this.businessClientOptions.unshift(new BusinessClient(null, null, '+ Create New Merchant', null, BusinessClientStatus.NEW, MemberSource.CONFIA, MemberType.BUSINESS_MERCHANT, TransactionType.ALL));
                    }
                    if (response.error) {
                        this.notifier.showError(response.error);
                    }
                    this.searching = false;
                    this.formGroup.controls['autoCompleteCtrl'].enable();
                    return of(this.businessClientOptions);
                }
            ), catchError((_error) => {
                this.formGroup.controls['autoCompleteCtrl'].enable();
                this.searching = false;
                return [];
            })
        );
    }

    businessClientChanged(businessClient: BusinessClient<Invoice | Bill>) {
        this.formGroup.controls[this.formCtrl].markAsDirty();
        this.formGroup.controls[this.merchantNameFormCtrl].markAsDirty();
        if (businessClient) {
            this.formGroup.controls[this.formCtrl].setValue(businessClient.status !== BusinessClientStatus.NEW ? businessClient : null);
            this.formGroup.controls[this.merchantNameFormCtrl].setValue(businessClient.status !== BusinessClientStatus.NEW ? businessClient.name : '');
            this.autoCompleteControl.setValue(businessClient.name);
            this.autoCompleteControl.disable();
            this.businessClientSelected.emit(businessClient);
        } else {
            this.formGroup.controls[this.formCtrl].setValue(null);
            this.formGroup.controls[this.merchantNameFormCtrl].setValue('');
            this.autoCompleteControl.setValue('');
            this.businessClientSelected.emit(null);
        }
    }

    onInputChanged(event: any) {
        if (event.keyCode === 220) {
            event.preventDefault();
            return;
        }
    }

    selected() {
        return this.formGroup.controls[this.formCtrl].value;
    }

    loadMemberAccount() {
        const businessClient: BusinessClient<Invoice | Bill> = this.formGroup.controls[this.formCtrl].value;
        if (businessClient && businessClient.memberAccountId) {
            this.memberAccountService.loadMemberAccount(businessClient.memberAccountId).subscribe((memberAccount: MemberAccount) => {
                this.merchantAccountService.getMerchantAccountConfigByMerchantMemberId(memberAccount.memberId).subscribe((merchantAccountConfig: MerchantAccountConfig) => {
                    if (!merchantAccountConfig) {
                        this.closeTransactionModal.emit(true);
                        throw new Error(`The counter party you have selected has been modified or renamed. Please try re-selecting the correct counter party and try your ${ this.subType === TransactionSubType.PAYMENT ? 'payment' : 'deposit' } again.`);
                    }
                    const merchantMember = (merchantAccountConfig.merchantMemberAccount.member as CorporateEntity);
                    this.autoCompleteControl.setValue(merchantMember.name, {emitEvent: false});
                    this.autoCompleteControl.disable();
                    this.autoCompleteControl.markAsUntouched();
                    if (businessClient.source === MemberSource.ACCOUNTING) {
                        if (merchantMember.name && businessClient.name !== merchantMember.name && merchantMember.memberType !== MemberType.CONSUMER) {
                            merchantMember.name = businessClient.name + ' (DBA: ' + merchantMember.name + ')';
                        } else {
                            merchantMember.name = businessClient.name;
                        }
                    } else if (merchantMember.dbaName && merchantMember.name !== merchantMember.dbaName && merchantMember.memberType !== MemberType.CONSUMER) {
                        merchantMember.name = merchantMember.name + ' (DBA: ' + merchantMember.dbaName + ')';
                    }
                    this.formGroup.controls[this.merchantNameFormCtrl].setValue(merchantMember.name);
                });
            });
        } else if (businessClient) {
            this.autoCompleteControl.setValue(businessClient.name, {emitEvent: false});
        } else {
            this.autoCompleteControl.setValue('');
            this.autoCompleteControl.enable();
        }
    }

    onIconClick(event: any) {
        if (this.selected()) {
            this.searching = true;
            this.autoCompleteControl.enable();
            this.businessClientOptions = [];
            this.formGroup.controls[this.formCtrl].setValue(null);
            this.formGroup.controls[this.formCtrl].enable();
            this.formGroup.controls[this.merchantNameFormCtrl].setValue('');
            this.formGroup.controls[this.merchantNameFormCtrl].enable();
            this.autoCompleteControl.setValue('');
            this.businessClientSelected.emit(null);
        }
        event.stopPropagation();
        this.trigger.openPanel();
    }

    getBusinessClientName(businessClient: BusinessClient<Invoice | Bill>) {
        if (businessClient.dbaName && businessClient.dbaName.toLowerCase() !== businessClient.name.toLowerCase()) {
            return businessClient.name + (' (DBA: ' + businessClient.dbaName + ')');
        }
        return businessClient.name;
    }
}
