import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';
import { IMyDateModel } from 'angular-mydatepicker';
import { AddressOccupant, AddressService, AddressStatus, AuthService, CustomerInfo, Member, MemberAccount, MemberAccountService, MemberAccountStatus,
    MemberAccountType, MemberFunction, MemberService, Configuration, MemberStatus, MemberType, PagedResponse, PaymentService, ReportsService, Role, TransactionSource,
    TransactionState, TransactionTableFilter, TransactionTableTypeFilter, TransactionType, TransactionUtils, Wallet, WalletFunction, WalletService, WorkflowService, PaymentRail,
    TransactionTableReturnCodeFilter
} from 'projects/services/src/public-api';
import { AuthorityPaymentModalComponent, CurrencyWithdrawalModalComponent, DepositModalComponent, PaymentModalComponent, RetailPaymentModalComponent } from 'projects/pt/src/app/components';
import { TableUtils } from 'projects/components/src/lib/table-utils.service';
import { AccountSelectorService } from 'projects/components/src/lib/account-selector.service';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pt-transaction-table-header',
    templateUrl: './transaction-table-header.component.html',
    styleUrls: ['./transaction-table-header.component.scss']
})
export class TransactionTableHeaderComponent implements OnInit, OnChanges {

    MemberType = MemberType;
    MemberStatus = MemberStatus;
    Role = Role;
    TransactionTableTypeFilter = TransactionTableTypeFilter;
    TransactionTableReturnCodeFilter = TransactionTableReturnCodeFilter;
    TransactionState = TransactionState;
    MemberAccountType = MemberAccountType;
    MemberAccountStatus = MemberAccountStatus;

    @Input() memberId = '';
    @Input() readOnly = false;
    @Input() filter: TransactionTableFilter;
    @Input() isActivityListAvailable = false;
    @Input() memberAccount: MemberAccount;
    @Input() taskId: string;
    @Input() exportAccountName = true;
    @Input() userNotes: string;
    @Input() customerInfo: CustomerInfo;

    @Output() filterChanged: EventEmitter<TransactionTableFilter> = new EventEmitter<TransactionTableFilter>();
    @Output() transactionSubmitted: EventEmitter<void> = new EventEmitter<void>();

    retailLocations: AddressOccupant[] = [];
    member: Member;
    formGroup: UntypedFormGroup;
    enableAuthorityTransactions = false;
    isReturnCodeFilterEnabled = false;
    returnCodeFilterOptions = [];
    isAuthority = false;
    currencyWithdrawalEnabled = false;
    depositsEnabled = false;
    paymentsEnabled = false;
    consumerPaymentsEnabled = false;
    accountUnderReview = false;
    memberUnderReview = false;
    approveEnabled = false;
    submitting = false;
    lastParams = '';
    lastTab = '';

    /**
     * Toggle advanced filter UI
     */
    isMoreFilterToggled: boolean = false;

    @ViewChild('downloadReportLink') downloadReportLink: ElementRef;

    /**
     * Query param related to minimum filter field.
     * @private
     */
    private readonly MINIMUM_FILTER_PARAM = 'minAmount';

    /**
     * Query param related to maximum filter field.
     * @private
     */
    private readonly MAXIMUM_FILTER_PARAM = 'maxAmount';

    constructor(public authService: AuthService,
                private memberService: MemberService,
                private paymentService: PaymentService,
                private addressService: AddressService,
                private reportsService: ReportsService,
                private memberAccountService: MemberAccountService,
                private walletService: WalletService,
                private workflowService: WorkflowService,
                private notifier: NotificationService,
                private dialog: MatDialog,
                private route: ActivatedRoute,
                private router: Router,
                private datePipe: DatePipe,
                private accountSelectorService: AccountSelectorService,
                private formBuilder: UntypedFormBuilder) { }

    ngOnInit() {
        this.openPayment = this.openPayment.bind(this);
        this.openRetailPayment = this.openRetailPayment.bind(this);
        this.openDeposit = this.openDeposit.bind(this);
        this.openCurrencyWithdrawal = this.openCurrencyWithdrawal.bind(this);
        this.openAddFunds = this.openAddFunds.bind(this);
        this.openRemoveFunds = this.openRemoveFunds.bind(this);
        this.openChargePayment = this.openChargePayment.bind(this);
        this.openCreditPayment = this.openCreditPayment.bind(this);
        this.onApproveAccount = this.onApproveAccount.bind(this);
        this.onRejectAccount = this.onRejectAccount.bind(this);
        this.exportTransactionListing = this.exportTransactionListing.bind(this);
        this.exportAccountActivityList = this.exportAccountActivityList.bind(this);
        this.canApproveAccount = this.canApproveAccount.bind(this);
        this.formGroup =  this.formBuilder.group({
            memberCtrl : new UntypedFormControl(),
            dateCtrl : new UntypedFormControl(),
            minimumCtrl: new UntypedFormControl(),
            maximumCtrl: new UntypedFormControl(),
            returnCodeFilterFormCtrl: new UntypedFormControl()
        });
        this.isAuthority = this.authService.isAuthority();
        this.accountUnderReview = this.memberAccount && this.taskId && (this.memberAccount.status === MemberAccountStatus.UNDER_REVIEW || this.memberAccount.status === MemberAccountStatus.PENDING);
        if (this.accountUnderReview) {
            this.setApproveEnabled();
            this.memberUnderReview = (this.memberAccount.member.status === MemberStatus.APPLIED || this.memberAccount.member.status === MemberStatus.UNDER_REVIEW || this.memberAccount.member.status === MemberStatus.PENDING);
        }
        if (this.memberId) {
            this.memberService.loadMember(this.filter.memberId).subscribe((member: Member) => {
                this.member = member;
                if (this.isAuthority && member.status === MemberStatus.ACTIVE && this.memberAccount && this.memberAccount.accountType !== MemberAccountType.MERCHANT && !this.accountUnderReview) {
                    this.enableAuthorityTransactions = true;
                }
                this.memberUnderReview = (this.member.status === MemberStatus.APPLIED || this.member.status === MemberStatus.UNDER_REVIEW || this.member.status === MemberStatus.PENDING);

            });
        }
        if (this.memberAccount && !this.accountUnderReview) {
            this.isActivityListAvailable = this.memberAccountService.hasWalletFunction(this.memberAccount, WalletFunction.ACH_DEPOSIT);
            this.consumerPaymentsEnabled = this.memberAccountService.hasWalletFunction(this.memberAccount, WalletFunction.ACCEPT_CONSUMER_PAYMENTS) && this.memberAccount.status === MemberAccountStatus.ACTIVE;
            this.depositsEnabled = !this.memberAccountService.hasWalletFunction(this.memberAccount, WalletFunction.ACH_BALANCE_PUSH) && this.memberAccount.status === MemberAccountStatus.ACTIVE;
            this.paymentsEnabled = this.memberAccount.status === MemberAccountStatus.ACTIVE;
            this.currencyWithdrawalEnabled = this.memberAccountService.hasWalletFunction(this.memberAccount, WalletFunction.ENABLE_CURRENCY_WITHDRAWAL) && this.memberAccount.status === MemberAccountStatus.ACTIVE;
            this.isReturnCodeFilterEnabled = this.memberAccountService.hasWalletFunction(this.memberAccount, WalletFunction.ENABLE_PAYMENT_PROCESSING) && this.memberAccount.status === MemberAccountStatus.ACTIVE;
            this.setReturnCodeFilterOptions();
            this.setFilterTransactionType();
            if (this.consumerPaymentsEnabled) {
                this.updateRelatedLocations();
            }
        } else if (this.memberId) {
            this.isActivityListAvailable = false;
            this.memberAccountService.getActiveMemberBusinessAccounts(this.memberId).subscribe((result: PagedResponse<MemberAccount>) => {
                result.content.forEach((memberAccount: MemberAccount) => {
                    if (memberAccount.accountType === MemberAccountType.BUSINESS && memberAccount.status === MemberAccountStatus.ACTIVE
                        && this.memberAccountService.hasWalletFunction(memberAccount, WalletFunction.ACCEPT_CONSUMER_PAYMENTS)) {
                        this.consumerPaymentsEnabled = true;
                    }
                    if (memberAccount.accountType === MemberAccountType.BUSINESS && memberAccount.status === MemberAccountStatus.ACTIVE
                        && !this.memberAccountService.hasWalletFunction(memberAccount, WalletFunction.ACH_BALANCE_PUSH)) {
                        this.depositsEnabled = true;
                    }
                    if (memberAccount.accountType === MemberAccountType.BUSINESS && memberAccount.status === MemberAccountStatus.ACTIVE
                        && this.memberAccountService.hasWalletFunction(memberAccount, WalletFunction.ENABLE_CURRENCY_WITHDRAWAL)) {
                        this.currencyWithdrawalEnabled = true;
                    }
                    if (memberAccount.accountType === MemberAccountType.BUSINESS && memberAccount.status === MemberAccountStatus.ACTIVE) {
                        this.paymentsEnabled = true;
                    }
                    if (!this.isReturnCodeFilterEnabled) {
                        // if any one of the member account is having payment processor wallet function, we need to show the return code filter.
                        this.isReturnCodeFilterEnabled = this.memberAccountService.hasWalletFunction(memberAccount, WalletFunction.ENABLE_PAYMENT_PROCESSING) && memberAccount.status === MemberAccountStatus.ACTIVE;
                    }
                });
                this.setReturnCodeFilterOptions();
                this.setFilterTransactionType();
                if (this.consumerPaymentsEnabled) {
                    this.updateRelatedLocations();
                }
            });
        } else if (!this.isReturnCodeFilterEnabled && this.authService.isAuthorityOrReviewer()) {
            // Authority transaction listing
            this.isReturnCodeFilterEnabled = true;
        }

        const queryParams = this.route.snapshot.queryParams;
        if (queryParams['state'] || queryParams['memberId'] || queryParams['txnType'] || queryParams['returnCode'] || queryParams['startDate'] || queryParams['endDate'] || queryParams['retailLocationId'] || queryParams[this.MINIMUM_FILTER_PARAM] || queryParams[this.MAXIMUM_FILTER_PARAM] || queryParams['_transactionTab']) {
            this.filter.transactionState = queryParams['state'] || TransactionState.ALL;
            this.filter.memberId = queryParams['memberId'] || this.memberId || '';
            this.filter.transactionTypeFilter = queryParams['txnType'] || TransactionTableTypeFilter.ALL_TYPES;
            if (this.isReturnCodeFilterEnabled && this.filter.transactionTypeFilter === TransactionTableTypeFilter.ACH) {
                this.filter.returnCode = queryParams['returnCode'] || TransactionTableReturnCodeFilter.ALL_ACH_TYPES;
                this.formGroup.controls['returnCodeFilterFormCtrl'].setValue(this.filter.returnCode);
                this.setReturnCodeFilterOptions();
            }
            this.typeChanged(false);
            this.filter.startDate = queryParams['startDate'] || '';
            this.filter.endDate = queryParams['endDate'] || '';
            this.filter.retailLocationId = queryParams['retailLocationId'] || '';
            this.filter.minAmount = queryParams[this.MINIMUM_FILTER_PARAM] || '';
            this.filter.maxAmount = queryParams[this.MAXIMUM_FILTER_PARAM] || '';
            this.filter.transactionTab = queryParams['_transactionTab'] || (this.isActivityListAvailable && !this.authService.isAuthority() ? 'ACCOUNT_ACTIVITY' : 'TRANSACTION_LIST');
            if (queryParams['memberId']) {
                this.memberService.loadMember(this.filter.memberId).subscribe((member: Member) => {
                    this.formGroup.controls['memberCtrl'].setValue(member.id);
                    this.memberSelected(member, false);
                });
            }
            if (this.filter.startDate && this.filter.endDate) {
                this.formGroup.controls['dateCtrl'].setValue({ isRange: true, singleDate: null, dateRange: { beginJsDate: new Date(this.filter.startDate + ' 00:00:00'), endJsDate: new Date(this.filter.endDate + ' 00:00:00') } });
            } else {
                this.formGroup.controls['dateCtrl'].setValue(null);
            }
        } else {
            this.filter.transactionState = TransactionState.ALL;
            this.filter.memberId = this.memberId || '';
            this.setFilterTransactionType();
            this.filter.startDate = '';
            this.filter.endDate = '';
            if (this.accountUnderReview) {
                this.filter.transactionTab = 'BILLING_INFO';
            } else {
                this.filter.transactionTab = this.isActivityListAvailable && !this.authService.isAuthority() ? 'ACCOUNT_ACTIVITY' : 'TRANSACTION_LIST';
            }
            this.filter.minAmount = '';
            this.filter.maxAmount = '';
            this.updateQueryParams(true, true);
        }

        this.route.queryParams.subscribe((params) => {
            // this is so that we are not reloading the graph gadget every time we page or change the sort on the table
            // we are only tracking the filter variables affect the graph
            const currentParams = `${params['memberId'] || ''}${params['_transactionTab'] || ''}${params['state'] || ''}${params['txnType'] || ''}${params['returnCode'] || ''}${params['retailLocationId'] || ''}${params['startDate'] || ''}${params['endDate'] || ''}${params['minAmount'] || ''}${params['maxAmount'] || ''}`;
            if (this.lastParams && this.lastParams !== currentParams) {
                this.handleParamChange(params);
            }
            this.lastTab = params['_transactionTab'] || '';
            this.lastParams = currentParams;
        });
    }

    setReturnCodeFilterOptions() {
        if (this.isReturnCodeFilterEnabled && this.filter.transactionTypeFilter === TransactionTableTypeFilter.ACH) {
            this.returnCodeFilterOptions = [TransactionTableReturnCodeFilter.ALL_ACH_TYPES,
                TransactionTableReturnCodeFilter.NON_RETURNS,
                TransactionTableReturnCodeFilter.ALL_RETURNS,
                TransactionTableReturnCodeFilter.ALL_ADMINISTRATIVE,
                TransactionTableReturnCodeFilter.ALL_UNAUTHORIZED,
                TransactionTableReturnCodeFilter.R01,
                TransactionTableReturnCodeFilter.R02,
                TransactionTableReturnCodeFilter.R03,
                TransactionTableReturnCodeFilter.R04,
                TransactionTableReturnCodeFilter.R05,
                TransactionTableReturnCodeFilter.R06,
                TransactionTableReturnCodeFilter.R07,
                TransactionTableReturnCodeFilter.R08,
                TransactionTableReturnCodeFilter.R09,
                TransactionTableReturnCodeFilter.R10,
                TransactionTableReturnCodeFilter.R16,
                TransactionTableReturnCodeFilter.R21,
                TransactionTableReturnCodeFilter.R23,
                TransactionTableReturnCodeFilter.R29,
                TransactionTableReturnCodeFilter.R51];
        } else {
            this.returnCodeFilterOptions = [];
            this.filter.returnCode = null;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.customerInfo && !changes?.customerInfo.firstChange && this.accountUnderReview) {
            this.setApproveEnabled();
        }
        if (changes.memberAccount && !changes?.memberAccount.firstChange) {
            this.ngOnInit();
        }
    }

    handleParamChange(params: Params) {
        this.filter.transactionState = params['state'] || TransactionState.ALL;
        this.filter.memberId = params['memberId'] || '';
        this.formGroup.controls['memberCtrl'].setValue(this.filter.memberId);
        this.filter.transactionTypeFilter = params['txnType'] || TransactionTableTypeFilter.ALL_TYPES;
        if (this.isReturnCodeFilterEnabled && this.filter.transactionTypeFilter === TransactionTableTypeFilter.ACH) {
            this.filter.returnCode = params['returnCode'] || TransactionTableReturnCodeFilter.ALL_ACH_TYPES;
            this.formGroup.controls['returnCodeFilterFormCtrl'].setValue(this.filter.returnCode);
            this.setReturnCodeFilterOptions();
        }
        this.setFilterTransactionType();
        this.filter.startDate = params['startDate'] || '';
        this.filter.endDate = params['endDate'] || '';
        this.filter.minAmount = params[this.MINIMUM_FILTER_PARAM] || '';
        this.filter.maxAmount = params[this.MAXIMUM_FILTER_PARAM] || '';
        this.filter.retailLocationId = params['retailLocationId'] || '';
        if (this.accountUnderReview) {
            this.filter.transactionTab = params['_transactionTab'] || 'BILLING_INFO';
        } else if (!this.filter.transactionTab && !params['_transactionTab']) {
            this.filter.transactionTab = (this.isActivityListAvailable && !this.authService.isAuthority()) ? 'ACCOUNT_ACTIVITY' : 'TRANSACTION_LIST';
        } else {
            this.filter.transactionTab = params['_transactionTab'];
        }
        if (this.filter.startDate && this.filter.endDate) {
            this.formGroup.controls['dateCtrl'].setValue({ isRange: true, singleDate: null, dateRange: { beginJsDate: new Date(this.filter.startDate + ' 00:00:00'), endJsDate: new Date(this.filter.endDate + ' 00:00:00') } });
        } else {
            this.formGroup.controls['dateCtrl'].setValue(null);
        }
        this.updateRelatedLocations();
        this.filterChanged.emit(this.filter);
    }

    setFilterTransactionType() {
        if (!this.filter.transactionTypeFilter) {
            if (this.isRetailer() || this.isConsumer()) {
                this.filter.transactionTypeFilter = TransactionTableTypeFilter.RETAIL_ONLY;
            } else if (this.authService.isAuthorityOrReviewer() && this.consumerPaymentsEnabled) {
                this.filter.transactionTypeFilter = TransactionTableTypeFilter.ALL_NON_RETAIL;
            } else {
                this.filter.transactionTypeFilter = TransactionTableTypeFilter.ALL_TYPES;
            }
        }
        if (this.isReturnCodeFilterEnabled && !this.filter.returnCode && this.filter.transactionTypeFilter === TransactionTableTypeFilter.ACH) {
            this.filter.returnCode = TransactionTableReturnCodeFilter.ALL_ACH_TYPES;
            this.formGroup.controls['returnCodeFilterFormCtrl'].setValue(this.filter.returnCode);
        }
        this.setReturnCodeFilterOptions();
        this.typeChanged(false);
    }

    onPayment() {
        if (this.memberAccount) {
            this.openPayment(this.memberAccount);
        } else {
            this.accountSelectorService.selectAccountAndPerform(this.memberId, false, this.openPayment);
        }
    }

    openPayment(memberAccount: MemberAccount) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'full-modal';
        dialogConfig.disableClose = true;
        dialogConfig.data = {
            memberAccount
        };

        const dialog = this.dialog.open(PaymentModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe(
            (refresh: boolean) => {
                if (refresh) {
                    this.postTransactionSubmit();
                }
            }
        );
    }

    onRetailPayment() {
        if (this.authService.getProfile().retailAccountId) {
            this.openRetailPayment(this.authService.getProfile().retailAccountId);
        }
    }

    openRetailPayment(retailAccountId: string) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.disableClose = true;

        const dialog = this.dialog.open(RetailPaymentModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe(
            (refresh: boolean) => {
                if (refresh) {
                    this.postTransactionSubmit();
                }
            }
        );
    }

    onDeposit() {
        if (this.memberAccount) {
            this.openDeposit(this.memberAccount);
        } else {
            this.accountSelectorService.selectAccountAndPerform(this.memberId, false, this.openDeposit, WalletFunction.ACH_BALANCE_PUSH, true);
        }
    }

    openDeposit(memberAccount: MemberAccount) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'full-modal';
        dialogConfig.disableClose = true;
        dialogConfig.data = {
            memberAccount
        };

        const dialog = this.dialog.open(DepositModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe(
            (refresh: boolean) => {
                if (refresh) {
                    this.postTransactionSubmit();
                }
            }
        );
    }

    onCurrencyWithdrawal() {
        if (this.memberAccount) {
            this.openCurrencyWithdrawal(this.memberAccount);
        } else {
            this.accountSelectorService.selectAccountAndPerform(this.memberId, false, this.openCurrencyWithdrawal, WalletFunction.ENABLE_CURRENCY_WITHDRAWAL);
        }
    }

    openCurrencyWithdrawal(memberAccount: MemberAccount) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'full-modal';
        dialogConfig.disableClose = true;
        dialogConfig.data = {
            memberAccount
        };

        const dialog = this.dialog.open(CurrencyWithdrawalModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe(
            (refresh: boolean) => {
                if (refresh) {
                    this.postTransactionSubmit();
                }
            }
        );
    }

    onAddFunds() {
        if (this.memberAccount) {
            this.openAddFunds(this.memberAccount);
        } else {
            this.accountSelectorService.selectAccountAndPerform(this.memberId, false, this.openAddFunds);
        }
    }

    openAddFunds(memberAccount: MemberAccount) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.data = {
            header: 'Add Funds',
            memberAccount,
            transactionType: TransactionType.DEPOSIT_ADJUSTMENT_ADDITION
        };
        dialogConfig.disableClose = true;
        const dialog = this.dialog.open(AuthorityPaymentModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe(
            (refresh: boolean) => {
                if (refresh) {
                    this.postTransactionSubmit();
                }
            }
        );
    }

    onRemoveFunds() {
        if (this.memberAccount) {
            this.openRemoveFunds(this.memberAccount);
        } else {
            this.accountSelectorService.selectAccountAndPerform(this.memberId, false, this.openRemoveFunds);
        }
    }

    openRemoveFunds(memberAccount: MemberAccount) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.data = {
            header: 'Remove Funds',
            memberAccount,
            transactionType: TransactionType.DEPOSIT_ADJUSTMENT_DEDUCTION
        };
        dialogConfig.disableClose = true;
        const dialog = this.dialog.open(AuthorityPaymentModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe(
            (refresh: boolean) => {
                if (refresh) {
                    this.postTransactionSubmit();
                }
            }
        );
    }

    onChargePayment() {
        if (this.memberAccount) {
            this.openChargePayment(this.memberAccount);
        } else {
            this.accountSelectorService.selectAccountAndPerform(this.memberId, false, this.openChargePayment);
        }
    }

    openChargePayment(memberAccount: MemberAccount) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.data = {
            header: 'Charge',
            memberAccount,
            transactionType: TransactionType.ACCOUNT_FEE
        };
        dialogConfig.disableClose = true;
        const dialog = this.dialog.open(AuthorityPaymentModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe(
            (refresh: boolean) => {
                if (refresh) {
                    this.postTransactionSubmit();
                }
            }
        );
    }

    onCreditPayment() {
        if (this.memberAccount) {
            this.openCreditPayment(this.memberAccount);
        } else {
            this.accountSelectorService.selectAccountAndPerform(this.memberId, false, this.openCreditPayment);
        }
    }

    openCreditPayment(memberAccount: MemberAccount) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.data = {
            header: 'Credit',
            memberAccount,
            transactionType: TransactionType.ACCOUNT_CREDIT
        };
        dialogConfig.disableClose = true;
        const dialog = this.dialog.open(AuthorityPaymentModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe(
            (refresh: boolean) => {
                if (refresh) {
                    this.postTransactionSubmit();
                }
            }
        );
    }

    stateChanged() {
        this.updateQueryParams(false);
        this.filterChanged.emit(this.filter);
    }

    retailLocationChanged() {
        this.updateQueryParams(false);
        this.filterChanged.emit(this.filter);
    }

    memberSelected(member: Member, emit = true) {
        if (member?.id) {
            this.member = member;
            this.filter.memberId = member.id;
            this.filter.memberType = member.memberType;
            this.filter.memberFunctions = member.memberFunctions;
            if (!this.filter.transactionTypeFilter) {
                if (member.memberType === MemberType.CONSUMER) {
                    this.filter.transactionTypeFilter = TransactionTableTypeFilter.RETAIL_ONLY;
                } else {
                    this.filter.transactionTypeFilter = TransactionTableTypeFilter.ALL_TYPES;
                }
            }
        } else {
            this.filter.memberId = '';
            this.filter.memberType = MemberType.ALL;
            this.filter.memberFunctions = [];
            this.filter.walletFunctions = [];
            this.member = null;
            if (!this.filter.transactionTypeFilter) {
                if (this.isAuthorityOrAcceptsConsumerPayments()) {
                    this.filter.transactionTypeFilter = TransactionTableTypeFilter.ALL_NON_RETAIL;
                } else {
                    this.filter.transactionTypeFilter = TransactionTableTypeFilter.ALL_TYPES;
                }
            }
        }
        this.filter.retailLocationId = '';
        this.updateRelatedLocations();
        this.typeChanged(false);
        this.updateQueryParams(false);
        if (emit) {
            this.filterChanged.emit(this.filter);
        }
    }

    dateChanged(event: IMyDateModel) {
        if (event && event.dateRange) {
            this.filter.startDate = this.datePipe.transform(event.dateRange.beginJsDate, 'yyyy-MM-dd');
            const endDateRange = event.dateRange.endJsDate.setDate(event.dateRange.endJsDate.getDate());
            this.filter.endDate = this.datePipe.transform(endDateRange, 'yyyy-MM-dd');
        } else {
            this.filter.startDate = '';
            this.filter.endDate = '';
        }
        this.updateQueryParams(false);
        this.filterChanged.emit(this.filter);
    }

    exportTransactionListing(reset: any) {
        this.setSubmitting(true);
        const memberName = this.member ? this.member.name : 'ALL';
        const state = this.filter.transactionState === TransactionState.ALL ? 'ALL' : this.filter.transactionState;
        const type = this.filter.transactionTypeFilter === TransactionTableTypeFilter.ALL_TYPES ? 'ALL' : this.getType().replace(/\s+/g, '_');
        const returnCode = this.filter.transactionTypeFilter === TransactionTableTypeFilter.ACH && this.filter.returnCode ? this.filter.returnCode + '_' : '';
        const startDate = this.filter.startDate === '' ? 'ALL' : this.filter.startDate;
        const endDate = this.filter.endDate === '' ? 'ALL' : this.filter.endDate;
        const retailLocation = !this.filter.retailLocationId ? 'ALL' : this.retailLocations.filter((location) => {
            return location.id === this.filter.retailLocationId;
        })[0].nickname.replace(/\s+/g, '_').replace(/\.+/g, '_');

        const fileName = memberName + '_' + state + '_' + type + '_' + returnCode + retailLocation + '_' + startDate + '-' + endDate + '.csv';
        this.paymentService.exportTransactionList(this.filter.memberId, this.filter.memberAccountId, this.filter.transactionState, this.filter.paymentRails, this.filter.returnCode, this.filter.transactionSources,
            this.filter.startDate, this.filter.endDate, this.filter.retailLocationId, this.filter.minAmount, this.filter.maxAmount, this.exportAccountName).subscribe({
            next: (response: HttpResponse<Blob>) => {
                TransactionUtils.downloadFile(response, fileName, this.downloadReportLink);
                this.setSubmitting(false);
                if (reset) {
                    reset();
                }
            },
            error: (error: any) => {
                this.setSubmitting(false);
                if (reset) {
                    reset();
                }
                throw error;
            }
        });
    }

    exportAccountActivityList(reset: any) {
        this.setSubmitting(true);
        let currentDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd_hh-mm-ss');
        const fileName = this.member ? `${this.member.name}_activity_${currentDate}.csv` : `activity_${currentDate}.csv`;

        this.reportsService.exportAccountActivityList(this.filter.memberId, this.filter.memberAccountId).subscribe({
            next: (response: HttpResponse<Blob>) => {
                TransactionUtils.downloadFile(response, fileName, this.downloadReportLink);
                this.setSubmitting(false);
                if (reset) {
                    reset();
                }
            },
            error: (error: any) => {
                this.setSubmitting(false);
                if (reset) {
                    reset();
                }
                throw error;
            }
        });
    }

    getType() {
        if (this.filter.transactionTypeFilter === TransactionTableTypeFilter.ALL_NON_RETAIL) {
            return 'All Non-Retail';
        } else if (this.filter.transactionTypeFilter === TransactionTableTypeFilter.RETAIL_ONLY) {
            return 'Retail Only';
        } else if (this.filter.transactionTypeFilter === TransactionTableTypeFilter.CONFIA_PAYMENT) {
            return 'Direct Transfer';
        } else if (this.filter.transactionTypeFilter === TransactionTableTypeFilter.ACH) {
            return 'ACH';
        } else if (this.filter.transactionTypeFilter === TransactionTableTypeFilter.CASH) {
            return 'Cash';
        } else if (this.filter.transactionTypeFilter === TransactionTableTypeFilter.CHECK) {
            return 'Check';
        } else if (this.filter.transactionTypeFilter === TransactionTableTypeFilter.WIRE) {
            return 'Wire';
        } else if (this.filter.transactionTypeFilter === TransactionTableTypeFilter.FEE) {
            return 'Fee';
        } else if (this.filter.transactionTypeFilter === TransactionTableTypeFilter.ADJUSTMENT) {
            return 'Adjustment';
        }
    }

    typeChanged(updateQueryParams: boolean) {
        if (!this.filter.retailLocationId) {
            this.filter.retailLocationId = this.authService.getProfile().retailLocationId || '';
        }

        if (this.isRetailer()) {
            this.filter.transactionSources = [TransactionSource.SYSTEM_TRANSACTION, TransactionSource.RETAIL_TRANSACTION];
        } else if (this.isConsumer()) {
            this.filter.transactionSources = [TransactionSource.EXTERNAL_TRANSACTION, TransactionSource.NETWORK_TRANSACTION, TransactionSource.SYSTEM_TRANSACTION, TransactionSource.RETAIL_TRANSACTION];
        } else {
            this.filter.transactionSources = [];
        }

        if (this.filter.transactionTypeFilter !== TransactionTableTypeFilter.RETAIL_ONLY && this.filter.transactionTypeFilter !== TransactionTableTypeFilter.ALL_TYPES) {
            this.filter.retailLocationId = '';
        }

        if (this.filter.transactionTypeFilter === TransactionTableTypeFilter.CONFIA_PAYMENT) {
            this.filter.paymentRails = [PaymentRail.NETWORK_PAYMENT];
        } else if (this.filter.transactionTypeFilter === TransactionTableTypeFilter.ACH) {
            this.filter.paymentRails = [PaymentRail.ACH];
            if (this.isReturnCodeFilterEnabled) {
                if (!this.filter.returnCode) {
                    this.filter.returnCode = TransactionTableReturnCodeFilter.ALL_ACH_TYPES;
                    this.formGroup.controls['returnCodeFilterFormCtrl'].setValue(this.filter.returnCode);
                } else {
                    this.returnCodeChanged(false);
                }
            }
        } else if (this.filter.transactionTypeFilter === TransactionTableTypeFilter.WIRE) {
            this.filter.paymentRails = [PaymentRail.WIRE];
        } else if (this.filter.transactionTypeFilter === TransactionTableTypeFilter.CHECK) {
            this.filter.paymentRails = [PaymentRail.CHECK];
        } else if (this.filter.transactionTypeFilter === TransactionTableTypeFilter.CASH) {
            this.filter.paymentRails = [PaymentRail.CASH];
        } else if (this.filter.transactionTypeFilter === TransactionTableTypeFilter.FEE) {
            this.filter.paymentRails = [PaymentRail.FEE];
        } else if (this.filter.transactionTypeFilter === TransactionTableTypeFilter.ADJUSTMENT) {
            this.filter.paymentRails = [PaymentRail.ADJUSTMENT];
        } else if (this.filter.transactionTypeFilter === TransactionTableTypeFilter.RETAIL_ONLY) {
            this.filter.transactionSources = [TransactionSource.RETAIL_TRANSACTION];
            this.filter.paymentRails = [];
        } else if (this.filter.transactionTypeFilter === TransactionTableTypeFilter.ALL_NON_RETAIL) {
            this.filter.transactionSources = [TransactionSource.EXTERNAL_TRANSACTION, TransactionSource.NETWORK_TRANSACTION, TransactionSource.SYSTEM_TRANSACTION];
            this.filter.paymentRails = [];
        } else {
            this.filter.transactionSources = [];
            this.filter.paymentRails = [];
        }
        if (this.filter.transactionTypeFilter !== TransactionTableTypeFilter.ACH && this.filter.returnCode) {
            this.filter.returnCode = null;
        }
        if (updateQueryParams) {
            this.updateQueryParams(false);
            this.filterChanged.emit(this.filter);
        }
    }

    returnCodeChanged(updateQueryParams: boolean) {
        this.filter.returnCode = this.formGroup.controls['returnCodeFilterFormCtrl'].value;
        if (updateQueryParams) {
            this.updateQueryParams(false);
            this.filterChanged.emit(this.filter);
        }
    }

    updateRelatedLocations() {
        this.retailLocations = [];
        if (this.filter.memberId) {
            this.addressService.getRetailAddressOccupantLinks(this.filter.memberId, [AddressStatus.ACTIVE, AddressStatus.DISABLED]).subscribe((response: PagedResponse<AddressOccupant>) => {
                this.retailLocations = response.content;
            });
        }
    }

    showRetailFilter() {
        if (this.authService.getProfile().retailLocationId) {
            return false;
        } else if (this.filter.memberFunctions.indexOf(MemberFunction.PARTNER) >= 0 && this.filter.memberId && this.retailLocations.length &&
            (this.filter.transactionTypeFilter === TransactionTableTypeFilter.RETAIL_ONLY || this.filter.transactionTypeFilter === TransactionTableTypeFilter.ALL_TYPES)) {
            return true;
        }
        return false;
    }

    isRetailer(memberAccount?: MemberAccount) {
        return this.authService.isRetailer(memberAccount);
    }

    isConsumer() {
        return this.filter.memberType === MemberType.CONSUMER;
    }

    isAuthorityOrAcceptsConsumerPayments() {
        return this.consumerPaymentsEnabled || (!this.filter.memberId && this.authService.isAuthorityOrReviewer());
    }

    updateQueryParams(replace: boolean, tabChange: boolean = false) {
        let queryParams = { memberId: this.filter.memberId, _transactionTab: this.filter.transactionTab, state: null, txnType: null, returnCode: null, retailLocationId: null, startDate: null, endDate: null, minAmount: null, maxAmount: null, page: null};
        if (tabChange) {
            // reset to defaults
            queryParams['sort'] = null;
            queryParams['dir'] = null;
            queryParams['num'] = null;
            if (this.filter.transactionTab === 'ACCOUNT_ACTIVITY') {
                queryParams['num'] = 100;
            } else if (this.filter.transactionTab === 'TRANSACTION_LIST') {
                queryParams['num'] = 100;
                queryParams['sort'] = 'transactionDate';
                queryParams['dir'] = 'desc';
            } else if (this.filter.transactionTab === 'ACCOUNT_SETTINGS') {
                queryParams['page'] = 1;
                queryParams['num'] = 25;
                queryParams['sort'] = 'status';
                queryParams['dir'] = 'asc';
            } else if (this.filter.transactionTab === 'BANKING_DETAILS') {
                queryParams['num'] = 25;
                queryParams['sort'] = 'created';
                queryParams['dir'] = 'desc';
            }
        }

        if (this.accountUnderReview) {
            queryParams['memberAccountId'] = this.filter.memberAccountId;
        } else if (this.filter.transactionTab === 'ACCOUNT_ACTIVITY') {
            queryParams['page'] = 1;
        } else if (this.filter.transactionTab === 'TRANSACTION_LIST') {
            queryParams['state'] = this.filter.transactionState;
            queryParams['txnType'] = this.filter.transactionTypeFilter;
            queryParams['returnCode'] = this.filter.returnCode;
            queryParams['retailLocationId'] = this.filter.retailLocationId;
            queryParams['startDate'] = this.filter.startDate;
            queryParams['endDate'] = this.filter.endDate;
            queryParams['minAmount'] = this.filter.minAmount;
            queryParams['maxAmount'] = this.filter.maxAmount;
            queryParams['page'] = 1;
        }
        TableUtils.updateQueryParams(this.route, this.router, queryParams, replace);
    }

    showRetailPaymentButton() {
        return this.isRetailer(this.memberAccount) && this.consumerPaymentsEnabled;
    }

    showExportButton() {
        return this.authService.isAuthorityOrAdmin() || this.authService.isMemberOrMemberReviewer();
    }

    /**
     * When minimum or maximum field filters changes.
     */
    minMaxChanged() {
        const minCtrl = this.formGroup.get('minimumCtrl');
        const maxCtrl = this.formGroup.get('maximumCtrl');
        this.filter.minAmount = minCtrl.value || '';
        this.filter.maxAmount = maxCtrl.value || '';

        if (minCtrl.value === '') {
            minCtrl.reset();
        }

        if (maxCtrl.value === '') {
            maxCtrl.reset();
        }
        this.updateQueryParams(false);
        this.filterChanged.emit(this.filter);
    }

    /**
     * Listen to the UI "more/less" toggle event
     * @param event
     */
    onMoreFiltersToggled(event) {
        this.isMoreFilterToggled = !this.isMoreFilterToggled;
    }

    setTransactionTab(tabOption: string) {
        if (this.filter.transactionTab === tabOption) {
            return;
        }
        this.isMoreFilterToggled = false;
        this.filter.transactionTab = tabOption;
        this.updateQueryParams(false, true);
    }

    getTabClass(tabOption: string) {
        if (!this.memberAccount && !this.isActivityListAvailable) {
            return 'span-header px-3 title-padding fw-bold';
        } else if (this.filter.transactionTab === tabOption) {
            return 'tab-navigation-selected';
        }
        return 'tab-navigation';
    }

    postTransactionSubmit() {
        if (this.memberAccount) {
            this.walletService.findByMemberAccountId(this.memberAccount.id).subscribe((wallet: Wallet) => {
                this.memberAccount.wallet = wallet;
            });
        }
        this.transactionSubmitted.emit();
    }

    onApproveAccount(reset: any) {
        let status = MemberAccountStatus.ACTIVE;
        if (Configuration.getConfig().bankingIntegrationEnabled && Configuration.getConfig().accountIdsRequired && this.memberAccount.status === MemberAccountStatus.UNDER_REVIEW) {
            status = MemberAccountStatus.PENDING;
        }
        this.completeTask(status, reset);
    }

    onRejectAccount(reset: any) {
        this.completeTask(MemberAccountStatus.REJECTED, reset);
    }

    completeTask(status: MemberAccountStatus, reset: any) {
        const body = {
            variables: {
                memberAccountStatus: {
                    value: status,
                    type: 'String'
                }
            }
        };

        this.workflowService.completeTask(this.taskId, body).subscribe(() => {
            this.workflowService.clearCurrentTask();
            if (status === MemberAccountStatus.ACTIVE) {
                this.notifier.showSuccess(`Account ${this.memberAccount.accountName} has been approved for ${this.memberAccount.member.name}.`);
            } else if (status === MemberAccountStatus.PENDING) {
                this.notifier.showSuccess(`Account ${this.memberAccount.accountName} has been approved for ${this.memberAccount.member.name}.<br/>  Account number is available and must be submitted for registration.`);
            }
            this.router.navigate(['/administration/member/' + this.memberId], {queryParams: {_activeTab: 'accounts'}});
        }, (error: any) => {
            reset();
            throw error;
        });
    }

    setApproveEnabled() {
        this.approveEnabled = false;
        if (this.memberAccount) {
            if (this.memberAccount.status === MemberAccountStatus.PENDING
                && Configuration.getConfig().bankingIntegrationEnabled
                && (this.memberAccount.member.memberType === MemberType.BUSINESS || this.memberAccount.member.memberType === MemberType.FEE_ACCOUNT)) {
                if (this.customerInfo?.customerId && this.customerInfo?.accountId) {
                    this.approveEnabled = true;
                }
            } else {
                this.approveEnabled = true;
            }
        }
    }

    canApproveAccount() {
        return this.approveEnabled;
    }

    setSubmitting(submitting: boolean) {
        this.submitting = submitting;
        if (submitting) {
            this.formGroup.disable();
        } else {
            this.formGroup.enable();
        }
    }
}
