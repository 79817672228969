import { Component, Input, Output, OnInit, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';

@Component({
    selector: 'pc-form-date-picker',
    templateUrl: './form-date-picker.component.html',
    styleUrls: ['./form-date-picker.component.scss']
})
export class FormDatePickerComponent implements OnInit, OnChanges {

    readonly ISO_DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/;

    @Input() changeTrigger: number;
    @Input() label: string;
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;
    @Input() type: 'RANGED' | 'SINGLE' = 'SINGLE';
    @Input() historical = false;
    @Input() defaultEmpty = false;
    @Input() defaultToday = false;
    @Input() excludeWeekends = false;
    @Input() excludedDates = [];

    @Output() dateSelected: EventEmitter<IMyDateModel> = new EventEmitter<IMyDateModel>();

    curDate = new Date();
    isCloseIconVisible = true;
    placeholder = '';
    clearClicked = false;
    myOptions: IAngularMyDpOptions;

    ngOnInit() {
        this.init();
        this.formGroup.controls[this.formCtrl].valueChanges.subscribe((result) => {
            this.isCloseIconVisible = !!result;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.changeTrigger && !changes.changeTrigger.firstChange) {
            this.init();
        }
    }

    init() {
        const formValue = this.formGroup.controls[this.formCtrl].value;
        if (this.type === 'SINGLE') {
            if (this.historical) {
                this.myOptions = {
                    firstDayOfWeek: 'su',
                    dateRange: false,
                    dateFormat: 'mm/dd/yyyy',
                    disableUntil : {year: 2020,  month: 4,  day: 1},
                    disableSince : {year: this.curDate.getFullYear(),  month: this.curDate.getMonth() + 1,  day: this.curDate.getDate() + 1},
                    disableDates: this.excludedDates,
                    disableWeekends: this.excludeWeekends
                };
            } else {
                this.myOptions = {
                    firstDayOfWeek: 'su',
                    dateRange: false,
                    dateFormat: 'mm/dd/yyyy',
                    disableUntil: { year: this.curDate.getFullYear(), month: this.curDate.getMonth() + 1, day: this.curDate.getDate()},
                    disableDates: this.excludedDates,
                    disableWeekends: this.excludeWeekends
                };
            }
            this.placeholder = 'Select a date';
            let model: IMyDateModel;
            if (formValue?.singleDate?.jsDate) {
                model = { isRange: false, singleDate: { jsDate: formValue?.singleDate?.jsDate }, dateRange: null };
            } else {
                let jsDate: Date;
                if (formValue && (typeof formValue === 'string') && formValue.match(this.ISO_DATE_REGEX)) {
                    jsDate = new Date(formValue.replace('-', '/'));
                } else if (formValue) {
                    jsDate = new Date(formValue);
                } else {
                    jsDate = new Date();
                }
                model = { isRange: false, singleDate: { jsDate: jsDate }, dateRange: null };
            }
            if (this.defaultEmpty) {
                this.formGroup.controls[this.formCtrl].setValue(null);
                this.isCloseIconVisible = false;
            } else {
                this.formGroup.controls[this.formCtrl].setValue(model);
            }
        } else {
            this.myOptions = {
                firstDayOfWeek: 'su',
                dateRange: true,
                dateFormat: 'mm/dd/yyyy',
                appendSelectorToBody: true,
                disableUntil : {year: 2020,  month: 4,  day: 1},
                disableSince : {year: this.curDate.getFullYear(),  month: this.curDate.getMonth() + 1,  day: this.curDate.getDate() + 1},
                disableDates: this.excludedDates,
                disableWeekends: this.excludeWeekends
            };
            this.isCloseIconVisible = false;
            this.formGroup.controls[this.formCtrl].setValue(null);
            if (formValue) {
                this.isCloseIconVisible = true;
                this.formGroup.controls[this.formCtrl].setValue(formValue);
            }
            this.placeholder = 'Select a date range';
        }
        if (this.formGroup.controls[this.formCtrl].disabled) {
            this.isCloseIconVisible = false;
        }
        this.formGroup.updateValueAndValidity();
    }

    onDateChanged(event: IMyDateModel) {
        if (this.type === 'SINGLE' && event.singleDate?.jsDate) {
            this.isCloseIconVisible = true;
            this.dateSelected.emit(event);
        } else if (event.dateRange?.beginJsDate && event.dateRange?.endJsDate) {
            this.dateSelected.emit(event);
            this.isCloseIconVisible = true;
        } else {
            this.dateSelected.emit(null);
            this.isCloseIconVisible = false;
        }
    }

    onClick(event: any, datePicker: any) {
        event.target.blur();
        event.preventDefault();
        if (!this.formGroup.controls[this.formCtrl].disabled && !this.clearClicked) {
            datePicker.toggleCalendar();
        }
        this.clearClicked = false;
    }

    clear(datePicker: any) {
        this.clearClicked = true;
        if (!this.defaultToday) {
            datePicker.clearDate();
        } else {
            const model: IMyDateModel = { isRange: false, singleDate: { jsDate: new Date() }, dateRange: null };
            this.formGroup.controls[this.formCtrl].setValue(model);
            this.formGroup.updateValueAndValidity();
            this.dateSelected.emit(model);
        }
    }
}
