import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { Configuration, PagedResponse, RecordsService, SupportEvidenceItem, TrackingSystemSupportEvidence } from 'projects/services/src/public-api';
import { Subscription, merge, startWith, switchMap, of as observableOf } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
    selector: 'pt-transaction-tracking-system-evidence',
    templateUrl: './transaction-tracking-system-evidence.component.html'
})
export class TransactionTrackingSystemEvidenceComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

    @Input() transactionTrackingSupportEvidence: TrackingSystemSupportEvidence;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    supportEvidenceItems: SupportEvidenceItem[];
    displayedColumns = ['manifest_counter_party_name', 'product_category', 'units_of_measurement', 'quantity', 'estimated_cost'];
    isLoadingResults = true;
    resultsLength = 0;
    trackingInfoConsolidationDays: number;
    pageTracking: PageTracking;
    subscription: Subscription;
    refreshEvent: EventEmitter<null> = new EventEmitter<null>();

    constructor(private route: ActivatedRoute,
      private router: Router,
      private recordsService: RecordsService,
      private cdr: ChangeDetectorRef) { }

    ngOnInit() {
        this.trackingInfoConsolidationDays = Configuration.getConfig().trackingInfoConsolidationDays;
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'estimatedCost', 'desc');
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.loadData();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.changeTrigger && !changes.changeTrigger.firstChange) {
            this.refreshEvent.emit();
        }
    }

    loadData() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.paginator.page, this.refreshEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                return this.getSupportEvidenceItems(this.transactionTrackingSupportEvidence.id, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((supportEvidenceItems: SupportEvidenceItem[]) => {
            this.supportEvidenceItems = supportEvidenceItems;
            UIUtils.scrollDashboardToTop();
            this.isLoadingResults = false;
        });
    }

    getSupportEvidenceItems(trackingEvidenceId: string, start: number, size: number, sort: string, sortDir: string) {
        return this.recordsService.getSupportEvidenceItemsByTrackingEvidenceId(trackingEvidenceId, start, size, sort, sortDir)
            .pipe(
                map((response: PagedResponse<SupportEvidenceItem>) => {
                    this.resultsLength = response.totalElements || 0;
                    return response.content || [];
                }),
                map((supportEvidenceItems: SupportEvidenceItem[]) => {
                    this.supportEvidenceItems = supportEvidenceItems;
                    return this.supportEvidenceItems;
                })
            );
    }

    isAccountActivityAmountExceedingEstimatedCost() {
        return this.transactionTrackingSupportEvidence.accountActivityAmount > this.transactionTrackingSupportEvidence.totalEstimatedCost;
    }
}
