import { Component, Inject, OnInit } from '@angular/core';
import {
    MemberAccount, MemberAccountType, MemberType, MerchantAccount, MerchantAccountService, MerchantAccountStatus, PagedResponse, PaymentService, Transaction
} from 'projects/services/src/public-api';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from 'projects/components/src/lib/modal';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ErrorType } from 'projects/components/src/lib/error-message';

@Component({
    selector: 'pc-counterparty-select-modal',
    templateUrl: './counterparty-select-modal.component.html',
    styleUrls: ['./counterparty-select-modal.component.scss']
})
export class CounterpartySelectModalComponent extends BaseModalComponent<CounterpartySelectModalComponent> implements OnInit {

    MemberType = MemberType;
    MemberAccountType = MemberAccountType;

    transaction: Transaction;
    originatorMemberId: string;
    counterpartyMemberId: string;
    ultimateCounterpartyMemberAccountId: string = '';
    ultimateCounterpartyMemberName: string = '';
    selectedUltimateCounterpartyMemberAccount: MemberAccount;
    ultimateCounterPartyForm: UntypedFormGroup;
    warning: { message: string, type: ErrorType };
    merchantLinkedToMember = false;

    constructor(dialogRef: MatDialogRef<CounterpartySelectModalComponent>,
                private formBuilder: UntypedFormBuilder,
                private paymentService: PaymentService,
                private merchantAccountService: MerchantAccountService,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.transaction = data.transaction;
        this.originatorMemberId = data.originatorMemberId;
        this.counterpartyMemberId = data.counterpartyMemberId;
        this.ultimateCounterpartyMemberAccountId = data.ultimateCounterpartyMemberAccountId || '';
        this.ultimateCounterpartyMemberName = data.ultimateCounterpartyMemberName || '';
    }

    ngOnInit() {
        this.onSubmit = this.onSubmit.bind(this);
        this.isAccountSelected = this.isAccountSelected.bind(this);
        this.counterPartyChanged = this.counterPartyChanged.bind(this);
        this.ultimateCounterPartyForm = this.formBuilder.group({
            counterPartyCtrl: new UntypedFormControl(this.ultimateCounterpartyMemberAccountId)
        });
    }

    close() {
        super.close(false);
    }

    onSubmit() {
        let updates = {
            relatedPartyMemberId: null,
            relatedPartyMemberName: null,
            relatedPartyAccountId: null
        };
        if (this.isAccountSelected()) {
            updates = {
                relatedPartyMemberId: this.selectedUltimateCounterpartyMemberAccount.memberId,
                relatedPartyMemberName: this.selectedUltimateCounterpartyMemberAccount.member.name,
                relatedPartyAccountId: this.selectedUltimateCounterpartyMemberAccount.id
            };
        }
        this.paymentService.updateTransaction(this.transaction.id, updates).subscribe(() => {
            super.close(true);
        });
    }

    isAccountSelected() {
        return this.selectedUltimateCounterpartyMemberAccount?.id;
    }

    counterPartyMemberAccountSelected(memberAccount: MemberAccount) {
        this.selectedUltimateCounterpartyMemberAccount = memberAccount;
        this.merchantLinkedToMember = false;
        if (this.ultimateCounterpartyMemberAccountId && !this.selectedUltimateCounterpartyMemberAccount.id) {
            this.warning = {
                type: ErrorType.WARN,
                message: `This will unlink ${this.ultimateCounterpartyMemberName} from this transaction as the counterparty`
            };
        } else if (memberAccount.id) {
            this.warning = null;
            if (memberAccount.member.memberType === MemberType.BUSINESS_MERCHANT) {
                this.merchantAccountService.getAllMerchantAccountsByMerchantMemberIdAndMemberId(memberAccount.memberId, this.originatorMemberId).subscribe((merchantAccounts: PagedResponse<MerchantAccount>) => {
                    if (merchantAccounts.content.length && merchantAccounts.content[0].status === MerchantAccountStatus.ACTIVE) {
                        this.merchantLinkedToMember = true;
                    }
                });
            }
        }
    }

    counterPartyChanged() {
        return this.isAccountSelected() || (!this.isAccountSelected() && this.ultimateCounterpartyMemberAccountId);
    }

}
