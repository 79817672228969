import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { WalletFunction } from '../models/member-account';

@Injectable({
    providedIn: 'root'
})
export class WalletFunctionFormService {

    constructor(private formbuilder: UntypedFormBuilder) {
    }

    initializeForm(walletFunctions: WalletFunction []): UntypedFormGroup {
        const form = this.formbuilder.group({
            achDepositEnabledCtrl: new UntypedFormControl(),
            cashDepositEnabledCtrl: new UntypedFormControl(),
            currencyWithdrawalEnabledCtrl: new UntypedFormControl(),
            acceptConsumerCtrl: new UntypedFormControl(),
            acceptBusinessCtrl: new UntypedFormControl(),
            directPaymentEnabledCtrl: new UntypedFormControl(),
            dailyAchPushEnabledCtrl: new UntypedFormControl(),
            paymentProcessingEnabledCtrl: new UntypedFormControl(),
            allowExternalCheckCtrl: new UntypedFormControl()
        });
        if (walletFunctions) {
            form.patchValue({
                achDepositEnabledCtrl: walletFunctions.includes(WalletFunction.ACH_DEPOSIT),
                cashDepositEnabledCtrl: walletFunctions.includes(WalletFunction.CASH_DEPOSIT),
                currencyWithdrawalEnabledCtrl: walletFunctions.includes(WalletFunction.ENABLE_CURRENCY_WITHDRAWAL),
                acceptConsumerCtrl: walletFunctions.includes(WalletFunction.ACCEPT_CONSUMER_PAYMENTS),
                acceptBusinessCtrl: walletFunctions.includes(WalletFunction.ACCEPT_BUSINESS_PAYMENTS),
                directPaymentEnabledCtrl: walletFunctions.includes(WalletFunction.ACH_DIRECT_PAYMENT),
                dailyAchPushEnabledCtrl: walletFunctions.includes(WalletFunction.ACH_BALANCE_PUSH),
                paymentProcessingEnabledCtrl: walletFunctions.includes(WalletFunction.ENABLE_PAYMENT_PROCESSING),
                allowExternalCheckCtrl: walletFunctions.includes(WalletFunction.ALLOW_EXTERNAL_CHECKS)
            });
        }
        return form;
    }

}
