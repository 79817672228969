import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    BusinessClientStatus, OnboardingTrackingService, MemberType, KeyParty, KeyPartyService, DataroomService, PagedResponse, UploadStatus, Upload, DocumentType, MemberStatus, MemberRegistrationType
} from 'projects/services/src/public-api';

@Component({
    selector: 'pt-member-key-party-details',
    templateUrl: './member-key-party-details.component.html'
})
export class MemberKeyPartyDetailsComponent implements OnInit {

    @Input() memberId: any;
    @Input() memberType: MemberType;
    @Input() memberRegistrationType: MemberRegistrationType;
    @Input() task: any;
    @Input() changeTrigger: number;
    @Input() memberStatus: MemberStatus | BusinessClientStatus;
    @Input() readOnly = false;
    @Input() allowAdd = true;
    @Input() isMerchant = false;

    @Output() next: EventEmitter<string> = new EventEmitter<string>();

    constructor(private keyPartyService: KeyPartyService,
                private dataroomService: DataroomService) {}

    ngOnInit() {
        this.onNavigate = this.onNavigate.bind(this);
        this.isKeyPartiesValid = this.isKeyPartiesValid.bind(this);
    }

    onNavigate() {
        this.next.emit();
    }

    keyPartiesUpdated() {
        this.keyPartyService.getAllActiveKeyParties(this.memberId).subscribe((keyParties: PagedResponse<KeyParty>) => {
            OnboardingTrackingService.setKeyPartyInfo(keyParties.content);
            const authorityDocuments: Upload[] = [];
            keyParties.content.forEach((keyParty) => {
                this.dataroomService.getUploads('', [UploadStatus.ACTIVE], [DocumentType.ID_RISK_SCREEN, DocumentType.MEDIA_SCREEN],
                    keyParty.individualKeyPartyId || keyParty.corporateKeyPartyId).subscribe((screeningDocuments: Upload[]) => {
                    authorityDocuments.push(...screeningDocuments);
                    OnboardingTrackingService.setAuthorityDocuments(authorityDocuments);
                });
            });
        });
    }

    isKeyPartiesValid() {
        return OnboardingTrackingService.isKeyPartyInfoValid();
    }

    onKeyPartyChange() {
        this.changeTrigger = Math.random();
    }
}
