import { AccountingFinancialItem } from './accounting-financial-item';

export class ProfitAndLossReport {
    fromDate: string;
    toDate: string;

    income: AccountingFinancialItem;
    costOfSales: AccountingFinancialItem;
    expenses: AccountingFinancialItem;
    otherIncome: AccountingFinancialItem;
    otherExpenses: AccountingFinancialItem;

    netOtherIncome: number;
    netProfit: number;
    grossProfit: number;
    netOperatingProfit: number;
}
