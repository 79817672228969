import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';

import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import {
    Address, AddressService, Consumer, Member, MemberAccount, MemberAccountService, MemberAccountStatus, MemberStatus, MemberType, PagedResponse, RiskTier, SearchService, Utils,
    WorkflowService
} from 'projects/services/src/public-api';
import { EntitySearchType } from '../search-header/search-header.component';

@Component({
    selector: 'pt-member-search-results',
    templateUrl: './member-search-results.component.html',
    styleUrls: ['./member-search-results.component.scss']
})
export class MemberSearchResultsComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

    EntitySearchType = EntitySearchType;
    MemberStatus = MemberStatus;
    MemberType = MemberType;
    RiskTier = RiskTier;
    Utils = Utils;

    @Input() searchType: EntitySearchType;
    @Input() searchTerm: string;

    filterEvent: EventEmitter<null> = new EventEmitter<null>();
    isLoadingResults = true;
    resultsLength = 0;
    subscription: any;
    pageTracking: PageTracking;
    defaultSize = 10;
    displayedColumns = ['business_name', 'contact_info', 'functions', 'status', 'created'];
    members: Member[] = [];

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private addressService: AddressService,
                private workflowService: WorkflowService,
                private searchService: SearchService,
                private memberAccountService: MemberAccountService,
                private cdr: ChangeDetectorRef) { }

    ngOnInit() {
        if (this.searchType !== EntitySearchType.MEMBER) {
            this.displayedColumns.splice(this.displayedColumns.indexOf('functions'), 1);
        }
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'sortName', 'asc', this.defaultSize);
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.searchTerm && this.pageTracking) {
            this.filterEvent.emit();
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.paginator.page, this.filterEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                let memberTypes: MemberType[] = [];
                if (this.searchType === EntitySearchType.MEMBER) {
                    memberTypes.push(MemberType.BUSINESS);
                } else if (this.searchType === EntitySearchType.CONSUMER) {
                    memberTypes.push(MemberType.CONSUMER);
                } else {
                    memberTypes.push(MemberType.BUSINESS_MERCHANT);
                    memberTypes.push(MemberType.INDIVIDUAL_MERCHANT);
                }
                return this.searchService.searchMembersBySearchTerm(this.searchTerm, memberTypes,
                    this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<Member>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((response: Member[]) => {
            this.members = response;
            for (const member of this.members) {
                this.loadMember(member);
            }
        });
    }

    loadMember(member: Member) {
        this.addressService.getPrimaryAddress(member.id).subscribe((location: Address) => {
            member.address = location;
        });
    }

    memberDetails(event: any, member: Member) {
        if (event.target && event.target.tagName !== 'I') {
            if (member.memberType === MemberType.INDIVIDUAL_MERCHANT || member.memberType === MemberType.BUSINESS_MERCHANT) {
                if (member.status === MemberStatus.UNDER_REVIEW) {
                    this.workflowService.loadTaskByProcessDefinitionWithVariables(['register_business_merchant_v2', 'register_individual_merchant_v2'], `merchantMemberId_eq_${member.id}`);
                } else {
                    const merchantType = member.memberType === MemberType.INDIVIDUAL_MERCHANT ? 'individual' : 'merchant';
                    const activeTab = member.memberType === MemberType.INDIVIDUAL_MERCHANT ? 'individual' : 'business';
                    this.router.navigate([`/administration/${merchantType}/`, member.id], {queryParams: {_activeTab: activeTab}});
                }
            } else {
                if (member.status === MemberStatus.UNDER_REVIEW) {
                    this.memberAccountService.getAllMemberBusinessAccounts(member.id).subscribe((result: PagedResponse<MemberAccount>) => {
                        // FIXME: clean this up once all old onboarding flows are gone
                        const isLegacyOnboarding = result.content.find((memberAccount) => {
                            // legacy flows have an account that is UNDER_REVIEW with an account number already assigned
                            return memberAccount.status === MemberAccountStatus.UNDER_REVIEW && memberAccount.accountNumber;
                        });
                        const isAccountPending = result.content.find((memberAccount) => {
                            // members that have been approved, but the account has not have
                            return memberAccount.status === MemberAccountStatus.PENDING || memberAccount.status === MemberAccountStatus.UNDER_REVIEW;
                        });
                        if (isLegacyOnboarding) {
                            // open the old workflow application
                            this.workflowService.loadTaskByProcessDefinitionWithVariables(['onboard_pt_member'], `memberId_eq_${member.id}`);
                        } else if (isAccountPending) {
                            // the application has been approved, but the account not approved yet
                            this.workflowService.loadTaskByProcessDefinitionWithVariables(['register_member_account_v2'], `memberId_eq_${member.id}`);
                        } else {
                            // we have a new application that has not yet been approved
                            this.workflowService.loadTaskByProcessDefinitionWithVariables(['register_member_v2'], `memberId_eq_${member.id}`);
                        }
                    });
                } else {
                    this.router.navigate(['/administration/member/', member.id]);
                }
            }
        }
    }

    getConsumerName(member: Member) {
        return (member as Consumer).lastName + ', ' + (member as Consumer).firstName;
    }

    getNameHeader() {
        if (this.searchType === EntitySearchType.CONSUMER) {
            return 'Consumer';
        } else if (this.searchType === EntitySearchType.MERCHANT) {
            return 'Merchant';
        }
        return 'Member';
    }
}
