<fieldset class="form-group" *ngIf="formCtrl">
    <div class="row">
        <div class="col-12" [formGroup]="formGroup">
            <label class="form-label">{{ label | translate }}</label>
        </div>
        <div class="inputGroup col-12" [formGroup]="formGroup">
            <input #corporateKeyPartySelect [formControl]="autoCompleteControl" type="text" [placeholder]="placeholder"
                class="form-control form-input-editable" [matAutocomplete]="auto" (keydown)="onInputChanged($event)" (click)="$event.target.select()">
            <i class="fa" [ngClass]="selected() ? 'fa-times-circle-o' : 'fa-chevron-down'" (click)="onIconClick($event)"></i>
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" >
                <mat-option [ngClass]="searching ? 'hidden' : 'corporate-key-party-entry'" *ngFor="let corporateKeyParty of corporateKeyPartyAutoComplete | async;"
                        [disabled]="keyPartyExists(corporateKeyParty.id)" (onSelectionChange)="corporateKeyPartyChanged(corporateKeyParty)">
                    <div class="row">
                        <div class="col-8">
                            {{ getCorporateKeyPartyName(corporateKeyParty) }}
                        </div>
                        <div class="col-4 text-end" *ngIf="corporateKeyParty.taxId">
                            EIN: {{ corporateKeyParty.taxId }}
                        </div>
                    </div>
                    <div *ngIf="corporateKeyParty?.address" class="row address-info">
                        <div class="col-8">{{ (corporateKeyParty.address.streetAddressOne || "") + " " +  (corporateKeyParty.address.streetAddressTwo || "")}}</div>
                        <div class="col-4 text-end">{{ (corporateKeyParty.address.city ? corporateKeyParty.address.city + ", " : "") + (corporateKeyParty.address.stateProvince || "") }}</div>
                    </div>
                </mat-option>
                <mat-option *ngIf="searching">
                    <i class="fa fa-spin fa-spinner"></i>Searching
                </mat-option>
                <mat-option *ngIf="!searching && totalResults > searchLimit" [disabled]="true">
                    There are {{totalResults - searchLimit}} additional results - please refine your search.
                </mat-option>
            </mat-autocomplete>
        </div>
    </div>
</fieldset>
