
<div *ngIf="paymentProcessorAccounts.length > 0" class="dashboard-list">
    <div class="row">
        <div class="col-8">
            <div class="d-flex align-items-center">
                <img class="dashboard-icon" src="assets/images/compliance-icon.svg" alt="">
                <a href="">Nacha Returns</a>
                <div class="ms-3 dashboard-list-summary"><strong>(60 Days Preceding)</strong></div>
            </div>
        </div>
    </div>
    <hr class="separator-thick">
    <div *ngFor="let element of nachaComplianceData; let isLast = last">
        <div class="row text-center align-items-center row mb-2  pt-2 justify-content-between">
            <div class="col-2 account-name">
                <a [routerLink]="['/member-accounts/details/' + element.memberAccountId]" [routerLinkActive]="['router-link-active']"> {{ element.memberAccountName }} </a>
            </div>
            <div class="col-4">
                <div class="return-type gray-shade"><strong>Administrative</strong></div>
                <div class="return-percentage gray-shade"><strong>{{ calculateReturnPercentage(element?.administrativeReturnsCount, element?.totalTransactionsCount) | number:'1.2-2' }}%</strong>
                    <div class="nacha-returns-count gray-shade"><strong>{{ 'Total: ' + element?.administrativeReturnsCount }}</strong></div>
                </div>
            </div>
            <div class="col-3">
                <div class="return-type gray-shade"><strong>Unauthorized</strong></div>
                <div class="return-percentage gray-shade"><strong>{{ calculateReturnPercentage(element?.unauthorizedReturnsCount, element?.totalTransactionsCount) | number:'1.2-2' }}%</strong>
                    <div class="nacha-returns-count gray-shade"><strong>{{ 'Total: ' + element?.unauthorizedReturnsCount }}</strong></div>
                </div>
            </div>
            <div class="col-3">
                <div class="return-type gray-shade"><strong>Overall</strong></div>
                <div class="return-percentage gray-shade"><strong>{{ calculateReturnPercentage(element?.overallReturnsCount, element?.totalTransactionsCount) | number:'1.2-2' }}%</strong>
                    <div class="nacha-returns-count gray-shade"><strong>{{ 'Total: ' + element?.overallReturnsCount }}</strong></div>
                </div>
            </div>
        </div>
        <hr *ngIf="!isLast"/>
    </div>
</div>
