import { Component, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { AuthService, Member, Profile, UserAccountService } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-session-refresh',
    templateUrl: './session-refresh.component.html',
    styleUrls: ['./session-refresh.component.scss']
})
export class SessionRefreshComponent {

    constructor(private bottomSheetRef: MatBottomSheetRef<SessionRefreshComponent>,
                public authService: AuthService,
                private userAccountService: UserAccountService,
                @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
    }

    close() {
        this.bottomSheetRef.dismiss();
    }

    refreshSession() {
        this.data.refresh().subscribe((profile: Profile) => {
            this.userAccountService.getMembersAvailableForLogin(profile.userId).subscribe((members: Member[]) => {
                if (members.length > 1) {
                    this.authService.setMultiMember();
                }
            });
            this.bottomSheetRef.dismiss();
        },
        (error: any) => {
            throw error;
        });
    }

}
