import { Component, Input, OnInit } from '@angular/core';
import {
    AuthService, CorporateEntity, Member, MemberAccount, MemberAccountService, MemberAccountStatus, MemberAccountType, MemberMergeService, MemberRegistrationType,
    MemberService, MemberStatus, MemberType, PagedResponse, WalletFunction
} from 'projects/services/src/public-api';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NewMemberAccountModalComponent } from '../new-member-account-modal/new-member-account-modal.component';
import { MemberMergeModalComponent, TransferFundsModalComponent } from 'projects/components/src/lib/modal';
import { Router } from '@angular/router';

@Component({
    selector: 'pt-member-accounts-list',
    templateUrl: './member-accounts-list.component.html'
})
export class MemberAccountsListComponent implements OnInit {

    MemberType = MemberType;
    MemberStatus = MemberStatus;
    MemberRegistrationType = MemberRegistrationType;

    @Input() memberId: string;
    @Input() authorityView = false;

    member: Member;
    memberAccounts: MemberAccount[] = [];
    activeBusinessAccounts: MemberAccount[] = [];
    /**
     * @deprecated remove after all member accounts have been transferred to their corporate entity
     */
    relatedMembers: CorporateEntity[] = [];
    changeTrigger = 1;

    constructor(public authService: AuthService,
                private memberService: MemberService,
                private memberAccountService: MemberAccountService,
                private memberMergeService: MemberMergeService,
                private router: Router,
                private dialog: MatDialog) {}

    ngOnInit() {
        this.memberService.loadMember(this.memberId).subscribe((member: Member) => {
            this.member = member;
            const memberAccountTypes: MemberAccountType[] = [];
            if (member.memberType === MemberType.BUSINESS || member.memberType === MemberType.FEE_ACCOUNT) {
                memberAccountTypes.push(MemberAccountType.BUSINESS);
            } else if (member.memberType === MemberType.CONSUMER) {
                memberAccountTypes.push(MemberAccountType.CONSUMER);
            }
            if (this.authService.isAuthorityOrReviewer()) {
                memberAccountTypes.push(MemberAccountType.MERCHANT);
            }
            let statuses = [];
            if (this.authService.isCorporateAdmin()) {
                statuses = [MemberAccountStatus.ACTIVE, MemberAccountStatus.PENDING, MemberAccountStatus.UNDER_REVIEW];
            }
            this.memberAccountService.getMemberAccountsByTypesAndStatus(this.memberId, memberAccountTypes, statuses, 0, -1, 'accountType', 'ASC').subscribe((memberAccounts: PagedResponse<MemberAccount>) => {
                this.memberAccounts = memberAccounts.content;
                this.memberAccounts.sort((a, b) => {
                    if (a.accountType === b.accountType) {
                        if (a.status === b.status && a.status === MemberAccountStatus.ACTIVE) {
                            if (a.accountNumber < b.accountNumber) {
                                return -1;
                            } else {
                                return 1;
                            }
                        }
                        if (a.status === MemberAccountStatus.ACTIVE) {
                            return -1;
                        } else if (b.status === MemberAccountStatus.ACTIVE) {
                            return 1;
                        }
                    } else {
                        if (a.accountType === MemberAccountType.BUSINESS) {
                            return -1;
                        } else if (b.accountType === MemberAccountType.BUSINESS) {
                            return 1;
                        }
                    }
                    return 0;
                });
                this.activeBusinessAccounts = this.memberAccounts.filter((account) => {
                    return account.accountType === MemberAccountType.BUSINESS && account.status === MemberAccountStatus.ACTIVE && this.authService.isAuthorityOrAdmin(account)
                        && !this.memberAccountService.hasWalletFunction(account, WalletFunction.ACH_BALANCE_PUSH);
                });
            });
            if (this.member.memberType === MemberType.BUSINESS && this.authService.isAuthoritySuperUser()) {
                this.memberMergeService.getRelatedMembers(this.memberId).subscribe((relatedMembers: CorporateEntity[]) => {
                    this.relatedMembers = relatedMembers;
                });
            }
        });
    }

    onAddAccount() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.data = {
            memberId: this.memberId
        };
        const dialog = this.dialog.open(NewMemberAccountModalComponent, dialogConfig);
        dialog?.afterClosed().subscribe((refresh: boolean) => {
            if (refresh) {
                this.ngOnInit();
            }
        });
    }

    onTransferFunds() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.data = {
            activeBusinessAccounts: this.activeBusinessAccounts
        };
        const dialog = this.dialog.open(TransferFundsModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((refresh: boolean) => {
            if (refresh) {
                this.changeTrigger = Math.random();
            }
        });
    }

    /**
     * @deprecated remove after all member accounts have been transferred to their corporate entity
     */
    canTransferAccounts() {
        if (this.member?.status !== MemberStatus.ACTIVE) {
            return false;
        } else {
            for (const memberAccount of this.memberAccounts) {
                if (memberAccount.status === MemberAccountStatus.PENDING) {
                    return false;
                }
            }
        }
        return true;
    }

    /**
     * @deprecated remove after all member accounts have been transferred to their corporate entity
     */
    onTransferAccounts() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.data = {
            relatedMembers: this.relatedMembers,
            sourceMemberId: this.memberId,
            sourceMemberName: this.member.name
        };
        const dialog = this.dialog.open(MemberMergeModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((targetMemberId: string) => {
            if (targetMemberId) {
                this.router.navigate(['/administration/member/', targetMemberId]);
            }
        });
    }

    showMemberDetails(memberType: MemberType) {
        return memberType === MemberType.BUSINESS || memberType === MemberType.CONSUMER || memberType === MemberType.BUSINESS_MERCHANT
            || memberType === MemberType.INDIVIDUAL_MERCHANT;
    }

    isDisabledOrBlockedOrRejected(status: MemberStatus) {
        return status === MemberStatus.DISABLED || status === MemberStatus.BLOCKED || status === MemberStatus.REJECTED;
    }

    onAccountStatusChange() {
        this.ngOnInit();
    }
}
