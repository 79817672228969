import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { TransactionRoutingModule } from './transaction-routing.module';
import { SharedComponentsModule } from 'projects/components/src/lib/shared-components.module';

import { TransactionListComponent, TransactionDetailsComponent, TransactionTableHeaderComponent, TransactionCompletionComponent, TransactionLineItemsListComponent, AccountActivityDetailsComponent, TransactionTrackingSystemEvidenceComponent, WatchlistSearchDetailsComponent } from './components';
import { HomeModule } from '../home/home.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QrTrackingModalComponent } from '../components';
import { SharedSmartContractsComponentsModule } from 'projects/smart-contract-components/src/public-api';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import {
    OutStandingCheckDetailsComponent
} from './components/outstanding-check-transaction-list/outstanding-check-transaction-list.component';

@NgModule({
    imports: [
        CommonModule,
        TransactionRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        SharedComponentsModule,
        TranslateModule,
        HomeModule,
        NgbModule,
        MatTableModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatSortModule,
        SharedSmartContractsComponentsModule,
        MatButtonToggleModule,
        MatIconModule,
        MatTooltipModule,
        MatBadgeModule
    ],

    declarations: [
        TransactionListComponent,
        TransactionTableHeaderComponent,
        TransactionDetailsComponent,
        AccountActivityDetailsComponent,
        QrTrackingModalComponent,
        TransactionCompletionComponent,
        TransactionLineItemsListComponent,
        TransactionTrackingSystemEvidenceComponent,
        WatchlistSearchDetailsComponent,
        OutStandingCheckDetailsComponent
    ],

    exports: [
        TransactionCompletionComponent,
        TransactionDetailsComponent,
        AccountActivityDetailsComponent,
        TransactionTableHeaderComponent,
        TransactionLineItemsListComponent,
        WatchlistSearchDetailsComponent,
        OutStandingCheckDetailsComponent
    ],

    providers: [
    ]
})
export class TransactionModule {
}
