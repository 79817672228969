import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { Observable, merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { AddressService, AuthService, MemberAccountStatus, MemberType, MerchantAccountConfig, MerchantAccountService, MerchantAccountStatus, PagedResponse, RiskTier, Utils, WorkflowService } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-merchant-list',
    templateUrl: './merchant-list.component.html',
    styleUrls: ['./merchant-list.component.scss']
})
export class MerchantListComponent implements OnInit, OnChanges, AfterViewInit {

    MemberAccountStatus = MemberAccountStatus;
    MemberType = MemberType;
    RiskTier = RiskTier;
    Utils = Utils;

    @Input() filter: string;
    @Input() changeTrigger: number;
    @Input() memberId = '';

    isLoadingResults = true;
    displayedColumns = ['business_name', 'contact_info', 'transaction_types', 'notifications', 'status', 'action'];
    pageTracking: PageTracking;
    resultsLength = 0;
    filterEvent: EventEmitter<null> = new EventEmitter<null>();

    merchantObservable: Observable<Array<MerchantAccountConfig>>;

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private route: ActivatedRoute,
                private addressService: AddressService,
                private merchantAccountService: MerchantAccountService,
                private router: Router,
                private workflowService: WorkflowService,
                public authService: AuthService,
                private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'merchantMemberAccount.member.sortName', 'asc');
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.changeTrigger && !changes.changeTrigger.firstChange) ||
            (changes.memberId && !changes.memberId.firstChange)) {
            this.filterEvent.emit();
        }
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.merchantObservable = merge(this.sort.sortChange, this.paginator.page, this.filterEvent).pipe(
            startWith({}),
            switchMap(() => {
                UIUtils.scrollToTop(document.querySelector('.mat-table-container'));
                this.isLoadingResults = true;
                return this.merchantAccountService.getMerchantAccountConfigsByMerchantMemberId(this.memberId,
                    this.paginator.pageIndex,
                    this.paginator.pageSize,
                    this.sort.active,
                    this.sort.direction);
            }),
            map((response: PagedResponse<MerchantAccountConfig>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        );
    }

    merchantDetails(event: any, memberId: string, status: MemberAccountStatus, memberType: MemberType) {
        if (event.target && event.target.tagName !== 'I') {
            if (status === MemberAccountStatus.UNDER_REVIEW) {
                // open the application this only works if the task is on the authority
                this.workflowService.loadTaskByProcessDefinitionWithVariables(['register_business_merchant_v2', 'register_individual_merchant_v2'], `merchantMemberId_eq_${memberId}`);
            } else if (memberType === MemberType.INDIVIDUAL_MERCHANT) {
                // go see the application or user view
                this.router.navigate(['/administration/individual/', memberId], {queryParams: {_activeTab: 'individual'}});
            } else {
                this.router.navigate(['/administration/merchant/', memberId], {queryParams: {_activeTab: 'business'}});
            }
        }
    }

    isEnabled(merchantConfig: MerchantAccountConfig) {
        return merchantConfig && merchantConfig.status !== MerchantAccountStatus.DISABLED && merchantConfig.status !== MerchantAccountStatus.REJECTED;
    }

    async toggleEnabled(event: any, merchantConfig: MerchantAccountConfig) {
        this.isLoadingResults = true;
        const updates = {
            status: this.isEnabled(merchantConfig) ? MemberAccountStatus.DISABLED : MemberAccountStatus.ACTIVE
        };
        await this.merchantAccountService.updateMerchantAccountConfig(merchantConfig.id, updates).subscribe(() => {
            this.addTableLoadListener();
        });
        event.cancelBubble = true;
        if (event.stopImmediatePropagation) {
            event.stopImmediatePropagation();
        }
    }
}
