import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import hmacSHA256 from 'crypto-js/hmac-sha256';

@Injectable({
    providedIn: 'root'
})
export class HttpHeaderService {

    getAuthHeader(): any;

    getAuthHeader(...args: any[]): HttpHeaders {
        if (args.length === 1) {
            return new HttpHeaders().set('Authorization', args[0]);
        }
        if (localStorage.getItem('token')) {
            let headers = new HttpHeaders()
                .set('Authorization', `${localStorage.getItem('token')}`);
            const profileString = localStorage.getItem('profile');
            if (profileString) {
                const profile = JSON.parse(profileString);
                let accessString = '';
                profile.access.forEach((access) => {
                    if (accessString.length) {
                        accessString += ',';
                    }
                    accessString += access.memberAccountId || '';
                    accessString += ',';
                    accessString += access.role;
                });
                const toHash = `${profile.memberId}${profile.userId}${profile.role}${profile.memberType}${profile.retailAccountId || ''}${profile.retailLocationId || ''}` +
                    `${profile.authorities.join(',')}${profile.memberFunctions.join(',')}${accessString}`;
                const hmac = hmacSHA256(toHash, profile.nonce);
                headers = headers.set('X-HMAC', `${hmac}`);
            }
            return headers;
        }
        return new HttpHeaders();
    }

}
