import { WalletFunction } from './member-account';
import { MemberFunction, MemberType } from './member';
import {PaymentRail, TransactionSource, TransactionState, TransactionType} from './transaction';

export class TransactionTableFilter {

    memberId: string;
    memberAccountId: string;
    memberType: MemberType;
    memberFunctions: MemberFunction[];
    walletFunctions: WalletFunction[];
    counterParty: string;
    transactionState: TransactionState;
    paymentRails: PaymentRail[];
    transactionSources: TransactionSource[];
    transactionTypeFilter: TransactionTableTypeFilter;
    returnCode: TransactionTableReturnCodeFilter;
    startDate: string;
    endDate: string;
    retailLocationId: string;
    minAmount: string;
    maxAmount: string;
    transactionTab: string;

    constructor(memberId?: string) {
        this.memberId = memberId || '';
        this.memberAccountId = '';
        this.memberType = MemberType.ALL;
        this.memberFunctions = [];
        this.walletFunctions = [];
        this.paymentRails = [];
        this.counterParty = '';
        this.transactionState = TransactionState.ALL;
        this.transactionSources = [];
        this.transactionTypeFilter = TransactionTableTypeFilter.ALL_TYPES;
        this.startDate = '';
        this.endDate = '';
        this.retailLocationId = '';
        this.minAmount = '';
        this.maxAmount = '';
        this.transactionTab = '';
    }
}

export enum TransactionTableTypeFilter {
    ALL_TYPES = 'ALL_TYPES',
    ALL_NON_RETAIL = 'ALL_NON_RETAIL',
    RETAIL_ONLY = 'RETAIL_ONLY',
    CONFIA_PAYMENT = 'CONFIA_PAYMENT',
    ACH = 'ACH',
    WIRE = 'WIRE',
    CHECK = 'CHECK',
    CASH = 'CASH',
    ADJUSTMENT = 'ADJUSTMENT',
    FEE = 'FEE'
}

export enum TransactionTableReturnCodeFilter {
    ALL_ACH_TYPES = 'ALL_ACH_TYPES',
    NON_RETURNS = 'NON_RETURNS',
    ALL_RETURNS = 'ALL_RETURNS',
    ALL_ADMINISTRATIVE = 'ALL_ADMINISTRATIVE',
    ALL_UNAUTHORIZED = 'ALL_UNAUTHORIZED',
    R01 = 'R01',
    R02 = 'R02',
    R03 = 'R03',
    R04 = 'R04',
    R05 = 'R05',
    R06 = 'R06',
    R07 = 'R07',
    R08 = 'R08',
    R09 = 'R09',
    R10 = 'R10',
    R16 = 'R16',
    R21 = 'R21',
    R23 = 'R23',
    R29 = 'R29',
    R51 = 'R51'
}
