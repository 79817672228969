import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    Alert, AlertStatus, AlertType, AuthService, CommentService, MemberAccount, MemberAccountService, PaymentService, Transaction, TransactionSubType,
    TransactionTableFilter, TransactionType, TransactionSource
} from 'projects/services/src/public-api';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertsModalComponent } from 'projects/components/src/lib/modal';
import { TableUtils } from 'projects/components/src/lib/table-utils.service';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'pt-alert-details',
    templateUrl: './alert-details.component.html',
    styleUrls: ['./alert-details.component.scss']
})
export class AlertDetailsComponent implements OnInit {

    TransactionSubType = TransactionSubType;
    AlertStatus = AlertStatus;
    AlertType = AlertType;
    TransactionType = TransactionType;
    TransactionSource = TransactionSource;

    @Input() activeTab = 'TRANSACTION_DETAILS';

    filter: TransactionTableFilter;
    transaction: Transaction;
    alert: Alert;
    alertId: string;
    involvedAccount: MemberAccount;
    changeTrigger = 1;


    constructor(private route: ActivatedRoute,
                private router: Router,
                public authService: AuthService,
                private paymentService: PaymentService,
                private memberAccountService: MemberAccountService,
                private alertService: CommentService,
                private datePipe: DatePipe,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        const queryParams = this.route.snapshot.queryParams;
        const params = this.route.snapshot.params;
        if (queryParams['_activeTab']) {
            this.activeTab = queryParams['_activeTab'];
        } else {
            this.activeTab = 'TRANSACTION_DETAILS';
            this.updateQueryParams(true);
        }
        if (params['alertId']) {
            this.alertId = params['alertId'];
            this.loadAlert();
        }
    }

    loadAlert() {
        this.alertService.getAlert(this.alertId).subscribe((alert: Alert) => {
            this.alert = alert;
            if (this.alert) {
                if (this.alert?.type !== AlertType.TRANSACTION_FILE_PROCESSING &&
                    (this.alert.type === AlertType.TRANSACTION || this.alert.type === AlertType.CURRENCY_WITHDRAWAL) &&
                    this.alert?.referencedEntityId) {
                    this.loadTransaction();
                } else {
                    this.alert.jsonData = JSON.parse(alert.jsonData);
                }
            }
        });
    }

    loadTransaction() {
        this.paymentService.getTransaction(this.alert.referencedEntityId).subscribe((transaction: Transaction) => {
            this.transaction = transaction;
            this.changeTrigger = Math.random();
            this.memberAccountService.loadMemberAccount(transaction.originatorAccountId).subscribe((memberAccount: MemberAccount) => {
                this.involvedAccount = memberAccount;
            });
            this.filter = new TransactionTableFilter(transaction.payorMemberId);
            let date = new Date(this.alert.created);
            this.filter.endDate = this.datePipe.transform(date, 'yyyy-MM-dd');
            date.setDate(date.getDate() - 30);
            this.filter.startDate = this.datePipe.transform(date, 'yyyy-MM-dd');
            this.paymentService.loadTransaction(this.transaction, true);
        });
    }

    getTabClass(tabOption: string) {
        if (this.activeTab === tabOption) {
            return 'tab-navigation-selected';
        } else {
            return 'tab-navigation';
        }
    }

    onSwitchTabs(tabOption: string) {
        this.activeTab = tabOption;
        this.updateQueryParams(false);
    }

    updateQueryParams(replace: boolean) {
        const queryParams = {_activeTab: this.activeTab, page: null, num: null, sort: null, dir: null};
        TableUtils.updateQueryParams(this.route, this.router, queryParams, replace);
    }

    reviewAlert(action: boolean, event: MouseEvent) {
        event.cancelBubble = true;
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.disableClose = true;
        dialogConfig.data = {
            alert: this.alert,
            action
        };
        const dialog = this.dialog.open(AlertsModalComponent, dialogConfig);
        dialog?.afterClosed().subscribe(
            (refresh: boolean) => {
                if (refresh) {
                    this.loadAlert();
                }
            }
        );
    }

    isAuthorityOrReviewer() {
        return this.authService.isAuthorityOrReviewer();
    }

    isAuthority() {
        return this.authService.isAuthority();
    }
}
