import {AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import { Configuration } from '../configuration/configuration.service';

export class CustomValidators {

    public static readonly UPPERCASE_REGEX = /[A-Z]+/;
    public static readonly LOWERCASE_REGEX = /[a-z]+/;
    public static readonly NUMBER_REGEX = /[0-9]+/;
    public static readonly ONLY_NUMBER_REGEX = /^[0-9]+$/;
    public static readonly SPECIAL_REGEX = /[\^$*.\[\]{}\(\)?"!@#%&\/,><\':;|_~]/;
    public static readonly ZIP_CODE_REGEXP = /^(\d{5}(-\d{4})?|[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ] ?\d[ABCEGHJKLMNPRSTVWXYZ]\d|\d{5})$/g;
    public static readonly PASTABLE_PHONE_REGEXP = /^\s*\(?\s*[0-9]{3}\s*\)?\s*-?\s*[0-9]{3}\s*-?\s*[0-9]{4}$/;
    // PT-2203: removing %&'/?`{} from email addresses until we can do a pass to allow those characters in URLs as escaped
    static readonly EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@\"%&'\/?`{}]+(\.[^<>()[\]\\.,;:\s@\"%&'\/?`{}]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
    // PT-2203: original email regex
    // static readonly EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
    static readonly POSTAL_CODE_CA_REGEXP = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/g;
    static readonly PERSON_NAME_REGEXP = /^[a-zA-Z'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/g;
    static readonly MIDDLE_NAME_REGEXP = /^[a-zA-Z'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{0,}$/g;
    static readonly MEMBER_NAME_REGEXP = /^[\w'\-,.][^_¡?÷?¿=$%ˆ*{}|~<>;:[\]]{1,}$/g;
    static readonly PHONE_REGEXP = /^[2-9][0-9]{9}$/;
    static readonly NO_WHITE_SPACES = /^\S*$/;
    static readonly NO_LEADING_OR_TRAILING_WHITEPACE = /^[^\s]+([ ]{1,2}[^\s]+)*[ ]{0,2}$/m;
    // static readonly NO_LEADING_OR_TRAILING_WHITEPACE = /^[^\s]+([ ]{1,2}[^\s]+)*$/;
    static readonly ABSOLUTE_URI_SPACES = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
    static readonly BIRTHDATE_REGEXP = /^([1-9][0-9]{3})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])$/;
    static readonly ONLY_SPACES  = /\s+/g;
    static readonly ONLY_TWO_DECIMALS = /^\d+(?:\.\d{1,2})?%?$/;

    static numeric(control: AbstractControl): ValidationErrors | null {
        const input = control.value;
        if (!input || (input && input.match(CustomValidators.NUMBER_REGEX))) {
            return null;
        }
        return { valueInvalid: true };
    }

    static noLeadingOrTrailingWhitespace(control: AbstractControl) {
        const input = control.value;
        if (!input || (input && input.match(CustomValidators.NO_LEADING_OR_TRAILING_WHITEPACE))) {
            return null;
        }
        return { valueInvalid: true };
    }

    static onlyNumeric(control: AbstractControl): ValidationErrors | null {
        const input = CustomValidators.stringConverter(control.value);
        if (!input || (input && input.match(CustomValidators.ONLY_NUMBER_REGEX))) {
            return null;
        }
        return { valueInvalid: true };
    }

    static onlyTwoDecimals(control: AbstractControl): ValidationErrors | null {
        const input = CustomValidators.stringConverter(control.value);
        if (!input || (input && input.match(CustomValidators.ONLY_TWO_DECIMALS))) {
            return null;
        }
        return { valueInvalid: true };
    }

    static stringConverter(value: any): string {
        if (Object.prototype.toString.call(value).slice(8, -1) === 'Number') {
            value = String(value);
        }
        return value;
    }

    static email(control: AbstractControl): ValidationErrors | null {
        const email = control.value;
        if (!email || (email && email.match(CustomValidators.EMAIL_REGEXP))) {
            return null;
        }
        return { emailInvalid: true };
    }

    static phone(control: AbstractControl): ValidationErrors | null {
        const phone = control.value;
        if (!phone || (phone && phone.match(CustomValidators.PHONE_REGEXP))) {
            return null;
        }
        return { phoneInvalid: true };
    }

    static memberName(control: AbstractControl): ValidationErrors | null {
        const name = control.value;
        let trimmedInput = control.value?.replace(CustomValidators.ONLY_SPACES, '');
        if (!name || (name && name.match(CustomValidators.MEMBER_NAME_REGEXP) && name.match(CustomValidators.NO_LEADING_OR_TRAILING_WHITEPACE) && trimmedInput?.length >= 2)) {
            return null;
        }
        return { nameInvalid: true };
    }

    static personName(control: AbstractControl): ValidationErrors | null {
        const name = control.value;
        let trimmedInput = control.value?.replace(CustomValidators.ONLY_SPACES, '');
        if (!name || (name && name.match(CustomValidators.PERSON_NAME_REGEXP) && name.match(CustomValidators.NO_LEADING_OR_TRAILING_WHITEPACE) && name.match() && trimmedInput?.length >= 2)) {
            return null;
        }
        return { nameInvalid: true };
    }

    static middleName(control: AbstractControl): ValidationErrors | null {
        const name = control.value;
        if (!name || (name && name.match(CustomValidators.MIDDLE_NAME_REGEXP) && name.match(CustomValidators.NO_LEADING_OR_TRAILING_WHITEPACE))) {
            return null;
        }
        return { nameInvalid: true };
    }

    static zipCode(control: AbstractControl): ValidationErrors | null {
        const zipCode = control.value;
        if (!zipCode || (zipCode && zipCode.match(CustomValidators.ZIP_CODE_REGEXP))) {
            return null;
        }
        return { zipCodeInvalid: true };
    }

    static postalCodeCA(control: AbstractControl): ValidationErrors | null {
        const zipCode = control.value;
        if (!zipCode || (zipCode && zipCode.match(CustomValidators.POSTAL_CODE_CA_REGEXP))) {
            return null;
        }
        return { zipCodeInvalid: true };
    }

    static noWhitespace(control: AbstractControl) : ValidationErrors | null {
        const noWhitespace = control.value;
        if (!noWhitespace || (noWhitespace && noWhitespace.match(CustomValidators.NO_WHITE_SPACES))) {
            return null;
        }
        return { noWhitespace: true };
    }

    static password(control: AbstractControl): ValidationErrors | null {
        const password = control.value;
        if (!password || (password && password.match(CustomValidators.UPPERCASE_REGEX)
            && password.match(CustomValidators.LOWERCASE_REGEX)
            && password.match(CustomValidators.NUMBER_REGEX)
            && password.match(CustomValidators.SPECIAL_REGEX))) {
            return null;
        }
        return { passwordInvalid: true };
    }

    static fromToDate(from: string, to: string) : ValidatorFn {
        return (formGroup: UntypedFormGroup): { [key: string]: boolean } | null => {
            if (!formGroup.controls) {
                return null;
            }
            const f = formGroup.controls[from];
            const t = formGroup.controls[to];
            f.setErrors(null);
            t.setErrors(null);
            if (f.value?.singleDate && t.value?.singleDate) {
                if (f.value?.singleDate?.jsDate && t.value?.singleDate?.jsDate && f.value?.singleDate?.jsDate > t.value?.singleDate?.jsDate) {
                    f.setErrors({incorrect: true});
                    t.setErrors({incorrect: true});
                    return {endDateInvalid: true};
                }
            }
            return null;
        };
    }

    static absoluteURI(control: AbstractControl): ValidationErrors | null {
        const absoluteURI = control.value;
        if (!absoluteURI || (absoluteURI && absoluteURI.match(CustomValidators.ABSOLUTE_URI_SPACES))) {
            return null;
        }
        return { absoluteURIInvalid: true };
    }

    static searchTermValidation(control: AbstractControl): ValidationErrors | null {
        let input = control.value?.replace(CustomValidators.ONLY_SPACES, '');
        if (input?.length > 2 || !control.value) {
            return null;
        }
        return { valueInvalid: true };
    }

    static birthDate(control: AbstractControl): ValidationErrors | null {
        const name = control.value;
        if (!name || (name && name.match(CustomValidators.BIRTHDATE_REGEXP))) {
            return null;
        }
        return { birthDateInvalid: true };
    }

    static validAdminDivision(country: any): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            const countryDefinition = Configuration.getConfig().countries.find((c) => {
                return c.name === country;
            });
            let adminDivisions;
            if (countryDefinition) {
                adminDivisions = countryDefinition.divisions || [];
            } else {
                adminDivisions = [];
            }
            const isValid = adminDivisions.some((div) => {
                return div.name === value;
            });
            return isValid || adminDivisions.length === 0 ? null : {invalidAdminDivision: true};
        };
    }
}
