<fieldset class="form-group" *ngIf="formCtrl">
    <div class="row">
        <div class="col-12" [formGroup]="formGroup">
            <label class="form-label">{{ label | translate }}</label>
        </div>
        <div class="inputGroup col-12" [formGroup]="formGroup">
            <input #individualKeyPartySelect [formControl]="autoCompleteControl" type="text" [placeholder]="placeholder"
                class="form-control searcher form-input-editable" [matAutocomplete]="auto" (keydown)="onInputChanged($event)" (click)="$event.target.select()">
            <i class="fa" [ngClass]="selected() ? 'fa-times-circle-o' : 'fa-chevron-down'" (click)="onIconClick($event)"></i>
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" >
                <mat-option [ngClass]="searching ? 'hidden' : 'individual-key-party-entry'" *ngFor="let individualKeyParty of individualKeyPartyAutoComplete | async;"
                        [disabled]="keyPartyExists(individualKeyParty.id)" (onSelectionChange)="individualKeyPartyChanged(individualKeyParty)">
                    <div class="row">
                        <div class="col-8">
                            {{ individualKeyParty.name }} <span class="email-info" *ngIf="individualKeyParty.email">({{ individualKeyParty.email }})</span>
                        </div>
                        <div class="col-4 text-end" *ngIf="individualKeyParty.taxId">
                            SSN: {{ individualKeyParty.taxId }}
                        </div>
                        <div class="col-4 text-end" *ngIf="!individualKeyParty.taxId && individualKeyParty.passportNumber">
                            Passport #: {{ individualKeyParty.passportNumber }}
                        </div>
                    </div>
                    <div *ngIf="individualKeyParty?.address" class="row address-info">
                        <div class="col-8">{{ (individualKeyParty.address.streetAddressOne || "") + " " +  (individualKeyParty.address.streetAddressTwo || "")}}</div>
                        <div class="col-4 text-end">{{ (individualKeyParty.address.city ? individualKeyParty.address.city + ", " : "") + (individualKeyParty.address.stateProvince || "") }}</div>
                    </div>
                </mat-option>
                <mat-option *ngIf="searching">
                    <i class="fa fa-spin fa-spinner"></i>Searching
                </mat-option>
                <mat-option *ngIf="!searching && totalResults > searchLimit" [disabled]="true">
                    There are {{totalResults - searchLimit}} additional results - please refine your search.
                </mat-option>
            </mat-autocomplete>
        </div>
    </div>
</fieldset>
