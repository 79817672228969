<pc-text-option-selector *ngIf="addressTypeOpts.length > 1" [required]="required" [label]="'Type'" [translated]="true" (selected)="addressTypeChanged($event)"
    [options]="addressTypeOpts" [formCtrl]="'typeCtrl'" [formGroup]="addressForm" [defaultValue]="''">
</pc-text-option-selector>

<pc-text-input *ngIf="showLocationName" [required]="isRetail()" [label]="'Name'" [placeholder]="'required for Retail locations'" [formCtrl]="'nicknameCtrl'" [formGroup]="addressForm">
</pc-text-input>

<pc-form-selector *ngIf="showAccountLink && memberAccountOptions.length && isRetail()" [label]="'Account for Retail Sales'" [formCtrl]="'accountIdCtrl'"
    [formGroup]="addressForm" [options]="memberAccountOptions" [idValue]="'id'" [displayValue]="'accountName'" [defaultValue]="''">
</pc-form-selector>

<pc-auto-complete-selector [required]="required" [label]="'Country'"  [formCtrl]="'countryCtrl'"
                           [formGroup]="addressForm" [options]="countries" [displayValue]="'name'">
</pc-auto-complete-selector>

<pc-auto-complete-street-address [label]="'Street Address'" [placeholder]="'Street Address'"
    [formCtrl]="'streetAddressOneCtrl'" [formGroup]="addressForm" [country]="countryCode" (setAddress)="setAddress($event)" (changed)="updateValidations()">
</pc-auto-complete-street-address>

<pc-text-input *ngIf="isUnitedStates()" [required]="required" [label]="'City'" [placeholder]="'City'" [formCtrl]="'cityCtrl'" [formGroup]="addressForm" (changed)="updateValidations()">
</pc-text-input>

<pc-text-input *ngIf="!isUnitedStates()" [required]="required" [label]="'City or Locality'" [placeholder]="'City or Locality'" [formCtrl]="'cityCtrl'" [formGroup]="addressForm" (changed)="updateValidations()">
</pc-text-input>

<pc-auto-complete-selector *ngIf="adminDivisions.length" [required]="required" [label]="isUnitedStates() ? 'State' : 'Province or Administrative Area'"  [formCtrl]="'stateProvinceCtrl'"
    [formGroup]="addressForm" [options]="adminDivisions" [displayValue]="'name'" (selected)="updateValidations()">
</pc-auto-complete-selector>

<pc-text-input *ngIf="!adminDivisions.length" [required]="required" [label]="isUnitedStates() ? 'State' : 'Province or Administrative Area'"
    [placeholder]="isUnitedStates() ? 'State' : 'Province or Administrative Area'" [formCtrl]="'stateProvinceCtrl'" [formGroup]="addressForm" (changed)="updateValidations()">
</pc-text-input>

<pc-zip-code-input *ngIf="isUnitedStates()" [required]="required" [label]="' Zip Code'" [formCtrl]="'zipPostalCodeCtrl'" [formGroup]="addressForm" (zipCodeChanged)="updateValidations()">
</pc-zip-code-input>

<pc-postal-code-input *ngIf="!isUnitedStates()" [country]="currentCountry" [required]="required" [label]="'Postal Code'" [formCtrl]="'zipPostalCodeCtrl'" [formGroup]="addressForm">
</pc-postal-code-input>
