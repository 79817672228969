import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    ApiAccess, ApiAccessService, ApiAccessStatus, AuthService
} from 'projects/services/src/public-api';

@Component({
    selector: 'pt-api-access-settings',
    templateUrl: './api-access-settings.component.html',
    styleUrls: ['./api-access-settings.component.scss']
})
export class ApiAccessSettingsComponent implements OnInit {

    ApiAccessStatus = ApiAccessStatus;

    @Input() memberId: string;
    @Input() changeTrigger: number;
    @Input() activeTab: string;
    @Input() readOnly = false;

    @Output() navigate: EventEmitter<string> = new EventEmitter<string>();

    keys: ApiAccess[];

    displayedApiKeysColumns: string[] = ['api_key', 'secret', 'issued_date', 'disable'];

    canCreate: boolean;

    constructor(private apiAccessService: ApiAccessService,
                public authService: AuthService) {}

    ngOnInit() {
        this.onCreateKey = this.onCreateKey.bind(this);
        this.canCreateNewKey = this.canCreateNewKey.bind(this);
        if (!this.authService.isAuthorityOrAdmin()) {
            this.displayedApiKeysColumns.splice(this.displayedApiKeysColumns.indexOf('disable'), 1);
        }
        this.loadKeys();
    }

    onDisableKey(apiAccess: ApiAccess) {
        if (!this.readOnly) {
            apiAccess.status = apiAccess.status === ApiAccessStatus.ENABLED ? ApiAccessStatus.DISABLED : ApiAccessStatus.ENABLED;
            this.apiAccessService.updateKey(apiAccess).subscribe(() => {
                this.loadKeys();
            });
        }
    }

    loadKeys() {
        this.apiAccessService.loadKeysByMemberId(this.memberId).subscribe((apiKeys: ApiAccess[]) => {
            this.keys = apiKeys;
            this.canCreate = apiKeys.length < 10 && !this.authService.isAuthority();
        });
    }

    onCreateKey(reset: any) {
        this.apiAccessService.createKey(this.memberId).subscribe((apiAccess: ApiAccess) => {
            apiAccess.copyable = true;
            this.keys = [...this.keys, apiAccess];
            this.canCreate = this.keys.length < 10 && !this.authService.isAuthority();
            reset();
        },
        (_error: any) => {
            reset();
        });
    }

    canCreateNewKey() {
        return (this.canCreate && !this.readOnly);
    }

}
