import { Inject, Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Router, UrlTree } from '@angular/router';
import { catchError, switchMap } from 'rxjs/operators';
import { Transaction, TransactionStatus } from './models/transaction';
import { ContractTemplate } from './models/smart-contracts/contract-template';
import { Contract } from './models/smart-contracts/contract';
import { Comment } from './models/comment';
import { RefundRequest } from './models/refund-request';
import { RfiNotification } from './models/rfi-notification';
import { MerchantNotificationRequest } from './models/merchant-notification-request.model';
import { PagedResponse } from './models/paged-response';
import { Task } from './models/task';
import { DocumentType } from './models/upload';
import { Observable, of } from 'rxjs';
import { CurrencyWithdrawal } from '../public-api';
import { NewUserRequest } from './models/new-user-request';
import { GqlService } from 'projects/services/src/lib/graphql/gql.service';
import { GqlWorkflowTask, gqlProcessDefinitionFields, gqlWorkflowTaskFields } from 'projects/services/src/lib/graphql/queries/workflow/gql-workflow-task';
import { GqlQueryBuilder } from 'projects/services/src/lib/graphql/gql-query.builder';

@Injectable({
    providedIn: 'root'
})
export class WorkflowService {

    baseUrl: string;

    constructor(private http: HttpService,
                private router: Router,
                private gqlService: GqlService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.WorkflowAPIEndpoint + '/';
    }

    depositToWallet(transaction: Transaction) {
        return this.http.post(`${this.baseUrl}transaction/v2/wallet_deposit`, transaction);
    }

    withdrawFromWallet(transaction: Transaction) {
        return this.http.post(`${this.baseUrl}transaction/v2/wallet_withdrawal`, transaction);
    }

    processWalletRefund(transaction: RefundRequest) {
        return this.http.post(`${this.baseUrl}transaction/v2/refund`, transaction);
    }

    recordDeposit(transaction: Transaction) {
        return this.http.post(`${this.baseUrl}transaction/v2/deposit`, transaction);
    }

    makePayment(transaction: Transaction) {
        return this.http.post(`${this.baseUrl}transaction/v2/payment`, transaction);
    }

    recordCurrencyWithdrawal(currencyWithdrawal: CurrencyWithdrawal) {
        return this.http.post(`${this.baseUrl}transaction/v2/currency_withdrawal`, currencyWithdrawal);
    }

    issueCredit(transaction: Transaction) {
        return this.http.post(`${this.baseUrl}transaction/v2/credit`, transaction);
    }

    issueCharge(transaction: Transaction) {
        return this.http.post(`${this.baseUrl}transaction/v2/charge`, transaction);
    }

    returnConfirmed(transactionId: string) {
        return this.http.post(`${this.baseUrl}transaction/v2/return/${transactionId}`, {});
    }

    completeScreening(transactionId: string) {
        return this.http.post(`${this.baseUrl}watchlist/v2/complete/${transactionId}`, {});
    }

    recordNote(comment: Comment) {
        return this.http.post(`${this.baseUrl}member/v2/record_note`, comment);
    }

    createTemplate(createTemplateRequest: ContractTemplate) {
        return this.http.post(`${this.baseUrl}smart-contracts/v2/create_template`, createTemplateRequest);
    }

    shareTemplate(shareTemplateRequest: ContractTemplate) {
        return this.http.post(`${this.baseUrl}smart-contracts/v2/share_template`, shareTemplateRequest);
    }

    createContract(contractRequest: Contract) {
        return this.http.post(`${this.baseUrl}smart-contracts/v2/contract_negotiation`, contractRequest);
    }

    registerBusinessMerchant(registerBusinessMerchantRequest: any, file: File) {
        const formData = new FormData();
        formData.append('registerBusinessMerchantRequest', JSON.stringify(registerBusinessMerchantRequest));
        if (file) {
            const metadata = {
                fileName: file.name,
                documentType: DocumentType.SAMPLE_ACH_INFO,
                explanation: ''
            };
            formData.append('file', file);
            formData.append('metadata', JSON.stringify(metadata));
        }
        return this.http.post(`${this.baseUrl}merchant/v2/register/business-merchant`, formData);
    }

    registerIndividualMerchant(registerIndividualMerchantRequest: any, file: File) {
        const formData = new FormData();
        formData.append('registerIndividualMerchantRequest', JSON.stringify(registerIndividualMerchantRequest));
        if (file) {
            const metadata = {
                fileName: file.name,
                documentType: DocumentType.SAMPLE_ACH_INFO,
                explanation: ''
            };
            formData.append('file', file);
            formData.append('metadata', JSON.stringify(metadata));
        }
        return this.http.post(`${this.baseUrl}merchant/v2/register/individual-merchant`, formData);
    }

    replaceMerchant(replaceMerchantRequest: any) {
        return this.http.post(`${this.baseUrl}merchant/v2/replace`, replaceMerchantRequest);
    }

    resetPassword(passwordResetRequest: any) {
        return this.http.post(`${this.baseUrl}user/v2/reset`, passwordResetRequest);
    }

    getProcessInstance(processInstanceId: string) {
        return this.http.get(`${this.baseUrl}process-instance/${processInstanceId}`);
    }

    deleteProcessInstance(processInstanceId: string) {
        return this.http.delete(`${this.baseUrl}process-instance/${processInstanceId}`);
    }

    getTasksSorted(taskDefinitionKeys: string[], assignee: string, start: number, size: number, sortColumn: string, sortOrder: string) {

        const fields = `${gqlWorkflowTaskFields}
            processDefinition {
                ${gqlProcessDefinitionFields}
            }
        `;

        const query = GqlQueryBuilder.builder(GqlWorkflowTask.searchTasks())
            .withFields(fields)
            .withPageFragmentType(GqlWorkflowTask.gqlWorkflowTaskPageFragment)
            .paginated()
            .build();

        return this.gqlService.query(query, {
            taskDefinitionKeys: taskDefinitionKeys, assignee: assignee, filtered: true,
            start: start, size: size, sort: sortColumn, sortDir: sortOrder
        });
    }

    getTaskCount(assignee?: string) {
        return this.http.head(`${this.baseUrl}task?assignee=${assignee || ''}`);
    }

    getTask(id: string) {
        return this.http.get(`${this.baseUrl}task/${id}`);
    }

    updateAssignee(id: string, body: any) {
        return this.http.post(`${this.baseUrl}task/${id}`, body);
    }

    getCurrentTask(): Task {
        const currentTask = localStorage.getItem('currentTask');
        if (currentTask) {
            return JSON.parse(localStorage.getItem('currentTask'));
        }
        this.router.navigate(['/dashboard']);
        throw new Error('Task is no longer valid.');
    }

    setCurrentTask(response: Task) {
        localStorage.setItem('currentTask', JSON.stringify(response));
        const urlTree: UrlTree = this.router.parseUrl(response.formKey);
        this.router.navigateByUrl(urlTree);
    }

    clearCurrentTask(): any {
        localStorage.removeItem('currentTask');
    }

    loadTask(id: string) {
        this.getTask(id).subscribe((response: Task) => {
            this.setCurrentTask(response);
        });
    }

    completeTask(taskId: string, body: any, failureCallback?: any) {
        return this.getTask(taskId).pipe(
            switchMap((_response: any) => {
                return this.http.post(`${this.baseUrl}task/${taskId}/complete`, body);
            }),
            catchError((error) => {
                // the getTask will return a 404 if not found, all other errors should propagate to the caller
                if (error.status === 404 && error.error === 'No task found.') {
                    if (failureCallback) {
                        failureCallback();
                    } else {
                        this.router.navigate(['/dashboard']);
                    }
                    throw new Error('Task is no longer valid.');
                } else {
                    throw error;
                }
            })
        );
    }

    getTaskByProcessInstanceId(processInstanceId: string, filtered?: boolean) {
        const params = `?processInstanceId=${processInstanceId}&filtered=${filtered ? 'true' : 'false'}`;
        return this.http.get(`${this.baseUrl}task${params}`);
    }

    updateProcessInstanceVariables(processInstanceId: string, updates: any) : Observable<Object> {
        return this.http.put(`${this.baseUrl}process-instance/${processInstanceId}/variables`, updates) as Observable<Object>;
    }

    getProcessDefinition(processDefinitionId: string) {
        return this.http.get(`${this.baseUrl}process-definition/${processDefinitionId}`);
    }

    loadTaskByProcessDefinitionWithVariables(processDefinitionKeys: string[], variable: string) {
        const params = `?processDefinitionKeys=${processDefinitionKeys}&processVariables=${variable}`;
        return this.http.get(`${this.baseUrl}task${params}`).subscribe((response: PagedResponse<Task>) => {
            if (response.content.length > 0) {
                this.setCurrentTask(response.content[0]);
            } else {
                this.router.navigate(['/dashboard']);
                throw new Error('Link is no longer valid');
            }
        });
    }

    getTaskByProcessDefinitionWithVariable(processDefinitionKeys: string[], variableName: string, variableValue: string, filtered?: boolean): Observable<Task> {
        const params = `?processDefinitionKeys=${processDefinitionKeys}&processVariables=${variableName}_eq_${variableValue}&filtered=${filtered ? 'true' : 'false'}&sortBy=created&sortOrder=desc`;
        return this.http.get(`${this.baseUrl}task${params}`).pipe(
            switchMap(
                (tasks: PagedResponse<Task>) => {
                    if (tasks.content.length) {
                        return of(tasks.content[0]);
                    }
                    return of(null);
                }
            ));
    }

    findTaskByTaskDefinitionsAndProcessVariable(taskDefinitionKeys: string[], varName: string, value: string) {
        const params = `?taskDefinitionKeys=${taskDefinitionKeys}&processVariables=${varName}_eq_${value}&sortBy=created&sortOrder=desc`;
        return this.http.get(`${this.baseUrl}task${params}`);
    }

    notifyRfiUpdate(rfiNotification: RfiNotification) {
        return this.http.post(`${this.baseUrl}rfi/v2/notify`, rfiNotification);
    }

    notifyExternalMerchantUpdate(merchantNotificationRequest: MerchantNotificationRequest) {
        return this.http.post(`${this.baseUrl}merchant/v2/notify`, merchantNotificationRequest);
    }

    cancelTransaction(transactionId: string, reason: TransactionStatus, cancellationMessage?: string) {
        return this.http.delete(`${this.baseUrl}transaction/v2/${transactionId}?reason=${reason}&message=${encodeURIComponent(cancellationMessage)}`);
    }

    registerUser(newUserRequest: NewUserRequest) {
        return this.http.post(`${this.baseUrl}user/v2/register`, newUserRequest);
    }

    registerNewMemberAccount(newAccountRequest: any) {
        return this.http.post(`${this.baseUrl}member_accounts/v2/register`, newAccountRequest);
    }

    addWorkflowComment(processInstanceId: string, taskId: string, comment: Comment) {
        return this.http.post(`${this.baseUrl}process-instance/${processInstanceId}/${taskId}/comment`, comment);
    }

    getWorkflowComments(processInstanceId: string) {
        return this.http.get(`${this.baseUrl}process-instance/${processInstanceId}/comments`);
    }

    updateTransactionEntries(transaction: Transaction) {
        return this.http.put(`${this.baseUrl}transaction/v2/line_items`, transaction);
    }
}

export enum TaskCategory {
    ALL = '',
    MEMBER_ONBOARDING = 'MEMBER_ONBOARDING',
    ACCOUNT_ONBOARDING = 'ACCOUNT_ONBOARDING',
    MERCHANT_ONBOARDING = 'MERCHANT_ONBOARDING',
    BANK_ACCOUNT_REVIEW = 'BANK_ACCOUNT_REVIEW',
    RFI_REVIEW = 'RFI_REVIEW',
    WATCHLIST_REVIEW = 'WATCHLIST_REVIEW',
    CHECK_VERIFICATION = 'CHECK_VERIFICATION',
    TRANSACTION_RECONCILIATION = 'TRANSACTION_RECONCILIATION',
    USER_INVITATIONS = 'USER_INVITATIONS',
    USER_PASSWORD_RESET = 'USER_PASSWORD_RESET',
    PERIODIC_REVIEW = 'PERIODIC_REVIEW',
    EXTERNAL_PRESENTED_CHECK_REVIEW = 'EXTERNAL_PRESENTED_CHECK_REVIEW'
}
