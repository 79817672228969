import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    Address, AuthService, DataroomService, AddressService, MemberFunction, MemberType, TransactionTableFilter, Member, MemberService, CorporateEntity
} from 'projects/services/src/public-api';

@Component({
    selector: 'pt-member-summary',
    templateUrl: './member-summary.component.html',
    styleUrls: ['./member-summary.component.scss']
})
export class MemberSummaryComponent implements OnInit {

    @Input() readOnly = false;

    memberId: string;
    member: Member;
    imageSrc: any;
    showLogo: boolean;
    imageAvailable = false;
    filter: TransactionTableFilter;

    constructor(private route: ActivatedRoute,
                private addressService: AddressService,
                private memberService: MemberService,
                private dataroomService: DataroomService,
                private authService: AuthService) {}

    ngOnInit() {
        this.showLogo = true;
        if (!this.memberId) {
            const profile = this.authService.getProfile();
            this.memberId = profile.memberId;
            this.filter = new TransactionTableFilter(this.memberId);
            this.filter.memberType = profile.memberType;
            this.filter.memberFunctions = profile.memberFunctions;
        }
        this.route.params.subscribe((params) => {
            this.memberId = params['memberId'];
            this.filter = new TransactionTableFilter(this.memberId);
            this.imageSrc = {
                key: this.memberId,
                src: this.dataroomService.getImage(this.memberId)
            };
            this.memberService.loadMember(this.memberId).subscribe((member: Member) => {
                this.member = member;
                this.filter.memberType = member.memberType;
                this.filter.memberFunctions = member.memberFunctions;
                if (!(member.memberType === MemberType.FEE_ACCOUNT && this.authService.isRetailer())) {
                    this.loadAddress();
                }
            });
        });
    }

    loadAddress() {
        this.addressService.getPrimaryAddress(this.memberId).subscribe((location: Address) => {
            this.member.address = location;
        });
    }

    updateImageAvailability(available: boolean) {
        this.imageAvailable = available;
        this.showLogo = available;
    }

    getDescription() {
        if (this.member.memberType === MemberType.BUSINESS || this.member.memberType === MemberType.BUSINESS_MERCHANT) {
            return (this.member as CorporateEntity).briefDescription;
        }
        return null;
    }

}
