import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import {
    Bill, BusinessClient, Invoice, ManifestSelection, PaymentService, RemittanceInfo, SupportingDocument, TransactionEntrySelection, TransactionFormService, TransactionSource,
    TransactionSubType, TransactionType, Utils
} from 'projects/services/src/public-api';
import { MatTable } from '@angular/material/table';
import { UntypedFormGroup } from '@angular/forms';
import { from } from 'rxjs';
import { PDFDocument } from 'pdf-lib';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'pt-review-transaction-step',
    templateUrl: './review-transaction-step.component.html',
    styleUrls: ['./review-transaction-step.component.scss']
})
export class ReviewTransactionStepComponent implements OnInit, OnChanges {

    TransactionType = TransactionType;
    TransactionSubType = TransactionSubType;

    @Input() businessClients: BusinessClient<Invoice | Bill>[] = [];
    @Input() formGroup: UntypedFormGroup;
    @Input() isReviewStepValid = true;
    @Input() selectedManifests: ManifestSelection[] = [];
    @Input() manifestSupportingDocs: SupportingDocument[] = [];
    @Input() transactionTypes: { id: TransactionType, name: string }[];
    @Input() multiClientDeposit = false;
    @Input() subType: TransactionSubType;
    @Input() remittanceInfo: RemittanceInfo = null;
    @Input() achExpressFee: number = null;
    @Input() wirePaymentFee: number = null;
    @Input() transactionTotal = '0.00';
    @Input() isUniversalWhiteListedMerchant = false;

    @Output() transactionReviewed: EventEmitter<any> = new EventEmitter<any>();
    @Output() changeClientContext: EventEmitter<BusinessClient<Invoice | Bill>> = new EventEmitter<BusinessClient<Invoice | Bill>>();
    @Output() clientRemoved: EventEmitter<number> = new EventEmitter<number>();

    transactionEntryColumns: string[];
    manifestColumns: string[] = ['manifest_number', 'supplier', 'recipient', 'created', 'received_date', 'package_count'];
    paymentCompletionDate: string;
    remittanceFormGroup: UntypedFormGroup;

    @ViewChild('nonRetailTable') nonRetailTable: MatTable<any>;
    @ViewChild('manifestTable') manifestTable: MatTable<any>;
    @ViewChild('remittanceUpload') private remittanceUploadLink: ElementRef;

    constructor(private paymentService: PaymentService,
                private transactionFormService: TransactionFormService,
                private datePipe: DatePipe,
                private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.submit = this.submit.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.remittanceFormGroup = this.transactionFormService.initializeWireRemittanceForm();
        if (this.isRetailTransaction()) {
            this.transactionEntryColumns = ['description', 'amount'];
        } else if (this.multiClientDeposit) {
            this.transactionEntryColumns = ['accounting_party', 'entry', 'amount', 'action'];
        } else {
            this.transactionEntryColumns = ['accounting_party', 'entry', 'amount'];
        }
        if (this.formGroup.controls['transactionTypeCtrl'].value !== TransactionType.CASH_DEPOSIT && this.businessClients.length === 1) {
            this.formGroup.controls['transactionTypeCtrl'].setValue(this.businessClients[0].defaultTransactionType);
            this.onTransactionTypeChanged();
        }
        this.cdr.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.businessClients && !changes.businessClients.firstChange && this.nonRetailTable) ||
            changes.transactionTotal && !changes.transactionTotal.firstChange && this.nonRetailTable) {
            this.nonRetailTable.renderRows();
        }
        if (changes.selectedManifests && !changes.selectedManifests.firstChange && this.manifestTable) {
            this.manifestTable.renderRows();
        }
    }

    isFormValid() {
        let otherAmountsValid = true;
        this.businessClients.forEach((businessClient: BusinessClient<Invoice | Bill>) => {
            businessClient.transactionEntries.forEach((transactionEntry: TransactionEntrySelection<Invoice | Bill>) => {
                if (transactionEntry.included && transactionEntry.data.type === 'OTHER' && transactionEntry.data.supportingDocuments.length === 0) {
                    otherAmountsValid = !!this.isUniversalWhiteListedMerchant;
                }
            });
        });
        if (this.subType === TransactionSubType.DEPOSIT) {
            if (!this.multiClientDeposit) {
                return otherAmountsValid && this.formGroup.valid && this.isReviewStepValid;
            } else {
                return otherAmountsValid && this.formGroup.valid && this.isReviewStepValid;
            }
        } else {
            if (this.isOutgoingWireToMerchant()) {
                return otherAmountsValid && this.formGroup.valid && this.isReviewStepValid && this.remittanceFormGroup.valid;
            }
            return otherAmountsValid && this.formGroup.valid && this.isReviewStepValid;
        }
    }

    submit(reset: any) {
        if (this.subType === TransactionSubType.DEPOSIT) {
            this.transactionReviewed.emit();
            reset();
        } else {
            this.transactionReviewed.emit(reset);
        }
    }

    removeBusinessClient(index: number) {
        this.clientRemoved.emit(index);
    }

    addBusinessClient() {
        this.changeClientContext.emit();
    }

    editBusinessClient(businessClient: BusinessClient<Invoice | Bill>) {
        this.changeClientContext.emit(businessClient);
    }

    isRetailTransaction() {
        return this.formGroup.controls['transactionTypeCtrl'].value === TransactionType.CASH_DEPOSIT &&
            this.formGroup.controls['sourceCtrl'].value === TransactionSource.RETAIL_TRANSACTION;
    }

    isCheckTransaction() {
        return this.formGroup.controls['transactionTypeCtrl'].value === TransactionType.OUTGOING_CHECK_TO_MERCHANT ||
            this.formGroup.controls['transactionTypeCtrl'].value === TransactionType.OUTGOING_DIGITAL_CHECK_TO_MERCHANT;
    }

    isOutgoingCheckToMerchant() {
        return this.formGroup.controls['transactionTypeCtrl'].value === TransactionType.OUTGOING_CHECK_TO_MERCHANT;
    }

    isOutgoingWireToMerchant() {
        return this.formGroup.controls['transactionTypeCtrl'].value === TransactionType.OUTGOING_WIRE_TO_MERCHANT;
    }

    addRemittanceSupportingDocument() {
        const link: HTMLElement = this.remittanceUploadLink.nativeElement;
        link.click();
    }

    removeRemittanceSupportingDocument() {
        this.remittanceInfo.remittanceFile = null;
    }

    removeManifestSupportingDocument(index: number) {
        this.manifestSupportingDocs.splice(index, 1);
    }

    selectRemittanceFile(event: any) {
        if (event.target.files && event.target.files.length) {
            const file = event.target.files[0];
            Utils.validateFile(file, event, true);
            this.countPdfPages(file).subscribe(
                (pageCount: number) => {
                    if (pageCount > 5) {
                        throw new Error('Remittance forms must be less than 5 pages.');
                    } else {
                        this.remittanceInfo.remittanceFile = new SupportingDocument();
                        this.remittanceInfo.remittanceFile.file = file;
                    }
                },
                (error: any) => {
                    event.target.value = '';
                    throw error;
                }
            );
        }
        event.target.value = '';
    }

    countPdfPages(file: File) {
        return from(file.arrayBuffer().then(async (buff) => {
            const fileUint8Array = new Uint8Array(buff);
            const pdfDoc = await PDFDocument.load(fileUint8Array, {updateMetadata: false, ignoreEncryption: true});
            return pdfDoc.getPageCount();
        }));
    }

    onDateSelected(transactionDate: Date) {
        const paymentDateType = this.formGroup.controls['paymentDateTypeCtrl'].value;
        this.calculatePaymentCompletionDate(transactionDate, paymentDateType);
    }

    onTransactionTypeChanged() {
        if (this.subType === TransactionSubType.PAYMENT) {
            const transactionDate = this.formGroup.controls['transactionDateCtrl'].value?.singleDate?.jsDate;
            const paymentDateType = this.formGroup.controls['paymentDateTypeCtrl'].value;
            this.calculatePaymentCompletionDate(transactionDate, paymentDateType);
        }
    }

    calculatePaymentCompletionDate(scheduledDate: Date, paymentDateType?: PaymentDateType) {
        const transactionType = this.getCurrentTransactionType();
        if (!transactionType || transactionType === TransactionType.WALLET_TO_WALLET_PAYMENT) {
            return;
        }
        const scheduledTransactionDate = this.datePipe.transform(scheduledDate, 'yyyy-MM-dd');
        this.paymentService.getPaymentCompletionDate(transactionType, paymentDateType === PaymentDateType.SCHEDULE_PAYMENT, scheduledTransactionDate ? scheduledTransactionDate : '').subscribe((completionDate: any) => {
            this.paymentCompletionDate = completionDate;
        });
    }

    getCurrentTransactionType() : TransactionType {
        return this.formGroup.controls['transactionTypeCtrl'].value;
    }
}

export enum PaymentDateType {
    PAY_NOW = 'PAY_NOW',
    SCHEDULE_PAYMENT = 'SCHEDULE_PAYMENT'
}
