import { Component, OnInit } from '@angular/core';
import {
    Address, AddressService, AuthService, BankAccount, BankAccountService, BankAccountStatus, CorporateEntity, KeyParty, KeyPartyService, Member, MemberService, MemberType, PagedResponse,
    PaymentService, Task,
    WorkflowService
} from 'projects/services/src/public-api';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BankAccountReviewNotesModalComponent } from './bank-account-review-notes-modal/bank-account-review-notes-modal.component';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pt-bank-account-review',
    templateUrl: './bank-account-review.component.html',
    styleUrls: ['./bank-account-review.component.scss']
})
export class BankAccountReviewComponent implements OnInit {

    MemberType = MemberType;

    task: Task;
    account: BankAccount;
    member: Member;
    keyParties: KeyParty[] = [];
    submitting = false;

    constructor(public authService: AuthService,
                private route: ActivatedRoute,
                private router: Router,
                private addressService: AddressService,
                private memberService: MemberService,
                private bankAccountService: BankAccountService,
                private workflowService: WorkflowService,
                private paymentService: PaymentService,
                private keyPartyService: KeyPartyService,
                private notifier: NotificationService,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        this.approveAccount = this.approveAccount.bind(this);
        this.rejectAccount = this.rejectAccount.bind(this);
        this.notSubmitting = this.notSubmitting.bind(this);
        const bankAccountId = this.route.snapshot.params.bankAccountId;
        if (bankAccountId) {
            this.workflowService.loadTaskByProcessDefinitionWithVariables(['review_bank_account_v2'], `bankAccountId_eq_${bankAccountId}`);
        } else if (this.workflowService.getCurrentTask()) {
            this.task = this.workflowService.getCurrentTask();
            this.bankAccountService.getBankAccountById(this.task.variables.bankAccountId).subscribe((response: BankAccount) => {
                this.account = response;
                this.loadAccount(this.account);
            });
            const memberId = this.task.variables.memberId;
            this.memberService.loadMember(memberId).subscribe((member: Member) => {
                this.member = member;
                this.addressService.getPrimaryAddress(member.id).subscribe((address: Address) => {
                    this.member.address = address;
                });
            });
            this.keyPartyService.getAllActiveKeyParties(memberId).subscribe((keyParties: PagedResponse<KeyParty>) => {
                this.keyParties = keyParties.content;
            });
        }
    }

    loadAccount(account: BankAccount) {
        if (account.externalAccountId !== null) {
            // load payee address
            this.paymentService.getExternalAccountDetails(account.externalAccountId).subscribe((response: any) => {
                account.externalAccountDetails = response;
            });
        }
    }

    approveAccount(reset: any) {
        reset();
        this.submitting = true;
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.data = 'Approve';
        const dialog = this.dialog.open(BankAccountReviewNotesModalComponent, dialogConfig);
        dialog?.afterClosed().subscribe((reason) => {
            if (reason) {
                this.workflowService.completeTask(this.task.id, this.requestBody(BankAccountStatus.ACTIVE, reason.replace(/(?:\r\n|\r|\n)/g, '<br>'))).subscribe(() => {
                    this.router.navigate(['/dashboard']);
                    this.notifier.showSuccess(`The account for <b>${this.member.name}</b> has been approved.`);
                });
            } else {
                this.submitting = false;
            }
        });
    }

    rejectAccount(reset: any) {
        reset();
        this.submitting = true;
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.data = 'Reject';
        dialogConfig.panelClass = 'normal-modal';

        const dialog = this.dialog.open(BankAccountReviewNotesModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((reason) =>  {
            if (reason) {
                this.workflowService.completeTask(this.task.id, this.requestBody(BankAccountStatus.REJECTED, reason.replace(/(?:\r\n|\r|\n)/g, '<br>'))).subscribe(() => {
                    this.router.navigate(['/dashboard']);
                    this.notifier.showSuccess(`The account for <b>${this.member.name}</b> has been rejected.`);
                });
            } else {
                this.submitting = false;
            }
        });
    }

    notSubmitting() {
        return !this.submitting;
    }

    requestBody(status: string, reviewNotes: string) {
        const variables = {
            variables: {
                status: {
                    value: status,
                    type: 'String'
                },
                verifiedBy: {
                    value: this.authService.getProfile().name,
                    type: 'String'
                }
            }
        };
        if (reviewNotes) {
            variables.variables['reviewNotes'] = {
                value: reviewNotes,
                type: 'String'
            };
        }
        return variables;
    }

    getDbaName() {
        if (this.member && this.member.memberType !== MemberType.CONSUMER) {
            return (this.member as CorporateEntity).dbaName;
        }
        return '';
    }
}
