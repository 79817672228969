import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AuthService, ConnectedMemberService, Consumer, Member, MemberAccount, MemberAccountStatus, MemberAccountType, MemberType, PagedResponse } from 'projects/services/src/public-api';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { Observable, merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pt-connected-members-list',
    templateUrl: './connected-members-list.component.html'
})
export class ConnectedMembersListComponent implements OnInit, OnChanges, AfterViewInit {

    MemberType = MemberType;

    @Input() memberId: string;
    @Input() memberAccountId: string;
    @Input() memberAccountType: MemberAccountType = MemberAccountType.BUSINESS;

    partnerId: string;
    isLoadingResults = true;
    pageTracking: PageTracking;
    resultsLength = 0;
    subscription: any;
    displayedColumns: string[] = ['business_name', 'id', 'contact_info', 'status'];
    consumers = false;
    membersObservable: Observable<Array<MemberAccount>>;

    refreshEvent: EventEmitter<null> = new EventEmitter<null>();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(public authService: AuthService,
                private route: ActivatedRoute,
                private router: Router,
                private connectedMemberService: ConnectedMemberService,
                private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.partnerId = this.authService.getProfile().memberId;
        this.consumers = this.memberAccountType === MemberAccountType.CONSUMER;
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'sortName', 'asc');
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.memberAccountType && !changes.memberAccountType.firstChange) ||
            (changes.memberId && !changes.memberId.firstChange) ||
            (changes.memberAccountId && !changes.memberAccountId.firstChange)) {
            if (changes.memberAccountType) {
                this.consumers = changes.memberAccountType.currentValue === MemberAccountType.CONSUMER;
            }
            this.refreshEvent.emit();
        }
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.membersObservable = merge(this.sort.sortChange, this.paginator.page, this.refreshEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                return this.connectedMemberService.searchConnectedMembers(this.partnerId, this.memberId,
                    this.memberAccountId || '',
                    [this.memberAccountType],
                    true, this.paginator.pageIndex,
                    this.paginator.pageSize,
                    this.sort.active,
                    this.sort.direction);
            }),
            map((response: PagedResponse<MemberAccount>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                UIUtils.scrollDashboardToTop();
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        );
    }

    refreshList() {
        this.refreshEvent.emit();
    }

    isEnabled(member: MemberAccount) {
        return member && member.status !== MemberAccountStatus.DISABLED;
    }

    getConsumerName(member: Member) {
        if (member.memberType === MemberType.CONSUMER) {
            return (member as Consumer).lastName + ', ' + (member as Consumer).firstName;
        }
        return member.name;
    }
}
