import { Inject, Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class PartnerSettingsService {

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.PartnerAPIEndpoint + '/api/';
    }

    updatePartnerSettings(id: string, partnerSetting: any) {
        return this.http.put(`${this.baseUrl}setting/${id}`, partnerSetting);
    }

    getByMemberId(memberId: string) {
        const params = `?memberId=${memberId}`;
        return this.http.get(`${this.baseUrl}setting${params}`);
    }
}
