import { MfaType, Role } from './profile';

export class NewUserRequest {
    role: Role;

    memberId : string;
    memberAccountId: string;
    userId : string;
    keyPartyId : string;

    firstName : string;
    middleName :string;
    lastName : string;

    jobTitle : string;
    email : string;

    officePhone : string;
    mobilePhone: string;
    mfaType: MfaType;
}
