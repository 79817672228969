
<div class="row">
    <div class="col-12 mb-2">
        <div class="row">
            <div class="col-sm-5 dashboard-header" *ngIf="!memberAccount">
                <img class="dashboard-icon" src="assets/images/transaction-2.svg" alt="">
                <span>Transactions</span>
            </div>
            <div class="col-sm-5 dashboard-header" *ngIf="memberAccount">
                <div class="row" *ngIf="authService.isAuthorityOrReviewer()">
                    <div class="col-1">
                        <img class="dashboard-icon" src="assets/images/account-2.svg" alt="">
                    </div>
                    <div class="col-11 ps-3">
                        <div class="d-flex">
                            <div>Member:&nbsp;</div>
                            <pc-load-member-details [linkDescription]="memberAccount.member.name" [memberId]="memberAccount.memberId" [activeTab]="'accounts'" [linkType]="'header'" [directLink]="memberUnderReview">
                            </pc-load-member-details>
                        </div>
                        <div>
                            Account: <i *ngIf="!memberAccount.isPublic" class="fa fa-user-secret" title="Private"></i>{{memberAccount.accountName}}
                        </div>
                        <pc-member-account-badge [parent]="memberAccount"></pc-member-account-badge>
                    </div>
                </div>
                <div *ngIf="authService.isMemberOrMemberReviewer()">
                    <img class="dashboard-icon" src="assets/images/account-2.svg" alt="">
                    <span>Account: {{memberAccount.accountName}}</span>
                </div>
            </div>
            <div class="col-sm-7" *ngIf="authService.isAdmin(memberAccount)">
                <div class="col-sm d-flex justify-content-end flex-wrap">
                    <button *ngIf="currencyWithdrawalEnabled" class="blue-button" (click)="onCurrencyWithdrawal()" type="button"><img src="assets/images/currency-withdrawal.svg" alt="" class="pull-left dashboard-icon"><div class="pull-right-button">Currency Order</div></button>
                    <button *ngIf="depositsEnabled" class="blue-button" (click)="onDeposit()" type="button"><img src="assets/images/new-deposit.svg" alt="" class="pull-left dashboard-icon"><div class="pull-right-button">New Deposit</div></button>
                    <button *ngIf="paymentsEnabled" class="blue-button pull-right" (click)="onPayment()" type="button"><img src="assets/images/new-payment.svg" alt="" class="pull-left dashboard-icon"><div class="pull-right-button">New Payment</div></button>
                </div>
            </div>
            <div class="col-sm-7" *ngIf="authService.isRetailer(memberAccount)">
                <div class="col-sm d-flex justify-content-end flex-wrap">
                    <button *ngIf="showRetailPaymentButton()" class="blue-button pull-right" (click)="onRetailPayment()" type="button">New Retail Payment</button>
                </div>
            </div>
            <div class="col-sm-7" *ngIf="enableAuthorityTransactions && member && member.memberType !== MemberType.BUSINESS_MERCHANT && member.memberType !== MemberType.INDIVIDUAL_MERCHANT && !readOnly">
                <div class="col-sm d-flex justify-content-end" *ngIf="member?.memberType !== MemberType.FEE_ACCOUNT">
                    <button class="blue-button" (click)="onCreditPayment()" type="button"><i class="fa fa-plus pull-left dashboard-icon" aria-hidden="true"></i><div class="pull-right-button">Credit Member</div></button>
                    <button class="blue-button" (click)="onChargePayment()" type="button"><i class="fa fa-minus pull-left dashboard-icon" aria-hidden="true"></i><div class="pull-right-button">Charge Member</div></button>
                </div>
                <div class="col-sm d-flex justify-content-end">
                    <button class="blue-button" (click)="onAddFunds()" type="button"><i class="fa fa-plus pull-left dashboard-icon" aria-hidden="true"></i><div class="pull-right-button">Add Funds</div></button>
                    <button class="blue-button" (click)="onRemoveFunds()" type="button"><i class="fa fa-minus pull-left dashboard-icon" aria-hidden="true"></i><div class="pull-right-button">Remove Funds</div></button>
                </div>
            </div>
            <div class="col-sm-7 row" *ngIf="accountUnderReview && !readOnly">
                <div class="col-12 d-flex justify-content-end">
                    <pc-submit-button *ngIf="memberAccount.member.status === MemberStatus.ACTIVE && memberAccount.status !== MemberAccountStatus.PENDING" [submit]="onRejectAccount" [text]="'Reject Account'" [leftIcon]="'times'"></pc-submit-button>
                    <pc-submit-button *ngIf="memberAccount.member.status === MemberStatus.ACTIVE || memberAccount.member.status === MemberStatus.UNDER_REVIEW" [submit]="onApproveAccount"
                        [text]="memberAccount.status === MemberAccountStatus.PENDING ? 'Finalize Account' : 'Approve Account'" [enabled]="canApproveAccount" [leftIcon]="'check'"
                    ></pc-submit-button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 mb-3">
        <div class="tab-row">
            <span class="mt-2" *ngIf="isActivityListAvailable && memberAccount.accountType !== MemberAccountType.MERCHANT && !accountUnderReview" [ngClass]="getTabClass('ACCOUNT_ACTIVITY')" (click)="setTransactionTab('ACCOUNT_ACTIVITY')"> Account Activity </span>
            <span class="mt-2" *ngIf="!accountUnderReview" [ngClass]="getTabClass('TRANSACTION_LIST')" (click)="setTransactionTab('TRANSACTION_LIST')"> Transaction Listing </span>
            <span class="mt-2" *ngIf="memberAccount && memberAccount.accountType !== MemberAccountType.MERCHANT && authService.isAuthorityOrReviewer()" [ngClass]="getTabClass('BILLING_INFO')" (click)="setTransactionTab('BILLING_INFO')"> Billing and Functions </span>
            <span class="mt-2" *ngIf="memberAccount && memberAccount.accountType !== MemberAccountType.MERCHANT && memberAccount.member.memberType !== MemberType.FEE_ACCOUNT && authService.isAuthorityOrReviewer()" [ngClass]="getTabClass('ACCOUNT_LIMITS')" (click)="setTransactionTab('ACCOUNT_LIMITS')"> Account Limits </span>
            <span class="mt-2" *ngIf="memberAccount && authService.isAdmin()" [ngClass]="getTabClass('ACCOUNT_OVERVIEW')" (click)="setTransactionTab('ACCOUNT_OVERVIEW')"> Account Overview </span>
            <span class="mt-2" *ngIf="memberAccount && memberAccount.accountType !== MemberAccountType.MERCHANT && (authService.isAuthorityOrCorporateAdmin() || authService.isAccountAdmin(memberAccount))" [ngClass]="getTabClass('ACCOUNT_SETTINGS')" (click)="setTransactionTab('ACCOUNT_SETTINGS')"> Account Settings </span>
            <span class="mt-2" *ngIf="memberAccount && authService.isAuthorityOrReviewer() && memberAccount.accountType === MemberAccountType.MERCHANT" [ngClass]="getTabClass('BANKING_DETAILS')" (click)="setTransactionTab('BANKING_DETAILS')"> Banking Details </span>
        </div>
        <div *ngIf="showExportButton() && isActivityListAvailable && filter.transactionTab === 'ACCOUNT_ACTIVITY'" class="pull-right">
            <pc-submit-button [submit]="exportAccountActivityList" [text]="'Export'"></pc-submit-button>
            <a class="report-download-link" #downloadReportLink></a>
        </div>
    </div>
</div>

<div class="row d-flex justify-content-start" *ngIf="filter.transactionTab === 'TRANSACTION_LIST'">
    <div class="col-auto" *ngIf="!memberId && authService.isAuthorityOrReviewer()">
        <pc-member-selector [formGroup]="formGroup" [displayLabel]="false" [formCtrl]="'memberCtrl'" [placeholder]="'Search Members'"
            [memberTypes]="[MemberType.BUSINESS, MemberType.CONSUMER, MemberType.FEE_ACCOUNT]"
            [statuses]="[MemberStatus.ACTIVE, MemberStatus.DISABLED, MemberStatus.BLOCKED, MemberStatus.FROZEN]"
            (memberSelected)="memberSelected($event)">
        </pc-member-selector>
    </div>
    <div class="col-auto">
        <select class="form-control-header form-input-table-header form-select-sm" type="text" [(ngModel)]="filter.transactionState" [disabled]="submitting" (change)="stateChanged()">
            <option [value]="TransactionState.ALL">All Stages</option>
            <option [value]="TransactionState.COMPLETED">Completed</option>
            <option [value]="TransactionState.PENDING">Pending</option>
            <option [value]="TransactionState.CANCELLED">Cancelled</option>
            <option [value]="TransactionState.SCHEDULED">Scheduled</option>
        </select>
    </div>

    <div class="col-auto">
        <select class="form-control-header form-input-table-header form-select-sm" type="text" [(ngModel)]="filter.transactionTypeFilter" [disabled]="submitting" (change)="typeChanged(true)">
            <option [value]="TransactionTableTypeFilter.ALL_TYPES">All Types</option>
            <option *ngIf="!isRetailer()" [value]="TransactionTableTypeFilter.CONFIA_PAYMENT">Direct Transfers</option>
            <option *ngIf="!isRetailer()" [value]="TransactionTableTypeFilter.ACH">ACH</option>
            <option *ngIf="!isRetailer()" [value]="TransactionTableTypeFilter.WIRE">Wires</option>
            <option *ngIf="!isRetailer()" [value]="TransactionTableTypeFilter.CHECK">Checks</option>
            <option *ngIf="!isRetailer()" [value]="TransactionTableTypeFilter.CASH">Cash</option>
            <option *ngIf="!isRetailer()" [value]="TransactionTableTypeFilter.ADJUSTMENT">Adjustments</option>
            <option [value]="TransactionTableTypeFilter.FEE">Fees</option>
        </select>
    </div>

    <div *ngIf="filter.transactionTypeFilter === TransactionTableTypeFilter.ACH && returnCodeFilterOptions.length > 0" class="col-auto">
        <pc-text-option-selector [class]="'form-control-header form-input-table-header form-select-sm'" [required]="false"  [formCtrl]="'returnCodeFilterFormCtrl'"
            [defaultValue]="this.filter.returnCode" [formGroup]="formGroup" [options]="returnCodeFilterOptions" [translated]="true" (change)="returnCodeChanged(true)">
        </pc-text-option-selector>
    </div>

    <div class="col-auto">
        <pc-form-date-picker [formCtrl]="'dateCtrl'" [formGroup]="formGroup" [type]="'RANGED'" (dateSelected)="dateChanged($event)">
        </pc-form-date-picker>
    </div>

    <div class="col-auto">
        <mat-button-toggle class="mat-more-button" [value]="isMoreFilterToggled ? 'Less' : 'More'"
                [aria-label]="isMoreFilterToggled ? 'Less' : 'More'"
                (change)="onMoreFiltersToggled($event)">

            <span>{{isMoreFilterToggled ? 'Less' : 'More'}}</span>
            <mat-icon *ngIf="isMoreFilterToggled; else iconUp">keyboard_arrow_up</mat-icon>

            <ng-template #iconUp>
                <mat-icon>keyboard_arrow_down</mat-icon>
            </ng-template>

        </mat-button-toggle>
    </div>
</div>

<div *ngIf="isMoreFilterToggled && filter.transactionTab === 'TRANSACTION_LIST'" class="d-flex row justify-content-start align-items-center">
    <div class="col-auto">
        <pc-form-amount-input [formCtrl]="'minimumCtrl'"
            [formGroup]="formGroup"
            [dollar]="true"
            [placeholder]="'Minimum'"
            [allowNegative]="true"
            (change)="minMaxChanged()">
        </pc-form-amount-input>
    </div>

    <div class="col-auto">
        <pc-form-amount-input [formCtrl]="'maximumCtrl'"
            [formGroup]="formGroup"
            [dollar]="true"
            [placeholder]="'Maximum'"
            [allowNegative]="true"
            (change)="minMaxChanged()">
        </pc-form-amount-input>
    </div>

    <div class="col-auto" *ngIf="showRetailFilter()">
        <select #retailLocationsCtrl class="form-control-header form-input-table-header form-select-sm select-location" type="text" [(ngModel)]="filter.retailLocationId" [disabled]="submitting" (change)="retailLocationChanged()">
            <option value="">All Locations</option>
            <option *ngFor="let location of retailLocations; let i = index;" [value]="location.id">{{location.nickname}}</option>
        </select>
    </div>

    <div class="col-auto export-button" *ngIf="showExportButton()">
        <pc-submit-button [submit]="exportTransactionListing" [text]="'Export'"></pc-submit-button>
        <a class="report-download-link" #downloadReportLink></a>
    </div>
</div>
