<div>
    <div>
        <div class="modal-header">
            <div class="row no-gutters col-12">
                <div class="col-1 pull-left"><img class="dashboard-icon" src="assets/images/account-2.svg" alt=""></div>
                <div class="col-10">
                    <label class="form-title">{{getDepositType()}} Deposit to Account: {{memberAccount.accountName}}</label>
                    <div class="row">
                        <div class="row mt-1">
                            <div class="col-4 centered">Available Balance:<strong>&nbsp;{{memberAccount.wallet.availableBalance | currency}}</strong></div>
                            <div class="col-4 centered">Pending Funds:<strong>&nbsp;{{memberAccount.wallet.pendingBalance | currency}}</strong></div>
                            <div class="col-4 centered" [ngClass]="errorMessage ? 'error' : ''">Deposit Total:<strong>&nbsp;{{transactionTotal | currency}}</strong></div>
                        </div>
                    </div>
                </div>
                <div class="col-1 pull-right"><i class="modal-close" (click)="close()">&times;</i></div>
            </div>
            <div class="row no-gutters col-12">
                <pc-error-message [error]="errorMessage" [width]="'full'"></pc-error-message>
            </div>
        </div>

        <div class="modal-content">
            <div *ngIf="!depositTypeSelected">
                <div class="d-flex justify-content-center">
                    <div class="flex-column">
                        <div class="mt-3 mb-5"><strong>Please select a deposit type:</strong></div>
                        <div class="mb-5">
                            <mat-button-toggle-group #group="matButtonToggleGroup" #buttongroup class="mb-5" [value]="depositType" (change)="onTypeChange(group.value)"  [vertical]="(buttonOrientation | async)!">
                                <mat-button-toggle [value]="DepositType.NON_CASH_DEPOSIT">
                                    <div class="p-3">ACH / Wire Deposit</div>
                                </mat-button-toggle>
                                <mat-button-toggle *ngIf="cashDepositEnabled" [value]="DepositType.RETAIL_CASH_DEPOSIT">
                                    <div class="p-3">Retail Cash Deposit</div>
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                        <div *ngIf="!isAccountingLinked" class="flex-column">
                            <pc-error-message [error]="ACCOUNTING_NOT_LINKED_ERROR" [width]="'full'"></pc-error-message>
                        </div>
                        <div *ngIf="!isMetrcLinked" class="flex-column">
                            <pc-error-message [error]="METRC_NOT_LINKED_ERROR" [width]="'full'"></pc-error-message>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <button class="blue-button" [disabled]="!depositType" (click)="selectDepositType()" type="button">Next</button>
                </div>
            </div>
            <div *ngIf="depositTypeSelected && memberAccount">
                <div *ngIf="depositType === DepositType.BUSINESS_CASH_DEPOSIT">
                    <pt-business-cash-deposit [memberAccount]="memberAccount" [isAccountingLinked]="isAccountingLinked" [isMetrcLinked]="isMetrcLinked"
                        (totalChanged)="onTotalChange($event)" (transactionReviewed)="onSubmit($event)" (closeTransactionModal)="close($event)"></pt-business-cash-deposit>
                </div>
                <div *ngIf="depositType === DepositType.NON_CASH_DEPOSIT">
                    <pt-business-non-cash-deposit [memberAccount]="memberAccount" [isAccountingLinked]="isAccountingLinked" [isMetrcLinked]="isMetrcLinked"
                        (totalChanged)="onTotalChange($event)" (transactionReviewed)="onSubmit($event)" (closeTransactionModal)="close($event)"></pt-business-non-cash-deposit>
                </div>
                <div *ngIf="depositType === DepositType.RETAIL_CASH_DEPOSIT">
                    <pt-retail-cash-deposit [memberAccount]="memberAccount" (totalChanged)="onTotalChange($event)" (transactionReviewed)="onSubmit($event)"></pt-retail-cash-deposit>
                </div>
            </div>
        </div>
    </div>
</div>
