import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CustomValidators } from 'projects/services/src/public-api';

@Injectable({
    providedIn: 'root'
})
export class DocumentFormService {

    constructor(private formBuilder: UntypedFormBuilder) { }

    initializeExplanationForm(): UntypedFormGroup {
        return this.formBuilder.group({
            notesCtrl: new UntypedFormControl('', [Validators.required, Validators.maxLength(1048575), Validators.minLength(2), CustomValidators.noLeadingOrTrailingWhitespace]),
            documentTypeCtrl: new UntypedFormControl('', [Validators.required])
        });
    }

    initializeDocumentForm(): UntypedFormGroup {
        return this.formBuilder.group({
            notesCtrl: new UntypedFormControl('', [Validators.maxLength(300)]),
            documentTypeCtrl: new UntypedFormControl('', [Validators.required])
        });
    }

}
