import { Inject, Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { ApiAccess } from './models/api-access';

@Injectable({
    providedIn: 'root'
})
export class ApiAccessService {

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.EntityAPIEndpoint + '/';
    }

    loadKeysByMemberId(memberId: string): Observable<any> {
        const params = `?memberId=${memberId}`;
        return this.http.get(`${this.baseUrl}access${params}`);
    }

    updateKey(apiAccess: ApiAccess) {
        return this.http.delete(`${this.baseUrl}access/${apiAccess.id}`);
    }

    createKey(memberId: string) {
        return this.http.post(`${this.baseUrl}access?memberId=${memberId}`, null);
    }
}
