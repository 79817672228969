import {Inject, Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {AuthService} from './auth/auth.service';
import {MessageType} from '../public-api';
import {GqlService} from 'projects/services/src/lib/graphql/gql.service';
import {GqlQueryBuilder} from 'projects/services/src/lib/graphql/gql-query.builder';
import {GqlMessageQueries} from 'projects/services/src/lib/graphql/queries/dataroom/gql-message.queries';

@Injectable({
    providedIn: 'root'
})
export class MessageService {

    baseUrl: string;
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    constructor(private http: HttpService,
                private authService: AuthService,
                private gqlService: GqlService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.DataroomAPIEndpoint + '/';
    }

    countReceivedMessages() {
        const params = '?readStatus=false';
        return this.http.head(`${this.baseUrl}message${params}`);
    }

    loadMessages(type: MessageType, senderId: String, senderMemberId: string, counterParty: string, readStatus: boolean, startDate: string, endDate: string, start: number, size: number, sort: string, sortDir: string) {

        const searchMessagesQuery = GqlQueryBuilder.builder(GqlMessageQueries.searchMessages())
            .withPageFragmentType(GqlMessageQueries.gqlPageFragment)
            .paginated()
            .build();

        const queryVars: any = {
            type: type,
            senderId,
            senderMemberId,
            counterParty,
            readStatus,
            startDate: startDate ? startDate : null,
            endDate: endDate ? endDate : null,
            timezone: this.timezone,
            start: start, size: size, sort: sort, sortDir: sortDir
        };

        return this.gqlService.query(searchMessagesQuery, queryVars);
    }

    loadSentMessages(counterParty: string, startDate: string, endDate: string, start: number, size: number, sort: string, sortDir: string) {
        let senderId = null;
        if (!this.authService.isAuthority()) {
            senderId = this.authService.getProfile().userId;
        }

        return this.loadMessages(MessageType.SENT, senderId, this.authService.getProfile().memberId, counterParty, null, startDate, endDate, start, size, sort, sortDir);
    }

    loadReceivedMessages(counterParty: string, startDate: string, endDate: string, start: number, size: number, sort: string, sortDir: string, readStatus: boolean) {
        return this.loadMessages(MessageType.RECEIVED, null, null, counterParty, readStatus, startDate, endDate, start, size, sort, sortDir);
    }

    loadAllMessages(counterParty: string, startDate: string, endDate: string, start: number, size: number, sort: string, sortDir: string) {
        let senderId = null;
        if (!this.authService.isAuthority()) {
            senderId = this.authService.getProfile().userId;
        }

        return this.loadMessages(MessageType.ALL, senderId, null, counterParty, null, startDate, endDate, start, size, sort, sortDir);
    }

    sendMessage(file: File, fileName: string, subject: string, body: string, recipientMemberId: string, recipientId: string, parentMessageId: string) {
        const formData = new FormData();
        formData.append('file', file);
        const metadata = {
            fileName,
            subject,
            body,
            recipientMemberId: recipientMemberId,
            parentMessageId,
            recipientId
        };
        formData.append('metadata', JSON.stringify(metadata));
        return this.http.post(`${this.baseUrl}message`, formData);
    }

    sendMessageNoAttachment(subject: string, body: string, recipientMemberId: string, recipientId: string, parentMessageId: string) {
        const formData = new FormData();
        const message = {
            subject,
            body,
            recipientMemberId: recipientMemberId,
            parentMessageId,
            recipientId
        };
        formData.append('metadata', JSON.stringify(message));
        return this.http.post(`${this.baseUrl}message`, formData);
    }

    updateMessageReadStatus(id: string, body: any) {
        return this.http.put(`${this.baseUrl}message/${id}`, body);
    }

}
