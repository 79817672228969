<div class="horizontal-scroll mat-table-container">
    <div class="table mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <table mat-table [dataSource]="userAccounts" matSort matSortDisableClear [matSortActive]="pageTracking.sort" [matSortDirection]="pageTracking.sortDir">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="lastName"> User Name </th>
            <td mat-cell *matCellDef="let element">
                <a *ngIf="element.user" class="link" (click)="memberDetails($event, element?.member)">
                    <b> {{ element.user.name }} </b>
                </a>
                <div *ngIf="element.user && element.user.mobilePhone">
                    {{ element.user.mobilePhone | phone }}
                    <br>
                </div>
                <div *ngIf="element.user && element.user.email">
                    {{ element.user.email }}
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef  class="centered" mat-sort-header> User Status </th>
            <td mat-cell *matCellDef="let element" class="centered">
                <pc-account-badge [parent]="element"></pc-account-badge>
            </td>
        </ng-container>
        <ng-container matColumnDef="member_status">
            <th mat-header-cell *matHeaderCellDef  class="centered"> Member Status </th>
            <td mat-cell *matCellDef="let element" class="centered">
                <pc-member-badge [parent]="element.member"></pc-member-badge>
                <div>{{ element.member.name }}</div>
                <div *ngIf="element.member.jurisdiction">{{ Utils.getJurisdictionName(element.member.jurisdiction) }}</div>
                <div *ngIf="element.member.tier === RiskTier.TIER_1">Tier 1</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef  class="centered" mat-sort-header> Created Date </th>
            <td mat-cell *matCellDef="let element" class="centered">
                <div *ngIf="element.user">
                    {{ element.user.created | date:'mediumDate' }}
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns" [class.disabled]="element.status === UserAccountStatus.DISABLED"></tr>
    </table>
    <mat-paginator class="mat-paginator-sticky" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"></mat-paginator>
</div>
