import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
    ReportsService, BsaReport, ReportType, DataroomService, AuthService, ReportStatus, PostedTransactionType, MemberService, Member, Role,
    FilingInstitution,
    TransactionSubType,
    AuditService,
    AuditRecord,
    ReferencedEntityType,
    PagedResponse,
    MemberStatus,
    TransactionUtils} from 'projects/services/src/public-api';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmModalComponent, FincenReportTrackingModalComponent, LoaderComponent } from 'projects/components/src/public-api';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { TableUtils } from 'projects/components/src/lib/table-utils.service';
import { FincenEditNarrativeModalComponent } from 'projects/components/src/lib/modal/fincen-edit-narrative-modal/fincen-edit-narrative-modal.component';
import { HttpResponse } from '@angular/common/http';

@Component({
    selector: 'pt-authority-reports-details',
    styleUrls: ['./authority-reports-details.component.scss'],
    templateUrl: './authority-reports-details.component.html'
})
export class AuthorityReportsDetailsComponent implements OnInit {
    ReportStatus = ReportStatus;
    PostedTransactionType = PostedTransactionType;
    TransactionSubType = TransactionSubType;
    ReportType = ReportType;
    FilingInstitution = FilingInstitution;
    MemberStatus = MemberStatus;
    bsaReportId: string;
    report: BsaReport;
    member: Member;
    isSubmitButtonEnabled = false;
    showTransactionsTab = false;
    isApproveButtonEnabled = false;
    showRegenerateButton = false;
    showEditNarrativeButton = false;
    activeTab = 'NARRATIVE';
    auditDetails = [];
    submitting = true;
    displayedColumns: string[] = ['name', 'date', 'action', 'description'];
    transactions: any[] = [];
    isContinuingOrFinalContinuing = false;

    @ViewChild('downloadReportLink') private downloadReportLink: ElementRef;
    constructor(private route: ActivatedRoute,
                private router: Router,
                private dataroomService: DataroomService,
                private dialog: MatDialog,
                private notifier: NotificationService,
                public authService: AuthService,
                private overlay: Overlay,
                private reportsService: ReportsService,
                private auditService: AuditService,
                private memberService: MemberService) {}

    ngOnInit() {
        const queryParams = this.route.snapshot.queryParams;
        const activeTab = queryParams['_activeTab'];
        if (activeTab && activeTab !== this.activeTab) {
            this.activeTab = activeTab;
        }
        this.route.queryParams.subscribe((params) => {
            this.handleQueryParamChanges(params);
        });
        this.route.params.subscribe((params) => {
            this.bsaReportId = params['bsaReportId'];
            this.report = null;
            this.loadReport(this.bsaReportId);
        });
    }

    loadReport(bsaReportId: string) {
        this.reportsService.getReportById(bsaReportId).subscribe((response: any) => {
            this.report = response;
            this.showRegenerateButton = this.report.filingInstitution === FilingInstitution.NBCU &&
                (this.authService.isAuthority() || this.isAuthorityReporter())  && (this.report.reportType === ReportType.CONTINUING_REPORT || this.report.reportType === ReportType.FINAL_CONTINUING_REPORT || this.report.reportType === ReportType.INITIAL_REPORT)
                        && (this.report.status === ReportStatus.AUTHORITY_REVIEW || this.report.status === ReportStatus.READY_FOR_SUBMISSION);
            this.showEditNarrativeButton = this.showRegenerateButton && !!this.report.narrativeText;
            this.loadTabDetails();
            this.memberService.loadMember(this.report.memberId).subscribe((member: Member) => {
                this.member = member;
            });
            this.reportsService.loadBsaReport(this.report);
            this.isSubmitButtonEnabled = !(this.report.status === ReportStatus.READY_FOR_SUBMISSION || this.report.status === ReportStatus.SUBMISSION_FAILED || this.report.status === ReportStatus.REJECTED || this.report.status === ReportStatus.ACKNOWLEDGEMENT_FAILED);
            this.isApproveButtonEnabled = !(this.report.status === ReportStatus.AUTHORITY_REVIEW || this.report.status === ReportStatus.SUBMISSION_FAILED || this.report.status === ReportStatus.REJECTED || this.report.status === ReportStatus.ACKNOWLEDGEMENT_FAILED) && this.report.filingInstitution === FilingInstitution.NBCU;
            this.isContinuingOrFinalContinuing = (this.report.reportType === ReportType.CONTINUING_REPORT || this.report.reportType === ReportType.FINAL_CONTINUING_REPORT);
            if (this.report.trackingDocumentId) {
                this.dataroomService.getDocumentById(this.report.trackingDocumentId).subscribe((upload: any) => {
                    this.report.trackingDocumentDetails = upload;
                });
            }
            if (this.report.acknowledgedDocumentId) {
                this.dataroomService.getDocumentById(this.report.acknowledgedDocumentId).subscribe((upload: any) => {
                    this.report.acknowledgedDocumentDetails = upload;
                });
            }
        });
    }

    downloadFile(uploadDetails: any) {
        this.dataroomService.downloadResource(uploadDetails, this.downloadReportLink);
    }

    updateFincenReportTrackingDetails() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            report: this.report
        };
        const dialog = this.dialog.open(FincenReportTrackingModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((refresh: boolean) => {
            if (refresh) {
                this.loadReport(this.bsaReportId);
                if (this.authService.isAuthority()) {
                    this.notifier.showSuccess('Fincen report tracking details successfully updated.', 5000);
                } else {
                    this.notifier.showSuccess('Notes successfully updated.', 5000);
                }
            }
        });
    }

    updateFincenFiledReport() {
        if (this.dialog.openDialogs?.length) {
            return;
        }
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        let reportDescription = this.getReportDescription(this.report);
        dialogConfig.data = {
            title: `Approve ${reportDescription}`,
            description: `Are you sure you want to approve the ${reportDescription} for <strong>${this.report.memberName}</strong>.`,
            confirmText: 'APPROVE',
            rejectText: 'CANCEL'
        };
        const dialog = this.dialog.open(ConfirmModalComponent, dialogConfig);
        dialog?.afterClosed().subscribe((confirmAction: any) => {
            if (confirmAction === 'confirmed') {
                const overlayRef = this.overlay.create({
                    positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                    hasBackdrop: true
                });
                const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
                componentRef.instance.title = 'Approving File...';
                const updates = {
                    status: ReportStatus.READY_FOR_SUBMISSION
                };
                this.reportsService.updateFincenFiledReport(this.bsaReportId, updates).subscribe((_status: ReportStatus) => {
                    this.loadReport(this.bsaReportId);
                    overlayRef.dispose();
                }, (error) => {
                    overlayRef.dispose();
                    throw error;
                });
            }
        });
    }

    uploadFileToSDTMServer() {
        if (this.dialog.openDialogs?.length) {
            return;
        }
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        let reportDescription = this.getReportDescription(this.report);
        dialogConfig.data = {
            title: 'Confirm Submission',
            description: `Are you sure you want to submit the ${reportDescription} for <strong>${this.report.memberName}</strong>.`,
            confirmText: 'CONFIRM SUBMISSION',
            rejectText: 'CANCEL'
        };
        const dialog = this.dialog.open(ConfirmModalComponent, dialogConfig);
        dialog?.afterClosed().subscribe((confirmAction: any) => {
            if (confirmAction === 'confirmed') {
                const overlayRef = this.overlay.create({
                    positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                    hasBackdrop: true
                });
                const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
                componentRef.instance.title = 'Uploading File...';
                const fileUploadRequest = {
                    bsaReportId: this.bsaReportId,
                    memberId: this.report.memberId
                };
                this.reportsService.uploadReportToServer(fileUploadRequest).subscribe((_status: ReportStatus) => {
                    this.loadReport(this.bsaReportId);
                    overlayRef.dispose();
                    this.notifier.showSuccessCloseRequired('The report submission is in progress.');
                }, (error) => {
                    overlayRef.dispose();
                    throw error;
                });
            }
        });
    }

    getReportDescription(report: BsaReport): string {
        switch (report.reportType) {
            case ReportType.INITIAL_REPORT:
                return 'SAR Initial Report';
            case ReportType.CONTINUING_REPORT:
                return 'SAR Continuing Report';
            case ReportType.CTR_REPORT:
                return 'CTR Report';
            case ReportType.FINAL_CONTINUING_REPORT:
                return 'SAR Final Continuing Report';
            default:
                return report.reportType;
        }
    }

    canApproveReport(report: BsaReport): boolean {
        return (((report.reportType === ReportType.CONTINUING_REPORT || this.report.reportType === ReportType.FINAL_CONTINUING_REPORT) || (!report.transactionId && report.reportType === ReportType.CTR_REPORT))
            && report.filingInstitution === FilingInstitution.NBCU && this.authService.isAuthority());
    }

    canSubmitReport(report: BsaReport): boolean {
        return (((report.reportType === ReportType.CONTINUING_REPORT || this.report.reportType === ReportType.FINAL_CONTINUING_REPORT) || (!report.transactionId && report.reportType === ReportType.CTR_REPORT))
            && report.filingInstitution === FilingInstitution.NBCU && this.authService.getProfile().role === Role.AUTHORITY_REPORTER);
    }

    /**
     * @deprecated we can be removed once there are no more pointchain reports
     */
    canSubmitPctReport(report: BsaReport): boolean {
        return (report.reportType === ReportType.CONTINUING_REPORT || this.report.reportType === ReportType.FINAL_CONTINUING_REPORT) && report.filingInstitution === FilingInstitution.POINTCHAIN && this.authService.isAuthority();
    }

    getSubmissionDueDate(report: BsaReport) {
        let submissionDueDate = new Date(report.created);
        if (report.actualReportTriggerDate) {
            submissionDueDate = new Date(report.actualReportTriggerDate);
        }
        const days = report.reportType === ReportType.CTR_REPORT ? 14 : 29;
        submissionDueDate.setDate(submissionDueDate.getDate() + days);
        return submissionDueDate;
    }

    regenerateReport() {
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });

        const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
        componentRef.instance.title = 'Regenerating report…';
        this.reportsService.regenerateReport(this.report.id).subscribe((_status: ReportStatus) => {
            this.loadReport(this.bsaReportId);
            overlayRef.dispose();
            this.notifier.showSuccessCloseRequired('Report regenerated successfully.');
        }, (error) => {
            overlayRef.dispose();
            throw error;
        });
    }

    editNarrativeText() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'full-modal';

        dialogConfig.data = {
            report: this.report
        };
        const dialog = this.dialog.open(FincenEditNarrativeModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((refresh: boolean) => {
            if (refresh) {
                this.loadReport(this.bsaReportId);
                this.notifier.showSuccess('Report updated and regenerated successfully.', 5000);
            }
        });
    }

    updateQueryParams(replace: boolean) {
        const queryParams = { _activeTab: this.activeTab, page: null, num: null, sort: null, dir: null };
        TableUtils.updateQueryParams(this.route, this.router, queryParams, replace);
    }

    getTabClass(tabOption: string) {
        if (this.activeTab === tabOption) {
            return 'tab-navigation-selected';
        } else {
            return 'tab-navigation';
        }
    }

    isAuthorityReporter() {
        return this.authService.getProfile().role === Role.AUTHORITY_REPORTER;
    }

    isAuthority() {
        return this.authService.isAuthority();
    }

    loadTabDetails() {
        if ((this.report.reportType === ReportType.CONTINUING_REPORT) || (this.report.reportType === ReportType.FINAL_CONTINUING_REPORT) || (this.report.reportType === ReportType.CTR_REPORT
            && !this.report.transactionId && this.report.filingInstitution === FilingInstitution.NBCU)) {
            this.showTransactionsTab = true;
            this.activeTab = 'TRANSACTIONS';
        }
        this.setActiveTab(this.activeTab, true);
    }

    handleQueryParamChanges(params: Params) {
        const activeTab = params['_activeTab'] || null;
        if (activeTab && activeTab !== this.activeTab) {
            this.setActiveTab(activeTab, true);
        }
        const currentPage = params['page'] || null;
        const currentPageSize = params['num'] || null;
        const currentSort = params['sort'] || null;
        const currentSortDir = params['dir'] || null;
        const newQueryParams = { page: currentPage, num: currentPageSize, sort: currentSort, dir: currentSortDir };
        TableUtils.updateQueryParams(this.route, this.router, newQueryParams, true);
    }

    setActiveTab(activeTab: string, skipLocationChange?: boolean) {
        if (activeTab === 'HISTORY') {
            this.auditService.getAuditRecordsByReferenceId(this.bsaReportId, ReferencedEntityType.FINCEN_REPORT).subscribe((response: PagedResponse<AuditRecord>) => {
                this.auditDetails = response.content;
            }, (error) => {
                throw error;
            });
        }
        if (activeTab !== this.activeTab) {
            this.activeTab = activeTab;
            this.router.navigateByUrl(`/reports/details/${this.report.id}?_activeTab=${activeTab}`, {skipLocationChange});
        }
    }

    exportTransactionActivityList() {
        const activityName = this.report.reportType === ReportType.CTR_REPORT ? 'ctr' : 'sar';
        const fileName = `${this.report.memberName}_${activityName}_activity_${this.report.startDate}_${this.report.endDate}.csv`;
        this.reportsService.exportTransactionActivityList(this.report.id).subscribe({
            next: (response: HttpResponse<Blob>) => {
                TransactionUtils.downloadFile(response, fileName, this.downloadReportLink);
            },
            error: (error: any) => {
                throw error;
            }
        });
    }
}
