import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../validators/custom.validators';
import { Address } from '../models/address';
import { MerchantAccountConfig } from '../models/merchant-account-config';
import { MerchantAccountContactInfo } from '../models/merchant-account-contact-info';
import { SupportedTransactionType } from '../models/transaction';
import { Configuration } from '../configuration/configuration.service';
import { AccountingParty} from '../models/accounting/accounting-party';
import { MemberType } from '../models/member';

@Injectable({
    providedIn: 'root'
})
export class MerchantFormService {

    constructor(private formBuilder: UntypedFormBuilder) {
    }

    initializeNewMerchantForm(merchantName?: string, merchantMemberType = MemberType.BUSINESS_MERCHANT): UntypedFormGroup {
        return this.formBuilder.group({
            merchantIdCtrl: new UntypedFormControl(''),
            merchantCtrl: new UntypedFormControl(),
            merchantNameCtrl: new UntypedFormControl(merchantName || '', [Validators.required, Validators.minLength(2)]),
            allowedTransactionsCtrl: new UntypedFormControl(SupportedTransactionType.BOTH, [Validators.required]),
            achAccountHolderCtrl: new UntypedFormControl('', [CustomValidators.noLeadingOrTrailingWhitespace]),
            achAccountNumberCtrl: new UntypedFormControl('', [Validators.minLength(5), Validators.maxLength(17), CustomValidators.onlyNumeric]),
            achRoutingNumberCtrl: new UntypedFormControl('', [Validators.minLength(9), Validators.maxLength(9), CustomValidators.onlyNumeric]),
            wireRoutingNumberCtrl: new UntypedFormControl('', [Validators.minLength(9), Validators.maxLength(9), CustomValidators.onlyNumeric]),
            merchantFirstNameCtrl: new UntypedFormControl('', merchantMemberType === MemberType.INDIVIDUAL_MERCHANT ? [Validators.required, Validators.minLength(2), CustomValidators.personName] : []),
            merchantMiddleNameCtrl: new UntypedFormControl('', [CustomValidators.personName]),
            merchantLastNameCtrl: new UntypedFormControl('', merchantMemberType === MemberType.INDIVIDUAL_MERCHANT ? [Validators.required, Validators.minLength(2), CustomValidators.personName] : []),
            birthDateCtrl: new UntypedFormControl('', [CustomValidators.birthDate]),
            ssnCtrl: new UntypedFormControl('', [Validators.pattern('^([0-9]{9})$')])
        });
    }

    initializeNewMerchantMemberAccountForm(shared: boolean, accountingParty?: AccountingParty, merchantMemberType = MemberType.BUSINESS_MERCHANT): UntypedFormGroup {
        const address = accountingParty?.billAddress || accountingParty?.shipAddress;
        if (address?.stateProvince) {
            const adminDivisions = Configuration.getConfig().countries.find((country) => {
                return country.code === 'US';
            }).divisions;
            let adminDivisionConfirmed = false;
            for (let adminDivision of adminDivisions) {
                if (adminDivision.code.toLowerCase() === address.stateProvince.toLowerCase() || adminDivision.name.toLowerCase() === address.stateProvince.toLowerCase()) {
                    address.stateProvince = adminDivision.name;
                    adminDivisionConfirmed = true;
                    break;
                }
            }
            if (!adminDivisionConfirmed) {
                address.stateProvince = null;
            }
        }
        if (accountingParty?.officePhone) {
            const cleanPhone = accountingParty.officePhone.replace(/\D/g, '');
            accountingParty.officePhone = cleanPhone.length === 10 ? cleanPhone : null;
        }
        return this.formBuilder.group({
            streetAddressOneCtrl: new UntypedFormControl(address?.streetAddressOne || '', [Validators.required, Validators.minLength(3)]),
            cityCtrl: new UntypedFormControl(address?.city || '', [Validators.required, Validators.minLength(2), CustomValidators.noLeadingOrTrailingWhitespace]),
            stateProvinceCtrl: new UntypedFormControl(address?.stateProvince || '', [Validators.required, Validators.minLength(2), CustomValidators.noLeadingOrTrailingWhitespace]),
            countryCtrl: new UntypedFormControl('United States', [Validators.required, Validators.minLength(2), CustomValidators.noLeadingOrTrailingWhitespace]),
            zipPostalCodeCtrl: new UntypedFormControl(address?.zipPostalCode || '', (address?.country === 'United States' || address?.country === 'Canada' || address?.country === 'Mexico')
                ? [Validators.required, CustomValidators.zipCode] : [Validators.required, CustomValidators.noLeadingOrTrailingWhitespace, Validators.minLength(3)]),
            ownerNameCtrl: new UntypedFormControl(accountingParty?.contactName || '', (shared || merchantMemberType === MemberType.INDIVIDUAL_MERCHANT) ? [CustomValidators.personName, Validators.maxLength(64)] : [Validators.required, CustomValidators.personName, Validators.maxLength(64)]),
            ownerEmailCtrl: new UntypedFormControl(accountingParty?.email || '', (shared || merchantMemberType === MemberType.INDIVIDUAL_MERCHANT) ? [CustomValidators.email, Validators.maxLength(64)] : [Validators.required, CustomValidators.email, Validators.maxLength(64)]),
            ownerPhoneCtrl: new UntypedFormControl(accountingParty?.officePhone || '', [CustomValidators.phone]),
            briefDescriptionCtrl: new UntypedFormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(510), CustomValidators.noLeadingOrTrailingWhitespace]),
            licenseHolderCtrl: new UntypedFormControl('')
        });
    }

    getAddress(form: UntypedFormGroup): Address {
        const location = new Address();
        location.streetAddressOne = form.controls['streetAddressOneCtrl'].value;
        location.city = form.controls['cityCtrl'].value;
        location.stateProvince = form.controls['stateProvinceCtrl'].value;
        location.country = form.controls['countryCtrl'].value;
        location.zipPostalCode = form.controls['zipPostalCodeCtrl'].value;
        return location;
    }

    getContactInfo(form: UntypedFormGroup): MerchantAccountContactInfo {
        const contactInfo = new MerchantAccountContactInfo();
        contactInfo.name = form.controls['ownerNameCtrl'].value;
        contactInfo.officePhone = form.controls['ownerPhoneCtrl'].value;
        contactInfo.email = form.controls['ownerEmailCtrl'].value;
        return contactInfo;
    }

    initializeMerchantConfig(merchantConfig: MerchantAccountConfig): UntypedFormGroup {
        return this.formBuilder.group({
            merchantAccountTypeCtrl: new UntypedFormControl(merchantConfig.merchantAccountType),
            defaultIncomingTransactionTypeCtrl: new UntypedFormControl(merchantConfig.defaultIncomingTransactionType, [Validators.required]),
            defaultOutgoingTransactionTypeCtrl: new UntypedFormControl(merchantConfig.defaultOutgoingTransactionType, [Validators.required]),
            merchantAccountNoteCtrl: new UntypedFormControl(merchantConfig.merchantAccountNote),
            merchantNotificationEmailCtrl: new UntypedFormControl(merchantConfig.merchantEmailNotification)
        });
    }

    getMerchantConfig(form: UntypedFormGroup): MerchantAccountConfig {
        const merchantAccountConfig = new MerchantAccountConfig();
        merchantAccountConfig.merchantAccountType = form.controls['merchantAccountTypeCtrl'].value;
        merchantAccountConfig.defaultIncomingTransactionType = form.controls['defaultIncomingTransactionTypeCtrl'].value || null;
        merchantAccountConfig.defaultOutgoingTransactionType = form.controls['defaultOutgoingTransactionTypeCtrl'].value || null;
        merchantAccountConfig.merchantAccountNote = form.controls['merchantAccountNoteCtrl'].value;
        merchantAccountConfig.merchantEmailNotification = form.controls['merchantNotificationEmailCtrl'].value;
        return merchantAccountConfig;
    }

    validateAndUpdateMerchantForm(form: UntypedFormGroup) {
        if (form.controls['achAccountHolderCtrl'].value ||
            form.controls['achAccountNumberCtrl'].value ||
            form.controls['achRoutingNumberCtrl'].value ||
            form.controls['wireRoutingNumberCtrl'].value) {
            form.controls['achAccountHolderCtrl'].setValidators([Validators.required, Validators.minLength(3)]);
            form.controls['achAccountNumberCtrl'].setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(17), CustomValidators.onlyNumeric]);
            form.controls['achRoutingNumberCtrl'].setValidators([Validators.required, Validators.minLength(9), Validators.maxLength(9), CustomValidators.onlyNumeric]);
            form.controls['wireRoutingNumberCtrl'].setValidators([Validators.required, Validators.minLength(9), Validators.maxLength(9), CustomValidators.onlyNumeric]);
            if (form.controls['achRoutingNumberCtrl'].value) {
                form.get('wireRoutingNumberCtrl').setValidators([Validators.minLength(9), Validators.maxLength(9), CustomValidators.onlyNumeric]);
            } else if (form.controls['wireRoutingNumberCtrl'].value) {
                form.get('achRoutingNumberCtrl').setValidators([Validators.minLength(9), Validators.maxLength(9), CustomValidators.onlyNumeric]);
            }
        } else {
            form.controls['achAccountHolderCtrl'].setValidators(null);
            form.controls['achAccountNumberCtrl'].setValidators(null);
            form.controls['achRoutingNumberCtrl'].setValidators(null);
            form.controls['wireRoutingNumberCtrl'].setValidators(null);
        }
        form.controls['achAccountHolderCtrl'].updateValueAndValidity();
        form.controls['achAccountNumberCtrl'].updateValueAndValidity();
        form.controls['achRoutingNumberCtrl'].updateValueAndValidity();
        form.controls['wireRoutingNumberCtrl'].updateValueAndValidity();
    }
}
