export class BsaReport {
    id: string;
    memberName: string;
    submissionType: string;
    dateApproved: Date;
    submissionDate: string;
    credit: number;
    debit: number;
    transfer: number;
    suspiciousAmount: number;
    cumulativeAmount: number;
    acknowledgement: string;
    acknowledgedDate: string;
    memberId: string;
    status: ReportStatus;
    riskType: string;
    reportType: ReportType;
    filePath: string;
    name: string;
    created: Date;
    startDate: Date;
    endDate: Date;
    documentDetails: any;
    documentId: string;
    bsaId: string;
    trackingId: string;
    trackingDocumentId: string;
    trackingDocumentDetails: any;
    acknowledgedDocumentId: string;
    acknowledgedDocumentDetails: any;
    notes: string;
    transactionId: string;
    filingInstitution: FilingInstitution;
    actualReportTriggerDate: Date;
    narrativeText: string;
    externalProviderNotes: string;
}

export enum ReportType {
    INITIAL_REPORT = 'INITIAL_REPORT',
    CONTINUING_REPORT = 'CONTINUING_REPORT',
    DEPOSIT_CTR_REPORT = 'DEPOSIT_CTR_REPORT',
    WITHDRAWAL_CTR_REPORT = 'WITHDRAWAL_CTR_REPORT',
    FINAL_CONTINUING_REPORT = 'FINAL_CONTINUING_REPORT',
    CTR_REPORT = 'CTR_REPORT',
    NONE = 'NONE'
}

export enum ReportStatus {
    ALL = 'ALL',
    AUTHORITY_REVIEW = 'AUTHORITY_REVIEW',
    READY_FOR_SUBMISSION = 'READY_FOR_SUBMISSION',
    SUBMISSION_IN_PROGRESS = 'SUBMISSION_IN_PROGRESS',
    SUBMISSION_SUCCESSFUL = 'SUBMISSION_SUCCESSFUL',
    SUBMISSION_FAILED = 'SUBMISSION_FAILED',
    ACCEPTED = 'ACCEPTED',
    ACCEPTED_WITH_WARNINGS = 'ACCEPTED_WITH_WARNINGS',
    REJECTED = 'REJECTED',
    ACKNOWLEDGED = 'ACKNOWLEDGED',
    ACKNOWLEDGEMENT_FAILED = 'ACKNOWLEDGEMENT_FAILED',
    FILED_MANUALLY = 'FILED_MANUALLY',
    WILL_NOT_BE_SUBMITTED = 'WILL_NOT_BE_SUBMITTED'
}

export enum FilingInstitution {
    POINTCHAIN = 'POINTCHAIN',
    NBCU = 'NBCU'
}
