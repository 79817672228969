import { GQLQueryType } from 'projects/services/src/lib/graphql/queries/gql-query-type';

export const gqlWorkflowTaskFields = `
    id
    name
    assignee
    created
    due
    followUp
    delegationState
    description
    executionId
    owner
    parentTaskId
    priority
    processDefinitionId
    processDefinitionName
    processInstanceId
    taskDefinitionKey
    caseExecutionId
    caseInstanceId
    caseDefinitionId
    suspended
    formKey
    tenantId
    variables
`;

export const gqlProcessDefinitionFields = `
    id
    key
    category
    description
    name
    version
    resource
    deploymentId
    diagram
    suspended
    tenantId
    versionTag
    historyTimeToLive
    startableInTasklist
`;

export abstract class GqlWorkflowTask {

    static gqlWorkflowTaskPageFragment = 'WorkflowTaskPage';

    static searchTasks(): GQLQueryType {
        return {
            params() {
                return `$processVariables: String, $processDefinitionKey: String, $processInstanceId: String, 
                    $taskDefinitionKeys: [String], $assignee: String, $filtered: Boolean`;
            },

            query(selectedFields: String = gqlWorkflowTaskFields, pageParams?: String) {
                return `searchTasks(processVariables: $processVariables, processDefinitionKey: $processDefinitionKey,
                                    processInstanceId: $processInstanceId, taskDefinitionKeys: $taskDefinitionKeys,
                                    assignee: $assignee, filtered: $filtered, ${pageParams}) {
                                    
                            content{
                                ${selectedFields}
                            }
                }`;

            }
        };
    }
}
