<div class="dashboard-info">
    <div *ngIf="member" class="row no-gutters">
        <div class="col-md-3">
            <div class="dashboard-header no-px">
                <img class="dashboard-icon" src="assets/images/settings-icon-2.svg" alt="">
                <span>Settings</span>
            </div>
            <div class="col-12 tab-col" [ngClass]="getTabClass('member-overview')" (click)="onSwitchTabs('member-overview')">
                Member Overview
            </div>
            <div class="col-12 tab-col" [ngClass]="getTabClass('linked_systems')" (click)="onSwitchTabs('linked_systems')">
                Linked Systems
            </div>
            <div class="col-12 tab-col" [ngClass]="getTabClass('bank_accounts')" (click)="onSwitchTabs('bank_accounts')">
                Banking
            </div>
            <div class="col-12 tab-col" [ngClass]="getTabClass('api_access')" (click)="onSwitchTabs('api_access')">
                API Access
            </div>
            <div *ngIf="!isPartner" class="col-12 tab-col" [ngClass]="getTabClass('partners')" (click)="onSwitchTabs('partners')">
                Partners
            </div>
            <div *ngIf="isPartner" class="col-12 tab-col" [ngClass]="getTabClass('partner_settings')" (click)="onSwitchTabs('partner_settings')">
                Partner Settings
            </div>
            <div class="col-12 tab-col" [ngClass]="getTabClass('merchants')" (click)="onSwitchTabs('merchants')">
                Merchants
            </div>
            <div class="col-12 tab-col" *ngIf="authService.isCorporateAdmin()" [ngClass]="getTabClass('key_parties')" (click)="onSwitchTabs('key_parties')">
                Key Parties
            </div>
            <div class="col-12 tab-col" [ngClass]="getTabClass('locations')" (click)="onSwitchTabs('locations')">
                Locations
            </div>
            <div class="col-12 tab-col" [ngClass]="getTabClass('licenses')" (click)="onSwitchTabs('licenses')">
                Licenses
            </div>
            <div class="col-12 tab-col" *ngIf="authService.isCorporateAdmin()" [ngClass]="getTabClass('documents')" (click)="onSwitchTabs('documents')">
                Documents
            </div>
        </div>
        <div class="col-sm-9 col-md-9 col-lg-9 tab-body" *ngIf="activeTab === 'member-overview'">
            <pt-member-overview></pt-member-overview>
        </div>
        <div class="col-sm-9 col-md-9 col-lg-9 tab-body" *ngIf="activeTab === 'linked_systems' && member">
            <pt-member-linked-systems
                [requiresMrb]="metrcEnabled"
                [requiresAccounting]="true"
                [memberId]="memberId">
            </pt-member-linked-systems>
        </div>
        <div class="col-sm-9 col-md-9 col-lg-9 tab-body" *ngIf="activeTab === 'bank_accounts'">
            <pt-bank-accounts></pt-bank-accounts>
        </div>
        <div class="col-sm-9 col-md-9 col-lg-9 tab-body" *ngIf="activeTab === 'locations'">
            <pt-location-list [memberId]="memberId" [readOnly]="!authService.isAdmin()" [allowAdd]="authService.isAdmin()">
            </pt-location-list>
        </div>
        <div class="col-sm-9 col-md-9 col-lg-9 tab-body" *ngIf="activeTab === 'licenses'">
            <pt-license-list [memberId]="memberId" [readOnly]="false">
            </pt-license-list>
        </div>
        <div class="col-sm-9 col-md-9 col-lg-9 tab-body" *ngIf="activeTab === 'merchants'">
            <pt-member-merchant-list [memberId]="memberId" [jurisdiction]="member.jurisdiction" [tier]="member.tier">
            </pt-member-merchant-list>
        </div>
        <div *ngIf="activeTab === 'key_parties'" class="col-sm-9 col-md-9 col-lg-9 tab-body">
            <pt-active-key-party-list [memberId]="memberId" [memberRegistrationType]="memberRegistrationType" [readOnly]="true" [memberType]="member.memberType"
                [memberStatus]="member?.status">
            </pt-active-key-party-list>
        </div>
        <div class="col-sm-9 col-md-9 col-lg-9 tab-body" *ngIf="activeTab === 'api_access'">
            <pt-api-access-settings [memberId]="memberId">
            </pt-api-access-settings>
        </div>
        <div class="col-sm-9 col-md-9 col-lg-9 tab-body" *ngIf="activeTab === 'partners' && member">
            <pt-partner-list [memberId]="memberId" [memberType]="member?.memberType">
            </pt-partner-list>
        </div>
        <div class="col-sm-9 col-md-9 col-lg-9 tab-body" *ngIf="activeTab === 'partner_settings'">
            <pt-partner-settings
                [memberId]="memberId">
            </pt-partner-settings>
        </div>
        <div class="col-sm-9 col-md-9 col-lg-9 tab-body" *ngIf="activeTab === 'documents'">
            <pt-member-other-documents
                [memberId]="memberId"
                [businessCategory]="member?.businessCategory">
            </pt-member-other-documents>
        </div>
    </div>
</div>
