import { KeyPartyType } from 'projects/services/src/lib/models/key-party';
import { SearchOrigin } from './search-origin';
import { SearchMatchStatus } from './search-match-status';
import { SearchRiskLevel } from './search-risk-level';

/**
 * Kyc search result data.
 *
 * @author David Teles
 */
export class SearchResult {

    id: string;
    subjectEntityId: string;
    subjectEntityType: KeyPartyType;
    origin: SearchOrigin;
    fuzziness: string;
    searchId: string;
    searchRef: string;
    searchUrl: string;
    searchTerm: string;
    matchStatus: SearchMatchStatus;
    riskLevel: SearchRiskLevel;
    totalHits: number;
    totalMatches: number;
    monitored: boolean;
    strict: boolean;
    extra: boolean;
    exactMatch: boolean;
    birthYear: string;
    countries: string;
    created: Date;
    updated: Date;

}
