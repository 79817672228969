import { Component, OnInit, Inject } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay } from '@angular/cdk/overlay';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    CorporateEntity, MemberMergeService, Transaction
} from 'projects/services/src/public-api';
import { BaseModalComponent } from 'projects/components/src/lib/modal';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { LoaderComponent } from 'projects/components/src/lib/loader';

@Component({
    selector: 'pc-transfer-funds-modal',
    templateUrl: './member-merge-modal.component.html'
})
export class MemberMergeModalComponent extends BaseModalComponent<MemberMergeModalComponent> implements OnInit {

    sourceMemberId: string;
    sourceMemberName: string;
    relatedMembers: CorporateEntity[] = [];

    selectedMember: CorporateEntity;
    memberMergeForm: UntypedFormGroup;
    memberValid = true;

    constructor(private memberMergeService: MemberMergeService,
                private notifier: NotificationService,
                private overlay: Overlay,
                private formBuilder: UntypedFormBuilder,
                dialogRef: MatDialogRef<MemberMergeModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.relatedMembers = data.relatedMembers;
        this.sourceMemberId = data.sourceMemberId;
        this.sourceMemberName = data.sourceMemberName;
    }

    ngOnInit() {
        this.onSubmit = this.onSubmit.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.memberMergeService.validateMemberMergeable(this.sourceMemberId).subscribe((valid: boolean) => {
            this.memberValid = valid;
            if (this.memberValid) {
                this.memberMergeForm = this.formBuilder.group({
                    targetMemberIdCtrl: new UntypedFormControl('', [Validators.required])
                });
                if (this.relatedMembers.length === 1) {
                    this.selectedMember = this.relatedMembers[0];
                    this.memberMergeForm.controls['targetMemberIdCtrl'].setValue(this.selectedMember.id);
                    this.memberMergeForm.controls['targetMemberIdCtrl'].disable();
                }
            }
        });
    }

    close() {
        super.close();
    }

    onSubmit() {
        const targetMemberId = this.memberMergeForm.controls['targetMemberIdCtrl'].value;
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
        componentRef.instance.title = 'Merging member...';

        this.memberMergeService.mergeMembers(this.sourceMemberId, targetMemberId).subscribe((result: Transaction) => {
            super.close(targetMemberId);
            overlayRef.dispose();
            this.notifier.showSuccessCloseRequired(`<b>${this.sourceMemberName}</b> has been merged to <b>${this.selectedMember.name}</b>. <br><br>Please review the account names on ${this.selectedMember.name} and change accordingly.`);
        }, (error: any) => {
            overlayRef.dispose();
            super.close();
            throw error;
        });
    }

    onSelectTargetMember(selectedMemberId: string) {
        if (selectedMemberId) {
            this.selectedMember = this.relatedMembers.find((relatedMember: CorporateEntity) => {
                return relatedMember.id === selectedMemberId;
            });
        } else {
            this.selectedMember = null;
        }
    }

    isFormValid() {
        return this.memberMergeForm && this.memberMergeForm.controls['targetMemberIdCtrl'].value;
    }
}
