import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from '../models/user';
import { CustomValidators } from '../validators/custom.validators';
import { NewUserRequest } from '../models/new-user-request';
import { Role } from '../models/profile';

@Injectable({
    providedIn: 'root'
})
export class UserFormService {

    constructor(private formbuilder: UntypedFormBuilder) { }

    initializeForm(user: User): UntypedFormGroup {
        const form = this.formbuilder.group({
            idCtrl: new UntypedFormControl(),
            createdCtrl: new UntypedFormControl(),
            updatedCtrl: new UntypedFormControl(),

            firstNameCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.personName]),
            middleNameCtrl: new UntypedFormControl(null, [CustomValidators.middleName]),
            lastNameCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.personName]),

            emailCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.email]),
            mobilePhoneCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.phone]),
            statusCtrl: new UntypedFormControl(),

            accountRoleCtrl: new UntypedFormControl()
        });
        if (user) {
            form.patchValue({
                idCtrl: user.id,
                createdCtrl: user.created,
                updatedCtrl: user.updated,

                firstNameCtrl: user.firstName,
                middleNameCtrl: user.middleName,
                lastNameCtrl: user.lastName,

                emailCtrl: user.email,
                mobilePhoneCtrl: user.mobilePhone,
                statusCtrl: user.status,

                accountRoleCtrl: user.accountRole
            });
        }
        return form;
    }

    getUser(form: UntypedFormGroup): User {
        const user = new User();
        user.id = form.controls['idCtrl'].value;
        user.created = form.controls['createdCtrl'].value;
        user.updated = form.controls['updatedCtrl'].value;

        user.firstName = form.controls['firstNameCtrl'].value;
        user.middleName = form.controls['middleNameCtrl'].value;
        user.lastName = form.controls['lastNameCtrl'].value;

        user.email = form.controls['emailCtrl'].value;
        user.mobilePhone = form.controls['mobilePhoneCtrl'].value;
        user.status = form.controls['statusCtrl'].value;

        return user;
    }

    newUserForm(memberId: string, memberAccountId: string, keyPartyLinkRequired: boolean): UntypedFormGroup {
        return this.formbuilder.group({
            memberIdCtrl: new UntypedFormControl(memberId, [Validators.required]),
            memberAccountIdCtrl: new UntypedFormControl(memberAccountId),
            userIdCtrl: new UntypedFormControl(),
            emailCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.email, Validators.maxLength(64)]),
            firstNameCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.personName, Validators.maxLength(32)]),
            middleNameCtrl: new UntypedFormControl('', [CustomValidators.middleName, Validators.maxLength(32)]),
            lastNameCtrl: new UntypedFormControl('', [Validators.required,  CustomValidators.personName, Validators.maxLength(32)]),
            titleCtrl: new UntypedFormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(64), CustomValidators.noLeadingOrTrailingWhitespace]),
            mobilePhoneCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.phone]),
            mfaCtrl: new UntypedFormControl(''),
            keyPartyIdCtrl: new UntypedFormControl('', keyPartyLinkRequired ? [Validators.required] : null)
        });
    }

    getNewUserRequest(form: UntypedFormGroup, role: Role): NewUserRequest {
        const newUserRequest = new NewUserRequest();
        newUserRequest.role = role;
        newUserRequest.memberId = form.controls['memberIdCtrl'].value;
        newUserRequest.memberAccountId = form.controls['memberAccountIdCtrl'].value;
        newUserRequest.userId = form.controls['userIdCtrl'].value;
        newUserRequest.keyPartyId = form.controls['keyPartyIdCtrl'].value || null;

        newUserRequest.firstName = form.controls['firstNameCtrl'].value;
        newUserRequest.middleName = form.controls['middleNameCtrl'].value;
        newUserRequest.lastName = form.controls['lastNameCtrl'].value;

        newUserRequest.email = form.controls['emailCtrl'].value;
        newUserRequest.jobTitle = form.controls['titleCtrl'].value;
        newUserRequest.mobilePhone = form.controls['mobilePhoneCtrl'].value;
        newUserRequest.mfaType = form.controls['mfaCtrl'].value;

        return newUserRequest;
    }
}
