<div class="row">
    <div class="col-4 centered">
        <div class="data-block">
            <div><span class="small">No. Invoices Outstanding</span></div>
            <div><span class="h4">{{noInvoicesOutstanding}}</span></div>
        </div>
    </div>
    <div class="col-4 centered">
        <div class="data-block">
            <div><span class="small">Total Owed</span></div>
            <div><span class="h4">{{totalOwed | currency}}</span></div>
        </div>
    </div>
    <div class="col-4 centered">
        <div class="data-block">
            <div><span class="small">Average Days Receivable</span></div>
            <div><span class="h4">{{daysSalesOutstanding}}</span></div>
        </div>
    </div>
</div>
<div class="mt-3"><span class="form-title">All Customers</span></div>
<div class="col-sm-12 horizontal-scroll mat-table-container pt-3">
    <div class="mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <table mat-table [dataSource]="accountingCustomers">
        <ng-container matColumnDef="customer_name">
            <th mat-header-cell *matHeaderCellDef>  Customer Name </th>
            <td mat-cell *matCellDef="let element">
                <b>{{ element.name }}</b>
            </td>
        </ng-container>
        <ng-container matColumnDef="concentration">
            <th mat-header-cell *matHeaderCellDef> Concentration % </th>
            <td mat-cell *matCellDef="let element">
                <span class="table-amount">
                    <b>{{ formatConcentration(element.concentration) }}</b>
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="total_owed">
            <th mat-header-cell class="centered" *matHeaderCellDef> Total Owed </th>
            <td mat-cell class="centered" *matCellDef="let element">
                <span class="table-amount">
                    <b>{{ element.outstanding | currency }}</b>
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="average_days_receivable">
            <th mat-header-cell class="centered" *matHeaderCellDef> Average Days Receivable </th>
            <td mat-cell  class="centered" *matCellDef="let element">
                <b> {{ element.daysSalesOutstanding }} </b>
            </td>
        </ng-container>
        <ng-container matColumnDef="no_invoices_outstanding">
            <th mat-header-cell class="centered" *matHeaderCellDef>  No. Invoices Outstanding </th>
            <td mat-cell  class="centered" *matCellDef="let element">
                <b> {{ element.noInvoicesOutstanding }} </b>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator #paginator [pageIndex]="paginator.pageIndex" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page">
  </mat-paginator>
</div>
