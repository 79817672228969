import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { WeeklyReport, AuthService, ReportsService, PagedResponse } from 'projects/services/src/public-api';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
@Component({
    selector: 'pt-member-weekly-activity-report',
    templateUrl: './member-weekly-activity-report.component.html'
})
export class MemberWeeklyActivityReportComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

    @Input() memberId: string;
    @Input() memberAccountId: string;

    isLoadingResults = true;
    displayedColumns: string[] = ['created_date', 'total_credits', 'total_debits', 'copy_of_file'];
    weeklyReports: WeeklyReport[] = [];

    pageTracking: PageTracking;
    resultsLength = 0;
    subscription: any;
    refreshEvent: EventEmitter<null> = new EventEmitter<null>();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('downloadReportLink') downloadReportLink: ElementRef;

    constructor(public authService: AuthService,
                private route: ActivatedRoute,
                private router: Router,
                private reportService: ReportsService,
                private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        if (!this.memberId) {
            this.memberId = this.authService.getProfile().memberId;
        }
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'created', 'desc', 100);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.memberAccountId && !changes.memberAccountId.firstChange)) {
            this.refreshEvent.emit();
        }
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.pageIndex = 0;
        });
        this.subscription = merge(this.paginator.page, this.refreshEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                return this.reportService.getWeeklyReportsByMemberId(this.memberId, this.memberAccountId, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<WeeklyReport>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((weeklyReports: WeeklyReport[]) => {
            this.weeklyReports = weeklyReports;
            UIUtils.scrollDashboardToTop();
        });
    }

    downloadResource(weeklyReport: WeeklyReport) {
        this.reportService.downloadResource(weeklyReport, this.downloadReportLink);
    }

    handlePageBottom(event: PageEvent) {
        this.paginator.pageSize = event.pageSize;
        this.paginator.pageIndex = event.pageIndex;
        this.paginator.page.emit(event);
    }

}
