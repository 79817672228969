import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
    AuthService, DataroomService, DocumentType, KeyParty, KeyPartyService, KeyPartyType, License, LicenseFormService, LicenseService, PagedResponse, Upload, Utils
} from 'projects/services/src/public-api';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from 'projects/components/src/lib/modal';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pc-msa-license-modal',
    templateUrl: './msa-license-modal.component.html'
})
export class MsaLicenseModalComponent extends BaseModalComponent<MsaLicenseModalComponent> implements OnInit {

    msaLicenseForm: UntypedFormGroup;
    license: License;
    licenseHolders: KeyParty[] = [];
    msaDocumentFile: File;
    error: string;

    constructor(private licenseService: LicenseService,
                private licenseFormService: LicenseFormService,
                public authService: AuthService,
                public keyPartyService: KeyPartyService,
                public dataroomService: DataroomService,
                private notificationService: NotificationService,
                private cdr: ChangeDetectorRef,
                dialogRef: MatDialogRef<MsaLicenseModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.license = data.license;
    }

    ngOnInit() {
        this.isMsaLicenseFormValid = this.isMsaLicenseFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.msaLicenseForm = this.licenseFormService.initializeMsaForm(this.license);
        this.cdr.detectChanges();
        if (this.license?.msaDocumentId) {
            this.dataroomService.getDocumentById(this.license.msaDocumentId).subscribe((upload: Upload) => {
                this.msaDocumentFile = new File([], upload.fileName);
            });
        }
        this.keyPartyService.getAllActiveKeyParties(this.license.memberId).subscribe((keyParties: PagedResponse<KeyParty>) => {
            this.licenseHolders = keyParties.content.filter((keyParty) => {
                return keyParty.licenseHolder && keyParty.type !== KeyPartyType.CORPORATE_ENTITY;
            });
            this.licenseHolders.forEach((keyParty) => {
                if (keyParty.corporateKeyPartyId) {
                    keyParty.name = keyParty.corporateKeyParty.name;
                } else {
                    keyParty.name = keyParty.individualKeyParty.name;
                }
            });
            if (this.licenseHolders.length === 0) {
                this.msaLicenseForm.disable();
                this.error = 'No key parties that are license holders.';
            }
        });
    }

    close(refresh?: boolean) {
        this.msaDocumentFile = null;
        super.close(refresh);
    }

    selectMsaDocumentFile(event: any) {
        if (event.target.files && event.target.files.length) {
            const file = event.target.files[0];
            Utils.validateFile(file, event);
            this.msaDocumentFile = file;
            event.target.value = '';
            this.msaLicenseForm.get('msaDocumentIdCtrl').setValue(null);
        }
    }

    onDeleteMsaDocumentFile() {
        this.msaDocumentFile = null;
        this.msaLicenseForm.get('msaDocumentIdCtrl').setValue(null);
    }

    onSubmit(reset: any) {
        const updates = this.licenseFormService.getUpdatedMsaValues(this.msaLicenseForm);
        updates.msaEnabled = true;
        if (!updates.msaDocumentId) {
            const path = this.license.memberId + '/licenses/' + this.license.jurisdiction.replace(' ', '_') + '/' + this.license.id + '/msa';
            this.dataroomService.uploadFile(this.license.memberId, path, this.msaDocumentFile, this.msaDocumentFile.name, '', DocumentType.LICENSE, this.license.memberId).subscribe((uploadResp: Upload) => {
                updates.msaDocumentId = uploadResp.id;
                this.updateMsaData(updates, reset);
            }, () => {
                reset();
                this.notificationService.showError('Document upload was unsuccessful. Please check your connection and try again.');
            });
        } else {
            this.updateMsaData(updates, reset);
        }
    }

    updateMsaData(updates: any, reset: any) {
        this.licenseService.updateLicense(this.license.id, updates).subscribe(() => {
            reset();
            this.close(true);
        }, (error) => {
            reset();
            throw error;
        });
    }

    isMsaLicenseFormValid() {
        return this.licenseHolders.length > 0 && !this.msaLicenseForm.invalid && this.msaDocumentFile;
    }

}
