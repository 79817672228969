<div class="horizontal-scroll mat-table-container mp-2">
    <div class="col-auto dashboard-header mb-3">
        <img class="dashboard-icon" src="assets/images/transaction-2.svg" alt="">
        <span *ngIf="report.reportType === ReportType.CONTINUING_REPORT || report.reportType === ReportType.FINAL_CONTINUING_REPORT"> {{ postedTxnType | translate }} </span>
        <span *ngIf="report.reportType === ReportType.CTR_REPORT && !report.transactionId && report.filingInstitution === FilingInstitution.NBCU"> {{ subType | translate }} </span>
    </div>
    <div class="mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <table  class="no-wrap table-sm table-hover" mat-table [dataSource]="transactions">
        <ng-container matColumnDef="transactionType">
            <th mat-header-cell *matHeaderCellDef > Type </th>
            <td mat-cell *matCellDef="let element">
                {{ element.transactionType === TransactionType.ACH_DEPOSIT && element.source === TransactionSource.PAYMENT_PROCESSOR ? 'ACH Deposit' : element.transactionType | translate }}
                <span *ngIf="element.apiOwnerId"> (Partner) </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="payor">
            <th mat-header-cell *matHeaderCellDef > Payor </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element?.payorMemberId && element?.payorMemberName">
                    <pc-load-member-details [linkDescription]="element.payorMemberName" [memberId]="element.payorMemberId" [linkType]="'table'"></pc-load-member-details>
                </span>
                <span *ngIf="element.payorMemberName && !element.payorMemberId"> {{ element.payorMemberName }}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="payee">
            <th mat-header-cell *matHeaderCellDef > Recipient </th>
            <td mat-cell  *matCellDef="let element">
                <span *ngIf="element?.recipientMemberId && element?.recipientMemberName">
                    <pc-load-member-details [linkDescription]="element.recipientMemberName" [memberId]="element.recipientMemberId" [linkType]="'table'"></pc-load-member-details>
                </span>
                <span *ngIf="element.recipientMemberName && !element.recipientMemberId"> {{ element.recipientMemberName }} </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="completionDate">
            <th mat-header-cell class="centered" *matHeaderCellDef > Date Completed </th>
            <td mat-cell  class="centered" *matCellDef="let element">
                <span *ngIf="element.completionDate">
                    <pc-date-time [date]="element.completionDate"></pc-date-time>
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="amount">
            <th mat-header-cell class="centered" *matHeaderCellDef > Amount </th>
            <td mat-cell  class="centered" *matCellDef="let element">
                <span class="table-amount">
                    <pc-transaction-amount [transaction]="element" [memberId]="report.memberId" [postedTxnType]="postedTxnType"></pc-transaction-amount>
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="score">
            <th mat-header-cell class="centered" *matHeaderCellDef > Score </th>
            <td mat-cell class="centered"  *matCellDef="let element">
                <span > {{ element.pointTrustScore }}</span>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="pc-click-row" (click)="loadTransactionDetails(row.id, $event)"></tr>
    </table>
    <div class="mt-2" *ngIf="report.reportType === ReportType.CONTINUING_REPORT || report.reportType === ReportType.FINAL_CONTINUING_REPORT">
        <div class="float-end">
            <b> Total {{ postedTxnType | translate }} : {{ (postedTxnType === PostedTransactionType.CREDIT ? report.credit : (postedTxnType === PostedTransactionType.DEBIT ? report.debit : report.transfer) ) | currency}} </b>
        </div>
    </div>
    <div class="mt-2" *ngIf="report.reportType === ReportType.CTR_REPORT && !report.transactionId">
        <div class="float-end">
            <b> Total {{ subType | translate }} : {{ (subType === TransactionSubType.DEPOSIT ? report.credit : report.debit) | currency}} </b>
        </div>
    </div>
</div>
