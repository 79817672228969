<div class="top-header">
    <div [class]="getMenuClass()" (click)="onToggleExpandSideMenu()">
        <div class="top-header-icon-content">
            <img src="assets/images/Confia-icon.png" *ngIf="!expandedMenu">
            <img src="assets/images/confia-logo.svg" *ngIf="expandedMenu">
        </div>
    </div>
    <div [class]="getBodyClass()">
        <div class="top-header-heading col-md-4">
            <h1 *ngIf="profile">{{profile.memberName}}</h1>
        </div>
        <div class="top-header-menu col-md-8">
            <div class="search-box">
                <pc-global-search-input [placeholder]="'Search'" [formCtrl]="'entitySearchCtrl'" [formGroup]="formGroup" (search)="search($event)"></pc-global-search-input>
            </div>
            <div class="header-link" [class.active]="isActive('Communication')">
                <a [routerLink]="isDisabled('/communication/messages') ? null : ['/communication/messages']" [routerLinkActive]="['router-link-active']">
                    <span [ngClass]="getMessageClass()" matBadgeColor="warn" matBadge="{{getUnreadMessageCount()}}" [matBadgeHidden]="getUnreadMessageCount() === 0" matTooltip="Messages"></span>
                </a>
            </div>
            <div *ngIf="authService.isAuthority()" class="header-link" [class.active]="isActive('Notifications')">
                <a [routerLink]="isDisabled('/alert/list') ? null : ['/alert/list']"
                [routerLinkActive]="['router-link-active']"><i class="fa fa-bell-o" matTooltip="Alerts"></i></a>
            </div>
            <div *ngIf="authService.isAuthority()" class="header-link" [class.active]="isActive('Notifications')">
                <a [routerLink]="isDisabled('/communication/notifications') ? null : ['/communication/notifications']" [routerLinkActive]="['router-link-active']">
                    <span matBadgeColor="warn" matBadge="{{getOpenItemCount()}}" [matBadgeHidden]="getOpenItemCount() === 0" matTooltip="Open Items">
                        <img src="assets/images/task2-icon-2.svg" alt="Open Items" class="dashboard-icon header-open-item">
                    </span>
                </a>
            </div>
            <div class="header-link" [class.active]="isActive('Administration')">
                <a [routerLink]="isDisabled('/administration/account') ? null : ['/administration/account']"
                [routerLinkActive]="['router-link-active']"><i class="fa fa-user-circle" matTooltip="Account"></i></a>
            </div>
            <div class="header-link" *ngIf="isMultiMemberUser()">
                <i class="fa fa-group" (click)="switchOrg()" aria-hidden="true" matTooltip="Switch Member"></i>
            </div>
            <div class="header-link" [class.active]="isActive('Log Out')">
                <i class="fa fa-sign-out" (click)="onLoggedout()" aria-hidden="true" matTooltip="Log Out"></i>
            </div>
        </div>
    </div>
</div>
