import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import {
    AuditAction,
    AuditRecord,
    AuditService,
    AuthService,
    Member,
    MemberService,
    MemberStatus,
    MemberType,
    PagedResponse,
    ReferencedEntityType,
    User,
    UserService
} from 'projects/services/src/public-api';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pt-audit-table-list',
    templateUrl: './audit-table-list.component.html',
    styleUrls: ['./audit-table-list.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class AuditTableListComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

    readonly LEGACY_MEMBER_DATE = new Date('2021-07-15');

    @Input() memberId = '';
    @Input() action = '';
    @Input() auditEntityType = '';

    auditRecords: AuditRecord[] = [];
    resultsLength = 0;
    pageTracking: PageTracking;
    isLoadingResults = true;
    expandedElement: AuditRecord;

    displayedColumns: string[] = ['performed', 'memberAccount', 'user', 'partner', 'ipAddress', 'action', 'auditEntityType', 'referencedEntity'];
    subscription: any;
    filterEvent: EventEmitter<null> = new EventEmitter<null>();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(public authService: AuthService,
                private route: ActivatedRoute,
                private router: Router,
                private auditService: AuditService,
                private memberService: MemberService,
                private userService: UserService,
                private cdr: ChangeDetectorRef) {}

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnInit() {
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'created', 'desc');
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.memberId && !changes.memberId.firstChange ||
            changes.action && !changes.action.firstChange ||
            changes.auditEntityType && !changes.auditEntityType.firstChange) {
            this.filterEvent.emit();
        }
    }

    loadData(element: any, event: any) {
        if (event.target.tagName !== 'SPAN') {
            this.expandedElement = this.expandedElement === element ? null : element;
        }
    }

    loadMemberAndUserData(auditRecord: AuditRecord) {
        this.memberService.loadMember(auditRecord.performedByMemberId).subscribe((member: Member) => {
            auditRecord.member = member;
        });
        if (auditRecord.performedByPartnerId) {
            this.memberService.loadMember(auditRecord.performedByPartnerId).subscribe((partner: Member) => {
                auditRecord.partner = partner;
            });
        }
        if (auditRecord.performedByUserId) {
            this.userService.loadUser(auditRecord.performedByUserId).subscribe((user: User) => {
                auditRecord.user = user;
            });
        }
        switch (auditRecord.referencedEntityType) {
            case ReferencedEntityType.LICENSE:
            case ReferencedEntityType.DOCUMENT:
            case ReferencedEntityType.MEMBER_ACCOUNT:
            case ReferencedEntityType.MEMBER:
            case ReferencedEntityType.TAX_INFO:
            case ReferencedEntityType.SIGNATURE:
            case ReferencedEntityType.FINANCIAL_DETAILS:
            case ReferencedEntityType.RISK_PROFILE:
            case ReferencedEntityType.BANK_ACCOUNT:
            case ReferencedEntityType.LOCATION:
            case ReferencedEntityType.FINCEN_REPORT:
            case ReferencedEntityType.BILLING_PROFILE:
            case ReferencedEntityType.PAYMENT_PROCESSOR_MERCHANT:
                if (auditRecord.action !== AuditAction.DELETE) {
                    if (new Date(auditRecord.created) > this.LEGACY_MEMBER_DATE) {
                        if (auditRecord.referencedEntityType === ReferencedEntityType.LICENSE) {
                            auditRecord.referencedEntityLinkParams = {_activeTab: 'licenses'};
                        } else if (auditRecord.referencedEntityType === ReferencedEntityType.DOCUMENT) {
                            auditRecord.referencedEntityLinkParams = {_activeTab: 'documents'};
                        } else if (auditRecord.referencedEntityType === ReferencedEntityType.LOCATION) {
                            auditRecord.referencedEntityLinkParams = {_activeTab: 'locations'};
                        } else if (auditRecord.referencedEntityType === ReferencedEntityType.FINCEN_REPORT) {
                            auditRecord.referencedEntityLinkParams = {_activeTab: 'accounts'};
                        } else if (auditRecord.referencedEntityType === ReferencedEntityType.BILLING_PROFILE) {
                            auditRecord.referencedEntityLinkParams = {memberId: auditRecord.memberId, _transactionTab: 'BILLING_INFO'};
                        }
                        if (auditRecord.memberId) {
                            this.memberService.loadMember(auditRecord.memberId).subscribe((member: Member) => {
                                if (member && member.status !== MemberStatus.REJECTED) {
                                    if (auditRecord.referencedEntityType === ReferencedEntityType.BILLING_PROFILE) {
                                        auditRecord.referencedEntityLink = '/member-accounts/details/' + auditRecord?.jsonData['memberAccountId'];
                                    } else {
                                        if (member.memberType === MemberType.BUSINESS_MERCHANT) {
                                            auditRecord.referencedEntityLink = '/administration/merchant/' + auditRecord.memberId;
                                        } else if (member.memberType === MemberType.INDIVIDUAL_MERCHANT) {
                                            auditRecord.referencedEntityLink = '/administration/individual/' + auditRecord.memberId;
                                        } else if (member.memberType !== MemberType.AUTHORITY) {
                                            auditRecord.referencedEntityLink = '/administration/member/' + auditRecord.memberId;
                                        }
                                    }
                                }
                            });
                        }
                    } else {
                        // legacy audit records prior to 2021-07-15 do not have the memberId set properly
                        auditRecord.referencedEntity = auditRecord.referencedEntityId;
                    }
                }
                break;
            case ReferencedEntityType.MERCHANT:
            case ReferencedEntityType.MERCHANT_CONFIG:
                this.memberService.loadMember(auditRecord.referencedEntityId).subscribe((member: Member) => {
                    if (member) {
                        if (member.memberType === MemberType.BUSINESS_MERCHANT) {
                            auditRecord.referencedEntityLink = '/administration/merchant/' + auditRecord.referencedEntityId;
                        } else {
                            auditRecord.referencedEntityLink = '/administration/individual/' + auditRecord.referencedEntityId;
                        }
                    }
                });
                break;
            case ReferencedEntityType.TRANSACTION:
                auditRecord.referencedEntity = auditRecord.referencedEntityId;
                auditRecord.referencedEntityLink = '/transaction/details/' + auditRecord.referencedEntityId;
                break;
            case ReferencedEntityType.RFI_HISTORY:
                auditRecord.referencedEntity = auditRecord.referencedEntityId;
                auditRecord.referencedEntityLink = '/rfi/details/' + auditRecord.referencedEntityId;
                break;
            case ReferencedEntityType.KEY_PARTY:
                if (auditRecord.action !== AuditAction.DELETE) {
                    if (new Date(auditRecord.created) > this.LEGACY_MEMBER_DATE) {
                        auditRecord.referencedEntityLinkParams = {_activeTab: 'internal'};
                        this.memberService.loadMember(auditRecord.memberId).subscribe((member: Member) => {
                            if (member) {
                                if (member.memberType === MemberType.BUSINESS_MERCHANT) {
                                    auditRecord.referencedEntityLink = '/administration/merchant/' + auditRecord.memberId;
                                } else if (member.memberType === MemberType.INDIVIDUAL_MERCHANT) {
                                    auditRecord.referencedEntityLink = '/administration/individual/' + auditRecord.memberId;
                                } else if (member.memberType !== MemberType.AUTHORITY) {
                                    auditRecord.referencedEntityLink = '/administration/member/' + auditRecord.memberId;
                                }
                            }
                        });
                    } else {
                        // legacy audit records prior to 2021-07-15 do not have the memberId set properly
                        auditRecord.referencedEntity = auditRecord.referencedEntityId;
                    }
                }
                break;
            case ReferencedEntityType.ACCOUNT:
                if (new Date(auditRecord.created) > this.LEGACY_MEMBER_DATE) {
                    auditRecord.referencedEntityLinkParams = {_activeTab: 'users'};
                    this.memberService.loadMember(auditRecord.memberId).subscribe((member: Member) => {
                        if (member) {
                            auditRecord.referencedEntityLink = '/administration/member/' + auditRecord.memberId;
                        }
                    });
                } else {
                    // legacy audit records prior to 2021-07-15 do not have the memberId set properly
                    auditRecord.referencedEntity = auditRecord.referencedEntityId;
                }
                break;
            case ReferencedEntityType.USER:
                if (new Date(auditRecord.created) > this.LEGACY_MEMBER_DATE) {
                    if (auditRecord.action === AuditAction.LOGIN) {
                        auditRecord.referencedEntityLinkParams = {_activeTab: 'users'};
                        this.memberService.loadMember(auditRecord.memberId).subscribe((member: Member) => {
                            if (member) {
                                auditRecord.referencedEntityLink = '/administration/member/' + auditRecord.memberId;
                            }
                        });
                    }
                } else {
                    // legacy audit records prior to 2021-07-15 do not have the memberId set properly
                    auditRecord.referencedEntity = auditRecord.referencedEntityId;
                }
                break;
            case ReferencedEntityType.COMPANY_WATCHLIST_SCREENING:
            case ReferencedEntityType.INDIVIDUAL_WATCHLIST_SCREENING:
                break;
            case ReferencedEntityType.FINCEN_INVESTIGATION:
                auditRecord.referencedEntity = auditRecord.referencedEntityId;
                auditRecord.referencedEntityLink = '/reports/authority/list';
                auditRecord.referencedEntityLinkParams = {reportType: 'FINCEN_INVESTIGATIONS'};
                break;
            default:
                auditRecord.referencedEntity = auditRecord.referencedEntityId;
        }
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.paginator.page, this.filterEvent).pipe(
            startWith({}),
            switchMap(() => {
                UIUtils.scrollToTop(document.querySelector('.mat-table-container'));
                this.isLoadingResults = true;
                return this.auditService.getAuditRecords(this.memberId, this.action, this.auditEntityType, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<AuditRecord>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((data) => {
            this.auditRecords = data;
            for (const auditRecord of this.auditRecords) {
                if (auditRecord.jsonData) {
                    auditRecord.jsonData = JSON.parse(auditRecord.jsonData);
                }
                this.loadMemberAndUserData(auditRecord);
            }
            this.isLoadingResults = false;
            return data || [];
        });
    }
}
