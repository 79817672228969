import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmModalComponent, LoaderComponent } from 'projects/components/src/public-api';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import {
    AuthService, MerchantAccountConfig, MerchantAccountService, MemberAccount, MemberAccountStatus, PagedResponse, PaymentService, RiskTier, Transaction,
    TransactionState, Utils, WorkflowService, MemberType, MemberStatus, ExternalAccountingId, MerchantAccount
} from 'projects/services/src/public-api';
import { forkJoin } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

@Component({
    selector: 'pt-merchant-administration',
    templateUrl: './merchant-administration.component.html',
    styleUrls: ['./merchant-administration.component.scss']
})
export class MerchantAdministrationComponent implements OnInit {

    MemberAccountStatus = MemberAccountStatus;
    MemberStatus = MemberStatus;
    MemberType = MemberType;
    RiskTier = RiskTier;
    Utils = Utils;

    @Input() merchantAccountConfig: MerchantAccountConfig;

    similarMerchantConfigs: MerchantAccountConfig[] = [];
    displayedColumns = ['merchant_name', 'contact_info', 'status', 'action'];
    canDeleteMerchant = false;
    hasTransactions = true;

    constructor(private merchantAccountService: MerchantAccountService,
                private router: Router,
                private dialog: MatDialog,
                private overlay: Overlay,
                private workflowService: WorkflowService,
                private paymentService: PaymentService,
                private notifier: NotificationService,
                private authService: AuthService) {}

    ngOnInit() {
        if (this.authService.isAuthoritySuperUser()) {
            this.canDeleteMerchant = true;
        } else if (!this.authService.isAuthority()) {
            this.displayedColumns.splice(this.displayedColumns.indexOf('action'), 1);
        }
        const merchantMemberId = this.merchantAccountConfig.merchantMemberAccount.memberId;
        this.merchantAccountService.getSimilarMerchantAccountConfigs(merchantMemberId, this.merchantAccountConfig.merchantMemberAccount.member.memberType).subscribe((result: MerchantAccountConfig[]) => {
            this.similarMerchantConfigs = result;
        });
        this.paymentService.getTransactionsByStatusAndType('', this.merchantAccountConfig.merchantMemberAccount.id, TransactionState.ALL, [], [], '', [], '', '', '', '', '', 0, 1, '', '').subscribe((response: PagedResponse<Transaction>) => {
            if (response.content.length === 0) {
                this.merchantAccountService.getAllMerchantAccountsByMerchantMemberAccountIdAndMemberId(this.merchantAccountConfig.merchantMemberAccount.id, '').subscribe((merchantAccounts: PagedResponse<MerchantAccount>) => {
                    const accountingIds = [];
                    merchantAccounts.content.forEach((merchantAccount) => {
                        accountingIds.push(...merchantAccount.accountingExternalIds);
                    });
                    if (!accountingIds.length) {
                        this.hasTransactions = false;
                    } else {
                        this.searchTransactionsByExternalId(accountingIds).subscribe((responses: HttpResponse<Object>[]) => {
                            this.hasTransactions = false;
                            responses.forEach((response: HttpResponse<Object>) => {
                                if (parseInt(response.headers.get('Content-Length'), 10) > 0) {
                                    this.hasTransactions = true;
                                }
                            });
                        });
                    }
                });
            } else {
                this.hasTransactions = !!response.content.length;
            }
        });
    }

    searchTransactionsByExternalId(externalIds: ExternalAccountingId[]) {
        return forkJoin(externalIds.map((externalId) => {
            return this.paymentService.countTransactionsInvolvingExternalId(externalId.accountingExternalId);
        }));
    }

    useExistingMerchant(memberAccount: MemberAccount) {
        if (this.dialog.openDialogs?.length) {
            return;
        }
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        const sourceName = this.merchantAccountConfig.merchantMemberAccount.member.name;
        dialogConfig.data = {
            title: 'Replace Merchant',
            description: `This will replace <b>${sourceName}</b> with <b>${memberAccount.member.name}</b>. All transactions for ${sourceName} will be updated to reflect this change.`,
            confirmMessage: 'Do you want to replace this merchant with the existing one?',
            confirmText: 'Replace'
        };
        const dialog = this.dialog.open(ConfirmModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((confirmAction: any) =>  {
            if (confirmAction === 'confirmed') {
                const overlayRef = this.overlay.create({
                    positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                    hasBackdrop: true
                });
                const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
                componentRef.instance.title = 'Replacing merchant.  Please wait...';
                // overlay?  This may take a while
                const replaceMerchantRequest = {
                    merchantMemberIdToReplace: this.merchantAccountConfig.merchantMemberAccount.memberId,
                    merchantMemberIdToUse: memberAccount.memberId
                };
                this.workflowService.replaceMerchant(replaceMerchantRequest).subscribe((_merchantAccountConfig: MerchantAccountConfig) => {
                    overlayRef.dispose();
                    this.workflowService.clearCurrentTask();
                    this.notifier.showSuccess(`${sourceName} has successfully been merged with <b>${memberAccount.member.name}</b>.`);
                    this.router.navigate(['/administration/members'], { queryParams: { _activeTab: 'merchants' }});
                }, (error: any) => {
                    overlayRef.dispose();
                    throw error;
                });
            }
        });
    }

    deleteExistingMerchant() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        const sourceName = this.merchantAccountConfig.merchantMemberAccount.member.name;
        dialogConfig.data = {
            title: 'Delete Merchant',
            description: `This will delete <b>${sourceName}</b>  This cannot be undone.`,
            confirmMessage: 'Do you want to delete this merchant?',
            confirmText: 'Delete'
        };
        const dialog = this.dialog.open(ConfirmModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((confirmAction: any) =>  {
            if (confirmAction === 'confirmed') {
                const overlayRef = this.overlay.create({
                    positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                    hasBackdrop: true
                });
                const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
                componentRef.instance.title = 'Deleting merchant.  Please wait...';
                this.merchantAccountService.deleteMerchant(this.merchantAccountConfig.id).subscribe(() => {
                    overlayRef.dispose();
                    this.workflowService.clearCurrentTask();
                    this.notifier.showSuccess(`${sourceName} has successfully been deleted</b>.`);
                    this.router.navigate(['/administration/members'], { queryParams: { _activeTab: 'merchants' }});
                }, (error: any) => {
                    overlayRef.dispose();
                    throw error;
                });
            }
        });
    }
}
