import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { merge, of as observableOf, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import {
    Alert,
    AlertStatus,
    AlertType,
    AuthService,
    CommentService,
    Member,
    MemberAccount,
    MemberAccountService,
    MemberType,
    PagedResponse
} from 'projects/services/src/public-api';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { AlertsModalComponent } from 'projects/components/src/lib/modal';

@Component({
    selector: 'pt-authority-alert',
    templateUrl: './authority-alert.component.html',
    styleUrls: ['./authority-alert.component.scss']
})
export class AuthorityAlertComponent implements OnInit, AfterViewInit, OnDestroy {

    AlertStatus = AlertStatus;
    MemberType = MemberType;
    AlertType = AlertType;

    @Input() memberId = '';
    @Input() showMemberFilter = true;

    @Output() alertReviewed: EventEmitter<void> = new EventEmitter<void>();

    type: AlertType = AlertType.ALL_ALERT_TYPES;
    statuses: AlertStatus[];
    alertStatus = AlertStatus.ALL;
    formGroup: UntypedFormGroup;
    alertTypes: AlertType[] = [];
    subscription: Subscription;

    alerts: Alert[] = [];
    isLoadingResults = true;
    pageTracking: PageTracking;
    resultsLength = 0;
    refreshEvent: EventEmitter<null> = new EventEmitter<null>();

    displayedColumns: string[] = ['description', 'created', 'status', 'type', 'member_name', 'account', 'note', 'acknowledged_by', 'source', 'action'];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private formBuilder: UntypedFormBuilder,
                private route: ActivatedRoute,
                private router: Router,
                private commentService: CommentService,
                private memberAccountService: MemberAccountService,
                private dialog: MatDialog,
                private cdr: ChangeDetectorRef,
                public authService: AuthService) { }

    ngOnInit() {
        this.alertTypes = Object.values(AlertType);
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'created', 'desc', 50);
        if (!this.showMemberFilter) {
            this.displayedColumns.splice(this.displayedColumns.indexOf('member_name'), 1);
        }

        const queryParams = this.route.snapshot.queryParams;
        if (queryParams['type'] || queryParams['status'] || queryParams['memberId']) {
            this.type = queryParams['type'] || AlertType.ALL_ALERT_TYPES;
            this.alertStatus = queryParams['status'] || AlertStatus.ALL;
            this.changedStatus(false);
            if (this.showMemberFilter) {
                this.memberId = queryParams['memberId'] || '';
            }
        } else {
            this.type = AlertType.ALL_ALERT_TYPES;
            this.alertStatus = AlertStatus.ACTIVE;
            this.changedStatus(false);
            if (this.showMemberFilter) {
                this.memberId = '';
            }
            this.updateQueryParams(true);
        }

        this.formGroup = this.formBuilder.group({
            memberCtrl: new UntypedFormControl(this.memberId),
            alertTypeCtrl: new UntypedFormControl(this.type)
        });
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort, this.refreshEvent);
        this.addTableLoadListener();
        this.route.queryParams.subscribe((params) => {
            if (params['type']) {
                this.type = params['type'];
            } else if (!this.type) {
                this.type = AlertType.ALL_ALERT_TYPES;
            }
            this.formGroup.controls['alertTypeCtrl'].setValue(this.type);
            if (params['status']) {
                this.alertStatus = params['status'];
            } else if (!this.alertStatus) {
                this.alertStatus = AlertStatus.ALL;
            }
            this.changedStatus(false);
            if (this.showMemberFilter) {
                this.memberId = params['memberId'] || '';
                this.formGroup.controls['memberCtrl'].setValue(this.memberId);
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.paginator.page, this.refreshEvent).pipe(
            startWith({}),
            switchMap(() => {
                UIUtils.scrollToTop(document.querySelector('.mat-table-container'));
                this.isLoadingResults = true;
                return this.commentService.getAlertsByStatusAndType(this.memberId, this.statuses, this.type, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<Alert>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((alerts: Alert[]) => {
            this.alerts = alerts;
            this.alerts.forEach((alert: Alert) => {
                if (alert.jsonData) {
                    alert.jsonData = JSON.parse(alert.jsonData);
                }
                if (alert.memberAccountId) {
                    this.memberAccountService.loadMemberAccount(alert.memberAccountId).subscribe((memberAccount: MemberAccount) => {
                        alert.memberAccount = memberAccount;
                    });
                }
            });
        });
    }

    reviewAlert(alert: Alert, event: MouseEvent) {
        event.cancelBubble = true;
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.disableClose = true;
        dialogConfig.data = {
            alert
        };
        const dialog = this.dialog.open(AlertsModalComponent, dialogConfig);
        dialog?.afterClosed().subscribe(
            (refresh: boolean) => {
                if (refresh) {
                    this.alertReviewed.emit();
                    this.refreshEvent.emit();
                }
            }
        );
    }

    navigatesToMember(type: AlertType) {
        return type === AlertType.CONSUMER || type === AlertType.MEMBER_ACCOUNT || type === AlertType.EXTERNAL_SYSTEM || type === AlertType.PERIODIC_REVIEW || type === AlertType.MEMBER;
    }



    changedStatus(updateQueryParams: boolean) {
        if (this.alertStatus === AlertStatus.ALL) {
            this.statuses = [];
        } else {
            this.statuses = [this.alertStatus];
        }
        if (updateQueryParams) {
            this.updateQueryParams(false);
        }
    }

    alertTypeChanged(alertType: any) {
        this.type = alertType;
        this.updateQueryParams(false);
    }

    memberSelected(member: Member) {
        if (member && member.id) {
            this.memberId = member.id;
        } else {
            this.memberId = '';
        }
        this.updateQueryParams(false);
    }

    updateQueryParams(replace: boolean) {
        const queryParams = { type: this.type, memberId: this.memberId, status: this.alertStatus, page: 1, num: this.pageTracking.pageSize };
        TableUtils.updateQueryParams(this.route, this.router, queryParams, replace);
    }
}
