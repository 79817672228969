<div>
    <form *ngIf="licenseForm" [formGroup]="licenseForm">
        <div>
            <div class="modal-header">
                <label class="form-title">License Details</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content">
                <div class="row">
                    <div class="col-4">
                        <pc-text-option-selector [required]="true" [label]="'Jurisdiction'"
                            [class]="'full'"
                            [options]="jurisdictionOptions"
                            [formCtrl]="'jurisdictionCtrl'"
                            (selected)="selectedJurisdiction($event)"
                            [formGroup]="licenseForm">
                        </pc-text-option-selector>
                    </div>
                    <div class="col-4">
                        <pc-text-input [required]="true" [label]="'License Number'" [placeholder]="licensePlaceholder"
                            [formCtrl]="'licenseNumberCtrl'" [formGroup]="licenseForm">
                        </pc-text-input>
                    </div>
                    <div class="col-4">
                        <pc-form-date-picker [changeTrigger]="changeTrigger"
                            [label]="'Expiration Date'"
                            [formCtrl]="'licenseExpDateCtrl'"
                            [formGroup]="licenseForm"
                            [defaultEmpty]="!existingLicense?.expirationDate || !this.licenseExpDate"
                            (dateSelected)="onDateSelected($event)">
                        </pc-form-date-picker>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <pc-form-text-area [label]="'License Holder'"  [formCtrl]="'licenseHolderCtrl'" [formGroup]="licenseForm">
                        </pc-form-text-area>
                    </div>
                    <div class="col-4">
                        <pc-form-text-area [label]="'Note'"  [formCtrl]="'licenseNoteCtrl'" [formGroup]="licenseForm">
                        </pc-form-text-area>
                    </div>
                    <div class="col-4 mt-3" *ngIf="selectionsMade()">
                        <button class="blue-button" type="button" (click)="licenseUpload.click()" [class]="(licenseFileRequired && !licenseFile) ? 'required': ''">Attach License</button>
                        <input hidden type="file" id="licenseUpload" #licenseUpload (change)="selectLicenseFile($event)" placeholder="Attach License" accept=".pdf,.doc,.docx">
                        <span *ngIf="licenseFile"><br/>{{licenseFile.name}}
                            <i class="fa fa-times" (click)="onDeleteFile()"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <pc-submit-button class="pull-right" [enabled]="licenseValid" [submit]="addLicense" [text]="getButtonText()"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
