import { Inject, Injectable } from '@angular/core';
import { HttpService, PaymentProcessorNotification } from '../public-api';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PaymentProcessingService {

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.PaymentProcessingAPIEndpoint;
    }

    getPaymentProcessorSettings(memberId: string): Observable<PaymentProcessorNotification[]> {
        return this.http.get(`${this.baseUrl}/notification/member/${memberId}`) as Observable<PaymentProcessorNotification[]>;
    }

    savePaymentProcessorSetting(updatedWebHookSettings: PaymentProcessorNotification) {
        return this.http.post(`${this.baseUrl}/notification`, updatedWebHookSettings) as Observable<PaymentProcessorNotification>;
    }

    updatePaymentProcessorSetting(id: string, updatedSetting: any) {
        return this.http.put(`${this.baseUrl}/notification/${id}`, updatedSetting) as Observable<PaymentProcessorNotification>;
    }

    deletePaymentProcessorSetting(id: string) {
        return this.http.delete(`${this.baseUrl}/notification/${id}`);
    }

}
