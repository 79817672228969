import { Component, OnInit } from '@angular/core';
import {
    AuthService, BankingIntegrationService, Configuration, CustomerInfo, MemberAccount, MemberAccountService, MemberAccountStatus, MemberAccountType, MemberType, PaymentService, Task, TransactionTableFilter,
    UserAccountService, Utils, Wallet, WalletFunction, WalletService, WorkflowService, BankingIntegrationProvider, MemberStatus
} from 'projects/services/src/public-api';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'pt-member-account-details',
    templateUrl: './member-account-details.component.html'
})
export class MemberAccountDetailsComponent implements OnInit {

    readOnly = true;
    memberAccount: MemberAccount;
    changeTrigger = 1;
    filter: TransactionTableFilter;
    customerInfo: CustomerInfo;
    memberAccountId: string;

    processInstanceId: string;
    taskId: string;

    constructor(public authService: AuthService,
                private route: ActivatedRoute,
                private router: Router,
                private workflowService: WorkflowService,
                private userAccountService: UserAccountService,
                private memberAccountService: MemberAccountService,
                private walletService: WalletService,
                private bankingIntegrationService: BankingIntegrationService,
                private paymentService: PaymentService) {}

    ngOnInit() {
        const memberAccountPathValue = this.route.snapshot.params.memberAccountId;
        if (!Utils.isUuid(memberAccountPathValue) && this.workflowService.getCurrentTask()) {
            const task = this.workflowService.getCurrentTask();
            if (!task.formKey.startsWith('/administration/member-account')) {
                this.workflowService.clearCurrentTask();
                this.router.navigate(['/dashboard']);
                throw new Error('Task not valid.');
            }
            this.taskId = task.id;
            this.processInstanceId = task.processInstanceId;
            this.memberAccountId = task.variables.memberAccountId;
            this.loadMemberAccountInfo(this.memberAccountId);
        } else {
            this.route.params.subscribe((params) => {
                this.memberAccountId = params['memberAccountId'];
                this.loadMemberAccountInfo(this.memberAccountId);
            });
        }
    }

    loadMemberAccountInfo(memberAccountId: string) {
        this.memberAccountService.loadMemberAccount(memberAccountId).subscribe((memberAccount: MemberAccount) => {
            this.memberAccount = memberAccount;
            if (this.isMemberEligibleForBankingIntegration()) {
                this.bankingIntegrationService.getCustomerInfo(memberAccount.id).subscribe((customerInfo: CustomerInfo) => {
                    this.customerInfo = customerInfo;
                    this.loadTaskInfo();
                }, (_error: any) => {
                    // ignore all errors from the finzly service: it may not be running, we may not be configured for finzly, etc.
                    this.loadTaskInfo();
                });
            } else {
                this.loadTaskInfo();
            }
        });
    }

    loadTaskInfo() {
        if (this.authService.isAuthorityOrAdmin() && (this.memberAccount.status === MemberAccountStatus.ACTIVE || this.memberAccount.member.status !== MemberStatus.DISABLED && (this.memberAccount.status === MemberAccountStatus.UNDER_REVIEW || this.memberAccount.status === MemberAccountStatus.PENDING))) {
            this.readOnly = false;
        }
        if (this.memberAccount.status === MemberAccountStatus.UNDER_REVIEW || this.memberAccount.status === MemberAccountStatus.PENDING) {
            this.workflowService.getTaskByProcessDefinitionWithVariable(['register_member_account_v2'], 'memberAccountId', this.memberAccount.id).subscribe((task: Task) => {
                if (task) {
                    this.taskId = task.id;
                    this.processInstanceId = task.processInstanceId;
                    this.applyFilter();
                } else {
                    this.loadWallet();
                    this.applyFilter();
                }
            });
        } else {
            this.loadWallet();
            this.applyFilter();
        }
    }

    applyFilter() {
        this.filter = new TransactionTableFilter(this.memberAccount.memberId);
        this.filter.memberAccountId = this.memberAccount.id;
        this.filter.memberType = this.memberAccount.member.memberType;
        this.filter.memberFunctions = this.memberAccount.member.memberFunctions;
        if (this.taskId) {
            this.filter.transactionTab = 'BILLING_INFO';
        } else {
            const profile = this.authService.getProfile();
            this.filter.retailLocationId = profile.retailLocationId || '';
            const queryParams = this.route.snapshot.queryParams;
            this.filter.transactionTab = queryParams['_transactionTab'] || (this.authService.isAuthorityOrReviewer() ? 'TRANSACTION_LIST' : 'ACCOUNT_ACTIVITY');
        }
    }

    loadWallet() {
        if (this.memberAccount.accountType !== MemberAccountType.MERCHANT && this.memberAccount.status !== MemberAccountStatus.UNDER_REVIEW && this.memberAccount.status !== MemberAccountStatus.PENDING) {
            this.walletService.findByMemberAccountId(this.memberAccount.id).subscribe((wallet: Wallet) => {
                this.memberAccount.wallet = wallet;
                if (!this.memberAccountService.hasWalletFunction(this.memberAccount, WalletFunction.ACH_DIRECT_PAYMENT)) {
                    this.paymentService.getOutstandingCheckAmountForMemberAccount(this.memberAccount.id).subscribe((outstandingAmount: number) => {
                        this.memberAccount.wallet.outstandingCheckAmount = outstandingAmount || 0;
                    });
                }
            });
        }
    }

    isMemberEligibleForBankingIntegration() {
        if (!Configuration.getConfig().bankingIntegrationEnabled) {
            return false;
        }

        if (Configuration.getConfig().bankProvider !== BankingIntegrationProvider.FINZLY) {
            return false;
        }

        return this.memberAccount.member.memberType === MemberType.BUSINESS || this.memberAccount.member.memberType === MemberType.FEE_ACCOUNT;
    }

    filterChanged(filter: TransactionTableFilter) {
        this.filter = filter;
        this.changeTrigger = Math.random();
    }

    onMemberAccountUpdate(memberAccount: MemberAccount) {
        this.memberAccount = memberAccount;
        this.loadWallet();
    }

    transactionSubmitted() {
        this.changeTrigger = Math.random();
    }

    bankingInfoUpdated(customerInfo: CustomerInfo) {
        this.customerInfo = customerInfo;
    }

    onBillingInfoUpdate() {
        // fetch the member account with the latest banking info
        this.loadMemberAccountInfo(this.memberAccountId);
    }
}
