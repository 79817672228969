import { Component, OnInit, AfterViewInit, Input, ViewChild, ElementRef, SimpleChanges, OnChanges, OnDestroy, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { BsaReport,  DataroomService, ReportsService, PagedResponse, ReportType, ReportStatus, FilingInstitution } from 'projects/services/src/public-api';
import { ActivatedRoute, Router } from '@angular/router';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';


@Component({
    selector: 'pc-reports-list',
    templateUrl: './reports-list.component.html'
})
export class ReportsListComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    ReportType = ReportType;
    FilingInstitution = FilingInstitution;

    @Input() submissionType = '';
    @Input() memberId : string;
    @Input() changeTrigger :number;
    @Input() reportStatus : ReportStatus;
    @Input() filingInstitution: FilingInstitution = FilingInstitution.NBCU;

    isLoadingResults = true;
    resultsLength = 25;
    bsareports: BsaReport[] = [];
    pageTracking: PageTracking;
    displayedColumns = [];
    subscription: any;
    filterEvent: EventEmitter<null> = new EventEmitter<null>();

    @ViewChild('downloadReportLink') private downloadReportLink: ElementRef;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private reportService: ReportsService,
                private dataroomService: DataroomService,
                private cdr: ChangeDetectorRef) {}

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnInit() {
        this.loadDisplayedColumns();
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'created', 'desc', 100);
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    ngOnChanges(changes : SimpleChanges) {
        if (!changes.changeTrigger) {
            if (changes.memberId) {
                this.updateQueryParams(!!changes.memberId.currentValue);
            } else if (changes.reportStatus) {
                this.updateQueryParams(false);
            }
            this.filterEvent.emit();
        }
    }

    loadDisplayedColumns() {
        if (this.submissionType === 'CTR') {
            this.displayedColumns = ['memberName', 'reportType', 'actualReportTriggerDate', 'dueDate', 'submissionDate', 'total_amount', 'trackingId', 'bsaId', 'copyoffile', 'status'];
        } else {
            this.displayedColumns = ['memberName', 'reportType', 'startDate', 'endDate', 'dueDate', 'submissionDate', 'trackingId', 'suspiciousAmount', 'cumulativeAmount', 'bsaId', 'copyoffile', 'status'];
        }
    }

    loadBsaTransactionDetails(bsaReportId: string, event: any) {
        if (event.target.tagName !== 'A') {
            this.router.navigate(['/reports/details/', bsaReportId]);
        }
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.paginator.page, this.filterEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                const reportStatuses = this.reportStatus === ReportStatus.ALL ? [] : [this.reportStatus];
                return this.reportService.getBsaReportsBySubmissionType(this.submissionType, reportStatuses, this.memberId, this.filingInstitution, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<BsaReport>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((reports: BsaReport[]) => {
            this.bsareports = reports;
            for (const bsareport of this.bsareports) {
                this.reportService.loadBsaReport(bsareport);
            }
            UIUtils.scrollDashboardToTop();
        });
    }

    downloadFile(report: BsaReport, event:MouseEvent) {
        this.dataroomService.downloadResource(report.documentDetails, this.downloadReportLink);
        event.cancelBubble = true;
        if (event.stopImmediatePropagation) {
            event.stopImmediatePropagation();
        }
    }

    updateQueryParams(resetPage: boolean) {
        const queryParams = { memberId: this.memberId || null, status: this.reportStatus, filingInstitution: this.filingInstitution };
        if (resetPage) {
            queryParams['page'] = 1;
        }
        TableUtils.updateQueryParams(this.route, this.router, queryParams, false);
    }

    getSubmissionDueDate(report: BsaReport) {
        let submissionDueDate = new Date(report.created);
        if (report.actualReportTriggerDate) {
            submissionDueDate = new Date(report.actualReportTriggerDate);
        }
        const days = report.reportType === ReportType.CTR_REPORT ? 14 : 29;
        submissionDueDate.setDate(submissionDueDate.getDate() + days);
        return submissionDueDate;
    }

    public handlePageBottom(event: PageEvent) {
        this.paginator.pageSize = event.pageSize;
        this.paginator.pageIndex = event.pageIndex;
        this.paginator.page.emit(event);
    }
}
