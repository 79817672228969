import { Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { Configuration } from './configuration/configuration.service';
import { AppInjector } from './injector/app-injector.service';

@Injectable({
    providedIn: 'root'
})
export class Utils {

    static readonly UUID_REGEX = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i;
    static readonly NON_NEGATIVE_CURRENCY_REGEX = /^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(\.[0-9][0-9]{0,1})?$/;
    static readonly CURRENCY_REGEX = /^\$?[-]?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(\.[0-9][0-9]{0,1})?$/;
    static readonly CENTS_REGEX = /\.(.*?\d*)/g;
    static readonly COMMA_REGEX = /,/g;
    static readonly MAX_AMOUNT = 100000000;
    static readonly BAD_FILE_EXTENSION_REGEX =  /^.*\.(sh|exe|bat)$/i;

    static isSafari() {
        return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
               navigator.userAgent &&
               navigator.userAgent.indexOf('CriOS') === -1 &&
               navigator.userAgent.indexOf('FxiOS') === -1;
    }

    static isNavigationKeyCode(keyCode: number) {
        return (keyCode === 8 || keyCode === 9 || keyCode === 37 || keyCode === 39 || keyCode === 46);
    }

    static isNaN(value: any) {
        if (value === 0) {
            return false;
        }
        if (!value) {
            return true;
        }
        return value ? isNaN(Number(value.toString().replace(Utils.COMMA_REGEX, ''))) : true;
    }

    static isNumericKeyCode(keyCode: number) {
        return (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105);
    }

    static validateFile(file: File, event: any, isRemittanceFile?: boolean) {
        if (file.size > Configuration.getConfig().maxUploadSize) {
            event.target.value = '';
            throw new Error('File exceeds maximum size of ' + Configuration.getMaxUploadSizeInMb() + '.');
        } else if (file.name.length > Configuration.getConfig().maxFileNameSize) {
            event.target.value = '';
            throw new Error('Filename exceeds maximum length of ' + Configuration.getConfig().maxFileNameSize + '.  Please rename the file and upload again.');
        } else if (file.size >= 1500000 && isRemittanceFile) {
            throw new Error('The uploaded file should be lesser than or equal to 1.5 MB');
        } else if (Utils.BAD_FILE_EXTENSION_REGEX.test(file.name)) {
            throw new Error('Invalid file type.');
        }
    }

    static isUuid(value: string) {
        if (!value) {
            return false;
        }
        return Utils.UUID_REGEX.test(value);
    }

    static formatCurrency(value: string, includeCents?: boolean) {
        return this._formatCurrency(Utils.CURRENCY_REGEX, value, includeCents);
    }

    static formatNonNegativeCurrency(value: string, includeCents?: boolean) {
        return this._formatCurrency(Utils.NON_NEGATIVE_CURRENCY_REGEX, value, includeCents);
    }

    private static _formatCurrency(regex: RegExp, value: string, includeCents?: boolean) {
        if (!value) {
            return '';
        }
        const temp = `${value}`.replace(/\,/g, '');
        if (Number(temp) >= Utils.MAX_AMOUNT) {
            return '';
        }
        if (!`${temp}`.match(regex)) {
            return '';
        }
        const amount = AppInjector.getInjector().get(CurrencyPipe).transform(temp).replace('$', '');
        if (includeCents) {
            return amount.replace(/\,/g, '');
        }

        return amount.replace(Utils.CENTS_REGEX, '');
    }

    static parseFloatIgnoreCommas(inputNumber: any) {
        if (!inputNumber && (typeof (inputNumber)) !== 'number') {
            return null;
        }
        if ((typeof (inputNumber)) === 'string') {
            const numberNoCommas = inputNumber.replace(/,/g, '');
            return parseFloat(numberNoCommas);
        }
        return inputNumber;
    }

    static getJurisdictionName(jurisdictionCode: string) {
        if (jurisdictionCode) {
            const adminDivisions = Configuration.getConfig().countries.find((country) => {
                return country.code === 'US';
            }).divisions;
            for (let adminDivision of adminDivisions) {
                if (adminDivision.code.toLowerCase() === jurisdictionCode.toLowerCase()) {
                    return adminDivision.name;
                }
            }
        }
        return '';
    }

    static getCountryCode(countryName: string) {
        if (countryName) {
            const countryConfig = Configuration.getConfig().countries.find((country) => {
                return country.name.toLowerCase() === countryName.toLowerCase();
            });
            return countryConfig.code || '';
        }
        return '';
    }

}
