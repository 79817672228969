import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent, ErrorType } from 'projects/components/src/public-api';
import { IMyDateModel } from 'angular-mydatepicker';
import {
    Address, AddressFormService, AddressType, AuthService, IndividualKeyParty, KeyParty, KeyPartyFormService, KeyPartyService, KeyPartyStatus, KeyPartyType, MemberAccountStatus,
    MemberType, PagedResponse, CustomValidators, LicenseService, LicenseStatus, License, Configuration, Country
} from 'projects/services/src/public-api';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pt-individual-key-party-modal',
    templateUrl: './individual-key-party-modal.component.html'
})
export class IndividualKeyPartyModalComponent extends BaseModalComponent<IndividualKeyPartyModalComponent> implements OnInit {

    AddressType = AddressType;

    memberId: string;
    keyPartyForm: UntypedFormGroup;
    addressForm: UntypedFormGroup;
    individualKeyPartyForm: UntypedFormGroup;
    isOwner = false;
    isUsCitizen = true;
    errorMessage: { message: string, type: ErrorType };
    keyParty: KeyParty;
    isExistingKeyParty = false;
    passportExpirationDate: string;
    isMerchant: false;
    memberType: MemberType;
    memberAccountStatus: MemberAccountStatus;
    existingKeyPartyIds: string[];
    countries: Country[];

    // originalAddress is used to store address of key party and replace addressForm with originalAddress in certain scenarios
    originalAddress: Address;

    constructor(private addressFormService: AddressFormService,
                private keyPartyService: KeyPartyService,
                private keyPartyFormService: KeyPartyFormService,
                public authService: AuthService,
                private datePipe: DatePipe,
                private licenseService: LicenseService,
                private notifier: NotificationService,
                private cdr: ChangeDetectorRef,
                dialogRef: MatDialogRef<IndividualKeyPartyModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.memberId = data.memberId;
        this.keyParty = data.keyParty;
        this.isMerchant = data.isMerchant;
        this.memberType = data.memberType;
        this.memberAccountStatus = data.memberAccountStatus;
        this.existingKeyPartyIds = data.existingKeyPartyIds;

        if (this.keyParty) {
            this.keyParty.owner = this.keyParty.percentOwnership > 0;
            this.isOwner = this.keyParty.owner;
            this.isExistingKeyParty = true;
        }
    }

    ngOnInit() {
        this.onSubmit = this.onSubmit.bind(this);
        this.formsValid = this.formsValid.bind(this);
        this.countries = Configuration.getConfig().countries;
        if (this.isExistingKeyParty) {
            this.keyPartyForm = this.keyPartyFormService.initializeKeyPartyForm(this.keyParty.type, this.keyParty.status, this.keyParty);
            this.addressForm = this.addressFormService.initializeForm(this.keyParty.individualKeyParty.address);
            // Storing the existing address of key party in originalAddress
            this.originalAddress = this.keyParty.individualKeyParty.address;
            this.individualKeyPartyForm = this.keyPartyFormService.initializeIndividualKeyPartyForm(this.keyParty.individualKeyParty);
            this.passportExpirationDate = this.keyParty.individualKeyParty.passportExpirationDate;
            this.keyPartyForm.get('percentOwnershipCtrl').updateValueAndValidity();
            this.showWarning(true);
            if (this.keyParty.userId) {
                this.keyPartyForm.disable();
                this.keyPartyForm.get('percentOwnershipCtrl').enable();
                this.keyPartyForm.get('employerCtrl').enable();
                this.keyPartyForm.get('professionalTitleCtrl').enable();
            }
        } else {
            this.keyPartyForm = this.keyPartyFormService.initializeKeyPartyForm(KeyPartyType.INDIVIDUAL, KeyPartyStatus.ACTIVE);
            this.addressForm = this.addressFormService.initializeForm(null);
            this.individualKeyPartyForm = this.keyPartyFormService.initializeIndividualKeyPartyForm();
            // for new key parties, address will not be available, so originalAddress is null
            this.originalAddress = null;
        }
        this.updateFormValidators();
    }

    isUsCitizenShip() {
        return this.individualKeyPartyForm.get('usCitizenCtrl').value;
    }

    updateFormValidators() {
        if (this.isContactOnly()) {
            this.keyPartyForm.patchValue({
                ownerCtrl: false,
                isDirectorCtrl: false,
                isOfficerCtrl: false,
                percentOwnershipCtrl: ''
            });
            this.keyPartyForm.get('ownerCtrl').disable();
            this.keyPartyForm.get('isDirectorCtrl').disable();
            this.keyPartyForm.get('isOfficerCtrl').disable();

            this.keyPartyForm.get('employerCtrl').setValidators([CustomValidators.noLeadingOrTrailingWhitespace]);
            this.keyPartyForm.get('professionalTitleCtrl').setValidators([CustomValidators.noLeadingOrTrailingWhitespace]);
            this.individualKeyPartyForm.get('birthDateCtrl').setValidators(null);
            this.individualKeyPartyForm.get('passportCountryOfIssueCtrl').setValidators(null);
            this.individualKeyPartyForm.get('passportNumberCtrl').setValidators(null);
            this.individualKeyPartyForm.get('passportExpDateCtrl').setValidators(null);
            this.individualKeyPartyForm.get('taxIdCtrl').setValidators(null);
            this.individualKeyPartyForm.get('passportExpDateCtrl').setValue(this.passportExpirationDate);

            // When contact-only check box is clicked, we are replacing the addressForm with originalAddress
            // this will ensure that any changes made to addressForm will be undone.
            this.addressForm = this.addressFormService.initializeForm(this.originalAddress);
            this.addressForm.get('streetAddressOneCtrl').setValidators(null);
            this.addressForm.get('cityCtrl').setValidators(null);
            this.addressForm.get('stateProvinceCtrl').setValidators(null);
            this.addressForm.get('countryCtrl').setValidators(null);
            this.addressForm.get('zipPostalCodeCtrl').setValidators(null);
        } else {
            this.isOwner = this.keyPartyForm.get('ownerCtrl').value;
            if (this.isOwner || this.keyPartyForm.get('isDirectorCtrl').value || this.keyPartyForm.get('isOfficerCtrl').value || this.keyPartyForm.get('isLicenseHolderCtrl').value) {
                this.keyPartyForm.get('isContactCtrl').disable();
            } else {
                this.keyPartyForm.get('isContactCtrl').enable();
            }
            this.keyPartyForm.get('ownerCtrl').enable();
            this.keyPartyForm.get('isDirectorCtrl').enable();
            this.keyPartyForm.get('isOfficerCtrl').enable();
            this.keyPartyForm.get('isLicenseHolderCtrl').enable();
            if (this.isOwner) {
                this.keyPartyForm.get('percentOwnershipCtrl').setValidators([Validators.required, Validators.min(1), Validators.max(100), CustomValidators.onlyNumeric]);
            } else {
                this.keyPartyForm.get('percentOwnershipCtrl').setValidators(null);
                this.keyPartyForm.patchValue({percentOwnershipCtrl: ''});
            }

            this.isUsCitizen = this.individualKeyPartyForm.get('usCitizenCtrl').value;
            if (this.isUsCitizen) {
                this.individualKeyPartyForm.get('taxIdCtrl').setValidators([Validators.required, Validators.pattern('^([0-9]{9})$')]);
                this.individualKeyPartyForm.get('passportCountryOfIssueCtrl').setValidators(null);
                this.individualKeyPartyForm.get('passportNumberCtrl').setValidators(null);
                this.individualKeyPartyForm.get('passportExpDateCtrl').setValidators(null);
            } else {
                this.individualKeyPartyForm.get('taxIdCtrl').setValidators([CustomValidators.noLeadingOrTrailingWhitespace]);
                this.individualKeyPartyForm.get('passportCountryOfIssueCtrl').setValidators([Validators.required, CustomValidators.noLeadingOrTrailingWhitespace]);
                this.individualKeyPartyForm.get('passportNumberCtrl').setValidators([Validators.required, Validators.maxLength(25), CustomValidators.noLeadingOrTrailingWhitespace]);
                this.individualKeyPartyForm.get('passportExpDateCtrl').setValue(this.passportExpirationDate);
                this.individualKeyPartyForm.get('passportExpDateCtrl').setValidators([Validators.required]);
            }

            this.keyPartyForm.get('employerCtrl').setValidators([Validators.required, CustomValidators.noLeadingOrTrailingWhitespace]);
            this.keyPartyForm.get('professionalTitleCtrl').setValidators([Validators.required, Validators.maxLength(24), CustomValidators.noLeadingOrTrailingWhitespace]);
            this.individualKeyPartyForm.get('birthDateCtrl').setValidators([Validators.required, CustomValidators.birthDate]);

            this.addressForm.get('streetAddressOneCtrl').setValidators([Validators.required, Validators.minLength(3), CustomValidators.noLeadingOrTrailingWhitespace]);
            this.addressForm.get('cityCtrl').setValidators([Validators.required, Validators.minLength(2), CustomValidators.noLeadingOrTrailingWhitespace]);
            this.addressForm.get('stateProvinceCtrl').setValidators([Validators.required, Validators.minLength(2), CustomValidators.noLeadingOrTrailingWhitespace]);
            this.addressForm.get('countryCtrl').setValidators([Validators.required, Validators.minLength(2), CustomValidators.noLeadingOrTrailingWhitespace]);
            this.addressForm.get('zipPostalCodeCtrl').setValidators((this.addressForm.get('countryCtrl').value === 'United States'
                || this.addressForm.get('countryCtrl').value === 'Canada'
                || this.addressForm.get('countryCtrl').value === 'Mexico')
                ? [Validators.required, CustomValidators.zipCode] : [Validators.required, CustomValidators.noLeadingOrTrailingWhitespace, Validators.minLength(3)]);
        }
        this.keyPartyForm.get('ownerCtrl').updateValueAndValidity();
        this.keyPartyForm.get('isDirectorCtrl').updateValueAndValidity();
        this.keyPartyForm.get('isOfficerCtrl').updateValueAndValidity();
        this.keyPartyForm.get('isLicenseHolderCtrl').updateValueAndValidity();
        this.keyPartyForm.get('percentOwnershipCtrl').updateValueAndValidity();
        this.keyPartyForm.get('employerCtrl').updateValueAndValidity();
        this.keyPartyForm.get('professionalTitleCtrl').updateValueAndValidity();
        this.individualKeyPartyForm.get('birthDateCtrl').updateValueAndValidity();
        this.individualKeyPartyForm.get('passportCountryOfIssueCtrl').updateValueAndValidity();
        this.individualKeyPartyForm.get('passportNumberCtrl').updateValueAndValidity();
        this.individualKeyPartyForm.get('passportExpDateCtrl').updateValueAndValidity();
        this.individualKeyPartyForm.get('taxIdCtrl').updateValueAndValidity();
        this.individualKeyPartyForm.get('phoneCtrl').updateValueAndValidity();
        this.addressForm.get('streetAddressOneCtrl').updateValueAndValidity();
        this.addressForm.get('cityCtrl').updateValueAndValidity();
        this.addressForm.get('stateProvinceCtrl').updateValueAndValidity();
        this.addressForm.get('countryCtrl').updateValueAndValidity();
        this.addressForm.get('zipPostalCodeCtrl').updateValueAndValidity();

        this.cdr.detectChanges();
    }

    isContactOnly() {
        return this.keyPartyForm.get('isContactCtrl').value && !this.isOwner && !this.keyPartyForm.get('isDirectorCtrl').value && !this.keyPartyForm.get('isOfficerCtrl').value;
    }

    onDateChanged(event: IMyDateModel) {
        if (event && event.singleDate) {
            this.passportExpirationDate = this.datePipe.transform(event?.singleDate?.jsDate, 'yyyy-MM-dd');
        } else {
            this.passportExpirationDate = '';
        }
    }

    close(refresh?: boolean) {
        super.close(refresh);
    }

    onSubmit(reset: any) {
        const address = this.addressFormService.getAddress(this.addressForm);
        const individualKeyParty = this.keyPartyFormService.getIndividualKeyParty(this.individualKeyPartyForm);
        individualKeyParty.address = address;
        // For new contact-only individual key party we should not create an address bean
        // originalAddress will be null only for new key parties
        if (this.isContactOnly() && !this.originalAddress) {
            individualKeyParty.address = null;
        }
        const keyParty = this.keyPartyFormService.getKeyParty(this.keyPartyForm);

        this.saveKeyParty(individualKeyParty, keyParty, reset);
    }

    saveKeyParty(individualKeyParty: IndividualKeyParty, keyParty: KeyParty, reset: any) {
        if (this.isContactOnly()) {
            keyParty.contactPerson = true;
        } else {
            individualKeyParty.passportExpirationDate = this.passportExpirationDate;
            if (!this.isExistingKeyParty) {
                keyParty.contactPerson = false;
            }
        }
        if (!keyParty.licenseHolder) {
            // we may have deselected the license holder, so we need to check if there are any licenses linked to the KP
            this.licenseService.loadMemberLicenses(this.memberId, [LicenseStatus.ACTIVE], 0, -1, 'id', 'DESC').subscribe((licensesResponse: PagedResponse<License>) => {
                const linkedLicenses = licensesResponse.content.filter((license) => {
                    return license.msaKeyPartyId === keyParty.id && license.msaEnabled === true && license.jurisdiction === 'State Operator';
                });
                if (linkedLicenses.length === 0) {
                    this.saveKeyPartyInformation(individualKeyParty, keyParty);
                } else {
                    reset();
                    this.notifier.showError(`${individualKeyParty.firstName} ${individualKeyParty.lastName} is in use by license ${linkedLicenses[0].licenseNo} and cannot have license holder removed.<br/><br/>Remove the MSA first.`);
                }
            });
        } else {
            this.saveKeyPartyInformation(individualKeyParty, keyParty);
        }
    }

    saveKeyPartyInformation(individualKeyParty: IndividualKeyParty, keyParty: KeyParty) {
        this.keyPartyService.saveIndividualKeyParty(individualKeyParty).subscribe((individualKeyParty: IndividualKeyParty) => {
            keyParty.individualKeyPartyId = individualKeyParty.id;
            keyParty.memberId = this.memberId;
            this.keyPartyService.saveKeyParty(keyParty).subscribe(() => {
                this.close(true);
            });
        });
    }

    // double check this logic
    formsValid() {
        return this.keyPartyForm.valid && this.addressForm.valid && this.individualKeyPartyForm.valid && this.isRelationshipsValid()
                && (!this.isOwner || this.keyPartyForm.get('percentOwnershipCtrl').value > 0)
                && (this.keyPartyForm.dirty || this.addressForm.dirty || this.individualKeyPartyForm.dirty);
    }

    isRelationshipsValid() {
        return this.keyPartyForm.controls['ownerCtrl'].value === true ||
            this.keyPartyForm.controls['isDirectorCtrl'].value === true ||
            this.keyPartyForm.controls['isOfficerCtrl'].value === true ||
            this.keyPartyForm.controls['isContactCtrl'].value === true ||
            this.keyPartyForm.controls['isLicenseHolderCtrl'].value === true;
    }

    handleIndividualKeyPartyChange(individualKeyParty: IndividualKeyParty) {
        this.individualKeyPartyForm.patchValue({
            idCtrl: individualKeyParty.id,
            createdCtrl: individualKeyParty.created,
            updatedCtrl: individualKeyParty.updated,

            firstNameCtrl: individualKeyParty.firstName,
            middleNameCtrl: individualKeyParty.middleName,
            lastNameCtrl: individualKeyParty.lastName,
            otherSurnameOrAliasCtrl: individualKeyParty.otherSurnameOrAlias,
            birthDateCtrl: individualKeyParty.birthDate,
            emailCtrl: individualKeyParty.email,
            phoneCtrl: individualKeyParty.phone,

            usCitizenCtrl: individualKeyParty.usCitizen || true,
            taxIdCtrl: individualKeyParty.taxId,
            passportCountryOfIssueCtrl: individualKeyParty.passportCountryOfIssue,
            passportNumberCtrl: individualKeyParty.passportNumber,
            passportExpDateCtrl: individualKeyParty.passportExpirationDate
        }, {emitEvent: true});
        if (individualKeyParty.address) {
            this.addressForm.patchValue({
                idCtrl: individualKeyParty.address?.id,
                createdCtrl: individualKeyParty.address?.created,
                updatedCtrl: individualKeyParty.address?.updated,

                streetAddressOneCtrl: individualKeyParty.address?.streetAddressOne,
                cityCtrl: individualKeyParty.address?.city,
                stateProvinceCtrl: individualKeyParty.address?.stateProvince,
                countryCtrl: individualKeyParty.address?.country || 'United States',
                zipPostalCodeCtrl: individualKeyParty.address?.zipPostalCode
            });
        } else {
            this.addressForm.patchValue({
                idCtrl: null,
                createdCtrl: null,
                updatedCtrl: null,
                streetAddressOneCtrl: '',
                cityCtrl: '',
                stateProvinceCtrl: '',
                countryCtrl: 'United States',
                zipPostalCodeCtrl: ''
            });
        }
        this.keyPartyForm.patchValue({
            individualKeyPartyIdCtrl: individualKeyParty.id
        });
        this.passportExpirationDate = individualKeyParty.passportExpirationDate;
        // when key party is changed we are updating the originalAddress with the address of changed key party
        this.originalAddress = individualKeyParty.address;
    }

    setIndividualKeyParty(individualKeyParty) {
        this.errorMessage = null;
        this.handleIndividualKeyPartyChange(individualKeyParty);
        this.keyPartyForm.patchValue({
            ownerCtrl: false,
            percentOwnershipCtrl: null,
            isDirectorCtrl: false,
            isOfficerCtrl: false
        });
        this.updateFormValidators();
        this.keyPartyForm.enable();
        this.showWarning(!!individualKeyParty.id);
    }

    showWarning(existingParty: boolean) {
        if (!existingParty) {
            this.errorMessage = null;
        } else {
            this.errorMessage = {
                message: 'This key party exists within Confia.  Changes to the individual and address information will update anywhere this key party is in use.',
                type: ErrorType.WARN
            };
        }
    }
}
