import { Component, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pc-clipboard-gadget',
    templateUrl: './clipboard-gadget.component.html',
    styleUrls: ['./clipboard-gadget.component.scss']
})
export class ClipboardGadgetComponent {

    @Input() value: string;

    constructor(private clipboard: Clipboard,
                private notifier: NotificationService) {
    }

    copy() {
        this.clipboard.copy(this.value);
        this.notifier.showSuccess('Copied to clipboard', 2000);
    }

}
