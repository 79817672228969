import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay } from '@angular/cdk/overlay';
import { ConfirmModalComponent, LoaderComponent, NameChangeModalComponent } from 'projects/components/src/public-api';
import {
    Address, AddressFormService, AddressOccupant, AddressService, AddressType, AuthService, BusinessCategory, BusinessClientStatus, Configuration, CorporateEntity,
    CorporateKeyParty, Jurisdiction, KeyParty, KeyPartyService, Member, MemberFormService, MemberRegistrationType, MemberService, MemberStatus, MemberType,
    MerchantAccountConfig, CustomValidators, MerchantAccountService, NameChangeType, OnboardingTrackingService, PagedResponse, RiskTier, SupportedTransactionType, Utils,
    WhitelistRelationshipParty, WhitelistRelationshipPartyService, BankAccount, TaxInfo, MemberSource
} from 'projects/services/src/public-api';

@Component({
    selector: 'pt-member-business-details',
    templateUrl: './business-details.component.html',
    styleUrls: ['./business-details.component.scss']
})
export class MemberBusinessDetailsComponent implements OnInit {

    AddressType = AddressType;
    RiskTier = RiskTier;
    BusinessCategory = BusinessCategory;

    @Input() task: string;
    @Input() memberId: string;
    @Input() changeTrigger: number;
    @Input() readOnly = false;

    @Output() next: EventEmitter<string> = new EventEmitter<string>();
    @Output() memberUpdated: EventEmitter<Member> = new EventEmitter<Member>();
    @Output() jurisdictionChanged: EventEmitter<string> = new EventEmitter<string>();

    multipleMembersWithSameNameWithoutJurisdiction = false;
    multipleMembersWithSameNameAndSameJurisdiction = false;
    isAuthority = false;
    memberType: MemberType;
    memberForm: UntypedFormGroup;
    addressForm: UntypedFormGroup;
    merchantTaxForm: UntypedFormGroup;
    merchantAccountConfig: MerchantAccountConfig;
    merchantType: 'PRIVATE' | 'PUBLIC';
    whitelistType : SupportedTransactionType;
    whitelistParty: WhitelistRelationshipParty;
    riskTier: RiskTier;
    jurisdiction: string;
    status: MemberStatus | BusinessClientStatus;
    accountingOpts: string[] = ['Quickbooks Online',
        'Quickbooks Desktop',
        'Xero',
        'Freshbooks',
        'Wave',
        'Sage Accounting',
        'Sage Intacct',
        'ZipBooks',
        'Zoho Books',
        'Netsuite',
        'Dynamics 365',
        'SAP',
        'Other',
        'None'];
    businessTypeOpts: string[] = ['Corporation',
        'Limited Liability Company',
        'Limited Partnership',
        'General Partnership',
        'Limited Liability Partnership',
        'Sole Proprietorship'];

    memberName: string;
    businessCategoriesOpts: BusinessCategory[];
    jurisdictions: Jurisdiction[] = [];
    corporateEntityKeyParty: CorporateKeyParty;
    businessCategory: BusinessCategory;
    bankAccounts: BankAccount[] = [];


    constructor(private memberService: MemberService,
                private memberFormService: MemberFormService,
                private addressService: AddressService,
                private addressFormService: AddressFormService,
                private merchantAccountService: MerchantAccountService,
                private whitelistService: WhitelistRelationshipPartyService,
                private keyPartyService: KeyPartyService,
                private dialog: MatDialog,
                private changeDetectorRef: ChangeDetectorRef,
                private overlay: Overlay,
                private authService: AuthService) {}

    ngOnInit() {
        this.isBusinessDetailsValid = this.isBusinessDetailsValid.bind(this);
        this.initMember = this.initMember.bind(this);
        this.initLocation = this.initLocation.bind(this);
        this.onNavigate = this.onNavigate.bind(this);
        this.onSave = this.onSave.bind(this);
        this.updateMemberName = this.updateMemberName.bind(this);
        this.initForms();
        this.isAuthority = this.authService.isAuthority();
    }

    initForms(callback?: any) {
        this.businessCategoriesOpts = Object.values(BusinessCategory);
        this.memberService.loadMember(this.memberId).subscribe((member: Member) => {
            this.jurisdictions = Configuration.getConfig().jurisdictions.filter((jurisdictionDefinition) => {
                return member.memberType === MemberType.BUSINESS_MERCHANT || jurisdictionDefinition.supportedMemberRegistrationTypes.length;
            });

            if (!member) {
                this.memberType = MemberType.BUSINESS;
                this.initMember(null);
                this.initLocation(null, callback);
                this.jurisdictions.unshift({id: '', name: 'All', supportedMemberRegistrationTypes:[]});
            } else {
                this.memberType = member.memberType;
                this.status = member.status;
                this.riskTier = member.tier;
                this.jurisdiction = member.jurisdiction;
                if (this.isBusinessMerchant() || this.isBusiness()) {
                    this.memberService.searchMembers(member.name, [MemberType.BUSINESS, MemberType.BUSINESS_MERCHANT], [], '', false, false).subscribe((similarMembersResponse: PagedResponse<Member>) => {
                        const similarMembers = similarMembersResponse.content.filter((similarMember) => {
                            return similarMember.name.toLowerCase() === member.name.toLowerCase() && member.id !== similarMember.id;
                        });
                        const sameMembers = similarMembers.filter((similarMember) => {
                            return !member.jurisdiction || !similarMember.jurisdiction || member.jurisdiction === similarMember.jurisdiction;
                        });
                        OnboardingTrackingService.setSameMembers(sameMembers);
                        if (this.riskTier !== RiskTier.TIER_1 && !similarMembers.length) {
                            this.jurisdictions.unshift({id: '', name: 'All', supportedMemberRegistrationTypes:[]});
                        }
                        if (sameMembers.length) {
                            this.memberForm.controls['jurisdictionCtrl'].setValidators([Validators.required]);
                            this.memberForm.controls['jurisdictionCtrl'].updateValueAndValidity();
                            if (!member.jurisdiction) {
                                this.multipleMembersWithSameNameWithoutJurisdiction = true;
                            } else {
                                this.multipleMembersWithSameNameAndSameJurisdiction = true;
                            }
                        }
                    });
                }
                if (!this.isBusinessMerchant()) {
                    this.businessCategoriesOpts.splice(this.businessCategoriesOpts.indexOf(BusinessCategory.POOLED_MERCHANT), 1);
                } else {
                    this.initMerchantTaxInfo(member.taxInfo);
                }
                if (this.isMerchant()) {
                    this.memberName = member.name;
                    this.merchantAccountService.getMerchantAccountConfigByMerchantMemberId(this.memberId).subscribe((merchantAccountConfig: MerchantAccountConfig) => {
                        this.initMerchantAccountConfig(merchantAccountConfig);
                    });
                    this.whitelistType = SupportedTransactionType.NONE;
                    this.whitelistService.getMerchantWhitelistingDetails(this.memberId).subscribe((response: WhitelistRelationshipParty) => {
                        if (response) {
                            this.whitelistParty = response;
                            this.whitelistType = this.whitelistParty.whitelistedTransactionType;
                        }
                    });
                }
                if (this.isCorporate()) {
                    this.keyPartyService.getCorporateEntityKeyParty(this.memberId).subscribe((keyParties: PagedResponse<KeyParty>) => {
                        if (keyParties && !keyParties.empty) {
                            this.corporateEntityKeyParty = keyParties.content[0].corporateKeyParty;
                        }
                    });
                }
                this.initMember(member);
                this.addressService.getPrimaryAddress(member.id).subscribe((address: Address) => {
                    this.initLocation(address, callback, member.memberType);
                });
            }
        });
    }

    initMember(member: Member) {
        if (member) {
            this.businessCategory = member.businessCategory;
            this.memberType = member.memberType;
        }
        this.memberForm = this.memberFormService.initializeForm(member);
        this.memberForm.get('nameCtrl').disable();
        this.memberForm.get('firstNameCtrl').disable();
        this.memberForm.get('middleNameCtrl').disable();
        this.memberForm.get('lastNameCtrl').disable();

        if (this.isMerchant()) {
            this.memberForm.controls['naicsCodeCtrl'].setValidators(null);
            this.memberForm.controls['businessTypeCtrl'].setValidators(null);
            this.memberForm.controls['officePhoneCtrl'].setValidators(CustomValidators.phone);
        } else {
            if (this.memberType === MemberType.BUSINESS) {
                this.memberForm.controls['accountingSystemCtrl'].setValidators([Validators.required]);
            }
            if (member.memberRegistrationType === MemberRegistrationType.REGULAR_MEMBER) {
                this.memberForm.controls['linkBankAccountCtrl'].disable();
                this.businessCategoriesOpts = this.businessCategoriesOpts.filter((businessCategory) => {
                    return (businessCategory !== BusinessCategory.ATM_BUSINESS);
                });
            }
            if (this.memberType === MemberType.CONSUMER) {
                this.memberForm.disable();
            }
            if (this.businessCategory === BusinessCategory.ATM_BUSINESS && this.memberType === MemberType.BUSINESS) {
                this.memberForm.controls['paymentProcessorCtrl'].setValidators([Validators.required, CustomValidators.noLeadingOrTrailingWhitespace]);
            } else {
                this.memberForm.controls['paymentProcessorCtrl'].setValidators(null);
            }
        }
        if ((this.isCorporate()) && this.riskTier === RiskTier.TIER_1) {
            this.memberForm.controls['jurisdictionCtrl'].setValidators([Validators.required]);
        }

        // disable the forms based on roles.
        if (this.readOnly) {
            this.memberForm.disable();
        } else if (this.status === MemberStatus.UNDER_REVIEW && !this.isAuthority) {
            this.memberForm.controls['jurisdictionCtrl'].disable();
        }
        this.memberForm.controls['naicsCodeCtrl'].updateValueAndValidity();
        this.memberForm.controls['accountingSystemCtrl'].updateValueAndValidity();
        this.memberForm.controls['businessTypeCtrl'].updateValueAndValidity();
        this.memberForm.controls['linkBankAccountCtrl'].updateValueAndValidity();
        this.memberForm.controls['jurisdictionCtrl'].updateValueAndValidity();
        this.memberForm.controls['paymentProcessorCtrl'].updateValueAndValidity();

        this.memberForm.markAsPristine();
        this.memberForm.valueChanges.subscribe(() => {
            this.updateValidity();
        });
    }

    initMerchantTaxInfo(taxInfo: TaxInfo) {
        this.merchantTaxForm = this.memberFormService.initializeTaxForm(taxInfo);
        this.merchantTaxForm.controls['einCtrl'].setValidators([Validators.pattern('^([0-9]{9})$')]);
        if (!taxInfo) {
            this.merchantTaxForm.controls['memberIdCtrl'].setValue(this.memberId);
        }
        if (this.readOnly) {
            this.merchantTaxForm.disable();
        }
        this.merchantTaxForm.updateValueAndValidity();
        this.merchantTaxForm.markAsPristine();
        this.merchantTaxForm.valueChanges.subscribe(() => {
            this.updateValidity();
        });

    }

    businessCategoryChanged(businessCategory: BusinessCategory) {
        this.businessCategory = businessCategory;
        if (businessCategory ===  BusinessCategory.ATM_BUSINESS && this.memberType === MemberType.BUSINESS) {
            this.memberForm.controls['paymentProcessorCtrl'].setValidators([Validators.required, Validators.minLength(3), CustomValidators.noLeadingOrTrailingWhitespace]);
        } else {
            this.memberForm.controls['paymentProcessorCtrl'].setValidators(null);
        }
        this.memberForm.controls['paymentProcessorCtrl'].updateValueAndValidity();
    }

    initLocation(address: Address, callback: any, memberType = MemberType.BUSINESS) {
        this.addressForm = this.addressFormService.initializeForm(address, memberType);
        if (this.memberType !== MemberType.INDIVIDUAL_MERCHANT) {
            this.addressForm.controls['zipPostalCodeCtrl'].setValidators((this.addressForm.get('countryCtrl').value === 'United States'
                || this.addressForm.get('countryCtrl').value === 'Canada'
                || this.addressForm.get('countryCtrl').value === 'Mexico')
                ? [Validators.required, CustomValidators.zipCode] : [Validators.required, CustomValidators.noLeadingOrTrailingWhitespace, Validators.minLength(3)]);
        }
        // disable the forms based roles.
        if (this.readOnly || this.memberType === MemberType.CONSUMER) {
            this.addressForm.disable();
        }
        if (!this.isMerchant()) {
            this.addressForm.controls['countryCtrl'].disable();
        }
        this.addressForm.markAsPristine();
        this.changeDetectorRef.detectChanges();
        this.updateValidity();
        if (callback) {
            callback();
        }

        this.addressForm.valueChanges.subscribe(() => {
            OnboardingTrackingService.isInternalDetailsValid();
            this.updateValidity();
        });
    }

    initMerchantAccountConfig(merchantAccountConfig: MerchantAccountConfig) {
        this.merchantAccountConfig = merchantAccountConfig;
        this.merchantType = this.merchantAccountConfig.shared ? 'PUBLIC' : 'PRIVATE';
    }

    onNavigate(reset: any) {
        if (this.isBusinessDetailsValid()) {
            const dialogConfig: MatDialogConfig = {};
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'normal-modal';
            dialogConfig.disableClose = true;

            dialogConfig.data = {
                title: 'You have unsaved changes.',
                description: 'Click Save to save your changes and continue, or click Ignore to continue without saving.',
                confirmText: 'Save',
                confirmAction: 'save',
                rejectText: 'Ignore',
                rejectAction: 'ignore'
            };

            const dialog = this.dialog.open(ConfirmModalComponent, dialogConfig);

            dialog?.afterClosed().subscribe((confirmAction: any) => {
                if (confirmAction === 'save') {
                    this.updateInformation(() => {
                        reset();
                        this.next.emit();
                    });
                } else if (confirmAction === 'ignore') {
                    this.initForms(() => {
                        reset();
                        this.next.emit();
                    });
                } else {
                    reset();
                }
            });
        } else {
            reset();
            this.next.emit();
        }
    }

    onSave(reset?: any) {
        if (this.isBusinessMerchant()) {
            const taxInfo = this.memberFormService.getTaxInfo(this.merchantTaxForm);
            this.memberService.saveTaxInfo(taxInfo).subscribe((result: TaxInfo) => {
                this.merchantTaxForm = this.memberFormService.initializeTaxForm(result);
                this.merchantTaxForm.controls['einCtrl'].setValidators([Validators.pattern('^([0-9]{9})$')]);
                this.updateInformation(reset);
            });
        } else {
            this.updateInformation(reset);
        }
    }

    updateInformation(reset?: any) {
        const memberToSave = this.memberFormService.getMember(this.memberForm);
        this.memberService.saveMember(memberToSave).subscribe((member: Member) => {
            this.memberUpdated.emit(member);
            const address = this.addressFormService.getAddress(this.addressForm);
            if (address.streetAddressOne && address.city && address.stateProvince && address.zipPostalCode) {
                this.addressService.linkAddressToOccupant(address, this.memberId, this.memberId).subscribe((occupant: AddressOccupant) => {
                    if (memberToSave.source !== MemberSource.PAYMENT_PROCESSOR && (this.memberType === MemberType.BUSINESS || this.memberType === MemberType.BUSINESS_MERCHANT)) {
                        this.corporateEntityKeyParty.name = member.name;
                        this.corporateEntityKeyParty.dbaName = (member as CorporateEntity).dbaName;
                        this.corporateEntityKeyParty.businessType = (member as CorporateEntity).businessType;
                        this.corporateEntityKeyParty.officePhone = (member as CorporateEntity).phone;
                        this.corporateEntityKeyParty.taxId = member.taxId;
                        this.corporateEntityKeyParty.email = member.email;
                        this.corporateEntityKeyParty.address = occupant.address;
                        this.keyPartyService.saveCorporateKeyParty(this.corporateEntityKeyParty).subscribe((_corporateEntityKeyParty: CorporateKeyParty) => {
                            this.initForms(reset);
                        });
                    } else {
                        this.initForms(reset);
                    }
                    this.addressService.getAllAddresses(this.memberId).subscribe((locations: PagedResponse<Address>) => {
                        OnboardingTrackingService.setLocationInfo(locations.content);
                        if (this.isMerchant() && this.merchantAccountConfig.merchantMemberAccount?.address?.country !== this.addressForm.controls['countryCtrl'].value
                            && Configuration.isChecksAvailable(this.merchantAccountConfig.merchantMemberAccount?.address?.country) !== Configuration.isChecksAvailable(this.addressForm.controls['countryCtrl'].value)) {
                            this.merchantAccountService.updateMerchantConfig(this.merchantAccountConfig, this.addressForm.controls['countryCtrl'].value);
                        }
                    });
                });
            } else {
                this.initForms(reset);
            }
        }, (error: any) => {
            if (reset) {
                reset();
            }
            throw error;
        });
    }

    onSelectBoxChange() {
        if (OnboardingTrackingService.isMemberInfoValid() && this.addressForm && this.addressForm.valid && this.memberForm && this.memberForm.valid) {
            this.onSave();
        }
    }

    updateValidity() {
        if (!this.addressForm) {
            return;
        }
        OnboardingTrackingService.setMemberInfo(this.addressFormService.getAddress(this.addressForm), this.memberFormService.getMember(this.memberForm));
        OnboardingTrackingService.setMemberInfoDirty(this.addressForm.dirty || this.memberForm.dirty || (this.merchantTaxForm && this.merchantTaxForm.dirty));
    }

    isBusinessDetailsValid() {
        if (this.isBusinessMerchant()) {
            return OnboardingTrackingService.isMemberInfoValid() && this.addressForm && this.addressForm.valid
                && this.memberForm && this.memberForm.valid
                && this.merchantTaxForm && this.merchantTaxForm.valid
                && OnboardingTrackingService.isMemberInfoDirty();
        } else {
            return OnboardingTrackingService.isMemberInfoValid() && this.addressForm && this.addressForm.valid
                && this.memberForm && this.memberForm.valid && OnboardingTrackingService.isMemberInfoDirty();
        }
    }

    onMerchantTypeChanged(merchantType: any) {
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        overlayRef.attach(new ComponentPortal(LoaderComponent));

        const updates = {
            shared: merchantType === 'PUBLIC'
        };
        this.merchantAccountService.updateMerchantAccountConfig(this.merchantAccountConfig.id, updates).subscribe((merchantAccountConfig: MerchantAccountConfig) => {
            this.initMerchantAccountConfig(merchantAccountConfig);
            overlayRef.detach();
            OnboardingTrackingService.setPrivateMerchant(!merchantAccountConfig.shared);
        }, (error: any) => {
            overlayRef.detach();
            throw error;
        });
    }

    updateMemberName() {
        let currentName: string;
        if (this.memberForm.get('nameCtrl') && this.memberForm.get('nameCtrl').value) {
            currentName = this.memberForm.get('nameCtrl').value;
        }
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            title: 'Change Member Name',
            currentName,
            id: this.memberId,
            type: NameChangeType.MEMBER_NAME
        };
        const dialog = this.dialog.open(NameChangeModalComponent, dialogConfig);
        dialog?.afterClosed().subscribe((result: any) => {
            if (result && result.value) {
                window.location.reload();
            }
        });
    }

    onWhitelistTypeChange(whitelistingType: SupportedTransactionType) {
        if (whitelistingType === SupportedTransactionType.NONE) {
            this.whitelistService.deleteWhitelistParty(this.whitelistParty.id).subscribe();
        } else {
            this.whitelistService.getMerchantWhitelistingDetails(this.memberId).subscribe((party: WhitelistRelationshipParty) => {
                if (party) {
                    this.whitelistService.updateWhitelistedParty(party.id, { whitelistedTransactionType: whitelistingType }).subscribe((response: WhitelistRelationshipParty) => {
                        this.whitelistParty = response;
                    });
                } else {
                    const newParty = new WhitelistRelationshipParty();
                    newParty.memberId = this.memberId;
                    newParty.memberName = this.memberName;
                    newParty.whitelistedTransactionType = whitelistingType;
                    this.whitelistService.saveWhitelistedParty(newParty).subscribe((response: WhitelistRelationshipParty) => {
                        this.whitelistParty = response;
                    });
                }
            });
        }
    }

    updateJurisdiction(jurisdictionCode: string) {
        if (this.riskTier === RiskTier.TIER_1) {
            const dialogConfig: MatDialogConfig = {};
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'normal-modal';
            dialogConfig.disableClose = true;
            const jurisdiction = Utils.getJurisdictionName(jurisdictionCode);
            let message = `Any existing Tier 1 members in ${Utils.getJurisdictionName(this.jurisdiction)} will no longer be able to transact with this ${this.memberType === MemberType.BUSINESS ? 'member' : 'merchant'}.`;
            if (!this.jurisdiction) {
                message = `Only Tier 1 members in ${jurisdiction} will be able to transact with this ${this.memberType === MemberType.BUSINESS ? 'member' : 'merchant'}.`;
            }
            dialogConfig.data = {
                title: `Jurisdiction Change for Tier 1 ${this.memberType === MemberType.BUSINESS ? 'Member' : 'Merchant'}`,
                description: `<p>You are about to change the jurisdiction to <b>${jurisdiction}</b>.<p><b>${message}</b></p>`
                    + '<p>Click Change to if you are certain you want to update the jurisdiction.</p>',
                confirmText: 'Change',
                confirmAction: 'change',
                rejectText: 'Cancel',
                rejectAction: 'cancel'
            };

            const dialog = this.dialog.open(ConfirmModalComponent, dialogConfig);

            dialog?.afterClosed().subscribe((confirmAction: any) => {
                if (confirmAction === 'change') {
                    this.handleJurisdictionChange(jurisdictionCode);
                } else {
                    this.resetJurisdiction();
                }
            });

        } else {
            this.handleJurisdictionChange(jurisdictionCode);
        }
    }

    handleJurisdictionChange(jurisdiction: string) {
        let overlayRef;
        if (this.status !== MemberStatus.APPLIED || this.isAuthority) {
            overlayRef = this.overlay.create({
                positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                hasBackdrop: true
            });
            overlayRef.attach(new ComponentPortal(LoaderComponent));
        }

        const updates = {
            jurisdiction: jurisdiction || null
        };
        this.memberService.updateMember(this.memberId, updates).subscribe((member: Member) => {
            this.memberUpdated.emit(member);
            OnboardingTrackingService.setJurisdiction(member.jurisdiction);
            this.initForms(() => {
                overlayRef?.detach();
            });
            this.multipleMembersWithSameNameWithoutJurisdiction = false;
            this.multipleMembersWithSameNameAndSameJurisdiction = false;
        }, (error: any) => {
            this.resetJurisdiction();
            overlayRef?.detach();
            throw error;
        });
    }

    mandatoryJurisdictionNotSet() {
        return (this.memberType === MemberType.BUSINESS_MERCHANT || this.memberType === MemberType.BUSINESS) &&
            this.riskTier === RiskTier.TIER_1 && !this.memberForm.controls['jurisdictionCtrl'].value;
    }

    resetJurisdiction() {
        this.memberForm.controls['jurisdictionCtrl'].patchValue(this.jurisdiction);
        this.memberForm.controls['jurisdictionCtrl'].markAsPristine();
        this.memberForm.controls['jurisdictionCtrl'].updateValueAndValidity();
    }

    isCorporate() {
        return this.memberType === MemberType.BUSINESS || this.memberType === MemberType.BUSINESS_MERCHANT || this.memberType === MemberType.FEE_ACCOUNT;
    }

    isIndividual() {
        return this.memberType === MemberType.CONSUMER || this.memberType === MemberType.INDIVIDUAL_MERCHANT;
    }

    isMerchant() {
        return this.memberType === MemberType.BUSINESS_MERCHANT || this.memberType === MemberType.INDIVIDUAL_MERCHANT;
    }

    isBusiness() {
        return this.memberType === MemberType.BUSINESS;
    }

    isBusinessMerchant() {
        return this.memberType === MemberType.BUSINESS_MERCHANT;
    }

    isIndividualMerchant() {
        return this.memberType === MemberType.INDIVIDUAL_MERCHANT;
    }

    isFeeAccount() {
        return this.memberType === MemberType.FEE_ACCOUNT;
    }
}
