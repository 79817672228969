<form *ngIf="memberForm" [formGroup]="memberForm">
    <div class="row">
        <div class="col-lg-12">
            <p class="form-title">Risk Profile</p>
            <div class="row">
                <div class="col-6">
                    <pc-text-option-selector [required]="true" [label]="'MRB Tier'" [translated]="true" (selected)="updateTier()" [class]="isTierValid() ? '' : 'required'"
                        [options]="riskTiers" [formCtrl]="'tierCtrl'" [formGroup]="memberForm" [defaultValue]="'NOT_TIERED'">
                    </pc-text-option-selector>
                </div>
                <div class="col-6">
                    <pc-text-input [label]="'EDD Risk Level'" [formCtrl]="'eddRiskLevelCtrl'" [formGroup]="memberForm" [placeholder]="'Not Reviewed'">
                    </pc-text-input>
                </div>
                <div class="col-6">
                    <pc-text-option-selector [required]="true" [label]="'FCC Risk Rating'" [translated]="true" (selected)="updateRating()" [class]="isRatingValid() ? '' : 'required'"
                        [options]="riskRatings" [formCtrl]="'ratingCtrl'" [formGroup]="memberForm" [defaultValue]="'NOT_RATED'">
                    </pc-text-option-selector>
                </div>
                <div class="col-6">
<!--                    <pc-text-input [label]="'Last Reviewed'" [formCtrl]="'reviewedDateCtrl'" [formGroup]="memberForm" [placeholder]="'Not Reviewed'">-->
<!--                    </pc-text-input>-->
                </div>
            </div>
        </div>
        <div *ngIf="isMember()">
            <div class="form-title">Member Functions</div>
            <div>
                <pc-simple-checkbox [label]="getPartnerFeatureLabel()" [class]="'no-margin-bottom'" [formCtrl]="'partnerCtrl'" [formGroup]="memberFunctionForm" [invert]="true" (changed)="updatePartnerFunction()">
                </pc-simple-checkbox>
            </div>
        </div>
        <div class="col-12" *ngIf="(memberType === MemberType.BUSINESS_MERCHANT || memberType === MemberType.INDIVIDUAL_MERCHANT) && merchantForm">
            <div class="row">
                <div class="col-12"><p class="form-title">Merchant Details</p></div>
                <div class="col-6">
                    <pc-text-option-selector [label]="'Show Merchant For:'" [options]="transactionOptions"
                        (selected)="onAllowedTransactionsValueChange($event)" [formCtrl]="'merchantAccountTypeCtrl'" [formGroup]="merchantForm" [translated]="true">
                    </pc-text-option-selector>
                    <pc-text-option-selector [label]="'Default Incoming Transaction Type'" [options]="incomingOptions"
                        (selected)="onTransactionTypeChange($event)" [formCtrl]="'defaultIncomingTransactionTypeCtrl'" [formGroup]="merchantForm" [translated]="true">
                    </pc-text-option-selector>
                    <pc-text-option-selector [label]="'Default Outgoing Transaction Type'" [options]="outgoingOptions"
                        (selected)="onTransactionTypeChange($event)" [formCtrl]="'defaultOutgoingTransactionTypeCtrl'" [formGroup]="merchantForm" [translated]="true">
                    </pc-text-option-selector>
                </div>
                <div class="col-6">
                    <pc-form-text-area [rows]="3" [label]="'Merchant Account ID Notes'" [formCtrl]="'merchantAccountNoteCtrl'"
                        [formGroup]="merchantForm" [placeholder]="'Enter any instructions specific to the account id for this merchant.'">
                    </pc-form-text-area>
                    <p class="merchant-notification">Merchant Notification</p>
                    <pc-simple-checkbox [label]="'Notify merchant of payment via email'" [formCtrl]="'merchantNotificationEmailCtrl'" [formGroup]="merchantForm"
                        [invert]="true" (changed)="onMerchantEmailNotificationChange()">
                    </pc-simple-checkbox>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex partner-setting" *ngIf="member?.memberType === MemberType.CONSUMER">
        <div class="form-title"> Blocklisted </div>
        <div><i class="fa fa-2x" [class.fa-toggle-on]="member?.status === MemberStatus.BLOCKED" [class.fa-toggle-off]="!(member?.status === MemberStatus.BLOCKED)" (click)="toggleBlocklistConsumer()" [class]="getClass()"></i></div>
    </div>
    <div class="row" *ngIf="memberType !== MemberType.CONSUMER && memberType !== MemberType.FEE_ACCOUNT && keyParties.length">
        <div class="col-12">
            <p class="form-title">Supporting Documentation</p>
            <div class="col-sm-12 horizontal-scroll mat-table-container pt-2 pb-2">
                <div class="mat-table-spinner" *ngIf="isLoadingResults">
                    <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
                </div>
                <table mat-table [dataSource]="keyParties">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let element"> <i
                                *ngIf="!((merchantConfig && !merchantConfig.shared) || (element.individualKeyParty && element.contactPerson)) && element.status === KeyPartyStatus.ACTIVE"
                                class="fa fa-onboard" [ngClass]="getIconClass(element)"></i> {{element.name}} </td>
                    </ng-container>
                    <ng-container matColumnDef="owner_percentage">
                        <th class="centered" mat-header-cell *matHeaderCellDef> % Owner </th>
                        <td class="centered" mat-cell *matCellDef="let element"> <strong>{{element.percentOwnership}}</strong></td>
                    </ng-container>
                    <ng-container matColumnDef="adverse_media_screen">
                        <th mat-header-cell class="centered" *matHeaderCellDef> Adverse Media Screen </th>
                        <td mat-cell class="centered" *matCellDef="let element">
                            <button class="blue-button" [disabled]="!authService.isAuthority() || element.status === KeyPartyStatus.DISABLED" type="button" (click)="uploadMediaScreen(element)"> Upload Media Screen </button>
                            <div class="document-list" *ngIf="element.adverseMediaScreen">
                                <div *ngFor="let document of element.adverseMediaScreen">
                                    <i *ngIf="authService.isAuthority() && element.status === KeyPartyStatus.ACTIVE" class="fa fa-times" (click)="deleteDocument(document.id)"></i>
                                    <a class="dashboard-list-a" (click)="downloadFile(document)">
                                        <i class="fa fa-paperclip"></i>{{document.fileName}}
                                    </a>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="id_risk_screen">
                        <th mat-header-cell class="centered" *matHeaderCellDef> ID and Risk Screen </th>
                        <td mat-cell class="centered" *matCellDef="let element">
                            <button class="blue-button" [disabled]="!authService.isAuthority() || element.status === KeyPartyStatus.DISABLED" type="button" (click)="uploadIdRiskScreen(element)"> Upload ID/Risk Screen </button>
                            <div class="document-list" *ngIf="element.idRiskScreen">
                                <div *ngFor="let document of element.idRiskScreen">
                                    <i *ngIf="authService.isAuthority() && element.status === KeyPartyStatus.ACTIVE" class="fa fa-times" (click)="deleteDocument(document.id)"></i>
                                    <a class="dashboard-list-a" (click)="downloadFile(document)">
                                        <i class="fa fa-paperclip"></i>{{document.fileName}}
                                    </a>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="comply_advantage">
                        <th mat-header-cell class="centered" *matHeaderCellDef> Comply Advantage </th>
                        <td mat-cell class="centered" *matCellDef="let element">
                            <pc-kyc-search-button [keyParty]="element"></pc-kyc-search-button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.disabled]="row.status === KeyPartyStatus.DISABLED"></tr>
                </table>
            </div>
        </div>
    </div>
    <pc-kyc-extra-search *ngIf="complianceSearchEnabled && memberType !== MemberType.CONSUMER && memberType !== MemberType.FEE_ACCOUNT" [keyParties]="keyParties"></pc-kyc-extra-search>
    <div class="row" *ngIf="memberType !== MemberType.CONSUMER && memberType !== MemberType.FEE_ACCOUNT">
        <div class="col-sm-12 horizontal-scroll mat-table-container pt-2 pb-2">
            <p class="form-title">Other Documentation</p>
            <div class="mat-table-spinner" *ngIf="isLoadingResults">
                <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
            </div>
            <table mat-table #otherDocumentsTable [dataSource]="otherDocuments">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let element">
                        <a class="dashboard-list-a" (click)="downloadFile(element)">
                            <i class="fa fa-paperclip"></i>{{element.fileName}}
                        </a>
                    </td>
                </ng-container>
                <ng-container matColumnDef="date">
                    <th class="centered" mat-header-cell *matHeaderCellDef> Date </th>
                    <td class="centered" mat-cell *matCellDef="let element">
                        <span *ngIf="element.created">
                            <pc-date-time [date]="element.created"></pc-date-time>
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th class="centered" mat-header-cell *matHeaderCellDef> Action </th>
                    <td class="centered" mat-cell *matCellDef="let element">
                        <strong> <i class="fa fa-times" (click)="deleteDocument(element.id)"></i> </strong>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedOtherDocColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedOtherDocColumns;"></tr>
            </table>
        </div>
        <div class="col-12">
            <button [disabled]="!authService.isAuthority()" class="blue-button pull-right" type="button" (click)="uploadOtherDiligenceDocument()">Upload Other Documentation</button>
        </div>
    </div>
    <div class="row" *ngIf="memberType !== MemberType.BUSINESS_MERCHANT && memberType !== MemberType.INDIVIDUAL_MERCHANT && memberType !== MemberType.CONSUMER">
        <div class="col-12 pt-2">
            <span class="form-title">Whitelisted Parties</span>
        </div>
        <pt-whitelist-relationship-party-details class="col-12" [memberId]="memberId"></pt-whitelist-relationship-party-details>
    </div>
    <div class="row mb-3">
        <div class="col-12">
            <span class="form-title">Internal Notes</span>
        </div>
        <pc-notes-table class="col-12" [memberId]="memberId" [type]="CommentType.INTERNAL" [changeTrigger]="changeTrigger"></pc-notes-table>
        <div class="col-12">
            <button class="blue-button pull-right" [disabled]="!authService.isAuthority()" (click)="onAddNote()" type="button"><i class="fa fa-plus pull-left dashboard-icon"></i><div class="pull-right-button">Add Note</div></button>
        </div>
    </div>
</form>

<a class="document-download-link" #downloadDocumentLink></a>

<div class="button-panel pull-right">
    <pc-submit-button *ngIf="!isFeeAccount()" [enabled]="isInternalDetailsValid" [submit]="onSave" [text]="'Save'"></pc-submit-button>
    <pc-submit-button  [submit]="onNavigate" [isSubmitButton]="true" [text]="'Next'" [rightIcon]="'arrow-right'" *ngIf="task"></pc-submit-button>
</div>
