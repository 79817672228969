<div class="col-sm-12 horizontal-scroll mat-table-container mt-3">
    <div class="mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <table mat-table [dataSource]="tasksObservable | async" matSort [matSortActive]="pageTracking.sort" matSortDisableClear [matSortDirection]="pageTracking.sortDir">
        <ng-container matColumnDef="priority">
            <th mat-header-cell class="centered" *matHeaderCellDef mat-sort-header="priority"> Priority </th>
            <td mat-cell class="centered" *matCellDef="let element">
                <span [ngClass]="isPriority(element) ? 'priority fw-bold' : ''">
                    {{ element.priority === 80 ? 'HIGH' : '' }}
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="description"> Name </th>
            <td mat-cell *matCellDef="let element">
                <span class="work-list-item" (click)="loadTask(element.id)">
                    {{ element.name }}
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="date">
            <th mat-header-cell class="centered" *matHeaderCellDef mat-sort-header="created"> Date </th>
            <td mat-cell class="centered" [ngClass]="isPriority(element) ? 'priority fw-bold' : ''" *matCellDef="let element">
                <pc-date-time [date]="element.created"></pc-date-time>
            </td>
        </ng-container>
        <ng-container matColumnDef="process">
            <th mat-header-cell *matHeaderCellDef> Process </th>
            <td mat-cell *matCellDef="let element">
                {{element?.processDefinition?.name}}
            </td>
        </ng-container>
        <ng-container matColumnDef="assignee">
            <th class="assignee" mat-header-cell *matHeaderCellDef> Assignee </th>
            <td class="assignee" mat-cell *matCellDef="let element">
                <pc-authority-workflow-assign *ngIf="authorityUsers.length" [processInstanceId]="element.processInstanceId" [taskId]="element.id" [assignee]="element.variables.assignedAuthority" [authorityUsers]="authorityUsers" [tableView]="true"
                (noteAdded)="refreshList()">
                </pc-authority-workflow-assign>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator class="mat-paginator-sticky" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
</div>
