 <div class="col-12">
    <div class="row mb-3">
        <div class="col-8">
            <span class="form-title">Linked Bank Accounts</span>
        </div>
        <div class="col-4">
            <button pc-no-dbl-click *ngIf="authService.isAuthority() && allowAddBankAccount" class="blue-button pull-right" (click)="addBankingInformation()" type="button" submit-button><i class="fa fa-plus pull-left dashboard-icon"></i><div class="pull-right-button">Add Bank Account</div></button>
        </div>
    </div>
    <div class="col-sm-12 horizontal-scroll mat-table-container">
        <div class="mat-table-spinner" *ngIf="isLoadingResults">
            <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
        </div>
        <table mat-table [dataSource]="accounts" matSort [matSortActive]="pageTracking.sort" matSortDisableClear [matSortDirection]="pageTracking.sortDir" multiTemplateDataRows>
            <ng-container matColumnDef="status">
                <th class="centered" mat-header-cell  *matHeaderCellDef mat-sort-header="status"> Status </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <pc-bank-account-badge [parent]="element"></pc-bank-account-badge>
                </td>
            </ng-container>
            <ng-container matColumnDef="account_number">
                <th class="centered" mat-header-cell *matHeaderCellDef> Account Number </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <span *ngIf="element.accountNumber"> {{ getBankAccountNumber(element.accountNumber) }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="ach_routing_number">
                <th class="centered" mat-header-cell *matHeaderCellDef> ACH Routing Number </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <span *ngIf="element.routingNumber"> {{ getBankRoutingNumber(element.routingNumber) }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="ach_identifier">
                <th class="centered" mat-header-cell *matHeaderCellDef> ACH Identifier </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <span *ngIf="element.achIdentifier"> {{ element.achIdentifier }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="wire_routing_number">
                <th class="centered" mat-header-cell *matHeaderCellDef> Wire Routing Number </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <span *ngIf="element.wireRoutingNumber"> {{ getBankRoutingNumber(element.wireRoutingNumber) }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="holder">
                <th mat-header-cell *matHeaderCellDef> Holder </th>
                <td mat-cell *matCellDef="let element">
                    <span>
                        <b *ngIf="element.holder">{{element.holder}}</b>
                        <span *ngIf="element.externalAccountDetails?.phone">
                            <br>
                            {{element.externalAccountDetails.phone | phone}}
                        </span>
                        <span *ngIf="element.externalAccountDetails?.email">
                            <br>
                            {{element.externalAccountDetails.email}}
                        </span>
                    </span>
                    <pc-business-address *ngIf="element.externalAccountDetails?.addressId" [addressId]="element.externalAccountDetails.addressId">
                    </pc-business-address>
                </td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell  *matHeaderCellDef mat-sort-header="name"> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="type"> Type </th>
                <td mat-cell *matCellDef="let element"> {{element.type | translate }} </td>
            </ng-container>
            <ng-container matColumnDef="source">
                <th mat-header-cell *matHeaderCellDef> Source </th>
                <td mat-cell *matCellDef="let element"> {{element.source | translate}} </td>
            </ng-container>
            <ng-container matColumnDef="financial_institution">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="financialInstitution"> Bank </th>
                <td mat-cell *matCellDef="let element">  {{element.financialInstitution}} </td>
            </ng-container>
            <ng-container matColumnDef="date_added">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="created"> Date Linked </th>
                <td mat-cell *matCellDef="let element"> {{ element.created | date:'mediumDate'}} </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell class="centered" *matHeaderCellDef> Actions </th>
                <td mat-cell  class="actions centered" *matCellDef="let element">
                    <i *ngIf="((element.status === BankAccountStatus.ACTIVE || element.status === BankAccountStatus.DISABLED) && isSourceValid(element)) && authService.isAuthorityOrAdmin()" class="fa" [class.fa-toggle-on]="isEnabled(element)" [class.fa-toggle-off]="!isEnabled(element)" (click)="toggleEnabled($event, element)"></i>
                    <i *ngIf="(element.status === BankAccountStatus.DISABLED) && authService.isAuthority()" class="fa fa-times" (click)="onDeleteAccount(element)"></i>
                    <i *ngIf="element.status === BankAccountStatus.PENDING && (member?.memberType === MemberType.INDIVIDUAL_MERCHANT || member?.memberType === MemberType.BUSINESS_MERCHANT) && authService.isAuthority()"
                        class="fa fa-pencil" (click)="addBankingInformation(element)"></i>
                    <i *ngIf="element.reviewNotes && member?.memberType === MemberType.BUSINESS && authService.isAuthorityOrReviewer()" (click)="expandedElement = expandedElement === element ? null : element" class="fa fa-question-circle"></i>
                </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="example-element-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                        <ng-container *ngIf="expandedElement === element">
                            <pc-bank-account-review-notes [bankAccount]="element"></pc-bank-account-review-notes>
                        </ng-container>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-expand-row" [class.example-expanded-row]="expandedElement === element"
                [ngClass]="!isEnabled(element) ? 'disabled' : ''">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>
        <mat-paginator [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
    </div>
</div>
<div class="button-panel">
    <pc-submit-button class="pull-right" [submit]="onNavigate" [text]="'Next'" [rightIcon]="'arrow-right'" *ngIf="task && authService.isAuthorityOrAdmin()"></pc-submit-button>
</div>
