import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs';

/**
 * By wrapping the graphql client in our domain, if in the future we need to change it, we would only change this service
 * instead of all the other services.
 */
@Injectable({
    providedIn: 'root'
})
export class GqlService {

    constructor(private apollo: Apollo) { }

    /**
     * Because the alias <b>result:</b> added to the graphql queries, we're able to normalize the result instead of let all the
     * services or components handling it.
     *
     * @param query - query built by the gql-query.builder
     * @param variables - these variables should match the query variables when implementing the gql-query-type interface
     * @see gql-query.builder
     * @see gql-query-type
     */
    query(query: any, variables?: object) {
        return this.apollo.query({
            query,
            variables,
            errorPolicy: 'all',
            fetchPolicy: 'no-cache'
        }).pipe(
            map((response: any) => {
                return response?.data?.result;
            })
        );
    }
}
