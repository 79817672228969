import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { AddressType } from '../models/address';
import { CustomValidators } from '../validators/custom.validators';

@Injectable({
    providedIn: 'root'
})
export class OnboardingFormService {

    constructor(private formbuilder: UntypedFormBuilder) {}

    initializeContactInfo(taskVariables: any): UntypedFormGroup {
        return this.formbuilder.group({
            firstNameCtrl: new UntypedFormControl(taskVariables.firstName, [Validators.required, CustomValidators.personName, Validators.maxLength(32)]),
            middleNameCtrl: new UntypedFormControl(taskVariables.middleName, [CustomValidators.middleName, Validators.maxLength(32)]),
            lastNameCtrl: new UntypedFormControl(taskVariables.lastName, [Validators.required,  CustomValidators.personName, Validators.maxLength(32)]),
            emailCtrl: new UntypedFormControl(taskVariables.email, [Validators.required, CustomValidators.email]),
            mobilePhoneCtrl: new UntypedFormControl(taskVariables.mobilePhone, [Validators.required, CustomValidators.phone]),
            birthDateCtrl: new UntypedFormControl(taskVariables.dateOfBirth, [Validators.required, CustomValidators.birthDate])
        });
    }

    initializeAddress(taskVariables: any): UntypedFormGroup {
        const form = this.formbuilder.group({
            streetAddressOneCtrl: new UntypedFormControl(taskVariables.streetAddressOne, [Validators.required]),
            cityCtrl: new UntypedFormControl(taskVariables.city, [Validators.required, Validators.minLength(2), CustomValidators.noLeadingOrTrailingWhitespace]),
            stateProvinceCtrl: new UntypedFormControl(taskVariables.stateProvince, [Validators.required, Validators.minLength(2), CustomValidators.noLeadingOrTrailingWhitespace]),
            countryCtrl: new UntypedFormControl(taskVariables.country || 'United States', [Validators.required, Validators.minLength(2), CustomValidators.noLeadingOrTrailingWhitespace]),
            zipPostalCodeCtrl: new UntypedFormControl(taskVariables.zipPostalCode, [Validators.required])
        });
        form.controls['countryCtrl'].disable();
        return form;
    }

    initializeVerificationForm(): UntypedFormGroup {
        return this.formbuilder.group({
            codeCtrl: new UntypedFormControl('', [Validators.required, Validators.min(100000), Validators.max(999999)])
        });
    }

    initializeTipForm(): UntypedFormGroup {
        return this.formbuilder.group({
            tipCtrl: new UntypedFormControl('')
        });
    }

    getOnboardingInformation(contactInfoForm: UntypedFormGroup, addressForm: UntypedFormGroup): any {
        return {
            firstName: contactInfoForm.get('firstNameCtrl').value,
            middleName: contactInfoForm.get('middleNameCtrl').value,
            lastName: contactInfoForm.get('lastNameCtrl').value,
            email: contactInfoForm.get('emailCtrl').value,
            mobilePhone: contactInfoForm.get('mobilePhoneCtrl').value,
            dateOfBirth: contactInfoForm.get('birthDateCtrl').value,
            address: {
                streetAddressOne: addressForm.get('streetAddressOneCtrl').value,
                city: addressForm.get('cityCtrl').value,
                stateProvince: addressForm.get('stateProvinceCtrl').value,
                country: addressForm.get('countryCtrl').value,
                zipPostalCode: addressForm.get('zipPostalCodeCtrl').value,
                type: AddressType.PRIMARY
            }
        };
    }
}
