import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsRoutingModule } from './reports-routing.module';
import {
    AuthorityReportsTableHeaderComponent, AuthoritySarReportsComponent, AuthorityCtrReportsComponent, AuthorityReportsDetailsComponent, MemberMonthlyStatementReportComponent,
    MemberReportsTableHeaderComponent, MemberWeeklyActivityReportComponent, SarContinuingReportListComponent, AuthorityOperationalReportsComponent,
    AuthorityFincenInvestigationReportComponent, AuthorityDailyReconciliationReportComponent,
    NachaComplianceReportComponent
} from './components';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedComponentsModule } from 'projects/components/src/lib/shared-components.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeModule } from '../home/home.module';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { FincenEditNarrativeModalComponent } from 'projects/components/src/lib/modal/fincen-edit-narrative-modal/fincen-edit-narrative-modal.component';

@NgModule({
    declarations: [
        AuthorityReportsTableHeaderComponent,
        AuthoritySarReportsComponent,
        AuthorityCtrReportsComponent,
        AuthorityReportsDetailsComponent,
        AuthorityDailyReconciliationReportComponent,
        MemberMonthlyStatementReportComponent,
        MemberReportsTableHeaderComponent,
        MemberWeeklyActivityReportComponent,
        SarContinuingReportListComponent,
        AuthorityOperationalReportsComponent,
        AuthorityFincenInvestigationReportComponent,
        FincenEditNarrativeModalComponent,
        NachaComplianceReportComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        SharedComponentsModule,
        TranslateModule,
        HomeModule,
        NgbModule,
        ReportsRoutingModule,
        MatTableModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatSortModule
    ],
    exports: [
        MemberReportsTableHeaderComponent
    ]
})
export class ReportsModule { }
