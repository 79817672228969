import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {
    AuthService, MemberFunction, MemberType, PagedResponse, PaymentService, Transaction, TransactionTableFilter, MemberService, Member, MemberAccountType, MemberAccount,
    MemberAccountService, TransactionType, TransactionSource
} from 'projects/services/src/public-api';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, mergeMap, of as observableOf } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

@Component({
    selector: 'pc-transaction-list',
    templateUrl: './transaction-list.component.html'
})
export class TransactionListComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

    TransactionType = TransactionType;
    TransactionSource = TransactionSource;

    @Input() filter: TransactionTableFilter;
    @Input() changeTrigger: number;
    @Input() readOnly = false;
    @Input() isMemberListing = true;
    @Input() defaultSort = 'transactionDate';
    @Input() memberId: string;

    resultsLength = 0;
    transactions: Transaction[] = [];
    pageTracking: PageTracking;
    isLoadingResults = true;
    displayedColumns: string[] = [];
    subscription: any;
    filterEvent: EventEmitter<null> = new EventEmitter<null>();
    accountNameMap = {};

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(public authService: AuthService,
                private router: Router,
                private paymentService: PaymentService,
                private memberService: MemberService,
                private memberAccountService: MemberAccountService,
                private route: ActivatedRoute,
                private cdr: ChangeDetectorRef) {}

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnInit() {
        if (!this.filter.memberId && this.memberId) {
            this.filter.memberId = this.memberId;
            this.memberService.loadMember(this.memberId).subscribe((member: Member) => {
                this.filter.memberType = member.memberType;
                this.filter.memberFunctions = member.memberFunctions;
            });
        }
        if (this.isMemberListing || this.memberId || this.filter.memberId) {
            this.memberAccountService.getMemberAccountsByTypesAndStatus(this.filter.memberId, [MemberAccountType.BUSINESS, MemberAccountType.CONSUMER], [], 0, 100, '', '').subscribe((memberAccounts: PagedResponse<MemberAccount>) => {
                memberAccounts.content.forEach((memberAccount: MemberAccount) => {
                    this.accountNameMap[memberAccount.id] = memberAccount.accountName;
                });
            });
        }
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, this.defaultSort, 'desc', 100);
        this.loadDisplayColumns();
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.changeTrigger && !changes.changeTrigger.firstChange)) {
            this.loadDisplayColumns();
            this.filterEvent.emit();
        }
    }

    loadTransactionDetails(transaction: Transaction, event: any) {
        if (!this.readOnly && event.target.tagName !== 'A') {
            this.router.navigate(['/transaction/details/', transaction.id]);
        }
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription =  merge(this.sort.sortChange, this.paginator.page, this.filterEvent).pipe(
            startWith({}),
            mergeMap(() => {
                this.isLoadingResults = true;
                if (this.filter.counterParty) {
                    return this.paymentService.getBothInvolvedTransactions(this.filter.memberId, this.filter.memberAccountId, this.filter.counterParty, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
                }
                return this.paymentService.getTransactionsByStatusAndType(this.filter.memberId, this.filter.memberAccountId, this.filter.transactionState, [], this.filter.paymentRails, this.filter.returnCode, this.filter.transactionSources, this.filter.minAmount, this.filter.maxAmount, this.filter.startDate, this.filter.endDate, this.filter.retailLocationId, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<Transaction>) => {
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((transactions: Transaction[]) => {
            this.transactions = transactions;
            for (const transaction of this.transactions) {
                this.paymentService.loadTransaction(transaction);
            }
            UIUtils.scrollDashboardToTop();
            this.isLoadingResults = false;
        });
    }

    loadDisplayColumns() {
        if (this.authService.isAuthorityOrReviewer()) {
            if (this.filter.memberFunctions.indexOf(MemberFunction.PARTNER) >= 0) {
                this.displayedColumns = ['transactionType', 'payor', 'recipient', 'account', 'status', 'transaction_date', 'completion_date', 'partner_location', 'pt_score', 'amount'];
            } else if (this.memberId || this.filter.memberId) {
                this.displayedColumns = ['transactionType', 'payor', 'recipient', 'account', 'status', 'transaction_date', 'completion_date', 'pt_score', 'amount'];
            } else {
                this.displayedColumns = ['transactionType', 'payor', 'recipient', 'status', 'transaction_date', 'completion_date', 'pt_score', 'amount'];
            }
        } else {
            if (this.authService.hasMemberFunction(MemberFunction.PARTNER) && this.memberId && !this.authService.isRetailer()) {
                this.displayedColumns = ['transactionType', 'payor', 'recipient', 'account', 'status', 'transaction_date', 'completion_date', 'partner_location', 'amount'];
            } else if ((this.filter.memberFunctions.indexOf(MemberFunction.PARTNER) >= 0 || this.authService.hasMemberFunction(MemberFunction.PARTNER)) && this.memberId) {
                this.displayedColumns = ['transactionType', 'payor', 'recipient', 'status', 'transaction_date', 'completion_date', 'partner_location', 'amount'];
            } else if (this.authService.hasMemberFunction(MemberFunction.PARTNER) && !this.isMemberListing) {
                this.displayedColumns = ['transactionType', 'payor', 'recipient', 'status', 'completion_date', 'partner_location', 'amount'];
            } else if (this.memberId && this.memberId === this.authService.getProfile().memberId) {
                this.displayedColumns = ['transactionType', 'payor', 'recipient', 'account', 'status', 'transaction_date', 'completion_date', 'amount'];
            } else {
                this.displayedColumns = ['transactionType', 'payor', 'recipient', 'status', 'completion_date', 'amount'];
            }
        }
    }

    navigateToMember(memberId: string) {
        this.memberService.navigateToMember(memberId);
    }

    public handlePageBottom(event: PageEvent) {
        this.paginator.pageSize = event.pageSize;
        this.paginator.pageIndex = event.pageIndex;
        this.paginator.page.emit(event);
    }

    getAccountName(transaction: Transaction) {
        return this.accountNameMap[this.getAccountId(transaction)];
    }

    getAccountId(transaction: Transaction) {
        if (this.filter.memberAccountId) {
            return this.filter.memberAccountId;
        } else if (this.filter.memberId === transaction.payorMemberId || this.memberId === transaction.payorMemberId) {
            return transaction.payorAccountId;
        } else if (this.filter.memberId === transaction.recipientMemberId || this.memberId === transaction.recipientMemberId) {
            return transaction.recipientAccountId;
        }
        return '';
    }
}
