import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { forkJoin, Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import {
    AccountingService, Address, AuthService, MemberAccountService, AddressService, WalletFunction, MemberAccount, Transaction, SupportingDocument, Upload,
    DocumentType, TransactionStatus, DataroomService, WorkflowService, MetrcStatus, MetrcLicense, MetrcService, AccountingLinkedMember, Configuration,
    MemberService,
    Member,
    RiskTier
} from 'projects/services/src/public-api';
import { BaseModalComponent, ErrorType, LoaderComponent } from 'projects/components/src/public-api';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { map } from 'rxjs/operators';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
    selector: 'pt-deposit-modal',
    templateUrl: './deposit-modal.component.html',
    styleUrls: ['./deposit-modal.component.scss']
})
export class DepositModalComponent extends BaseModalComponent<DepositModalComponent> implements OnInit {

    DepositType = DepositType;

    readonly ACCOUNTING_NOT_LINKED_ERROR = {
        message: 'Please link an accounting system to make deposits from your accounting clients',
        type: ErrorType.WARN
    };
    readonly METRC_NOT_LINKED_ERROR = {
        message: 'METRC is not linked, so manifest tracking is unavailable',
        type: ErrorType.WARN
    };

    memberId: string;
    member: Member;
    memberAccount: MemberAccount;
    submitterId: string;
    isAccountingLinked = true;
    isMetrcLinked = true;
    transactionTotal = '0.00';

    depositTypeSelected = false;
    depositType: DepositType = DepositType.NON_CASH_DEPOSIT;
    cashDepositEnabled = false;
    errorMessage: { message: string, type: ErrorType };

    buttonOrientation: Observable<string>;

    @ViewChild('buttongroup') protected buttongroup: MatButtonToggleGroup;

    constructor(private authService: AuthService,
                private memberAccountService: MemberAccountService,
                private addressService: AddressService,
                private accountingService: AccountingService,
                private dataroomService: DataroomService,
                private workflowService: WorkflowService,
                private notifier: NotificationService,
                private metrcService: MetrcService,
                private memberService: MemberService,
                private overlay: Overlay,
                protected breakpointObserver: BreakpointObserver,
                dialog: MatDialogRef<DepositModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialog);
        this.memberAccount = data.memberAccount;
        this.memberId = this.memberAccount.memberId;
    }

    ngOnInit() {
        this.buttonOrientation = this.breakpointObserver.observe('(min-width: 799px)').pipe(
            map((matches: BreakpointState) => {
                return matches.matches ? 'false' : 'true';
            })
        );
        this.submitterId = this.authService.getProfile().userId;
        this.loadMemberAccount();
        if (this.memberAccountService.hasWalletFunction(this.memberAccount, WalletFunction.CASH_DEPOSIT)) {
            this.cashDepositEnabled = true;
        } else {
            this.depositType = DepositType.NON_CASH_DEPOSIT;
            this.depositTypeSelected = true;
        }
        this.accountingService.getAccountingSystemInfo(this.memberId).subscribe((accountingSystem: AccountingLinkedMember) => {
            if (!accountingSystem?.linked) {
                this.isAccountingLinked = false;
            }
        });
        this.memberService.loadMember(this.memberId).subscribe((member: Member) => {
            this.member = member;
            if (Configuration.getConfig().metrcEnabled && this.isMetrcTier()) {
                this.metrcService.getActiveMetrcLicenseInformation(MetrcStatus.ACTIVE, this.memberId).subscribe((response: MetrcLicense[]) => {
                    this.isMetrcLinked = response.length > 0;
                });
            } else {
                this.isMetrcLinked = false;
            }
        });
    }

    loadMemberAccount() {
        this.addressService.getPrimaryAddress(this.memberAccount.id).subscribe((location: Address) => {
            this.memberAccount.address = location;
        });
    }

    onTotalChange(total: string) {
        this.transactionTotal = total;
    }


    onTypeChange(type: DepositType) {
        this.depositType = type;
    }

    selectDepositType() {
        this.depositTypeSelected = true;
    }

    onSubmit(transaction: Transaction) {
        const filesToUpload: SupportingDocument[] = [];
        transaction.incomes.forEach((income) => {
            income.supportingDocuments.forEach((supportingDocument) => {
                if (!supportingDocument.documentId) {
                    supportingDocument.memberId = this.memberId;
                    filesToUpload.push(supportingDocument);
                }
            });
        });
        let transactionLevelDocuments = [];
        transaction.manifestSupportingDocuments.forEach((supportingDocument) => {
            if (!supportingDocument.documentId) {
                supportingDocument.memberId = this.memberId;
                transaction.supportingDocuments.push(supportingDocument);
                filesToUpload.push(supportingDocument);
            }
        });

        if (filesToUpload.length) {
            const overlayRef = this.overlay.create({
                positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                hasBackdrop: true
            });
            const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
            componentRef.instance.title = 'Uploading files.  Please wait...';
            this.uploadFiles(filesToUpload, transaction.submissionReference).subscribe((results: Upload[]) => {
                for (let i = 0; i < results.length; i++) {
                    filesToUpload[i].documentId = results[i].id;
                    filesToUpload[i].file = null;
                    if (filesToUpload[i].documentType === DocumentType.SEEDTOSALE_TRACK_MANIFEST) {
                        const doc = new SupportingDocument();
                        doc.documentId = results[i].id;
                        doc.documentType = results[i].documentType;
                        doc.memberId = this.memberId;
                        transactionLevelDocuments.push(doc);
                    }
                }
                transaction.supportingDocuments = transactionLevelDocuments;
                overlayRef.dispose();
                this.recordDeposit(transaction);
            }, () => {
                overlayRef.dispose();
                this.notifier.showError('Document upload was unsuccessful. Please check your connection and try again.');
            });
        } else {
            this.recordDeposit(transaction);
        }
    }

    uploadFiles(supportingDocuments: SupportingDocument[], submissionReference: string) {
        return forkJoin(supportingDocuments.map((supportingDocument) => {
            return this.dataroomService.uploadFile(this.memberId, `${this.memberId}/${this.submitterId}/transaction/${submissionReference}/${uuidv4()}`, supportingDocument.file, supportingDocument.file.name, supportingDocument.explanation, DocumentType.DEPOSIT_SUPPORTING_DOCUMENT, this.memberId);
        }));
    }

    recordDeposit(transaction: Transaction) {
        transaction.recipientMemberId = this.memberAccount.memberId;
        transaction.recipientAccountId = this.memberAccount.id;
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
        componentRef.instance.title = 'Submitting transaction...';

        this.workflowService.recordDeposit(transaction).subscribe((result: Transaction) => {
            this.close(true);
            overlayRef.dispose();
            if (result.status === TransactionStatus.PENDING_RFI_AUTHORITY_REVIEW || result.status === TransactionStatus.PENDING_RFI_DUE_DILIGENCE) {
                this.notifier.showSuccess(`Your deposit in the amount of <b>$${transaction.totalAmount}</b> has been submitted and will be reviewed.`);
            } else {
                this.notifier.showSuccess(`A deposit in the amount of <b>$${transaction.totalAmount}</b> has been submitted.`);
            }
        }, (error: any) => {
            overlayRef.dispose();
            if (error.status === 400 || error.status === 404) {
                this.close(true);
            }
            throw error;
        });
    }

    getDepositType() {
        if (this.depositType === DepositType.BUSINESS_CASH_DEPOSIT) {
            return 'Business Cash';
        } else if (this.depositType === DepositType.NON_CASH_DEPOSIT) {
            return 'ACH / Wire';
        }
        return 'Retail Cash';
    }

    isMetrcTier() {
        return this.member.tier === RiskTier.TIER_1 || this.member.tier === RiskTier.TIER_2;
    }
}

export enum DepositType {
    BUSINESS_CASH_DEPOSIT = 'BUSINESS_CASH_DEPOSIT',
    NON_CASH_DEPOSIT = 'NON_CASH_DEPOSIT',
    RETAIL_CASH_DEPOSIT = 'RETAIL_CASH_DEPOSIT'
}
