<div class="row no-gutters">
    <div class="col-auto dashboard-header">
        <img class="dashboard-icon" src="assets/images/audit-icon-2.svg" alt="">
        <span>Audit Records</span>
    </div>
</div>
<div class="row">
    <div *ngIf="formGroup" class="col-auto">
        <pc-member-selector [formGroup]="formGroup" [formCtrl]="'memberCtrl'" [placeholder]="'Search Members'"
            (memberSelected)="memberSelected($event)" [displayLabel]="false" [excludeSelf]="false"
            [memberTypes]="[MemberType.BUSINESS, MemberType.CONSUMER, MemberType.AUTHORITY]" [statuses]="[]">
        </pc-member-selector>
    </div>
    <div class="col-auto">
        <select class="form-control form-input-table-header form-select-sm" type="text" [(ngModel)]="action"  (change)="changedStatus()">
            <option value="">All Actions</option>
            <option value="CREATE">Create</option>
            <option value="CANCELLED">Cancelled</option>
            <option value="DELETE">Delete</option>
            <option value="DISABLE">Disable</option>
            <option value="LOGIN">Login</option>
            <option value="MERGED">Merge</option>
            <option value="SUBMIT">Submit</option>
            <option value="UPDATE">Update</option>
        </select>
    </div>
    <div class="col-auto">
        <select class="form-control form-input-table-header form-select-sm" type="text" [(ngModel)]="auditEntityType" (change)="changedStatus()">
            <option value="">All Entity Types</option>
            <option value="MEMBER">Members</option>
            <option value="MEMBER_ACCOUNT">Member Accounts</option>
            <option value="USER">Users</option>
            <option value="ACCOUNT">Accounts</option>
            <option value="RFI">RFIs</option>
            <option value="RFI_HISTORY">RFI Histories</option>
            <option value="TRANSACTION">Transactions</option>
            <option value="WALLET">Wallets</option>
            <option value="BANK_ACCOUNT">Bank Accounts</option>
            <option value="SIGNATURE">Signatures</option>
            <option value="TAX_INFO">Tax Information</option>
            <option value="FINANCIAL_DETAILS">Financial Details</option>
            <option value="RISK_PROFILE">Risk Profiles</option>
            <option value="KEY_PARTY">Key Parties</option>
            <option value="MERCHANT">Merchant</option>
            <option value="MERCHANT_CONFIG">Merchant Config</option>
            <option value="LICENSE">Licenses</option>
            <option value="EXTERNAL_ACCOUNT">ACH Account Number</option>
            <option value="DOCUMENT">Document</option>
            <option value="LOCATION">Locations</option>
            <option value="COMPANY_WATCHLIST_SCREENING">Company Watchlist Screening</option>
            <option value="INDIVIDUAL_WATCHLIST_SCREENING">Individual Watchlist Screening</option>
            <option value="FINCEN_REPORT">Fincen Report</option>
            <option value="FINCEN_INVESTIGATION">Fincen 314a Investigations</option>
            <option value="BILLING_PROFILE">Billing Profile</option>
            <option value="PAYMENT_PROCESSOR_MERCHANT">Payment Processor Merchant</option>
        </select>
    </div>
</div>
