<div class="col-sm-12 horizontal-scroll mat-table-container mt-2">
    <div class="mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <table mat-table [dataSource]="userMemberAccess" matSort [matSortActive]="pageTracking.sort" matSortDisableClear [matSortDirection]="pageTracking.sortDir">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="user.lastName"> Name </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.user">
                    <strong *ngIf="element.user.firstName">{{element.user.firstName}}</strong>
                    <strong *ngIf="element.user.lastName"> {{element.user.lastName}}</strong>
                    <div *ngIf="element.user.mobilePhone">
                        {{element.user.mobilePhone | phone }}
                    </div>
                    <div *ngIf="element.user.email">
                        {{element.user.email}}
                    </div>
                    <div class="mt-2" *ngIf="element.user.status !== UserStatus.ACTIVE">
                        <pc-account-badge [parent]="element.user"></pc-account-badge>
                    </div>
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="key_party">
            <th mat-header-cell *matHeaderCellDef> Key Party </th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="element.isKeyParty">
                    <i class="fa fa-link" *ngIf="authService.isAuthorityOrCorporateAdmin()" title="Manage Key Party" (click)="linkKeyParty(element)"></i><strong>{{element.keyParty.individualKeyParty.name}}</strong>
                    <pc-business-address [address]="element.keyParty.individualKeyParty.address" [showLineTwo]="false"></pc-business-address>
                </div>
                <div *ngIf="!element.isKeyParty">
                    <i class="fa fa-link" *ngIf="authService.isAuthorityOrCorporateAdmin()" title="Link Key Party" (click)="linkKeyParty(element)"></i>Not Linked
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="account_access">
            <th mat-header-cell class="centered" *matHeaderCellDef> Account Access </th>
            <td mat-cell *matCellDef="let element">
                <div class="centered" *ngIf="isAuthorityAccess(element)">
                    {{element.accountAccess[0].role | translate}}
                    <div class="centered mb-2">
                        <pc-account-badge [parent]="element.accountAccess[0]"></pc-account-badge>
                    </div>
                    <button *ngIf="authService.isAuthorityOrCorporateAdmin() && element.accountAccess[0].status === UserAccountStatus.INVITED" class="blue-button" type="button" (click)="revokeInvitation(element)">Revoke Invitation</button>
                </div>
                <div *ngIf="!isAuthorityAccess(element)">
                    <div class="centered" *ngIf="element.accountAccess.length === 1 && element.accountAccess[0].status === UserAccountStatus.INVITED">
                        {{element.accountAccess[0].role | translate}}
                        <div class="centered mb-2">
                            <pc-account-badge [parent]="element.accountAccess[0]"></pc-account-badge>
                        </div>
                        <button *ngIf="authService.isAuthorityOrCorporateAdmin() && element.accountAccess[0].status === UserAccountStatus.INVITED" class="blue-button" type="button" (click)="revokeInvitation(element)">Revoke Invitation</button>
                    </div>
                    <div *ngIf="element.accountAccess.length > 0 && element.accountAccess[0].status !== UserAccountStatus.INVITED && element.accountAccess[0].status !== UserAccountStatus.PENDING_REGISTRATION">
                        <div class="mb-3" *ngFor="let accountAccess of element.accountAccess">
                            <dl class="row" *ngIf="accountAccess.role !== Role.CORPORATE_ADMIN">
                                <dt class="col-sm-5 text-end">Account:</dt>
                                <dd class="col-sm-7">
                                    <a [routerLink]="['/member-accounts/details/' + accountAccess.memberAccount.id]"
                                       [queryParams]="(authService.isAuthorityOrCorporateAdmin() || authService.isAccountAdmin(accountAccess.memberAccount))? {_transactionTab: 'ACCOUNT_SETTINGS'} : {}"
                                       [routerLinkActive]="['router-link-active']">
                                        {{accountAccess.memberAccount.accountName}}
                                    </a>
                                    <span *ngIf="accountAccess.memberAccount.status !== MemberAccountStatus.ACTIVE"> ({{accountAccess.memberAccount.status | translate}})</span>
                                </dd>
                                <dt class="col-sm-5 text-end">Role:</dt>
                                <dd class="col-sm-7">{{accountAccess.role | translate}}</dd>
                            </dl>
                            <div class="centered" *ngIf="accountAccess.role === Role.CORPORATE_ADMIN">
                                {{Role.CORPORATE_ADMIN | translate}}
                            </div>
                            <div class="centered">
                                <pc-account-badge [parent]="accountAccess"></pc-account-badge>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="element.accountAccess.length > 0 && element.accountAccess[0].role !== Role.CORPORATE_ADMIN && element.accountAccess[0].status === UserAccountStatus.PENDING_REGISTRATION">
                        <div class="mb-3" *ngFor="let accountAccess of element.accountAccess">
                            <dl class="row">
                                <dt class="col-sm-5 text-end">Role:</dt>
                                <dd class="col-sm-7">{{accountAccess.role | translate}}</dd>
                            </dl>
                            <div class="centered">
                                <pc-account-badge [parent]="accountAccess"></pc-account-badge>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell class="centered" *matHeaderCellDef> Manage </th>
            <td mat-cell class="action centered" *matCellDef="let element">
                <i *ngIf="element.user.status !== UserStatus.PENDING_REGISTRATION && hasActiveAccountAccess(element) && element.member.status === MemberStatus.ACTIVE && hasCorporateAdminAccess(element)" class="fa fa-2x fa-hand-o-down" (click)="toggleEnabledCorporateAdminAccess($event, element)" [title]="'Remove Corporate Admin Access'"></i>
                <i *ngIf="element.user.status !== UserStatus.PENDING_REGISTRATION && hasActiveAccountAccess(element) && element.member.status === MemberStatus.ACTIVE && !hasCorporateAdminAccess(element) && element.isKeyParty" class="fa fa-2x fa-hand-o-up" (click)="toggleEnabledCorporateAdminAccess($event, element)" [title]="'Add Corporate Admin Access'"></i>
                <i *ngIf="element.user.status !== UserStatus.PENDING_REGISTRATION && hasActiveAccountAccess(element)" class="fa fa-2x fa-user-times" (click)="toggleEnabled($event, element)" [title]="'Disable User Access'"></i>
                <i *ngIf="element.user.status !== UserStatus.PENDING_REGISTRATION && !hasActiveAccountAccess(element) && !isPendingRegistration(element) && !isAdminWithoutKeyParty(element)" class="fa fa-2x fa-user-o" (click)="toggleEnabled($event, element)" [title]="'Enable User Access'"></i>
                <i *ngIf="canUserBeReset(element)" class="fa fa-2x" [class.fa-key]="element.user?.status === UserStatus.LOCKED" [class.fa-lock]="element.user?.status === UserStatus.ACTIVE" (click)="resetPassword($event, element)" [title]="element.user?.status === UserStatus.ACTIVE ? 'Reset Password' : 'Resend Password Reset'"></i>
                <i *ngIf="canUserBeUpdated(element)" class="fa fa-2x fa-phone" (click)="updatePhoneNumber($event, element)" [title]="'Change Phone Number'"></i>
                <i *ngIf="canUserBeUpdated(element)" class="fa fa-2x fa-at" (click)="updateEmail($event, element)" [title]="'Change Email Address'"></i>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="!hasActiveAccountAccess(row) ? 'disabled' : ''"></tr>
    </table>
    <mat-paginator class="mat-paginator-sticky" [pageIndex]="paginator.pageIndex" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions"
        [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
</div>
