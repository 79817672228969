import { Component, OnInit } from '@angular/core';
import { AuthService, MemberAccount, MemberAccountService, MemberAccountStatus, WalletFunction, PaymentService } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-nacha-compliance-dashboard',
    templateUrl: './nacha-compliance-dashboard.component.html',
    styleUrls: ['./nacha-compliance-dashboard.component.scss']
})
export class NachaComplianceDashboardComponent implements OnInit {

    paymentProcessorAccounts: MemberAccount[] = [];
    MemberAccountStatus = MemberAccountStatus;

    nachaComplianceData: any[] = [];

    constructor(private authService: AuthService,
                private memberAccountService: MemberAccountService,
                private paymentService: PaymentService) {
    }

    ngOnInit() {
        let memberId = this.authService.getProfile().memberId;
        this.memberAccountService.getActiveMemberBusinessAccounts(memberId).subscribe((response: any) => {
            this.paymentProcessorAccounts = response.content.filter((account: MemberAccount) => {
                return this.memberAccountService.hasWalletFunction(account, WalletFunction.ENABLE_PAYMENT_PROCESSING);
            });
            if (this.paymentProcessorAccounts.length > 0) {
                this.paymentProcessorAccounts.forEach((memberAccount) => {
                    this.paymentService.getCalculatedNachaComplianceData(memberAccount.id).subscribe((response: any) => {
                        response.memberAccountId = memberAccount.id;
                        response.memberAccountName = memberAccount.accountName;
                        this.nachaComplianceData.push(response);
                    });
                });
            }
        });
    }

    calculateReturnPercentage(noOfReturns: number, totalTxns: number): number {
        if (totalTxns === 0) {
            return 0; // Avoid division by zero
        }
        return (noOfReturns / totalTxns) * 100;
    }
}
