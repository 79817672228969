import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PaymentProcessorNotification } from '../models/payment-processor-notification';
import { CustomValidators } from '../validators/custom.validators';

@Injectable({
    providedIn: 'root'
})
export class PaymentProcessingNotificationsFormService {

    constructor(private formBuilder: UntypedFormBuilder) { }

    initializeForm(memberId: string, notification: PaymentProcessorNotification): UntypedFormGroup {
        return this.formBuilder.group({
            idCtrl: new UntypedFormControl(notification?.id),
            userNameCtrl: new UntypedFormControl(notification?.userName, [Validators.maxLength(100), CustomValidators.noWhitespace, CustomValidators.noLeadingOrTrailingWhitespace]),
            passwordCtrl: new UntypedFormControl(notification?.password, [Validators.maxLength(100), CustomValidators.noWhitespace, CustomValidators.noLeadingOrTrailingWhitespace]),
            webhookUrlCtrl: new UntypedFormControl(notification?.webhookUrl, [Validators.required, Validators.pattern('^(http|https)://.*$'), Validators.maxLength(255)]),
            accountIdCtrl: new UntypedFormControl(notification?.memberAccountId, [Validators.required]),
            memberIdCtrl: new UntypedFormControl(memberId)
        });
    }

    getPaymentProcessorSetting(form: UntypedFormGroup): any {
        return {
            id: form.get('idCtrl')?.value,
            userName: form.get('userNameCtrl').value,
            password: form.get('passwordCtrl').value,
            webhookUrl: form.get('webhookUrlCtrl').value,
            memberAccountId: form.get('accountIdCtrl').value,
            memberId: form.get('memberIdCtrl').value
        };
    }

    getUpdatedPaymentProcessorSetting(form: UntypedFormGroup): any {
        return {
            userName: form.get('userNameCtrl')?.value ?? '',
            password: form.get('passwordCtrl')?.value ?? '',
            webhookUrl: form.get('webhookUrlCtrl')?.value ?? ''
        };
    }
}
