import { Component, Input, OnInit } from '@angular/core';
import { MemberAccount, MemberAccountService, PaymentProcessorNotification, PaymentProcessingService, PagedResponse, MemberAccountType } from 'projects/services/src/public-api';
import { AddPaymentProcessingSettingModalComponent } from './add-payment-processing-setting-modal/add-payment-processing-setting-modal.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
    selector: 'pt-payment-processing-notifications',
    templateUrl: './payment-processing-notifications.component.html',
    styleUrls: ['./payment-processing-notifications.component.scss']
})
export class PaymentProcessingNotificationsComponent implements OnInit {

    @Input() memberId: string;
    @Input() readOnly = false;

    displayedApiKeysColumns: string[] = ['user_name', 'password', 'webhook_url', 'account_name', 'action'];
    paymentProcessorNotifications: PaymentProcessorNotification[] = [];
    private accountMap = new Map<string, MemberAccount>();
    private activeAccounts: MemberAccount[] = [];

    constructor(private paymentProcessorService: PaymentProcessingService,
        private memberAccountService: MemberAccountService,
        private dialog: MatDialog) {}

    ngOnInit(): void {
        this.loadPaymentProcessingNotifications();
    }

    loadPaymentProcessingNotifications() {
        this.memberAccountService.getMemberAccountsByTypesAndStatus(this.memberId, [MemberAccountType.BUSINESS], [], 0, 100, 'accountName', 'asc').subscribe((response: PagedResponse<MemberAccount>) => {
            this.activeAccounts = response.content;
            this.accountMap = new Map(
                this.activeAccounts.map((acc) => {
                    return [acc.id, acc];
                })
            );

            this.paymentProcessorService.getPaymentProcessorSettings(this.memberId).subscribe((notifications) => {
                this.paymentProcessorNotifications = notifications.map((notification) => {
                    return {
                        ...notification,
                        memberAccountName: this.accountMap.get(notification.memberAccountId)?.accountName
                    };
                });
            });
        });
    }

    onAddNotification(): void {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.data = {
            memberId: this.memberId,
            isEdit: false,
            accounts: this.activeAccounts
        };
        dialogConfig.disableClose = true;
        const dialogref = this.dialog.open(AddPaymentProcessingSettingModalComponent, dialogConfig);
        dialogref?.afterClosed().subscribe((refresh: boolean) => {
            if (refresh) {
                this.loadPaymentProcessingNotifications();
            }
        });
    }

    onEditNotification(paymentProcessorNotification: PaymentProcessorNotification): void {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.data = {
            memberId: this.memberId,
            paymentProcessorSetting: paymentProcessorNotification,
            isEdit: true,
            accounts: this.activeAccounts
        };
        dialogConfig.disableClose = true;
        const dialogref = this.dialog.open(AddPaymentProcessingSettingModalComponent, dialogConfig);
        dialogref?.afterClosed().subscribe((refresh: boolean) => {
            if (refresh) {
                this.loadPaymentProcessingNotifications();
            }
        });
    }

    onDeleteNotification(webhook: PaymentProcessorNotification): void {
        this.paymentProcessorService.deletePaymentProcessorSetting(webhook.id).subscribe(() => {
            this.loadPaymentProcessingNotifications();
        });
    }

}
