<form *ngIf="memberForm && addressForm" [formGroup]="memberForm">
    <div class="row col-12 mb-3">
        <span *ngIf="isCorporate()" class="form-title">Business</span>
        <span *ngIf="isIndividual()" class="form-title">Individual</span>
    </div>
    <div class="row">
        <div class="col-12 mb-3 merchant-type" *ngIf="isMerchant()">
            <pc-text-option-selector *ngIf="isBusinessMerchant()" [label]="'Business Category'" [options]="businessCategoriesOpts" [formCtrl]="'businessCategoryCtrl'"
                [formGroup]="memberForm" [defaultValue]="''" [translated]="true" (selected)="businessCategoryChanged($event)">
            </pc-text-option-selector>

            <span class="subtitle">Merchant Type</span>
            <p>
                This merchant is<select type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="merchantType" [disabled]="readOnly" (change)="onMerchantTypeChanged(merchantType)">
                    <option value="PRIVATE">Private</option>
                    <option value="PUBLIC">Public</option>
                </select>.
            </p>
             <p *ngIf="merchantAccountConfig?.shared">
                 <b>This merchant will be visible to all members, and they will be able to add accounts for transferring funds</b>
             </p>
             <p *ngIf="!merchantAccountConfig?.shared">
                 This merchant is visible only to members who have added it and will not be searchable by anyone else.
             </p>
            <span class="subtitle"> Whitelist Transaction Types </span>
            Transaction Type(s) <select type="text" [ngModelOptions]="{standalone: true}" [disabled]="readOnly || riskTier === RiskTier.TIER_1"  [(ngModel)]="whitelistType" (change)="onWhitelistTypeChange(whitelistType)">
                <option value=""> None </option>
                <option value="OUTGOING"> Payments Only </option>
                <option value="INCOMING"> Deposits Only </option>
                <option value="BOTH"> Deposits and Payments </option>
            </select>
        </div>
        <div class="col-6 left-col mb-3">
            <span *ngIf="isCorporate()" class="subtitle">Business Information</span>
            <span *ngIf="isIndividual()" class="subtitle">Individual Information</span>
            <pc-form-selector *ngIf="isBusiness() || isBusinessMerchant()" [label]="'Jurisdiction of Business'" [formCtrl]="'jurisdictionCtrl'"
                [formGroup]="memberForm" [options]="jurisdictions" [idValue]="'id'" [displayValue]="'name'" [defaultValue]="''" (selected)="updateJurisdiction($event)">
            </pc-form-selector>
            <div class="jurisdiction jurisdiction-required" *ngIf="mandatoryJurisdictionNotSet()"><i class="fa fa-exclamation"></i>Jurisdiction must be set for Tier 1.</div>
            <div class="jurisdiction jurisdiction-required" *ngIf="multipleMembersWithSameNameWithoutJurisdiction && !(mandatoryJurisdictionNotSet())"><i class="fa fa-exclamation"></i>Jurisdiction must be set for multiple members with the identical name.</div>
            <div class="jurisdiction jurisdiction-required" *ngIf="multipleMembersWithSameNameAndSameJurisdiction && !(mandatoryJurisdictionNotSet())"><i class="fa fa-exclamation"></i>The same jurisdiction should not apply to multiple members with identical names.</div>
            <pc-text-input *ngIf="isIndividual()" [required]="true" [label]="'First Name'" [placeholder]="'First Name'" [formCtrl]="'firstNameCtrl'"
                [formGroup]="memberForm" [editFn]="updateMemberName">
            </pc-text-input>
            <pc-text-input *ngIf="isIndividual()" [required]="false" [label]="'Middle Name'" [placeholder]="'Middle Name'" [formCtrl]="'middleNameCtrl'"
                [formGroup]="memberForm" [editFn]="updateMemberName">
            </pc-text-input>
            <pc-text-input *ngIf="isIndividual()" [required]="true" [label]="'Last Name'" [placeholder]="'Last Name'" [formCtrl]="'lastNameCtrl'"
                [formGroup]="memberForm" [editFn]="updateMemberName">
            </pc-text-input>
            <pc-form-text-input *ngIf="isIndividualMerchant()" [required]="false" [label]="'SSN'" [placeholder]="'numbers only (no hyphens)'"
                [formCtrl]="'taxIdCtrl'" [formGroup]="memberForm" [colSize]="6" [restrictToDigits]="true">
            </pc-form-text-input>
            <pc-text-input *ngIf="isCorporate()" [required]="true" [label]="isMerchant() ? 'Merchant Name' : 'Member Name'" [placeholder]="isMerchant() ? 'Merchant Name' : 'Member Name' " [formCtrl]="'nameCtrl'"
                [formGroup]="memberForm" [inlineEdit]="isAuthority && !isFeeAccount()" [editFn]="updateMemberName">
            </pc-text-input>
            <pc-form-text-input *ngIf="isBusinessMerchant() && merchantTaxForm" [label]="'EIN'" [placeholder]="'numbers only (no hyphens)'" [formCtrl]="'einCtrl'"
                [formGroup]="merchantTaxForm" [restrictToDigits]="true">
            </pc-form-text-input>
            <pc-text-input *ngIf="isCorporate()" [label]="'DBA Name'" [placeholder]="'DBA Name'" [formCtrl]="'dbaNameCtrl'"
                [formGroup]="memberForm">
            </pc-text-input>
            <pc-text-input *ngIf="isCorporate()" [label]="'Previous Name (if changed in the last 2 years)'" [placeholder]="'Previous Name'" [formCtrl]="'previousNameCtrl'"
                [formGroup]="memberForm">
            </pc-text-input>
            <pc-text-option-selector *ngIf="isBusiness()" [required]="true" [label]="'Business Type'"
                [options]="businessTypeOpts" [formCtrl]="'businessTypeCtrl'" [formGroup]="memberForm" [defaultValue]="''">
            </pc-text-option-selector>
            <pc-text-input *ngIf="isBusinessMerchant()" [required]="true" [label]="'Nature Of Business'"
                [formCtrl]="'briefDescriptionCtrl'" [formGroup]="memberForm">
            </pc-text-input>
            <pc-phone-input [required]="!isIndividual()" [label]="'Phone Number'" [formCtrl]="'officePhoneCtrl'" [formGroup]="memberForm">
            </pc-phone-input>
            <pc-email-input [label]="'General Contact Email Address'" [formCtrl]="'emailCtrl'" [formGroup]="memberForm">
            </pc-email-input>
            <div *ngIf="isBusiness()">
                <span class="subtitle mt-3">Financial Systems</span>
                <pc-text-option-selector [required]="true" [label]="'What accounting system do you use?'"
                    [options]="accountingOpts" [formCtrl]="'accountingSystemCtrl'" [formGroup]="memberForm">
                </pc-text-option-selector>
                <pc-text-input *ngIf="businessCategory === BusinessCategory.ATM_BUSINESS" [label]="'Payment Processor'"
                    [placeholder]="'Payment Processor'" [formCtrl]="'paymentProcessorCtrl'" [formGroup]="memberForm">
                </pc-text-input>
            </div>
        </div>
        <div class="col-6 mb-3">
            <div class="mb-3" *ngIf="isBusiness()">
                <span class="subtitle">Background Information</span>
                <pc-simple-checkbox [label]="'This is a publicly traded company'" [formCtrl]="'isPublicCtrl'" [formGroup]="memberForm" [invert]="true" (changed)="onSelectBoxChange()">
                </pc-simple-checkbox>
                <pc-simple-checkbox [label]="'We have a bank account that will be linked'" [formCtrl]="'linkBankAccountCtrl'" [formGroup]="memberForm" [invert]="true" (changed)="onSelectBoxChange()">
                </pc-simple-checkbox>
            </div>
            <span *ngIf="!isIndividual()" class="subtitle">Business Main Address</span>
            <span *ngIf="isIndividual()" class="subtitle">Individual Main Address</span>
            <form *ngIf="addressForm" [formGroup]="addressForm">
                <pc-address-input [required]="!isIndividual()" [addressTypeOpts]="[AddressType.PRIMARY]" [addressForm]="addressForm" [lockCountry]="!isMerchant()"></pc-address-input>
            </form>
        </div>
        <div class="col-12 row mb-3" *ngIf="isBusiness()">
            <span class="col-12 subtitle">Business Overview</span>
            <pc-text-option-selector [required]="true" [label]="'Business Category'" [options]="businessCategoriesOpts" [formCtrl]="'businessCategoryCtrl'"
                [formGroup]="memberForm" [defaultValue]="''" [translated]="true" (selected)="businessCategoryChanged($event)">
            </pc-text-option-selector>
            <div class="col-6">
                <pc-text-input [required]="false" [label]="'Business Website'" [placeholder]="'Business Website'" [formCtrl]="'websiteCtrl'" [formGroup]="memberForm">
            </pc-text-input>
            </div>
            <div class="col-6">
                <pc-text-input [required]="true" [label]="'NAICS Code'" [placeholder]="'NAICS Code'" [formCtrl]="'naicsCodeCtrl'" [formGroup]="memberForm">
                </pc-text-input>
                <div class="naics-link">
                    <i>You can lookup your NAICS code <a href="https://www.naics.com/code-search/?naicstrms=marijuana" target="_blank" rel="noopener noreferrer">here</a> </i>
                </div>
            </div>
        </div>
        <div class="col-12 mb-3" *ngIf="!isBusinessMerchant()">
            <pc-form-text-area [formCtrl]="'briefDescriptionCtrl'" [formGroup]="memberForm"
                [label]="!isIndividual() ? 'Brief Description of the Business' : 'Relationship To Company'">
            </pc-form-text-area>
        </div>
    </div>

    <div class="button-panel pull-right">
        <pc-submit-button [enabled]="isBusinessDetailsValid" [submit]="onSave" [text]="'Save'"></pc-submit-button>
        <pc-submit-button  [submit]="onNavigate" [isSubmitButton]="true" [text]="'Next'" [rightIcon]="'arrow-right'" *ngIf="task"></pc-submit-button>
    </div>
</form>
