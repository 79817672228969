import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import {
    AuthService, ConnectedMember, ConnectedMemberService, ConnectedMemberStatus, MemberAccount, MemberAccountService, MemberStatus, MemberType, PagedResponse
} from 'projects/services/src/public-api';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddConnectedMemberModalComponent } from 'projects/pt/src/app/components/modal';

@Component({
    selector: 'pt-partner-list',
    templateUrl: './partner-list.component.html',
    styleUrls: ['./partner-list.component.scss']
})
export class PartnerListComponent implements OnInit, AfterViewInit, OnDestroy {

    MemberStatus = MemberStatus;

    @Input() memberId: string;
    @Input() memberType: MemberType;
    @Input() changeTrigger: number;

    isLoadingResults = true;

    displayedColumns: string[] = ['name', 'account', 'created', 'action'];
    connectedMembers: ConnectedMember[] = [];
    partnerType = 'linked';
    currentPartnerType = 'linked';

    pageTracking: PageTracking;
    resultsLength = 0;
    subscription: any;
    filterEvent: EventEmitter<null> = new EventEmitter<null>();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private authService: AuthService,
                private route: ActivatedRoute,
                private router: Router,
                private notifier: NotificationService,
                private connectedMemberService: ConnectedMemberService,
                private memberAccountService: MemberAccountService,
                private dialog: MatDialog,
                private cdr: ChangeDetectorRef) {}

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnInit() {
        if (!this.memberId) {
            this.memberId = this.authService.getProfile().memberId;
        }
        const queryParams = this.route.snapshot.queryParams;
        if (queryParams['partnerType']) {
            this.partnerType = queryParams['partnerType'];
        } else {
            this.updateQueryParams(true);
        }
        this.updateDisplayColumns();

        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'sortName', 'asc');
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.route.queryParams.subscribe((params: any) => {
            if (params['partnerType']) {
                if (this.currentPartnerType !== params['partnerType']) {
                    this.filterEvent.emit();
                }
                this.currentPartnerType = params['partnerType'] || 'linked';
                this.updateDisplayColumns();
            }
        });
        this.addTableLoadListener();
    }

    onDeleteConnectedMember(connectedMember: ConnectedMember) {
        this.connectedMemberService.disconnectMember(connectedMember.id).subscribe(() => {
            this.filterEvent.emit();
            this.notifier.showSuccess('<b>' + connectedMember.partner.name + '</b> has been <b>removed</b> as a partner.<br> You will now see them under the Available Partner table as well.');
        });
    }

    // we should always pass status is active when adding the connected member
    onAddConnectedMember(connectedMember: ConnectedMember) {
        this.memberAccountService.getActiveMemberBusinessAccounts(this.memberId, true).subscribe((result: PagedResponse<MemberAccount>) => {
            const memberAccounts = result.content.filter((memberAccount: MemberAccount) => {
                return this.authService.isAccountAdmin(memberAccount) || this.authService.isCorporateAdmin() || this.authService.isAuthority();
            });
            if (memberAccounts.length === 0) {
                throw new Error('Member has no active accounts.');
            } else {
                const dialogConfig: MatDialogConfig = {};
                dialogConfig.panelClass = 'normal-modal';
                dialogConfig.data = {
                    partnerId: connectedMember.partner.id,
                    partnerName: connectedMember.partner.name,
                    memberId: this.memberId,
                    memberAccounts
                };
                const dialogRef = this.dialog.open(AddConnectedMemberModalComponent, dialogConfig);

                dialogRef?.afterClosed().subscribe((refresh: boolean) => {
                    if (refresh) {
                        this.filterEvent.emit();
                    }
                });
            }
        });
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.paginator.page, this.filterEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                this.connectedMembers = [];
                if (this.partnerType === 'available') {
                    return this.connectedMemberService.getAvailablePartners(this.memberId, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
                }
                return this.connectedMemberService.getPartnersByMemberId(this.memberId, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((data: PagedResponse<any>) => {
                this.isLoadingResults = false;
                this.resultsLength = data.totalElements || 0;
                return data.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((data) => {
            if (this.partnerType === 'available') {
                this.connectedMembers = data.map((availablePartner) => {
                    const connectedMember = new ConnectedMember();
                    connectedMember.partner = availablePartner;
                    connectedMember.status = ConnectedMemberStatus.ACTIVE;
                    return connectedMember;
                });
            } else {
                this.connectedMembers = data.map((connectedMember: ConnectedMember) => {
                    return connectedMember;
                });
            }
            UIUtils.scrollDashboardToTop();
        });
    }

    onValueChange(value : string) {
        this.connectedMembers = [];
        this.partnerType = value;
        this.updateDisplayColumns();
        this.updateQueryParams(false);
    }

    isAuthorityOrAdmin(memberAccount: MemberAccount) {
        return this.authService.isAuthorityOrAdmin(memberAccount);
    }

    updateQueryParams(replace: boolean) {
        const queryParams = { partnerType: this.partnerType, page: 1 };
        TableUtils.updateQueryParams(this.route, this.router, queryParams, replace);
    }

    updateDisplayColumns() {
        if (this.partnerType === 'linked') {
            this.displayedColumns = ['name', 'account', 'created', 'action'];
        } else {
            this.displayedColumns = ['name', 'action'];
        }
        if (this.memberType === MemberType.CONSUMER) {
            this.displayedColumns.splice(this.displayedColumns.indexOf('action'), 1);
        }
    }
}
