export class ApiAccess {

    id: string;
    created: Date;
    memberId: string;
    key: string;
    secret: string;
    status: ApiAccessStatus;

    // adhoc fields
    copyable: boolean;
}

export enum ApiAccessStatus {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED'
}
