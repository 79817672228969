<div id="merchant-details" class="col-12 dashboard-info" *ngIf="merchantConfig">
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div *ngIf="isAuthorityOrReviewer()">
                    <div class="dashboard-header">
                        {{ merchantConfig.merchantMemberAccount.member.name }} <span *ngIf="merchantConfig.merchantMemberAccount.member.jurisdiction"> ({{merchantConfig.merchantMemberAccount.member.jurisdiction}})</span>
                    </div>
                    <div class="pull-right" *ngIf="task && isAuthority()">
                        Assigned to: <pc-authority-workflow-assign [processInstanceId]="processInstanceId" [taskId]="taskId" [assignee]="assignee" [authorityUsers]="authorityUsers"
                            (noteAdded)="onNoteAdded()">
                        </pc-authority-workflow-assign>
                    </div>
                </div>
                <div *ngIf="!isAuthorityOrReviewer()" class="dashboard-header"><img class="dashboard-icon" src="assets/images/member-icon-2.svg" alt="">Merchant Details</div>
            </div>
        </div>
        <div class="col-3">
            <div class="row">
                <div *ngIf="isAuthorityOrReviewer() && merchantConfig"><pc-merchant-config-badge [parent]="merchantConfig"></pc-merchant-config-badge></div>
            </div>
            <div *ngIf="merchantConfig.merchantMemberAccount.member.status !== MemberStatus.UNDER_REVIEW && isAuthoritySuperUser()" [class]="getTabClass('administration')" (click)="onSwitchTabs('administration')">
                Administration
            </div>
            <div *ngIf="task && isAuthorityOrReviewer()" [class]="getTabClass('contact_info')" (click)="onSwitchTabs('contact_info')">
                Contact Info
            </div>
            <div *ngIf="isAuthorityOrReviewer()" [class]="getTabClass('accounts')" (click)="onSwitchTabs('accounts')">
                Accounts
            </div>
            <pc-onboard-tab class="col-12 tab-col onboard-nav" *ngIf="isAuthorityOrReviewer()" [title]="'Business'" [tabId]="'business'" [tabClass]="getTabClass" [tabValid]="isBusinessDetailsValid" [tabDirty]="isBusinessDetailsDirty" (tabChange)="onSwitchTabs($event)"></pc-onboard-tab>
            <pc-onboard-tab class="col-12 tab-col onboard-nav" *ngIf="isAuthorityOrReviewer()" [title]="'Contacts'" [tabId]="'key_parties'" [tabClass]="getTabClass" [tabValid]="isKeyPartyDetailsValid" (tabChange)="onSwitchTabs($event)"></pc-onboard-tab>
            <pc-onboard-tab class="col-12 tab-col onboard-nav" *ngIf="isAuthorityOrReviewer()" [title]="'Locations'" [tabId]="'locations'" [tabClass]="getTabClass" [tabValid]="isLocationDetailsValid" (tabChange)="onSwitchTabs($event)"></pc-onboard-tab>
            <pc-onboard-tab class="col-12 tab-col onboard-nav" *ngIf="isAuthorityOrReviewer()" [title]="'Licenses'" [tabId]="'licenses'" [tabClass]="getTabClass" [tabValid]="isLicenseDetailsValid" (tabChange)="onSwitchTabs($event)"></pc-onboard-tab>
            <pc-onboard-tab class="col-12 tab-col onboard-nav" *ngIf="isAuthorityOrReviewer()" [title]="'Internal KYC'" [tabId]="'internal'" [tabClass]="getTabClass" [tabValid]="isInternalDetailsValid" [tabDirty]="isInternalDetailsDirty" (tabChange)="onSwitchTabs($event)"></pc-onboard-tab>
            <pc-onboard-tab class="col-12 tab-col onboard-nav" [title]="'Documents'" [tabId]="'documents'" [tabClass]="getTabClass" [tabValid]="isDocumentsValid" (tabChange)="onSwitchTabs($event)"></pc-onboard-tab>
            <pc-onboard-tab class="col-12 tab-col onboard-nav" *ngIf="isAuthorityOrReviewer()" [title]="'Banking Details'" [tabId]="'banking_details'" [tabClass]="getTabClass" [tabValid]="isBankingDetailsValid" (tabChange)="onSwitchTabs($event)"></pc-onboard-tab>
            <pc-onboard-tab class="col-12 tab-col onboard-nav" *ngIf="isAuthorityOrReviewer()" [title]="'Adjudicate'" [tabId]="'adjudicate'" [tabClass]="getTabClass" [tabValid]="isAdjudicateValid" (tabChange)="onSwitchTabs($event)"></pc-onboard-tab>

            <div *ngIf="task && isAuthority()" [class]="getTabClass('workflow_notes')" (click)="onSwitchTabs('workflow_notes')">
                Assignment Notes
            </div>

            <div *ngIf="!task && isAuthorityOrReviewer()" [class]="getTabClass('compliance')" (click)="onSwitchTabs('compliance')">
                RFIs
            </div>
            <div *ngIf="!task && isAuthorityOrReviewer()" [class]="getTabClass('transactions')" (click)="onSwitchTabs('transactions')">
                Transactions
            </div>
            <div *ngIf="!task && isAuthorityOrReviewer()" [class]="getTabClass('member_accounts')" (click)="onSwitchTabs('member_accounts')">
                Member Accounts
            </div>
            <div *ngIf="isAuthority()" [class]="getTabClass('auditing')" (click)="onSwitchTabs('auditing')">
                Auditing
            </div>
        </div>

        <div class="col-9 tab-body" *ngIf="activeTab === 'administration' && isAuthoritySuperUser()">
            <pt-merchant-administration *ngIf="merchantConfig" [merchantAccountConfig]="merchantConfig">
            </pt-merchant-administration>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'contact_info' && task && isAuthorityOrReviewer()">
            <pt-merchant-contact-info *ngIf="merchantMemberId" [taskVars]="taskVars" (next)="next()" [task]="task">
            </pt-merchant-contact-info>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'accounts' && isAuthorityOrReviewer()">
            <pt-member-accounts-list [memberId]="merchantMemberId" [authorityView]="true"></pt-member-accounts-list>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'business' && isAuthorityOrReviewer()">
            <pt-member-business-details *ngIf="merchantMemberId" [readOnly]="!authService.isAuthorityOrAdmin()" [memberId]="merchantMemberId" [changeTrigger]="changeTrigger"
                (next)="next()" (memberUpdated)="updateMerchantMember($event)" [task]="task">
            </pt-member-business-details>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'locations' && isAuthorityOrReviewer()">
            <pt-member-locations *ngIf="merchantMemberId" [memberAccountId]="merchantMemberId" [task]="task" [changeTrigger]="changeTrigger"
                (next)="next()">
            </pt-member-locations>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'licenses'">
            <pt-member-licenses *ngIf="merchantMemberId" [memberAccountId]="merchantMemberId" [changeTrigger]="changeTrigger"
                (next)="next()" [task]="task">
            </pt-member-licenses>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'key_parties' && isAuthorityOrReviewer()">
            <pt-member-key-party-details *ngIf="merchantMemberId" [memberId]="merchantMemberId" [task]="task" [isMerchant]="true"
                 [changeTrigger]="changeTrigger" (next)="next()" [memberType]="MemberAccountType.BUSINESS_MERCHANT" [memberStatus]="merchantConfig.merchantMemberAccount.member.status">
            </pt-member-key-party-details>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'internal' && isAuthorityOrReviewer()">
            <pt-member-internal-details *ngIf="merchantMemberId" [memberId]="merchantMemberId" [memberReadOnly]="!authService.isAuthorityOrAdmin()" [memberType]="MemberAccountType.BUSINESS_MERCHANT"
                [task]="task" [changeTrigger]="changeTrigger" (next)="next()" (tierChanged)="tierChanged($event)">
            </pt-member-internal-details>
        </div>
        <div *ngIf="activeTab === 'documents'" class="col-9 tab-body">
            <pt-member-other-documents *ngIf="merchantMemberId" [memberId]="merchantMemberId" [allOptional]="true" [changeTrigger]="changeTrigger"
                (next)="next()" [task]="task">
            </pt-member-other-documents>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'banking_details' && isAuthorityOrReviewer()">
            <pt-member-banking-details *ngIf="merchantMemberId" [allowAddBankAccount]="true" [memberId]="merchantMemberId" [task]="task" [changeTrigger]="changeTrigger" [isMerchantBankDetails]="true" (next)="next()">
            </pt-member-banking-details>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'compliance' && isAuthorityOrReviewer()">
            <pt-member-rfi [changeTrigger]="changeTrigger" [memberId]="merchantMemberId">
            </pt-member-rfi>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'transactions' && isAuthorityOrReviewer()">
            <pt-member-transactions [memberId]="merchantMemberId">
            </pt-member-transactions>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'member_accounts' && isAuthorityOrReviewer()">
            <pt-authority-member-merchant-list [changeTrigger]="changeTrigger" [readOnly]="!isAuthority() || merchantConfig.status !== MerchantAccountStatus.ACTIVE" [merchantMemberId]="merchantMemberId"
                [tier]="merchantConfig.merchantMemberAccount.member.tier" [jurisdiction]="merchantConfig.merchantMemberAccount.member.jurisdiction">
            </pt-authority-member-merchant-list>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'adjudicate' && isAuthorityOrReviewer()">
            <pt-member-application-decision
                [changeTrigger]="changeTrigger"
                [memberType]="MemberAccountType.BUSINESS_MERCHANT"
                [memberId]="merchantMemberId"
                [taskId]="taskId">
            </pt-member-application-decision>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'auditing' && isAuthority()">
            <pt-member-audit [memberId]="merchantMemberId">
            </pt-member-audit>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'workflow_notes' && isAuthority()">
            <pc-authority-workflow-notes [processInstanceId]="processInstanceId" [changeTrigger]="notesTrigger">
            </pc-authority-workflow-notes>
        </div>
    </div>
</div>
