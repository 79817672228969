<div>
    <div *ngIf="member">
        <div *ngIf="isBusinessMember && (!isPending || directLink)">
            <a class="member-details-font" [ngClass]="linkType"
                [routerLink]="['/administration/member/' + memberId]"
                [queryParams]="{_activeTab: activeTab}" [routerLinkActive]="['router-link-active']" [target]="newTab ? '_blank' : '_self'">
                {{ linkDescription }}
            </a>
        </div>
        <div *ngIf="isBusinessMerchant && (!isPending || directLink)">
            <a class="member-details-font" [ngClass]="linkType"
               [routerLink]="['/administration/merchant/' + memberId]"
               [queryParams]="{_activeTab: activeTab}" [routerLinkActive]="['router-link-active']" [target]="newTab ? '_blank' : '_self'">
                {{ linkDescription }}
            </a><span *ngIf="pooled"> (Pooled)</span>
        </div>
        <div *ngIf="isIndividualMerchant && (!isPending || directLink)">
            <a class="member-details-font" [ngClass]="linkType"
               [routerLink]="['/administration/individual/' + memberId]"
               [queryParams]="{_activeTab: activeTab}" [routerLinkActive]="['router-link-active']" [target]="newTab ? '_blank' : '_self'">
                {{ linkDescription }}
            </a>
        </div>
        <div *ngIf="isPending && !directLink && !newTab">
            <a class="member-details-font" [ngClass]="linkType" (click)="pendingMemberDetails()">
                {{ linkDescription }}
            </a><span *ngIf="pooled && isBusinessMerchant"> (Pooled)</span>
        </div>
        <div *ngIf="isPending && !directLink && newTab">
            <span class="member-details-font" [ngClass]="linkType">{{ linkDescription }}</span>
        </div>
    </div>
    <div *ngIf="!member" class="member-details-font" [ngClass]="linkType">
        {{ linkDescription }}
    </div>
</div>
