import { MemberSource, MemberType } from './member';
import { TransactionEntrySelection } from './transaction-entry-selection';
import { Invoice } from './invoice';
import { Bill } from './bill';
import { TransactionType } from './transaction';
import { AccountingParty } from './accounting/accounting-party';
import { Address } from './address';

export class BusinessClient<T extends Invoice | Bill> {

    constructor(memberAccountId?: string, memberId?: string, name?: string, dbaName?: string, status?: BusinessClientStatus, source?: MemberSource, type?: MemberType, defaultTransactionType?: TransactionType, accountingId?: string, accountingData?: AccountingParty, address?: Address) {
        this.memberAccountId = memberAccountId;
        this.memberId = memberId;
        this.name = name;
        this.dbaName = dbaName;
        this.status = status;
        this.source = source;
        this.type = type;
        this.accountingId = accountingId;
        this.accountingData = accountingData;
        this.defaultTransactionType = defaultTransactionType;
        this.transactionEntries = [];
        this.address = address;
    }

    memberAccountId: string;
    memberId: string;
    name: string;
    dbaName: string;
    status: BusinessClientStatus;
    source: MemberSource;
    platform: string;
    type: MemberType;
    defaultTransactionType: TransactionType;
    accountingId: string;
    accountingData: AccountingParty;
    merchantData: AccountingParty;
    transactionEntries: TransactionEntrySelection<T>[];
    selectedTotal = '0.00';
    address: Address;
}

export enum BusinessClientStatus {
    DILIGENCE_REQUIRED = 'DILIGENCE_REQUIRED',
    VERIFICATION_REQUIRED = 'VERIFICATION_REQUIRED',
    PENDING_VERIFICATION_REQUIRED = 'PENDING_VERIFICATION_REQUIRED',
    LINKED = 'LINKED',
    LINKED_PENDING = 'LINKED_PENDING',
    AVAILABLE = 'AVAILABLE',
    ACTIVE = 'ACTIVE',
    PENDING = 'PENDING',
    DISABLED = 'DISABLED',
    REJECTED = 'REJECTED',
    NEW = 'NEW'
}
