import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PagedResponse, Task, WorkflowService, AuthService } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-work-gadget',
    templateUrl: './work-gadget.component.html',
    styleUrls: ['./work-gadget.component.scss']
})
export class WorkGadgetComponent implements OnChanges {

    @Input() changeTrigger: number;

    tasks: Task[];
    taskCount: number;
    myTaskCount: number = 0;
    assignee: string;

    constructor(private workflowService: WorkflowService,
                private authService: AuthService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.assignee = this.authService.getProfile().userId;
        if (changes.changeTrigger) {
            this.refreshList();
        }
    }

    refreshList() {
        // retrieve all the tasks and add
        this.workflowService.getTasksSorted([], '', 0, 3, 'created', 'desc').subscribe((tasks: PagedResponse<Task>) => {
            this.tasks = tasks.content;
            this.workflowService.getTaskCount().subscribe((response: any) => {
                const taskCount = response.headers.get('Content-Length');
                if (taskCount >= 3) {
                    this.taskCount = taskCount;
                }
            });
            if (this.authService.isAuthority()) {
                this.workflowService.getTaskCount(this.assignee).subscribe((response: any) => {
                    this.myTaskCount = response.headers.get('Content-Length');
                });
            }
        });
    }

    loadTask(taskId: string) {
        this.workflowService.loadTask(taskId);
    }

    completeTask(taskId: string) {
        this.workflowService.completeTask(taskId, {}, this.refreshList).subscribe(() => {
            this.refreshList();
        });
    }
}
