<div class="col-12">

    <div class="row mb-3">
        <div class="col-5">
            <span class="form-title">
                  {{ pageTitle(isMerchant()) }}
            </span>
        </div>

        <div *ngIf="isAddButtonsVisible()" class="col-7">
            <button (click)="addOrUpdateIndividualKeyParty()" class="blue-button pull-right" type="button">
                <i class="fa fa-plus pull-left dashboard-icon"></i>
                <div class="pull-right-button">Individual</div>
            </button>

            <button (click)="addOrUpdateCorporateKeyParty()" class="blue-button pull-right" type="button">
                <i class="fa fa-plus pull-left dashboard-icon"></i>
                <div class="pull-right-button">Corporation</div>
            </button>
        </div>
    </div>

    <ng-container *ngIf="isDescriptionVisible()">
        <i>
            Please include all:
            <ul>
                <li>
                    Directors, Officers and Board Members
                </li>
                <li>
                    Control Persons (defined as any person within the Member who holds significant responsibility
                    to control, manage, or direct the legal entity or who will have signing authority on this account)
                </li>
                <li>
                    Shareholders with <strong>{{ getUBOPercentage() }}% interest or more</strong> (at all levels,
                    including Shareholders of corporate owners)
                </li>
            </ul>
        </i>
    </ng-container>

    <pc-datatable matSort matSortDisableClear
        [dataSource]="keyParties"
        [columns]="displayedColumns"
        [isLoading]="isLoadingResults"
        [totalElements]="resultsLength"
        (paginationChanged)="refreshList()"
        (sortChanged)="refreshList()">

        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
                <div class="key-party-type">
                    <img *ngIf="row.corporateKeyParty" src="/assets/images/site-icon.svg" title="Corporate">
                    <img *ngIf="row.individualKeyParty" src="/assets/images/profile-icon.svg" title="Individual">
                    <i class="fa fa-warning" *ngIf="row.similarKeyParties?.length" title="Similar Key Parties Found" (click)="openKeyPartyMerge(row)"></i>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name</th>
            <td mat-cell *matCellDef="let row">
                <div *ngIf="row.individualKeyParty">
                    <b>{{row.individualKeyParty.name}}</b>
                </div>
                <div *ngIf="row.corporateKeyParty">
                    <b>{{row.corporateKeyParty.name}}</b>
                    <div *ngIf="row.corporateKeyParty.dbaName">
                        <b>DBA: {{row.corporateKeyParty.dbaName}}</b>
                    </div>
                </div>

                <div *ngIf="row.individualKeyParty">
                    <div *ngIf="row.professionalTitle">
                        {{row.professionalTitle}}
                    </div>
                    <div *ngIf="row.individualKeyParty.phone">
                        {{row.individualKeyParty.phone | phone}}
                    </div>
                    <div *ngIf="row.individualKeyParty.email">
                        {{row.individualKeyParty.email}}
                    </div>
                </div>

                <div *ngIf="row.corporateKeyParty">
                    <div *ngIf="row.corporateKeyParty.officePhone">
                        {{row.corporateKeyParty.officePhone | phone}}
                    </div>
                    <div *ngIf="row.corporateKeyParty.email">
                        {{row.corporateKeyParty.email}}
                    </div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef> Address</th>
            <td mat-cell *matCellDef="let row">
                <div class="px-1">
                    <pc-business-address *ngIf="row.individualKeyParty?.address" [address]="row.individualKeyParty?.address"></pc-business-address>
                    <pc-business-address *ngIf="row.corporateKeyParty?.address" [address]="row.corporateKeyParty?.address"></pc-business-address>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="details">
            <th mat-header-cell *matHeaderCellDef> Details </th>
            <td mat-cell *matCellDef="let row">
                <div *ngIf="isDetailsVisible(row)">
                    <div *ngIf="row.individualKeyParty.birthDate">
                        DOB: {{row.individualKeyParty.birthDate | date }}
                    </div>
                    <div *ngIf="row.individualKeyParty.usCitizen">
                        US Citizen
                    </div>
                    <div *ngIf="isDetailSsnVisible(row.individualKeyParty)">
                        SSN: {{row.individualKeyParty.taxId}}
                    </div>
                    <div *ngIf="row.individualKeyParty.passportCountryOfIssue">
                        Passport Country of Issue: {{row.individualKeyParty.passportCountryOfIssue}}
                    </div>
                    <div *ngIf="isDetailPassportVisible(row.individualKeyParty)">
                        Passport: {{row.individualKeyParty.passportNumber}}
                    </div>
                    <div *ngIf="isDetailPassportExpirationVisible(row.individualKeyParty)">
                        Passport Expiration Date: {{row.individualKeyParty.passportExpirationDate | date}}
                    </div>
                </div>

                <div *ngIf="row.corporateKeyParty">
                    <div *ngIf="row.corporateKeyParty.taxId">
                        EIN/Tax ID: {{row.corporateKeyParty.taxId}}
                    </div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="relationship">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="percentOwnership"> Relationship</th>
            <td mat-cell *matCellDef="let row">
                <div *ngIf="isOwnershipVisible(row)">
                    Ownership: {{row.percentOwnership / 100 | percent}}
                </div>
                <div *ngIf="row.director">
                    Director
                </div>
                <div *ngIf="row.officer">
                    Officer
                </div>
                <div *ngIf="row.contactPerson">
                    Contact Info
                </div>
                <div *ngIf="row.licenseHolder" class="license-holder">
                    License Holder
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="date_added">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="created"> Date Added</th>
            <td mat-cell *matCellDef="let row"> {{ row.created | date:'mediumDate'}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell class="centered" *matHeaderCellDef> Action</th>
            <td mat-cell class="action centered" *matCellDef="let row">
                <i *ngIf="isEditKeyPartyVisible()" class="fa fa-pencil" (click)="editKeyParty(row)"></i>
                <i *ngIf="isDeleteKeyPartyVisible(row)" class="fa fa-times" (click)="onDeleteKeyParty(row)"></i>
            </td>
        </ng-container>
    </pc-datatable>

</div>
