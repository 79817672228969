<div>
    <div class="col-12">
        <div class="col-sm-12 horizontal-scroll mat-table-container" [ngClass]="memberPageView ? 'member-page-view' : ''">
            <div class="mat-table-spinner" *ngIf="isLoadingResults">
                <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
            </div>
            <table mat-table [dataSource]="rfiObservable | async" matSort [matSortActive]="pageTracking.sort" matSortDisableClear [matSortDirection]="pageTracking.sortDir">
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef> Type </th>
                    <td mat-cell [ngClass]="isPriority(element) ? 'priority fw-bold' : ''" *matCellDef="let element">
                        {{element?.transaction?.transactionType | translate}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell class="centered" *matHeaderCellDef mat-sort-header> Status </th>
                    <td mat-cell class="centered" *matCellDef="let element">
                        <pc-rfi-badge [parent]="element"></pc-rfi-badge>
                    </td>
                </ng-container>
                <ng-container matColumnDef="created">
                    <th mat-header-cell class="centered" *matHeaderCellDef mat-sort-header="created"> Created Date </th>
                    <td mat-cell class="centered" [ngClass]="isPriority(element) ? 'priority fw-bold' : ''" *matCellDef="let element">
                        <pc-date-time [date]="element.created"></pc-date-time>
                    </td>
                </ng-container>
                <ng-container matColumnDef="updated">
                    <th mat-header-cell class="centered" *matHeaderCellDef mat-sort-header="updated"> Updated Date </th>
                    <td mat-cell  class="centered" *matCellDef="let element">
                        <pc-date-time [date]="element.updated"></pc-date-time>
                    </td>
                </ng-container>
                <ng-container matColumnDef="check">
                    <th mat-header-cell class="centered" *matHeaderCellDef> Check # </th>
                    <td mat-cell  class="centered" *matCellDef="let element">
                        {{element.transaction?.check?.checkNumber ? element.transaction?.check?.checkNumber : ''}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="transaction_date">
                    <th mat-header-cell class="centered" *matHeaderCellDef> Payment Date </th>
                    <td mat-cell  class="centered" *matCellDef="let element">
                        <ng-container *ngIf="element.transaction">
                            {{element.transaction.transactionDate | date:'mediumDate'}}
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="amount">
                    <th mat-header-cell class="centered" *matHeaderCellDef> Amount </th>
                    <td mat-cell class="centered" *matCellDef="let element">
                        <span *ngIf="element.transaction?.totalAmount" class="table-amount">
                            {{ element.transaction.totalAmount | currency }}
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="payor">
                    <th mat-header-cell *matHeaderCellDef> Payor </th>
                    <td mat-cell *matCellDef="let element">
                        <a *ngIf="element.transaction?.payorMemberId && element.transaction?.payorMemberId" (click)="navigateToMember(element.transaction.payorMemberId)">
                            {{element.transaction.payorMemberName}}
                        </a>
                        <span *ngIf="!element.transaction?.payorMemberId">{{element.transaction?.payorMemberName}}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="recipient">
                    <th mat-header-cell *matHeaderCellDef> Recipient </th>
                    <td mat-cell *matCellDef="let element">
                        <a *ngIf="element?.transaction?.recipientMemberId && element.transaction.recipientMemberName"
                            (click)="navigateToMember(element.transaction.recipientMemberId)">
                            {{element.transaction.recipientMemberName}}
                        </a>
                        <span *ngIf="!element.transaction?.recipientMemberId">{{element.transaction?.recipientMemberName}}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="assignee">
                    <th class="assignee" mat-header-cell *matHeaderCellDef> Assignee </th>
                    <td class="assignee" mat-cell *matCellDef="let element" (click)="nullClick($event)">
                        <pc-authority-workflow-assign *ngIf="authorityUsers.length && element?.task" [processInstanceId]="element.task.processInstanceId" [taskId]="element.task.id" [assignee]="element.task.variables.assignedAuthority" [authorityUsers]="authorityUsers" [tableView]="true"
                        (noteAdded)="refreshList()">
                        </pc-authority-workflow-assign>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="pc-click-row" (click)="loadRfiDetails(row.id, $event)"></tr>
            </table>
            <mat-paginator class="mat-paginator-sticky" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
        </div>
    </div>
</div>
