import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AuthService, CommentType, User, WorkflowService } from 'projects/services/src/public-api';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NewNoteModalComponent } from 'projects/components/src/lib/modal';

@Component({
    selector: 'pc-authority-workflow-assign',
    templateUrl: './authority-workflow-assign.component.html',
    styleUrls: ['./authority-workflow-assign.component.scss']
})
export class AuthorityWorkflowAssignComponent implements OnInit, OnChanges {

    @Input() assignee: string;
    @Input() processInstanceId: string;
    @Input() taskId: string;
    @Input() tableView = false;
    @Input() authorityUsers: {userId: string, user: User}[] = [];

    @Output() noteAdded: EventEmitter<boolean> = new EventEmitter<boolean>();

    assigning = false;
    isPriority = true;
    assigneeName: string;

    constructor(private authService: AuthService,
                private workflowService: WorkflowService,
                private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.assigneeName = this.getAssignee();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ((changes.assignee && !changes?.assignee.firstChange) ||
            (changes.authorityUsers && !changes?.authorityUsers.firstChange)) {
            this.assigneeName = this.getAssignee();
        }
    }

    getClass() {
        if (this.tableView) {
            return 'assignee mt-2';
        }
        return 'assignee fixed-height';
    }

    editAssignee() {
        this.assigning = !this.assigning;
    }

    getAssignee() {
        if (!this.assignee) {
            this.isPriority = true;
            return 'Unassigned';
        }
        const authority = this.authorityUsers.find((authorityUser) => {
            return authorityUser.userId === this.assignee;
        });
        if (authority) {
            this.isPriority = false;
            return authority.user.lastName + ', ' + authority.user.firstName.charAt(0);
        }
        this.isPriority = true;
        return 'Please reassign';
    }

    onAssigneeChanged() {
        this.workflowService.updateAssignee(this.taskId, {id: this.taskId, userId: this.assignee}).subscribe(() => {
            this.assigneeName = this.getAssignee();
            const note: any = {
                comment: `${this.authService.getProfile().name} assigned this task to ${this.assigneeName}`,
                authorName: this.authService.getProfile().name,
                authorId: this.authService.getProfile().userId
            };
            this.workflowService.addWorkflowComment(this.processInstanceId, this.taskId, note).subscribe(() => {
                this.assigning = false;
                this.noteAdded.emit(false);
            });
        });
    }

    clear() {
        this.assigning = false;
    }

    addNote() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            processInstanceId: this.processInstanceId,
            taskId: this.taskId,
            userId: this.authService.getProfile().userId,
            type: CommentType.WORKFLOW
        };
        const dialog = this.dialog.open(NewNoteModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((refresh: boolean) => {
            if (refresh) {
                this.noteAdded.emit(true);
            }
        });
    }
}
