
<div *ngIf="paymentProcessingSettingsForm">
    <form [formGroup]="paymentProcessingSettingsForm">
        <div>
            <div class="modal-header">
                <label class="form-title">{{isEdit ? 'Edit Notification' : 'Add Notification'}}</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content-small">
                <div>
                    <pc-text-input 
                        [label]="'Username'"
                        [placeholder]="'Enter username'"
                        [formCtrl]="'userNameCtrl'" 
                        [formGroup]="paymentProcessingSettingsForm">
                    </pc-text-input> 
                    <pc-text-input 
                        [label]="'Password'"
                        [placeholder]="'Enter password'"
                        [formCtrl]="'passwordCtrl'" 
                        [formGroup]="paymentProcessingSettingsForm">
                    </pc-text-input> 
                    <pc-text-input 
                        [label]="'Webhook URL'" 
                        [required]="true"
                        [placeholder]="'https://example.com/webhook'"
                        [formCtrl]="'webhookUrlCtrl'" 
                        [formGroup]="paymentProcessingSettingsForm">
                    </pc-text-input>
                    <pc-form-selector *ngIf="activeBusinessAccounts.length > 0" 
                        [label]="'Select Account'"
                        [formCtrl]="'accountIdCtrl'"
                        [formGroup]="paymentProcessingSettingsForm"
                        [options]="activeBusinessAccounts"
                        [idValue]="'id'"
                        [displayValue]="'accountName'" [defaultValue]="''">
                    </pc-form-selector>
                </div>
            </div>
            <div class="modal-footer">
                <pc-submit-button 
                    [enabled]="isSettingFormValid"
                    [submit]="onSubmit" 
                    [text]="isEdit ? 'Update Setting' : 'Add Setting'">
                </pc-submit-button>
            </div>
        </div>
    </form>
</div>