import { User } from './user';
import { Member } from './member';

export class AuditRecord {
    id: string;
    created: Date;
    memberId: string;

    performedByMemberId: string;
    performedByMember: string;
    member: Member;

    performedByUserId: string;
    performedByUser: string;
    user: User;

    performedByPartnerId: string;
    performedByPartner: string;
    partner: Member;


    ipAddress: string;
    action: AuditAction;
    referencedEntityType: ReferencedEntityType;
    referencedEntity: string;
    referencedEntityId: string;
    description: string;
    jsonData: string;

    referencedEntityLink: string;
    referencedEntityLinkParams: {[key: string]: any};
}

export enum AuditAction {
    LOGIN = 'LOGIN',
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DISABLE = 'DISABLE',
    DELETE = 'DELETE',
    SUBMIT = 'SUBMIT',
    CANCELLED = 'CANCELLED',
    MERGED = 'MERGED'
}

export enum ReferencedEntityType {
    ALL = '',
    MEMBER = 'MEMBER',
    MEMBER_ACCOUNT = 'MEMBER_ACCOUNT',
    USER = 'USER',
    ACCOUNT = 'ACCOUNT',
    TRANSACTION = 'TRANSACTION',
    TAX_INFO = 'TAX_INFO',
    SIGNATURE = 'SIGNATURE',
    FINANCIAL_DETAILS = 'FINANCIAL_DETAILS',
    RISK_PROFILE = 'RISK_PROFILE',
    BANK_ACCOUNT = 'BANK_ACCOUNT',
    KEY_PARTY = 'KEY_PARTY',
    MERCHANT = 'MERCHANT',
    MERCHANT_CONFIG = 'MERCHANT_CONFIG',
    RFI_HISTORY = 'RFI_HISTORY',
    LICENSE = 'LICENSE',
    EXTERNAL_ACCOUNT = 'EXTERNAL_ACCOUNT',
    DOCUMENT = 'DOCUMENT',
    COMPANY_WATCHLIST_SCREENING = 'COMPANY_WATCHLIST_SCREENING',
    INDIVIDUAL_WATCHLIST_SCREENING = 'INDIVIDUAL_WATCHLIST_SCREENING',
    LOCATION = 'LOCATION',
    FINCEN_REPORT = 'FINCEN_REPORT',
    FINCEN_INVESTIGATION = 'FINCEN_INVESTIGATION',
    PAYMENT_PROCESSOR_MERCHANT = 'PAYMENT_PROCESSOR_MERCHANT',
    BILLING_PROFILE= 'BILLING_PROFILE'
}
