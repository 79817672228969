import {NgModule} from '@angular/core';
import {APOLLO_OPTIONS, ApolloModule} from 'apollo-angular';
import {ApolloClientOptions, ApolloLink, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {environment} from 'projects/pt/src/environments/environment';
import {HttpHeaders} from '@angular/common/http';
import {onError} from '@apollo/client/link/error';
import {NotificationService} from 'projects/pt/src/app/notifications/notification.service';
import { HttpHeaderService } from 'projects/services/src/lib/header.service';

const authInterceptor = (httpHeaderService: HttpHeaderService) => {
    const apolloLink: ApolloLink = new ApolloLink((operation: any, forward: any) => {
        const headers: HttpHeaders = httpHeaderService.getAuthHeader();
        operation.setContext({
            headers: headers
        });

        return forward(operation);
    });

    return apolloLink;
};

const errorInterceptor = (notifier: NotificationService) => {
    return onError(({graphQLErrors, networkError}) => {
        if (graphQLErrors) {
            graphQLErrors.map(({message, locations, path}) => {
                notifier.showError(message);
            });
        }

        if (networkError) {
            notifier.showError(networkError.message);
        }
    });
};

export function createApollo(httpLink: HttpLink, notifier: NotificationService, httpHeaderService: HttpHeaderService): ApolloClientOptions<any> {
    return {
        cache: new InMemoryCache(),
        link: ApolloLink.from([
            errorInterceptor(notifier),
            authInterceptor(httpHeaderService),
            httpLink.create({uri: environment.GraphQLAPIEndpoint})
        ])
    };
}

@NgModule({
    exports: [ApolloModule],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink, NotificationService, HttpHeaderService]
        }
    ]
})
export class GqlModule {
}
