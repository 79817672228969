import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
    AccountingPartyType, Address, Bill, BusinessClient, BusinessClientStatus, Configuration, CorporateEntity, ExternalAccountingId, Invoice, Member, MemberAccount, MemberAccountService, MemberAccountStatus,
    MemberSource, MemberType, MerchantAccount, MerchantAccountConfig, MerchantAccountService, MerchantAccountSource, MerchantAchInfo, MerchantFormService, PagedResponse, SupportedTransactionType,
    TransactionSubType, TransactionType, Utils, WorkflowService
} from 'projects/services/src/public-api';
import { ErrorType } from 'projects/components/src/public-api';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pt-business-client-step',
    templateUrl: './business-client-step.component.html',
    styleUrls: ['./business-client-step.component.scss']
})
export class BusinessClientStepComponent implements OnInit {

    TransactionType = TransactionType;
    TransactionSubType = TransactionSubType;
    SupportedTransactionType = SupportedTransactionType;

    @Input() memberAccount: MemberAccount;
    @Input() isAccountingLinked = false;
    @Input() subType: TransactionSubType;
    @Input() formGroup: UntypedFormGroup;
    @Input() currentBusinessClient: BusinessClient<Invoice | Bill>;
    @Input() businessClients: BusinessClient<Invoice | Bill>[];
    @Input() clientContextTrigger = 1;

    @Output() businessClientSelected: EventEmitter<BusinessClient<Invoice | Bill>> = new EventEmitter();
    @Output() changeClientContext: EventEmitter<BusinessClient<Invoice | Bill>> = new EventEmitter();
    @Output() nextStep: EventEmitter<null> = new EventEmitter<null>();
    @Output() closeTransactionModal: EventEmitter<boolean> = new EventEmitter<boolean>();

    readonly ACCOUNTING_NOT_LINKED_ERROR = {
        type: ErrorType.WARN,
        message: 'Please link an accounting system to make deposits from your accounting clients'
    };

    createNewBusinessClient = false;
    verifyBusinessClient = false;

    merchantForm: UntypedFormGroup;
    merchantMemberAccountForm: UntypedFormGroup;
    existingAccountConfig: MerchantAccountConfig;
    merchantAccountExistsError: { message: string, type: ErrorType };
    merchantAccountVerificationInProgress = false;
    newMerchant = false;
    file: File;

    constructor(private memberAccountService: MemberAccountService,
                private merchantAccountService: MerchantAccountService,
                private workflowService: WorkflowService,
                private notificationService: NotificationService,
                private merchantFormService: MerchantFormService) {}

    ngOnInit() {
        this.next = this.next.bind(this);
        this.isMerchantFormValid = this.isMerchantFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.verifyMerchantLinkage = this.verifyMerchantLinkage.bind(this);
        this.createNewMerchant = this.createNewMerchant.bind(this);
    }

    onSelectBusinessClient(selectedBusinessClient: BusinessClient<Invoice | Bill>) {
        if (selectedBusinessClient && selectedBusinessClient.memberAccountId === null && selectedBusinessClient.status !== BusinessClientStatus.NEW) {
            this.currentBusinessClient = selectedBusinessClient;
            this.merchantForm = this.merchantFormService.initializeNewMerchantForm(this.currentBusinessClient.name);
            this.merchantForm.controls['merchantCtrl'].setValue(selectedBusinessClient);
            this.merchantForm.controls['merchantNameCtrl'].disable();
            this.merchantMemberAccountForm = this.merchantFormService.initializeNewMerchantMemberAccountForm(false, selectedBusinessClient.accountingData);
            this.createNewBusinessClient = true;
            this.newMerchant = true;
            this.verifyBusinessClient = false;
        } else if (selectedBusinessClient && (selectedBusinessClient.status === BusinessClientStatus.VERIFICATION_REQUIRED || selectedBusinessClient.status === BusinessClientStatus.PENDING_VERIFICATION_REQUIRED)) {
            this.currentBusinessClient = selectedBusinessClient;
            this.createNewBusinessClient = false;
            this.verifyBusinessClient = true;
        } else if (selectedBusinessClient && selectedBusinessClient.status !== BusinessClientStatus.NEW) {
            this.currentBusinessClient = selectedBusinessClient;
            this.createNewBusinessClient = false;
            this.verifyBusinessClient = false;
        } else if (selectedBusinessClient && selectedBusinessClient.status === BusinessClientStatus.NEW) {
            this.currentBusinessClient = null;
            this.merchantForm = this.merchantFormService.initializeNewMerchantForm();
            this.merchantForm.controls['merchantNameCtrl'].enable();
            this.merchantMemberAccountForm = this.merchantFormService.initializeNewMerchantMemberAccountForm(false);
            this.createNewBusinessClient = true;
            this.verifyBusinessClient = false;
            this.newMerchant = false;
        } else {
            this.currentBusinessClient = null;
            this.createNewBusinessClient = false;
            this.verifyBusinessClient = false;
        }
        this.businessClientSelected.emit(this.currentBusinessClient);
    }

    next() {
        this.nextStep.emit();
    }

    onCancel() {
        this.formGroup.controls['currentBusinessClientCtrl'].setValue(null);
        this.formGroup.controls['currentBusinessClientNameCtrl'].setValue('');
        this.currentBusinessClient = null;
        this.createNewBusinessClient = false;
        this.newMerchant = false;
        this.existingAccountConfig = null;
        this.merchantAccountExistsError = null;
        this.businessClientSelected.emit(this.currentBusinessClient);
    }

    isMerchantFormValid() {
        return this.merchantForm && this.merchantForm.valid &&
            (!this.newMerchant || (this.newMerchant && this.merchantMemberAccountForm.valid))
            && !this.merchantAccountExistsError
            && !this.merchantAccountVerificationInProgress;
    }

    updateBankAccountValidity(_event: any) {
        this.merchantFormService.validateAndUpdateMerchantForm(this.merchantForm);
    }

    setMerchant(merchantMemberAccount: MemberAccount) {
        this.newMerchant = merchantMemberAccount.status === MemberAccountStatus.NEW;
        const merchantMember = merchantMemberAccount.member;
        this.merchantForm.controls['merchantCtrl'].setValue(merchantMember);
        this.merchantAccountExistsError = null;
        this.merchantAccountVerificationInProgress = false;
        this.resetForm();
        if (!this.newMerchant && merchantMember?.id) {
            // we need to retrieve the merchant config for this merchant and do some stuff
            this.merchantAccountService.getMerchantAccountConfigByMerchantMemberId(merchantMember.id).subscribe((merchantAccountConfig: MerchantAccountConfig) => {
                this.existingAccountConfig = merchantAccountConfig;
                this.merchantForm.controls['merchantIdCtrl'].setValue(merchantMember.id);
                this.loadMerchantContactAndLicenseInfo(merchantMember.id);
                this.merchantMemberAccountForm.controls['briefDescriptionCtrl'].setValue(merchantMember.briefDescription);
                this.merchantForm.controls['allowedTransactionsCtrl'].setValue(merchantAccountConfig.merchantAccountType);
                this.merchantAccountService.getMerchantAccountConfigAddress(merchantAccountConfig.id).subscribe((address: Address) => {
                    this.merchantMemberAccountForm.controls['streetAddressOneCtrl'].setValue(address.streetAddressOne);
                    this.merchantMemberAccountForm.controls['cityCtrl'].setValue(address.city);
                    this.merchantMemberAccountForm.controls['stateProvinceCtrl'].setValue(address.stateProvince);
                    this.merchantMemberAccountForm.controls['countryCtrl'].setValue(address.country);
                    this.merchantMemberAccountForm.controls['zipPostalCodeCtrl'].setValue(address.zipPostalCode);
                    this.merchantMemberAccountForm.disable();
                });
                this.verifyMerchantNotAttached();
            });
        } else if (this.newMerchant) {
            this.merchantForm.controls['merchantIdCtrl'].setValue('');
            this.merchantForm.controls['allowedTransactionsCtrl'].setValue(SupportedTransactionType.BOTH);
            this.existingAccountConfig = null;
            this.merchantMemberAccountForm.enable();
            this.merchantMemberAccountForm.controls['countryCtrl'].setValue('United States');
            this.merchantMemberAccountForm.markAsPristine();
            this.merchantForm.markAsPristine();
        } else {
            this.existingAccountConfig = null;
            this.currentBusinessClient = null;
            this.createNewBusinessClient = false;
            this.merchantForm.reset();
            this.merchantMemberAccountForm.reset();
            this.merchantMemberAccountForm.enable();
            this.merchantForm.controls['allowedTransactionsCtrl'].setValue(SupportedTransactionType.BOTH);
        }
    }

    resetForm() {
        this.file = null;
        this.merchantForm.controls['achAccountHolderCtrl'].setValue('');
        this.merchantForm.controls['achAccountNumberCtrl'].setValue('');
        this.merchantForm.controls['achRoutingNumberCtrl'].setValue('');
        this.merchantForm.controls['wireRoutingNumberCtrl'].setValue('');
        this.merchantFormService.validateAndUpdateMerchantForm(this.merchantForm);
        this.merchantMemberAccountForm.controls['ownerPhoneCtrl'].setValue('');
        this.merchantMemberAccountForm.controls['ownerEmailCtrl'].setValue('');
        this.merchantMemberAccountForm.controls['ownerNameCtrl'].setValue('');
        this.merchantMemberAccountForm.controls['licenseHolderCtrl'].setValue('');
        this.merchantMemberAccountForm.controls['streetAddressOneCtrl'].setValue('');
        this.merchantMemberAccountForm.controls['cityCtrl'].setValue('');
        this.merchantMemberAccountForm.controls['stateProvinceCtrl'].setValue('');
        this.merchantMemberAccountForm.controls['zipPostalCodeCtrl'].setValue('');
        this.merchantMemberAccountForm.controls['briefDescriptionCtrl'].setValue('');
    }

    verifyMerchantNotAttached() {
        if (this.existingAccountConfig) {
            this.merchantAccountExistsError = null;
            this.merchantAccountVerificationInProgress = true;
            this.merchantAccountService.getAllMerchantAccountsByMerchantMemberAccountIdAndMemberId(this.existingAccountConfig.merchantMemberAccount.id, this.memberAccount.memberId).subscribe((merchantAccounts: PagedResponse<MerchantAccount>) => {
                if (merchantAccounts.totalElements > 0) {
                    this.merchantAccountExistsError = {
                        message: 'This merchant has already been registered.',
                        type: ErrorType.ERROR
                    };
                }
                this.merchantAccountVerificationInProgress = false;
            });
        }
    }

    onSubmit(reset: any) {
        const businessMerchantMember = new Member();
        if (this.newMerchant) {
            businessMerchantMember.name = this.merchantForm.controls['merchantCtrl'].value.name;
            businessMerchantMember.briefDescription = this.merchantMemberAccountForm.controls['briefDescriptionCtrl'].value;
            businessMerchantMember.source = this.currentBusinessClient?.source || MemberSource.CONFIA;
            const orgAddress = this.merchantFormService.getAddress(this.merchantMemberAccountForm);
            const merchantAccountConfig = new MerchantAccountConfig();
            // When authority added new merchant in members account section which is public
            merchantAccountConfig.shared = false;
            merchantAccountConfig.merchantAccountType = this.merchantForm.controls['allowedTransactionsCtrl'].value;
            merchantAccountConfig.source = this.currentBusinessClient?.source === MemberSource.ACCOUNTING ? MerchantAccountSource.ACCOUNTING : MerchantAccountSource.CONFIA;
            const merchantAccount = new MerchantAccount();
            const merchantAchInfo = new MerchantAchInfo();
            merchantAccount.merchantAccountConfig = merchantAccountConfig;
            if (this.currentBusinessClient?.accountingId) {
                merchantAccount.accountingExternalIds = [new ExternalAccountingId(this.currentBusinessClient.accountingId, this.subType === TransactionSubType.PAYMENT ? AccountingPartyType.SUPPLIER : AccountingPartyType.CUSTOMER)];
            }
            if (merchantAccountConfig.merchantAccountType !== SupportedTransactionType.INCOMING) {
                merchantAchInfo.accountHolder = this.merchantForm.controls['achAccountHolderCtrl'].value;
                merchantAchInfo.accountNumber = this.merchantForm.controls['achAccountNumberCtrl'].value;
                merchantAchInfo.routingNumber = this.merchantForm.controls['achRoutingNumberCtrl'].value;
                merchantAchInfo.wireRoutingNumber = this.merchantForm.controls['wireRoutingNumberCtrl'].value;
            }
            const licenseHolder = this.merchantMemberAccountForm.controls['licenseHolderCtrl'].value || '';
            const contactInfo = this.merchantFormService.getContactInfo(this.merchantMemberAccountForm);
            const registerMerchant = {
                contactInfo,
                merchantAddress: orgAddress,
                merchantMember: businessMerchantMember,
                merchantAccount,
                merchantAchInfo,
                licenseHolder
            };

            this.workflowService.registerBusinessMerchant(registerMerchant, this.file).subscribe((savedMerchantAccountConfig: MerchantAccountConfig) => {
                const businessClientMerchant = (savedMerchantAccountConfig.merchantMemberAccount.member as CorporateEntity);
                reset();
                this.createNewBusinessClient = false;
                const defaultTransactionType = this.subType === TransactionSubType.DEPOSIT ? savedMerchantAccountConfig.defaultIncomingTransactionType : savedMerchantAccountConfig.defaultOutgoingTransactionType;
                this.currentBusinessClient = new BusinessClient(savedMerchantAccountConfig.merchantMemberAccount.id,
                    businessClientMerchant.id,
                    businessClientMerchant.name,
                    businessClientMerchant.dbaName,
                    BusinessClientStatus[businessClientMerchant.status],
                    savedMerchantAccountConfig.merchantMemberAccount.member.source,
                    MemberType.BUSINESS_MERCHANT,
                    defaultTransactionType,
                    this.currentBusinessClient?.accountingId,
                    null,
                    savedMerchantAccountConfig.merchantMemberAccount?.address
                );
                this.formGroup.controls['currentBusinessClientCtrl'].setValue(this.currentBusinessClient);
                this.formGroup.controls['currentBusinessClientNameCtrl'].setValue(this.currentBusinessClient?.name);
                this.businessClientSelected.emit(this.currentBusinessClient);
                this.next();
            }, (error: any) => {
                reset();
                if (error.error instanceof ProgressEvent && error.error.loaded === 0 && error.error.type === 'error') {
                    // Client-side error
                    this.notificationService.showError('Document upload was unsuccessful. Please check your connection and try again.');
                } else {
                    // Server-side error
                    throw error;
                }
            });
        } else {
            this.saveExistingMerchantAccount(reset);
        }
    }

    saveExistingMerchantAccount(reset: any) {
        const merchantAccount = new MerchantAccount();
        merchantAccount.merchantAccountConfig = this.existingAccountConfig;
        merchantAccount.memberId = this.memberAccount.memberId;
        merchantAccount.status = this.existingAccountConfig.status;
        this.memberAccountService.loadMemberAccount(this.existingAccountConfig.merchantMemberAccount.id).subscribe((memberAccount: MemberAccount) => {
            merchantAccount.merchantMemberAccount = memberAccount;
            this.merchantAccountService.linkMerchantAndMember(merchantAccount).subscribe((merchantAccount: MerchantAccount) => {
                reset();
                this.createNewBusinessClient = false;
                const defaultTransactionType = this.subType === TransactionSubType.DEPOSIT ? merchantAccount.merchantAccountConfig.defaultIncomingTransactionType : merchantAccount.merchantAccountConfig.defaultOutgoingTransactionType;
                this.currentBusinessClient = new BusinessClient(merchantAccount.merchantMemberAccount.id,
                    merchantAccount.merchantMemberAccount.memberId,
                    merchantAccount.merchantMemberAccount.member.name,
                    (merchantAccount.merchantMemberAccount.member as CorporateEntity).dbaName,
                    BusinessClientStatus[merchantAccount.merchantMemberAccount.member.status],
                    merchantAccount.merchantMemberAccount.member.source,
                    MemberType.BUSINESS_MERCHANT,
                    defaultTransactionType,
                    null,
                    null,
                    merchantAccount.merchantMemberAccount?.address
                );
                this.formGroup.controls['currentBusinessClientCtrl'].setValue(this.currentBusinessClient);
                this.formGroup.controls['currentBusinessClientNameCtrl'].setValue(this.currentBusinessClient.name);
                this.businessClientSelected.emit(this.currentBusinessClient);
                this.next();
            });
        });
    }

    selectFile(event: any) {
        if (event.target.files && event.target.files.length) {
            for (const file of event.target.files) {
                Utils.validateFile(file, event);
            }
            this.file = event.target.files[0];
        }
        event.target.value = '';
    }

    deleteDocument() {
        this.file = null;
    }

    showInvolvedMerchants() {
        return this.formGroup.controls['transactionTypeCtrl'].value === TransactionType.CASH_DEPOSIT && this.subType === TransactionSubType.DEPOSIT &&
            this.businessClients.length > 0;
    }

    changeContext(index: number) {
        this.changeClientContext.emit(this.businessClients[index]);
    }

    loadMerchantContactAndLicenseInfo(memberId: string) {
        this.merchantAccountService.loadMerchantContactAndLicenseInfo(memberId).subscribe((result: any) => {
            if (result) {
                this.merchantMemberAccountForm.controls['ownerPhoneCtrl'].setValue(result?.officePhone);
                this.merchantMemberAccountForm.controls['ownerEmailCtrl'].setValue(result?.email);
                this.merchantMemberAccountForm.controls['ownerNameCtrl'].setValue(result?.name);
                this.merchantMemberAccountForm.controls['licenseHolderCtrl'].setValue(result.licenseHolder);
            }
        });
    }

    verifyMerchantLinkage(reset: any) {
        const merchantAccountId = this.currentBusinessClient.merchantData.externalId;
        this.merchantAccountService.getMerchantAccountById(merchantAccountId).subscribe((merchantAccount: MerchantAccount) => {
            const accountingIds = merchantAccount.accountingExternalIds;
            accountingIds.push(new ExternalAccountingId(this.currentBusinessClient.accountingData.externalId, this.currentBusinessClient.accountingData.type));
            this.merchantAccountService.updateMerchantAccount(merchantAccountId, {accountingExternalIds: accountingIds}).subscribe(() => {
                this.verifyBusinessClient = false;
                reset();
                this.next();
            }, (error: any) => {
                reset();
                throw error;
            });
        });
    }

    createNewMerchant() {
        const businessClient = new BusinessClient();
        businessClient.name = this.currentBusinessClient.name;
        this.merchantForm = this.merchantFormService.initializeNewMerchantForm(this.currentBusinessClient.name);
        this.merchantForm.controls['merchantCtrl'].setValue(businessClient);
        this.merchantForm.controls['merchantNameCtrl'].disable();
        this.merchantMemberAccountForm = this.merchantFormService.initializeNewMerchantMemberAccountForm(false, this.currentBusinessClient.accountingData);
        this.createNewBusinessClient = true;
        this.newMerchant = true;
        this.verifyBusinessClient = false;
    }

    closeModal(event: any) {
        this.closeTransactionModal.emit(event);
    }
}
