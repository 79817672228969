import { Task, WorkflowService, PagedResponse, TaskCategory, AuthService, User, Role } from 'projects/services/src/public-api';
import { Component, Input,  OnInit, AfterViewInit, ViewChild, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pc-work-list',
    templateUrl: './work-list.component.html',
    styleUrls: ['./work-list.component.scss']
})
export class WorkListComponent implements OnInit, AfterViewInit {

    @Input() taskCategory = TaskCategory.ALL;
    @Input() assignee = '';
    @Input() authorityUsers: {userId: string, user: User}[] = [];

    tasksObservable: Observable<Task[]>;
    taskDefinitions: string[] = [];

    isLoadingResults = true;
    displayedColumns: string[] = ['priority', 'name', 'date', 'process', 'assignee'];

    pageTracking: PageTracking;
    resultsLength = 0;
    subscription: any;
    filterEvent: EventEmitter<null> = new EventEmitter<null>();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private workflowService: WorkflowService,
                public authService: AuthService,
                private route: ActivatedRoute,
                private router: Router,
                private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.updateTaskDefinitions();
        if (!this.authService.isAuthorityOrReviewer()) {
            this.displayedColumns.splice(this.displayedColumns.indexOf('priority'), 1);
            this.displayedColumns.splice(this.displayedColumns.indexOf('assignee'), 1);
        }
        if (this.authService.getProfile().role === Role.NEW_ADMIN_REGISTRANT) {
            this.displayedColumns.splice(this.displayedColumns.indexOf('process'), 1);

        }
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'created', 'desc', 100);

    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort, this.filterEvent);
        this.addTableLoadListener();
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.tasksObservable = merge(this.sort.sortChange, this.paginator.page, this.filterEvent).pipe(
            startWith({}),
            switchMap(() => {
                UIUtils.scrollToTop(document.querySelector('.mat-table-container'));
                this.isLoadingResults = true;
                this.updateTaskDefinitions();
                // NOTE: The Camunda API does not query by pages - it uses start and ends.  So the pageable needs to take this in to account.
                const currentPage = ((this.paginator.pageIndex + 1) - 1) * this.paginator.pageSize;
                return this.workflowService.getTasksSorted(this.taskDefinitions, this.assignee, currentPage, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<Task>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        );
    }

    loadTask(taskId: string) {
        this.workflowService.loadTask(taskId);
    }

    updateTaskDefinitions() {
        switch (this.taskCategory) {
            case TaskCategory.ALL:
                this.taskDefinitions = [];
                break;

            case TaskCategory.MEMBER_ONBOARDING:
                this.taskDefinitions = ['user_pt_onboarding_review', 'user_authority_member_review', 'user_verify_email'];
                break;

            case TaskCategory.ACCOUNT_ONBOARDING:
                this.taskDefinitions = ['user_account_registration_review', 'user_account_number_registration'];
                break;

            case TaskCategory.MERCHANT_ONBOARDING:
                this.taskDefinitions = ['user_review_individual_merchant', 'user_review_business_merchant'];
                break;

            case TaskCategory.RFI_REVIEW:
                this.taskDefinitions = ['user_adjudicate_rfi'];
                break;

            case TaskCategory.WATCHLIST_REVIEW:
                this.taskDefinitions = ['user_review_watchlist_hits'];
                break;

            case TaskCategory.CHECK_VERIFICATION:
                this.taskDefinitions = ['user_verify_check'];
                break;

            case TaskCategory.TRANSACTION_RECONCILIATION:
                this.taskDefinitions = ['user_confirm_check_withdrawal', 'user_confirm_deposit', 'user_confirm_currency_withdrawal'];
                break;

            case TaskCategory.BANK_ACCOUNT_REVIEW:
                this.taskDefinitions = ['user_bank_account_review'];
                break;

            case TaskCategory.USER_INVITATIONS:
                this.taskDefinitions = ['user_create_account', 'user_verify_link'];
                break;

            case TaskCategory.USER_PASSWORD_RESET:
                this.taskDefinitions = ['user_reset_password'];
                break;

            case TaskCategory.PERIODIC_REVIEW:
                this.taskDefinitions = ['user_authority_periodic_review'];
                break;

            case TaskCategory.EXTERNAL_PRESENTED_CHECK_REVIEW:
                this.taskDefinitions = ['user_adjudicate_presented_check_review'];
            default:
                break;
        }
    }

    completeTask(taskId: string) {
        this.workflowService.completeTask(taskId, {}, this.filterEvent.emit).subscribe(() => {
            this.filterEvent.emit();
        });
    }

    isPriority(task: Task) {
        return this.authService.isAuthorityOrReviewer() && task.priority === 80;
    }

    refreshList() {
        this.filterEvent.emit();
    }
}
