import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Member, Transaction, TransactionSubType, TransactionType } from 'projects/services/src/public-api';
import { BaseModalComponent } from '../base-modal.component';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { ErrorType } from 'projects/components/src/lib/error-message';

@Component({
    selector: 'pc-merge-check-details-modal',
    templateUrl: './merge-check-details-modal.component.html',
    styleUrls: ['./merge-check-details-modal.component.scss']
})

export class MergeCheckDetailsModelComponent extends BaseModalComponent<MergeCheckDetailsModelComponent> implements OnInit, OnDestroy {

    TransactionSubType = TransactionSubType;
    TransactionType = TransactionType;
    existingTransaction : Transaction;
    payorMember: Member;
    payeeMember: Member;
    externalCheckAmount: number;
    externalCheckNumber: string;
    checkCreationDate: Date;
    amountMismatchIndicator = false;
    checkNumberMismatchIndicator = false;
    url: string;
    subscription : any;

    readonly AMOUNT_MISMATCH = {
        message: 'The selected transaction amount does not matched with the external check amount.',
        type: ErrorType.WARN
    };
    readonly CHECK_NUMBER_MISMATCH = {
        message: 'The selected transaction check number does not matched with the external check number.',
        type: ErrorType.WARN
    };

    constructor(dialogRef: MatDialogRef<MergeCheckDetailsModelComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private browserRouter: Router,
        private route: ActivatedRoute) {
        super(dialogRef);
        this.existingTransaction = data.existingTransaction;
        this.payeeMember = data.payeeMember;
        this.payorMember = data.payorMember;
        this.externalCheckAmount = data.externalCheckAmount;
        this.externalCheckNumber = data.externalCheckNumber;
        this.checkCreationDate = data.checkCreationDate;
        if (this.externalCheckAmount !== this.existingTransaction.totalAmount) {
            this.amountMismatchIndicator = true;
        }
        if (this.externalCheckNumber !== data.existingTransaction?.checkNumber) {
            this.checkNumberMismatchIndicator = true;
        }
    }

    ngOnInit(): void {
        this.url = this.route.snapshot['_routerState'].url.split('?')[0];

        // close the modal when user shifts to other pages
        this.subscription = this.browserRouter.events.subscribe((data: any) => {
            if (data instanceof NavigationStart) {
                const urlRoot = data.url.split('?')[0];
                if (urlRoot !== this.url) {
                    this.onCancel(false);
                }
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    onMerge(mergeTransaction: boolean) {
        let data = {
            mergeTransaction : mergeTransaction,
            transactionId : this.existingTransaction.id
        };
        super.close(data);
    }

    onCancel(notifyUser?: boolean) {
        let data = {
            mergeTransaction : notifyUser
        };
        super.close(data);
    }

    onSubmit(reset?: any) {
        setTimeout(() => {
            reset();
        }, 500);
    }
}