import { ElementRef, Inject, Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { ExternalPresentedCheckDetails, ProcessedCheckDetails, Upload } from 'projects/services/src/public-api';
import { HttpResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class CheckProcessorService {

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.NBCUAPIEndpoint;
    }

    getProcessedCheckDetailsByTransactionId(transactionId: any) : Observable<ProcessedCheckDetails> {
        return this.http.get(`${this.baseUrl}/check_processor/processed_check/transaction_id/${transactionId}`) as Observable<ProcessedCheckDetails>;
    }

    updateCheckDetailsById(processedCheckDetailsId: any, updatableCheckDetails: any) : Observable<ProcessedCheckDetails> {
        return this.http.put(`${this.baseUrl}/check_processor/processed_check/${processedCheckDetailsId}`, updatableCheckDetails) as Observable<ProcessedCheckDetails>;
    }

    getExternalCheckNumberByTransactionId(transactionId: any) : Observable<string> {
        return this.http.get(`${this.baseUrl}/external_check/${transactionId}`) as Observable<string>;
    }

    getExternalPresentedChecksbyCheckNumber(checkNumber: string) : Observable<ExternalPresentedCheckDetails []> {
        return this.http.get(`${this.baseUrl}/external_check/search/${checkNumber}`) as Observable<ExternalPresentedCheckDetails[]>;
    }

    updateCheckDetails(checkId: string, body: any) {
        return this.http.put(`${this.baseUrl}/external_check/${checkId}`, body);
    }
}