
<div class="col-12 dashboard-list">
    <div class="row d-flex justify-content-start mb-3 mt-3">
        <div class="col-auto dashboard-header">
            <img class="dashboard-icon" src="assets/images/bell-icon.svg" alt="alerts">
            <span>Alerts</span>
        </div>
        <div class="col-auto">
            <pc-member-selector *ngIf="showMemberFilter && formGroup" [formGroup]="formGroup" [formCtrl]="'memberCtrl'" [placeholder]="'Search Members'"
                (memberSelected)="memberSelected($event)" [displayLabel]="false" [excludeSelf]="false"
                [memberTypes]="[MemberType.BUSINESS, MemberType.CONSUMER, MemberType.BUSINESS_MERCHANT, MemberType.INDIVIDUAL_MERCHANT]" [statuses]="[]">
            </pc-member-selector>
        </div>
        <div class="col-auto">
            <pc-text-option-selector [required]="true" [label]="" [options]="alertTypes"
                [formCtrl]="'alertTypeCtrl'" [formGroup]="formGroup" [defaultValue]="''" [translated]="true"
                (selected)="alertTypeChanged($event)">
            </pc-text-option-selector>
        </div>
        <div class="col-auto">
            <select class="form-control-header form-input-table-header form-select-sm" type="text" [(ngModel)]="alertStatus" (change)="changedStatus(true)">
                <option [value]="AlertStatus.ALL">All Stages</option>
                <option [value]="AlertStatus.ACTIVE">Active</option>
                <option [value]="AlertStatus.REVIEWED">Reviewed</option>
                <option [value]="AlertStatus.INFORMATIONAL">Informational</option>
                <option [value]="AlertStatus.ACTIONED">Actioned</option>
            </select>
        </div>
    </div>
    <div class="col-sm-12 horizontal-scroll mat-table-container">
        <div class="mat-table-spinner" *ngIf="isLoadingResults">
            <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
        </div>
        <table mat-table [dataSource]="alerts" matSort [matSortActive]="pageTracking.sort" matSortDisableClear
               [matSortDirection]="pageTracking.sortDir">
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> Description</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.type">
                        <div *ngIf="navigatesToMember(element.type) && !authService.isAuthority()">
                            {{ element.description }}
                        </div>
                        <a *ngIf="navigatesToMember(element.type) && authService.isAuthority()"
                           [routerLink]="['/administration/member/' + element.memberId]"
                           [queryParams]="{_activeTab: 'accounts'}" [routerLinkActive]="['router-link-active']">
                            {{ element.description }}
                        </a>
                        <a *ngIf="element.type === AlertType.TRANSACTION || element.type === AlertType.CURRENCY_WITHDRAWAL"
                           [routerLink]="['/alert/details/transaction/' + element.id]"
                           [routerLinkActive]="['router-link-active']">
                            {{ element.description }}
                        </a>
                        <a *ngIf="element.type === AlertType.KEY_PARTY"
                           [routerLink]="['/administration/member/' + element.memberId]"
                           [queryParams]="{_activeTab: 'key_parties'}" [routerLinkActive]="['router-link-active']">
                            {{ element.description }}
                        </a>
                        <a *ngIf="element.type === AlertType.LICENSE"
                           [routerLink]="['/administration/member/' + element.memberId]" [queryParams]="{_activeTab: 'licenses'}"
                           [routerLinkActive]="['router-link-active']">
                            {{ element.description }}
                        </a>
                        <a *ngIf="element.type === AlertType.DOCUMENT"
                           [routerLink]="['/administration/member/' + element.memberId]" [queryParams]="{_activeTab: 'documents'}"
                           [routerLinkActive]="['router-link-active']">
                            {{ element.description }}
                        </a>
                        <a *ngIf="element.type === AlertType.QUESTION_RESPONSE"
                           [routerLink]="['/administration/member/' + element.memberId]" [queryParams]="{_activeTab: 'questions'}"
                           [routerLinkActive]="['router-link-active']">
                            {{ element.description }}
                        </a>
                        <a *ngIf="element.type === AlertType.MONTHLY_REPORT"
                           [routerLink]="['/administration/member/' + element.memberId]" [queryParams]="{_activeTab: 'reports'}"
                           [routerLinkActive]="['router-link-active']">
                            {{ element.description }}
                        </a>
                        <a *ngIf="element.type === AlertType.SAR_REPORT"
                            [routerLink]="element.source.includes('Fincen Failure Alert') ? ['/reports/authority/list'] : ['/reports/details', element.referencedEntityId]"
                            [queryParams]="element.source.includes('Fincen Failure Alert') ? { reportType: 'SAR', memberId: element.memberId } : {}"
                            [routerLinkActive]="['router-link-active']">
                            {{ element.description }}
                        </a>
                        <a *ngIf="element.type === AlertType.CTR_REPORT"
                            [routerLink]="element.source.includes('Fincen Failure Alert') ? ['/reports/authority/list'] : ['/reports/details', element.referencedEntityId]"
                            [queryParams]="element.source.includes('Fincen Failure Alert') ? { reportType: 'CTR', memberId: element.memberId } : {}"
                            [routerLinkActive]="['router-link-active']">
                            {{ element.description }}
                        </a>
                        <a *ngIf="element.type === AlertType.TRACKING_SYSTEM"
                           [routerLink]="['/administration/member/' + element.memberId]" [queryParams]="{_activeTab: 'metrc'}"
                           [routerLinkActive]="['router-link-active']">
                            {{ element.description }}
                        </a>
                        <a *ngIf="element.type === AlertType.TRANSACTION_FILE_PROCESSING && (element.referencedEntityId || element.jsonData)" [routerLink]="['/alert/details/' + element.id]" [routerLinkActive]="['router-link-active']">
                            {{ element.description }}
                        </a>
                        <div *ngIf="element.type === AlertType.TRANSACTION_FILE_PROCESSING && !(element.referencedEntityId || element.jsonData)">
                            {{ element.description }}
                        </div>
                        <a *ngIf="element.type === AlertType.FINCEN_INVESTIGATION"
                            [routerLink]="['/reports/authority/list/']" [queryParams]="{reportType: 'FINCEN_INVESTIGATIONS'}" [routerLinkActive]="['router-link-active']">
                            {{ element.description }}
                        </a>
                        <a *ngIf="element.type === AlertType.USER"
                            [routerLink]="['/administration/member/' + element.memberId]" [queryParams]="{_activeTab: 'users'}" [routerLinkActive]="['router-link-active']">
                            {{ element.description }}
                        </a>
                        <div *ngIf="element.type === AlertType.MERCHANT && !(element.referencedEntityId || element.jsonData)">
                            {{ element.description }}
                        </div>
                        <a *ngIf="element.type === AlertType.PAYMENT_PROCESSOR"
                            [routerLink]="['/member-accounts/details/' + element.memberAccountId]" [queryParams]="{_transactionTab: 'TRANSACTION_LIST'}" [routerLinkActive]="['router-link-active']">
                            {{ element.description }}
                        </a>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date</th>
                <td class="text-nowrap" mat-cell *matCellDef="let element">
                    {{ element.created | date:'mediumDate' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Type</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.type | translate }}
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th class="centered" mat-header-cell *matHeaderCellDef mat-sort-header> Status</th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <pc-alerts-badge [parent]="element"></pc-alerts-badge>
                </td>
            </ng-container>
            <ng-container matColumnDef="member_name">
                <th mat-header-cell *matHeaderCellDef> Member Name</th>
                <td mat-cell *matCellDef="let element">
                    <a [routerLink]="['/administration/member/' + element.memberId]"
                        [queryParams]="{_activeTab: 'accounts'}" [routerLinkActive]="['router-link-active']">
                        {{ element.memberName }}
                    </a>
                </td>
            </ng-container>
            <ng-container matColumnDef="account">
                <th mat-header-cell *matHeaderCellDef> Account</th>
                <td mat-cell *matCellDef="let element">
                    <a *ngIf="element.memberAccount" [routerLink]="['/member-accounts/details/' + element.memberAccountId]"
                       [queryParams]="{_transactionTab: 'ACCOUNT_LIMITS'}"
                       [routerLinkActive]="['router-link-active']">
                        {{ element.memberAccount.accountName }}
                    </a>
                </td>
            </ng-container>
            <ng-container matColumnDef="note">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Note</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.note }}
                </td>
            </ng-container>
            <ng-container matColumnDef="acknowledged_by">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="acknowledgedBy"> Acknowledged By</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.acknowledgedBy }}
                </td>
            </ng-container>
            <ng-container matColumnDef="source">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Source</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.source | translate }}
                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th class="centered" mat-header-cell *matHeaderCellDef> Action</th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <div *ngIf="element.status === AlertStatus.ACTIVE && element.type !== AlertType.TRANSACTION">
                        <i *ngIf="authService.isAuthority()" class="fa fa-times" (click)="reviewAlert(element,$event)"></i>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator class="mat-paginator-sticky" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons
            aria-label="Select page"></mat-paginator>
    </div>
</div>
