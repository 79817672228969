<div class="col-sm-12">
    <div class="row mb-3">
        <div class="col-sm-8">
            <p class="form-title mb-0">Payment Processing Notifications</p>
        </div>
        <div class="col-sm-4 text-end" *ngIf="!readOnly">
            <button pc-no-dbl-click class="blue-button" (click)="onAddNotification()" type="button" submit-button>
                <i class="fa fa-plus pull-left dashboard-icon"></i>
                <div class="pull-right-button">Add Notification</div>
            </button>
        </div>
    </div>
    <div class="col-sm-12 mt-3 mb-3 horizontal-scroll mat-table-container">
        <table mat-table [dataSource]="paymentProcessorNotifications">
            <ng-container matColumnDef="user_name">
                <th class="centered" mat-header-cell *matHeaderCellDef> User Name </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    {{ element.userName }}
                </td>
            </ng-container>
            <ng-container matColumnDef="password">
                <th class="centered" mat-header-cell *matHeaderCellDef> Password </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    {{ element.password }}
                </td>
            </ng-container>
            <ng-container matColumnDef="webhook_url">
                <th class="centered" mat-header-cell *matHeaderCellDef> Webhook URL </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    {{ element.webhookUrl }}
                </td>
            </ng-container>
            <ng-container matColumnDef="account_name">
                <th class="centered" mat-header-cell *matHeaderCellDef> Account Name </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    {{ element.memberAccountName }}
                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th class="centered" mat-header-cell *matHeaderCellDef> Actions </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <div class="action-buttons" *ngIf="!readOnly">
                        <i class="fa fa-edit cursor-pointer" (click)="onEditNotification(element)"></i>
                        <i class="fa fa-trash cursor-pointer" (click)="onDeleteNotification(element)"></i>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedApiKeysColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedApiKeysColumns;"></tr>
        </table>
        <div class="text-center p-3" *ngIf="paymentProcessorNotifications?.length === 0">
            No Notifications configured
        </div>
    </div>
</div>