<div>
    <div *ngIf="!createNewBusinessClient">
        <pc-business-client-selector *ngIf="!currentBusinessClient || !createNewBusinessClient" [formGroup]="formGroup" [formCtrl]="'currentBusinessClientCtrl'" [merchantNameFormCtrl]="'currentBusinessClientNameCtrl'"  [placeholder]="'Search Business Clients'"
             [accountingLinked]="isAccountingLinked" [clientContextTrigger]="clientContextTrigger" [subType]="subType" (businessClientSelected)="onSelectBusinessClient($event)" (closeTransactionModal)="closeModal($event)"></pc-business-client-selector>
        <div *ngIf="verifyBusinessClient" class="row">
            <div class="col-12">
                <p>This merchant appears to exist in your accounting system already.</p>
            </div>
            <div class="col-6 d-flex justify-content-center">
                <div>
                    <label class="form-label mb-2">Merchant Info</label>
                    <div>{{currentBusinessClient.merchantData.name}}</div>
                    <div *ngIf="currentBusinessClient.merchantData.dbaName">{{currentBusinessClient.merchantData.dbaName}}</div>
                    <div *ngIf="currentBusinessClient.merchantData.email">{{currentBusinessClient.merchantData.email}}</div>
                    <div *ngIf="currentBusinessClient.merchantData.officePhone">{{currentBusinessClient.merchantData.officePhone}}</div>
                    <div *ngIf="currentBusinessClient.merchantData.billAddress" class="mt-2">{{currentBusinessClient.merchantData.billAddress.streetAddressOne}}<br/>
                        {{currentBusinessClient.merchantData.billAddress.city}}, {{currentBusinessClient.merchantData.billAddress.stateProvince}}<br/>
                        {{currentBusinessClient.merchantData.billAddress.country}}<br/>
                        {{currentBusinessClient.merchantData.billAddress.zipPostalCode}}<br/>
                    </div>
                    <div *ngIf="currentBusinessClient.accountingData.description" class="mt-2">Description: {{currentBusinessClient.accountingData.description}}</div>
                </div>
            </div>
            <div class="col-6 d-flex justify-content-center">
                <div>
                    <label class="form-label mb-2">Accounting Info</label>
                    <div>{{currentBusinessClient.accountingData.name}}</div>
                    <div *ngIf="currentBusinessClient.accountingData.dbaName">{{currentBusinessClient.accountingData.dbaName}}</div>
                    <div *ngIf="currentBusinessClient.accountingData.email">{{currentBusinessClient.accountingData.email}}</div>
                    <div *ngIf="currentBusinessClient.accountingData.officePhone">{{currentBusinessClient.accountingData.officePhone}}</div>
                    <div *ngIf="currentBusinessClient.accountingData.billAddress" class="mt-2">{{currentBusinessClient.accountingData.billAddress.streetAddressOne}}<br/>
                        {{currentBusinessClient.accountingData.billAddress.city}}, {{currentBusinessClient.accountingData.billAddress.stateProvince}}<br/>
                        {{currentBusinessClient.accountingData.billAddress.country}}<br/>
                        {{currentBusinessClient.accountingData.billAddress.zipPostalCode}}<br/>
                    </div>
                    <div *ngIf="currentBusinessClient.accountingData.contactName" class="mt-2">Contact Name: {{currentBusinessClient.accountingData.contactName}}</div>
                    <div *ngIf="currentBusinessClient.accountingData.externalId" class="mt-2">{{currentBusinessClient.accountingData.type | translate}} ID: {{currentBusinessClient.accountingData.externalId}}</div>
                </div>
            </div>
            <div class="col-12 mt-3">
                <p>Please confirm the information, and either link to your accounting system or create a new merchant if this is not the correct merchant.</p>
            </div>
        </div>
        <div *ngIf="showInvolvedMerchants()">
            <div class="involved-merchants">
                <span>Included Merchants:</span>
                <ul>
                    <ng-container *ngFor="let client of businessClients; let i = index;">
                        <li class="dashboard-link">
                            <a *ngIf="client.memberAccountId !== currentBusinessClient?.memberAccountId" (click)="changeContext(i)">{{client.dbaName ? client.name + ' (DBA: ' + client.dbaName + ')': client.name}}</a>
                            <span *ngIf="client.memberAccountId === currentBusinessClient?.memberAccountId">{{client.dbaName ? client.name + ' (DBA: ' + client.dbaName + ')': client.name}}</span>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
        <div *ngIf="!isAccountingLinked" class="flex-column">
            <pc-error-message [error]="ACCOUNTING_NOT_LINKED_ERROR" [width]="'full'"></pc-error-message>
        </div>
        <div *ngIf="!verifyBusinessClient" class="d-flex justify-content-end">
            <button class="blue-button" [disabled]="!currentBusinessClient" (click)="next()" type="button">Next</button>
        </div>
        <div *ngIf="verifyBusinessClient" class="d-flex justify-content-end">
            <pc-submit-button [submit]="verifyMerchantLinkage" [text]="'Use Existing Merchant'"></pc-submit-button>
            <button class="blue-button" (click)="createNewMerchant()" type="button">Create New</button>
        </div>
    </div>
    <div *ngIf="createNewBusinessClient">
        <div class="row">
            <div class="col-12">
                <pc-merchant-searcher *ngIf="!currentBusinessClient?.accountingData" [formGroup]="merchantForm" [placeholder]="'Enter Merchant Name'" [formCtrl]="'merchantNameCtrl'"
                    [jurisdiction]="memberAccount.member.jurisdiction" [memberId]="memberAccount.memberId" [includeAddress]="true" (merchantSelected)="setMerchant($event)">
                </pc-merchant-searcher>
                <pc-form-text-input *ngIf="currentBusinessClient?.accountingData" [class]="'full'" [formGroup]="merchantForm" [formCtrl]="'merchantNameCtrl'">
                </pc-form-text-input>
                <div *ngIf="!newMerchant && !existingAccountConfig" class="d-flex justify-content-end">
                    <pc-submit-button [submit]="onCancel" [text]="'Cancel'"></pc-submit-button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-4 col-4" *ngIf="newMerchant || existingAccountConfig">
                <span class="subtitle">Merchant Address Info</span>
                <pc-address-input [addressForm]="merchantMemberAccountForm" [lockCountry]="false"></pc-address-input>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-4" *ngIf="newMerchant || existingAccountConfig">
                <span class="subtitle">Merchant Contact Info</span>
                <pc-email-input [label]="'Contact Email'"
                                [formCtrl]="'ownerEmailCtrl'" [formGroup]="merchantMemberAccountForm">
                </pc-email-input>
                <pc-text-input [label]="'Contact Name'"
                               [formCtrl]="'ownerNameCtrl'" [formGroup]="merchantMemberAccountForm">
                </pc-text-input>
                <pc-phone-input [label]="'Contact Phone Number'"
                                [formCtrl]="'ownerPhoneCtrl'" [formGroup]="merchantMemberAccountForm">
                </pc-phone-input>
                <pc-text-input [label]="'Nature Of Business'"
                               [formCtrl]="'briefDescriptionCtrl'" [formGroup]="merchantMemberAccountForm">
                </pc-text-input>
                <pc-text-input [label]="'Name of License Holder (Tier 1 Only)'" placeholder="Enter license holder, if different from merchant name"
                               [formCtrl]="'licenseHolderCtrl'" [formGroup]="merchantMemberAccountForm">
                </pc-text-input>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-4" *ngIf="(newMerchant && !existingAccountConfig) || (!newMerchant && existingAccountConfig)">
                <div *ngIf="newMerchant">
                    <span class="subtitle">Merchant ACH Info</span>
                    <div>
                        <pc-text-input [label]="'Name of Account Holder'" [placeholder]="'Account Holder'"
                                       [formCtrl]="'achAccountHolderCtrl'" [formGroup]="merchantForm"
                                       (changed)="updateBankAccountValidity($event)">
                        </pc-text-input>
                        <pc-text-input [label]="'Account Number'" [placeholder]="'Account Number - 5 to 17 digits'"
                                       [formCtrl]="'achAccountNumberCtrl'" [formGroup]="merchantForm"
                                       (changed)="updateBankAccountValidity($event)">
                        </pc-text-input>
                        <pc-text-input [label]="'ACH Routing Number'" [placeholder]="'9 digit ACH routing number'"
                                       [formCtrl]="'achRoutingNumberCtrl'" [formGroup]="merchantForm"
                                       (changed)="updateBankAccountValidity($event)">
                        </pc-text-input>
                        <pc-text-input [label]="'Wire Routing Number'" [placeholder]="'9 digit Wire routing number'"
                                       [formCtrl]="'wireRoutingNumberCtrl'" [formGroup]="merchantForm"
                                       (changed)="updateBankAccountValidity($event)">
                        </pc-text-input>
                        <a class="dashboard-list-a" (click)="upload.click()">or upload an invoice with ACH info</a>
                        <input hidden type="file" id="upload" #upload (change)="selectFile($event)" placeholder="Upload file"
                               accept=".pdf,.doc,.docx">
                        <div *ngIf="file">
                            <hr class="separator">
                            <i class="fa fa-times" (click)="deleteDocument()"></i>
                            <a *ngIf="file" class="dashboard-list-a"><i class="fa fa-paperclip"></i>{{file.name}}</a>
                        </div>
                    </div>
                </div>

                <div *ngIf="existingAccountConfig">
                    <span class="subtitle">Merchant Payment Options</span>
                    <div *ngIf="existingAccountConfig && (existingAccountConfig.merchantAccountType === SupportedTransactionType.OUTGOING || existingAccountConfig.merchantAccountType === SupportedTransactionType.BOTH)">
                        Accepts Payments via:
                        <ul>
                            <li *ngIf="existingAccountConfig.defaultOutgoingTransactionType === TransactionType.OUTGOING_ACH_TO_MERCHANT">ACH</li>
                            <li *ngIf="existingAccountConfig.defaultOutgoingTransactionType === TransactionType.OUTGOING_WIRE_TO_MERCHANT">Wire</li>
                            <li *ngIf="existingAccountConfig.defaultOutgoingTransactionType === TransactionType.OUTGOING_CHECK_TO_MERCHANT">Check</li>
                            <li *ngIf="existingAccountConfig.defaultOutgoingTransactionType === TransactionType.OUTGOING_DIGITAL_CHECK_TO_MERCHANT">Digital Check</li>
                        </ul>
                    </div>
                    <div *ngIf="existingAccountConfig && (existingAccountConfig.merchantAccountType === SupportedTransactionType.INCOMING || existingAccountConfig.merchantAccountType === SupportedTransactionType.BOTH)">
                        Pays via:
                        <ul>
                            <li *ngIf="existingAccountConfig.defaultIncomingTransactionType === TransactionType.INCOMING_ACH_FROM_MERCHANT">ACH</li>
                            <li *ngIf="existingAccountConfig.defaultIncomingTransactionType === TransactionType.INCOMING_WIRE_FROM_MERCHANT">Wire</li>
                            <li *ngIf="existingAccountConfig.defaultIncomingTransactionType === TransactionType.INCOMING_CHECK_FROM_MERCHANT">Check</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div *ngIf="merchantAccountExistsError" class="duplicate-account">
                    <pc-error-message [error]="merchantAccountExistsError" [width]="'full'"></pc-error-message>
                </div>
                <div *ngIf="existingAccountConfig || newMerchant" class="d-flex justify-content-end">
                    <pc-submit-button [submit]="onCancel" [text]="'Cancel'"></pc-submit-button>
                    <pc-submit-button *ngIf="!merchantAccountExistsError" [enabled]="isMerchantFormValid" [submit]="onSubmit" [text]="existingAccountConfig ? 'Link Existing Merchant' : 'Submit Merchant for Review'"></pc-submit-button>
                </div>
            </div>
        </div>
    </div>

</div>
