
<div class="dashboard-list">
    <div class="row">
        <div class="col-12">
            <img class="dashboard-icon" src="assets/images/bell-icon.svg" alt="">&nbsp;
            <a [routerLink]="['/alert/list']" [routerLinkActive]="['router-link-active']">Alerts</a>
        </div>
    </div>
    <div class="col-sm-12 mt-3 mb-3 horizontal-scroll mat-table-container">
        <div class="mat-table-spinner" *ngIf="isLoadingResults">
            <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
        </div>
        <table mat-table [dataSource]="alerts">
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> Description </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.type">
                        <a *ngIf="navigatesToMember(element.type) && authService.isAuthority()"
                            [routerLink]="['/administration/member/' + element.memberId]"
                            [routerLinkActive]="['router-link-active']">
                            {{ element.description }}
                        </a>
                        <span *ngIf="navigatesToMember(element.type) && !authService.isAuthority()">
                            {{ element.description }}
                        </span>
                        <a *ngIf="element.type === AlertType.TRANSACTION || element.type === AlertType.CURRENCY_WITHDRAWAL"
                            [routerLink]="['/alert/details/transaction/' + element.id]" [routerLinkActive]="['router-link-active']">
                            {{ element.description }}
                        </a>
                        <span *ngIf="element.type === AlertType.KEY_PARTY">
                            <pc-load-member-details [linkDescription]="element.description" [memberId]="element.memberId" [activeTab]="'key_parties'" [linkType]="'table'"></pc-load-member-details>
                        </span>
                        <a *ngIf="element.type === AlertType.DOCUMENT"
                            [routerLink]="['/administration/member/' + element.memberId]" [queryParams]="{_activeTab: 'documents'}" [routerLinkActive]="['router-link-active']">
                            {{ element.description }}
                        </a>
                        <span *ngIf="element.type === AlertType.LICENSE">
                            <pc-load-member-details [linkDescription]="element.description" [memberId]="element.memberId" [activeTab]="'licenses'" [linkType]="'table'"></pc-load-member-details>
                        </span>
                        <a *ngIf="element.type === AlertType.QUESTION_RESPONSE"
                            [routerLink]="['/administration/member/' + element.memberId]" [queryParams]="{_activeTab: 'questions'}" [routerLinkActive]="['router-link-active']">
                            {{ element.description }}
                        </a>
                        <a *ngIf="element.type === AlertType.MONTHLY_REPORT"
                            [routerLink]="['/administration/member/' + element.memberId]" [queryParams]="{_activeTab: 'reports'}" [routerLinkActive]="['router-link-active']">
                            {{ element.description }}
                        </a>
                        <a *ngIf="element.type === AlertType.SAR_REPORT"
                            [routerLink]="element.source.includes('Fincen Failure Alert') ? ['/reports/authority/list'] : ['/reports/details', element.referencedEntityId]"
                            [queryParams]="element.source.includes('Fincen Failure Alert') ? { reportType: 'SAR', memberId: element.memberId } : {}"
                            [routerLinkActive]="['router-link-active']">
                            {{ element.description }}
                        </a>
                        <a *ngIf="element.type === AlertType.CTR_REPORT"
                            [routerLink]="element.source.includes('Fincen Failure Alert') ? ['/reports/authority/list'] : ['/reports/details', element.referencedEntityId]"
                            [queryParams]="element.source.includes('Fincen Failure Alert') ? { reportType: 'CTR', memberId: element.memberId } : {}"
                            [routerLinkActive]="['router-link-active']">
                            {{ element.description }}
                        </a>
                        <a *ngIf="element.type === AlertType.TRACKING_SYSTEM"
                            [routerLink]="['/administration/member/' + element.memberId]" [queryParams]="{_activeTab: 'metrc'}" [routerLinkActive]="['router-link-active']">
                            {{ element.description }}
                        </a>
                        <a *ngIf="element.type === AlertType.TRANSACTION_FILE_PROCESSING && (element.referencedEntityId || element.jsonData)" [routerLink]="['/alert/details/' + element.id]" [routerLinkActive]="['router-link-active']">
                            {{ element.description }}
                        </a>
                        <div *ngIf="element.type === AlertType.TRANSACTION_FILE_PROCESSING && !(element.referencedEntityId || element.jsonData)">
                            {{ element.description }}
                        </div>
                        <a *ngIf="element.type === AlertType.FINCEN_INVESTIGATION"
                            [routerLink]="['/reports/authority/list/']" [queryParams]="{reportType: 'FINCEN_INVESTIGATIONS'}" [routerLinkActive]="['router-link-active']">
                            {{ element.description }}
                        </a>
                        <a *ngIf="element.type === AlertType.USER"
                            [routerLink]="['/administration/member/' + element.memberId]" [queryParams]="{_activeTab: 'users'}" [routerLinkActive]="['router-link-active']">
                            {{ element.description }}
                        </a>
                        <div *ngIf="element.type === AlertType.MERCHANT && !(element.referencedEntityId || element.jsonData)">
                            {{ element.description }}
                        </div>
                        <a *ngIf="element.type === AlertType.PAYMENT_PROCESSOR"
                            [routerLink]="['/member-accounts/details/' + element.memberAccountId]" [queryParams]="{_transactionTab: 'TRANSACTION_LIST', txnType: 'ACH'}" [routerLinkActive]="['router-link-active']">
                            {{ element.description }}
                        </a>
                    </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="date">
                <th class="centered" mat-header-cell *matHeaderCellDef> Date </th>
                <td class="centered text-nowrap" mat-cell *matCellDef="let element">
                    <span> {{element.created | date:'mediumDate'}} </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th class="centered" mat-header-cell *matHeaderCellDef> Status </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <span> <pc-alerts-badge [parent]="element" ></pc-alerts-badge> </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th class="centered" mat-header-cell *matHeaderCellDef > Action </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <span *ngIf="element.status === AlertStatus.ACTIVE && element.type !== AlertType.TRANSACTION">
                        <i *ngIf="authService.isAuthority()" class="fa fa-times" (click)="reviewAlert(element,$event)"></i>
                     </span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>
