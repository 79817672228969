<div class="col-sm-12 col-md-12 col-lg-12">
    <div class="row mb-3">
        <div class="col-sm-8">
            <span class="form-title">MRB State and Local Licenses</span>
        </div>
        <div class="col-sm-4">
            <button class="blue-button pull-right" *ngIf="authService.isAuthorityOrAdmin() && allowAdd" (click)="onAddLicense()" type="button">
                <i class="fa fa-plus pull-left dashboard-icon"></i><div class="pull-right-button">Add License</div>
            </button>
        </div>
    </div>
    <div class="col-sm-12 horizontal-scroll mat-table-container">
        <div class="mat-table-spinner" *ngIf="isLoadingResults">
            <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
        </div>
        <table mat-table [dataSource]="licenses" matSort [matSortActive]="pageTracking.sort" matSortDisableClear [matSortDirection]="pageTracking.sortDir" multiTemplateDataRows>
            <ng-container matColumnDef="jurisdiction">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Jurisdiction </th>
                <td mat-cell *matCellDef="let element" [ngClass]="element.alertCreated ? 'error': ''"><i *ngIf="element.alertCreated && authService.isAuthority()" class="error fa fa-exclamation-circle" title="Under Alert - Check audit log"></i> {{ element.jurisdiction }} </td>
            </ng-container>
            <ng-container matColumnDef="license_number">
                <th mat-header-cell *matHeaderCellDef> License Number </th>
                <td mat-cell *matCellDef="let element" [ngClass]="element.alertCreated ? 'error': ''"> {{ element.licenseNo }} </td>
            </ng-container>
            <ng-container matColumnDef="license_holder">
                <th mat-header-cell  *matHeaderCellDef> License Holder </th>
                <td mat-cell *matCellDef="let element" [ngClass]="element.alertCreated ? 'error': ''"> {{ element.holderName }} </td>
            </ng-container>
            <ng-container matColumnDef="expiration_date">
                <th class="centered" mat-header-cell *matHeaderCellDef mat-sort-header="expirationDate"> Expiration Date </th>
                <td class="centered" mat-cell *matCellDef="let element" [ngClass]="element.alertCreated ? 'error': ''"> {{ element.expirationDate | date: 'mediumDate' }} </td>
            </ng-container>
            <ng-container matColumnDef="note">
                <th mat-header-cell *matHeaderCellDef> Note </th>
                <td mat-cell *matCellDef="let element">  {{ element.note }} </td>
            </ng-container>
            <ng-container matColumnDef="document">
                <th mat-header-cell *matHeaderCellDef> Document </th>
                <td class="overflow-wrap-any" mat-cell *matCellDef="let element">
                    <a *ngIf="element.document" class="dashboard-list-a"  (click)="downloadFile(element.document)">
                        <i class="fa fa-paperclip"></i>{{element.document.fileName}}
                    </a>
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell class="centered" *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell class="centered" *matCellDef="let element">
                    <pc-license-badge [parent]="element"></pc-license-badge>
                </td>
            </ng-container>
            <ng-container *ngIf="!readOnly" matColumnDef="action">
                <th mat-header-cell class="centered" *matHeaderCellDef> Action </th>
                <td mat-cell  class="text-nowrap centered" *matCellDef="let element">
                    <div *ngIf="!readOnly && authService.isAuthorityOrAdmin() && !isLicenseDisabled(element)">
                        <i class="fa fa-pencil" [title]="'Edit License'" (click)="onEditLicense(element)" *ngIf="authService.isAuthority()"></i>
                        <i class="fa fa-times" [title]="'Disable License'" (click)="disableLicense(element.id)"></i>
                        <i class="fa" *ngIf="element.jurisdiction === 'State Operator' && authService.isAuthority()" [ngClass]="element.msaEnabled ? 'fa-toggle-on' : 'fa-toggle-off'" [title]="element.msaEnabled ? 'Disable MSA' : 'Enable MSA'" (click)="toggleMSA(element)"></i>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="msaLicensee">
                <td mat-cell class="msa-row" colspan="2" *matCellDef="let element">
                    <strong>Licensee:</strong> <span [ngClass]="!element.msaKeyPartyId && element.msaEnabled ? 'error fw-bold' : ''"> {{element.msaKeyPartyName}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="msaDuration">
                <td mat-cell class="msa-row" colspan="2" *matCellDef="let element">
                    <strong>Valid:</strong> {{ element.msaStartDate | date: 'mediumDate' }} to <span [ngClass]="element.msaExpired && element.msaEnabled ? 'error fw-bold' : ''">{{ element.msaExpirationDate | date: 'mediumDate' }} </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="msaDocument">
                <td mat-cell class="msa-row" [colSpan]="3" *matCellDef="let element">
                    <a *ngIf="element.msaDocument" class="dashboard-list-a"  (click)="downloadFile(element.msaDocument)">
                        <i class="fa fa-paperclip"></i>{{element.msaDocument.fileName}}
                    </a>
                </td>
            </ng-container>
            <ng-container matColumnDef="msaAction">
                <td mat-cell class="msa-row centered" *matCellDef="let element">
                    <span *ngIf="!isLicenseDisabled(element) && element.msaEnabled">
                        <i class="fa fa-pencil" [title]="'Edit MSA'" (click)="showMsaModal(element)" *ngIf="authService.isAuthority()"></i>
                    </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="msaScope">
                <td mat-cell class="msa-bottom-row" [colSpan]="readOnly ? 7 : 8"  *matCellDef="let element">
                    <strong>Scope:</strong> {{element.msaScope}}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="isLicenseDisabled(row) ? 'disabled' : ''"></tr>
            <tr mat-row *matRowDef="let row; columns: msaHeaderColumns; when: msaInfoAvailable" [ngClass]="(!row.msaEnabled || isLicenseDisabled(row)) ? 'disabled' : ''"></tr>
            <tr mat-row *matRowDef="let row; columns: msaScopeColumns; when: msaInfoAvailable" [ngClass]="(!row.msaEnabled || isLicenseDisabled(row)) ? 'disabled' : ''"></tr>
        </table>
        <mat-paginator [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
    </div>
    <a #downloadLicenseLink></a>
</div>
