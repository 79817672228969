import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
    Address, AddressService, AuthService, Bill, BusinessCategory, CheckDetails, CheckProcessorService, Configuration, Contract, CurrencyWithdrawal, DataroomService, DocumentType,
    ExternalTransactionReference, Invoice, LabelledPackage, MemberAccount, MemberAccountService, MemberAccountStatus, MemberAccountType, MemberService,
    MemberType, MerchantAccount, MerchantAccountService, MerchantAccountStatus, PagedResponse, PaymentService, ProcessedCheckDetails, Profile, RecordsService, Rfi, RfiService,
    RfiStatus, RiskTier, Role, Shipment, SmartContractService, SupportingDocument, Task, TrackingSystemSupportEvidence, Transaction, TransactionSource, TransactionState,
    TransactionStatus, TransactionSubType, TransactionType, TransactionUtils, Upload, User, UserAccount, UserAccountService, UserAccountStatus, Wallet,
    WalletService, WatchlistHitCount, WatchlistService, WorkflowService
} from 'projects/services/src/public-api';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AuthorityPaymentModalComponent, QrTrackingModalComponent } from 'projects/pt/src/app/components';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay } from '@angular/cdk/overlay';
import {
    CancelTransactionModalComponent,
    ConfirmModalComponent,
    CounterpartySelectModalComponent,
    LoaderComponent,
    RefundModalComponent
} from 'projects/components/src/public-api';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { MatTable } from '@angular/material/table';
import { TableUtils } from 'projects/components/src/lib/table-utils.service';

@Component({
    selector: 'pt-transaction-details',
    templateUrl: './transaction-details.component.html',
    styleUrls: ['./transaction-details.component.scss']
})
export class TransactionDetailsComponent implements OnInit {

    TransactionType = TransactionType;
    TransactionStatus = TransactionStatus;
    TransactionState = TransactionState;
    MemberAccountType = MemberAccountType;
    TransactionSource = TransactionSource;

    transaction: Transaction;
    externalTransactionReference: ExternalTransactionReference;
    dropOffDate: Date;
    dropOffLocation: Address;
    checkDetails: CheckDetails;
    processedCheckDetails: ProcessedCheckDetails;
    externalCheckNumber: string;
    profile: Profile;
    transactionTrackingSupportEvidence: TrackingSystemSupportEvidence;
    taskId: string;
    assignee: string;
    taskLoading = false;
    processInstanceId: string;
    authorityUsers: {userId: string, user: User}[] = [];
    expenses: Bill[] = [];
    invoices: Invoice[] = [];
    contract: Contract;
    shipments: Shipment[] = [];
    rfi: Rfi;
    supportingDocuments: SupportingDocument[] = [];
    isAuthority = false;
    isCancellable = false;
    isStoppable = false;
    stopPaymentFeeAmount = 0.0;
    relatedTransactionDisplayedColumn = ['type', 'amount', 'transaction_date', 'completed_date'];
    documentDisplayedColumn = ['document', 'description', 'date'];
    isHighRiskPayor = false;
    isHighRiskPayee = false;
    isPassThroughScorer = false;
    isPayorFeeAccount = false;
    isPayeeFeeAccount = false;
    isProcessableExternalAuthorizedACHTxn = false;
    isParticipantsActive = false;
    showPossibleManifestsInfo = false;
    overrideNSFDisabledMessage = '';
    activeTab = 'TRANSACTION_DETAILS';
    isScreenableTransaction = false;
    showTraceDetails = false;
    unclearedHitCount = 0;
    isTransactionScreeningCompleted = false;
    notesTrigger = 1;
    isPooledMerchant = false;
    originatorMemberId;
    counterpartyMemberId;
    showRearCheck = false;

    @ViewChild('downloadDocumentLink') downloadDocumentLink: ElementRef;
    @ViewChild('documentsTable') documentTable: MatTable<SupportingDocument>;


    constructor(private route: ActivatedRoute,
                private router: Router,
                public authService: AuthService,
                private paymentService: PaymentService,
                private workflowService: WorkflowService,
                private rfiService: RfiService,
                private addressService: AddressService,
                private notifier: NotificationService,
                private recordsService: RecordsService,
                private dataroomService: DataroomService,
                private smartContractService: SmartContractService,
                private overlay: Overlay,
                private dialog: MatDialog,
                private memberService: MemberService,
                private memberAccountService: MemberAccountService,
                private userAccountService: UserAccountService,
                private walletService: WalletService,
                private watchlistService: WatchlistService,
                private merchantAccountService: MerchantAccountService,
                private checkProcessorService: CheckProcessorService) {}

    ngOnInit() {
        this.init();
    }

    resetDetails() {
        this.rfi = null;
        this.checkDetails = null;
        this.externalCheckNumber = null;
        this.externalTransactionReference = null;
        this.showPossibleManifestsInfo = false;
        this.isPassThroughScorer = false;
        this.isHighRiskPayee = false;
        this.isPayeeFeeAccount = false;
        this.isHighRiskPayor = false;
        this.isPayorFeeAccount = false;
        this.expenses = [];
        this.invoices = [];
        this.transactionTrackingSupportEvidence = null;
        this.isScreenableTransaction = false;
        this.unclearedHitCount = 0;
        this.isStoppable = false;
        this.isPooledMerchant = false;
        this.originatorMemberId = '';
    }

    loadExternalTransactionDetails() {
        this.showTraceDetails = false;
        if (!TransactionUtils.isExternallySubmittedTransaction(this.transaction.transactionType)) {
            return;
        }
        if (!TransactionUtils.isExternalTransactionReferenceAvailable(this.transaction)){
            return;
        }
        this.paymentService.getExternalTransactionReference(this.transaction.id).subscribe({
            next: (response: ExternalTransactionReference) => {
                this.externalTransactionReference = response;
                if (this.externalTransactionReference && this.authService.isAuthorityOrReviewer()) {
                    this.showTraceDetails = true;
                }
            },
            error: (_: any) => {
                this.externalTransactionReference = null;
            }
        });
    }

    navigateToMember(memberId: string) {
        this.memberService.navigateToMember(memberId);
    }

    loadTransactionCancellationStatus() {
        // do not allow cancellation/stopping by anyone other than the originator or authority
        if (this.transaction.originatorId === this.profile.memberId || this.isAuthority) {
            if (this.transaction.transactionType === TransactionType.OUTGOING_DIGITAL_CHECK_TO_MERCHANT || this.transaction.transactionType === TransactionType.OUTGOING_CHECK_TO_MERCHANT) {
                if (this.transaction.status === TransactionStatus.PENDING_PAYMENT_VERIFICATION && this.authService.isAuthorityOrAdmin() && this.transaction.checkId) {
                    if (this.transaction.transactionType === TransactionType.OUTGOING_DIGITAL_CHECK_TO_MERCHANT) {
                        // Digital check after approval state
                        this.isStoppable = true;
                        this.isCancellable = false;
                    } else {
                        // Mailed check after approval state
                        this.paymentService.getCheckCancellationStatus(this.checkDetails.checkId).subscribe((response: boolean) => {
                            this.isCancellable = response;
                            this.isStoppable = !response;
                            if (this.isStoppable) {
                                this.loadCheckStopPaymentFee();
                            }
                        });
                    }
                    if (this.stopPaymentFeeAmount === 0 && this.isStoppable) {
                        this.loadCheckStopPaymentFee();
                    }
                }
                if (this.transaction.status === TransactionStatus.SCHEDULED || this.transaction.status === TransactionStatus.PENDING_RFI_AUTHORITY_REVIEW) {
                    // Cancelling checks before generation check
                    this.isCancellable = true;
                    this.isStoppable = false;
                }
            } else if (this.transaction.transactionType === TransactionType.INCOMING_CHECK_FROM_MERCHANT && this.transaction.status === TransactionStatus.PENDING_RECEIPT_VERIFICATION && !this.isAuthority) {
                this.isCancellable = false;
            } else {
                // Enabling cancel button for rest all of the payments
                this.isCancellable = true;
                this.isStoppable = false;
            }
        }
    }

    loadCheckDetails() {
        if (!this.authService.isRetailer() && this.transaction && (this.transaction.transactionType === TransactionType.OUTGOING_CHECK_TO_MERCHANT || this.transaction.transactionType === TransactionType.OUTGOING_DIGITAL_CHECK_TO_MERCHANT || this.transaction.transactionType === TransactionType.OUTGOING_EXTERNAL_CHECK_TO_MERCHANT)) {
            if (this.transaction.transactionType === TransactionType.OUTGOING_EXTERNAL_CHECK_TO_MERCHANT) {
                this.checkProcessorService.getExternalCheckNumberByTransactionId(this.transaction.id).subscribe((checkNumber: string) => {
                    this.externalCheckNumber = checkNumber;
                });
            } else if (this.transaction.checkId) {
                this.paymentService.getCheckDetailsById(this.transaction.checkId).subscribe((checkDetails: CheckDetails) => {
                    this.checkDetails = checkDetails;
                    if (this.transaction.transactionType === TransactionType.OUTGOING_CHECK_TO_MERCHANT && this.transaction.status === TransactionStatus.PENDING_PAYMENT_VERIFICATION) {
                        this.loadTransactionCancellationStatus();
                    }
                });
            }
        }
    }

    loadProcessedCheckDetails() {
        if (this.authService.isAuthorityOrReviewer() && this.transaction && this.transaction.transactionType === TransactionType.INCOMING_CHECK_FROM_MERCHANT) {
            this.checkProcessorService.getProcessedCheckDetailsByTransactionId(this.transaction.id).subscribe((processedCheckDetails: ProcessedCheckDetails) => {
                this.processedCheckDetails = processedCheckDetails;
            });
        }
    }

    loadCheckStopPaymentFee(){
        this.paymentService.getStopPaymentFeeAmount().subscribe((response: any) => {
            this.stopPaymentFeeAmount = response.stopPaymentFee;
        });
    }

    loadRfi() {
        if (!this.authService.isRetailer()) {
            this.rfiService.findRfiByTransactionId(this.transaction.id).subscribe((rfis: PagedResponse<Rfi>) => {
                if (rfis.content.length > 0) {
                    this.rfi = rfis.content[0];
                    if (this.rfi.status !== RfiStatus.CLOSED) {
                        this.isScreenableTransaction = false;
                    }
                }
            });
        }
    }

    loadDetails() {
        if (this.transaction.transactionType === TransactionType.CURRENCY_WITHDRAWAL) {
            this.loadCurrencyWithdrawal();
        } else if (this.transaction.transactionType === TransactionType.SMART_CONTRACT_PAYMENT) {
            this.loadContract();
        } else if (this.transaction.transactionType === TransactionType.CASH_DEPOSIT ||
                this.transaction.transactionType === TransactionType.CHECK_DEPOSIT ||
                this.transaction.transactionType === TransactionType.INCOMING_ACH_FROM_MERCHANT ||
                this.transaction.transactionType === TransactionType.INCOMING_WIRE_FROM_MERCHANT ||
                this.transaction.transactionType === TransactionType.INCOMING_CHECK_FROM_MERCHANT ||
                this.transaction.transactionType === TransactionType.ACH_DEPOSIT) {
            this.loadInvoices();
            this.loadShipments();
        } else if (this.transaction.transactionType === TransactionType.ACH_REFUND ||
                this.transaction.transactionType === TransactionType.WALLET_REFUND ||
                this.transaction.transactionType === TransactionType.WALLET_TO_ACH_REFUND) {
            return;
        } else {
            this.loadExpenses();
            this.loadShipments();
        }
    }

    loadCurrencyWithdrawal() {
        this.paymentService.getCurrencyWithdrawal(this.transaction?.id).subscribe((currencyWithdrawal: CurrencyWithdrawal) => {
            if (currencyWithdrawal) {
                this.dropOffDate = currencyWithdrawal.dropOffDate;
                this.addressService.loadAddress(currencyWithdrawal.dropOffLocationId).subscribe((address: Address) => {
                    this.dropOffLocation = address;
                });
            }
        });
    }

    loadShipments() {
        if (!this.authService.isRetailer()) {
            this.recordsService.getShipmentsForTransaction(this.transaction.id, 0, 100, 'createdDateTime', 'DESC').subscribe((response: PagedResponse<Shipment>) => {
                this.shipments = response.content;
                this.shipments.forEach((shipment) => {
                    this.recordsService.getPackagesForShipment(shipment.id, 0, 100, 'created', 'DESC').subscribe((packages: PagedResponse<LabelledPackage>) => {
                        shipment.lineItems = packages.content;
                    });
                });
            });
        }
    }

    loadExpenses() {
        let newExpenses: Bill[] = [];
        if (this.transaction.lineItems.length > 0) {
            for (const lineItem of this.transaction.lineItems) {
                if (lineItem.type !== 'OTHER') {
                    this.recordsService.getExpense(lineItem.transactionEntryId).subscribe((bill: Bill) => {
                        newExpenses.push(bill);
                    });
                } else {
                    const bill = new Bill();
                    if (lineItem.allocatedAmount) {
                        bill.paidAmount = lineItem.allocatedAmount.toFixed(2);
                        bill.submittedAmount = bill.paidAmount;
                    }
                    bill.type = lineItem.type;
                    bill.description = lineItem.description;
                    bill.txnDate = lineItem.created;
                    bill.lineItems = [];
                    bill.vendorName = lineItem.memberName;
                    bill.memberAccountId = lineItem.memberAccountId;
                    newExpenses.push(bill);
                }
            }
        }
        this.transaction.expenses = newExpenses;
    }

    loadInvoices() {
        if (this.transaction.lineItems.length > 0) {
            for (const lineItem of this.transaction.lineItems) {
                if (lineItem.type !== 'OTHER') {
                    this.recordsService.getInvoice(lineItem.transactionEntryId).subscribe((invoice: Invoice) => {
                        this.invoices.push(invoice);
                    });
                } else {
                    const invoice = new Invoice();
                    if (lineItem.allocatedAmount) {
                        invoice.paidAmount = lineItem.allocatedAmount.toFixed(2);
                        invoice.submittedAmount = invoice.paidAmount;

                    }
                    invoice.type = lineItem.type;
                    invoice.description = lineItem.description;
                    invoice.txnDate = lineItem.created;
                    invoice.lineItems = [];
                    invoice.customerName = lineItem.memberName;
                    invoice.memberAccountId = lineItem.memberAccountId;
                    this.invoices.push(invoice);
                }
            }
        }
        this.transaction.incomes = this.invoices;
    }

    loadContract() {
        this.smartContractService.getContractByTransactionId(this.transaction.id).subscribe((contract: Contract) => {
            this.contract = contract;
        });
    }

    loadDocuments() {
        let newSupportingDocuments: SupportingDocument[] = [];
        for (const supportingDocument of this.transaction.supportingDocuments) {
            if ((!this.authService.isRetailer()) && ((this.authService.isAuthorityOrReviewer()) || (supportingDocument.memberId === this.profile.memberId) && !supportingDocument.authorityOnly)) {
                if (!supportingDocument.document && (this.transaction.state !== TransactionState.CANCELLED || supportingDocument.documentType !== DocumentType.DIGITAL_CHECK_TO_MERCHANT)) {
                    if (this.authService.isMemberOrMemberReviewer() && this.transaction.transactionType === TransactionType.OUTGOING_EXTERNAL_CHECK_TO_MERCHANT
                        && supportingDocument.documentType === DocumentType.EXTERNAL_CHECK_REAR_IMAGE) {
                        continue;
                    }
                    this.dataroomService.getDocumentById(supportingDocument.documentId).subscribe((upload: Upload) => {
                        supportingDocument.document = upload;
                        newSupportingDocuments.unshift(supportingDocument);
                        newSupportingDocuments.sort((a, b) => {
                            return new Date(a.document.created).getTime() - new Date(b.document.created).getTime();
                        });
                        this.documentTable?.renderRows();
                    });
                }
            }
        }
        if (this.showRearCheck) {
            const supportingDocument = new SupportingDocument();
            const upload = new Upload();
            supportingDocument.created = this.transaction.transactionDate;
            upload.explanation = 'Rear Check PDF';
            supportingDocument.document = upload;
            newSupportingDocuments.push(supportingDocument);
        }
        this.supportingDocuments = newSupportingDocuments;
    }

    loadRearCheck() {
        this.showRearCheck = (this.transaction.checkId && this.transaction.transactionType === TransactionType.OUTGOING_DIGITAL_CHECK_TO_MERCHANT
            && (this.transaction.status === TransactionStatus.PENDING_PAYMENT_VERIFICATION || this.transaction.status === TransactionStatus.COMPLETED));
    }

    openQrTrackingInfo() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'full-modal';
        dialogConfig.data = {
            transaction: this.transaction
        };
        this.dialog.open(QrTrackingModalComponent, dialogConfig);
    }

    viewRfi() {
        if (this.rfi) {
            this.router.navigate(['/rfi/details/', this.rfi.id]);
        }
    }

    onChargeOrCreditPayment(transactionType: TransactionType, header: string) {
        this.memberAccountService.loadMemberAccount(this.transaction.originatorAccountId).subscribe((memberAccount: MemberAccount) => {
            this.walletService.findByMemberAccountId(memberAccount.id).subscribe((wallet: Wallet) => {
                memberAccount.wallet = wallet;
                const dialogConfig: MatDialogConfig = {};
                dialogConfig.autoFocus = true;
                dialogConfig.panelClass = 'normal-modal';
                dialogConfig.data = {
                    header,
                    memberAccount: memberAccount,
                    transactionType: transactionType,
                    transaction: this.transaction
                };
                const dialog = this.dialog.open(AuthorityPaymentModalComponent, dialogConfig);
                dialog?.afterClosed().subscribe((refresh: boolean) => {
                    if (refresh) {
                        this.paymentService.loadTransaction(this.transaction, true);
                    }
                });
            });
        });
    }

    onCancelTransaction(reason: TransactionStatus, notifierMessage?: string) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        const dialog = this.dialog.open(CancelTransactionModalComponent, dialogConfig);
        dialog?.afterClosed().subscribe((cancellationReason: string) => {
            if (cancellationReason) {
                const overlayRef = this.overlay.create({
                    positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                    hasBackdrop: true
                });

                const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
                componentRef.instance.title = 'Canceling Transaction...';
                this.workflowService.cancelTransaction(this.transaction.id, reason, cancellationReason).subscribe(() => {
                    setTimeout(() => {
                        overlayRef.dispose();
                        if (notifierMessage) {
                            this.notifier.showSuccess(notifierMessage);
                        }
                        this.init();
                    }, 500);
                }, (error: any) => {
                    overlayRef.dispose();
                    throw error;
                });
            }
        });
    }

    openRefundTransactionModal() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            transaction: this.transaction
        };
        const dialog = this.dialog.open(RefundModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((refresh: boolean) => {
            if (refresh) {
                this.paymentService.loadTransaction(this.transaction, true);
            }
        });
    }

    openReturnTransactionModal() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            title: 'Transaction Return Confirmation',
            description: 'By clicking \'Confirm Return\' you are confirming that this transaction is successfully returned.',
            confirmText: 'Confirm Return',
            confirmAction: 'accept',
            rejectAction: 'decline'
        };
        const dialog = this.dialog.open(ConfirmModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((userOption: string) => {
            if (userOption === 'accept') {
                const overlayRef = this.overlay.create({
                    positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                    hasBackdrop: true
                });

                const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
                componentRef.instance.title = 'Confirming Transaction Return...';
                this.workflowService.returnConfirmed(this.transaction.id).subscribe(() => {
                    setTimeout(() => {
                        overlayRef.dispose();
                        this.init();
                    }, 500);
                }, (error: any) => {
                    overlayRef.dispose();
                    throw error;
                });
            }
        });
    }

    isCreditablePayment() {
        if (this.transaction && this.transaction.transactionType === TransactionType.WALLET_TO_WALLET_PAYMENT && this.transaction.recipientMemberId === this.transaction.payorMemberId) {
            // transfer to account owned by same member
            return false;
        }
        return this.transaction && (this.transaction.subType === TransactionSubType.FEE || this.transaction.subType === TransactionSubType.PAYMENT || this.transaction.subType === TransactionSubType.WITHDRAWAL) &&
            this.isAuthority && this.transaction.status === TransactionStatus.COMPLETED;
    }

    isChargeablePayment() {
        if (this.transaction && this.transaction.transactionType === TransactionType.WALLET_TO_WALLET_PAYMENT && this.transaction.recipientMemberId === this.transaction.payorMemberId) {
            // transfer to account owned by same member
            return false;
        }
        return this.transaction && (this.transaction.subType === TransactionSubType.DEPOSIT || this.transaction.subType === TransactionSubType.PAYMENT || this.transaction.subType === TransactionSubType.WITHDRAWAL) &&
            this.isAuthority && this.transaction.status === TransactionStatus.COMPLETED;
    }

    isClientCancellablePayment() {
        return this.authService.isAdmin() &&
            (this.transaction.originatorId === this.profile.memberId) &&
            (this.transaction.state === TransactionState.PENDING || this.transaction.state === TransactionState.SCHEDULED) &&
            this.transaction.status !== TransactionStatus.PENDING_RFI_WITH_FUNDS &&
            this.transaction.status !== TransactionStatus.PENDING_RETURN &&
            (this.transaction.transactionType === TransactionType.ACH_DEPOSIT ||
                this.transaction.transactionType === TransactionType.ACH_WITHDRAWAL ||
                this.transaction.transactionType === TransactionType.ACH_TO_WALLET_PAYMENT ||
                this.transaction.transactionType === TransactionType.INCOMING_CHECK_FROM_MERCHANT ||
                this.transaction.transactionType === TransactionType.INCOMING_WIRE_FROM_MERCHANT ||
                this.transaction.transactionType === TransactionType.INCOMING_ACH_FROM_MERCHANT ||
                this.transaction.transactionType === TransactionType.CHECK_DEPOSIT ||
                this.transaction.transactionType === TransactionType.CASH_DEPOSIT ||
                this.transaction.transactionType === TransactionType.WALLET_TO_ACH_PAYMENT ||
                this.transaction.transactionType === TransactionType.WALLET_TO_WALLET_PAYMENT ||
                this.transaction.transactionType === TransactionType.OUTGOING_ACH_TO_MERCHANT ||
                this.transaction.transactionType === TransactionType.WALLET_TO_EXPRESS_ACH_PAYMENT ||
                this.transaction.transactionType === TransactionType.OUTGOING_EXPRESS_ACH_PULL_FROM_MERCHANT ||
                (this.transaction.transactionType === TransactionType.OUTGOING_WIRE_TO_MERCHANT
                    && (this.transaction.status === TransactionStatus.PENDING_RFI_AUTHORITY_REVIEW || this.transaction.state === TransactionState.SCHEDULED
                        || this.transaction.status === TransactionStatus.PENDING_WATCHLIST_REVIEW || this.transaction.status === TransactionStatus.PENDING_WATCHLIST_SCREENING)) ||
                this.transaction.transactionType === TransactionType.OUTGOING_CHECK_TO_MERCHANT ||
                this.transaction.transactionType === TransactionType.OUTGOING_DIGITAL_CHECK_TO_MERCHANT);
    }

    isAuthorityCancellablePayment() {
        return this.isAuthority &&
            (this.transaction.state === TransactionState.PENDING || this.transaction.state === TransactionState.SCHEDULED) &&
            this.transaction.status !== TransactionStatus.PENDING_RFI_WITH_FUNDS &&
            this.transaction.status !== TransactionStatus.PENDING_RETURN &&
            (this.transaction.transactionType === TransactionType.ACH_DEPOSIT ||
                this.transaction.transactionType === TransactionType.ACCOUNT_FEE ||
                this.transaction.transactionType === TransactionType.TRANSACTION_FEE ||
                this.transaction.transactionType === TransactionType.ACH_WITHDRAWAL ||
                this.transaction.transactionType === TransactionType.ACH_TO_WALLET_PAYMENT ||
                this.transaction.transactionType === TransactionType.INCOMING_CHECK_FROM_MERCHANT ||
                this.transaction.transactionType === TransactionType.INCOMING_WIRE_FROM_MERCHANT ||
                this.transaction.transactionType === TransactionType.INCOMING_ACH_FROM_MERCHANT ||
                this.transaction.transactionType === TransactionType.CHECK_DEPOSIT ||
                this.transaction.transactionType === TransactionType.CASH_DEPOSIT ||
                this.transaction.transactionType === TransactionType.CURRENCY_WITHDRAWAL ||
                this.transaction.transactionType === TransactionType.WALLET_TO_ACH_PAYMENT ||
                this.transaction.transactionType === TransactionType.WALLET_TO_WALLET_PAYMENT ||
                this.transaction.transactionType === TransactionType.OUTGOING_ACH_TO_MERCHANT ||
                this.transaction.transactionType === TransactionType.WALLET_TO_EXPRESS_ACH_PAYMENT ||
                this.transaction.transactionType === TransactionType.OUTGOING_EXPRESS_ACH_PULL_FROM_MERCHANT ||
                this.transaction.transactionType === TransactionType.OUTGOING_WIRE_TO_MERCHANT ||
                this.transaction.transactionType === TransactionType.OUTGOING_CHECK_TO_MERCHANT ||
                this.transaction.transactionType === TransactionType.OUTGOING_DIGITAL_CHECK_TO_MERCHANT);
    }

    isRefundablePayment() {
        return this.transaction &&
            (this.transaction.transactionType === TransactionType.WALLET_TO_WALLET_PAYMENT
                || (this.transaction.transactionType === TransactionType.ACH_TO_WALLET_PAYMENT)
                || this.transaction.transactionType === TransactionType.WALLET_TO_ACH_PAYMENT) &&
            ((this.profile.memberId === this.transaction.recipientMemberId &&
            (this.authService.isAdmin() || this.authService.isRetailer())) || this.isAuthority) &&
            this.transaction.recipientMemberId !== this.transaction.payorMemberId &&
            (this.transaction.status === TransactionStatus.COMPLETED || this.transaction.state === TransactionState.PENDING);
    }

    isReturnableTransaction() {
        return this.authService.isAuthority() && this.transaction && this.transaction.status === TransactionStatus.PENDING_RETURN &&
            (this.transaction.transactionType === TransactionType.INCOMING_ACH_FROM_MERCHANT
                || this.transaction.transactionType === TransactionType.INCOMING_WIRE_FROM_MERCHANT
                || this.transaction.transactionType === TransactionType.EXTERNAL_AUTHORIZED_ACH_TO_MERCHANT
                || this.transaction.transactionType === TransactionType.OUTGOING_EXTERNAL_CHECK_TO_MERCHANT);
    }

    openStopPaymentModal() {
        if (this.dialog.openDialogs?.length) {
            return;
        }
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            title: 'Check Stop Payment Confirmation',
            description: 'You have requested to stop payment on this check.<br/><br/><strong>There is a fee associated with this action.</strong><br/><br/>By clicking \'Stop Check Payment\' you are accepting that this fee will be charged to your account.',
            confirmText: 'Stop Check Payment',
            confirmAction: 'accept',
            rejectAction: 'decline',
            labelOneTitle: 'Fee Type',
            labelTwoTitle: 'Fee',
            labelOneDescription: 'Check Stop Payment',
            labelTwoDescription: '$' + this.stopPaymentFeeAmount
        };
        const dialog = this.dialog.open(ConfirmModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((userOption: string) => {
            if (userOption === 'accept') {
                this.onCancelTransaction(this.isAuthority ? TransactionStatus.CANCELLED_BY_AUTHORITY : TransactionStatus.CANCELLED_BY_CLIENT, 'Stop payment successfully submitted.');
            }
        });
    }

    openWatchlistConfirmationModal() {
        if (this.dialog.openDialogs?.length) {
            return;
        }
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            title: 'Complete Watchlist Screening',
            description: 'By clicking <strong>Complete Screening</strong> you are confirming that this transaction screening is completed.',
            confirmText: 'Complete Screening',
            confirmAction: 'accept',
            rejectAction: 'decline'
        };
        const dialog = this.dialog.open(ConfirmModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((userOption: string) => {
            if (userOption === 'accept') {
                const overlayRef = this.overlay.create({
                    positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                    hasBackdrop: true
                });

                const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
                componentRef.instance.title = 'Completing Transaction Screening...';
                this.workflowService.completeScreening(this.transaction.id).subscribe(() => {
                    overlayRef.dispose();
                    this.router.navigate(['/dashboard']);
                }, (error: any) => {
                    overlayRef.dispose();
                    throw error;
                });
            }
        });
    }

    downloadFile(document: any) {
        this.dataroomService.downloadResource(document, this.downloadDocumentLink);
    }

    getTransactionType(transaction: Transaction) {
        if (transaction.source === TransactionSource.RETAIL_TRANSACTION && transaction.subType === TransactionSubType.PAYMENT) {
            return 'Retail Payment';
        }
        return transaction.transactionType;
    }

    loadMemberAccountDetails() {
        if (this.transaction.payorAccountId) {
            this.memberAccountService.loadMemberAccount((this.transaction.payorAccountId)).subscribe((payorAccount: MemberAccount) => {
                this.transaction.payorAccount = payorAccount;
                if (payorAccount.member.tier === RiskTier.TIER_1 || ((payorAccount.member.memberType === MemberType.BUSINESS_MERCHANT || payorAccount.member.memberType === MemberType.INDIVIDUAL_MERCHANT) && payorAccount.member.tier === RiskTier.NOT_TIERED)) {
                    this.isHighRiskPayor = true;
                }
                if (payorAccount.member.memberType === MemberType.FEE_ACCOUNT) {
                    this.isPayorFeeAccount = true;
                }
                if (payorAccount.accountType === MemberAccountType.MERCHANT && payorAccount.member.memberType === MemberType.BUSINESS_MERCHANT && payorAccount.member.businessCategory === BusinessCategory.POOLED_MERCHANT) {
                    this.isPooledMerchant = true;
                }
                if (this.transaction.originatorAccountId === this.transaction.payorAccountId) {
                    this.originatorMemberId = payorAccount.memberId;
                }
                if (this.transaction.originatorAccountId === this.transaction.recipientAccountId) {
                    this.counterpartyMemberId = payorAccount.memberId;
                }
                this.isPossibleManifestsDisplayable();
            });
        }
        if (this.transaction.recipientAccountId) {
            this.memberAccountService.loadMemberAccount((this.transaction.recipientAccountId)).subscribe((recipientAccount: MemberAccount) => {
                this.transaction.recipientAccount = recipientAccount;

                if (recipientAccount.member.tier === RiskTier.TIER_1 || ((recipientAccount.member.memberType === MemberType.BUSINESS_MERCHANT || recipientAccount.member.memberType === MemberType.INDIVIDUAL_MERCHANT) && recipientAccount.member.tier === RiskTier.NOT_TIERED)) {
                    this.isHighRiskPayee = true;
                }
                if (recipientAccount.member.memberType === MemberType.FEE_ACCOUNT) {
                    this.isPayeeFeeAccount = true;
                }
                if (recipientAccount.accountType === MemberAccountType.MERCHANT && recipientAccount.member.memberType === MemberType.BUSINESS_MERCHANT && recipientAccount.member.businessCategory === BusinessCategory.POOLED_MERCHANT) {
                    this.isPooledMerchant = true;
                }
                if (this.transaction.originatorAccountId === this.transaction.recipientAccountId) {
                    this.originatorMemberId = recipientAccount.memberId;
                }
                if (this.transaction.originatorAccountId === this.transaction.payorAccountId) {
                    this.counterpartyMemberId = recipientAccount.memberId;
                }
                this.isPossibleManifestsDisplayable();
            });
        }
    }

    loadTrackingSupportEvidence() {
        this.recordsService.getTransactionTrackingSupportEvidenceByTransactionId(this.transaction.id).subscribe((trackingEvidence: TrackingSystemSupportEvidence) => {
            this.transactionTrackingSupportEvidence = trackingEvidence;
        });
    }

    isPossibleManifestsDisplayable() {
        this.showPossibleManifestsInfo = (this.authService.isAuthorityOrReviewer()) &&
            (this.transaction.subType === TransactionSubType.PAYMENT || this.transaction.subType === TransactionSubType.DEPOSIT) &&
            (this.isHighRiskPayor && this.isHighRiskPayee) &&
            (!this.isPayorFeeAccount && !this.isPayeeFeeAccount) &&
            (this.transaction?.state !== TransactionState.CANCELLED) && (!this.isPassThroughScorer) &&
            (!this.shipments?.length);
    }

    getTabClass(tabOption: string) {
        if (this.activeTab === tabOption) {
            return 'tab-navigation-selected';
        } else {
            return 'tab-navigation';
        }
    }

    setActiveTab(activeTab: string, skipLocationChange?: boolean) {
        if (activeTab !== this.activeTab) {
            this.activeTab = activeTab;
            this.router.navigateByUrl(`/transaction/details/${this.transaction.id}?_activeTab=${activeTab}`, {skipLocationChange});
        }
    }

    handleQueryParamChanges(params: Params) {
        const activeTab = params['_activeTab'] || null;
        if (activeTab && activeTab !== this.activeTab) {
            this.setActiveTab(activeTab, true);
        }
        const currentPage = params['page'] || null;
        const currentPageSize = params['num'] || null;
        const currentSort = params['sort'] || null;
        const currentSortDir = params['dir'] || null;
        const newQueryParams = { page: currentPage, num: currentPageSize, sort: currentSort, dir: currentSortDir };
        TableUtils.updateQueryParams(this.route, this.router, newQueryParams, true);
    }

    processExternalAuthorizedAchToMerchant() {
        const body = {
            variables: {
                status: {
                    value: TransactionStatus.PENDING_TRANSFER,
                    type: 'String'
                }
            }
        };
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });

        const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
        componentRef.instance.title = 'Processing Transaction...';
        this.workflowService.completeTask(this.taskId, body).subscribe(() => {
            setTimeout(() => {
                overlayRef.dispose();
                this.init();
            }, 500);
        }, (error: any) => {
            overlayRef.dispose();
            throw error;
        });
    }

    loadExternalReturnDetails() {
        this.workflowService.getTaskByProcessDefinitionWithVariable(['payment_processing_v2', 'rfi_request_v2', 'payment_processing', 'rfi_request'], 'transactionId', this.transaction.id, false).subscribe((task: Task) => {
            if (task) {
                this.taskId = task.id;
                this.isProcessableExternalAuthorizedACHTxn = !Configuration.getConfig().transactionsExternalReturnsEnabled;
                this.memberAccountService.loadMemberAccount(this.transaction.payorAccountId).subscribe((memberAccount: MemberAccount) => {
                    this.merchantAccountService.getAllMerchantAccountsByMerchantMemberIdAndMemberId(this.transaction.recipientMemberId, this.transaction.payorMemberId).subscribe((merchantAccountResponse: PagedResponse<MerchantAccount>) => {
                        if (merchantAccountResponse.content.length > 0) {
                            const merchantAccount = merchantAccountResponse.content[0];
                            this.isParticipantsActive = this.isProcessableExternalAuthorizedACHTxn && merchantAccount.status === MerchantAccountStatus.ACTIVE
                                && merchantAccount.merchantMemberAccount.status === MemberAccountStatus.ACTIVE
                                && memberAccount.status === MemberAccountStatus.ACTIVE;
                            if (!this.isParticipantsActive) {
                                this.overrideNSFDisabledMessage = 'The member account or merchant is not in an active state';
                            }
                        }
                    });
                });
            }
        });
    }

    loadWatchlistTask() {
        this.taskLoading = true;
        this.workflowService.getTaskByProcessDefinitionWithVariable(['watchlist_review_v2'], 'transactionId', this.transaction.id, true).subscribe((task: Task) => {
            if (task) {
                this.taskId = task.id;
                this.processInstanceId = task.processInstanceId;
                this.assignee = task.variables.assignedAuthority;
                this.taskLoading = false;
            } else {
                this.taskLoading = false;
            }
        });
    }

    loadAuthorityUsers() {
        this.userAccountService.loadAccountsByMemberIdAndStatusesAndRoles(this.profile.memberId, [UserAccountStatus.ACTIVE], [Role.AUTHORITY, Role.AUTHORITY_SUPERUSER], 0, 1000, 'user.lastName', 'ASC').subscribe((response: PagedResponse<UserAccount>) => {
            this.authorityUsers = response.content.map((userAccount: UserAccount) => {
                return {
                    userId: userAccount.userId,
                    user: userAccount.user
                };
            });
        });
    }

    showAccountInfo(memberAccount: MemberAccount) {
        if (!memberAccount || this.authService.isRetailer(memberAccount)) {
            return false;
        }
        if (this.authService.isAuthorityOrReviewer()) {
            return memberAccount && memberAccount.accountType === MemberAccountType.BUSINESS;
        }
        return (memberAccount?.memberId === this.profile.memberId) && memberAccount.accountType === MemberAccountType.BUSINESS;
    }

    getAccountTab() {
        return this.authService.isAuthorityOrReviewer() ? 'ACCOUNT_LIMITS' : 'TRANSACTION_LIST';
    }

    loadWatchlistClearedStatus() {
        this.watchlistService.getWatchlistHitCount(this.transaction.id).subscribe((hitCount: WatchlistHitCount) => {
            this.unclearedHitCount = hitCount.pendingCount + hitCount.matchedCount;
            if (this.transaction.status === TransactionStatus.PENDING_WATCHLIST_REVIEW && hitCount.pendingCount === 0) {
                this.isTransactionScreeningCompleted = true;
            }
        });
    }

    onNoteAdded(noteAdded: boolean) {
        this.notesTrigger = Math.random();
    }

    init() {
        this.profile = this.authService.getProfile();
        this.isAuthority = this.authService.isAuthority();
        const queryParams = this.route.snapshot.queryParams;
        const activeTab = queryParams['_activeTab'];
        if (activeTab && activeTab !== this.activeTab) {
            this.activeTab = activeTab;
        }
        this.route.queryParams.subscribe((params) => {
            this.handleQueryParamChanges(params);
        });
        this.route.params.subscribe((params) => {
            this.resetDetails();
            if (this.transaction && this.transaction.id !== params['transactionId']) {
                this.activeTab = 'TRANSACTION_DETAILS';
            }
            this.transaction = null;
            this.paymentService.getTransaction(params['transactionId']).subscribe((transaction: Transaction) => {
                this.taskId = null;
                this.processInstanceId = null;
                this.transaction = transaction;
                if (this.authService.isAuthorityOrReviewer() && transaction.state !== TransactionState.SCHEDULED) {
                    if (transaction.transactionType === TransactionType.OUTGOING_WIRE_TO_MERCHANT || transaction.transactionType === TransactionType.OUTGOING_ACH_TO_MERCHANT
                        || transaction.transactionType === TransactionType.WALLET_TO_EXPRESS_ACH_PAYMENT || transaction.transactionType === TransactionType.WALLET_TO_ACH_PAYMENT
                        || transaction.transactionType === TransactionType.ACH_TO_WALLET_PAYMENT || transaction.transactionType === TransactionType.ACH_WITHDRAWAL
                        || transaction.transactionType === TransactionType.ACH_REFUND || transaction.transactionType === TransactionType.WALLET_TO_ACH_REFUND || transaction.transactionType === TransactionType.ACH_DEPOSIT
                        || transaction.transactionType === TransactionType.OUTGOING_EXPRESS_ACH_PULL_FROM_MERCHANT) {
                        this.isScreenableTransaction = true;
                        this.loadWatchlistClearedStatus();
                    }
                }
                this.paymentService.loadTransaction(this.transaction, true);
                this.loadCheckDetails();
                this.loadProcessedCheckDetails();
                this.loadDetails();
                this.loadRearCheck();
                this.loadDocuments();
                this.loadRfi();
                this.loadMemberAccountDetails();
                this.isPassThroughScorer = (transaction.subType !== TransactionSubType.PAYMENT) && (transaction.subType !== TransactionSubType.WITHDRAWAL) && (transaction.subType !== TransactionSubType.DEPOSIT);
                this.isPossibleManifestsDisplayable();
                if (!(this.transaction.transactionType === TransactionType.OUTGOING_CHECK_TO_MERCHANT && this.transaction.status === TransactionStatus.PENDING_PAYMENT_VERIFICATION)){
                    // for OUTGOING_CHECK_TO_MERCHANT this will be called under loadCheckDetails , as check id is needed
                    this.loadTransactionCancellationStatus();
                }
                this.loadExternalTransactionDetails();

                const isNSFTransaction = transaction.scores.some((score) => {
                    return score.pointChainReasonCodes.includes('INSUFFICIENT_FUNDS');
                });
                if (isNSFTransaction && this.transaction.transactionType === TransactionType.EXTERNAL_AUTHORIZED_ACH_TO_MERCHANT && this.transaction.status === TransactionStatus.PENDING_RETURN && this.isAuthority) {
                    this.loadExternalReturnDetails();
                } else {
                    this.isProcessableExternalAuthorizedACHTxn = false;
                }
                if (this.authService.isAuthorityOrReviewer() && (this.transaction.subType === TransactionSubType.DEPOSIT || this.transaction.subType === TransactionSubType.PAYMENT)) {
                    this.loadTrackingSupportEvidence();
                }
                if (this.isScreenableTransaction && this.authService.isAuthority()) {
                    this.loadWatchlistTask();
                    this.loadAuthorityUsers();
                }
            });
        });
    }

    setCounterparty() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.data = {
            transaction: this.transaction,
            originatorMemberId: this.originatorMemberId,
            counterpartyMemberId: this.counterpartyMemberId,
            ultimateCounterpartyMemberAccountId: this.transaction.relatedPartyAccountId,
            ultimateCounterpartyMemberName: this.transaction.relatedPartyMemberName
        };
        const dialog = this.dialog.open(CounterpartySelectModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((refresh) => {
            if (refresh) {
                this.rfi = null;
                this.transaction = null;
                this.init();
            }
        });
    }
}
