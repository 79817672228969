<div class="row mt-2">
    <div class="col-12 dashboard-header">
        <span>Users</span>
        <button *ngIf="displayAddUserButton()" class="blue-button pull-right" (click)="onLinkUser()" type="button">
            <i class="fa fa-plus pull-left dashboard-icon"></i><div class="pull-right-button">Link User</div>
        </button>
    </div>
</div>
<div class="col-sm-12 horizontal-scroll mat-table-container mt-3">
    <div class="mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <table mat-table [dataSource]="userAccounts" matSort [matSortActive]="pageTracking.sort" matSortDisableClear [matSortDirection]="pageTracking.sortDir">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.user">
                    <strong *ngIf="element.user.firstName">{{element.user.firstName}}</strong>
                    <strong *ngIf="element.user.lastName"> {{element.user.lastName}}</strong>
                    <span *ngIf="element.user.mobilePhone">
                        <br>
                        {{element.user.mobilePhone | phone }}
                    </span>
                    <span *ngIf="element.user.email">
                        <br>
                        {{element.user.email}}
                    </span>
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th class="centered" mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
            <td class="centered" mat-cell *matCellDef="let element">
                <pc-account-badge [parent]="element"></pc-account-badge>
            </td>
        </ng-container>
        <ng-container matColumnDef="key_party">
            <th mat-header-cell class="centered" *matHeaderCellDef> Key Party Linked </th>
            <td mat-cell class="centered" *matCellDef="let element">
                <span *ngIf="element.isKeyParty">Yes</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="role">
            <th mat-header-cell class="centered" *matHeaderCellDef> Role </th>
            <td mat-cell class="centered" *matCellDef="let element">
                <div *ngIf="(isAuthorityOrCorporateAdmin() || !currentUser(element)) && element.status !== UserAccountStatus.INVITED
                        && element.status !== UserAccountStatus.PENDING_REGISTRATION && !readOnly">
                    <select type="text" [(ngModel)]="element.role"
                            (change)="onRoleChanged(element)">
                        <option value="ADMIN" *ngIf="(isAuthorityOrAdmin() && element.isKeyParty) || element.role === Role.ADMIN">Administrator</option>
                        <option value="MEMBER">Member</option>
                        <option value="MEMBER_READONLY">Reviewer</option>
                        <option value="RETAILER" *ngIf="isPartner() || element.role === Role.RETAILER">Retailer</option>
                    </select>
                </div>
                <div *ngIf="((element.user && currentUser(element) && !isAuthorityOrCorporateAdmin()) || (element.status !== UserAccountStatus.ACTIVE && element.status !== UserAccountStatus.DISABLED)) || readOnly">
                    {{element.role | translate}}
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell class="centered" *matHeaderCellDef> Manage </th>
            <td mat-cell  class="action centered" *matCellDef="let element">
                <i *ngIf="!currentUser(element) && isAuthorityOrAdmin() && element.status !== UserAccountStatus.INVITED && element.status !== UserAccountStatus.PENDING_REGISTRATION && !(!isPartner() && element.role === Role.RETAILER)" class="fa fa-2x" [class.fa-user]="isAccountEnabled(element)" [class.fa-user-o]="!isAccountEnabled(element)" (click)="toggleEnabled($event, element)" [title]="isAccountEnabled(element) ? 'Disable User' : 'Enable User'"></i>
                <i *ngIf="!currentUser(element) && isAuthorityOrCorporateAdmin() && element.status === UserAccountStatus.DISABLED" class="fa fa-2x fa-times" (click)="deleteAccess(element)" title="Delete Access"></i>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="!isAccountEnabled(row) ? 'disabled' : ''"></tr>
    </table>
    <mat-paginator class="mat-paginator" [pageIndex]="paginator.pageIndex" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions"
        [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
</div>
