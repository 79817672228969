import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    AccountingLinkedMember, AccountingService, Address, AddressService, AuthService, BankAccount, BankAccountService, CodatSettings, CodatSettingsFormService, LinkedBankAccount,
    MemberAccount, MemberAccountService, MemberAccountType, MemberRegistrationType, MemberType, NameChangeType, PagedResponse, ReconciliationAccount, Role
} from 'projects/services/src/public-api';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ComponentPortal } from '@angular/cdk/portal';
import { LoaderComponent } from 'projects/components/src/public-api';
import { Overlay } from '@angular/cdk/overlay';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NameChangeModalComponent, LinkedReconciliationAccountModalComponent } from 'projects/components/src/lib/modal';


@Component({
    selector: 'pt-member-account-settings',
    templateUrl: './member-account-settings.component.html',
    styleUrls: ['./member-account-settings.component.scss']
})
export class MemberAccountSettingsComponent implements OnInit {

    MemberAccountType = MemberAccountType;
    Role = Role;
    MemberType = MemberType;
    MemberRegistrationType = MemberRegistrationType;

    @Input() memberAccount: MemberAccount;
    @Input() readOnly = false;

    @Output() memberAccountChanged: EventEmitter<MemberAccount> = new EventEmitter<MemberAccount>();

    memberAccountForm: UntypedFormGroup;

    bankAccountForm: UntypedFormGroup;
    bankAccounts: BankAccount[] = [];

    addressForm: UntypedFormGroup;
    addresses: Address[] = [];

    accountingConnected = false;
    accountingSystemDetails : AccountingLinkedMember;
    codatSettingsForm: UntypedFormGroup;
    accounts: ReconciliationAccount[] = [];
    confiaAccount: ReconciliationAccount;
    pushSettingsAvailable = false;
    accountsLoading = false;
    isRequestingResults = true;

    constructor(public authService: AuthService,
                private formBuilder: UntypedFormBuilder,
                private bankAccountService: BankAccountService,
                private memberAccountService: MemberAccountService,
                private addressService: AddressService,
                private accountingService: AccountingService,
                private codatSettingsFormService: CodatSettingsFormService,
                private notifier: NotificationService,
                private dialog: MatDialog,
                private overlay: Overlay) {}

    ngOnInit() {
        this.updateMemberAccountName = this.updateMemberAccountName.bind(this);
        this.updateMemberAccountFriendlyName = this.updateMemberAccountFriendlyName.bind(this);
        this.updateAccountSignatory = this.updateAccountSignatory.bind(this);

        this.initForm();
        this.loadLinkedBankAccount();
        this.loadAddresses();
        this.bankAccountService.getAllActiveBankAccounts(this.memberAccount.memberId).subscribe((bankAccounts: PagedResponse<BankAccount>) => {
            this.bankAccounts = bankAccounts.content;
        });
        if (this.memberAccount.accountType === MemberAccountType.BUSINESS) {
            this.accountingService.getAccountingSystemInfo(this.memberAccount.memberId).subscribe((accountingSystemDetails: AccountingLinkedMember) => {
                this.accountsLoading =  true;
                this.accountingSystemDetails = accountingSystemDetails;
                if (accountingSystemDetails?.linked) {
                    this.accountingConnected = true;
                    // successful login
                    this.pushSettingsAvailable = !!this.accountingService.getSupportedPushPlatforms().find((supportedPlatform) => {
                        return accountingSystemDetails.platform?.toLowerCase().indexOf(supportedPlatform.toLowerCase()) > -1;
                    });
                    if ((this.authService.isAdmin() || this.authService.isAuthorityOrReviewer()) && this.pushSettingsAvailable) {
                        this.accountsLoading = true;
                        this.accountingService.getCodatSettings(this.memberAccount.memberId, this.memberAccount.id).subscribe((codatSettings: CodatSettings) => {
                            this.codatSettingsForm = this.codatSettingsFormService.initializeForm(codatSettings);
                            if (!codatSettings.reconciliationAccountId) {
                                this.codatSettingsForm.get('invoicePushCtrl').setValue(false);
                                this.codatSettingsForm.get('invoicePushCtrl').disable();
                                this.codatSettingsForm.get('billPushCtrl').setValue(false);
                                this.codatSettingsForm.get('billPushCtrl').disable();
                            }
                            if (this.authService.isAuthorityOrReviewer()) {
                                this.codatSettingsForm.disable();
                            }
                            this.accountsLoading = false;
                            this.fetchAccounts();
                        });
                    }
                }
            });
        }
    }

    initForm() {
        this.memberAccountForm = this.formBuilder.group({
            accountNameCtrl: new UntypedFormControl(this.memberAccount.accountName),
            accountFriendlyNameCtrl: new UntypedFormControl(this.memberAccount.accountFriendlyName),
            accountSignatoryCtrl: new UntypedFormControl(this.memberAccount.signatory),
            isPublicCtrl: new UntypedFormControl(this.memberAccount.isPublic)
        });
        if (this.readOnly) {
            this.memberAccountForm.disable();
        } else {
            this.memberAccountForm.controls['accountNameCtrl'].disable();
            this.memberAccountForm.controls['accountFriendlyNameCtrl'].disable();
            this.memberAccountForm.controls['accountSignatoryCtrl'].disable();
        }
    }

    loadLinkedBankAccount() {
        this.bankAccountService.getLinkedBankAccount(this.memberAccount.memberId, this.memberAccount.id).subscribe((linkedBankAccount: LinkedBankAccount) => {
            this.memberAccount.linkedBankAccount = linkedBankAccount;
            this.bankAccountForm = this.formBuilder.group({
                bankAccountIdCtrl: new UntypedFormControl(this.memberAccount.linkedBankAccount?.bankAccountId)
            });
            if (this.readOnly) {
                this.bankAccountForm.disable();
            }
        });
    }

    loadAddresses() {
        this.addressService.getMainAndOperationsAddresses(this.memberAccount.memberId).subscribe((locations: PagedResponse<Address>) => {
            this.addresses = locations.content;
            this.addressForm = this.formBuilder.group({
                addressIdCtrl: new UntypedFormControl(this.memberAccount.addressId)
            });
            if (this.readOnly) {
                this.addressForm.disable();
            }
        });
    }

    onSelectBankAccount(bankAccount: BankAccount) {
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
        if (bankAccount.id && this.isRequestingResults) {
            this.isRequestingResults = false;
            componentRef.instance.title = 'Linking bank account...';
            const linkedAccount = {
                memberId: this.memberAccount.memberId,
                memberAccountId: this.memberAccount.id,
                bankAccountId: bankAccount.id
            };
            this.bankAccountService.linkBankAccount(linkedAccount).subscribe((linkedAccount: LinkedBankAccount) => {
                overlayRef.dispose();
                this.isRequestingResults = true;
                this.memberAccount.linkedBankAccount = linkedAccount;
                this.notifier.showSuccess(`Bank account ${bankAccount.name} successfully linked.`);
            });
        } else if (this.memberAccount.linkedBankAccount && this.isRequestingResults) {
            this.isRequestingResults = false;
            componentRef.instance.title = 'Unlinking bank account...';
            this.bankAccountService.unlinkBankAccount(this.memberAccount.memberId, this.memberAccount.id).subscribe(() => {
                overlayRef.dispose();
                this.isRequestingResults = true;
                this.memberAccount.linkedBankAccount = null;
                this.notifier.showSuccess('Bank account unlinked.');
            });
        } else {
            overlayRef.dispose();
        }
    }

    onSelectAddress(event: any) {
        const addressId = event.target.value;

        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
        componentRef.instance.title = 'Linking address...';
        this.memberAccountService.updateMemberAccount(this.memberAccount.id, {addressId}).subscribe(() => {
            overlayRef.dispose();
            this.memberAccount.addressId = addressId;
            this.notifier.showSuccess('Address successfully linked.');
        });
    }

    fetchAccounts() {
        this.accountsLoading = true;
        this.accountingService.fetchAllAccountsByMemberId(this.memberAccount.memberId).subscribe((accounts: ReconciliationAccount[]) => {
            this.accounts = accounts;
            this.confiaAccount = this.accounts.find((account: ReconciliationAccount) => {
                return Number(account.nominalCode) === this.memberAccount.accountNumber;
            });
            const nullAccount = new ReconciliationAccount();
            nullAccount.id = '';
            nullAccount.name = '- None -';
            this.accounts.unshift(nullAccount);
            this.accountsLoading = false;
        }, (_error: any) => {
            this.accountsLoading = false;
            throw new Error('Unable to retrieve accounts at this time.  Please try again later.');
        });
    }

    onAccountChange(accountId: string) {
        if (accountId) {
            this.codatSettingsForm.get('invoicePushCtrl').enable();
            this.codatSettingsForm.get('billPushCtrl').enable();
            this.updateCodatSettings();
        } else {
            this.codatSettingsForm.get('invoicePushCtrl').setValue(false);
            this.codatSettingsForm.get('invoicePushCtrl').disable();
            this.codatSettingsForm.get('billPushCtrl').setValue(false);
            this.codatSettingsForm.get('billPushCtrl').disable();
            this.updateCodatSettings();
        }
    }

    updateCodatSettings() {
        this.accountingService.updateCodatSettings(this.memberAccount.memberId, this.memberAccount.id, this.codatSettingsFormService.getCodatSettings(this.codatSettingsForm)).subscribe((response: CodatSettings) => {
            this.codatSettingsFormService.initializeForm(response);
        });
    }

    onCreateReconcilationAccount() {
        if (this.confiaAccount) {
            return;
        }
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.data = {
            memberAccount: this.memberAccount
        };
        const dialog = this.dialog.open(LinkedReconciliationAccountModalComponent, dialogConfig);
        dialog?.afterClosed().subscribe((name: string) => {
            if (name) {
                const overlayRef = this.overlay.create({
                    positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                    hasBackdrop: true
                });
                const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
                componentRef.instance.title = 'Creating account...';

                const accountRequest = {
                    accountName: name
                };
                this.accountingService.createReconciliationAccount(this.memberAccount.memberId, this.memberAccount.id, accountRequest).subscribe((_result: any) => {
                    this.accountsLoading = true;
                    overlayRef.dispose();
                    this.notifier.showSuccessCloseRequired('<p>Your request to create an account has been submitted. <strong>This may take up to ten minutes.</strong></p><p>If you do not see your account in the list of accounts, please wait until the accounts have synced.</p>');
                    this.fetchAccounts();
                }, (error: any) => {
                    overlayRef.dispose();
                    throw error;
                });
            }
        });
    }

    updateMemberAccountName() {
        let currentName: string;
        if (this.memberAccountForm.get('accountNameCtrl') && this.memberAccountForm.get('accountNameCtrl').value) {
            currentName = this.memberAccountForm.get('accountNameCtrl').value;
        }
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            title: 'Change Account Name',
            currentName,
            id: this.memberAccount.id,
            type: NameChangeType.ACCOUNT_NAME
        };
        const dialog = this.dialog.open(NameChangeModalComponent, dialogConfig);
        dialog?.afterClosed().subscribe((result: any) => {
            if (result) {
                this.memberAccount = result;
                this.initForm();
                this.memberAccountChanged.emit(result);
            }
        });
    }

    updateMemberAccountFriendlyName() {
        let currentName: string;
        if (this.memberAccountForm.get('accountFriendlyNameCtrl') && this.memberAccountForm.get('accountFriendlyNameCtrl').value) {
            currentName = this.memberAccountForm.get('accountFriendlyNameCtrl').value;
        }
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            title: 'Change Friendly Account Name',
            currentName,
            id: this.memberAccount.id,
            type: NameChangeType.ACCOUNT_FRIENDLY_NAME
        };
        const dialog = this.dialog.open(NameChangeModalComponent, dialogConfig);
        dialog?.afterClosed().subscribe((result: any) => {
            if (result) {
                this.memberAccount = result;
                this.initForm();
                this.memberAccountChanged.emit(result);
            }
        });
    }

    updateAccountSignatory() {
        let currentName: string;
        if (this.memberAccountForm.get('accountSignatoryCtrl') && this.memberAccountForm.get('accountSignatoryCtrl').value) {
            currentName = this.memberAccountForm.get('accountSignatoryCtrl').value;
        }
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            title: 'Change Account Signatory',
            currentName,
            id: this.memberAccount.id,
            type: NameChangeType.ACCOUNT_SIGNATORY_CHANGE
        };
        const dialog = this.dialog.open(NameChangeModalComponent, dialogConfig);
        dialog?.afterClosed().subscribe((result: any) => {
            if (result) {
                this.memberAccount = result;
                this.initForm();
                this.memberAccountChanged.emit(result);
            }
        });
    }

    onPublicAccountChange() {
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
        componentRef.instance.title = 'Updating visibility.  Please wait...';

        const body = {
            isPublic: this.memberAccountForm.controls['isPublicCtrl'].value
        };

        this.memberAccountService.updateMemberAccount(this.memberAccount.id, body).subscribe((memberAccount: MemberAccount) => {
            this.memberAccount = memberAccount;
            overlayRef.dispose();
            this.initForm();
            this.memberAccountChanged.emit(memberAccount);
        });
    }
}
