import { ChangeDetectorRef, EventEmitter, Injectable } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { ActivatedRoute, NavigationStart, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TableUtils {

    private static readonly DEFAULT_TABLE_PAGE_OPTIONS = [ 10, 25, 50, 100 ];

    private static subscriptions: Subscription[] = [];
    private static url: string;
    private static lastQueryParams: Params;
    private static lastPageParams: Params;
    private static defaultSort: string;
    private static defaultSortDir: 'asc' | 'desc';

    static initializeTableValues(route: ActivatedRoute, router: Router, defaultSort?: string, defaultSortDir?: 'asc' | 'desc', defaultPageSize?: number) {
        this.clearSubscriptions();
        const queryParams = route.snapshot.queryParams;
        const pageTracking = new PageTracking();

        if (queryParams['page']) {
            pageTracking.page = Number(queryParams['page']);
        } else {
            pageTracking.page = 1;
        }

        if (queryParams['num']) {
            pageTracking.pageSize = Number(queryParams['num']);
        } else if (defaultPageSize) {
            pageTracking.pageSize = defaultPageSize;
        } else {
            pageTracking.pageSize = TableUtils.DEFAULT_TABLE_PAGE_OPTIONS[1];
        }

        if (queryParams['sort']) {
            pageTracking.sort = queryParams['sort'];
        } else if (defaultSort) {
            pageTracking.sort = defaultSort;
            this.defaultSort = defaultSort;
        }

        if (queryParams['dir']) {
            if (queryParams['dir'] !== 'asc' && queryParams['dir'] !== 'desc') {
                pageTracking.sortDir = 'asc';
            } else {
                pageTracking.sortDir = queryParams['dir'];
            }
        } else if (defaultSortDir) {
            pageTracking.sortDir = defaultSortDir;
            this.defaultSortDir = defaultSortDir;
        }

        pageTracking.pageSizeOptions = TableUtils.DEFAULT_TABLE_PAGE_OPTIONS;

        return pageTracking;
    }

    static initializePaginatorAndSort(route: ActivatedRoute, router: Router, cdr: ChangeDetectorRef, pageTracking: PageTracking, paginator: MatPaginator, sort?: MatSort, filterEvent?: EventEmitter<any>) {
        // Keep track of our current URL without any query parameters
        this.url = route.snapshot['_routerState'].url.split('?')[0];

        // Watch for navigation events to handle route changes
        this.subscriptions.push(router.events.subscribe((data: any) => {
            if (data instanceof NavigationStart) {
                const urlRoot = data.url.split('?')[0];
                const queryParams = data.url.split('?')[1];
                if (urlRoot !== this.url && queryParams) {
                    this.clearSubscriptions();
                    // Set the current url params to last query and page params to track tab changes.
                    const currentParams = this.parseQueryParams(queryParams);
                    this.lastQueryParams = this.getQueryParams(currentParams);
                    this.lastPageParams = this.getPageParams(currentParams);
                } else {
                    // set the last query and page params of the previous url.
                    this.lastQueryParams = this.getQueryParams(route.snapshot.queryParams);
                    this.lastPageParams = this.getPageParams(route.snapshot.queryParams);
                }
            }
        }));

        // Listen for when the user changes the sort order
        if (sort) {
            this.subscriptions.push(sort.sortChange.subscribe((data: any) => {
                if (data.active !== pageTracking.sort || data.direction !== pageTracking.sortDir) {
                    // Update the URL to reflect the new sort settings
                    router.navigate([], {
                        relativeTo: route,
                        queryParams: { page: paginator.pageIndex + 1, num: paginator.pageSize, sort: data.active, dir: data.direction },
                        queryParamsHandling: 'merge'
                    });
                }
            }));
        }

        // Listen for pagination changes
        this.subscriptions.push(paginator.page.subscribe((data: any) => {
            if (data.pageIndex !== (pageTracking.page - 1) || data.pageSize !== pageTracking.pageSize) {
                // Update the URL when the user changes pages or page size
                router.navigate([], {
                    relativeTo: route,
                    queryParams: { page: data.pageIndex + 1, num: data.pageSize, sort: sort.active, dir: sort.direction },
                    queryParamsHandling: 'merge'
                });
            }
        }));

        // Watch for any changes to the URL parameters
        this.subscriptions.push(route.queryParams.subscribe((params: Params) => {
            if (Object.keys(params).length === 0) {
                return;
            }

            // Check if any non-pagination parameters have changed
            let queryUpdated = false;
            const queryParams = this.getQueryParams(params);
            if (this.lastQueryParams) {
                // Remove any parameters that haven't changed
                for (const queryParam in queryParams) {
                    // eslint-disable-next-line no-undefined
                    if (this.lastQueryParams[queryParam] !== undefined && (queryParams[queryParam] === this.lastQueryParams[queryParam])) {
                        delete queryParams[queryParam];
                    }
                }
                queryUpdated = Object.keys(queryParams).length > 0;
                // Special handling for tab changes
                for (const param in queryParams) {
                    if (param.startsWith('_') && this.lastQueryParams[param]) {
                        if (queryParams[param] !== this.lastQueryParams[param]) {
                            // this is a tab change, so we can blow away any page params as they are not valid if we switch tabs
                            queryUpdated = true;
                            this.lastPageParams = queryParams;
                            return;
                        } else {
                            queryUpdated = false;
                        }
                    }
                }
            } else {
                this.lastQueryParams = queryParams;
            }

            // Check if any pagination-related parameters have changed
            let pageUpdated = false;
            const pageParams = this.getPageParams(params);
            if (this.lastPageParams) {
                // Remove any pagination parameters that haven't changed
                for (const pageParam in pageParams) {
                    // eslint-disable-next-line no-undefined
                    if (this.lastPageParams && this.lastPageParams[pageParam] !== undefined && (pageParams[pageParam] === this.lastPageParams[pageParam])) {
                        delete pageParams[pageParam];
                    }
                }
                pageUpdated = Object.keys(pageParams).length > 0;
            } else {
                this.lastPageParams = pageParams;
            }

            if (sort) {
                const sortDirParam = params['dir'];
                if (sortDirParam && sortDirParam !== 'asc' && sortDirParam !== 'desc') {
                    return;
                }
            }

            // Update our page tracking with the latest URL parameters
            const pageNumParam = params['page'];
            const pageSizeParam = params['num'];

            if (pageNumParam) {
                pageTracking.page = Number(pageNumParam);
            } else {
                pageTracking.page = 1;
            }

            if (pageSizeParam) {
                pageTracking.pageSize = Number(pageSizeParam);
            } else {
                pageTracking.pageSize = TableUtils.DEFAULT_TABLE_PAGE_OPTIONS[1];
            }

            let pageChange = false;
            let sortChange = false;

            if (sort) {
                const sortParam = params['sort'];
                const sortDirParam = params['dir'];

                if (sortParam && sortParam !== pageTracking.sort) {
                    pageTracking.sort = sortParam;
                    sortChange = true;
                } else if (!sortParam) {
                    pageTracking.sort = this.defaultSort;
                    sortChange = true;
                }

                if (sortDirParam && sortDirParam !== pageTracking.sortDir) {
                    pageTracking.sortDir = sortDirParam;
                    sortChange = true;
                } else if (!sortDirParam) {
                    pageTracking.sortDir = this.defaultSortDir;
                    sortChange = true;
                }

                if (sortChange) {
                    sort.active = pageTracking.sort;
                    sort.direction = pageTracking.sortDir;
                }
            }

            // Update the paginator if the page or page size has changed
            if (paginator.pageIndex !== pageTracking.page - 1) {
                paginator.pageIndex = pageTracking.page - 1;
                pageChange = true;
            }

            if (paginator.pageSize !== pageTracking.pageSize) {
                paginator.pageSize = pageTracking.pageSize;
                pageChange = true;
            }

            // Let other components know about any changes we've made
            if (pageUpdated && (pageChange || sortChange)) {
                const event = new PageEvent();
                event.length = paginator.length;
                event.pageIndex = paginator.pageIndex;
                event.pageSize = paginator.pageSize;
                // Give the UI a moment to catch up before emitting the event
                setTimeout(() => {
                    paginator.page.emit(event);
                }, 100);
            } else if (queryUpdated && filterEvent) {
                // this is to ensure any changes are propagated to the child components
                setTimeout(() => {
                    filterEvent.emit();
                }, 100);
            }

            cdr.detectChanges();
        }));
    }

    // this gets everything that is not a paging param
    static getQueryParams(params: Params) {
        const paramsCopy = ({...params});
        delete paramsCopy['sort'];
        delete paramsCopy['dir'];
        delete paramsCopy['page'];
        delete paramsCopy['num'];
        return paramsCopy;
    }

    // this gets ONLY paging params
    static getPageParams(params: Params) {
        const pageParams = {
            page: params['page'] || '',
            num: params['num'] || '',
            sort: params['sort'] || '',
            dir: params['dir'] || ''
        };
        return pageParams;
    }

    // Clean up all our subscriptions when we're done
    static clearSubscriptions() {
        this.subscriptions.forEach((subscription) => {
            return subscription.unsubscribe();
        });
        this.subscriptions = [];
    }

    // Update the URL with new query parameters
    static updateQueryParams(route: ActivatedRoute, router: Router, queryParams: any, replace: boolean) {
        if (replace) {
            router.navigate([], {
                relativeTo: route,
                queryParams,
                queryParamsHandling: 'merge',
                replaceUrl: true
            });
        } else {
            router.navigate([], {
                relativeTo: route,
                queryParams,
                queryParamsHandling: 'merge'
            });
        }
    }

    static parseQueryParams(queryString: string): Record<string, string> {
        if (!queryString) {
            return {};
        }
        const params: Record<string, string> = {};
        const searchParams = new URLSearchParams(queryString);
        searchParams.forEach((value, key) => {
            params[key] = value;
        });
        return params;
    }
}

export class PageTracking {
    page: number;
    pageSize: number;
    sort: string;
    sortDir: SortDirection;
    pageSizeOptions: number[];
}
