import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChartType, ChartService, PaymentService, TransactionTableFilter } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-transaction-charts-gadgets',
    templateUrl: './transaction-charts-gadgets.component.html',
    styleUrls: ['./transaction-charts-gadgets.component.scss']
})
export class TransactionChartsGadgetsComponent implements OnInit, OnChanges {

    ChartType = ChartType;

    @Input() filter: TransactionTableFilter;
    @Input() changeTrigger: number;

    period = 60;
    view = 'amount';

    chartData: any[] = [];
    labels: string[] = [];
    dataLoading = false;

    colorScheme = { domain: ['#239B56']};
    scoreColorScheme = { domain: ['#0E6251', '#27AE60', '#F4D03F', '#F5B041', '#E74C3C', '#CB4335']};

    constructor(private paymentsService: PaymentService,
                private chartService: ChartService) { }

    ngOnInit() {
        this.getTransactionsChartData();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.filter && !changes.filter.firstChange) ||
            (changes.changeTrigger && !changes.changeTrigger.firstChange)) {
            this.getTransactionsChartData();
        }
    }

    getTransactionsChartData() {
        this.dataLoading = true;
        if (this.view === 'amount') {
            this.getTransactionAmountByPeriod();
        } else if (this.view === 'transactions') {
            this.getTransactionCountsByPeriod();
        } else if (this.view === 'scores') {
            this.getTransactionScoresByPeriod();
        }
    }

    viewDecision(viewType: string) {
        this.view = viewType;
        this.getTransactionsChartData();
    }

    getTransactionScoresByPeriod() {
        this.paymentsService.getTransactionsScores(this.filter.memberId, this.filter.memberAccountId, this.filter.transactionState, [], this.filter.transactionSources, this.period, this.filter.minAmount, this.filter.maxAmount, this.filter.startDate, this.filter.endDate, this.filter.retailLocationId, this.filter.paymentRails)
            .subscribe(
                (response: any) => {
                    this.labels = Object.keys(response);
                    this.chartData = this.chartService.getChartTransactionScores(response, this.labels);
                    this.dataLoading = false;
                }
            );
    }

    getTransactionCountsByPeriod() {
        this.paymentsService.getNumberOfTransactionsByPeriod(this.filter.memberId, this.filter.memberAccountId, this.filter.transactionState, [], this.filter.transactionSources, this.period, this.filter.minAmount, this.filter.maxAmount, this.filter.startDate, this.filter.endDate, this.filter.retailLocationId, this.filter.paymentRails)
            .subscribe(
                (response: any) => {
                    this.labels = Object.keys(response);
                    this.chartData = this.chartService.getChartTransaction(response, this.labels, '# of Transactions');
                    this.dataLoading = false;
                }
            );
    }

    getTransactionAmountByPeriod() {
        this.paymentsService.getTransactionAmountByPeriod(this.filter.memberId, this.filter.memberAccountId, this.filter.transactionState, [], this.filter.transactionSources, this.period, this.filter.minAmount, this.filter.maxAmount, this.filter.startDate, this.filter.endDate, this.filter.retailLocationId, this.filter.paymentRails)
            .subscribe(
                (response: any) => {
                    this.labels = Object.keys(response);
                    this.chartData = this.chartService.getChartTransaction(response, this.labels);
                    this.dataLoading = false;
                }
            );
    }
}
