import { MemberRegistrationType } from './member';
import { BankingIntegrationProvider } from './bank-integration-provider';

export class Config {
    maxUploadSize: number;
    maxFileNameSize: number;
    mfaEnabled: boolean;
    consumerEnabled: boolean;
    qaEnabled: boolean;
    complyAdvantageEnabled: boolean;
    smartContractsEnabled: boolean;
    metrcEnabled: boolean;
    passwordLinkExpirationInMinutes: number;
    registrationLinkExpirationInMinutes: number;
    countries: Country[];
    jurisdictions: Jurisdiction[];
    confiaGoogleMapApiKey: string;
    bankingIntegrationEnabled: boolean;
    bankProvider: BankingIntegrationProvider;
    transactionsExternalReturnsEnabled: boolean;
    accountIdsRequired: boolean;
    autoCompleteSearchLimit: number;
    trackingInfoConsolidationDays: number;

}

export class Country {
    name: string;
    code: string;
    postalPattern: string;
    divisionLabel: string;
    divisions: AdminDivision[];
}

export class AdminDivision {
    name: string;
    code: string;
    aliases: string[];
}

export class Jurisdiction {
    id: string;
    name: string;
    supportedMemberRegistrationTypes: MemberRegistrationType[];
}
