import { Injectable } from '@angular/core';
import { TransactionScores } from './models/transaction-scores';
import { TransactionTypeTableData } from './models/transaction-type-table-data';
import { Datapoint } from './models/datapoint';
import { ChartData } from './models/chart-data';


@Injectable({
    providedIn: 'root'
})
export class ChartService {

    getChartData(chartDataInformation: Map<string, number>, chartLabels: string[]): Datapoint[] {
        const chartDataList: Datapoint[] = [];
        chartLabels.forEach((chartLabel: string) => {
            const chartData: Datapoint = new Datapoint();
            const value =  chartDataInformation[chartLabel];
            chartData.name = chartLabel;
            chartData.value = value;
            chartDataList.push(chartData);
        });
        return chartDataList;
    }

    getChartTransaction(chartDataInformation: Map<string, number>, chartLabels: string[], name = 'Amount'): ChartData[] {
        const chartData = this.getChartData(chartDataInformation, chartLabels);
        const multiList: ChartData[] = [];
        const multi = new ChartData();
        multi.name = name;
        multi.series = chartData;
        multiList.push(multi);
        return multiList;
    }

    getChartTransactionScores(chartTransactionScores: any, chartLabels: string[]): ChartData[] {
        const multiList: ChartData[] = [];
        chartLabels.forEach((chartLabel) => {
            const multi = new ChartData();
            multi.name = chartLabel;
            for (let i = 0; i <= 5; i++) {
                const transactionTypes = chartTransactionScores[chartLabel];
                const count = transactionTypes['SCORE_' + i];
                const datapoint = new Datapoint();
                datapoint.name = i.toString();
                datapoint.value = count;
                multi.series.push(datapoint);
            }
            multiList.push(multi);
        });
        return multiList;
    }

    getTableTransactionScores(tableTransactionScores: any, labels: string[]): TransactionScores[] {
        const transactionScores: TransactionScores[] = [];
        labels.forEach((key: string) => {
            const transactionScore: TransactionScores = new TransactionScores();
            const scores = tableTransactionScores[key];
            transactionScore.date = key;
            transactionScore.scoreZero = scores['SCORE_0'];
            transactionScore.scoreOne = scores['SCORE_1'];
            transactionScore.scoreTwo = scores['SCORE_2'];
            transactionScore.scoreThree = scores['SCORE_3'];
            transactionScore.scoreFour = scores['SCORE_4'];
            transactionScore.scoreFive = scores['SCORE_5'];
            transactionScores.push(transactionScore);
        });
        return transactionScores;
    }

    getTableTransactionTypes(tableTransactionTypes: any, labels: string[]): any[] {
        const transactionTypeTableDataList: TransactionTypeTableData[] = [];
        labels.forEach((key: string) => {
            const transactionTypeTableData: TransactionTypeTableData = new TransactionTypeTableData();
            const scoreVsCount = tableTransactionTypes[key];
            transactionTypeTableData.date = key;
            transactionTypeTableData.ach = scoreVsCount['ACH'];
            transactionTypeTableData.cash = scoreVsCount['CASH'];
            transactionTypeTableData.check = scoreVsCount['CHECK'];
            transactionTypeTableData.wire = scoreVsCount['WIRE'];
            transactionTypeTableData.network = scoreVsCount['NETWORK_PAYMENT'];
            transactionTypeTableData.fee = scoreVsCount['FEE'];
            transactionTypeTableData.adjustment = scoreVsCount['ADJUSTMENT'];
            transactionTypeTableDataList.push(transactionTypeTableData);
        });
        return transactionTypeTableDataList;
    }

    getChartTransactionTypes(chartTransactionTypes: any, chartLabels: string[]): ChartData[] {
        const chartDataList: ChartData[] = [];
        const labels = ['ACH', 'CHECK', 'CASH', 'WIRE', 'NETWORK_PAYMENT', 'FEE', 'ADJUSTMENT'];
        chartLabels.forEach((chartLabel: string) => {
            const chartData: ChartData = new ChartData();
            chartData.name = chartLabel;
            labels.forEach((label) => {
                const typeVsValue = chartTransactionTypes[chartLabel];
                const value = typeVsValue[label];
                const datapoint = new Datapoint();
                switch (label) {
                    case 'ACH':
                        datapoint.name = 'ACH';
                        break;
                    case 'CHECK':
                        datapoint.name = 'Check';
                        break;
                    case 'CASH':
                        datapoint.name = 'Cash';
                        break;
                    case 'WIRE':
                        datapoint.name = 'Wire';
                        break;
                    case 'NETWORK_PAYMENT':
                        datapoint.name = 'Network';
                        break;
                    case 'FEE':
                        datapoint.name = 'Fee';
                        break;
                    case 'ADJUSTMENT':
                        datapoint.name = 'Adjustment';
                        break;
                }
                datapoint.value = value;
                chartData.series.push(datapoint);
                chartDataList.push(chartData);
            });
        });
        return chartDataList;
    }

}
