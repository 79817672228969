import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import { Configuration, IndividualKeyParty, KeyPartyService, PagedResponse } from 'projects/services/src/public-api';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
    selector: 'pc-individual-key-party-searcher',
    templateUrl: './individual-key-party-searcher.component.html',
    styleUrls: ['./individual-key-party-searcher.component.scss']
})

export class IndividualKeyPartySearcherComponent implements OnInit, AfterViewInit {

    @Input() label: string;
    @Input() placeholder = '';
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;
    @Input() existingKeyPartyIds: string[];

    @Output() individualKeyPartySelected = new EventEmitter<IndividualKeyParty>();

    individualKeyPartyAutoComplete: Observable<IndividualKeyParty[]> = null;
    autoCompleteControl = new UntypedFormControl('');
    individualKeyPartyOptions: IndividualKeyParty[] = [];
    searching = false;
    keyPartySelect: Subscription;
    searchLimit: number;
    totalResults: number;

    @ViewChild('individualKeyPartySelect', { read: MatAutocompleteTrigger }) trigger: MatAutocompleteTrigger;

    constructor(private keyPartyService: KeyPartyService) {}

    ngOnInit() {
        this.searchLimit = Configuration.getConfig().autoCompleteSearchLimit;
        this.formGroup.addControl('autoCompleteCtrl', this.autoCompleteControl);
        this.individualKeyPartyAutoComplete = this.autoCompleteControl.valueChanges.pipe(
            startWith(''),
            distinctUntilChanged(),
            debounceTime(1000),
            switchMap((value) => {
                this.individualKeyPartyOptions = [];
                return this.lookup(value || '');
            })
        );
    }

    ngAfterViewInit() {
        this.subscribeToClosingActions();
    }

    private subscribeToClosingActions() {
        if (this.keyPartySelect && !this.keyPartySelect.closed) {
            this.keyPartySelect.unsubscribe();
        }

        this.keyPartySelect = this.trigger.panelClosingActions.subscribe((e) => {
            if (!e || !e.source) {
                if (this.individualKeyPartyOptions.length === 1) {
                    this.individualKeyPartyChanged(this.individualKeyPartyOptions[0]);
                } else {
                    const autoCompleteValue = this.autoCompleteControl.value;
                    if (autoCompleteValue) {
                        const selected = this.individualKeyPartyOptions
                            .map((option) => {
                                return option.email;
                            })
                            .find((option) => {
                                return option === autoCompleteValue;
                            });

                        if (!selected && this.selected()) {
                            this.formGroup.controls[this.formCtrl].markAsPristine();
                            this.formGroup.controls[this.formCtrl].setValue('');
                            this.autoCompleteControl.setValue('');
                            this.individualKeyPartyChanged(new IndividualKeyParty());
                        } else {
                            const keyParty = new IndividualKeyParty();
                            keyParty.email = autoCompleteValue;
                            this.individualKeyPartyChanged(keyParty);
                        }
                    }
                }
            }
        }, () => {
            return this.subscribeToClosingActions();
        }, () => {
            return this.subscribeToClosingActions();
        });
    }

    lookup(value: string) : Observable<IndividualKeyParty[]> {
        this.totalResults = 0;
        if (value?.length <= 1) {
            return of([]);
        }
        this.searching = true;
        this.formGroup.controls['autoCompleteCtrl'].disable({emitEvent: false});
        return this.keyPartyService.findIndividualKeyParties(value, this.searchLimit).pipe(
            switchMap(
                (response: PagedResponse<IndividualKeyParty>) => {
                    this.totalResults = response.totalElements;
                    this.formGroup.controls['autoCompleteCtrl'].enable({emitEvent: false});
                    this.individualKeyPartyOptions = response.content;
                    if (value && value.length >= 2) {
                        const newKeyParty = new IndividualKeyParty();
                        newKeyParty.name = '(create new key party)';
                        this.individualKeyPartyOptions.unshift(newKeyParty);
                    }
                    this.searching = false;
                    return of(this.individualKeyPartyOptions);
                }
            ), catchError((_error) => {
                this.searching = false;
                this.formGroup.controls['autoCompleteCtrl'].enable({emitEvent: false});
                return [];
            })
        );
    }

    individualKeyPartyChanged(individualKeyParty: IndividualKeyParty) {
        this.formGroup.controls[this.formCtrl].markAsDirty();
        this.formGroup.controls[this.formCtrl].setValue(individualKeyParty.id);
        this.formGroup.controls['autoCompleteCtrl'].disable({emitEvent: false});
        this.formGroup.controls['autoCompleteCtrl'].patchValue(individualKeyParty.id ? individualKeyParty.name : 'New Individual Key Party', {emitEvent: false});
        this.individualKeyPartySelected.emit(individualKeyParty);
    }

    onInputChanged(event: any) {
        if (event.keyCode === 220) {
            event.preventDefault();
            return;
        }
    }

    selected() {
        return this.formGroup.controls['autoCompleteCtrl'].disabled;
    }

    onIconClick(event: any) {
        if (this.selected()) {
            this.individualKeyPartyOptions = [];
            this.formGroup.controls[this.formCtrl].setValue(null);
            this.formGroup.controls['autoCompleteCtrl'].enable({emitEvent: false});
            this.formGroup.controls['autoCompleteCtrl'].patchValue('', {emitEvent: false});
            this.individualKeyPartySelected.emit(new IndividualKeyParty());
        }
        event.stopPropagation();
    }

    keyPartyExists(keyPartyId: string) {
        return keyPartyId && this.existingKeyPartyIds.indexOf(keyPartyId) >= 0;
    }
}
