import { GQLQueryType } from 'projects/services/src/lib/graphql/queries/gql-query-type';
import { gqlMemberFields, gqlConsumerFields } from 'projects/services/src/lib/graphql/queries/entities/gql-member.queries';

export const gqlMemberAccountFields = `
    id
    created
    updated

    accountNumber
    memberId
    member {
        ${gqlMemberFields}
        ${gqlConsumerFields}
    }
    addressId
    accountName
    accountFriendlyName
    isPublic
    approvedDate
    disabledDate
    accountType
    status
    walletFunctions
`;

export abstract class GqlConnectedMemberQueries {

    static pageFragment = 'MemberAccountPage';

    static searchConnectedMemberAccounts(): GQLQueryType {
        return {
            params(): String {
                return `$partnerId: String!, $memberId: String, $memberAccountId: String, $memberAccountTypes: [String], 
                        $memberName: String, $memberEmail: String, $memberPhone: String, $statuses: [String], 
                        $partnerReferenceId: String`;
            },

            query(selectedFields: String = gqlMemberAccountFields, pageParams: String): String {
                return `searchConnectedMemberAccounts(partnerId: $partnerId, memberId: $memberId, memberAccountId: $memberAccountId,
                        memberAccountTypes: $memberAccountTypes, memberName: $memberName, memberEmail: $memberEmail, memberPhone: $memberPhone, 
                        statuses: $statuses, partnerReferenceId: $partnerReferenceId, ${pageParams}){

                            content{
                                ${selectedFields}
                            }
                }`;
            }
        };
    }
}