import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NavigationLinks {

    static dashboardLink = {
        link: '/dashboard',
        title: 'Dashboard',
        icon: 'assets/images/dashboard-icon.svg'
    };

    static memberAccountsLink = {
        link: '/member-accounts/list',
        title: 'Accounts',
        icon: 'assets/images/account-icon-2.svg'
    };

    static transactionLink = {
        link: '/transaction/list',
        title: 'Transactions',
        icon: 'assets/images/receipt-icon.svg'
    };

    static authorityTransactionLink = {
        link: '/transaction/list',
        title: 'Transactions',
        icon: 'assets/images/receipt-icon.svg'
    };

    static reportsLink = {
        link: '/reports/list',
        title: 'Reports',
        icon: 'assets/images/report-icon.svg'
    };

    static authorityReportsLink = {
        link: '/reports/authority/list',
        title: 'Reports',
        icon: 'assets/images/report-icon.svg'
    };

    static rfiLink = {
        link: '/rfi/list',
        title: 'Compliance',
        icon: 'assets/images/compliance-icon-2.svg'
    };

    static smartContractsLink = {
        link: '/smart_contracts',
        title: 'Smart Contracts',
        icon: 'assets/images/files-icon.svg',
        queryParams: {_activeTab: 'my_contracts'}
    };

    static authorityRfiLink = {
        link: '/rfi/authority/list',
        title: 'Compliance',
        icon: 'assets/images/compliance-icon-2.svg'
    };

    static authorityAuditLink = {
        link: '/audit/authority/list',
        title: 'Auditing',
        icon: 'assets/images/audit-icon.svg'
    };

    static settingsLink = {
        link: '/administration/settings',
        title: 'Settings',
        icon: 'assets/images/settings-icon.svg'
    };

    static memberAdministrationLink = {
        link: '/administration/members',
        queryParams: {_activeTab: 'members'},
        title: 'Members',
        icon: 'assets/images/member-icon.svg'
    };

    static connectedMemberAdministrationLink = {
        link: '/administration/members',
        queryParams: {_activeTab: 'connected_members'},
        title: 'Members',
        icon: 'assets/images/member-icon.svg'
    };

    static supportLink = {
        link: '/support',
        title: 'Support',
        icon: 'assets/images/help-icon.svg'
    };

}
