import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
    AccountingLinkedMember, AccountingService, AuthService, BankAccountService, BusinessClientStatus, CodatSettings, ConnectedMember, ConnectedMemberService, LinkedBankAccount,
    MemberAccount, MemberAccountService, MemberAccountStatus, MemberAccountType, MemberFunction, MemberStatus, PagedResponse, PaymentService, ReconciliationAccount, Role,
    Wallet, WalletFunction, WalletService
} from 'projects/services/src/public-api';

@Component({
    selector: 'pt-member-account-card',
    templateUrl: './member-account-card.component.html',
    styleUrls: ['./member-account-card.component.scss']
})
export class MemberAccountCardComponent implements OnInit, OnChanges {

    MemberAccountType = MemberAccountType;
    MemberAccountStatus = MemberAccountStatus;
    MemberStatus = MemberStatus;

    @Input() memberAccount: MemberAccount;
    @Input() authorityView = false;
    @Input() changeTrigger = 1;

    @Output() accountStatusChanged: EventEmitter<void> = new EventEmitter<void>();

    connectedMembers: ConnectedMember[] = [];
    role: Role;
    isDepositorAccount = false;
    accountingLoading = true;
    accountingConnected = false;
    reconciliationAccountName = '';

    constructor(public authService: AuthService,
                private walletService: WalletService,
                private connectedMemberService: ConnectedMemberService,
                private memberAccountService: MemberAccountService,
                private bankAccountService: BankAccountService,
                private accountingService: AccountingService,
                private paymentService: PaymentService) {}

    ngOnInit() {
        this.init();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.changeTrigger && !changes.changeTrigger.firstChange) {
            this.loadWalletInfo();
        }
    }

    init() {
        this.role = this.authService.getProfile().role;
        this.isDepositorAccount = !this.memberAccountService.hasWalletFunction(this.memberAccount, WalletFunction.ACH_DIRECT_PAYMENT);
        this.memberAccount.isAdmin = this.authService.isAccountAdmin(this.memberAccount) || this.authService.isAuthority();
        this.loadWalletInfo();
        this.bankAccountService.getLinkedBankAccount(this.memberAccount.memberId, this.memberAccount.id).subscribe((linkedBankAccount: LinkedBankAccount) => {
            this.memberAccount.linkedBankAccount = linkedBankAccount;
        });
        if (this.memberAccount.accountType === MemberAccountType.BUSINESS && this.memberAccount.isAdmin) {
            this.accountingService.getAccountingSystemInfo(this.memberAccount.memberId).subscribe((accountingSystemDetails: AccountingLinkedMember) => {
                if (accountingSystemDetails?.linked) {
                    this.accountingConnected = true;
                    // successful login
                    const pushSettingsAvailable = !!this.accountingService.getSupportedPushPlatforms().find((supportedPlatform) => {
                        return accountingSystemDetails.platform?.toLowerCase().indexOf(supportedPlatform.toLowerCase()) > -1;
                    });
                    if (pushSettingsAvailable) {
                        this.accountingService.getCodatSettings(this.memberAccount.memberId, this.memberAccount.id).subscribe((codatSettings: CodatSettings) => {
                            if (codatSettings.reconciliationAccountId) {
                                this.fetchAccounts(codatSettings.reconciliationAccountId);
                            } else {
                                this.accountingLoading = false;
                            }
                        });
                    } else {
                        this.accountingLoading = false;
                    }
                }
            });
        }
    }

    loadWalletInfo() {
        if (this.memberAccount.accountType !== MemberAccountType.MERCHANT && !this.underReview()) {
            this.walletService.findByMemberAccountId(this.memberAccount.id).subscribe((wallet: Wallet) => {
                this.memberAccount.wallet = wallet;
                if (this.isDepositorAccount) {
                    this.paymentService.getOutstandingCheckAmountForMemberAccount(this.memberAccount.id).subscribe((outstandingAmount: number) => {
                        this.memberAccount.wallet.outstandingCheckAmount = outstandingAmount || 0;
                    });
                }
            });
            if (this.role !== Role.MEMBER_READONLY) {
                this.connectedMemberService.getConnectedMembersForMemberAccount(this.memberAccount.memberId, this.memberAccount.id).subscribe((results: PagedResponse<ConnectedMember>) => {
                    this.connectedMembers = results.content;
                });
            }
        }
    }

    fetchAccounts(reconciliationAccountId: string) {
        this.accountingService.fetchAllAccountsByMemberId(this.memberAccount.memberId).subscribe((accounts: ReconciliationAccount[]) => {
            accounts.forEach((account) => {
                if (account.id === reconciliationAccountId) {
                    this.reconciliationAccountName = account.name;
                }
            });
            this.accountingLoading = false;
        });
    }

    toggleAccountActive() {
        const body = {
            status: this.memberAccount.status === MemberAccountStatus.DISABLED ? MemberAccountStatus.ACTIVE : MemberAccountStatus.DISABLED
        };
        this.memberAccountService.updateMemberAccount(this.memberAccount.id, body).subscribe((memberAccount: MemberAccount) => {
            this.memberAccount = memberAccount;
            this.init();
            this.accountStatusChanged.emit();
        });
    }

    getBankAccountNumber() {
        const accountNumber = this.memberAccount.linkedBankAccount.bankAccount.accountNumber;
        return '*****' + accountNumber.substring(accountNumber.length - 4);
    }

    underReview() {
        return this.memberAccount.status === MemberAccountStatus.UNDER_REVIEW || this.memberAccount.status === MemberAccountStatus.PENDING;
    }

    isAccountIdVisible() {
        return (this.memberAccount.member.memberFunctions?.indexOf(MemberFunction.PARTNER) >= 0)
            || (this.memberAccount.walletFunctions.indexOf(WalletFunction.ENABLE_PAYMENT_PROCESSING) >= 0);
    }

    isDisabledOrBlockedOrRejected(memberAccountStatus: MemberAccountStatus | BusinessClientStatus) {
        return memberAccountStatus === MemberAccountStatus.DISABLED || memberAccountStatus === MemberAccountStatus.BLOCKED
            || memberAccountStatus === MemberAccountStatus.REJECTED;
    }
}
