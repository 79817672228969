import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { DirtyComponent } from 'projects/components/src/lib/administration';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { ConfirmModalComponent } from 'projects/components/src/public-api';
import {
    Address, AuthService, DataroomService, BankAccount, BankAccountService, License, LicenseService, LicenseStatus, AddressService, MerchantAccountConfig,
    MerchantAccountService, MerchantAccountStatus, OnboardingTrackingService, MemberType, KeyParty, KeyPartyService, PagedResponse, RiskTier, Upload, Utils, WorkflowService,
    UploadStatus, DocumentType, MemberStatus, Member, UserAccountService, UserAccountStatus, UserAccount, Role, User, MemberService, Task, BankAccountStatus
} from 'projects/services/src/public-api';
import { of } from 'rxjs';

@Component({
    selector: 'pt-individual-details',
    templateUrl: './individual-details.component.html',
    styleUrls: ['./individual-details.component.scss']
})
export class IndividualDetailsComponent implements OnInit, DirtyComponent {

    MemberStatus = MemberStatus;
    MerchantAccountStatus = MerchantAccountStatus;
    MemberType = MemberType;

    @Input() tabValid: () => boolean;

    activeTab: string;
    merchantMemberId: string;
    processInstanceId: string;
    changeTrigger = Math.random();
    notesTrigger = Math.random();
    task: string;
    taskId: string;
    taskVars: any;
    merchantConfig : MerchantAccountConfig;
    merchantMember: Member;

    authorityUsers: {userId: string, user: User}[] = [];
    assignee: string;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private memberService: MemberService,
                private keyPartyService: KeyPartyService,
                private addressService: AddressService,
                private licenseService: LicenseService,
                private merchantAccountService: MerchantAccountService,
                private workflowService: WorkflowService,
                private bankAccountService: BankAccountService,
                private dataroomService: DataroomService,
                private userAccountService: UserAccountService,
                private dialog: MatDialog,
                public authService: AuthService) {}

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            const activeTab = params['_activeTab'];
            if (activeTab && activeTab !== this.activeTab && (this.task || this.merchantMemberId)) {
                this.onSwitchTabs(params['_activeTab']);
            }
        });
        this.onSwitchTabs = this.onSwitchTabs.bind(this);
        this.getTabClass = this.getTabClass.bind(this);
        this.isIndividualDetailsValid = this.isIndividualDetailsValid.bind(this);
        this.isKeyPartyDetailsValid = this.isKeyPartyDetailsValid.bind(this);
        this.isLocationDetailsValid = this.isLocationDetailsValid.bind(this);
        this.isLicenseDetailsValid = this.isLicenseDetailsValid.bind(this);
        this.isInternalDetailsValid = this.isInternalDetailsValid.bind(this);
        this.isDocumentsValid = this.isDocumentsValid.bind(this);
        this.isBankingDetailsValid = this.isBankingDetailsValid.bind(this);
        // Consumer
        this.isAdminDetailsValid = this.isAdminDetailsValid.bind(this);
        this.isAdminDetailsDirty = this.isAdminDetailsDirty.bind(this);
        this.isInternalAlertsValid = this.isInternalAlertsValid.bind(this);
        this.isInternalAlertsDirty = this.isInternalAlertsDirty.bind(this);
        const individualMemberId = this.route.snapshot.params.individualMemberId;
        const individualOnboardMemberId = this.route.snapshot.params.individualOnboardMemberId;
        OnboardingTrackingService.setCurrentTask(null);

        if (individualOnboardMemberId) {
            this.workflowService.loadTaskByProcessDefinitionWithVariables(['register_individual_merchant_v2'], `merchantMemberId_eq_${individualOnboardMemberId}`);
        } else if (!Utils.isUuid(individualMemberId) && this.workflowService.getCurrentTask()) {
            const task = this.workflowService.getCurrentTask();
            if (!task.formKey.startsWith('/administration/individual')) {
                this.workflowService.clearCurrentTask();
                this.router.navigate(['/dashboard']);
                throw new Error('Task not valid.');
            }
            this.taskId = task.id;
            this.processInstanceId = this.workflowService.getCurrentTask().processInstanceId;
            this.setTaskVariables(task);
        } else if (Utils.isUuid(individualMemberId)) {
            this.memberService.loadMember(individualMemberId).subscribe((member: Member) => {
                if (!member) {
                    this.router.navigate(['/dashboard']);
                    throw new Error('Link is no longer valid');
                }
                if (member.status === MemberStatus.UNDER_REVIEW) {
                    this.workflowService.getTaskByProcessDefinitionWithVariable(['register_individual_merchant_v2'], 'merchantMemberId', member.id).subscribe((task: Task) => {
                        if (task) {
                            this.taskId = task.id;
                            this.processInstanceId = task.processInstanceId;
                            this.setTaskVariables(task);
                        } else {
                            this.router.navigate(['/dashboard']);
                            throw new Error('Link is no longer valid');
                        }
                    });
                } else {
                    this.merchantMemberId = individualMemberId;
                    this.checkValidity();
                    if (this.route.snapshot.queryParams['_activeTab']) {
                        this.activeTab = this.route.snapshot.queryParams['_activeTab'];
                        UIUtils.scrollToTop(document.querySelector('#individual-details'));
                    } else {
                        this.onSwitchTabs('individual', true);
                    }
                }
            });
        }
    }

    setTaskVariables(task: Task) {
        this.assignee = task.variables.assignedAuthority;
        this.taskVars = task.variables;
        this.userAccountService.loadAccountsByMemberIdAndStatusesAndRoles(this.authService.getProfile().memberId, [UserAccountStatus.ACTIVE], [Role.AUTHORITY, Role.AUTHORITY_SUPERUSER], 0, 1000, 'user.lastName', 'ASC').subscribe((response: PagedResponse<UserAccount>) => {
            this.authorityUsers = response.content.map((userAccount: UserAccount) => {
                return {
                    userId: userAccount.userId,
                    user: userAccount.user
                };
            });
        });
        this.merchantMemberId = task.variables.merchantMemberId;
        this.task = 'adjudicate';
        if (this.route.snapshot.queryParams['_activeTab']) {
            this.activeTab = this.route.snapshot.queryParams['_activeTab'];
            this.checkValidity();
        } else {
            this.task = 'adjudicate';
            this.onSwitchTabs('contact_info', true);
        }
        OnboardingTrackingService.setCurrentTask(this.task);
    }

    onSwitchTabs(tabOption: string, skipLocationChange?: boolean, replaceUrl?: boolean) {
        if (this.activeTab === tabOption) {
            return;
        }
        if (!this.isDirty()) {
            this.activeTab = tabOption;
            if (replaceUrl) {
                this.router.navigateByUrl(`/administration/individual/${this.task ? this.task : this.merchantMemberId}?_activeTab=${tabOption}`, {skipLocationChange, replaceUrl: true});
            } else {
                this.router.navigateByUrl(`/administration/individual/${this.task ? this.task : this.merchantMemberId}?_activeTab=${tabOption}`, {skipLocationChange});
            }
            UIUtils.scrollToTop(document.querySelector('#individual-details'));
        } else {
            const dialogConfig: MatDialogConfig = {};
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'normal-modal';
            dialogConfig.disableClose = true;

            dialogConfig.data = {
                title: 'You have unsaved changes',
                description: 'Click Return to finish entering information, or click Ignore to continue without saving.',
                confirmText: 'Ignore',
                confirmAction: 'ignore',
                rejectText: 'Return',
                rejectAction: 'return'
            };

            const dialog = this.dialog.open(ConfirmModalComponent, dialogConfig);

            dialog?.afterClosed().subscribe((confirmAction: any) => {
                if (confirmAction === 'ignore') {
                    this.activeTab = tabOption;
                    this.clearTrackingState();
                    this.router.navigateByUrl(`/administration/individual/${this.task ? this.task : this.merchantMemberId}?_activeTab=${tabOption}`);
                    UIUtils.scrollToTop(document.querySelector('#individual-details'));
                }
            });
        }
    }

    clearTrackingState() {
        OnboardingTrackingService.setMemberInfoDirty(false);
        OnboardingTrackingService.setInternalInfoDirty(false);
        OnboardingTrackingService.setCurrentTask(this.task);
        OnboardingTrackingService.setInternalAlertsDirty(false);
    }

    getTabClass(tabOption: string) {
        if (this.activeTab === tabOption) {
            return 'col-12 tab-col tab-option-selected';
        } else {
            return 'col-12 tab-col tab-option';
        }
    }

    next() {
        switch (this.activeTab) {
            case ('contact_info'): {
                this.onSwitchTabs('individual');
                break;
            }
            case ('individual'): {
                this.onSwitchTabs('key_parties');
                break;
            }
            case ('key_parties'): {
                this.onSwitchTabs('locations');
                break;
            }
            case ('locations'): {
                this.onSwitchTabs('documents');
                break;
            }
            case ('documents'): {
                this.onSwitchTabs('internal');
                break;
            }
            case ('internal'): {
                this.onSwitchTabs('banking_details');
                break;
            }
            case ('banking_details'): {
                this.onSwitchTabs('adjudicate');
                break;
            }
        }
    }

    checkValidity() {
        this.merchantAccountService.getMerchantAccountConfigByMerchantMemberId(this.merchantMemberId).subscribe((merchantConfig: MerchantAccountConfig) => {
            this.merchantConfig = merchantConfig;
            this.merchantMember = merchantConfig.merchantMemberAccount.member;
            OnboardingTrackingService.setPrivateMerchant(!merchantConfig.shared);
            this.addressService.getPrimaryAddress(merchantConfig.merchantMemberAccount.memberId).subscribe((address: Address) => {
                OnboardingTrackingService.setMemberInfo(address, merchantConfig.merchantMemberAccount.member);
            });

            this.checkKeyPartiesValid();
            this.checkLocationsValid();
            this.checkLicensesValid();
            this.checkBankingDetailsValid();
            if (this.isAuthority()) {
                this.checkInternalValid();
            }
        });
    }

    checkKeyPartiesValid() {
        this.keyPartyService.getAllActiveKeyParties(this.merchantMemberId).subscribe((keyParties: PagedResponse<KeyParty>) => {
            OnboardingTrackingService.setKeyPartyInfo(keyParties.content);
            const authorityDocuments: Upload[] = [];
            keyParties.content.forEach((keyParty) => {
                this.dataroomService.getUploads('', [UploadStatus.ACTIVE], [DocumentType.ID_RISK_SCREEN, DocumentType.MEDIA_SCREEN],
                    keyParty.individualKeyPartyId || keyParty.corporateKeyPartyId).subscribe((screeningDocuments: Upload[]) => {
                    authorityDocuments.push(...screeningDocuments);
                    OnboardingTrackingService.setAuthorityDocuments(authorityDocuments);
                });
            });
        });
    }

    checkLocationsValid() {
        this.addressService.getAllAddresses(this.merchantMemberId).subscribe((locations: PagedResponse<Address>) => {
            OnboardingTrackingService.setLocationInfo(locations.content);
        });
    }

    checkLicensesValid() {
        this.licenseService.loadMemberLicenses(this.merchantMemberId, [LicenseStatus.ACTIVE], 0, 1, 'id', 'DESC').subscribe((licensesResponse: PagedResponse<License>) => {
            OnboardingTrackingService.setLicenseInfo(licensesResponse.content);
        });
    }

    checkBankingDetailsValid() {
        this.bankAccountService.getBankAccounts(this.merchantMemberId, [BankAccountStatus.ACTIVE, BankAccountStatus.PENDING], 0, -1, 'created', 'DESC').subscribe((response: PagedResponse<BankAccount>) => {
            const activeBankAccounts = response.content.filter((bankAccount: BankAccount) => {
                return bankAccount.status === BankAccountStatus.ACTIVE;
            });
            const pendingBankAccounts = response.content.filter((bankAccount: BankAccount) => {
                return bankAccount.status === BankAccountStatus.PENDING;
            });
            OnboardingTrackingService.setBankingDetails(this.merchantConfig.defaultOutgoingTransactionType, activeBankAccounts, pendingBankAccounts);
        });
    }

    checkInternalValid() {
        OnboardingTrackingService.setInternalDetails(this.merchantConfig.merchantMemberAccount.member.tier, this.merchantConfig.merchantMemberAccount.member.rating, this.merchantConfig.merchantMemberAccount.member.jurisdiction, this.merchantConfig);
    }

    isIndividualDetailsValid() {
        return OnboardingTrackingService.isMemberInfoValid();
    }

    isIndividualDetailsDirty() {
        return OnboardingTrackingService.isMemberInfoDirty();
    }

    isKeyPartyDetailsValid() {
        return OnboardingTrackingService.isKeyPartyInfoValid();
    }

    isLocationDetailsValid() {
        return OnboardingTrackingService.isLocationInfoValid();
    }

    isInternalDetailsValid() {
        return OnboardingTrackingService.isInternalDetailsValid();
    }

    isInternalDetailsDirty() {
        return OnboardingTrackingService.isInternalInfoDirty();
    }

    isBankingDetailsValid() {
        return OnboardingTrackingService.isBankingDetailsValid();
    }

    isLicenseDetailsValid() {
        return OnboardingTrackingService.isLicenseInfoValid();
    }

    isDocumentsValid() {
        return true;
    }

    isAuthority() {
        return this.authService.isAuthority();
    }

    isAuthorityOrReviewer() {
        return this.authService.isAuthorityOrReviewer();
    }

    isAuthoritySuperUser() {
        return this.authService.isAuthoritySuperUser();
    }

    isDirty() {
        if  (this.activeTab === 'individual') {
            return OnboardingTrackingService.isMemberInfoDirty();
        } else if (this.activeTab === 'internal') {
            return OnboardingTrackingService.isInternalInfoDirty();
        }
        return false;
    }

    canDeactivate(nextState: RouterStateSnapshot) {
        if (!this.isDirty()) {
            return of(true);
        } else {
            const dialogConfig: MatDialogConfig = {};
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'normal-modal';
            dialogConfig.disableClose = true;

            dialogConfig.data = {
                title: 'You have unsaved changes',
                description: 'Click Return to finish entering information, or click Ignore to continue without saving.',
                confirmText: 'Ignore',
                confirmAction: 'ignore',
                rejectText: 'Return',
                rejectAction: 'return'
            };

            const dialog = this.dialog.open(ConfirmModalComponent, dialogConfig);

            dialog?.afterClosed().subscribe(((confirmAction: any) => {
                if (confirmAction === 'ignore') {
                    this.clearTrackingState();
                    this.router.navigateByUrl(nextState.url, {state: {bypassGuard: true}});
                }
            }));
            return of(false);
        }
    }

    isAdjudicateValid() {
        return OnboardingTrackingService.isAdjudicateValid();
    }

    tierChanged(tier: RiskTier) {
        this.merchantConfig.merchantMemberAccount.member.tier = tier;
        this.changeTrigger = Math.random();
    }

    isAdminDetailsValid() {
        return OnboardingTrackingService.isAdminInfoValid();
    }

    isAdminDetailsDirty() {
        return OnboardingTrackingService.isAdminInfoDirty();
    }

    isInternalAlertsValid() {
        return OnboardingTrackingService.isInternalAlertsValid();
    }

    isInternalAlertsDirty() {
        return OnboardingTrackingService.isInternalAlertsDirty();
    }

    onNoteAdded() {
        this.notesTrigger = Math.random();
    }
}
