import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CorporateKeyParty, IndividualKeyParty, KeyParty, KeyPartyStatus, KeyPartyType } from '../models/key-party';
import { CustomValidators } from '../validators/custom.validators';

@Injectable({
    providedIn: 'root'
})
export class KeyPartyFormService {

    constructor(private formbuilder: UntypedFormBuilder) { }

    initializeKeyPartyForm(type: KeyPartyType, status: KeyPartyStatus, keyParty?: KeyParty): UntypedFormGroup {
        const form = this.formbuilder.group({
            idCtrl: new UntypedFormControl(),
            createdCtrl: new UntypedFormControl(),
            updatedCtrl: new UntypedFormControl(),
            corporateKeyPartyIdCtrl: new UntypedFormControl(),
            individualKeyPartyIdCtrl: new UntypedFormControl(),
            userIdCtrl: new UntypedFormControl(),
            ownerCtrl: new UntypedFormControl(false),
            employerCtrl: new UntypedFormControl('', [CustomValidators.noLeadingOrTrailingWhitespace]),
            professionalTitleCtrl: new UntypedFormControl('', [CustomValidators.noLeadingOrTrailingWhitespace]),
            percentOwnershipCtrl: new UntypedFormControl(''),
            isDirectorCtrl: new UntypedFormControl(false),
            isLicenseHolderCtrl: new UntypedFormControl(false),
            isOfficerCtrl: new UntypedFormControl(false),
            isContactCtrl: new UntypedFormControl(false),
            typeCtrl: new UntypedFormControl(type),
            statusCtrl: new UntypedFormControl(status)
        });
        if (keyParty) {
            form.patchValue({
                idCtrl: keyParty.id,
                createdCtrl: keyParty.created,
                updatedCtrl: keyParty.updated,
                corporateKeyPartyIdCtrl: keyParty.corporateKeyPartyId,
                individualKeyPartyIdCtrl: keyParty.individualKeyPartyId,
                userIdCtrl: keyParty.userId,
                employerCtrl: keyParty.employer,
                professionalTitleCtrl: keyParty.professionalTitle,
                ownerCtrl: keyParty.percentOwnership && keyParty.percentOwnership > 0,
                percentOwnershipCtrl: keyParty.percentOwnership,
                isDirectorCtrl: keyParty.director,
                isOfficerCtrl: keyParty.officer,
                isLicenseHolderCtrl: keyParty.licenseHolder,
                typeCtrl: keyParty.type,
                isContactCtrl: keyParty.contactPerson,
                statusCtrl: keyParty.status
            });
            form.updateValueAndValidity();
        }
        return form;
    }

    getKeyParty(form: UntypedFormGroup): KeyParty {
        const keyParty = new KeyParty();
        keyParty.id = form.controls['idCtrl'].value;
        keyParty.created = form.controls['createdCtrl'].value;
        keyParty.updated = form.controls['updatedCtrl'].value;
        keyParty.corporateKeyPartyId = form.controls['corporateKeyPartyIdCtrl'].value;
        keyParty.individualKeyPartyId = form.controls['individualKeyPartyIdCtrl'].value;
        keyParty.userId = form.controls['userIdCtrl'].value;
        keyParty.employer = form.controls['employerCtrl'].value;
        keyParty.professionalTitle = form.controls['professionalTitleCtrl'].value;
        keyParty.owner = form.controls['ownerCtrl'].value;
        keyParty.percentOwnership = form.controls['percentOwnershipCtrl'].value;
        keyParty.director = form.controls['isDirectorCtrl'].value;
        keyParty.officer = form.controls['isOfficerCtrl'].value;
        keyParty.contactPerson = form.controls['isContactCtrl'].value;
        keyParty.licenseHolder = form.controls['isLicenseHolderCtrl'].value;
        keyParty.type = form.controls['typeCtrl'].value;
        keyParty.status = form.controls['statusCtrl'].value;
        return keyParty;
    }

    initializeIndividualKeyPartyForm(individualKeyParty?: IndividualKeyParty): UntypedFormGroup {
        const form = this.formbuilder.group({
            idCtrl: new UntypedFormControl(),
            createdCtrl: new UntypedFormControl(),
            updatedCtrl: new UntypedFormControl(),

            firstNameCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.personName]),
            middleNameCtrl: new UntypedFormControl('', [CustomValidators.middleName]),
            lastNameCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.personName]),
            otherSurnameOrAliasCtrl: new UntypedFormControl('', [CustomValidators.personName]),

            emailCtrl: new UntypedFormControl('', [CustomValidators.email]),
            phoneCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.phone]),
            birthDateCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.birthDate]),

            usCitizenCtrl: new UntypedFormControl(true),
            taxIdCtrl: new UntypedFormControl('', [Validators.required, Validators.pattern('^([0-9]{9})$')]),
            passportCountryOfIssueCtrl: new UntypedFormControl(),
            passportNumberCtrl: new UntypedFormControl(),
            passportExpDateCtrl: new UntypedFormControl()
        });
        if (individualKeyParty) {
            form.patchValue({
                idCtrl: individualKeyParty.id,
                createdCtrl: individualKeyParty.created,
                updatedCtrl: individualKeyParty.updated,

                firstNameCtrl: individualKeyParty.firstName,
                middleNameCtrl: individualKeyParty.middleName,
                lastNameCtrl: individualKeyParty.lastName,
                otherSurnameOrAliasCtrl: individualKeyParty.otherSurnameOrAlias,

                emailCtrl: individualKeyParty.email,
                phoneCtrl: individualKeyParty.phone,
                birthDateCtrl: individualKeyParty.birthDate,

                usCitizenCtrl: individualKeyParty.usCitizen,
                taxIdCtrl: individualKeyParty.taxId,
                passportCountryOfIssueCtrl: individualKeyParty.passportCountryOfIssue,
                passportNumberCtrl: individualKeyParty.passportNumber,
                passportExpDateCtrl: individualKeyParty.passportExpirationDate
            });
        }
        return form;
    }

    getIndividualKeyParty(form: UntypedFormGroup): IndividualKeyParty {
        const individualKeyParty = new IndividualKeyParty();
        individualKeyParty.id = form.controls['idCtrl'].value;
        individualKeyParty.created = form.controls['createdCtrl'].value;
        individualKeyParty.updated = form.controls['updatedCtrl'].value;

        individualKeyParty.firstName = form.controls['firstNameCtrl'].value;
        individualKeyParty.middleName = form.controls['middleNameCtrl'].value;
        individualKeyParty.lastName = form.controls['lastNameCtrl'].value;
        individualKeyParty.otherSurnameOrAlias = form.controls['otherSurnameOrAliasCtrl'].value;
        individualKeyParty.birthDate = form.controls['birthDateCtrl'].value;

        individualKeyParty.email = form.controls['emailCtrl'].value;
        individualKeyParty.phone = form.controls['phoneCtrl'].value;

        individualKeyParty.usCitizen = form.controls['usCitizenCtrl'].value;
        individualKeyParty.taxId = form.controls['taxIdCtrl'].value;
        individualKeyParty.passportCountryOfIssue = form.controls['passportCountryOfIssueCtrl'].value;
        individualKeyParty.passportNumber = form.controls['passportNumberCtrl'].value;
        if (form.controls['passportExpDateCtrl']) {
            individualKeyParty.passportExpirationDate = form.controls['passportExpDateCtrl'].value;
        }
        return individualKeyParty;
    }

    initializeCorporateKeyPartyForm(corporateKeyParty?: CorporateKeyParty): UntypedFormGroup {
        const form = this.formbuilder.group({
            idCtrl: new UntypedFormControl(),
            createdCtrl: new UntypedFormControl(),
            updatedCtrl: new UntypedFormControl(),

            nameCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.noLeadingOrTrailingWhitespace, CustomValidators.memberName, Validators.maxLength(250)]),
            dbaNameCtrl: new UntypedFormControl('', [CustomValidators.noLeadingOrTrailingWhitespace, Validators.maxLength(250)]),
            taxIdCtrl: new UntypedFormControl('', [Validators.required, Validators.pattern('^([0-9]{9})$')]),
            businessTypeCtrl: new UntypedFormControl('', [Validators.required]),
            emailCtrl: new UntypedFormControl('', [CustomValidators.email]),
            officePhoneCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.phone])
        });
        if (corporateKeyParty) {
            form.patchValue({
                idCtrl: corporateKeyParty.id,
                createdCtrl: corporateKeyParty.created,
                updatedCtrl: corporateKeyParty.updated,

                nameCtrl: corporateKeyParty.name,
                dbaNameCtrl: corporateKeyParty.dbaName,
                taxIdCtrl: corporateKeyParty.taxId,
                businessTypeCtrl: corporateKeyParty.businessType,
                emailCtrl: corporateKeyParty.email,
                officePhoneCtrl: corporateKeyParty.officePhone
            });
        }
        return form;
    }

    getCorporateKeyParty(form: UntypedFormGroup): CorporateKeyParty {
        const corporateKeyParty = new CorporateKeyParty();
        corporateKeyParty.id = form.controls['idCtrl'].value;
        corporateKeyParty.created = form.controls['createdCtrl'].value;
        corporateKeyParty.updated = form.controls['updatedCtrl'].value;

        corporateKeyParty.name = form.controls['nameCtrl'].value;
        corporateKeyParty.dbaName = form.controls['dbaNameCtrl'].value;
        corporateKeyParty.taxId = form.controls['taxIdCtrl'].value;
        corporateKeyParty.businessType = form.controls['businessTypeCtrl'].value;
        corporateKeyParty.email = form.controls['emailCtrl'].value;
        corporateKeyParty.officePhone = form.controls['officePhoneCtrl'].value;

        return corporateKeyParty;
    }

}
