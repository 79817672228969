<div>
    <div class="modal-header">
        <label class="form-title">Select {{ isMember ? 'Member Account' : 'Merchant' }}</label>
        <i class="modal-close" (click)="close(false)">&times;</i>
    </div>
    <div class="modal-content">
        <div class="flex text-center justify-content-center">
            <div class="col-12" *ngIf="!isMember">
                <pc-merchant-searcher [memberId]="null" [jurisdiction]="''" [placeholder]="'Search Recipient'" [formGroup]="userForm" [formCtrl]="'selectedUserCtrl'"  [merchantAccountSources]="merchantAccountSources"
                [includeAddress]="true" [allowDisableSelect]="true" [merchantMemberType]="null" (merchantSelected)="merchantSelected($event)"></pc-merchant-searcher>
            </div>
            <div class="col-12" *ngIf="isMember">
                <pc-member-account-selector [class]="'full'" [formGroup]="userForm" [formCtrl]="'selectedUserCtrl'" (memberAccountSelected)="memberAccountSelected($event)"
                    [includeAddress]="true" [accountStatuses]="[MemberAccountStatus.ACTIVE, MemberAccountStatus.DISABLED]" [excludeTransactMemberAccounts]="true"
                    [memberTypes]="memberTypes" [showStatusBadge]="true" [placeholder]="'Search Member Account'"></pc-member-account-selector>
            </div>
        </div>
        <div *ngIf="isMember && selectedMemberAccount?.id">
            <div class="col-12 m-4 mt-0">
                <span class="fs-7 fw-bold">Member Account Information</span>
                <div class="mb-3"></div>
                <div class="row">
                    <div class="col-6 row">
                        <div class="col-6 expandedLabel">
                            Tier:
                        </div>
                        <div class="col-6 expandedValue">
                            {{ selectedMemberAccount.member.tier | translate }}
                        </div>
                        <div class="col-6 expandedLabel">
                            Jurisdiction:
                        </div>
                        <div class="col-6 expandedValue">
                            {{ selectedMemberAccount.member.jurisdiction }}
                        </div>
                        <div class="col-6 expandedLabel">
                            Account Number:
                        </div>
                        <div class="col-6 expandedValue">
                            {{ selectedMemberAccount.accountNumber }}
                        </div>
                    </div>
                    <div class="col-6">
                        <h6>Address:</h6>
                        <pc-business-address [address]="selectedMemberAccount.address"></pc-business-address>
                    </div>
                </div>
            </div>
            <div class="mb-2"></div>
            <div class="col-12 d-flex justify-content-center align-items-center" *ngIf="selectedMemberAccount">
                <pc-error-message [error]="MEMBER_WARN" [width]="'full'"></pc-error-message>
            </div>
            <div class="mb-3"></div>
            <div class="col-12 d-flex justify-content-end align-items-end" *ngIf="selectedMemberAccount">
                <pc-submit-button [text]="'Select Member'" [submit]="handleSubmit" (clickEvent)="closeMemberDialog()"></pc-submit-button>
            </div>
        </div>
    </div>
</div>