import { Address } from './address';
import { BusinessClientStatus } from './business-client';
import { LinkedBankAccount } from './bank-account';
import { Member } from './member';
import { Wallet } from './wallet';

export class MemberAccount {
    id: string;
    created: Date;
    updated: Date;

    memberId: string;
    member: Member;

    addressId: string;
    address: Address;

    accountNumber: number;
    accountName: string;
    accountFriendlyName: string;
    accountType: MemberAccountType;
    status: MemberAccountStatus | BusinessClientStatus;
    approvedDate: Date;
    disabledDate: Date;
    isPublic = false;
    walletFunctions: WalletFunction [];
    accountNotes: string;
    signatory: string;

    // adhoc fields
    wallet: Wallet;
    linkedBankAccount: LinkedBankAccount;
    selected = false;
    isAdmin = false;


}

export enum WalletFunction {
    ALL = '',
    CASH_DEPOSIT = 'CASH_DEPOSIT',
    ACH_DEPOSIT = 'ACH_DEPOSIT',
    ACCEPT_CONSUMER_PAYMENTS = 'ACCEPT_CONSUMER_PAYMENTS',
    ACCEPT_BUSINESS_PAYMENTS = 'ACCEPT_BUSINESS_PAYMENTS',
    ACH_DIRECT_PAYMENT = 'ACH_DIRECT_PAYMENT',
    ACH_BALANCE_PUSH = 'ACH_BALANCE_PUSH',
    ENABLE_CURRENCY_WITHDRAWAL = 'ENABLE_CURRENCY_WITHDRAWAL',
    ENABLE_PAYMENT_PROCESSING = 'ENABLE_PAYMENT_PROCESSING',
    ALLOW_EXTERNAL_CHECKS = 'ALLOW_EXTERNAL_CHECKS'
}

export enum MemberAccountStatus {
    ALL = '',
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    BLOCKED = 'BLOCKED',
    FROZEN = 'FROZEN',
    APPLIED = 'APPLIED',
    UNDER_REVIEW = 'UNDER_REVIEW',
    DISABLED = 'DISABLED',
    REJECTED = 'REJECTED',
    PENDING = 'PENDING',
    NEW = 'NEW'
}

export enum MemberAccountType {
    ALL = '',
    CONSUMER = 'CONSUMER',
    BUSINESS = 'BUSINESS',
    MERCHANT = 'MERCHANT'
}
