import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import {
    Configuration, CorporateEntity, Member, MemberRegistrationType, MemberService, MemberStatus, MemberType, PagedResponse, Utils
} from 'projects/services/src/public-api';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
    selector: 'pc-member-selector',
    templateUrl: './member-selector.component.html',
    styleUrls: ['./member-selector.component.scss']
})

export class MemberSelectorComponent implements OnInit, OnChanges, AfterViewInit {

    MemberType = MemberType;

    @Input() placeholder = '';
    @Input() label: string;
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;
    @Input() displayLabel = true;
    @Input() memberTypes: MemberType[] = [MemberType.BUSINESS];
    @Input() statuses: MemberStatus[] = [MemberStatus.ACTIVE];
    @Input() excludeSelf = false;
    @Input() excludedId = '';
    @Input() class = '';
    @Input() jurisdiction = '';
    @Input() includeAddress = false;
    @Input() includeStatus = false;
    @Input() required = false;


    @Output() memberSelected = new EventEmitter<Member>();

    memberSelect: Subscription;
    memberAutoComplete: Observable<Member[]> = null;
    autoCompleteControl = new UntypedFormControl('');
    memberOptions: Member[] = [];
    searching = false;
    searchLimit: number;
    totalResults: number;

    @ViewChild('memberSelect', { read: MatAutocompleteTrigger }) trigger: MatAutocompleteTrigger;

    constructor(private memberService: MemberService) {}

    ngOnInit() {
        this.searchLimit = Configuration.getConfig().autoCompleteSearchLimit;
        this.formGroup.addControl('autoCompleteCtrl', this.autoCompleteControl);
        if (this.required) {
            this.formGroup.controls['autoCompleteCtrl'].setValidators(Validators.required);
        }

        this.memberAutoComplete = this.autoCompleteControl.valueChanges.pipe(
            startWith(''),
            distinctUntilChanged(),
            debounceTime(1000),
            switchMap((value) => {
                this.searching = true;
                return this.lookup(value || '');
            })
        );
        this.loadMember();
        this.formGroup.controls[this.formCtrl].valueChanges.subscribe((_value) => {
            this.loadMember();
        });
    }

    ngAfterViewInit() {
        this.subscribeToClosingActions();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.statuses && !changes.statuses.firstChange) ||
            (changes.memberTypes && !changes.memberTypes.firstChange)) {
            this.memberOptions = [];
            this.memberAutoComplete = this.autoCompleteControl.valueChanges.pipe(
                startWith(''),
                distinctUntilChanged(),
                debounceTime(1000),
                switchMap((value) => {
                    this.searching = true;
                    return this.lookup(value || '');
                })
            );
        }
    }

    private subscribeToClosingActions() {
        if (this.memberSelect && !this.memberSelect.closed) {
            this.memberSelect.unsubscribe();
        }

        this.memberSelect = this.trigger.panelClosingActions.subscribe((e) => {
            if (!e || !e.source) {
                if (this.memberOptions.length === 1) {
                    this.memberChanged(this.memberOptions[0]);
                } else {
                    const selected = this.memberOptions
                        .map((option) => {
                            return option.name;
                        })
                        .find((option) => {
                            return option === this.autoCompleteControl.value;
                        });

                    if (!selected) {
                        this.formGroup.controls[this.formCtrl].markAsPristine();
                        this.formGroup.controls[this.formCtrl].setValue('');
                        this.autoCompleteControl.setValue('');
                        this.memberChanged(new Member());
                    }
                }
            }
        }, () => {
            return this.subscribeToClosingActions();
        }, () => {
            return this.subscribeToClosingActions();
        });
    }

    lookup(value: string) : Observable<Member[]> {
        this.totalResults = 0;
        if (value?.length <= 1) {
            this.searching = false;
            this.memberOptions = [];
            return of([]);
        }
        this.formGroup.controls['autoCompleteCtrl'].disable();
        return this.memberService.searchMembers(value, this.memberTypes, this.statuses, this.jurisdiction, this.excludeSelf, this.includeAddress, this.searchLimit).pipe(
            switchMap((response: PagedResponse<Member>) => {
                this.totalResults = response.totalElements;
                this.formGroup.controls['autoCompleteCtrl'].enable();
                if (this.excludedId) {
                    this.memberOptions = response.content.filter((member) => {
                        return member.id !== this.excludedId;
                    });
                } else {
                    this.memberOptions = response.content;
                }
                this.memberOptions.forEach((member) => {
                    this.setMemberName(member);
                    if (member.address) {
                        member.address.country = Utils.getCountryCode(member.address.country);
                    }
                });
                this.searching = false;
                return of(this.memberOptions);
            }), catchError((_error) => {
                this.searching = false;
                this.formGroup.controls['autoCompleteCtrl'].enable();
                return [];
            })
        );
    }

    memberChanged(member: Member) {
        this.formGroup.controls[this.formCtrl].markAsDirty();
        this.formGroup.controls[this.formCtrl].setValue(member.id);
        this.memberSelected.emit(member);
    }

    onInputChanged(event: any) {
        if (event.keyCode === 220) {
            event.preventDefault();
            return;
        }
    }

    selected() {
        return this.formGroup.controls[this.formCtrl].value;
    }

    loadMember() {
        if (this.selected()) {
            this.memberService.loadMember(this.formGroup.controls[this.formCtrl].value).subscribe((member: Member) => {
                if (!member) {
                    this.resetSearchBar();
                    return;
                }
                this.setMemberName(member);
                this.autoCompleteControl.setValue(member.name);
            });
        } else {
            this.autoCompleteControl.setValue('');
        }
    }

    onIconClick(event: any) {
        if (this.selected()) {
            this.resetSearchBar();
        }
        event.stopPropagation();
        this.trigger.openPanel();
    }

    resetSearchBar() {
        this.formGroup.controls[this.formCtrl].setValue('');
        this.memberOptions = [];
        this.memberSelected.emit(new Member());
        this.formGroup.controls['autoCompleteCtrl'].enable();
    }

    setMemberName(member: Member) {
        if (member.memberType === MemberType.BUSINESS || member.memberType === MemberType.BUSINESS_MERCHANT) {
            const corporateEntity = (member as CorporateEntity);
            if (corporateEntity.dbaName && corporateEntity.name !== corporateEntity.dbaName) {
                member.name = corporateEntity.name + ' (DBA: ' + corporateEntity.dbaName + ')';
            }
        }
    }

    getMemberName(member: Member) {
        if (member.memberRegistrationType === MemberRegistrationType.CONSUMER) {
            return member.name;
        }
        return `${member.name} (${member.jurisdiction ? member.jurisdiction : 'All'})`;
    }
}
