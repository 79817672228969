import { User } from './user';
import { MemberAccount } from './member-account';
import { Role } from './profile';
import { Member } from './member';
import { UserAccountStatus } from './user-account';
import { AddressOccupant } from './address-occupant';
import { KeyParty } from './key-party';

export class UserMemberAccess {
    memberId: string;
    member: Member;

    userId: string;
    user: User;

    accountAccess: MemberAccountAccess[];

    isKeyParty: boolean;

    // adhoc fields
    keyParty: KeyParty;
}

export class MemberAccountAccess {

    userAccountId: string;

    memberAccountId: string;
    memberAccount: MemberAccount;

    retailLocationId: string;
    retailLocation: AddressOccupant;

    role: Role;
    status: UserAccountStatus;
    expirationDate: Date;
    position: string;
}
