<div>
    <div class="modal-header">
        <div class="pull-left"><img class="dashboard-icon" src="assets/images/account-2.svg" alt=""></div>
        <label class="form-title">
            {{ isAdmin() ? (subType === TransactionSubType.DEPOSIT ? 'Add Invoices' : 'Add Bills') : (subType === TransactionSubType.DEPOSIT ? 'View Invoices' : 'View Bills') }} for {{currentBusinessClient.name}}:
        </label>
        <i class="modal-close" (click)="close()">&times;</i>
    </div>
    <div class="my-2"></div>
    <div class="d-flex justify-content-center loading" *ngIf="transactionEntriesLoading">
        <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i><span>Loading {{ subType === TransactionSubType.DEPOSIT ? 'invoices' : 'bills' }}...</span>
    </div>
    <div class="d-flex justify-content-center" *ngIf="errorMessage">
        <pc-error-message [error]="errorMessage" [width]="'full'"></pc-error-message>
    </div>
    <div class="transaction-entries" *ngIf="!transactionEntriesLoading">
        <table mat-table #transactionEntriesTable [dataSource]="customerTransactionEntries" multiTemplateDataRows >
            <ng-container matColumnDef="entry_number">
                <th mat-header-cell *matHeaderCellDef>{{ subType === TransactionSubType.DEPOSIT ? 'Invoice' : 'Bill' }} #</th>
                <td mat-cell *matCellDef="let element">
                    <i class="fa" [class.fa-plus-circle]="expandedElement !== element" [class.fa-minus-circle]="expandedElement === element"
                       (click)="expandedElement = (expandedElement === element && element.data.type !== 'OTHER') ? null : element"></i>{{element.data.externalReference || 'N/A'}}</td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="entry_date">
                <th mat-header-cell class="centered" *matHeaderCellDef>{{ subType === TransactionSubType.DEPOSIT ? 'Invoice' : 'Bill' }} Date</th>
                <td mat-cell class="centered" *matCellDef="let element">
                    {{ element.data.txnDate ? (element.data.txnDate | date: 'mediumDate') : 'N/A' }}
                </td>

                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="entry_due_date">
                <th mat-header-cell class="centered" *matHeaderCellDef>Due Date</th>
                <td mat-cell class="centered" *matCellDef="let element">
                    {{ element.data.dueDate ? (element.data.dueDate | date: 'mediumDate') : 'N/A' }}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="entry_unpaid_amount">
                <th mat-header-cell class="centered" *matHeaderCellDef>{{ subType === TransactionSubType.DEPOSIT ? 'Receivable' : 'Payable' }}</th>
                <td mat-cell class="centered" *matCellDef="let element">
                    <span class="table-amount">
                        {{element.data.externalId ? (element.data.unpaidAmount | currency) : 'N/A' }}
                    </span>
                </td>
                <td mat-footer-cell class="centered" *matFooterCellDef><strong>{{ subType === TransactionSubType.DEPOSIT ? 'Invoice' : 'Bill' }} Total:</strong></td>
            </ng-container>
            <ng-container matColumnDef="entry_paid_amount">
                <th mat-header-cell class="centered" *matHeaderCellDef>{{ subType === TransactionSubType.DEPOSIT ? 'Deposit' : 'Payment' }}</th>
                <td mat-cell class="centered" *matCellDef="let element">
                    <span class="dollar">
                        <input [ngModelOptions]="{standalone: true}" [(ngModel)]="element.data.paidAmount" type="text" oninput="value = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" placeholder="0.00" (change)="onEntryChange(element)" [disabled]="!element.inputDisabled || !isAdmin()">
                        <div *ngIf="element.error">
                            <div>
                                <p class="error">{{element.error}}</p>
                            </div>
                        </div>
                    </span>
                </td>
                <td mat-footer-cell class="centered" *matFooterCellDef>
                    <span class="dollar">
                        <input [ngModelOptions]="{standalone: true}" [(ngModel)]="entriesTotal" type="number" disabled="true" readonly="true">
                    </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="entry_included">
                <th mat-header-cell class="centered" *matHeaderCellDef>Include</th>
                <td mat-cell class="centered" *matCellDef="let element">
                    <i class="fa" [class.fa-check-square-o]="element.included" [class.fa-square-o]="!element.included" [class.disabled]="element.checkboxDisabled() || !isAdmin()" (click)="isAdmin() && !element.checkboxDisabled() && onSelected(element)"></i>
                </td>
            </ng-container>
            <ng-container matColumnDef="other_entry_description_label">
                <td mat-cell *matCellDef="let element"><span [class.error]="!isUniversalWhiteListedMerchant ? (!isDescriptionValid(element) && element.data.paidAmount): false">Description:</span></td>
            </ng-container>
            <ng-container matColumnDef="other_entry_description">
                <td mat-cell *matCellDef="let element" colspan="2">
                    <textarea [disabled]="!isAdmin()" maxlength="1000" [ngModelOptions]="{standalone: true}" [(ngModel)]="element.data.description" (change)="onEntryChange(element)"
                              [class.error]="!isUniversalWhiteListedMerchant ? (!isDescriptionValid(element) && element.data.paidAmount) : false"></textarea>
                </td>
            </ng-container>
            <ng-container matColumnDef="other_entry_amount_label">
                <td mat-cell class="centered" *matCellDef="let element">
                    <span>Amount:</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="other_entry_amount">
                <td mat-cell class="centered" *matCellDef="let element">
                    <span class="dollar">
                        <input [ngModelOptions]="{standalone: true}" type="text"
                               oninput="value = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                               [(ngModel)]="element.data.paidAmount" placeholder="0.00" [disabled]="!isAdmin()" (change)="onEntryChange(element)">
                    </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="other_entry_include">
                <td mat-cell class="centered" *matCellDef="let element; let entryIndex = dataIndex;">
                    <i *ngIf="isAdmin()" class="fa fa-2x fa-minus" (click)="removeOtherAmount(entryIndex)"></i>
                </td>
            </ng-container>
            <ng-container matColumnDef="summary_add_row">
                <td mat-footer-cell *matFooterCellDef="let element">
                    <button *ngIf="isAdmin()" class="blue-button button-small" (click)="addNewTransactionEntryRow()" type="button"><i class="fa fa-plus pull-left dashboard-icon"></i><div class="pull-right-button">Add Row</div></button>
                </td>
            </ng-container>
            <ng-container matColumnDef="empty">
                <td mat-cell *matCellDef></td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="entryColumns.length">
                    <div class="example-element-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                        <pc-expense-line [expense]="element.data"></pc-expense-line>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="total_transaction_amount_label">
                <td mat-footer-cell class="centered" *matFooterCellDef>
                    <strong>Transaction Amount:</strong>
                </td>
            </ng-container>
            <ng-container matColumnDef="total_transaction_amount_value">
                <td mat-footer-cell class="centered" *matFooterCellDef>
                    <span class="dollar">
                        <!-- Bind this to a calculated value -->
                        <input [ngModelOptions]="{standalone: true}" [(ngModel)]="transaction.totalAmount" type="number" disabled="true" readonly="true">
                    </span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="entryHeaders; sticky: true"></tr>
            <tr mat-row *matRowDef="let element; columns: entryColumns; when: isEntryFromAccounting"
                class="example-expand-row"
                [class.example-expanded-row]="expandedElement === element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isEntryFromAccounting" class="example-detail-row"></tr>
            <tr mat-row *matRowDef="let element; columns: otherEntryColumns; when: isOtherEntry"></tr>
            <tr mat-footer-row class="total-footer pt-2" *matFooterRowDef="summaryColumns; sticky: true"></tr>
            <tr mat-footer-row class="total-footer pt-2" *matFooterRowDef="totalTransactionAmountColumns"></tr>
        </table>
    </div>
    <div class="modal-footer">
        <div *ngIf="amountMismatchError && !transactionEntriesLoading">
            <pc-error-message [error]="amountMismatchError" [width]="'full'"></pc-error-message>
        </div>
        <div class="mt-3 row">
            <div class="col-12 d-flex justify-content-end">
                <button class="blue-button" (click)="close()" type="button">{{ isAdmin() ? 'Cancel' : 'Close' }}</button>

            </div>
        </div>
        <div class="mt-3 row" *ngIf="isAdmin()">
            <div class="col-12 d-flex justify-content-end">
                <button class="blue-button" [disabled]="!(transactionEntriesValid() && !amountMismatchError && addButtonEnabled)" (click)="updateTransactionEntries()" type="button">
                    Add {{ subType === TransactionSubType.DEPOSIT ? 'Invoice(s)' : 'Bill(s)' }}
                </button>
            </div>
        </div>
    </div>
</div>
