import { Address } from './address';
import { MemberAccount } from './member-account';
import { Member } from './member';

export class LinkedBankAccount {
    id: string;
    memberId: string;
    memberAccountId: string;
    bankAccountId: string;

    bankAccount: BankAccount;
    memberAccount: MemberAccount;
}

export class BankAccount {
    id: string;
    memberId: string;

    type: string;
    subType: string;
    name: string;
    holder: string;
    validated: boolean;
    routingNumber: string;
    wireRoutingNumber: string;
    achIdentifier: string;
    accountNumber: string;
    externalAccountId: string;
    countryCode: string;
    financialInstitution: string;
    status: BankAccountStatus;
    source: BankAccountSource;

    memberAccount: MemberAccount;
    externalAccountDetails: any;
    billingAddress: Address;

    created: Date;
    updated: Date;

    reviewNotes: string;
    verifiedBy: string;
    verifiedDate: Date;

    // adhoc fields
    member: Member;
}

export enum BankAccountStatus {
    ALL = '',
    ACTIVE = 'ACTIVE',
    PENDING = 'PENDING',
    DISABLED = 'DISABLED',
    UNSUPPORTED = 'UNSUPPORTED',
    DELETED = 'DELETED',
    BANK_VALIDATION_REQUIRED = 'BANK_VALIDATION_REQUIRED',
    USER_VALIDATION_REQUIRED = 'USER_VALIDATION_REQUIRED',
    VALIDATION_REQUIRED = 'VALIDATION_REQUIRED',
    SYSTEM_ERROR = 'SYSTEM_ERROR',
    DUPLICATE_BANK_ACCOUNT = 'DUPLICATE_BANK_ACCOUNT',
    REJECTED = 'REJECTED'
}

export enum BankAccountSource {
    YODLEE = 'YODLEE',
    USER_ENTERED = 'USER_ENTERED',
    AUTHORITY_VERIFIED = 'AUTHORITY_VERIFIED',
    EXTERNALLY_PROVIDED_ACCOUNT = 'EXTERNALLY_PROVIDED_ACCOUNT',
    PAYMENT_PROCESSOR = 'PAYMENT_PROCESSOR'
}
