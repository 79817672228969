import { Router } from '@angular/router';
import { Inject, Injectable } from '@angular/core';

import { HttpService } from '../http.service';
import { IDPService } from './idp.service';
import { catchError, switchMap } from 'rxjs/operators';
import { Profile, Role } from '../models/profile';
import { AccountingService } from '../accounting.service';
import { StompService } from '../stomp.service';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { Observable, of } from 'rxjs';
import { RefreshService } from 'projects/pt/src/app/refresh/refresh.service';
import { CountNotificationService } from '../count-notification.service';
import { MemberFunction, MemberRegistrationType, MemberType } from '../models/member';
import { MemberAccount } from '../models/member-account';
import { MemberAccountAccess } from '../models/user-member-access';
@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private interval: any;

    baseUrl: string;

    constructor(private http: HttpService,
                private router: Router,
                private idpService: IDPService,
                private accountingService: AccountingService,
                private stompService: StompService,
                private notifier: NotificationService,
                private countNotificationService: CountNotificationService,
                private refresh: RefreshService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.SecurityAPIEndpoint + '/';
    }

    signinUserOrg(authRequest: any, memberId: string, successCallback: any, errorCallback: any) {
        this.idpService.signinUser(authRequest, (token: string) => {
            localStorage.setItem('token', token);
            this.getAuthenticatedProfile().subscribe(
                () => {
                    this.updateAuthentication(memberId, false).subscribe(
                        (updatedProfile: Profile) => {
                            if (updatedProfile.memberType === MemberType.FEE_ACCOUNT) {
                                this.updateAuthentication(authRequest.authority, true).subscribe((updatedProfile: any) => {
                                    this.initializeApplication(updatedProfile, successCallback, 'dashboard', true);
                                });
                            } else {
                                this.initializeApplication(updatedProfile, successCallback);
                            }
                        });
                }
            );
        }, (error: any) => {
            errorCallback(error);
        });
    }

    updatePassword(passwordDetails: any) {
        return this.idpService.updatePassword(passwordDetails);
    }

    updateAuthentication(memberId: string, feeAccount: boolean) {
        return this.idpService.updateCurrentMember(memberId, feeAccount).pipe(
            switchMap(
                (response: any) => {
                    this.setToken(response.token);
                    return this.getAuthenticatedProfile();
                }
            )
        );
    }

    selectRetailLocation(memberId: string, retailLocationId: string) {
        return this.idpService.updateCurrentRetailLocation(memberId, retailLocationId).pipe(
            switchMap(
                (response: any) => {
                    this.setToken(response.token);
                    return this.getAuthenticatedProfile();
                }
            )
        );
    }

    initializeApplication(profile: Profile, callback: any, redirectUrl?: string, forceReload?: boolean) {
        localStorage.setItem('profile', JSON.stringify(profile));
        if (this.isAdmin()){
            // request an update of records for admins who make deposits / payments
            this.accountingService.updateAccountingRecords(profile.memberId);
        }
        this.stompService.connect(this.getToken());
        if (callback) {
            callback();
        }
        if (redirectUrl) {
            const urlTree = this.router.parseUrl(redirectUrl);
            this.router.navigateByUrl(urlTree).then(() => {
                if (forceReload) {
                    window.location.reload();
                }
            });
        } else if (this.isRetailer()) {
            this.router.navigate(['/transaction/list']);
        } else {
            this.router.navigate(['dashboard']);
        }
    }

    verifyEmailPassword(authRequest: any, callback: any, errorCallback: any) {
        this.idpService.signinUser(authRequest, callback, errorCallback);
    }

    isAuthority() {
        return this.hasRole(Role.AUTHORITY);
    }

    isAuthorityOrReviewer() {
        return this.hasAnyRole([Role.AUTHORITY, Role.AUTHORITY_READONLY]);
    }

    isAuthoritySuperUser() {
        return this.hasAnyRole([Role.AUTHORITY_SUPERUSER]);
    }

    isAuthorityOrAdmin(memberAccount?: MemberAccount) {
        if (memberAccount) {
            return this.hasAnyRole([Role.AUTHORITY, Role.CORPORATE_ADMIN]) || this.isAccountAdmin(memberAccount);
        }
        return this.hasAnyRole([Role.AUTHORITY, Role.CORPORATE_ADMIN, Role.ADMIN]);
    }

    isAuthorityOrCorporateAdmin() {
        return this.hasAnyRole([Role.AUTHORITY, Role.CORPORATE_ADMIN]);
    }

    isCorporateAdmin() {
        return this.hasRole(Role.CORPORATE_ADMIN);
    }

    isAdmin(memberAccount?: MemberAccount) {
        if (memberAccount) {
            return this.isAccountAdmin(memberAccount);
        }
        return this.hasRole(Role.ADMIN);
    }

    isAccountAdmin(memberAccount?: MemberAccount) {
        return this.isCorporateAdmin() || this.hasAccountRole(memberAccount, Role.ADMIN);
    }

    isMember(memberAccount?: MemberAccount) {
        if (memberAccount) {
            return this.hasAccountRole(memberAccount, Role.MEMBER);
        }
        return this.hasRole(Role.MEMBER);
    }

    isMemberOrMemberReviewer(memberAccount?: MemberAccount) {
        if (memberAccount) {
            return this.hasAccountRole(memberAccount, Role.MEMBER_READONLY);
        }
        return this.hasRole(Role.MEMBER_READONLY);
    }

    isRetailer(memberAccount?: MemberAccount) {
        if (memberAccount) {
            if (memberAccount.id !== this.getProfile().retailAccountId) {
                return false;
            }
            return this.hasAccountRole(memberAccount, Role.RETAILER);
        }
        return this.hasRole(Role.RETAILER);
    }

    isConsumer() {
        return this.hasRole(Role.CONSUMER);
    }

    isNewRegistrant() {
        return this.hasAnyRole([Role.NEW_ADMIN_REGISTRANT, Role.NEW_REGISTRANT]);
    }

    hasAccountRole(memberAccount: MemberAccount, role: Role) {
        const profile = this.getProfile();
        if (profile) {
            return !!profile.access.find((access: MemberAccountAccess) => {
                return access.memberAccountId === memberAccount.id && access.role === role;
            });
        }
        return false;
    }

    private hasRole(role: Role) {
        const profile = this.getProfile();
        if (profile) {
            return (profile.authorities.indexOf(role) >= 0);
        }
        return false;
    }

    hasAnyRole(roles: Role[]) {
        const profile = this.getProfile();
        for (const role of roles) {
            if (profile) {
                if (profile.authorities.indexOf(role) >= 0) {
                    return true;
                }
            }
        }
        return false;
    }

    getMemberRegistrationType(): MemberRegistrationType {
        return this.getProfile().memberRegistrationType || null;
    }

    hasMemberFunction(memberFunction: MemberFunction) {
        const profile = this.getProfile();
        if (profile) {
            return (profile.memberFunctions.indexOf(memberFunction) >= 0);
        }
        return false;
    }

    getProfile(): Profile {
        return JSON.parse(localStorage.getItem('profile'));
    }

    setProfile(profile: Profile) {
        localStorage.setItem('profile', JSON.stringify(profile));
    }

    getAuthenticatedProfile(): Observable<Profile> {
        return this.http.get(`${this.baseUrl}profile`).pipe(
            switchMap(
                (response: Profile) => {
                    this.setProfile(response);
                    return of(response);
                }
            )
        );
    }

    setToken(token: any) {
        localStorage.setItem('token', token);
    }

    getToken() {
        return localStorage.getItem('token');
    }

    isAuthenticated() {
        return localStorage.getItem('token') && localStorage.getItem('profile');
    }

    logout(redirectUrl?: string) {
        if (redirectUrl) {
            this.router.navigate(['/login'], { queryParams: { redirectUrl } }).then((navigated: boolean) => {
                if (navigated) {
                    this.clear();
                }
            });
        } else {
            this.router.navigate(['/login']).then((navigated: boolean) => {
                if (navigated) {
                    this.clear();
                }
            });
        }
    }

    logoutAndMessage(index: number) {
        this.router.navigate(['/pending', index]).then((navigated: boolean) => {
            if (navigated) {
                this.clear();
            }
        });
    }

    clear() {
        this.stompService.disconnect();
        this.clearInterval();
        localStorage.removeItem('token');
        localStorage.removeItem('profile');
        localStorage.removeItem('currentTask');
        this.notifier.clear();
        this.refresh.clear();
        this.countNotificationService.clear();
        this.idpService.logout();
    }

    userExists(username: any) {
        return this.idpService.userExists(username);
    }

    setInterval(interval: any) {
        this.interval = interval;
    }

    clearInterval() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    refreshToken() {
        return this.idpService.refresh().pipe(
            switchMap(
                (response: any) => {
                    this.setToken(response.token);
                    return this.getAuthenticatedProfile();
                }),
            catchError(
                (error: any) => {
                    return of(error);
                }
            )
        );
    }

    setMultiMember() {
        const profile = this.getProfile();
        profile.multiMember = true;
        this.setProfile(profile);
    }
}
