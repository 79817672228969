import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    AccountingPartyType, AccountingService, Address, AuthService, AccountingLinkedMember, ExternalAccountingId, AddressService, MemberRegistrationType, MerchantAccount,
    MerchantAccountService, MerchantAccountStatus, MemberAccountStatus, PagedResponse, RiskTier, SupportedTransactionType, Utils, Member, MemberType, WorkflowService
} from 'projects/services/src/public-api';
import { AddAccountingMerchantModalComponent, AddMerchantModalComponent, EditMerchantAccountingIdModalComponent } from 'projects/pt/src/app/components';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'pt-member-merchant-list',
    templateUrl: './member-merchant-list.component.html',
    styleUrls: ['./member-merchant-list.component.scss']
})
export class MemberMerchantListComponent implements OnInit, AfterViewInit, OnDestroy {

    MerchantAccountStatus = MerchantAccountStatus;
    SupportedTransactionType = SupportedTransactionType;
    AccountingPartyType = AccountingPartyType;
    MemberType = MemberType;
    RiskTier = RiskTier;
    Utils = Utils;

    @Input() memberId: string;
    @Input() jurisdiction: string;
    @Input() merchantActionsAllowed = false;
    @Input() tier: RiskTier;
    @Input() memberPageView = false;

    isLoadingResults = true;
    resultsLength = 0;
    subscription: any;
    pageTracking: PageTracking;
    filterEvent: EventEmitter<null> = new EventEmitter<null>();
    merchantAccounts: MerchantAccount[];
    displayColumns = ['business_name', 'contact_info', 'type', 'transaction_types', 'status', 'action'];
    accountingLinked = false;

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private route: ActivatedRoute,
                private router: Router,
                public authService: AuthService,
                private merchantAccountService: MerchantAccountService,
                private addressService: AddressService,
                private accountingService: AccountingService,
                private workflowService: WorkflowService,
                private dialog: MatDialog,
                private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.accountingService.getAccountingSystemInfo(this.memberId).subscribe((accountingSystem: AccountingLinkedMember) => {
            this.accountingLinked = accountingSystem?.linked;
        });
        if (this.authService.isAuthorityOrReviewer()) {
            this.displayColumns = ['business_name', 'contact_info', 'type', 'transaction_types', 'accounting_ids', 'status', 'action'];
        }
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'merchantMemberAccount.member.sortName', 'asc');
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    isAdmin() {
        return this.authService.isAdmin();
    }

    isPremiumMember() {
        return MemberRegistrationType.PREMIUM_MEMBER === this.authService.getMemberRegistrationType();
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.paginator.page, this.filterEvent).pipe(
            startWith({}),
            switchMap(() => {
                UIUtils.scrollToTop(document.querySelector('.mat-table-container'));
                this.isLoadingResults = true;
                return this.merchantAccountService.getMerchantAccounts(this.memberId, [], this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<MerchantAccount>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((response: MerchantAccount[]) => {
            this.merchantAccounts = response;
            for (const merchant of this.merchantAccounts) {
                this.loadMerchantData(merchant.merchantMemberAccount.member);
            }
        });
    }

    loadMerchantData(merchantMember: Member) {
        // load the address
        this.addressService.getPrimaryAddress(merchantMember.id).subscribe((location: Address) => {
            merchantMember.address = location;
        });
    }

    isEnabled(merchantAccount: MerchantAccount) {
        return merchantAccount && merchantAccount.status === MerchantAccountStatus.ACTIVE && merchantAccount.merchantAccountConfig.status === MerchantAccountStatus.ACTIVE;
    }

    toggleEnabled(event: any, merchantAccount: MerchantAccount) {
        if (this.isEnabled(merchantAccount)) {
            this.merchantAccountService.updateMerchantAccount(merchantAccount.id, { status: MemberAccountStatus.DISABLED }).subscribe(() => {
                this.filterEvent.emit();
            });
        } else {
            this.merchantAccountService.updateMerchantAccount(merchantAccount.id, { status: MemberAccountStatus.ACTIVE }).subscribe(() => {
                this.filterEvent.emit();
            });
        }
        event.cancelBubble = true;
        if (event.stopImmediatePropagation) {
            event.stopImmediatePropagation();
        }
    }

    onRequestNewMerchant() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'full-modal';
        dialogConfig.data = {
            shared: false,
            memberId: this.memberId,
            accountingLinked: this.accountingLinked,
            jurisdiction: this.tier === RiskTier.TIER_1 ? this.jurisdiction : ''
        };
        const dialogRef = this.dialog.open(AddMerchantModalComponent, dialogConfig);

        dialogRef?.afterClosed().subscribe((refresh: boolean) => {
            if (refresh) {
                this.filterEvent.emit();
            }
        });
    }

    onRequestNewAccountingMerchant() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'full-modal';
        dialogConfig.data = {
            memberId: this.memberId,
            accountingLinked: this.accountingLinked
        };
        const dialogRef = this.dialog.open(AddAccountingMerchantModalComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((refresh: boolean) => {
            if (refresh) {
                this.filterEvent.emit();
            }
        });
    }

    editAccountingId(event: any, merchantAccount: MerchantAccount) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.data = {
            merchantAccount: merchantAccount
        };
        const dialogRef = this.dialog.open(EditMerchantAccountingIdModalComponent, dialogConfig);

        dialogRef?.afterClosed().subscribe((refresh: boolean) => {
            if (refresh) {
                this.filterEvent.emit();
            }
        });
        event.cancelBubble = true;
        if (event.stopImmediatePropagation) {
            event.stopImmediatePropagation();
        }
    }

    getAccountingIds(merchantAccount: MerchantAccount, accountingPartyType: AccountingPartyType): ExternalAccountingId[] {
        if (!merchantAccount.accountingExternalIds.length) {
            return [];
        } else {
            return merchantAccount.accountingExternalIds.filter((accountExternalId: ExternalAccountingId) => {
                return accountExternalId.accountingPartyType === accountingPartyType;
            });
        }
    }

    canEnableDisableMerchant(merchantAccount: MerchantAccount) {
        if (merchantAccount.member.tier === RiskTier.TIER_1 && this.tier === RiskTier.TIER_1 &&
            this.jurisdiction !== merchantAccount.merchantMemberAccount.member.jurisdiction) {
            return false;
        } else {
            return merchantAccount.merchantAccountConfig.status === MerchantAccountStatus.ACTIVE
                && (this.authService.isAdmin() || (this.authService.isAuthority() && this.merchantActionsAllowed));
        }
    }

    merchantDetails(event: any, memberId: string, status: MemberAccountStatus, memberType: MemberType) {
        if (event.target && event.target.tagName !== 'I') {
            if (status === MemberAccountStatus.UNDER_REVIEW) {
                // open the application this only works if the task is on the authority
                this.workflowService.loadTaskByProcessDefinitionWithVariables(['register_business_merchant_v2', 'register_individual_merchant_v2'], `merchantMemberId_eq_${memberId}`);
            } else if (memberType === MemberType.INDIVIDUAL_MERCHANT) {
                // go see the application or user view
                this.router.navigate(['/administration/individual/', memberId], {queryParams: {_activeTab: 'individual'}});
            } else {
                this.router.navigate(['/administration/merchant/', memberId], {queryParams: {_activeTab: 'business'}});
            }
        }
    }
}
