import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsaReport } from '../models/bsa-report';
import { CustomValidators } from '../validators/custom.validators';

@Injectable({
    providedIn: 'root'
})
export class ReportsFormService {

    constructor(private formBuilder: UntypedFormBuilder,
                private datePipe: DatePipe) {
    }

    initializeForm(report: BsaReport): UntypedFormGroup {
        return this.formBuilder.group({
            fincenTrackingCtrl: new UntypedFormControl(report.trackingId, [Validators.minLength(5), Validators.maxLength(20), CustomValidators.noWhitespace, CustomValidators.noLeadingOrTrailingWhitespace]),
            bsaIdCtrl: new UntypedFormControl(report.bsaId, [Validators.minLength(5), Validators.maxLength(20), CustomValidators.noWhitespace, CustomValidators.noLeadingOrTrailingWhitespace, CustomValidators.onlyNumeric]),
            acknowledgedDateCtrl: new UntypedFormControl(report.acknowledgedDate),
            submissionDateCtrl: new UntypedFormControl(report.submissionDate),
            statusCtrl: new UntypedFormControl(report.status),
            notesCtrl: new UntypedFormControl(report.notes, [Validators.maxLength(8000)]),
            narrativeCtrl: new UntypedFormControl(report.narrativeText, [Validators.minLength(20), Validators.maxLength(20000)]),
            externalProviderNotesCtrl: new UntypedFormControl(report.externalProviderNotes, [Validators.maxLength(8000)])
        });
    }

    getTrackingDetails(report: BsaReport, form: UntypedFormGroup) {
        return {
            reportId: report.id,
            trackingId: form.get('fincenTrackingCtrl').value,
            bsaId: form.get('bsaIdCtrl').value,
            status: form.get('statusCtrl').value,
            acknowledgedDate: form.get('acknowledgedDateCtrl')?.value?.singleDate?.jsDate ? this.datePipe.transform(form.get('acknowledgedDateCtrl').value.singleDate.jsDate, 'yyyy-MM-dd') : null,
            notes: form.get('notesCtrl').value,
            narrativeText: form.get('narrativeCtrl').value,
            submissionDate: form.get('submissionDateCtrl')?.value?.singleDate?.jsDate ? this.datePipe.transform(form.get('submissionDateCtrl').value.singleDate.jsDate, 'yyyy-MM-dd') : null,
            externalProviderNotes: form.get('externalProviderNotesCtrl').value
        };
    }
}
