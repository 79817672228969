import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'pc-auto-complete-street-address',
    templateUrl: './auto-complete-street-address.component.html'
})
export class AutoCompleteStreetAddressComponent implements OnInit, OnChanges {

    @Input() country: string;
    @Input() placeholder = '';
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;
    @Input() label: string;

    @Output() setAddress: EventEmitter<any> = new EventEmitter();
    @Output() changed: EventEmitter<any> = new EventEmitter();

    options: any;
    debounceTime = 250;

    ngOnInit() {
        this.initOptions();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.country && !changes?.country.firstChange) {
            this.initOptions();
        }
    }

    initOptions() {
        this.options =  {
            componentRestrictions: { country: this.country || []},
            types: ['address']
        };
    }

    placeChangedCallback(address: any) {
        this.setAddress.emit(address);
    }

    onInputChanged(event: any) {
        if (event.keyCode === 220 || event.keyCode === 222) {
            event.preventDefault();
            return;
        }
    }

    onValueChanged(value: any) {
        this.changed.emit(value);
    }
}
