<div>
    <form [formGroup]="msaLicenseForm">
        <div>
            <div class="modal-header">
                <label class="form-title">{{ license.msaKeyPartyId ? 'Update MSA Agreement' : 'Add New MSA Agreement'}}</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content-medium">
                <div>
                    <pc-form-selector
                        [label]="'License Holder'"
                        [formCtrl]="'msaKeyPartyIdCtrl'"
                        [formGroup]="msaLicenseForm"
                        [options]="licenseHolders"
                        [idValue]="'id'"
                        [displayValue]="'name'"
                        [defaultValue]="''">
                    </pc-form-selector>
                    <pc-form-date-picker
                        [label]="'Start Date'"
                        [historical]="true"
                        [defaultEmpty]="!license.msaStartDate"
                        [formCtrl]="'msaStartDateCtrl'"
                        [formGroup]="msaLicenseForm">
                    </pc-form-date-picker>
                    <pc-form-date-picker
                        [label]="'Expiration Date'"
                        [defaultEmpty]="!license.msaExpirationDate"
                        [formCtrl]="'msaExpirationDateCtrl'"
                        [formGroup]="msaLicenseForm">
                    </pc-form-date-picker>
                </div>
                <pc-form-text-area *ngIf="msaLicenseForm"
                    [label]="'Scope'"
                    [formCtrl]="'msaScopeCtrl'"
                    [formGroup]="msaLicenseForm"
                    [rows]="15">
                </pc-form-text-area>
                <label class="form-label">MSA Agreement</label>
                <div *ngIf="msaDocumentFile">
                    <i class="fa fa-times" (click)="onDeleteMsaDocumentFile()"></i>{{msaDocumentFile.name}}
                </div>
                <div *ngIf="!msaDocumentFile" class="required">
                    Please attach MSA agreement.
                </div>
            </div>
            <div *ngIf="error" class="error-message mb-4">
                <div class="form-error" [innerHTML]="error"></div>
            </div>
            <input hidden type="file" id="msaDocumentUpload" #msaDocumentUpload (change)="selectMsaDocumentFile($event)" placeholder="Attach MSA Agreement" accept=".pdf,.doc,.docx">
            <div class="modal-footer">
                <button class="blue-button" type="button" (click)="msaDocumentUpload.click()" [class]="!msaDocumentFile ? 'required': ''">Attach MSA</button>
                <pc-submit-button [enabled]="isMsaLicenseFormValid" [submit]="onSubmit" [text]="license.msaKeyPartyId ? 'Update Agreement' : 'Add Agreement'">
                </pc-submit-button>
            </div>
        </div>
    </form>
</div>
