import { GQLQueryType } from 'projects/services/src/lib/graphql/queries/gql-query-type';
import { gqlMemberFields } from 'projects/services/src/lib/graphql/queries/entities/gql-member.queries';
import { gqlAddressFields } from 'projects/services/src/lib/graphql/queries/entities/gql-address.queries';

export const gqlMerchantMemberAccountFields = `
    id
    accountNumber
    memberId
    member{${gqlMemberFields}}
    addressId
    address{${gqlAddressFields}}
    accountName
    accountFriendlyName
    isPublic
    approvedDate
    disabledDate
    accountType
    status
    walletFunctions
`;

export const gqlMerchantAccountConfigFields = `
    id
    merchantMemberAccount{${gqlMerchantMemberAccountFields}}
    status
    merchantAccountType
    defaultIncomingTransactionType
    defaultOutgoingTransactionType
    source
    merchantAccountNote
    shared
    merchantEmailNotification
    activeAccountsCount
`;

export abstract class GqlMerchantQueries {

    static gqlMerchantPageFragment = 'MerchantPage';

    static searchMerchantAccountConfigs(): GQLQueryType {
        return {
            params(): String {
                return '$merchantMemberId: String, $merchantMemberAccountId: String, $jurisdiction: String, $sources: [String], $merchantMemberType: String';
            },

            query(selectedFields: String = gqlMerchantAccountConfigFields, pageParams: String): String {
                return `searchMerchantAccountConfigs(merchantMemberId: $merchantMemberId, merchantMemberAccountId: $merchantMemberAccountId, jurisdiction: $jurisdiction, 
                            sources: $sources, merchantMemberType: $merchantMemberType, 
                            ${pageParams}){

                        content{
                            ${selectedFields}
                        }
                }`;
            }
        };
    }
}