<div class="row mb-3">
    <div class="col-12" >
        <mat-card class="shadow">
            <div class="d-flex row mb-2">
                <div class="col-6 account-name">
                    <div>
                        <i *ngIf="!memberAccount.isPublic" class="fa fa-user-secret" title="Private"></i>
                        <a *ngIf="authService.isAuthorityOrReviewer() || !underReview()" [routerLink]="['/member-accounts/details/' + memberAccount.id]"
                           [routerLinkActive]="['router-link-active']">
                            {{memberAccount.accountName}}
                        </a>
                        <span *ngIf="!authService.isAuthorityOrReviewer() && underReview()">{{memberAccount.accountName}}</span>
                    </div>
                    <div *ngIf="memberAccount.accountFriendlyName">{{memberAccount.accountFriendlyName}}</div>
                    <div *ngIf="memberAccount.accountType !== MemberAccountType.MERCHANT && memberAccount.status !== MemberAccountStatus.UNDER_REVIEW"><b>Account Number:</b> {{memberAccount.accountNumber}}</div>
                    <div *ngIf="memberAccount.accountType !== MemberAccountType.MERCHANT && !underReview() && isAccountIdVisible()">
                        <span>
                            <b>Account ID:</b> {{memberAccount.id}}<pc-clipboard-gadget value="{{memberAccount.id}}"></pc-clipboard-gadget>
                        </span>
                    </div>
                    <div *ngIf="isDisabledOrBlockedOrRejected(memberAccount.status)">
                        <b>Disabled Date:</b> {{ memberAccount.disabledDate | date:'mediumDate' }}
                    </div>
                    <div>
                        <pc-member-account-badge [parent]="memberAccount"></pc-member-account-badge>
                    </div>
                    <div *ngIf="memberAccount.accountType !== MemberAccountType.MERCHANT && !underReview()">
                        <a *ngIf="connectedMembers.length && authorityView" [routerLink]="['/administration/member/' + memberAccount.memberId]"
                           [queryParams]="{_activeTab: 'partners'}"
                           [routerLinkActive]="['router-link-active']">
                            Partner Access: {{connectedMembers.length}}
                        </a>
                        <a *ngIf="connectedMembers.length && !authorityView" [routerLink]="['/administration/settings']" [queryParams]="{_activeTab: 'partners'}"
                           [routerLinkActive]="['router-link-active']">
                            Partner Access: {{connectedMembers.length}}
                        </a>
                    </div>
                </div>
                <div class="balances col-6" *ngIf="memberAccount.wallet">
                    <div class="row mb-2" *ngIf="memberAccount.isAdmin">
                        <div class="col-11" *ngIf="memberAccount.address">
                            {{memberAccount.address.streetAddressOne}}, {{memberAccount.address.city}}, {{memberAccount.address.stateProvince}} {{memberAccount.address.zipPostalCode}}
                        </div>
                        <div class="col-11" *ngIf="!memberAccount.address">
                            <i class="fa fa-exclamation-circle error"></i>Address not linked to account.
                        </div>
                        <div *ngIf="authService.isAuthority() && memberAccount.member.status === MemberStatus.ACTIVE && memberAccount.accountType !== MemberAccountType.CONSUMER" class="col-1 px-0">
                            <i class="fa" [class.fa-toggle-on]="memberAccount.status === MemberAccountStatus.ACTIVE" [class.fa-toggle-off]="memberAccount.status === MemberAccountStatus.DISABLED" (click)="toggleAccountActive()"></i>
                        </div>
                    </div>
                    <div class="row" *ngIf="memberAccount.isAdmin">
                        <div class="col-12" *ngIf="memberAccount.linkedBankAccount">
                            <i class="fa fa-check"></i>Banking information linked ({{getBankAccountNumber()}}).
                        </div>
                        <div class="col-12" *ngIf="!memberAccount.linkedBankAccount">
                            <i class="fa fa-warning"></i>Banking information not linked to account.
                        </div>
                    </div>
                    <div class="row" *ngIf="memberAccount.isAdmin && !accountingLoading">
                        <div class="col-12" *ngIf="!accountingConnected">
                            <i class="fa fa-warning"></i>Accounting system not linked.
                        </div>
                        <div class="col-12" *ngIf="accountingConnected && !reconciliationAccountName">
                            <i class="fa fa-warning"></i>Accounting not reconciling.
                        </div>
                        <div class="col-12" *ngIf="accountingConnected && reconciliationAccountName">
                            <i class="fa fa-check"></i>Accounting reconciling to {{reconciliationAccountName}}.
                        </div>
                    </div>
                    <dl class="row mt-3">
                        <dt class="col-8">Available Balance<i class="fa fa-question-circle-o" matTooltip="The available balance includes all completed payments and deposits, as well as any pending ACH/WIRE payments. Pending checks and scheduled payments have not been deducted from the available balance."></i>:</dt>
                        <dd class="col-4 text-end" [ngClass]="memberAccount.wallet.availableBalance < 0 ? 'negative-amount' : ''">{{ memberAccount.wallet.availableBalance | currency }}</dd>
                    </dl>
                    <dl class="row">
                        <dt class="col-8">Pending Incoming Amount<i class="fa fa-question-circle-o" matTooltip="Pending incoming funds have not yet completed. They have not yet been added to your available balance."></i>:</dt>
                        <dd class="col-4 text-end">{{ memberAccount.wallet.pendingBalance | currency }}</dd>
                    </dl>
                    <dl class="row" *ngIf="isDepositorAccount">
                        <dt class="col-8">Outstanding Check Amount<i class="fa fa-question-circle-o" matTooltip="Outstanding amount of all pending checks that have not yet been cashed. They have not been deducted from your available balance."></i>:</dt>
                        <dd class="col-4 text-end" [ngClass]="memberAccount.wallet.outstandingCheckAmount > 0 ? 'negative-amount' : ''">{{ memberAccount.wallet.outstandingCheckAmount | currency }}</dd>
                    </dl>
                </div>
                <div class="balances col-6" *ngIf="memberAccount.accountType === MemberAccountType.MERCHANT || underReview()">
                    <div class="col-12 mb-2" *ngIf="memberAccount.address">
                        {{memberAccount.address.streetAddressOne}}, {{memberAccount.address.city}}, {{memberAccount.address.stateProvince}} {{memberAccount.address.zipPostalCode}}
                    </div>
                    <div class="col-12 mb-2" *ngIf="!memberAccount.address">
                        <i class="fa fa-warning"></i>Address not linked to account.
                    </div>
                    <div class="col-12" *ngIf="memberAccount.linkedBankAccount">
                        <i class="fa fa-check"></i>Banking information linked ({{getBankAccountNumber()}}).
                    </div>
                    <div class="col-12" *ngIf="!memberAccount.linkedBankAccount">
                        <i class="fa fa-warning"></i>Banking information not linked to account.
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
</div>
