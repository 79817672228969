import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Configuration, WorkflowService, PagedResponse, BankAccount, BankAccountService, BusinessCategory, MemberService, Member, RiskTier } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-complete-member-registration',
    templateUrl: './complete-member-registration.component.html'
})
export class CompleteMemberRegistrationComponent implements OnInit {

    taskId: string;
    memberId: string;
    requiresMrb = false;
    requiresAccounting = false;
    requiresBankAccount = false;
    mrbLinked = false;
    accountingLinked = false;
    member: Member;

    constructor(private memberService: MemberService,
                private router: Router,
                private bankAccountService: BankAccountService,
                private workflowService: WorkflowService) {
    }

    ngOnInit() {
        this.onCompleteTask = this.onCompleteTask.bind(this);
        if (this.workflowService.getCurrentTask()) {
            const task = this.workflowService.getCurrentTask();
            this.taskId = task.id;
            this.memberId = task.variables.memberId;
            this.memberService.loadMember(this.memberId).subscribe((member: Member) => {
                this.member = member;
                if (member.businessCategory) {
                    this.requiresMrb = member.businessCategory === BusinessCategory.MRB_LICENSED_BUSINESS
                        && Configuration.getConfig().metrcEnabled
                        && (member.tier === RiskTier.TIER_1 || member.tier === RiskTier.TIER_2);
                }
                if (member.metaInfo.accountingSystem !== null) {
                    this.requiresAccounting = (member.metaInfo.accountingSystem !== 'None');
                }
                if (member.metaInfo.linkBankAccount !== null) {
                    this.bankAccountService.getAllActiveBankAccounts(this.memberId).subscribe((accounts: PagedResponse<BankAccount>) => {
                        this.requiresBankAccount = accounts.content.length === 0;
                    });
                }
            });
        }
    }

    onCompleteTask() {
        this.workflowService.completeTask(this.taskId, {}).subscribe(() => {
            this.router.navigate(['/dashboard']);
        });
    }

    updateLinkedSystems(data: any) {
        this.mrbLinked = data.mrbLinked;
        this.accountingLinked = data.accountingLinked;
    }

    requiredSystemsLinked() {
        return (!this.requiresMrb && !this.requiresAccounting) ||
            (this.requiresMrb && this.mrbLinked && !this.requiresAccounting) ||
            (this.requiresAccounting && this.accountingLinked && !this.requiresMrb) ||
            (this.requiresMrb && this.mrbLinked && this.requiresAccounting && this.accountingLinked);
    }

    manageBankAccounts() {
        this.router.navigate(['/administration/settings'], {queryParams: { _activeTab: 'bank_accounts' }} );
    }
}
