import { Component, Input, OnInit, OnChanges, SimpleChanges, EventEmitter, ViewChild, ChangeDetectorRef, OnDestroy, AfterViewInit } from '@angular/core';
import {
    UserAccount, UserAccountService, AddressService, User, AuthService, PagedResponse, WorkflowService, UserAccountStatus, UserStatus, Profile, Role, UserUpdatableField
} from 'projects/services/src/public-api';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmModalComponent, NewUserModalComponent, InfoModalComponent, UserFieldUpdateModalComponent } from 'projects/components/src/lib/modal';
import { TranslatePipe } from '@ngx-translate/core';
import { LoaderComponent } from 'projects/components/src/public-api';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay } from '@angular/cdk/overlay';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pc-authority-users-list',
    templateUrl: './authority-users-list.component.html',
    styleUrls: ['authority-users-list.component.scss']
})
export class AuthorityUsersListComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy  {

    UserAccountStatus = UserAccountStatus;
    UserStatus = UserStatus;
    Role = Role;

    @Input() changeTrigger: number;
    @Input() memberId: string;

    isLoadingResults = true;
    pageTracking: PageTracking;
    resultsLength = 0;
    subscription: any;
    accounts: UserAccount[] = [];
    profile: Profile;
    displayedColumns: string[] = ['name', 'status', 'role', 'actions'];

    refreshEvent: EventEmitter<null> = new EventEmitter<null>();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private userAccountService: UserAccountService,
                public authService: AuthService,
                private workflowService: WorkflowService,
                private overlay: Overlay,
                private translatePipe: TranslatePipe,
                private route: ActivatedRoute,
                private router: Router,
                private addressService: AddressService,
                private dialog: MatDialog,
                private cdr: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.changeTrigger && !changes?.changeTrigger.firstChange)) {
            this.refreshPage();
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnInit() {
        this.profile = this.authService.getProfile();
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'status', 'asc', 100);
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.paginator.page, this.refreshEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                return this.userAccountService.loadUserAccountsByMemberId(this.memberId, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<UserAccount>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((accounts: UserAccount[]) => {
            const results = [];
            for (const account of accounts) {
                this.initializeAccount(account);
                results.push(account);
            }
            this.accounts = results;
            UIUtils.scrollDashboardToTop();
        });
    }

    initializeAccount(userAccount: UserAccount) {
        userAccount.user.accountRole = this.translatePipe.transform(userAccount.role);
    }

    isAuthority() {
        return this.authService.isAuthority();
    }

    isAuthoritySuperuser() {
        return this.authService.isAuthoritySuperUser();
    }

    isAccountEnabled(userAccount: UserAccount) {
        return userAccount && userAccount.status !== UserAccountStatus.DISABLED;
    }

    canUserBeReset(userAccount: UserAccount) {
        return userAccount.user && (userAccount.status === UserAccountStatus.ACTIVE || userAccount.user.status === UserStatus.LOCKED);
    }

    currentUser(account: UserAccount) {
        return account.userId === this.profile.userId;
    }

    toggleEnabled(event: any, userAccount: UserAccount) {
        if (this.isAccountEnabled(userAccount)) {
            this.userAccountService.updateUserAccount(userAccount.id, {status : UserAccountStatus.DISABLED})
                .subscribe(() => {
                    this.refreshPage();
                });
        } else {
            this.userAccountService.updateUserAccount(userAccount.id, {status : UserAccountStatus.ACTIVE})
                .subscribe(() => {
                    this.refreshPage();
                });
        }
        event.cancelBubble = true;
        if (event.stopImmediatePropagation) {
            event.stopImmediatePropagation();
        }
    }

    resetPassword(_event: any, account: UserAccount) {
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        overlayRef.attach(new ComponentPortal(LoaderComponent));
        const passwordResetRequest = {
            userId: account.user.id
        };
        this.workflowService.resetPassword(passwordResetRequest).subscribe(
            () => {
                overlayRef.dispose();
                const dialogConfig: MatDialogConfig = {};
                dialogConfig.autoFocus = true;
                dialogConfig.panelClass = 'normal-modal';

                dialogConfig.data = {
                    message: 'Password reset link has been sent, and will expire in 8 hours.'
                };
                const dialog = this.dialog.open(InfoModalComponent, dialogConfig);

                dialog?.afterClosed().subscribe(
                    (refresh: boolean) => {
                        if (refresh) {
                            this.refreshPage();
                        }
                    }
                );
            }
        );
    }

    revokeInvitation(account: UserAccount) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            title: 'Revoke Invitation',
            description: `This will revoke the invitation sent to ${account.user.firstName} ${account.user.lastName}.  Any registration links sent to this user will be expired immediately.`,
            confirmMessage: 'Do you want to revoke this invitation?',
            confirmText: 'Revoke'
        };
        const dialog = this.dialog.open(ConfirmModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe(
            (confirmAction: any) =>  {
                if (confirmAction === 'confirmed') {
                    this.userAccountService.deleteUserInvitation(account.id).subscribe(
                        () => {
                            this.refreshPage();
                        }
                    );
                }
            }
        );

    }

    onRoleChanged(account: UserAccount) {
        this.userAccountService.updateUserAccount(account.id, {role : account.role})
            .subscribe(() => {
                this.refreshPage();
            });
    }

    onAddNewAuthority() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.data = {
            memberId: this.memberId,
            role: Role.AUTHORITY_READONLY
        };
        const dialog = this.dialog.open(NewUserModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe(
            (refresh: boolean) => {
                if (refresh) {
                    this.refreshPage();
                }
            }
        );
    }

    updatePhoneNumber(_event: any, user: User) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            user,
            fieldType: UserUpdatableField.PHONE
        };
        const dialog = this.dialog.open(UserFieldUpdateModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe(
            (refresh: boolean) => {
                if (refresh) {
                    this.refreshPage();
                }
            }
        );
    }

    updateEmail(_event: any, user: User) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            user,
            fieldType: UserUpdatableField.EMAIL
        };
        const dialog = this.dialog.open(UserFieldUpdateModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe(
            (refresh: boolean) => {
                if (refresh) {
                    this.refreshPage();
                }
            }
        );
    }

    refreshPage() {
        this.refreshEvent.emit();
    }
}
