import { MemberAccount } from './member-account';

export class Alert {
    id: string;
    created: Date;
    memberId: string;
    memberAccountId: string;
    memberName: string;
    acknowledgedBy: string;
    source: string;
    description : string;
    status: AlertStatus;
    type: AlertType;
    referencedEntityId: string;
    note : string;
    jsonData: string;

    // adhoc fields
    memberAccount: MemberAccount; // this should be replaced by a GraphQL call
}

export enum AlertStatus {

    ALL= '',
    ACTIVE = 'ACTIVE',
    REVIEWED = 'REVIEWED',
    ACTIONED = 'ACTIONED',
    INFORMATIONAL = 'INFORMATIONAL'

}

export enum AlertType {

    ALL_ALERT_TYPES = 'ALL_ALERT_TYPES',
    TRANSACTION = 'TRANSACTION',
    EXTERNAL_SYSTEM = 'EXTERNAL_SYSTEM',
    MEMBER = 'MEMBER',
    MEMBER_ACCOUNT = 'MEMBER_ACCOUNT',
    CONSUMER = 'CONSUMER',
    KEY_PARTY = 'KEY_PARTY',
    LICENSE = 'LICENSE',
    DOCUMENT = 'DOCUMENT',
    QUESTION_RESPONSE = 'QUESTION_RESPONSE',
    MONTHLY_REPORT = 'MONTHLY_REPORT',
    SAR_REPORT = 'SAR_REPORT',
    CTR_REPORT = 'CTR_REPORT',
    PERIODIC_REVIEW = 'PERIODIC_REVIEW',
    CURRENCY_WITHDRAWAL = 'CURRENCY_WITHDRAWAL',
    TRACKING_SYSTEM ='TRACKING_SYSTEM',
    TRANSACTION_FILE_PROCESSING = 'TRANSACTION_FILE_PROCESSING',
    FINCEN_INVESTIGATION = 'FINCEN_INVESTIGATION',
    MERCHANT = 'MERCHANT',
    PAYMENT_PROCESSOR = 'PAYMENT_PROCESSOR',
    USER = 'USER'
}

export class AccountThresholds {

    expectedMonthlyTransactions: number;
    expectedMonthlyCashDepositAmount: number;
    expectedMonthlyPaymentAmount: number;
    monthlyCashDepositAmountLimit: number;
    accountAmountLimit: number;
    maxNumberOfTransactionsPerMonth: number;
    maxTransactionTotalPerMonth: number;
    maxNumberOfTransactionsPerDay: number;
    maxTransactionTotal: number;
}
