export class ExternalPresentedCheckDetails {
    checkId: string;
    checkNumber: string;
    payorAccountNumber: string;
    checkAmount: number;
    checkCreationDate: Date;
    frontImageDocumentId: string;
    rearImageDocumentId: string;
    checkReviewReason: string;
}

export enum ExternalPresentedCheckStatus {
    NSF_CHECK = 'NSF_CHECK',
    APPROVED = 'APPROVED',
    RETURNED = 'RETURNED',
    MERGED = 'MERGED'
}