<div>
    <div class="col-12">
        <div class="horizontal-scroll mat-table-container">
            <div class="mat-table-spinner" *ngIf="isLoadingResults">
                <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
            </div>
            <table class="no-wrap table-sm" mat-table [dataSource]="transactions" matSort [matSortActive]="pageTracking.sort" matSortDisableClear [matSortDirection]="pageTracking.sortDir">
                <ng-container matColumnDef="Payor">
                    <th mat-header-cell *matHeaderCellDef> Payor </th>
                    <td mat-cell *matCellDef="let element">
                          <pc-load-member-details [linkDescription]="element.payorMemberName" [memberId]="element?.payorMemberId" [activeTab]="'internal'"></pc-load-member-details>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Recipient">
                    <th mat-header-cell *matHeaderCellDef class="centered"> Recipient </th>
                    <td mat-cell *matCellDef="let element" class="centered">
                        <pc-load-member-details [linkDescription]="element.recipientMemberName" [memberId]="element?.recipientMemberId" [activeTab]="'internal'"></pc-load-member-details>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Transaction Date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="transactionDate" class="centered"> Transaction Date </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.transactionDate">
                            <pc-date-time [date]="element.transactionDate"></pc-date-time>
                        </span>
                        <span *ngIf="!element.transactionDate"> Pending </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Check Number">
                    <th mat-header-cell *matHeaderCellDef class="centered"> Check Number </th>
                    <td mat-cell *matCellDef="let element" class="centered">
                        {{ element?.checkNumber }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Amount">
                    <th mat-header-cell  mat-sort-header="totalAmount" *matHeaderCellDef class="centered"> Amount </th>
                    <td mat-cell *matCellDef="let element" class="flex-start" class="centered pr-20">
                        <span>
                            <pc-transaction-amount [transaction]="element" [memberId]="element.payorMemberId" [memberAccountId]="element.payorAccountId"></pc-transaction-amount>
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Transaction Type">
                    <th mat-header-cell  mat-sort-header="transactionType" *matHeaderCellDef class="centered"> Transaction Type </th>
                    <td mat-cell *matCellDef="let element" class="flex-start" class="centered pr-20">
                        {{element.transactionType | translate}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Document">
                    <th mat-header-cell class="centered" *matHeaderCellDef> Document </th>
                    <td mat-cell class="centered" *matCellDef="let element">
                      <ng-container *ngIf="element?.supportingDocuments?.length > 0; else pending">
                        <a 
                          class="dashboard-list-a" 
                          (click)="downloadCheck(element.supportingDocuments[0].document)"
                          *ngIf="element.supportingDocuments[0]?.document">
                          <i class="fa fa-paperclip"></i>
                          {{ element.supportingDocuments[0].document.fileName }}
                        </a>
                      </ng-container>
                      <ng-template #pending>
                        <span *ngIf="!element?.supportingDocuments || element?.supportingDocuments.length === 0"> N/A </span>
                      </ng-template>
                    </td>
                  </ng-container>
                  
                <ng-container matColumnDef="Action">
                    <th mat-header-cell class="centered" *matHeaderCellDef> Action </th>
                    <td mat-cell  class="centered" *matCellDef="let element">
                        <pc-submit-button [text]="'Merge'" [submit]="submitEvent" (clickEvent)="handleMerge(element)"></pc-submit-button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="pc-click-row" (click)="loadTransactionDetails(row, $event)"></tr>
            </table>
            <mat-paginator #paginator (page)="handlePageBottom($event)" [pageIndex]="paginator.pageIndex" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
        </div>
    </div>
</div>